import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import { Grid } from '@material-ui/core'
import Popin from '../../../../../components/Popin'
import { colors, FIELDS_SUPPRESSION_DOCUMENT_POPIN } from '../../../../../utils/constants'
import { connect } from 'react-redux'
import Radio from '../../../../../components/form/Radio'
import { DOCUMENT_STATUS_ADVANCED, DOCUMENT_STATUS } from '../../utils/documentConstants'
import Input from '../../../../../components/form/Input'
import { isDocumentAMasquerOuASupprimer, isDocumentBrouillonOuAValider } from '../../../../../utils/utils'

const styles = () => getStyles({
	paddingText: {
		marginBottom: 24
	},
	content: {
		maxHeight: 600,
		fontFamily: 'Roboto'
	},
	radioGroup: {
		marginTop: -5,
		paddingLeft: 'inherit'
	},
	popin: {
		overflow: 'hidden'
	},
	radio: {
		width: '100%',
		marginBottom: 10,
		'& > div': {
			'& > label': {
				marginTop: 5,
				'& > span': {
					fontSize: 14,
					color: colors.text
				}
			}
		}
	},
	button: {
		width: 196,
		heigh: 36
	},
	containerMessage: {
		paddingLeft: 'inherit',
		paddingRight: 'inherit'
	},
	warningSuppression: {
		color: '#d32f2f',
		marginBottom: 24
	}
})

const RETRAIT_DOC_POPIN = 'RETRAIT_DOC_POPIN'

const validate = (values) => {
	const errors = {}
	if (!values.message || values.message.length === 0 || (values.message && values.message.trim().length === 0)) {
		errors.message = { id: 'global.errors.obligatoire' }
	}
	return errors
}

const SuppressionDocumentPopin = ({
	onClose, onSubmit,
	handleSubmit, classes,
	open, intl, isAdmin,
	documentTender, choixStatus
}) => {

	// Aucune checkbox si un contenu est BROUILLON ou A_VALIDER
	// La checkbox MASQUE n'apparait pas si un contenu à une demande de retrait en cours et que
	// son ancien status est BROUILLON ou A_VALIDER
	const previousStatus = documentTender.workflow.previousStatus
	const radioOptions = [
		{
			value: isAdmin ? DOCUMENT_STATUS.MASQUE : DOCUMENT_STATUS.A_MASQUER,
			label: <FormattedMessage id="document.masquerSupprimerDoc.popin.radioGroup.retrait" />
		},
		{
			value: isAdmin ? DOCUMENT_STATUS_ADVANCED.SUPPRIME : DOCUMENT_STATUS.A_SUPPRIMER,
			label: <FormattedMessage id="document.masquerSupprimerDoc.popin.radioGroup.suppression" />
		}
	]

	const optionsUpdated = isDocumentAMasquerOuASupprimer(documentTender)
		? radioOptions.concat({
			value: DOCUMENT_STATUS_ADVANCED.ROLLBACK_DEMAND,
			label: <FormattedMessage id={'document.masquerSupprimerDoc.popin.radioGroup.rollBackToPublie'} />
		})
		: radioOptions

	const actions = [
		<Button color="inherit" variant="outlined"
		        key="fermer" onClick={onClose}
		        className={classes.button}>
			<FormattedMessage id="global.buttons.annuler" />
		</Button>,

		<Button key="valider" color="primary"
		        variant="contained" type="submit"
		        onClick={handleSubmit(onSubmit)}
		        className={classes.button}
		>
			<FormattedMessage id="global.buttons.validate" />
		</Button>
	]

	return (
		<form onSubmit={onSubmit} className={classes.popin}>
			<Popin
				fromSearch
				open={open}
				onClose={onClose}
				actions={actions}
				title={isAdmin
					? <FormattedMessage id="document.masquerSupprimerDoc.popin.titleAdmin" />
					: <FormattedMessage id="document.masquerSupprimerDoc.popin.title" />
				}
				content={
					<Grid container className={classes.content} alignContent="flex-start">
						{(isAdmin && isDocumentAMasquerOuASupprimer(documentTender)) &&
						<Grid item xs={12}>
							<Typography variant="body2" className={classes.paddingText}>
								{documentTender.workflow.status === DOCUMENT_STATUS.A_MASQUER
									? <b><FormattedMessage id="document.masquerSupprimerDoc.popin.demandExistMasque" /></b>
									: <b><FormattedMessage id="document.masquerSupprimerDoc.popin.demandExistSuppression" /></b>
								}
							</Typography>
						</Grid>
						}

						{(isAdmin && documentTender.workflow.message !== null && isDocumentAMasquerOuASupprimer(documentTender)) &&
						<Grid item xs={12}>
							<Typography variant="body2" className={classes.paddingText}>
								{<FormattedMessage
									id="document.masquerSupprimerDoc.popin.messageUserForAdmin"
									values={{ message: documentTender.workflow.message }}
								/>}
							</Typography>
						</Grid>
						}

						<Grid item xs={12}>
							<Typography variant="body2" className={classes.paddingText}>
								{isDocumentBrouillonOuAValider(documentTender.workflow.status) || (previousStatus && isDocumentBrouillonOuAValider(previousStatus))
									? <FormattedMessage id="document.masquerSupprimerDoc.popin.detailsBrouillonOrToValidate" />
									: isAdmin
										? <FormattedMessage id="document.masquerSupprimerDoc.popin.detailsAdmin" />
										: <FormattedMessage id="document.masquerSupprimerDoc.popin.details" />
								}
							</Typography>
						</Grid>

						{!isDocumentBrouillonOuAValider(documentTender.workflow.status) &&
						<Grid item xs={12} className={classes.radioGroup}>
							<Field
								name={FIELDS_SUPPRESSION_DOCUMENT_POPIN.CHOIX_STATUS}
								component={Radio}
								choices={isAdmin
									? previousStatus && isDocumentBrouillonOuAValider(previousStatus)
										? optionsUpdated.filter(option => option.value !== DOCUMENT_STATUS.MASQUE)
										: optionsUpdated
									: optionsUpdated}
								column
								style={{
									justifyContent: 'space-between',
									paddingLeft: 'inherit'
								}}
								containerClass={classes.radio}
							/>
						</Grid>
						}

						{(isAdmin && choixStatus === DOCUMENT_STATUS_ADVANCED.SUPPRIME) &&
						<Grid item xs={12}>
							<Typography variant="body2" className={classes.warningSuppression}>
								{<FormattedMessage id="document.masquerSupprimerDoc.popin.warningSuppression" />}
							</Typography>
						</Grid>
						}

						{!isAdmin &&
						<Grid item xs={12}>
							<Typography variant="body2" className={classes.paddingText}>
								{<FormattedMessage id="document.masquerSupprimerDoc.popin.detailsMsg" />}
							</Typography>
						</Grid>
						}

						{!isAdmin &&
						<Grid item xs={12}>
							<Field
								name={FIELDS_SUPPRESSION_DOCUMENT_POPIN.MESSAGE}
								label={intl.formatMessage({ id: 'document.masquerSupprimerDoc.popin.placeholder' })}
								containerClassName={classes.containerMessage}
								component={Input}
								multiline
								inputProps={{
									maxLength: 250
								}}
							/>
						</Grid>
						}
					</Grid>
				}
			/>
		</form>
	)
}

SuppressionDocumentPopin.propTypes = {
	open: PropTypes.bool,
	isAdmin: PropTypes.bool,
	classes: PropTypes.object,
	documentTender: PropTypes.object,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	handleSubmit: PropTypes.func,
	choixStatus: PropTypes.string
}

const selector = formValueSelector(RETRAIT_DOC_POPIN)

const mapStateToProps = (state, { isAdmin, documentTender }) => {
	const previousStatus = documentTender.workflow.previousStatus

	const initChoixStatus = isDocumentBrouillonOuAValider(documentTender.workflow.status)
		? isAdmin ? DOCUMENT_STATUS_ADVANCED.SUPPRIME : DOCUMENT_STATUS.A_SUPPRIMER
		: isAdmin
			? previousStatus && isDocumentBrouillonOuAValider(previousStatus)
				? DOCUMENT_STATUS_ADVANCED.SUPPRIME
				: DOCUMENT_STATUS.MASQUE
			: DOCUMENT_STATUS.A_MASQUER

	return ({
		choixStatus: selector(state, 'choixStatus'),
		initialValues: {
			choixStatus: initChoixStatus
		}
	})
}

export default compose(
	injectIntl,
	connect(mapStateToProps),
	reduxForm({
		form: RETRAIT_DOC_POPIN,
		validate
	}),
	withStyles(styles)
)(SuppressionDocumentPopin)