import React from 'react'
import { getStyles } from 'isotope-client'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Popin from '../../../../../../components/Popin'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { getUserLanguage } from '../../../../../fo/user/services/userSelectors'
import { deleteSection } from '../../../services/parametrageActions'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { getSectionTitle } from '../../../../../fo/document/utils/documentUtils'

const styles = () => getStyles({
	popinResize: {
		maxWidth: 500
	},
	buttonAction: {
		marginLeft: 50
	}
})

const SectionDeletePopin = ({
								open,
								onClose,
								classes,
								deletedSection,
								deleteSection,
								idLanguage,
								type,
								sectionPath,
								snackSuccess
							}) => {
	const [error, setError] = React.useState(null)

	const handleDelete = (sectionId) => {
		deleteSection(type, sectionId, sectionPath)
			.then(() => {
				snackSuccess({ id: 'parametrage.section.delete.success' })
				onClose()
			})
			.catch((e) => {
				if (e.bodyError && e.bodyError.id) {
					setError(e.bodyError)
				} else {
					setError({ id: 'parametrage.section.delete.error.generic' })
				}
				throw e
			})
	}

	const actions =
		<Grid container justify="center">
			<Button
				key="annuler"
				color="inherit"
				variant="outlined"
				onClick={onClose}
			>
				<FormattedMessage id={`global.buttons.${error === null ? 'annuler' : 'close'}`} />
			</Button>
			{!error && <Button
				key="confirmer"
				className={classes.buttonAction}
				color="primary"
				variant="contained"
				type="submit"
				onClick={() => {
					handleDelete(deletedSection.id)
				}}
			>
				<FormattedMessage id="global.buttons.confirm" />
			</Button>}
		</Grid>

	return <Popin
		dialogClasses={{ paper: classes.popinResize }}
		open={open}
		actions={actions}
		onClose={onClose}
		title={<Typography variant="inherit">
			<FormattedMessage id={'parametrage.section.delete.title'} /> : {getSectionTitle(deletedSection, idLanguage)}
		</Typography>}
		content={<Grid container direction="column">
			{error === null ? <FormattedMessage id={'parametrage.section.delete.ask'} /> :
				<Typography variant="inherit" color="error"><FormattedHTMLMessage {...error} /></Typography>
			}
		</Grid>}
	/>
}

const actions = {
	deleteSection
}

const mapStateToProps = (state) => ({
	idLanguage: getUserLanguage(state)
})

export default compose(
	injectSnackActions,
	connect(mapStateToProps, actions),
	withStyles(styles)
)(SectionDeletePopin)
