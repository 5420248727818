import { getStyles } from 'isotope-client'
import { CmsEntityForm } from 'isotope-client-cms'
import React from 'react'
import PropTypes from 'prop-types'
import { Paper, withStyles } from '@material-ui/core'

const styles = getStyles({
	paper: {
		padding: '1rem 0.2rem',
		margin: '5rem auto 0'
	}
})

const CmsPage = ({ match, classes }) => {
	const { shortcut, modelName } = match.params
	return (<Paper className={classes.paper}>
		<CmsEntityForm key={shortcut} shortcut={shortcut} modelName={modelName} />
	</Paper>)
}

CmsPage.propType = {
	match: PropTypes.object.isRequired,
	classes: PropTypes.object
}

export default withStyles(styles)(CmsPage)
