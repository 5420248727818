import { fetchFactory } from 'isotope-client'

export const likeDocument = (documentId, documentType) => fetchFactory(`/utilisateurs/like/${documentId}`, {
	method: 'put',
	body: documentType
})

export const getLikes = () => fetchFactory('/utilisateurs/like')

export const getDocumentLike = (documentId) => fetchFactory(`/recherche/like/${documentId}`)
