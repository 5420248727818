import React from 'react'
import {compose} from 'redux'
import {Redirect, Route} from 'react-router-dom'
import AccueilPage from './accueil/AccueilPage'
import ContactPage from './contact/ContactPage'
import ConsultationDocumentPage from './document/consultation/ConsultationDocumentPage'
import DocumentPage from './document/DocumentPage'
import NewDocumentPage from './document/new/NewDocumentPage'
import RechercheDocumentPage, {
	ContenuAPublier,
	ContenuARetirer,
	ContenuAValider,
	MesContributions
} from './document/recherche/RechercheDocumentPage'
import ModificationUtilisateur, {MonCompte} from './user/ModificationUtilisateur'
import UsersPage, {CorrespondantsPage, DemandesAccesPage} from './user/UsersPage'
import {loadDefaultValueList} from '../common/valueList/ValueListInjector'
import {loadConversion} from '../common/conversion/ConversionInjector'
import {loadDocumentModels} from '../common/model/ModelInjector'
import {loadFavoris} from './favoris/services/FavorisInjector'
import {loadLikes} from './like/LikeInjector'
import PresentationPage from './tenderbox/PresentationPage'
import TabDocumentPage from './tabDocument/TabDocumentPage'

const FrontOffice = () => <>
	<Route exact path="/" render={() => <Redirect to={{ pathname: "/accueil" }}/>} />
	<Route exact path="/accueil" component={AccueilPage} />
	<Route exact path="/demandes" component={DemandesAccesPage} />
	<Route exact path="/correspondants" component={CorrespondantsPage} />
	<Route exact path="/utilisateurs" component={UsersPage} />
	<Route exact path="/utilisateurs/:idUser" component={ModificationUtilisateur} />
	<Route exact path="/documents" component={RechercheDocumentPage} />
	<Route exact path="/documents/:type/:id" component={RechercheDocumentPage} />
	<Route exact path="/documents/:id" component={RechercheDocumentPage} />
	<Route exact path="/mes-contributions" component={MesContributions} />
	<Route exact path="/contenu-a-valider" component={ContenuAValider} />
	<Route exact path="/contenu-a-publier" component={ContenuAPublier} />
	<Route exact path="/contenu-a-retirer" component={ContenuARetirer} />
	<Route exact path="/me" component={MonCompte} />
	<Route exact path="/edition-document/:id/:typeDocument" component={DocumentPage} />
	<Route exact path="/edition-document/:id" component={DocumentPage} />
	<Route exact path="/consultation-document/:id" component={ConsultationDocumentPage} />
	<Route exact path="/new-document" component={NewDocumentPage} />
	<Route exact path="/contact-form" component={ContactPage} />
	<Route exact path="/tenderbox" component={PresentationPage} />
	<Route exact path="/edition-table" component={TabDocumentPage} />
	<Route exact path="/consultation-table" component={TabDocumentPage} />
	<Route exact path="/edition-table/:id" component={TabDocumentPage} />
	<Route exact path="/consultation-table/:id" component={TabDocumentPage} />
</>

export default compose(
	loadDefaultValueList,
	loadConversion,
	loadDocumentModels,
	loadFavoris,
	loadLikes
)(FrontOffice)
