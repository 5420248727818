import React from 'react'
import { compose } from 'redux'
import { Route } from 'react-router-dom'
import { loadModels } from 'isotope-client-cms'
import { getStyles, ValueListPage } from 'isotope-client'
import AccueilBoPage from './accueil/AccueilBoPage'
import { ROLE_WEIGHT, ROOT_BO } from '../../utils/constants'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import CenteredLayout from '../../components/layout/CenteredLayout'
import CmsPage from './cms/CmsPage'
import { connect } from 'react-redux'
import cmsModels from './models/models'
import checkRole from '../../components/security/RoleChecker'
import ParametrageContenu from './parametrage/ParametrageContenu'
import { loadDocumentModels } from '../common/model/ModelInjector'
import { loadAllValueLists } from '../common/valueList/ValueListInjector'
import AdditionalFiltersPage from './additionalFilters/AdditionalFiltersPage'

const styles = () => getStyles({
	paper: {
		height: '100%'
	},
	accueilbo: {
		width: 580,
		margin: 'auto',
		marginTop: 100
	}
})

const BackOffice = ({ classes, loadModels, location }) => {
	React.useEffect(() => {
		// Chargement du modèle CMS
		loadModels(cmsModels)
	}, [])
	return (<React.Fragment>
			<CenteredLayout>
				<Paper className={location.pathname === ROOT_BO ? classes.accueilbo : classes.paper}>
					<Route exact path={ROOT_BO} component={AccueilBoPage} />
					<Route exact path={`${ROOT_BO}/value-list`} component={ValueListPage} />
					<Route exact path={`${ROOT_BO}/value-list/:id`} component={ValueListPage} />
					<Route exact path={`${ROOT_BO}/settings/:typeContenu`} component={ParametrageContenu} />
					<Route exact path={`${ROOT_BO}/cms/:modelName/:shortcut`} component={CmsPage} />
					<Route exact path={`${ROOT_BO}/additional-filters`} component={AdditionalFiltersPage} />
				</Paper>
			</CenteredLayout>
		</React.Fragment>
	)
}

const action = {
	loadModels: loadModels
}

export default compose(
	checkRole(ROLE_WEIGHT.ROLE_ADMIN),
	connect(null, action),
	loadAllValueLists,
	loadDocumentModels,
	withStyles(styles)
)(BackOffice)
