import { DEFAULT_LANGUAGE_ID, DEFAULT_SU, GlOBAL_LANGUAGE_ID } from '../../../../utils/constants'
import { getConversionBySuAndCode } from '../../../common/conversion/conversionSelectors'
import { ATTRIBUTE_DATA_CONTENT, ATTRIBUTE_DATA_FIELD, ATTRIBUTE_DATA_MO_FIELD, ATTRIBUTE_DATA_SECTION, FIELD_TYPE } from './documentConstants'
import { getDuplicateSuffix, getFilteredFields, getFilteredSections, normalizeNumber } from './documentUtils'
import { mergeDeep } from '../../../../utils/utils';

/**
 * Initialisation des valeurs du document
 * @param document
 * @param language
 * @returns {*}
 */
export const initContentDocumentValues = (document, language) => {
	if (!document || !document.attributeData) {
		return {}
	}

	const contentFormatted = document.attributeData.reduce((acc, att) => ({
		...acc,
		[att.idLanguage]: {
			...(acc[att.idLanguage] || {}),
			[att.label]: att.value
		}
	}), {})

	const idLanguage = contentFormatted[GlOBAL_LANGUAGE_ID] && contentFormatted[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_CONTENT.LANGUE]

	return {
		id: document.id,
		defaultTitle: idLanguage && contentFormatted[idLanguage] && contentFormatted[idLanguage][ATTRIBUTE_DATA_CONTENT.TITLE],
		title: contentFormatted[language] && contentFormatted[language][ATTRIBUTE_DATA_CONTENT.TITLE],
		zone: contentFormatted[GlOBAL_LANGUAGE_ID] && contentFormatted[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_CONTENT.ZONE],
		idLanguage,
		contributor: contentFormatted[GlOBAL_LANGUAGE_ID] && contentFormatted[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_CONTENT.CONTRIBUTOR],
		commentaire: contentFormatted[language] && contentFormatted[language][ATTRIBUTE_DATA_CONTENT.COMMENTAIRE]
	}
}

/////////////////////////////////////////////////////////
//          FONCTIONS D'EXTRACTION DES VALUES         //
///////////////////////////////////////////////////////

/**
 * Fonction qui récupère les values de l'ensemble du document
 * values du modèle + values du document
 * @param moSections
 * @param docSections
 * @param language
 * @param userSu // Utile pour les conversions unités
 * @param conversionMap // Utile pour les conversions unités
 */
export const extractValues = (moSections = [], docSections = [], language, userSu = DEFAULT_SU, conversionMap = {}) => {

	// On récupère les valeurs par défaut de tous les champs dans le modèle
	const moValues = getFilteredSections(moSections).reduce((acc, section) => {
		let sectionsFilles
		if (section.sections) {
			sectionsFilles = extractSectionValues(getFilteredSections(section.sections), language, true, undefined, userSu, conversionMap, section.id, true)
		} else {
			// Si je n'ai pas d'enfants, j'initialise le tout avec des valeurs vides
			sectionsFilles = { fields: {} }
		}

		return {
			...acc,
			...sectionsFilles.fields,
			...extractFieldsValues(getFilteredFields(section.fields), language, true, undefined, userSu, conversionMap, undefined)
		}
	}, {})

	let status = {}
	let sectionsWithContent = {}

	// On récupère les valeurs  de tous les champs et les statuts des sections dans le document
	const docValues = docSections.reduce((acc, section) => {
		sectionsWithContent = {
			...sectionsWithContent,
			[section.idMoSection]: {
				...(sectionsWithContent[section.idMoSection] || {}),
				[section.duplicated ? section.duplicatedNumber : 0]: section.hasContent[language] || section.hasContent[GlOBAL_LANGUAGE_ID] || false
			}
		}
		let sectionsFilles = {}
		if (section.sections) {
			// Si ma section a des enfants, je vais chercher les valeurs de ses champs et les statuts de la section
			sectionsFilles = extractSectionValues(section.sections, language, false, undefined, userSu, conversionMap, section.idMoSection)
		}

		status = {
			...status,
			...sectionsFilles.status
		}

		sectionsWithContent = mergeDeep(sectionsWithContent, sectionsFilles.sectionsWithContent)

		// On retourne les champs des sections filles (mode récursif) et les valeurs des champs
		return {
			...acc,
			...sectionsFilles.fields,
			...extractFieldsValues(section.fields, language, false, undefined, userSu, conversionMap, undefined)
		}
	}, {})

	return {
		status,
		fields: Object.keys(docValues).reduce((map, key) => {
			// Dans le cas d'un tableau on fusionne la value
			// Utile dans le cas d'ajout de colonne
			if (map[key] && map[key].type === FIELD_TYPE.TABLE) {
				return ({
					...map,
					[key]: {
						...map[key],
						...docValues[key],
						numberRow: docValues[key].numberRow || 1,
						value: {
							...map[key].value,
							...((docValues[key] && docValues[key].value) || {})
						},
						id: (map[key] || {}).id || (docValues[key] || {}).id,
						idMoField: (map[key] || {}).idMoField || (docValues[key] || {}).idMoField,
						idMoSection: (map[key] || {}).idMoSection || (docValues[key] || {}).idMoSection,
						idSection: (map[key] || {}).idSection || (docValues[key] || {}).idSection
					}
				})
			}
			return ({
				...map,
				[key]: {
					...map[key],
					...docValues[key],
					id: (map[key] || {}).id || (docValues[key] || {}).id,
					idMoField: (map[key] || {}).idMoField || (docValues[key] || {}).idMoField,
					idMoSection: (map[key] || {}).idMoSection || (docValues[key] || {}).idMoSection,
					idSection: (map[key] || {}).idSection || (docValues[key] || {}).idSection
				}
			})
		}, moValues),
		sectionsWithContent
	}
}

/**
 * Fonction qui récupère les values pour une section
 * Modèle pour un ajout, document pour une édition
 * @param sections
 * @param language
 * @param isInitModele
 * @param duplicatedNumber
 * @param userSu
 * @param conversionMap
 * @param isModele
 * @param idMoParentSection
 */
export const extractSectionValues = (sections, language, isInitModele = false, duplicatedNumber = undefined, userSu, conversionMap, idMoParentSection = '', isModele = false) => {
	return sections.reduce((acc, section) => {

		// On commence par indiquer si la section a des champs renseignés
		// Comme on va boucler sur le modèle pour l'affichage des sections, on doit indiquer les ID modèle dans notre objet
		let sectionsWithContent = acc.sectionsWithContent
		if (!isModele) {
			if (section.hasContent) {
				sectionsWithContent = {
					...acc.sectionsWithContent,
					[section.idMoSection]: {
						...(acc.sectionsWithContent[section.idMoSection] || {}),
						[section.duplicated ? section.duplicatedNumber : 0]: section.hasContent[language] || section.hasContent[GlOBAL_LANGUAGE_ID] || false
					}
				}
			}
		}

		let sectionsFilles
		if (section.sections) {
			// Si j'ai des sections filles, je vais chercher les enfants et les champs
			const filteredSections = isInitModele ? getFilteredSections(section.sections) : section.sections
			sectionsFilles = extractSectionValues(filteredSections, language, isInitModele, duplicatedNumber, userSu, conversionMap, section.idMoSection, isModele)
		} else {
			// Si je n'ai pas d'enfants, j'initialise le tout avec des valeurs vides
			sectionsFilles = isModele ? { fields: {} } : { fields: {}, status: {} }
		}

		// On concatène les infos de champs des sections filles
		const fields = {
			...acc.fields,
			...sectionsFilles.fields,
			...extractFieldsValues(isInitModele ? getFilteredFields(section.fields) : section.fields, language, isInitModele, duplicatedNumber, userSu, conversionMap, undefined)
		}

		if (isModele) {
			return { fields }
		} else {
			const status = {
				id: section.idMoSection,
				idMoParentSection: idMoParentSection,
				position: section.position,
				duplicated: section.duplicated,
				duplicatedNumber: section.duplicatedNumber,
				finished: section.attributeData && section.attributeData[GlOBAL_LANGUAGE_ID] && section.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_SECTION.IS_FINISHED] && section.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_SECTION.IS_FINISHED].value === 'true'
			}
			return {
				fields,
				status: {
					...acc.status,
					...sectionsFilles.status,
					[`${idMoParentSection}-${section.duplicated ? `dup#${section.duplicatedNumber || duplicatedNumber}-` : '' }${section.position}-${section.idMoSection}`]: status
				},
				sectionsWithContent: mergeDeep(sectionsWithContent, sectionsFilles.sectionsWithContent)
			}
		}
	}, { fields: {}, status: isModele ? undefined : {}, sectionsWithContent: isModele ? undefined : {} })
}

/**
 * Fonction qui récupère les values des fields
 * @param fields
 * @param language
 * @param isInitModele
 * @param duplicatedNumber
 * @param userSu
 * @param conversionMap
 * @param numberRow
 * @returns {*}
 */
const extractFieldsValues = (fields, language, isInitModele, duplicatedNumber, userSu, conversionMap, numberRow) =>
	fields.reduce((acc, field) => {
		if (field.reference) {
			// Gestion spécifique du tableau
			if (field.type === FIELD_TYPE.TABLE) {
				if (!isInitModele && numberRow === undefined && field.attributeData[GlOBAL_LANGUAGE_ID] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.NUMBER_ROW]) {
					numberRow = field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.NUMBER_ROW].value
				}
				return {
					...acc,
					[`${field.reference}${getDuplicateSuffix(duplicatedNumber)}`]: {
						type: field.type,
						numberRow,
						value: extractFieldsValues(field.fields, language, isInitModele, undefined, userSu, conversionMap, numberRow),
						id: isInitModele ? undefined : field.id,
						idMoField: isInitModele ? field.id : field.idMoField,
						idMoSection: isInitModele ? field.idMoSection : undefined,
						idSection: isInitModele ? undefined : field.idSection
					}
				}
			}

			// Récupération de l'éventuelle valeur
			let value = undefined
			if (!isInitModele && (field.attributeData[language] || field.attributeData[GlOBAL_LANGUAGE_ID])) {
				const dataValue = (field.attributeData[language] && field.attributeData[language][ATTRIBUTE_DATA_FIELD.VALUE]) ||
					(field.attributeData[GlOBAL_LANGUAGE_ID] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.VALUE])
				if (dataValue) {
					value = dataValue.value
					// Si nous sommes sur un multiselect ou sur un field de tableau, on formatte la potentielle defaultValue
					if (field.type === FIELD_TYPE.MULTI_SELECT || field.type === FIELD_TYPE.FILE ||
						((isInitModele && field.idMoParentField) || (!isInitModele && field.idParentField))) {
						value = JSON.parse(dataValue.value)
					}
				}
			}

			// Récupération de la valeur par défaut (utile dans le cas globalReport)
			const defaultValue = {}
			if (isInitModele && field.attributeData[GlOBAL_LANGUAGE_ID] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.DEFAULT_VALUE]) {
				defaultValue.defaultValue = field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.DEFAULT_VALUE].value
			}

			// Si nous sommes sur un field de tableau, on l'initialise avec un tableau d'un élément
			if (!value && ((isInitModele && field.idMoParentField) || (!isInitModele && field.idParentField))) {
				value = ['']
			}

			// Pour un link, on référence également le libellé
			// (dans le cas d'une édition)
			let libelle = undefined
			if (!isInitModele && field.type === FIELD_TYPE.LINK && field.attributeData[language] && field.attributeData[language][ATTRIBUTE_DATA_FIELD.LIBELLE_LINK]) {
				libelle = field.attributeData[language][ATTRIBUTE_DATA_FIELD.LIBELLE_LINK].value
			}

			// Pour un montant, on référence l'éventuelle siValue et l'unité
			const amount = {}
			if (!isInitModele && field.type === FIELD_TYPE.AMOUNT) {
				amount.unite = field.attributeData[GlOBAL_LANGUAGE_ID] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.VALUE] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.VALUE].unite
				amount.siValue = normalizeNumber(field.attributeData[GlOBAL_LANGUAGE_ID] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.SI_VALUE] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.SI_VALUE].value)
				amount.value = normalizeNumber(value)
			}

			const dunsData = {}
			if (!isInitModele && field.type === FIELD_TYPE.DUNS) {
				dunsData.dunsData= field.attributeData[GlOBAL_LANGUAGE_ID] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.VALUE] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.DUNS_DATA] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.DUNS_DATA].value
			}

			if (!isInitModele && field.type === FIELD_TYPE.NUMBER && value) {
				amount.value = value.map ? value.map(val => normalizeNumber(val)) : normalizeNumber(value)
			}

			const numberUnit = {}
			// Dans le cas d'une unité, nous devons effectuer une conversion (si l'utilisateur n'est pas dans le système international)
			if (field.type === FIELD_TYPE.NUMBER_UNIT && field.attributeData[GlOBAL_LANGUAGE_ID]) {
				if (isInitModele && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.DEFAULT_UNITE] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.DEFAULT_UNITE].value) {
					const conversionCoeff = getConversionCoeff(field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.DEFAULT_UNITE].value, userSu, conversionMap)
					numberUnit.unite = JSON.stringify({
						code: field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.DEFAULT_UNITE].value,
						coeff: conversionCoeff
					})
					numberUnit.value = normalizeNumber(value)
				} else if (!isInitModele && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.VALUE] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.VALUE].value) {
					const conversionCoeff = getConversionCoeff(field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.VALUE].unite, userSu, conversionMap)
					numberUnit.unite = JSON.stringify({
						code: field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.VALUE].unite,
						coeff: conversionCoeff
					})
					numberUnit.value = normalizeNumber(field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.VALUE].value * (conversionCoeff || 1))
					if (conversionCoeff) {
						numberUnit.userValue = field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.VALUE].value
					}
				}

			}

			if (!value && field.type === FIELD_TYPE.TEXT_MULTILINE) {
				value = ''
			}
			return {
				...acc,
				[`${field.reference}${getDuplicateSuffix(duplicatedNumber)}`]: {
					labelField: (field.attributeData[language] && field.attributeData[language][ATTRIBUTE_DATA_MO_FIELD.NAME] && field.attributeData[language][ATTRIBUTE_DATA_MO_FIELD.NAME].value) ||
						(field.attributeData[DEFAULT_LANGUAGE_ID] && field.attributeData[DEFAULT_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.NAME] && field.attributeData[DEFAULT_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.NAME].value),
					value,
					libelle,
					...amount,
					...numberUnit,
					...defaultValue,
					...dunsData,
					type: field.type,
					id: isInitModele ? undefined : field.id,
					idMoField: isInitModele ? field.id : field.idMoField,
					idMoSection: isInitModele ? field.idMoSection : undefined,
					idSection: isInitModele ? undefined : field.idSection,
					confidential: isInitModele ? false :
						field.attributeData && field.attributeData[GlOBAL_LANGUAGE_ID] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.CONFIDENTIAL] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_FIELD.CONFIDENTIAL].value === 'true'
				}
			}
		}
		return acc
	}, {})

/**
 * Retourne le coefficient de conversion
 * @param value
 * @param userSu
 * @param conversionMap
 * @returns {*}
 */
const getConversionCoeff = (value, userSu, conversionMap) => {
	if (userSu !== DEFAULT_SU) {
		const conversion = getConversionBySuAndCode(undefined, userSu, value, conversionMap)
		if (!!conversion) {
			return conversion.coeff
		}
	}
	return undefined
}
