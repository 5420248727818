import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'

import { getStyles } from 'isotope-client'
import { colors } from '../../utils/constants'
import mapError from './mapError'
import { toI18N } from '../../utils/utils'

import withStyles from '@material-ui/core/styles/withStyles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FilledInput from '@material-ui/core/FilledInput'
import FormHelperText from '@material-ui/core/FormHelperText'
import MuiSelect from '@material-ui/core/Select'

const styles = () => getStyles({
	formControl: {
		width: 'auto'
	},
	container: {
		height: '100%',
		paddingRight: 10
	},
	filledInput: {
		padding: '18px 25px 19px'
	},

	selectHeader: {
		'&:focus': {
			fontSize: '14px',
			paddingLeft: '30px',
			backgroundColor: colors.tertiary,
			color: colors.text
		},
		fontSize: '14px',
		paddingLeft: '30px',
		backgroundColor: colors.tertiary,
		color: colors.text
	},
	selectContent: {
		'&:focus': {
			fontSize: '16px',
			paddingLeft: '30px',
			backgroundColor: colors.primary,
			color: 'white'
		},
		fontSize: '16px',
		paddingLeft: '30px',
		backgroundColor: colors.primary,
		color: 'white'
	},
	iconHeader: {
		color: 'inherit'
	},
	iconContent: {
		color: 'white'
	}
})

const SearchSelect = ({ input: { value, active, ...inputProps }, meta: { error, dirty, touched }, id, label, children, disabled, classes, isHeader }) => {
	return (<div className={classes.container}>
		<FormControl className={classes.formControl} error={touched && !!error}>
			{!!label && <InputLabel htmlFor={id}>{label}</InputLabel>}
			<MuiSelect
				value={value}
				disableUnderline={disabled}
				disabled={disabled}
				input={<FilledInput classes={{ input: classes.filledInput }} {...inputProps} />}
				id={id}
				classes={{
					select: isHeader ? classes.selectHeader :classes.selectContent,
					icon: isHeader ? classes.iconHeader : classes.iconContent
				}}
			>
				{children}
			</MuiSelect>
			{typeof error !== 'undefined' && touched && <FormHelperText>{toI18N(error)}</FormHelperText>}
		</FormControl>
	</div>)
}

SearchSelect.propTypes = {
	input: PropTypes.object,
	meta: PropTypes.object,
	classes: PropTypes.object,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	isHeader: PropTypes.bool
}

export default compose(
	mapError,
	withStyles(styles)
)(SearchSelect)

