import React from 'react'
import PropTypes from 'prop-types'
import OverflowTypography from '../../../../../components/OverflowTypography'
import { bytesToSize } from '../../../../../utils/utils'
import SvgIcon from '../../../../../components/icon/SvgIcon'
import { SVG_ICONS } from '../../../../../utils/constants'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import { FormattedMessage } from 'react-intl'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => getStyles({
	padding: {
		paddingLeft: 10,
		paddingRight: 10
	},
	fileName: {
		fontWeight: 'bold',
		paddingLeft: 20
	},
	fileSize: {
		paddingLeft: 20
	},
	paperFile: {
		position: 'relative',
		width: '100%'
	},
	cardFile: {
		padding: 16,
		height: 74
	},
	deleteIconButton: {
		position: 'absolute',
		top: 8,
		right: 8,
		zIndex: 10
	},
	deleteIcon: {
		fontSize: '1.2rem'
	},
	circularProgress: {
		marginLeft: 8,
		color: theme.palette.text.main
	}
})

const FileCard = ({ file, canDelete, onDelete, uploaded, classes }) => {
	const renderIcon = (mimeTypes) => {
		const iconProperty = {
			className: classes.icon,
			color: 'secondary',
			fontSize: 'large'
		}

		switch (mimeTypes) {
			case `application/vnd.ms-excel`:
			case `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`:
				return <SvgIcon path={SVG_ICONS.FILE_EXCEL} {...iconProperty} />
			case `application/pdf`:
				return <SvgIcon path={SVG_ICONS.FILE_PDF} {...iconProperty} />
			case `application/vnd.ms-powerpoint`:
			case `application/vnd.openxmlformats-officedocument.presentationml.presentation`:
				return <SvgIcon path={SVG_ICONS.FILE_POWERPOINT} {...iconProperty} />
			case `application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document`:
				return <SvgIcon path={SVG_ICONS.FILE_WORD} {...iconProperty} />
			case `image/png`:
			case `image/jpeg`:
			case `image/jpg`:
				return <SvgIcon path={SVG_ICONS.FILE_PHOTO} {...iconProperty} />
			case `video/mp4`:
				return <SvgIcon path={SVG_ICONS.FILE_VIDEO} {...iconProperty} />
			case `application/vnd.google-apps.spreadsheet`:
				return <SvgIcon path={SVG_ICONS.FILE_SHEET} {...iconProperty} />
			case `application/vnd.google-apps.document`:
				return <SvgIcon path={SVG_ICONS.FILE_DOC} {...iconProperty} />
			case `application/vnd.google-apps.presentation`:
				return <SvgIcon path={SVG_ICONS.FILE_SLIDE} {...iconProperty} />
			default:
				return <SvgIcon path={SVG_ICONS.FILE_DOC} {...iconProperty} />

		}
	}

	const size = bytesToSize(file.sizeBytes)

	return <div className={classes.paperFile}>
		{uploaded && canDelete && <IconButton
			size="small"
			onClick={(e) => {
				onDelete()
				e.stopPropagation()
			}}
			className={classes.deleteIconButton}
		><ClearIcon className={classes.deleteIcon} /></IconButton>}
		<a key={`file-${file.id}`} target="_blank" rel="noopener noreferrer" href={file.url}>
			<Paper className={classes.paperFile}>
				<Grid direction="row" item container xs={12} className={classes.cardFile} alignItems="center">
					<Grid item xs={1}>
						{renderIcon(file.mimeType)}
					</Grid>
					<Grid item xs={10}>
						<OverflowTypography variant="body1" className={classes.fileName}>{file.name}</OverflowTypography>
						<Typography variant="body2" className={classes.fileSize}>{size} {uploaded === false && <FormattedMessage id="document.field.drive.uploading" />}</Typography>
					</Grid>
					<Grid item xs={1}>
						{uploaded === false && <CircularProgress size={24} className={classes.circularProgress} />}
					</Grid>
				</Grid>
			</Paper>
		</a>
	</div>
}

FileCard.propTypes = {
	file: PropTypes.object,
	canDelete: PropTypes.bool,
	onDelete: PropTypes.func,
	classes: PropTypes.object
}

export default withStyles(styles)(FileCard)
