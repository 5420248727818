import { USER_PREFERENCES } from '../../../../utils/constants'

export const SECTION_TYPE = {
	RUBRIQUE: 'RUBRIQUE',
	FI: 'FI',
	DOCUMENT: 'DOCUMENT',
	STANDARD: 'STANDARD'
}

// Attribute data modèle
export const ATTRIBUTE_DATA_MO_SECTION = {
	TITLE: 'TITLE',
	VISIBILITY_REF: 'VISIBILITY_REF',
	VISIBILITY_VALUE: 'VISIBILITY_VALUE',
	TITLE_CODE: 'TITLE_CODE'
}

export const ATTRIBUTE_DATA_MO_FIELD = {
	NAME: 'NAME',
	DEFAULT_VALUE: 'DEFAULT_VALUE',
	HELP: 'HELP',
	SOURCE: 'SOURCE',
	PARENT_REFERENCE: 'PARENT_REFERENCE',
	UNITE_LIST: 'UNITE_LIST',
	DEFAULT_UNITE: 'DEFAULT_UNITE',
	VISIBILITY_REF: 'VISIBILITY_REF',
	VISIBILITY_VALUE: 'VISIBILITY_VALUE'
}

// Attribute data document
export const ATTRIBUTE_DATA_CONTENT = {
	TITLE: 'TITLE',
	ZONE: 'ZONE',
	LANGUE: 'LANGUE',
	CONTRIBUTOR: 'CONTRIBUTOR',
	COMMENTAIRE: 'COMMENTAIRE'
}

export const ATTRIBUTE_DATA_SECTION = {
	IS_FINISHED: 'IS_FINISHED'
}

export const ATTRIBUTE_DATA_FIELD = {
	VALUE: 'VALUE',
	CONFIDENTIAL: 'CONFIDENTIAL',
	LIBELLE_LINK: 'LIBELLE_LINK',
	SI_VALUE: 'SI_VALUE',
	NUMBER_ROW: 'NUMBER_ROW',
	DUNS_DATA: 'DUNS_DATA'
}

export const FIELD_TYPE = {
	TEXT: 'TEXT',
	TEXT_MULTILINE: 'TEXT_MULTILINE',
	SELECT: 'SELECT',
	MULTI_SELECT: 'MULTI_SELECT',
	NUMBER: 'NUMBER',
	NUMBER_UNIT: 'NUMBER_UNIT',
	AMOUNT: 'AMOUNT',
	DATE: 'DATE',
	FILE: 'FILE',
	LINK: 'LINK',
	LINK_PROPOSITION: 'LINK_PROPOSITION',
	TABLE: 'TABLE',
	DUNS: 'DUNS',
	OTHER_DATA: 'OTHER_DATA'
}

export const EXCLUDED_FIELDS_TABLE_VIEW = [FIELD_TYPE.FILE, FIELD_TYPE.TABLE, FIELD_TYPE.LINK_PROPOSITION]

export const DOCUMENT_STATUS = {
	BROUILLON: 'BROUILLON',
	A_VALIDER: 'A_VALIDER',
	VALIDE: 'VALIDE',
	PUBLIE: 'PUBLIE',
	MASQUE: 'MASQUE',
	A_SUPPRIMER: 'A_SUPPRIMER',
	A_MASQUER: 'A_MASQUER'
}

// SUPPRIME permet d'informer qu'il faut supprimé le contenu et donc de changer son état
// A ne pas mettre dans DOCUMENT_STATUS car on ne veut pas voir cette option apparaître dans les critères de recherche
export const DOCUMENT_STATUS_ADVANCED = {
	SUPPRIME: 'SUPPRIME',
	ROLLBACK_DEMAND: 'ROLLBACK_DEMAND'
}

export const DISPLAY_LEVEL_SUMMARY = 3

export const COLLAPSE_MAX_LEVEL_SECTION = 2

export const COLLAPSE_MAX_LEVEL_SECTION_BO = 1

export const SELECT_OPTIONS_AVANCEE = {
	SUPERIOR: 'SUPERIOR',
	EQUAL: 'EQUAL',
	NOT_EQUAL: 'NOT_EQUAL',
	INFERIOR: 'INFERIOR',
	BEFORE: 'BEFORE',
	AFTER: 'AFTER',
	INCLUDE: 'INCLUDE',
	NOT_INCLUDE: 'NOT_INCLUDE',
	BETWEEN: 'BETWEEN',
	EQUAL_DATE: 'EQUAL_DATE'
}

export const SELECT_OPTIONS_BY_FIELD = {
	TEXT: [SELECT_OPTIONS_AVANCEE.INCLUDE, SELECT_OPTIONS_AVANCEE.NOT_INCLUDE],
	DUNS: [SELECT_OPTIONS_AVANCEE.INCLUDE, SELECT_OPTIONS_AVANCEE.NOT_INCLUDE],
	SELECT: [SELECT_OPTIONS_AVANCEE.INCLUDE, SELECT_OPTIONS_AVANCEE.NOT_INCLUDE],
	MULTI_SELECT: [SELECT_OPTIONS_AVANCEE.INCLUDE, SELECT_OPTIONS_AVANCEE.NOT_INCLUDE],
	NUMBER: [SELECT_OPTIONS_AVANCEE.SUPERIOR, SELECT_OPTIONS_AVANCEE.EQUAL, SELECT_OPTIONS_AVANCEE.INFERIOR, SELECT_OPTIONS_AVANCEE.BETWEEN],
	NUMBER_UNIT: [SELECT_OPTIONS_AVANCEE.SUPERIOR, SELECT_OPTIONS_AVANCEE.EQUAL, SELECT_OPTIONS_AVANCEE.INFERIOR, SELECT_OPTIONS_AVANCEE.BETWEEN],
	AMOUNT: [SELECT_OPTIONS_AVANCEE.SUPERIOR, SELECT_OPTIONS_AVANCEE.EQUAL, SELECT_OPTIONS_AVANCEE.INFERIOR, SELECT_OPTIONS_AVANCEE.BETWEEN],
	DATE: [SELECT_OPTIONS_AVANCEE.BEFORE, SELECT_OPTIONS_AVANCEE.AFTER, SELECT_OPTIONS_AVANCEE.EQUAL_DATE, SELECT_OPTIONS_AVANCEE.BETWEEN],
	FILE: [SELECT_OPTIONS_AVANCEE.INCLUDE, SELECT_OPTIONS_AVANCEE.NOT_INCLUDE]
}

export const REFERENCES_FI = ['FI_PRE_CLIENT_MARKET', 'FI_SEGMENTS', 'FI_CORE_BUSINESS', 'FI_COUNTRY', 'FI_BID_BRIEF']

export const DRIVE_UPLOAD_TYPE = {
	FOLDER: 'folder',
	FILE: 'file'
}

export const FI_TRIPLET = [
	{fieldNameSearch: 'fi_SEGMENTS_VALUE',fieldName: 'FI_SEGMENTS', pref: USER_PREFERENCES.FI_SEGMENT},
	{fieldNameSearch: 'fi_COUNTRY_VALUE',fieldName: 'FI_COUNTRY', pref: USER_PREFERENCES.FI_PAYS},
	{fieldNameSearch: 'fi_PRE_CLIENT_MARKET_VALUE',fieldName: 'FI_PRE_CLIENT_MARKET', pref: USER_PREFERENCES.FI_MARCHES}
]

export const FI_FACILITATEUR = [
	{fieldNameSearch: 'fi_PRE_CLIENT_SECTOR_VALUE',fieldName: 'FI_PRE_CLIENT_SECTOR', pref: USER_PREFERENCES.FI_PRE_CLIENT_SECTOR},
	{fieldNameSearch: 'fi_SUBSEGMENT_VALUE',fieldName: 'FI_SUBSEGMENT', pref: USER_PREFERENCES.FI_SUBSEGMENT}
]

export const FI_ALL_USER = [
	...FI_TRIPLET,
	...FI_FACILITATEUR
]

export const RECHERCHE_FORM_COLLAPSED_ID = {
	LOCATION: 'location',
	CLIENTS: 'clients',
	ACTIVITIES: 'activities',
	DOCUMENT: 'document',
	ADDITIONNALS: 'additionnals',
	ADMIN: 'admin'
}
