import React from 'react'
import { FormRichText, getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import { compose } from 'redux'
import PropTypes from 'prop-types'

const styles = () => getStyles({
	container: {
		paddingRight: 10,
		paddingLeft: 10,
		marginBottom: 15
	}
})

const RichText = ({ classes, ...otherProps }) => (
	<div className={classes.container}>
		<FormRichText
			{...otherProps}
		/>
	</div>
)

RichText.defaultProps = {
	configuration: { toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'] }
}

RichText.propTypes = {
	classes: PropTypes.object
}

export default compose(
	withStyles(styles)
)(RichText)