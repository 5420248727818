import { flatten } from 'flat'
import { messages } from 'isotope-client'
import { cmsMessagesFr } from 'isotope-client-cms'

export default flatten({
	...messages,
	cms: {
		...cmsMessagesFr,
		entities: {
			tenderbox: 'Modification de la page de présentation de Tender Box',
			visiteur: 'Modification du bloc visiteur',
			presentation: 'Modification du bloc présentation',
			contact: 'Modification du bloc contact',
			correspondant: 'Modification du bloc correspondant'
		},
		fields: {
			title: 'Titre du bloc *',
			content: 'Contenu du bloc'
		}
	},
	global: {
		messages: {
			accueilFO: 'Un plugin de suivi est utilisé temporairement pour nous permettre d\'améliorer l\'outils en fonction de vos usages.'
		},
		link: {
			viderFiltre: 'Effacer tous les critères'
		},
		buttons: {
			rechercher: 'Rechercher',
			voirPlus: 'Voir plus ..',
			annuler: 'Annuler',
			confirm: 'Confirmer',
			compris: 'Compris',
			more: 'PLUS D\'INFORMATIONS',
			save: 'Enregistrer',
			save_filter: 'Sauvegarder',
			send: 'Envoyer',
			close: 'Fermer',
			add: 'Ajouter',
			validate: 'Valider',
			delete: 'Supprimer',
			updateFields: 'Modifier les champs',
			update: 'Mettre à jour',
			loginGoogle: 'SIGN IN WITH GOOGLE'
		},
		errors: {
			droitsInsuffisants: 'Vous n\'avez pas les droits nécessaires pour accéder à cette page',
			obligatoire: 'Ce champ doit être renseigné',
			formulaire: 'Vous devez renseigner les champs obligatoires',
			formatEmail: 'Le format du mail n\'est pas valide',
			amountObligatoire: 'Vous devez renseigner une devise',
			amountObligatoireMontant: 'Vous devez renseigner un montant',
			emptyMessageError: ' '
		},
		yes: 'Oui',
		no: 'Non',
		workInProgress: 'Fonctionnalité en cours de développement',
		no_results: 'Il n\'y a aucun résultats',
		resultTitle: '{results, plural, one {Résultat} other {Résultats}} de la recherche ({results, number})',
		tris: {
			pertinence: 'Par pertinence',
			a_z: 'A ⇒ Z',
			z_a: 'Z ⇒ A',
			date_connexion_desc: 'Date de dernière connexion du plus au moins récent',
			date_connexion_asc: 'Date de dernière connexion du moins au plus récent',
			date_modification_desc: 'Date de modification du plus au moins récent',
			date_modification_asc: 'Date de modification du moins au plus récent',
			like: 'Contenus les plus likés'
		},
		more: {
			export_result: 'Exporter les résultats',
			partage_result: 'Partager la recherche',
			ajouter_favoris_result: 'Ajouter la recherche en favoris',
			ajouter_favoris_result_notif_on: 'Ajouter la recherche en favoris avec notification'
		},
		NA: 'Non renseigné'
	},
	menu: {
		boParametrageMenu: 'PARAMETRAGE',
		boPropositionCommercial: 'Propositions commerciales',
		boSavoirFaire: 'Savoir-faire',
		boReference: 'Références',
		boValueList: 'Liste de valeurs',
		additionalFilters: 'Filtres supplémentaires',
		boPersonnalisationMenu: 'PERSONNALISATION',
		boBlocPresentation: 'Bloc présentation',
		boBlocVisiteur: 'Bloc  pour les visiteurs',
		boBlocContact: 'Bloc contact',
		boBlocCorrespondant: 'Bloc correspondant',
		returnFo: 'Front office',
		admin: 'Administration fonctionnelle',
		accueilMenu: 'Accueil',
		contenuMenu: 'CONTENUS',
		rechercherContenu: 'Rechercher un contenu',
		ajouterContenu: 'Ajouter un contenu',
		contribution: 'Mes Contributions',
		contenuAValider: 'Contenu à valider',
		contenuAPublier: 'Contenu à publier',
		contenuARetirer: 'Contenu à retirer',
		annuaireMenu: 'ANNUAIRE',
		correspondanceZone: 'Correspondants',
		demandeAcces: 'Demander un accès membre',
		demandeAttente: 'Demandes en attente',
		listeUtilisateur: 'Liste des utilisateurs',
		aideContactMenu: 'AIDE & CONTACT',
		presentationTenderBox: 'Présentation Tender Box',
		formContact: 'Contacter l’équipe Tender Box'
	},
	favoris: {
		addSearch: {
			success: 'Votre recheche a été ajoutée aux favoris',
			error: 'Votre recherche n\'a pas pu être ajoutée aux favoris'
		},
		document: 'Contenus',
		emptyDocument: 'Vous n\'avez pas encore enregistré de contenu en favoris',
		search: 'Recherches',
		emptySearch: 'Vous n\'avez pas encore enregistré de recherche',
		searchItem: 'Recherche enregistrée le',
		deleteSearchTitle: 'Suppression d\'une recherche en favoris',
		deleteDocumentTitle: 'Suppression d\'un document en favoris',
		deleteSearchDescription: 'Êtes-vous sûr de vouloir supprimer la recherche des favoris ?',
		deleteDocumentDescription: 'Êtes-vous sûr de vouloir supprimer le contenu des favoris ?',
		updateSearch: {
			success: 'Votre recherche a été mise à jour',
			error: 'Votre recherche n\'a pu être mise à jour'
		},
		deleteSearch: {
			success: 'Votre recherche a bien été supprimée',
			error: 'Votre recherche n\'a pas pu être supprimée'
		}
	},
	appBar: {
		menus: {
			monCompte: 'Mon compte',
			cookies: 'Gérer mes préférences cookies',
			logout: 'Déconnecter'
		}
	},
	accueilBo: {
		title: 'Bienvenue dans la partie "Administration" de l\'application Tender Box de Veolia'
	},
	translation: {
		inputLabel: 'Traduction',
		button: 'Traduire'
	},
	searchBar: {
		placeHolder: 'Rechercher dans Tender Box',
		all: 'Tous les contenus'
	},
	autocompleteMulti: {
		placeHolder: 'Sélectionnez'
	},
	constants: {
		request: {
			VALID: 'Validé',
			TO_VALIDATE: 'A valider'
		},
		profils: {
			VISITEUR: 'Visiteur',
			MEMBRE: 'Membre',
			CORRESPONDANT: 'Correspondant',
			ADMIN: 'Administrateur'
		},
		user_connecte: {
			SEMAINE: 'Dans la semaine',
			MOIS: 'Dans le mois',
			TRIMESTRE: 'Dans le trimestre',
			ANNEE: 'Dans l\'année'
		}
	},
	users: {
		title: 'Recherche',
		listeUsers: 'Liste des utilisateurs',
		listeDemandes: 'Demandes à valider',
		listeCorrespondants: 'Liste des correspondants',
		demandeCorrespondant: 'Vous souhaitez devenir correspondant ?',
		click: 'Cliquez ici',
		noResults: 'Il n\' y a aucun utilisateur correspondant à votre recherche',
		search: {
			filterTitle: 'Recherche',
			default: 'Indifférent',
			nameLabel: 'Nom',
			zoneLabel: 'Zone',
			paysLabel: 'Pays',
			paysOrigineLabel: 'Pays d\'origine',
			marcheLabel: 'Marché',
			segmentLabel: 'Business segment',
			profilLabel: 'Profil',
			demandeLabel: 'Demande',
			connecteLabel: 'Utilisateurs connectés'
		},
		actions: {
			valid: 'Valider',
			donDroits: 'Donner les droits Membre',
			refuse: 'Refuser',
			delDroits: 'Retirer les droits Membre',
			modify: 'Modifier le compte utilisateur'
		},
		accesRequest: {
			demande: 'Accès demandé le : ',
			valide: 'Accès validé le : ',
			refuse: 'Accès refusé : ',
			derniereCo: 'Dernière connexion le : ',
			refusAuto: 'Refus automatique'
		},
		popin: {
			validation: {
				titlePopin: 'Donner les droits Membre',
				messagePopin: 'Confirmez-vous l’attribution des droits Membre à ',
				success: 'La demande d\'accès a bien été validée',
				error: 'Une erreur s\'est produite lors de la validation de la demande'
			},
			refus: {
				titleRefus: 'Refuser une demande d’accès',
				titleRetire: 'Retirer les droits Membre ',
				messageRefus: 'Confirmez-vous le rejet de la demande d’accès de ',
				messageRetire: 'Confirmez-vous le retrait des droits Membre de ',
				field: 'Justification *',
				success: 'La demande de refus a bien été validée',
				error: 'Une erreur s\'est produite lors de la demande de refus'
			}
		}
	},
	oss: {
		popinChoixFields: {
			title: 'Sélection des colonnes à afficher ({sizeColumns}/50 colonnes maximum)',
			sectionChampsAvances: 'Champs avancés',
			maxColumnReached: 'Vous ne pouvez pas afficher plus de 50 colonnes sur le tableau'
		},
		tooltip: {
			accesEditList: 'Editer les références',
			accesViewList: 'Visualisation des références de la recherche'
		},
		tabDocumentPage: {
			titleEdition: 'Modification de contenu',
			titleConsultation: 'Visualisation de contenus',
			table: {
				headerTitle: 'Références'
			},
			tooltip: {
				fieldNotFoundLang: 'Ce champ n’est pas encore renseigné dans la langue sélectionnée, pour le renseigner, rendez-vous directement sur la référence.',
				notEditable: 'Ce champ impacte la structure de la référence, pour le modifier, rendez-vous directement sur la référence.',
				cantModify: 'Ce champ n’est pas encore renseigné dans la langue sélectionnée, pour le renseigner, rendez-vous directement sur la référence.',
				notExist: 'Ce champ n’est renseigné dans aucune des langues, pour le renseigner, rendez-vous directement sur la référence.'
			},
			snackBar: {
				tabInfo: 'Ce mode de modification vous permet de voir uniquement les références pour lesquelles vous avez les droits de modification.',
				editFieldSucces: 'Modification prise en compte.',
				editFieldError: 'Échec de la modification.',
				tabModificationInfo: 'Ce mode de modification vous permet de voir uniquement les références pour lesquelles vous avez les droits de modification.',
				tabConsultationInfo: 'Ce mode de visualisation vous permet de consulter l\'ensemble des références excepté les données confidentielles notées "Données confidentielles"'
			}
		},
		actionBar:{
			editColumns: 'Modifier les colonnes'
		},
		popin: {
			shareTitle: {
				title: 'Partager le tableau'
			}
		}
	},
	document: {
		search: {
			collapse: {
				location: 'LOCALISATION',
				clients: 'CLIENTS',
				activities: 'ACTIVITÉS',
				document: 'TYPES DE DOCUMENT',
				additional: 'FILTRES ADDITIONNELS',
				admin: 'FILTRES ADMINISTRATEUR'
			},
			additionalInfos: 'Vous cherchez un autre critère ?',
			additionalAddInfos: 'Cliquez ici pour rechercher une donnée spécifique',
			adminInfos: 'Cliquez ici pour ajouter un filtre administrateur',
			correspondantInfos: 'Cliquez ici pour ajouter un filtre correspondant',
			title: 'Rechercher dans Tender Box',
			titleMesContrib: 'Mes contributions',
			titleAValider: 'Contenus à valider',
			titleAPublier: 'Contenus à publier',
			titleARetirer: 'Contenus à retirer',
			filterTitle: 'Critère de recherche',
			default: 'Indifférent',
			queryPlaceholder: 'Rechercher dans Tender Box',
			typeContenuLabel: 'Types de contenu',
			typeContenuDefault: 'Tous',
			langueLabel: 'Langue',
			zoneLabel: 'Zone',
			marketLabel: 'Market',
			sectorLabel: 'Secteur',
			businessSegmentLabel: 'Business segment',
			businessSubSegmentLabel: 'Sous-Segment',
			coreBusinessLabel: 'Core business',
			bidBriefLabel: 'Part of the bid brief',
			paysLabel: 'Pays',
			proposalStatusLabel: 'Statut de la proposition',
			withAttachmentLabel: 'Avec pièces jointes',
			internalExternalLabel: 'Interne - Externe',
			noResults: 'Il n\'y a aucun contenu correspondant à votre recherche',
			correspondant: {
				title: 'FILTRES CORRESPONDANTS',
				info: 'Lorsqu\'un des filtres ci-dessous est appliqué, la recherche s\'effectuera uniquement sur les contenus dont vous êtes correspondant'
			},
			field: {
				noInput: 'Veuillez entrer une valeur',
				noValue: 'Aucune valeur correspondante'
			},
			advance: {
				title: 'Filtres supplémentaires',
				addFilter: 'Ajouter un filtre',
				description: 'Vous pouvez sélectionner ci-dessous les filtres recommandés proposés en fonction des sous-segments sélectionnés. \n' +
					'Si vous cherchez une  donnée spécifique différente sélectionner le choix "<strong>Autre donnée</strong>" qui vous permet de rechercher une donnée spécifique.',
				removeFilter: 'Effacer tous les filtres',
				showOnlyAttachments: 'Afficher seulement les contenus avec pièce jointe',
				appliedFilters: 'Filtres appliqués',
				appliedFiltersDescription: 'Les filtres supplémentaires appliqués apparaitront ici',
				newFilter: {
					title: 'Nouveau Filtre',
					field: {
						searchKey: 'Filtre supplémentaire',
						searchNumber: 'Égal, supérieur ou inférieur',
						searchDate: 'Avant, après',
						searchText: 'Contient, ne contient pas',
						placeholderText: 'Rechercher la valeur',
						otherData: 'Rechercher une autre donnée',
						dataName: 'Nom de la donnée',
						attachment: 'Afficher seulement les contenus avec pièce jointe',
						and: '{lessThan} et {greaterThan}',
						EQUAL: 'Égal à',
						NOT_EQUAL: 'Différent de',
						SUPERIOR: 'Supérieur à',
						INFERIOR: 'Inférieur à',
						BEFORE: 'Avant le',
						AFTER: 'Après le',
						INCLUDE: 'Contient',
						NOT_INCLUDE: 'Est différent de',
						BETWEEN: 'Entre',
						EQUAL_DATE: 'Le',
						errorBetween: 'Ne peut être inférieur à la première valeur',
						errorBetweenDate: 'Ne peut être antérieur à la première date'
					},
					button: 'Filtrer',
					closeButton: 'Annuler'
				}
			},
			adminPopin: {
				title: 'Filtres administrateurs',
				description: 'Vous avez la possibilité de filtrer ici des informations réservées aux administrateurs comme par exemple le statut du contenu , le contributeur, la date de création, la date de soumission, la date de publication et bien plus encore.',
				fields: {
					adminFilter: 'Filtre administrateur'
				}
			},
			correspondantPopin: {
				title: 'Filtres correspondants',
				description: 'Vous avez la possibilité de filtrer ici des informations réservées aux correspondants comme par exemple le statut du contenu , le contributeur et bien plus encore. Lorsqu\'un des filtres est appliqué ici, la recherche s\'effectuera uniquement sur les contenus dont vous êtes correspondant.',
				fields: {
					correspondantFilter: 'Filtre correspondant'
				},
				typeField: {}
			},
			adminCorrespondantPopin: {
				errors:{
					date2Beforedate1 : 'Dates invalides'
				},
				fields: {
					status: 'Statut du contenu',
					contributor: 'Contributeur',
					dateCreation: 'Date de création',
					dateSoumission: 'Date de soumission',
					datePublication: 'Date de publication',
					publierPar: 'Administrateur ayant publié le contenu',
					dateLastModif: 'Date de dernière modification',
					modifierPar: 'Utilisateur ayant modifié un contenu en dernier',
					dateMasqued: 'Date de retrait',
					masquerPar: 'Administrateur ayant retiré le contenu'
				},
				radioGroup: {
					after: 'Après le ',
					at: 'Le ',
					before: 'Avant le ',
					between: 'Entre '
				},
				appliedFilters: 'Filtres appliqués :',
				emptyStateFilters: 'Les filtres supplémentaires appliqués apparaitront ici',
				cardTitle: {
					status: 'Status',
					contributor: 'Contributeur',
					dateCreation: 'Date de création',
					dateSoumission: 'Date de soumission',
					datePublication: 'Date de publication',
					publierPar: 'Publié par',
					dateLastModif: 'Date de dernière modification',
					modifierPar: 'Dernière modification par',
					dateMasqued: 'Date de retrait',
					masquerPar: 'Masqué par'
				},
				filterDateBetween: 'Entre le {dateDebut} et le {dateFin}'
			},
			share: {
				title: 'Partager la recherche',
				description: 'Renseignez les adresses e-mail du ou des destinataires séparés par un ";" afin qu’ils puissent accéder la recherche que vous avez effectué ',
				form: {
					email: 'Email des destinataires *',
					commentaire: 'Message complémentaire'
				},
				success: 'Votre recherche a bien été partagée'
			},
			export: {
				sent: 'Votre recherche est en cours d\'export. Vous recevrez un e-mail lorsque le document sera disponible.',
				title: 'Exporter le tableau de résultat',
				description: 'Vous êtes sur le point d\'exporter le tableau de résultat de votre recherche.  Vous pouvez sélectionner ici les différentes colonnes que vous désirez faire apparaître sur votre tableau et les ordonner selon votre besoin.',
				selection: 'Sélection des colonnes',
				selected: 'Colonnes sélectionnées',
				default: 'Colonnes par défaut',
				add: 'Ajouter',
				remove: 'Retirer',
				placeholder: 'Colonnes à afficher',
				send: 'Exporter le tableau'
			},
			notFound: 'Aucune recherche ne correspond à l\'id spécifié',
			addToFavorite: {
				title: "Édition d'une recherche sauvegardée",
				form: {
					title: "Titre de la recherche",
					notification: "Notification hebdomadaire",
					listFields: "Liste des champs recherchés ? :"
				}
			}
		},
		card: {
			created: 'Créé le : ',
			contributor: 'Contributeur : ',
			modified: 'Mis à jour le : ',
			modifiedBy: 'Mis à jour par : ',
			submit: 'Soumis le : ',
			submitBy: 'Soumis par : ',
			validated: 'Validé le : ',
			published: 'Publié le : ',
			publishedBy: 'Publié par : ',
			masked: 'Masqué le : ',
			maskedBy: 'Masqué par : ',
			toDelete: 'Demande suppression le : ',
			toDeleteBy: 'Demande suppression par : ',
			toMask: 'Demande masque le : ',
			toMaskBy: 'Demande masque par : '
		},
		newDocumentPage: {
			propositionCommercial: {
				titre: 'Proposition Commerciale',
				description: 'Un contenu de type Proposition Commerciale permet de partager les informations et documents d\'une Offre commerciale.\nCertaines données pourront être déclarées confidentielles, et donc non visibles pour la majorité des utilisateurs de Tender Box.',
				libelleLien: 'AJOUTER UNE PROPOSITION COMMERCIALE'
			},
			savoirFaire: {
				titre: 'Savoir-faire',
				description: 'Un contenu de type Savoir-faire permet de partager des informations spécifiques relatives aux savoirs faire de Veolia, et pouvant être réutilisées dans une nouvelle proposition commerciale, dans un communiqué, etc..',
				libelleLien: 'AJOUTER UN SAVOIR FAIRE'
			},
			reference: {
				titre: 'Référence',
				description: 'Un contenu de type Référence permet de partager les données commerciales et techniques sur des réalisations Veolia.\nCertaines données pourront être déclarées confidentielles, et donc non visibles pour la majorité des utilisateurs de Tender Box',
				libelleLien: 'AJOUTER UNE RÉFÉRENCE'
			}
		},
		submittingInfo: 'En raison d\'une restriction de Google dans le processus de copie, le téléchargement des dossiers et fichiers peut prendre plusieurs minutes. Veuillez ne pas fermer cet onglet pendant l\'enregistrement.',
		saveSuccess: 'Informations enregistrées. La mise à jour de cet enregistrement et la visibilité sur Tender Box peut prendre un certain temps en fonction du nombre et de la taille des documents joints.',
		newContent: 'Nouveau contenu',
		editContent: 'Modification de contenu',
		retour: 'Retour',
		saveRequiredPublication: 'L\'enregistrement est requis pour changer le statut',
		saveRequired: 'L\' enregistrement est requis pour changer de langue',
		errorField: 'Le champ "{fieldName}" doit être correctement renseigné ',
		globalReport: {
			tooltip: 'Mettre à jour les valeurs de Global Report',
			popin: {
				title: 'Mise à jour de la fiche',
				details: 'Certains champs de la fiche sont liés au Data lake Veolia Global Report. Vous êtes sur le point de mettre à jour ces champs avec les données actuelles de Global Report, ce qui écrasera les valeurs renseignées précédemment dans ces champs.',
				confirm: 'Mettre à jour'
			},
			success: 'Mise à jour des valeurs liées à Global Report effectuée.'
		},
		entete: {
			title: 'Titre',
			zone: 'Zone',
			language: 'Langue d\'origine',
			contributor: 'Contributeur',
			commentaire: 'Commentaire à l\'attention de l\'équipe Tender Box (non diffusé)'
		},
		section: {
			defaultTitle: 'Titre de section',
			defaultSubtitle: 'Sous-titre de section',
			switch: 'Indiquer cette section comme terminée',
			deleteTitle: 'Suppression section dupliquée',
			deleteMessage: 'Confirmez-vous la suppression de cette section dupliquée ?'
		},
		field: {
			confidentiable: 'Donnée confidentielle',
			libelleLink: 'Libellé',
			urlLink: 'URL',
			defaultValueInfo: 'Valeur de référence :',
			noValueInGR: 'Aucune donnée de référence pour ce champ.',
			drive: {
				formPlaceHolder : "Rechercher dans le drive",
				open: 'Ouvrir mon drive',
				selectFiles: 'Sélectionnez le ou les fichiers correspondant depuis votre drive',
				infoFolder: 'Seuls les 5 premiers niveaux d\'un dossier seront importés. Les sous-dossiers et fichiers à partir du 5ème niveau ne seront pas importés. La taille maximale d\'un fichier importable est de 50 Mo (aucune limite de taille sur les fichiers vidéo).',
				prefixFolder: 'Dossier : ',
				errorRights: '{nomFichier} non chargé (droits insuffisants).',
				errorSize: '{nomFichier} non chargé (taille supérieure à 50Mo).',
				errorGlobal: '{nomFichier} non chargé (une erreur est survenue).',
				uploading: '- Enregistrement en cours...'
			},
			duns:{
				noresult:"Aucun resultat",
				errorApi:"Erreur lors de l'appel API Duns",
				dunsLabelName:"Société",
				dunsGlobalLabelName:"Société parente (monde)",
				dunsCountryLabelName:"Société parente (pays)",
				searchLink:"Cliquez ici pour obtenir un code DUNS"
			}
		},
		folderPopin: {
			title: 'Composition des dossiers du champ "{fieldName}"',
			emptyFolder: 'Le dossier est vide',
			emptySearch: 'Aucun document ne correspond à la recherche'
		},
		statusResponse: {
			error: 'Vous n\'êtes pas autorisé à effectuer cette action',
			refus: 'La demande a bien été refusée.',
			A_VALIDER: 'Le contenu a bien été soumis.',
			VALIDE: 'Le contenu a bien été validé.',
			PUBLIE: 'Le contenu a bien été publié.',
			MASQUE: 'Le contenu a bien été retiré.',
			SUPPRIME: 'Le contenu a bien été supprimé.',
			A_SUPPRIMER: 'Le contenu a bien été soumis pour être supprimé.',
			A_MASQUER: 'Le contenu a bien été soumis pour être masqué.'
		},
		button: {
			soumettre: 'Soumettre',
			valider: 'Valider',
			publier: 'Publier',
			retirer: 'Retirer'
		},
		menuConsultation: {
			share: 'Partager',
			save: 'Enregistrer',
			contribute: 'Contribuer',
			exportFollow: 'Exporter pour suivi'
		},
		partage: {
			title: 'Partager le contenu',
			ask: 'Désirez-vous partager ce contenu pour consultation ou pour contribution ?',
			description: 'Renseignez les adresses e-mail du ou des destinataires séparés par un ";" afin qu’ils reçoivent le lien du contenu. ',
			form: {
				contribution: 'Partager pour contribution',
				consultation: 'Partager pour consultation',
				email: 'Email des destinataires *',
				commentaire: 'Message complémentaire'
			},
			success: 'Le partage de contenu a bien été transmis',
			close: 'Fermer',
			send: 'Envoyer'
		},
		translate: {
			title: 'Traduction automatique',
			ask: 'Sélectionnez dans quelle langue vous souhaitez effectuer une traduction automatique.',
			description: 'La traduction automatique s\'effectuera seulement pour les champs restés vides dans la ou les langues sélectionnées et l\'utilisateur aura toujours la possibilité de les modifier.',
			close: 'Annuler',
			send: 'Traduire'
		},
		suivi: {
			success: 'L\'export est en cours, vous allez recevoir un mail avec le lien permettant d\'accéder au fichier.'
		},
		masquerSupprimerDoc: {
			popin: {
				title: 'Demande de retrait de contenu',
				details: 'Désirez-vous demander de masquer ce contenu de Tender Box ou de le supprimer définitivement ?',
				detailsBrouillonOrToValidate: 'Ce contenu n\'étant pas encore publié, il peut uniquement être supprimé définitivement. Désirez-vous le supprimer ?',
				titleAdmin: 'Retirer le contenu',
				detailsAdmin: 'Désirez-vous masquer ce contenu de Tender Box ou le supprimer définitivement ?',
				detailsMsg: 'Vous pouvez ajouter un message à destination de l’administrateur.',
				placeholder: 'Message complémentaire (250 caractères max.)*',
				warningSuppression: 'Attention : La suppression est irréversible.',
				demandExistMasque: 'Un utilisateur souhaite masquer cette fiche.',
				demandExistSuppression: 'Un utilisateur souhaite supprimer cette fiche.',
				messageUserForAdmin: 'Message de l\'utilisateur : {message}',
				radioGroup: {
					retrait: 'Masquer le contenu',
					suppression: 'Supprimer définitivement',
					rollBackToPublie: 'Annuler la demande en cours'
				}
			},
			snackbar: {
				success: 'Demande de retrait prise en compte',
				error: 'Une erreur est survenue'
			}
		}
	},
	contact: {
		title: 'Contacter l’équipe Tender Box',
		validation: {
			message: 'Votre message a été envoyé avec succès, l’équipe Tender Box vous répondra dans les plus brefs délais'
		},
		field: {
			sujet: {
				label: 'Sujet *',
				placeholder: 'Sélectionner le sujet du message'
			},
			objet: 'Objet *',
			message: 'Message *'
		}
	},
	enums: {
		langue: {
			fr: 'Français',
			en: 'Anglais',
			es: 'Espagnol',
			pl: 'Polonais'
		},
		documentType: {
			REFERENCE: 'Référence',
			PROPOSITION_COMMERCIALE: 'Proposition commerciale',
			SAVOIR_FAIRE: 'Savoir-faire'
		},
		profil: {
			ROLE_VISITEUR: 'Visiteur',
			ROLE_MEMBRE: 'Membre',
			ROLE_CORRESPONDANT: 'Correspondant',
			ROLE_ADMIN: 'Administrateur'
		},
		documentStatus: {
			BROUILLON: 'Brouillon',
			A_VALIDER: 'À valider',
			VALIDE: 'Validé',
			PUBLIE: 'Publié',
			MASQUE: 'Masqué',
			A_SUPPRIMER: 'À supprimer',
			A_MASQUER: 'À masquer'
		},
		documentStatusSummary: {
			BROUILLON: '(brouillon)',
			A_VALIDER: '(à valider)',
			VALIDE: '(validé)',
			PUBLIE: '(publié)',
			MASQUE: '(masqué)',
			A_SUPPRIMER: '(à supprimer)',
			A_MASQUER: '(à masquer)',
			SUPPRIME: 'Supprimé'
		},
		documentStatusContributions: {
			BROUILLON: 'Créé le',
			A_VALIDER: 'Soumis le',
			VALIDE: 'Validé le',
			PUBLIE: 'Publié le',
			MASQUE: 'Retiré de la publication le',
			A_SUPPRIMER: '[SUP] Soumis le',
			A_MASQUER: '[MASK] Soumis le'
		},
		field: {
			TEXT: 'Texte',
			TEXT_MULTILINE: 'Texte multiligne',
			SELECT: 'Select',
			MULTI_SELECT: 'Multi-select',
			NUMBER: 'Nombre',
			NUMBER_UNIT: 'Nombre et unité',
			AMOUNT: 'Montant',
			DATE: 'Date',
			FILE: 'Fichier',
			LINK: 'Lien',
			LINK_PROPOSITION: 'Lien proposition commerciale',
			TABLE: 'Tableau',
			DUNS: 'Code Duns'
		},
		withAttachment: {
			CASE_STUDY: 'Étude de cas',
			CERTIFICATE: 'Certificat'
		},
		internalExternal: {
			INTERNAL: 'Interne',
			EXTERNAL: 'Externe'
		}
	},
	modificationUser: {
		title: 'Utilisateur : ',
		titleCompte: 'Mon compte',
		retour: 'Retour vers la recherche',
		infoVisiteur: 'En tant que visiteur, vous disposez d\'un accès restreint à Tender Box.\n' +
			'Un membre a accès à de nombreuses fonctionnalités supplémentaires telles que :\n' +
			'- Valoriser les contenus de sa zone en alimentant la Tender Box\n' +
			'- Partager ses critères de recherche\n' +
			'- Créer ses favoris.\n',
		btnAsk: 'Demander un accès',
		form: {
			titlePart: 'Préférences',
			nom: 'Nom *',
			prenom: 'Prénom *',
			email: 'Email *',
			zone: 'Zone *',
			zoneVisiteur: 'Vérifiez qu\'il s\'agit bien de votre pays. Une fois votre demande d\'accès demandée, vous ne pourrez plus la modifier et seul un administrateur pourra l’éditer.',
			profil: 'Profil *',
			FI_COUNTRY: 'Pays',
			FI_PRE_CLIENT_MARKET: 'Marché',
			FI_SEGMENTS: 'Business Segment',
			FI_PRE_CLIENT_SECTOR: 'Secteur',
			FI_SUBSEGMENT: 'Sous-Segment',
			paysOrigine: 'Pays d\'origine *',
			langue: 'Langue *',
			devise: 'Devise *',
			su: 'Système d\'unité *',
			paramGestion: 'Périmètre de gestion',
			paramSubElement: 'TODO Périmètre de Facilitateur',
			error: {
				mandatory: 'Ce champ doit être renseigné'
			}
		},
		backHome: 'Retourner à l\'accueil',
		askTitle: 'Demande d\'accès',
		askMessage: 'Votre demande a bien été prise en compte. Vous recevrez une notification par email une fois que le correspondant de votre zone aura validé ou refusé l’accès.',
		popin: {
			title: 'Modification du profil et des préférences',
			message: 'Confirmez-vous les modifications effectuées pour le profil de ',
			success: 'L\'utilisateur a bien été modifié',
			error: 'Une erreur s\'est produite lors de l\'enregistrement de l\'utilisateur'
		}
	},
	accueil: {
		propositionCommerciale: {
			titre: 'Propositions commerciales',
			description: 'Les Propositions commerciales sont l’ensemble des documents qui ont été remis au client lors d\'un processus d\'appel d\'offres.<br /><br />' +
				'Vous pourrez entre autres partager et télécharger des notes techniques ou également des synthèse pour la préparation d\'appels d’offres.'
		},
		savoirFaire: {
			titre: 'Savoir-faire',
			description: 'Les Savoir-faire sont des briques d’offres standardisées qui mettent en avant une thématique précise. Elles peuvent être réutilisées dans le cadre de préparation d\'appels d’offres.<br /><br />' +
				'Vous pourrez partager et télécharger différents sujets tels que la santé et la sécurité, la gestion des actifs, la présentation de l\'organisation transversale, etc.'
		},
		reference: {
			titre: 'Références',
			description: 'Les références fournissent des informations contractuelles, techniques et financières sur les contrats remportés par Veolia.<br /><br />' +
				'Vous pourrez partager et télécharger des case studies, des lettres clients et également de nombreux indicateurs techniques',
			button: 'Afficher des références avec des cases studies uniquement'
		},
		disponible: 'Disponibles dans Tender Box',
		publication: 'Dont publiés ce mois',
		contenuAValider: {
			titre: 'Contenus à valider',
			description: 'Vous trouverez ici le nombre de contenu soumis par les membres Tender Box de votre zone en attente de validation.',
			emptyTitre: 'Vous n’avez pas de contenu à valider…',
			emptyDescription: 'Vous trouverez ici le nombre de contenus soumis par les membres Tender Box de votre zone qui sont en attente de validation',
			bouton: 'Voir ces contenus'
		},
		contenuAPublier: {
			titre: 'Contenus à publier',
			description: 'Ces contenus ont été soumis par des Membres Tender Box, toutes zones confondues. Ils sont en attente de validation pour publication.',
			emptyTitre: 'Vous n’avez pas de contenu à publier…',
			emptyDescription: 'Vous trouverez ici le nombre de contenus soumis par des membres Tender Box, toutes zones confondues, en attente de validation pour publication.',
			bouton: 'Voir ces contenus'
		},
		accesAValider: {
			titre: 'Demandes d’accès à valider',
			description: 'Ces demandes ont été formulées par des utilisateurs de votre zone. Validez leurs demandes pour leur permettre d\'ajouter des contenus et utiliser pleinement Tender Box.',
			emptyTitre: 'Vous n’avez pas d’accès à valider…',
			emptyDescription: 'Vous trouverez ici le nombre de demandes formulées par des visiteurs Tender Box pour votre zone, en attente de validation',
			bouton: 'Voir ces demandes'
		},
		accesAValiderAdmin: {
			titre: 'Demandes d’accès à valider',
			description: 'Ces demandes d\'accès ont été formulées par des Visiteurs de Tender Box, toutes zones confondues. Elles sont en attente de validation par les correspondants de zones.',
			emptyTitre: 'Vous n’avez pas d’accès à valider…',
			emptyDescription: 'Vous trouverez ici le nombre de demande formulées par des Visiteurs de Tender Box, toutes zones confondues et en attente de validation.',
			bouton: 'Voir ces demandes'
		},
		top: {
			titre: 'Top 10 des contenus Tender Box',
			description: 'Liste des 10 contenus les plus likés par les utilisateurs'
		},
		mesContributions: {
			titre: 'Mes contributions',
			description: 'Tous les contenus dont je suis contributeur',
			add: 'Ajouter un contenu',
			empty: 'Vous n\'avez aucune contribution'
		},
		libelleLien: {
			presentation: 'En savoir plus',
			contact: 'Nouveau message',
			correspondant: 'Rechercher',
			visiteur: 'Demander un accès'
		}
	},
	parametrage: {
		typeContenu: {
			PROPOSITION_COMMERCIALE: 'Propositions commerciales',
			SAVOIR_FAIRE: 'Savoir-faire',
			REFERENCE: 'Références'
		},
		plan: 'Plan',
		detail: 'Détail',
		section: {
			defaultTitle: 'Titre de la section',
			type: {
				FI: 'Fiche d\'identité',
				RUBRIQUE: 'Rubrique',
				STANDARD: 'Standard',
				DOCUMENT: 'Document'
			},
			level: 'Niveau {level}',
			form: {
				REFERENCE: 'Références : ',
				new: 'Nouvelle section de niveau 1',
				title: 'Nouvelle section',
				parent: 'Section parent *',
				name: {
					en: 'Nom de la section (Anglais) *',
					fr: 'Nom de la section (Français) *',
					es: 'Nom de la section (Espagnol) *',
					pl: 'Nom de la section (Polonais) *'
				},
				type: 'Type de section *',
				titleCode: 'Code du champ impactant le titre',
				after: 'La section s\'affiche après la section *',
				first: 'La section s\'affiche en première position',
				visibility: 'La visibilité de la section est conditionnée par une valeur',
				sectionRegroupement: 'La section est une rubrique thématique de regroupement',
				reference: 'Référence de la donnée conditionnant la visibilité',
				code: 'Code de la valeur conditionnant la visibilité',
				publier: 'Publier la section',
				traduction: 'Traduction'
			},
			createsuccess: 'Nouvelle section créée avec succès',
			editsuccess: 'Section modifiée avec succès',
			delete: {
				title: 'Suppression de la section',
				ask: 'Êtes-vous sûr de vouloir supprimer cette section ?',
				success: 'Section supprimée avec succès',
				error: {
					child: 'Suppression impossible, car la section n\'est pas vide.',
					fi: 'Impossible de supprimer une Fiche d\'identité.',
					generic: 'Une erreur est survenue lors de la suppression de la section.'
				}
			},
			error: {
				isNotEmpty: 'Dépublication impossible, car la section comporte des données/sections non dépubliées',
				parentNotPublished: 'Publication impossible, car la section parent est dépubliée'
			}
		},
		field: {
			defaultTitle: 'Titre du champ'
		},
		btnSection: 'Nouvelle section',
		btnField: 'Nouvelle donnée',
		btnMajSearch: 'Mettre à jour la recherche',
		majSearchSuccess: 'La mise à jour de la recherche est en cours',
		updateOrder: {
			success: 'La position des données de la section à été mise à jour'
		},
		deleteField: {
			popin: {
				title: 'Suppression de la donnée {name}',
				content: 'Confirmez vous la suppression de la donnée {name} ?'
			},
			error: {
				isReferenced: 'Suppression impossible, car la donnée est référencée par les données suivantes:<br />{references}',
				generic: 'Une erreur est survenue lors de la suppression de la donnée.'
			}
		},
		donneeForm: {
			newTitle: 'Nouvelle donnée',
			fields: {
				sectionParent: 'Section parent',
				nomDonnee: 'Nom de la donnée ({langue})',
				reference: 'Référence de la donnée',
				type: 'Type de donnée',
				visibility: {
					check: 'La visibilité de la donnée est conditionnée par une valeur',
					reference: 'Référence de la donnée conditionnant la visibilité',
					code: 'Code de la valeur conditionnant la visibilité'
				},
				defaut: 'Valeur par défaut',
				confidentiable: 'L\'utilisateur peut déclarer cette donnée confidentielle',
				donneeAvancee: 'Cette donnée est une donnée avancée',
				help: 'Texte d\'aide pour le contributeur ({langue})',
				publier: 'Publier la donnée',
				select: {
					source: 'Liste de valeurs source',
					reference: 'Le contenu de cette liste dépend d\'une autre donnée',
					referenceParent: 'Référence de la donnée parent'
				},
				unite: {
					source: 'Unité SI associée',
					default: 'Unité par défaut'
				},
				table: {
					columnName: 'Nom de la colonne ({langue})',
					popin: {
						addTitle: 'Ajouter une colonne',
						editTitle: 'Editer une colonne'
					}
				}
			},
			fieldTypes: {
				TEXT: 'Texte',
				TEXT_MULTILINE: 'Texte multi-lignes',
				SELECT: 'Liste de choix simple',
				MULTI_SELECT: 'Liste de choix multiple',
				NUMBER: 'Nombre',
				NUMBER_UNIT: 'Nombre et unité',
				AMOUNT: 'Montant',
				DATE: 'Date',
				FILE: 'Document',
				LINK: 'Lien',
				LINK_PROPOSITION: 'Lien autre contenu',
				TABLE: 'Tableau',
				DUNS: 'Code Duns'
			},
			error: {
				isReferenced: 'Dépublication impossible, car la donnée est référencée par les données suivantes:<br />{references}',
				parentNotPublished: 'Publication impossible, car la section parent est dépubliée',
				referenceAlreadyExist: 'La référence de la donnée suivante existe déjà : {reference}',
				tableReferenceIdentical: 'Le tableau comporte des références identiques'
			}
		},
		traduction: 'Traduction'
	},
	additionalFilters: {
		title: 'Filtres supplémentaires',
		researchSections: 'Sections de recherche',
		researchFields: 'Champs de recherche',
		newSection: 'Nouvelle section',
		updateSection: 'Modifier la section',
		newField: 'Nouveau champ de recherche',
		updateField: 'Modifier un champ de recherche',
		reset: 'Annuler',
		save: 'Enregistrer',
		addField: 'Ajouter un champ',
		form: {
			name: 'Nom de la section (Anglais) *',
			subSegments: 'Valeur(s) du sous segment conditionnant la visibilité',
			publishedSection: 'Publier la section',
			publishedField: 'Publier le champ',
			nameFr: 'Nom de la section (Français)',
			nameEs: 'Nom de la section (Espagnol)',
			namePl: 'Nom de la section (Polonais)',
			reference: 'Référence de la donnée',
			alreadyUsedReference: 'Ce code est déjà utilisé dans une section de recherche',
			referenceNotFound: 'Référence introuvable'
		},
		add: {
			snackSuccess: 'Modifications enregistrées'
		}
	}
})
