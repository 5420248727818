import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getLikes } from './likeActions'

export const loadLikes = (WrappedComponent) => {
	const LikesInjector = ({ getLikes, ...props }) => {
		React.useEffect(() => {
			getLikes()
		}, [])

		return <WrappedComponent {...props} />
	}

	const actions = {
		getLikes
	}

	LikesInjector.propTypes = {
		getLikes: PropTypes.func
	}

	return connect(undefined, actions)(LikesInjector)
}
