import { createSelector } from 'reselect'

const getLocalState = state => state.model

export const getModelByType = (state, type) => createSelector(
	getLocalState,
	model => (model && model.data && model.data[type]) || {}
)(state)

export const getRequiredFieldsByType = (state, type) => createSelector(
	getLocalState,
	model => (model && model.requiredFields && model.requiredFields[type]) || []
)(state)

// Attention, utilisé uniquement dans le cas des sections de regroupement
export const getReferenceDependencies = createSelector(
	getLocalState,
	model => (model && model.referenceDependencies) || {}
)

export const getReferenceDependenciesBySection = (state, id) => createSelector(
	getLocalState,
	model => (((model && model.referenceDependencies) || {})[id]) || { sectionsChildren: [], fieldsChildren: [], fieldsAdvancedChildren: []}
)(state)
