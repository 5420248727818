import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { getStyles, Snackbar } from 'isotope-client'
import { isOpened } from 'isotope-client/components/menu/services/menuSelectors'
import * as menuActions from 'isotope-client/components/menu/services/menuActions'

import { ACCUEIL_PATH, colors, RECHERCHE_DOCUMENT_PATH } from '../../utils/constants'
import UserMenu from './menu/UserMenu'
import TendexBoxMenu from './menu/TendexBoxMenu'
import { SearchBarHeader } from './SearchBar'

import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Link from '../Link'

const menuWidth = 279
const appBarSmHeight = 56
const appBarHeight = 64

const style = theme => getStyles({
	appFrame: {
		zIndex: 1,
		overflow: 'hidden',
		position: 'relative',
		display: 'flex',
		width: '100%'
	},
	appBar: {
		flex: 1,
		backgroundColor: colors.appBarBackground,
		color: colors.appBarColor
	},
	contentFrame: {
		width: '100%',
		position: 'absolute',
		display: 'flex',
		overflow: 'auto',
		bottom: 0,
		left: 0,
		right: 0,
		[theme.breakpoints.down('sm')]: {
			top: appBarSmHeight
		},
		[theme.breakpoints.up('sm')]: {
			top: appBarHeight
		},
		backgroundColor: colors.foBackgroundColorPage
	},
	drawerPaper: {
		width: menuWidth,
		zIndex: 2
	},
	mainContent: {
		position: 'relative',
		flexGrow: 1,
		marginLeft: 0,
		width: '100%'
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20
	},
	title: {
		flex: 1
	},
	logo: {
		paddingRight: 24,
		cursor: 'pointer'
	}
})


export const Page = ({ opened, classes, children, location, menu, switchMenu, closeMenu, didomi }) => {

	const handleCloseMenu = () => closeMenu(menu)

	React.useEffect(() => {
		return () => closeMenu(menu)
	}, [menu])

	return (
		<React.Fragment>
			<div className={classes.appFrame}>
				<AppBar elevation={1} className={classes.appBar}>
					<Toolbar>
						{/*Menu Burger*/}
						<IconButton className={classes.menuButton} color="inherit" onClick={() => switchMenu(menu)}>
							<MenuIcon />
						</IconButton>

						{/*Logo tender Box*/}
						<Link to="/accueil">
							<img src="/img/tenderBox.png" className={classes.logo} alt="Tender Box" height={55} width="auto"/>
						</Link>


						{/*Bar de recherche dans le header*/}
						{location && location.pathname !== ACCUEIL_PATH && location.pathname !== RECHERCHE_DOCUMENT_PATH && <SearchBarHeader />}

						{/*Ici on met en place la brique user*/}
						<UserMenu didomi={didomi} />
					</Toolbar>
				</AppBar>
			</div>
			<div className={classes.contentFrame}>
				<Drawer
					variant="temporary"
					open={opened}
					onClose={handleCloseMenu}
					classes={{ paper: classes.drawerPaper }}
					ModalProps={{ keepMounted: true }}
				>
					<TendexBoxMenu code={menu} location={location} />
				</Drawer>
				<main className={classes.mainContent}>
					{children}
				</main>
				<Snackbar />
			</div>
		</React.Fragment>
	)
}

Page.propTypes = {
	classes: PropTypes.object,
	children: PropTypes.object,
	location: PropTypes.object,
	opened: PropTypes.bool,
	menu: PropTypes.string.isRequired,
	closeMenu: PropTypes.func,
	switchMenu: PropTypes.func,
	didomi: PropTypes.object

}

Page.defaultProps = {
	menu: 'fo'
}

const mapStateToProps = (state, { menu = 'fo' }) => ({
	opened: isOpened(state, menu)
})

const actions = {
	closeMenu: menuActions.closeMenu,
	switchMenu: menuActions.switchMenu
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(style, { name: 'page' })
)(Page)
