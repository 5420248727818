import React from 'react'
import { getUser } from './usersApi'

export const loadUser = (WrappedComponent) => {
	const InjectUser = ({ ...otherProps }) => {
		const match = otherProps.match
		const [user, setUser] = React.useState({})

		React.useEffect(() => {
			if (match && match.params && match.params.idUser) {
				getUser(match.params.idUser).then(setUser)
			}
		}, [])

		return <WrappedComponent user={user} {...otherProps} />
	}

	return (InjectUser)
}
