import { DEFAULT_LANGUAGE_ID } from '../../../../utils/constants'
import { FI_TRIPLET, FIELD_TYPE } from './documentConstants'

const validate = section => (values) => {
	const errors = {}
	if (values.fields && Object.keys(values.fields).length > 0) {
		// Filtrage sur le type montant
		// Dans le cas d'une duplication, on regarde le type de son parent
		const errorsFieldsAmount = Object.keys(values.fields).filter(key =>
			values.fields[key].type === FIELD_TYPE.AMOUNT
			|| (key.includes('#') && values.fields[key.substring(0, key.indexOf('#'))] && values.fields[key.substring(0, key.indexOf('#'))].type === FIELD_TYPE.AMOUNT)
		).reduce((acc, key) => {
			// Gestion particulière du field AMOUNT
			let amountError = {}

			if (values.fields[key].value
				|| values.fields[key].unite) {
				if (!values.fields[key].siValue) {
					amountError = {
						[key]: {
							fieldKey: values.fields[key].labelField,
							siValue: { id: 'global.errors.obligatoire' }
						}
					}
				}

				if (values.fields[key].value && !values.fields[key].unite) {
					amountError = {
						[key]: {
							...(amountError[key] || {}),
							fieldKey: values.fields[key].labelField,
							value: { id: 'global.errors.amountObligatoire' },
							unite: { id: 'global.errors.emptyMessageError' }
						}
					}
				} else if (values.fields[key].unite && !values.fields[key].value) {
					amountError = {
						[key]: {
							...(amountError[key] || {}),
							fieldKey: values.fields[key].labelField,
							value: { id: 'global.errors.amountObligatoireMontant' },
							unite: { id: 'global.errors.emptyMessageError' }
						}
					}
				}
				return { ...acc, ...amountError }
			}
			return acc
		}, {})

		if (Object.keys(errorsFieldsAmount).length > 0) {
			errors.fields = errorsFieldsAmount
		}
	}

	// Champs du document
	if (!values.title) {
		errors.title = { id: 'global.errors.obligatoire', fieldKey: 'document.entete.title', mustBeTranslated: true }
	}
	if (!values.zone) {
		errors.zone = { id: 'global.errors.obligatoire', fieldKey: 'document.entete.zone', mustBeTranslated: true }
	}
	if (!values.idLanguage) {
		errors.idLanguage = {
			id: 'global.errors.obligatoire',
			fieldKey: 'document.entete.language',
			mustBeTranslated: true
		}
	}
	if (!values.contributor) {
		errors.contributor = {
			id: 'global.errors.obligatoire',
			fieldKey: 'document.entete.contributor',
			mustBeTranslated: true
		}
	}

	FI_TRIPLET.forEach(({ fieldName }) => {
			const field = values.fields[fieldName]
			if (!field || !field.value || !field.value.length) {
				const error = {
					id: 'global.errors.obligatoire',
					mustBeTranslated: true
				}
				if (!field || !field.labelField) {
					const field = (section && section.fields.find(f => f.reference === fieldName)) || {}
					errors[fieldName] = {
						...error,
						fieldKey: getFieldName(field, values.selectedLanguage) || fieldName
					}
				} else {
					errors[fieldName] = {
						...error,
						fieldKey: field.labelField
					}
				}
			}
		}
	)
	return errors
}

const getFieldName = (section, idLanguage) => section.attributeData && (
	(section.attributeData[idLanguage] && section.attributeData[idLanguage].NAME && section.attributeData[idLanguage].NAME.value)
	|| (idLanguage !== DEFAULT_LANGUAGE_ID && getFieldName(section, DEFAULT_LANGUAGE_ID)))

export default validate
