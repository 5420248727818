import { createSelector } from 'reselect'

const getLocalState = state => state.favoris

export const getRechercheFavoris = createSelector(
	getLocalState,
	state => state.recherche
)

export const getDocumentFavoris = createSelector(
	getLocalState,
	state => state.document
)

export const isDocumentFavorited = (state, id) => createSelector(
	getDocumentFavoris,
	documents => documents && !!documents[id]
)(state)
