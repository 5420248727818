import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'
import { getItemsByListCode } from 'isotope-client/modules/valueList/services/valueListSelectors'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import Input from '../../../../../components/form/Input'
import Select from '../../../../../components/form/Select'
import OverflowTypography from '../../../../../components/OverflowTypography'
import { colors, DEFAULT_DEVISE_LABEL, DEFAULT_LANGUAGE_ID, DEFAULT_SU } from '../../../../../utils/constants'
import { getConversionBySuAndCode } from '../../../../common/conversion/conversionSelectors'
import { loadListFoByShortcut } from '../../../../common/valueList/valueListActions'
import { getUserLanguage, getUserSu } from '../../../user/services/userSelectors'
import { getAdditionalFilters, getListFieldMoDocuments } from '../../services/documentApi'
import { FIELD_TYPE, SELECT_OPTIONS_AVANCEE, SELECT_OPTIONS_BY_FIELD } from '../../utils/documentConstants'
import { normalizeDate, normalizeNumber, parseNumber } from '../../utils/documentUtils'
import Radio from '../../../../../components/form/Radio'
import AdditionalFiltersApplied from '../AdditionalFiltersApplied'
import { ListSubheader } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Autocomplete from '../../../../../components/form/Autocomplete'

const styles = theme => getStyles({
	padding: {
		paddingLeft: 10,
		paddingRight: 10
	},
	addFilter: {
		backgroundColor: 'white',
		border: '2px solid #00aec7',
		fontWeight: 500,
		marginTop: 20
	},
	searchKeyPath: {
		paddingLeft: 6,
		marginBottom: 5
	},
	link: {
		color: theme.palette.primary.main,
		float: 'right',
		fontWeight: 500,
		paddingBottom: 15,
		marginRight: 10
	},
	newFilterContainer: {
		marginTop: 10
	},
	newFilterForm: {
		alignContent: 'flex-start'
	},
	overflowTypo: {
		color: 'white'
	},
	form: {
		width: '100%'
	},
	containerAppliedFilters: {
		overflowY: 'auto',
		maxHeight: 550
	},
	subHeader: {
		lineHeight: 1,
		fontSize: 12,
		fontWeight: 'bold',
		color: '#88888a87',
		padding: '7px 0px 6px 20px'
	},
	containerInput: {
		border: '2px solid #88888a80',
		borderRadius: 5,
		padding: 10,
		marginLeft: 12
	},
	dataNameInput: {
		paddingLeft: 5,
		paddingBottom: 6
	},
	choices: {
		lineHeight: 1,
		fontSize: 14,
		paddingLeft: 30
	},
	searchOtherDataOption: {
		lineHeight: 1,
		fontSize: 14,
		paddingLeft: 15,
		paddingTop: 5,
		fontWeight: 'bold'
	},
	addButton: {
		width: 35,
		height: 35
	},
	cancelLink: {
		color: colors.text,
		textAlign: 'center',
		fontSize: 14,
		cursor: 'pointer',
		marginRight: 20
	},
	submitLink: {
		color: colors.primary,
		textAlign: 'center',
		fontSize: 14,
		cursor: 'pointer'
	},
	radio: {
		'& > div': {
			width: '100%',
			'& label': {
				marginTop: 7,
				'& > span': {
					fontSize: 14,
					color: colors.text
				}
			}
		}
	},
	titleFilter12: {
		fontSize: 12,
		color: colors.placeholder
	},
	titleFilter14: {
		fontSize: 14,
		color: colors.placeholder
	},
	devise: {
		fontSize: 14,
		paddingTop: 27,
		color: colors.text
	},
	searchValues: {
		alignContent: 'flex-start'
	}
})

const RESEARCH_ADVANCE_FORM = 'documentsSearchAvancee'

const validate = (values) => {
	const errors = {}
	if (!values.searchKey) {
		return { searchKey: { id: 'global.errors.obligatoire' } }
	}

	if (!values.searchType) {
		errors.searchType = { id: 'global.errors.obligatoire' }
	}
	if (!values.searchValue && values.searchKey.type !== FIELD_TYPE.FILE) {
		errors.searchValue = { id: 'global.errors.obligatoire' }
	}
	if (values.searchType && values.searchType === SELECT_OPTIONS_AVANCEE.BETWEEN) {
		if (values.searchValue && values.searchValue2 && values.searchValue > values.searchValue2) {
			if (values.searchKey.type === FIELD_TYPE.DATE) {
				errors.searchValue2 = { id: 'document.search.advance.newFilter.field.errorBetweenDate' }
			} else {
				errors.searchValue2 = { id: 'document.search.advance.newFilter.field.errorBetween' }
			}
		}
	}
	return errors
}

function setInitialValueRadios(type, change) {
	switch (type) {
		case FIELD_TYPE.DATE:
			change('searchType', SELECT_OPTIONS_AVANCEE.AFTER)
			break
		case FIELD_TYPE.AMOUNT:
		case FIELD_TYPE.NUMBER:
		case FIELD_TYPE.NUMBER_UNIT:
			change('searchType', SELECT_OPTIONS_AVANCEE.SUPERIOR)
			break
		default:
			change('searchType', null)
	}
}

const sortByPositionBO = (section) => {
	return section.moFieldAdvanceSearchJsonEsList.length > 0
		? section.moFieldAdvanceSearchJsonEsList
			//on filtre les éventuels fields a null et ceux pour lesquels les code/reference de elastic et de la base ne correspondent pas
			.filter(field => field != null)
			.filter(field => section.researchFields.find(researchField => researchField.reference === field.code))
			.sort((first, second) => section.researchFields.find(field => field.reference === first.code).position - section.researchFields.find(field => field.reference === second.code).position)
		: []
}

const RechercheDocumentFormAvancee = ({
          searchKey,
          searchType,
          sectionField,
          userLanguage,
          unite,
          rechercheAvancee,
          loadListFoByShortcut,
          initialize,
          change,
          handleSubmit,
          addField,
          updateField,
          removeField,
          canAddFilter,
          intl,
          classes,
          subSegments,
          selectTypeContenu,
          modifyFilterIndex,
          setModifyFilterIndex
      }) => {
	const [selectItems, setSelectItems] = React.useState([])

	const [fields, setFields] = React.useState([])

	const [searchOtherData, setSearchOtherData] = React.useState(false)

	React.useEffect(() => {
		if (modifyFilterIndex !== null && modifyFilterIndex < rechercheAvancee.length) {
			loadListFoByShortcut(rechercheAvancee[modifyFilterIndex].source, userLanguage)
				.then(({ payload }) => setSelectItems(payload))
		}
	}, [modifyFilterIndex])

	const getFields = () => {
		getAdditionalFilters(userLanguage, subSegments).then(res => res.flatMap(section => {
			return ([
				<ListSubheader key={section.researchSection.id} className={classes.subHeader}>{section.dataResearchSection.label}</ListSubheader>,
				sortByPositionBO(section).map(field => {
					return field && <MenuItem className={classes.choices} key={field.code}
					                          value={{
						                          ...field,
						                          label: field.label[userLanguage] || field.label[DEFAULT_LANGUAGE_ID],
						                          path: field.path[userLanguage] || field.path[DEFAULT_LANGUAGE_ID]
					                          }}>{field.label[userLanguage] || field.label[DEFAULT_LANGUAGE_ID]}</MenuItem>
				})
			])
		}).concat([<Divider variant="middle" />,
			<MenuItem key="SearchOtherData" value={{ type: FIELD_TYPE.OTHER_DATA }}>
				<Typography component="span" className={classes.searchOtherDataOption}>
					<FormattedMessage id="document.search.advance.newFilter.field.otherData" />
				</Typography>
			</MenuItem>
		])).then(setFields)
	}

	React.useEffect(() => {
		getFields()
	}, [])

	const getAutoCompleteOtherData = () => {
		if (searchOtherData) {
			return <Grid item xs={10}>
				<Field
					name="searchAutocomplete"
					label={intl.formatMessage({ id: `document.search.advance.newFilter.field.dataName` })}
					component={Autocomplete}
					getSuggestion={(inputLength, inputValue) => getListFieldMoDocuments({ 'query': inputValue, 'typeContenu': selectTypeContenu.map(option => option.code) }).then(fields => fields.map(field => ({
							...field, label: field.label[userLanguage] || field.label[DEFAULT_LANGUAGE_ID], path: field.path[userLanguage] || field.path[DEFAULT_LANGUAGE_ID]
						}))
					)}
					fromFilters
					minLength={3}
					onClickItem={value => {
						change('searchKey', value)
						// Dans le cas d'une source, on charge la value list
						if (value.source) {
							loadListFoByShortcut(value.source, userLanguage)
								.then(({ payload }) => setSelectItems(payload))
						}
						setInitialValueRadios(value.type, change)
					}}
				>
				</Field>
			</Grid>
		}
	}

	const getSelect = () => {
		if (searchKey.type !== FIELD_TYPE.OTHER_DATA) {
			return <Grid item xs={12}>
				<Grid item xs={12} className={classes.dataNameInput}>
					<Typography className={classes.titleFilter14}>{searchKey.label}</Typography>
				</Grid>
				<Grid item xs={12} className={classes.radio}>
					<Field
						name="searchType"
						component={Radio}
						onChange={() => {
							change('searchValue', null)
							change('searchValue2', null)
						}}
						choices={SELECT_OPTIONS_BY_FIELD[searchKey.type].map(choice => {
							return {
								value: choice,
								label: intl.formatMessage({ id: `document.search.advance.newFilter.field.${choice}` })
							}
						})}
					>
					</Field>
				</Grid>
			</Grid>
		}
	}

	const getInput = () => {
		const inputProps = {
			name: 'searchValue'
		}
		switch (searchKey.type) {
			case FIELD_TYPE.TEXT:
				return <Grid item xs={12}>
					<Field
						{...inputProps}
						placeholder={intl.formatMessage({ id: 'document.search.advance.newFilter.field.placeholderText' })}
						component={Input}
					/>
				</Grid>
			case FIELD_TYPE.SELECT:
			case FIELD_TYPE.MULTI_SELECT:
				return <Grid item xs={12}>
					<Field
						{...inputProps}
						component={Select}
						multiple
					>
						{selectItems.map(item => <MenuItem
							key={`recherche-avancee-select-${item.code}`}
							value={item.code}>{item.label}</MenuItem>)}
					</Field>
				</Grid>
			case FIELD_TYPE.NUMBER:
				if (searchType === SELECT_OPTIONS_AVANCEE.BETWEEN) {
					return <Grid item xs={12} className={classes.searchValues}><Grid item xs={6}>
						<Field
							{...inputProps}
							placeholder="0"
							format={normalizeNumber}
							parse={parseNumber}
							component={Input}
						/>
					</Grid>
						<Grid item xs={6}>
							<Field
								name="searchValue2"
								placeholder="0"
								format={normalizeNumber}
								parse={parseNumber}
								component={Input}
							/>
						</Grid>
					</Grid>
				}
				return <Grid item xs={12}>
					<Field
						{...inputProps}
						placeholder="0"
						format={normalizeNumber}
						parse={parseNumber}
						component={Input}
					/>
				</Grid>
			case FIELD_TYPE.NUMBER_UNIT:
			case FIELD_TYPE.AMOUNT:
				if (searchType === SELECT_OPTIONS_AVANCEE.BETWEEN) {
					return <Grid item container xs={12} className={classes.searchValues}>
						<Grid item xs={5}>
							<Field
								{...inputProps}
								placeholder="0"
								format={normalizeNumber}
								parse={parseNumber}
								component={Input}
							/>
						</Grid>
						<Grid item xs={5}>
							<Field
								name="searchValue2"
								placeholder="0"
								format={normalizeNumber}
								parse={parseNumber}
								component={Input}
							/>
						</Grid>
						<Grid item xs={2}>
							<OverflowTypography variant="body1" className={classes.devise}>
								{searchKey.type === FIELD_TYPE.NUMBER_UNIT ? unite.label : DEFAULT_DEVISE_LABEL}
							</OverflowTypography>
						</Grid>
					</Grid>
				}
				return <Grid item container xs={12} alignItems="center">
					<Grid item xs={10}>
						<Field
							{...inputProps}
							placeholder="0"
							format={normalizeNumber}
							parse={parseNumber}
							component={Input}
						/>
					</Grid>
					<Grid item xs={2}>
						<OverflowTypography variant="body1" className={classes.devise}>
							{searchKey.type === FIELD_TYPE.NUMBER_UNIT ? unite.label : DEFAULT_DEVISE_LABEL}
						</OverflowTypography>
					</Grid>
				</Grid>
			case FIELD_TYPE.DATE:
				if (searchType === SELECT_OPTIONS_AVANCEE.BETWEEN) {
					return <Grid container xs={12}>
						<Grid item xs={6}>
							<Field
								{...inputProps}
								type="date"
								component={Input}
								format={normalizeDate}
							/>
						</Grid>
						<Grid item xs={6}>
							<Field
								name="searchValue2"
								type="date"
								component={Input}
								format={normalizeDate}
							/>
						</Grid>
					</Grid>
				}
				return <Grid item xs={12}>
					<Field
						{...inputProps}
						type="date"
						component={Input}
						format={normalizeDate}
					/>
				</Grid>
			default:
				return <></>
		}
	}

	const addFilter = ({ searchKey, searchType, searchValue, searchValue2 }) => {
		let value = searchValue
		let valueLabel = undefined
		// Pour la gestion du mutliselect, on récupère les values et les stringify pour l'envoi
		if (searchKey.type === FIELD_TYPE.MULTI_SELECT || searchKey.type === FIELD_TYPE.SELECT) {
			let valueSelect = []
			let valueSelectLabel = []
			selectItems.forEach(item => {
				searchValue.forEach(val => {
					if(val === item.code){
						valueSelect.push(item.code)
						valueSelectLabel.push(item.label)
					}
				})
			})
			value = JSON.stringify(valueSelect)
			valueLabel = valueSelectLabel.join(', ')
		}
		if (searchValue2 != null) {
			value = [searchValue, searchValue2]
		}
		if (modifyFilterIndex || modifyFilterIndex === 0) {
			updateField({
				...searchKey,
				searchType,
				value,
				valueLabel,
				unite: searchKey.type === FIELD_TYPE.NUMBER_UNIT || searchKey.type === FIELD_TYPE.AMOUNT ? unite : undefined
			}, modifyFilterIndex)
		} else {
			addField({
				...searchKey,
				searchType,
				value,
				valueLabel,
				unite: searchKey.type === FIELD_TYPE.NUMBER_UNIT || searchKey.type === FIELD_TYPE.AMOUNT ? unite : undefined
			})
		}
		setSearchOtherData(false)
		setModifyFilterIndex(null)
		initialize(RESEARCH_ADVANCE_FORM, {})
	}

	return (
		<Grid container>
			<form onSubmit={(event) => {
				handleSubmit(addFilter)(event)
				event.stopPropagation()
			}}
			      className={classes.form}
			>
				<Grid container className={classes.padding}>
					<Grid container xs={6}>
						<Grid item xs={12}>
							<Grid item xs={12}>
								<Typography variant="h6">
									<FormattedMessage id="document.search.advance.appliedFilters" />
								</Typography>
							</Grid>
							<Grid item xs={10} className={classes.containerAppliedFilters}>
								{rechercheAvancee.length > 0
									? <AdditionalFiltersApplied rechercheAvancee={rechercheAvancee} fromPopin removeField={removeField} change={change} modifyFilterIndex={modifyFilterIndex} setModifyFilterIndex={setModifyFilterIndex} />
									: <Grid item>
										<Typography variant="body2">
											<FormattedMessage id="document.search.advance.appliedFiltersDescription" />
										</Typography>
									</Grid>}
							</Grid>
						</Grid>
					</Grid>
					<Grid container item xs={6} justify="center" className={classes.newFilterContainer}>
						<Grid container item xs={12} className={classes.newFilterForm}>
							<Grid item xs={10}>
								<Field
									name="sectionField"
									label={<FormattedMessage id="document.search.advance.newFilter.field.searchKey" />}
									component={Select}
									onChange={event => {
										setModifyFilterIndex(null)
										if (event.target.value) {
											if (event.target.value.type && event.target.value.type === FIELD_TYPE.OTHER_DATA) {
												change('searchKey', event.target.value)
												change('searchAutocomplete', null)
												setSearchOtherData(true)
											} else {
												change('searchKey', event.target.value)
												// Dans le cas d'une source, on charge la value list
												if (event.target.value.source) {
													loadListFoByShortcut(event.target.value.source, userLanguage)
														.then(({ payload }) => setSelectItems(payload))
												}
												setSearchOtherData(false)
												change('searchAutocomplete', null)
												setInitialValueRadios(event.target.value.type, change)
											}
										}
									}}
								>
									{fields}
								</Field>
							</Grid>
							{searchOtherData && getAutoCompleteOtherData()}
							{!!searchKey && searchKey.type !== FIELD_TYPE.OTHER_DATA && <Grid container item className={classes.containerInput}>
								<Grid item xs={12} className={classes.searchKeyPath}>
									<Typography variant="caption" className={classes.titleFilter12}>
										{intl.formatMessage({ id: `enums.documentType.${searchKey.moType}` }).toUpperCase()}{searchKey.path}
									</Typography>
								</Grid>
								{getSelect()}
								{getInput()}
								<Grid item container xs={12} direction="row-reverse">
									{canAddFilter && searchKey.type !== FIELD_TYPE.OTHER_DATA &&
										(<Grid container justify="flex-end">
											<Typography
												variant="body1"
												className={classes.cancelLink}
												component="span"
												onClick={() => {
													change('sectionField', null)
													change('searchKey', null)
													change('searchAutocomplete', null)
													setSearchOtherData(false)
												}}
											>
												<FormattedMessage id="global.buttons.annuler" />
											</Typography>
											<Typography
												variant="body1"
												className={classes.submitLink}
												component="span"
												onClick={() => handleSubmit(addFilter)()}
											>
												<FormattedMessage id="global.buttons.save_filter" />
											</Typography>
										</Grid>)
									}
								</Grid>
							</Grid>}
						</Grid>
					</Grid>
				</Grid>
			</form>
		</Grid>
	)
}

const mapStateToProps = (state, { initValues }) => {
	const searchKey = formValueSelector(RESEARCH_ADVANCE_FORM)(state, 'searchKey')
	const searchType = formValueSelector(RESEARCH_ADVANCE_FORM)(state, 'searchType')
	const searchValue = formValueSelector(RESEARCH_ADVANCE_FORM)(state, 'searchValue')
	const searchValue2 = formValueSelector(RESEARCH_ADVANCE_FORM)(state, 'searchValue2')
	const userSu = getUserSu(state)
	// Dans le cas d'une unité, on regarde par rapport à l'unité SI
	const unite = {
		label: '',
		coeff: 1
	}
	if (searchKey && searchKey.source && searchKey.unite) {
		const uniteList = getItemsByListCode(state)[searchKey.source] || []
		const uniteFound = uniteList.find(unite => unite.code === searchKey.unite)
		if (uniteFound) {
			unite.label = uniteFound.label
			// On affiche les libellés dans son système si
			if (userSu !== DEFAULT_SU) {
				const conversion = getConversionBySuAndCode(state, userSu, uniteFound.code)
				if (conversion) {
					unite.label = conversion.uniteLocale
					unite.coeff = conversion.coeff
				}
			}
		}
	}

	if (initValues) {
		return ({
			searchKey,
			searchType,
			searchValue,
			searchValue2,
			userLanguage: getUserLanguage(state),
			unite,
			canAddFilter: !!searchKey,
			initialValues: {
				searchKey: initValues.searchKey,
				searchType: initValues.searchType,
				searchValue: initValues.searchValue,
				searchValue2: (initValues && initValues.searchValue2) || undefined
			}
		})
	} else {
		return ({
			searchKey,
			searchType,
			searchValue,
			searchValue2,
			userLanguage: getUserLanguage(state),
			unite,
			canAddFilter: !!searchKey
		})
	}

}

const actions = {
	loadListFoByShortcut
}

RechercheDocumentFormAvancee.propTypes = {
	searchKey: PropTypes.object,
	userLanguage: PropTypes.string,
	unite: PropTypes.object,
	rechercheAvancee: PropTypes.array,
	canAddFilter: PropTypes.bool,
	classes: PropTypes.object,
	intl: PropTypes.object,
	change: PropTypes.func,
	reset: PropTypes.func,
	selectTypeContenu: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	handleSubmit: PropTypes.func,
	addField: PropTypes.func,
	removeField: PropTypes.func,
	removeAll: PropTypes.func
}

export default compose(
	connect(mapStateToProps, actions),
	injectIntl,
	reduxForm({
		form: RESEARCH_ADVANCE_FORM,
		validate
	}),
	withStyles(styles)
)(RechercheDocumentFormAvancee)
