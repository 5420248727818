import { SVG_ICONS, TYPE_CONTENU } from '../../../utils/constants'

// Contenu accueil
export const BLOCS_DETAIL = [
	{ key: 'propositionCommerciale', serverKey: 'Propositions', icon: SVG_ICONS.PROPOSITION_COMMERCIALE, enumType: TYPE_CONTENU.PROPOSITION_COMMERCIALE},
	{ key: 'savoirFaire', serverKey: 'SavoirFaire', icon: SVG_ICONS.SAVOIR_FAIRE , enumType: TYPE_CONTENU.SAVOIR_FAIRE},
	{ key: 'reference', serverKey: 'References',icon: SVG_ICONS.REFERENCE , enumType: TYPE_CONTENU.REFERENCE},
]

export const BLOC_VALIDATION_CORRESPONDANT = [
	{ key: 'contenuAValider', link: '/contenu-a-valider' },
	{ key: 'accesAValider', link: '/demandes' },
]

export const BLOC_VALIDATION_ADMIN = [
	{ key: 'contenuAPublier', link: '/contenu-a-publier' },
	{ key: 'accesAValiderAdmin', link: '/demandes' },
]

export const BLOC_INFOS = [
	{ key: 'presentation', link: 'https://www.google.com/url?q=https://docs.google.com/presentation/d/1w4zZI19bLgLX-Iw6VPsbGPyQSS208KqXSf8U6ywgV2A/present?slide%3Did.p&sa=D&source=hangouts&ust=1580477564751000&usg=AFQjCNFxj4rFeQCeTtB9VQGvziplVzoGkQ', external: true },
	{ key: 'contact', link: '/contact-form' },
	{ key: 'correspondant', link: '/correspondants' }
]
