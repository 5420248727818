import { createSelector } from 'reselect'
import { DEFAULT_DEVISE, DEFAULT_LANGUAGE_ID, DEFAULT_SU, DEFAULT_ZONE, USER_PREFERENCES } from '../../../../utils/constants'

export const getUser = state => state.user

export const getUserEmail = createSelector(
	getUser,
	user => user.email
)

export const getAuthorities = createSelector(
	getUser,
	user => user.authorities
)

export const getPreferences = createSelector(
	getUser,
	user => user.preferences || {}
)

export const getUserId = createSelector(
	getUser,
	user => user.id
)

export const getUserLanguage = createSelector(
	getPreferences,
	preferences => preferences[USER_PREFERENCES.LANGUE] || DEFAULT_LANGUAGE_ID
)

export const getUserSu = createSelector(
	getPreferences,
	preferences => preferences[USER_PREFERENCES.SU] || DEFAULT_SU
)

export const getUserZone = createSelector(
	getPreferences,
	preferences => preferences[USER_PREFERENCES.ZONE] || DEFAULT_ZONE
)

export const getUserDevise = createSelector(
	getPreferences,
	preferences => preferences[USER_PREFERENCES.DEVISE] || DEFAULT_DEVISE
)

export const getOpenVisitPopup = state => state.openVisitPopup
