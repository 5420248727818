import * as actions from './likeActions'

const initLikes = []
export const likes = (state = initLikes, action) => {
	switch (action.type) {
		case actions.GET_LIKE_SUCCESS:
			return action.likes
		case actions.HANDLE_LIKE_SUCCESS: {
			// Ajout d'un like
			if (!action.isDeleted) {
				return state.concat(action.documentId)
			}
			// Suppression d'un like
			const newState = [...state]
			if (action.isDeleted) {
				newState.splice(newState.indexOf(action.documentId), 1)
			}
			return newState
		}
		default:
			return state
	}
}
