import * as api from './additionalFiltersApi'

export const GET_RESEARCH_SECTION_REQUEST = 'GET_RESEARCH_SECTION_REQUEST'
export const GET_RESEARCH_SECTION_SUCCESS = 'GET_RESEARCH_SECTION_SUCCESS'
export const GET_RESEARCH_SECTION_ERROR = 'GET_RESEARCH_SECTION_ERROR'

export const getResearchSections = () => dispatch => {
	dispatch({ type: GET_RESEARCH_SECTION_REQUEST })
	return api.getAllAdditionalFilters()
		.then(sections => {
			dispatch({
				type: GET_RESEARCH_SECTION_SUCCESS,
				sections: sections
			})
			return sections
		})
		.catch(e => {
			dispatch({ type: GET_RESEARCH_SECTION_ERROR })
			throw e
		})
}

export const SET_SECTION_SELECTED = 'SET_SECTION_SELECTED'
export const setSectionSelected = (section) => dispatch => {
	dispatch({type: SET_SECTION_SELECTED, payload: section})
	return section
}

export const POST_RESEARCH_SECTION_REQUEST = 'POST_RESEARCH_SECTION_REQUEST'
export const POST_RESEARCH_SECTION_SUCCESS = 'POST_RESEARCH_SECTION_SUCCESS'
export const POST_RESEARCH_SECTION_ERROR = 'POST_RESEARCH_SECTION_ERROR'

export const postResearchSection = (values) => dispatch => {
	dispatch({ type: POST_RESEARCH_SECTION_REQUEST })
	return api.postResearchSection(values)
		.then(section => dispatch({
			type: POST_RESEARCH_SECTION_SUCCESS,
			payload: section
		}))
		.catch(e => {
			dispatch({ type: POST_RESEARCH_SECTION_ERROR })
			throw e
		})
}

export const POST_RESEARCH_FIELD_REQUEST = 'POST_RESEARCH_FIELD_REQUEST'
export const POST_RESEARCH_FIELD_SUCCESS = 'POST_RESEARCH_FIELD_SUCCESS'
export const POST_RESEARCH_FIELD_ERROR = 'POST_RESEARCH_FIELD_ERROR'

export const postResearchField = (values, idSection) => dispatch => {
	dispatch({ type: POST_RESEARCH_FIELD_REQUEST })
	return api.postResearchField(values, idSection)
		.then(field => dispatch({
			type: POST_RESEARCH_FIELD_SUCCESS,
			payload: field
		}))
		.catch(e => {
			dispatch({ type: POST_RESEARCH_FIELD_ERROR })
			throw e
		})
}

export const GET_RESEARCH_FIELD_SUCCESS = 'GET_RESEARCH_FIELD_SUCCESS'
export const GET_RESEARCH_FIELD_ERROR = 'GET_RESEARCH_FIELD_ERROR'
export const GET_RESEARCH_FIELD_REQUEST = 'GET_RESEARCH_FIELD_REQUEST'
export const getResearchFields = (idSection) => dispatch => {
	dispatch({ type: GET_RESEARCH_FIELD_REQUEST })
	return api.getFields(idSection)
		.then(res =>
			dispatch({
				type: GET_RESEARCH_FIELD_SUCCESS,
				fields: res
			}))
		.catch(e => {
			dispatch({ type: GET_RESEARCH_FIELD_ERROR })
			throw e
		})
}

export const REORDER_FIELDS = 'REORDER_FIELDS'
export const reorderFields = (source, destination) => dispatch => {
	return dispatch({ type: REORDER_FIELDS, payload: { source: source, destination: destination } })
}

export const DROP_RESEARCH_FIELD = 'DROP_RESEARCH_FIELD'
export const dropField = (index) => dispatch => {
	return dispatch({ type: DROP_RESEARCH_FIELD, payload: index })
}

export const DISPATCH_SAVE_ACTION = 'DISPATCH_SAVE_ACTION'
export const dispatchSaveResearchFields = () => dispatch => {
	return dispatch({ type: DISPATCH_SAVE_ACTION })
}

export const UNDO_CHANGES_FIELDS = 'UNDO_CHANGES_FIELDS'
export const resetFields = () => dispatch => {
	return dispatch({ type: UNDO_CHANGES_FIELDS })
}