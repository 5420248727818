import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import Checkbox from '../../../../../components/form/Checkbox'
import Popin from '../../../../../components/Popin'
import { getLanguagesWithoutDefault } from 'isotope-client/modules/langue/services/langueSelectors'
import { useForm } from 'react-final-form'
import Grid from '@material-ui/core/Grid'

const styles = () => getStyles({
	paddingText: {
		paddingLeft: 10,
		marginBottom: 15
	},
	button: {
		marginRight: 50
	},
	popinContent: {
		paddingTop: 10,
		paddingBottom: 5
	}
})

const TranslatePopin = ({ open, closePopin, languages, classes }) => {

	const [translateTo, setTranslateTo] = React.useState([])

	const form = useForm()

	React.useEffect(() => {
		if (open) {
			setTranslateTo(languages.map(language => language.id))
		}
	}, [open])

	const submit = () => {
		closePopin()
		if (!form.getState().hasValidationErrors) {
			form.change('translateTo', translateTo)
			return form.submit()
		}
	}

	const handleChange = idLanguage => event => {
		if (event.target.checked) {
			setTranslateTo([...translateTo, idLanguage])
		} else {
			setTranslateTo(translateTo.filter(id => id !== idLanguage))
		}
	}

	const actions = [
		<Grid container justify="center" key="buttons">
			<Button
				className={classes.button}
				color="inherit"
				variant="outlined"
				key="fermer"
				onClick={closePopin}
			>
				<FormattedMessage id="document.translate.close" />
			</Button>
			<Button
				key="valider"
				color="primary"
				variant="contained"
				type="submit"
				onClick={submit}
				disabled={translateTo.length === 0}
			>
				<FormattedMessage id="document.translate.send" />
			</Button>
		</Grid>
	]

	return <Popin
		contentStyleName={classes.popinContent}
		onClose={closePopin}
		open={open}
		actions={actions}
		title={<FormattedMessage id="document.translate.title" />}
		content={<>
			<Typography variant="body2" className={classes.paddingText}><FormattedMessage id="document.translate.ask" /></Typography>
			<Typography variant="body2" className={classes.paddingText}><FormattedMessage id="document.translate.description" /></Typography>
			{languages.map((language, key) => <Checkbox
				key={`language-${key}`}
				meta={{}}
				input={{
					value: translateTo.includes(language.id),
					onChange: handleChange(language.id)
				}}
				label={language.name}
			/>)}
		</>}
	/>
}

TranslatePopin.propTypes = {
	open: PropTypes.bool,
	languages: PropTypes.array,
	classes: PropTypes.object,
	closePopin: PropTypes.func,
	onSubmit: PropTypes.func
}


const mapStateToProps = (state, { selectedLanguage }) => ({
	languages: getLanguagesWithoutDefault(state).filter(language => language.id !== selectedLanguage)
})


export default compose(
	connect(mapStateToProps),
	withStyles(styles)
)(TranslatePopin)
