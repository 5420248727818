import React from 'react'
import { IntlProvider } from 'react-intl'
import messagesEn from './messages_en'
import messagesFr from './messages_fr'
import messagesEs from './messages_es'
import messagesPl from './messages_pl'
import { DEFAULT_LANGUAGE_ID } from '../utils/constants'

const messages = {
	fr: messagesFr,
	en: messagesEn,
	es: messagesEs,
	pl: messagesPl
}

const localesInTenderById = {
	1: 'fr',
	2: 'en',
	3: 'es',
	4: 'pl'
}

const formats = {
	date: {
		datetime: {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		}
	}
}

export const IntlContext = React.createContext({})

export const IntlProviderWrapper = ({ children }) => {

	const [intl, setIntl] = React.useState({
		locale: localesInTenderById[DEFAULT_LANGUAGE_ID],
		messages: messages[localesInTenderById[DEFAULT_LANGUAGE_ID]]
	})

	const changeLanguage = idLocale => {
		const locale = localesInTenderById[idLocale] || localesInTenderById[DEFAULT_LANGUAGE_ID]
		setIntl({
			locale,
			messages: messages[locale]
		})
	}

	const contextValues = {
		changeLanguage
	}

	return <IntlContext.Provider value={contextValues}>
		<IntlProvider
			locale={intl.locale}
			key={intl.locale}
			messages={intl.messages}
			formats={formats}
			defaultLocale={localesInTenderById[DEFAULT_LANGUAGE_ID]}
			defaultMessages={messages[localesInTenderById[DEFAULT_LANGUAGE_ID]]}
			defaultsFormats={formats}
		>
			{children}
		</IntlProvider>
	</IntlContext.Provider>
}
