import React from 'react'
import PropTypes from 'prop-types'
import MuiSvgIcon from '@material-ui/core/SvgIcon'

const SvgIcon = ({ path, viewBox, color, fontSize, className }) => {
	return <MuiSvgIcon color={color} fontSize={fontSize} className={className} viewBox={viewBox}>
		<path d={path} />
	</MuiSvgIcon>
}

SvgIcon.propTypes = {
	path: PropTypes.string.isRequired,
	viewBox: PropTypes.string,
	color: PropTypes.string,
	fontSize: PropTypes.string,
	className: PropTypes.string
}

SvgIcon.defaultProps = {
	path: '',
	viewBox: '0 0 24 24',
	color: 'primary',
	fontSize: 'small',
	className: ''
}

export default SvgIcon

