import { Grid } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import HelpIcon from '@material-ui/icons/HelpOutline'
import { getStyles } from 'isotope-client'
import moment from 'moment/moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Field, useField } from 'react-final-form'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Input from '../../../../components/form/Input'
import RichText from '../../../../components/form/RichText'
import { hasAccess } from '../../../../components/security/AccessChecker'
import { DEFAULT_LANGUAGE_ID, PROFILS } from '../../../../utils/constants'
import * as userSelectors from '../../user/services/userSelectors'
import { ATTRIBUTE_DATA_MO_FIELD, FIELD_TYPE } from '../utils/documentConstants'
import { getFieldName, normalizeDate, normalizeNumber, parseNumber } from '../utils/documentUtils'
import DocumentAmountField from './fields/DocumentAmountField'
import DocumentDriveField from './fields/DocumentDriveField'
import DocumentPropositionField from './fields/DocumentPropositionField'
import DocumentRowField from './fields/DocumentRowField'
import DocumentSelectField from './fields/DocumentSelectField'
import DocumentTableField from './fields/DocumentTableField'
import DocumentUnitField from './fields/DocumentUnitField'
import { SpyField } from './SpyField'
import DatasetHandler from './DatasetHandler'
import DocumentDunsField from './fields/DocumentDunsField';

const styles = theme => getStyles({
	tooltipRichText: {
		marginTop: 25
	},
	tooltipTable: {
		paddingLeft: 10
	},
	confidentialRichText: {
		marginTop: 13
	},
	selectedItem: {
		backgroundColor: 'rgba(0, 0, 0, 0.08) !important'
	},
	titleLink: {
		paddingLeft: 10
	},
	link: {
		border: `${theme.palette.placeholder} 1px dashed`,
		marginBottom: 20
	},
	consultationLink: {
		color: theme.palette.primary.main,
		fontWeight: 400
	},
	consultationRichText: {
		color: '#55555a',
		fontSize: 14,
		fontFamily: 'Roboto',
		fontWeight: 400,
		lineHeight: 1.5
	},
	consultationContainer: {
		paddingBottom: 24
	}
})

/**
 * Composant qui représente l'ensemble des fields possibles dans un document
 * Affichage du field, de la tooltip d'aide (si activée) et de la checkbox confidentielle (si activée)
 */
const InternalField = ({
	field,
	addSectionInMap,
	intl,
	classes,
	selectedLanguage,
	duplicatedNumber,
	documentType,
	consultation,
	isAdmin,
	isCorrespondantValid,
	isMemberValid,
	userEmail,
	documentContributor,
	workflow
}) => {
	const data = field.attributeData[selectedLanguage] || field.attributeData[DEFAULT_LANGUAGE_ID]
	// On récupère la value pour les fields simples en édition
	// En consultation, on récupère le field avec le confidential
	const fieldName = `${getFieldName(field, duplicatedNumber)}${field.type === FIELD_TYPE.AMOUNT || field.type === FIELD_TYPE.DUNS || field.type === FIELD_TYPE.LINK || consultation ? '' : '.value'}`
	const fieldObject = useField(fieldName)

	const initialValues = fieldObject.input.value

	// Le champ est visible en consultation si une valeur est présente et :
	// - je suis admin
	// - je suis le contributeur
	// - je suis correspondant et je possède le triplet
	// - je suis membre facilitateur sur de document
	// - le champ n'est pas confidentiel
	if (!consultation || (consultation && initialValues &&
		(isAdmin
			|| isCorrespondantValid
			|| isMemberValid
			|| (documentContributor === userEmail)
			|| !initialValues.confidential
		))) {
		const fieldProps = {
			value: initialValues,
			name: fieldName,
			label: data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] && `${data[ATTRIBUTE_DATA_MO_FIELD.NAME].value} ${field.required ? '*' : ''}`
		}

		addSectionInMap(field && getFieldName(field, duplicatedNumber))

		const fieldsPropsValue = fieldProps && fieldProps.value && fieldProps.value.value

		switch (field.type) {
			case FIELD_TYPE.TEXT: {
				if (consultation) {
					if (!fieldsPropsValue || fieldsPropsValue.length === 0) {
						return <></>
					}
					return <Grid item container xs={12} className={classes.consultationContainer}>
						<Grid item xs={4}>
							<Typography variant="caption">{fieldProps.label}</Typography>
						</Grid>
						<Grid item container xs={8}>
							<Typography variant="body1">{fieldsPropsValue}</Typography>
						</Grid>
					</Grid>
				} else {
					return <DocumentRowField field={field} duplicatedNumber={duplicatedNumber} attData={data}>
						<Grid item xs={8}><Field
							{...fieldProps}
							parse={(value) => value || ''}
							component={Input}
							autoComplete="off"
						/></Grid>
					</DocumentRowField>
				}
			}
			case FIELD_TYPE.TEXT_MULTILINE: {
				if (consultation) {
					if (!fieldsPropsValue || fieldsPropsValue.length === 0) {
						return <></>
					}
					return <Grid item container xs={12} className={classes.consultationContainer}>
						<Grid item xs={4}>
							<Typography variant="caption">{fieldProps.label}</Typography>
						</Grid>
						<Grid item container xs={8} className={classes.consultationRichText}>
							<div dangerouslySetInnerHTML={{ __html: fieldsPropsValue }} />
						</Grid>
					</Grid>
				} else {
					return <DocumentRowField field={field} duplicatedNumber={duplicatedNumber} attData={data} className={classes.tooltipRichText}>
						<Grid item xs={8}>
							<Field
								{...fieldProps}
								component={RichText}
								parse={(value) => (value) || ''}
								format={(value) => (value) || ''}
							/>
						</Grid>
					</DocumentRowField>
				}
			}
			case FIELD_TYPE.NUMBER: {
				if (consultation) {
					if (!fieldsPropsValue) {
						return <></>
					}
					return <Grid item container xs={12} className={classes.consultationContainer}>
						<Grid item xs={4}>
							<Typography variant="caption">{fieldProps.label}</Typography>
						</Grid>
						<Grid item container xs={8}>
							<Typography variant="body1">{fieldsPropsValue}</Typography>
						</Grid>
					</Grid>
				} else {
					addSectionInMap(fieldProps.name)
					return <DatasetHandler field={field} duplicatedNumber={duplicatedNumber}>
						<DocumentRowField field={field} duplicatedNumber={duplicatedNumber} attData={data}>
							<Grid item xs={8}>
								<Field
									{...fieldProps}
									component={Input}
									parse={parseNumber}
									format={normalizeNumber}
								/>
							</Grid>
						</DocumentRowField>
					</DatasetHandler>

				}
			}
			case FIELD_TYPE.NUMBER_UNIT: {
				if (consultation) {
					return <DocumentUnitField
						inputName={fieldProps.name}
						duplicatedNumber={duplicatedNumber}
						initialValues={fieldProps.value}
						consultation={consultation}
						field={field}
						selectedLanguage={selectedLanguage}
					/>
				} else {
					return <DocumentUnitField
							inputName={fieldProps.name}
							consultation={consultation}
							field={field}
							selectedLanguage={selectedLanguage}
							duplicatedNumber={duplicatedNumber}
							attData={data}
						/>
				}
			}
			case FIELD_TYPE.DUNS: {
				if (consultation) {
					return <DocumentDunsField
						inputName={fieldProps.label}
						duplicatedNumber={duplicatedNumber}
						initialValues={fieldProps.value}
						consultation={consultation}
						field={field}
						selectedLanguage={selectedLanguage}
					/>
				} else {
					return <DocumentDunsField
						inputName={fieldProps.name}
						consultation={consultation}
						initialValues={initialValues}
						field={field}
						selectedLanguage={selectedLanguage}
						duplicatedNumber={duplicatedNumber}
					/>
				}
			}
			case FIELD_TYPE.DATE: {
				if (consultation) {
					if (!fieldsPropsValue) {
						return <></>
					}
					return <Grid item container xs={12} className={classes.consultationContainer}>
						<Grid item xs={4}>
							<Typography variant="caption">{fieldProps.label}</Typography>
						</Grid>
						<Grid item container xs={8}>
							{selectedLanguage === DEFAULT_LANGUAGE_ID ?
								<Typography variant="body1">{moment(fieldsPropsValue).format('MM/DD/YYYY')}</Typography> :
								<Typography variant="body1">{moment(fieldsPropsValue).format('DD/MM/YYYY')}</Typography>}
						</Grid>
					</Grid>
				} else {
					return <DocumentRowField field={field} duplicatedNumber={duplicatedNumber} attData={data}>
						<Grid item xs={8}>
							<Field
								{...fieldProps}
								component={Input}
								type="date"
								InputLabelProps={{
									shrink: true
								}}
								format={normalizeDate}
							/>
						</Grid>
					</DocumentRowField>
				}
			}
			case FIELD_TYPE.SELECT:
			case FIELD_TYPE.MULTI_SELECT: {
				if (consultation) {
					return <DocumentSelectField
						consultation
						field={field}
						fieldProps={fieldProps.value}
						selectedLanguage={selectedLanguage}
						duplicatedNumber={duplicatedNumber}
					/>
				} else {
					return <DocumentRowField field={field} duplicatedNumber={duplicatedNumber} attData={data}>
						<Grid item xs={8}>
							<DocumentSelectField
								consultation={false}
								field={field}
								fieldProps={fieldProps}
								selectedLanguage={selectedLanguage}
								duplicatedNumber={duplicatedNumber}
								workflow={workflow}
								isMemberValid={isMemberValid}
							/>
						</Grid>
					</DocumentRowField>
				}
			}
			case FIELD_TYPE.LINK_PROPOSITION: {
				if (consultation) {
					const linkProps = fieldProps.value
					if (!linkProps || !linkProps.value) {
						return <></>
					}
					return <DocumentPropositionField
						initialValues={fieldsPropsValue && fieldsPropsValue.toString()}
						consultation={consultation}
						field={field}
						duplicatedNumber={duplicatedNumber}
						selectedLanguage={selectedLanguage}
					/>
				} else {
					return <DocumentRowField field={field} duplicatedNumber={duplicatedNumber} attData={data}>
						<Grid item xs={8}>
							<DocumentPropositionField
								initialValues={fieldsPropsValue}
								consultation={consultation}
								field={field}
								selectedLanguage={selectedLanguage}
								duplicatedNumber={duplicatedNumber}
							/>
						</Grid>
					</DocumentRowField>
				}
			}
			case FIELD_TYPE.LINK: {
				if (consultation) {
					const linkProps = fieldProps.value
					if (!linkProps || !linkProps.value || !linkProps.libelle) {
						return <></>
					}
					return <Grid item container xs={12} className={classes.consultationContainer}>
						<Grid item xs={4}>
							<Typography variant="caption">{fieldProps.label}</Typography>
						</Grid>
						<Grid item container xs={8}>
							<Typography variant="body1">
								<a target="_blank" rel="noopener noreferrer" className={classes.consultationLink} href={linkProps.value}>
									{linkProps.libelle}
								</a>
							</Typography>
						</Grid>
					</Grid>
				} else {
					return <Grid container className={classes.link}>
						<Grid item xs={12}>
							<Typography variant="caption" className={classes.titleLink}>
								{data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] && `${data[ATTRIBUTE_DATA_MO_FIELD.NAME].value} ${field.required ? '*' : ''}`}
							</Typography>
						</Grid>
						<DocumentRowField field={field} duplicatedNumber={duplicatedNumber} attData={data}>
							<Grid item xs={8}>
								<Field
									name={`${fieldName}.libelle`}
									label={intl.formatMessage({ id: 'document.field.libelleLink' })}
									component={Input}
								/>
							</Grid>
						</DocumentRowField>
						<Grid item xs={8}>
							<Field
								name={`${fieldName}.value`}
								label={intl.formatMessage({ id: 'document.field.urlLink' })}
								component={Input}
							/>
						</Grid>
					</Grid>
				}
			}
			case FIELD_TYPE.TABLE: {
				if (consultation) {
					return <DocumentTableField
						field={field}
						duplicatedNumber={duplicatedNumber}
						initialValues={fieldsPropsValue}
						consultation
					/>
				} else {
					return <Grid container alignItems="flex-start">
						<Grid item xs={12}>
							<Typography
								variant="caption"
								className={classes.titleLink}
							>
								{data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] && `${data[ATTRIBUTE_DATA_MO_FIELD.NAME].value} ${field.required ? '*' : ''}`}
							</Typography>
						</Grid>
						<Grid item xs={11} className={classes.link}><DocumentTableField
							field={field}
							selectedLanguage={selectedLanguage}
							duplicatedNumber={duplicatedNumber}
							initialValues={fieldProps.value !== '' ? fieldProps.value : undefined}
							consultation={false}
						/></Grid>
						<Grid item xs={1} className={classes.tooltipTable}>
							{data && data[ATTRIBUTE_DATA_MO_FIELD.HELP] &&
							<Tooltip title={data[ATTRIBUTE_DATA_MO_FIELD.HELP].value} placement="right">
								<HelpIcon />
							</Tooltip>
							}
						</Grid>
					</Grid>
				}
			}
			case FIELD_TYPE.AMOUNT: {
				return <DocumentAmountField
					inputName={fieldProps.name}
					initialValues={fieldProps.value !== '' ? fieldProps.value : undefined}
					consultation={consultation}
					field={field}
					selectedLanguage={selectedLanguage}
					duplicatedNumber={duplicatedNumber}
				/>
			}
			case FIELD_TYPE.FILE: {
				return <DocumentDriveField
					field={field}
					selectedLanguage={selectedLanguage}
					duplicatedNumber={duplicatedNumber}
					initialValues={initialValues.value || []}
					consultation={consultation}
					documentType={documentType}
				/>
			}
			default:
				return <React.Fragment />
		}
	} else if (consultation && initialValues &&
		!(isAdmin
			|| isCorrespondantValid
			|| isMemberValid
			|| (documentContributor === userEmail)
			|| !initialValues.confidential
		)) {
		return <Grid item container xs={12} className={classes.consultationContainer}>
			<Grid item xs={4}>
				<Typography
					variant="caption">{data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] && `${data[ATTRIBUTE_DATA_MO_FIELD.NAME].value} `}</Typography>
			</Grid>
			<Grid item container xs={8}>
				<Typography variant="body1">********</Typography>
			</Grid>
		</Grid>
	}

	return null
}

const DocumentField = (props) => {
	const { field, selectedLanguage, duplicatedNumber } = props
	return <SpyField part={field} selectedLanguage={selectedLanguage} duplicatedNumber={duplicatedNumber}>
		<Grid container alignItems="center">
			<InternalField{...props} />
		</Grid>
	</SpyField>

}

const mapStateToProps = (state) => {
	const authorities = userSelectors.getAuthorities(state)
	const isAdmin = hasAccess(PROFILS.ADMIN, authorities)
	const userZone = userSelectors.getUserZone(state)
	const userEmail = userSelectors.getUserEmail(state)

	return ({
		isAdmin,
		userZone,
		userEmail
	})
}

DocumentField.propTypes = {
	field: PropTypes.object.isRequired,
	addSectionInMap: PropTypes.func.isRequired,
	intl: PropTypes.object,
	classes: PropTypes.object,
	selectedLanguage: PropTypes.string,
	documentType: PropTypes.string,
	isElementVisible: PropTypes.bool,
	duplicatedNumber: PropTypes.number,
	consultation: PropTypes.bool,
	isCorrespondantValid: PropTypes.bool,
	isMemberValid: PropTypes.bool,
	userEmail: PropTypes.string,
	isAdmin: PropTypes.bool,
	documentContributor: PropTypes.string // Contributeur initial du document
}

export default compose(
	connect(mapStateToProps),
	injectIntl,
	withStyles(styles)
)(DocumentField)
