import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Grid, withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'
import CenteredLayout from '../../../components/layout/CenteredLayout'
import Paper from '@material-ui/core/Paper'
import { getLocalizedEntityWithDefaultValues } from 'isotope-client-cms'
import { getUserLanguage } from '../user/services/userSelectors'
import { getLanguagesWithoutDefault } from 'isotope-client/modules/langue/services/langueSelectors'

const styles = () => getStyles({
	paper: {
		padding: '20px 30px 20px 30px',
		minHeight: 500
	}
})

const PresentationPage = ({ selectedLanguage, classes }) => {
	const [inUseContent, setInUseContent] = React.useState({ unSet: true })

	React.useEffect(() => {
		if (inUseContent.unSet && selectedLanguage) {
			getLocalizedEntityWithDefaultValues('tenderbox', {}, selectedLanguage).then(setInUseContent)
		}
	}, [inUseContent.unSet, selectedLanguage])

	return <CenteredLayout>
		<Typography variant="h1">{inUseContent.titre}</Typography>
		<Grid container>
			<Grid item xs={12} sm={9}>
				<Paper className={classes.paper}>
					<Typography variant="body2" align="justify" dangerouslySetInnerHTML={{ __html: inUseContent.texte }} />
				</Paper>
			</Grid>
		</Grid>
	</CenteredLayout>
}

const mapStateToProps = state => {
	const language = getLanguagesWithoutDefault(state).find(language => language.id === getUserLanguage(state))

	return ({
		selectedLanguage: !!language ? language.codeLang : 'en'
	});
}

PresentationPage.propTypes = {
	selectedLanguage: PropTypes.string,
	classes: PropTypes.object
}

export default compose(
	connect(mapStateToProps),
	withStyles(styles)
)(PresentationPage)
