import { CLOSE_VISIT_POPUP, OPEN_VISIT_POPUP } from './userActions'

export const openVisitPopup = (state = false, action) => {
	switch (action.type) {
		case OPEN_VISIT_POPUP:
			return true
		case CLOSE_VISIT_POPUP:
			return false
		default:
			return state
	}
}
