import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FilledInput from '@material-ui/core/FilledInput'
import FormHelperText from '@material-ui/core/FormHelperText'
import MuiSelect from '@material-ui/core/Select'
import { getStyles } from 'isotope-client'
import mapError from './mapError'
import { toI18N } from '../../utils/utils'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'

const styles = () => getStyles({
	select: {
		width: '100%'
	},
	container: {
		paddingRight: 10,
		paddingLeft: 10,
		marginBottom: 15
	},
	input: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
	}
})

const joinNode = (elements, separator) => {
	let elementsArray = elements
	if (!Array.isArray(elementsArray)) {
		elementsArray = [elements]
	}

	return elementsArray.map((element, index) => <React.Fragment
		key={index}>{element}{index !== elementsArray.length - 1 && separator}</React.Fragment>)
}

const MultiSelect = ({ input: { value: initialValue, active, onChange, ...inputProps }, meta: { error, touched }, withDefault, id, label, children, disabled, classes, containerClassName }) => {
	const [value, setValue] = React.useState((initialValue && initialValue.length) ? initialValue : [0])

	React.useEffect(() => {
		setValue((initialValue && initialValue.length) ? initialValue : [0])
	}, [initialValue])

	const handleChange = (event) => {
		const values = event.target.value
		const size = values.length
		if(!size){
			setValue([0])
			return
		}
		if(value[0] === 0 && size >= 2){
			values.shift()
		}
		setValue(values)
		onChange(event)
	}

	// look into the children for the selected option and get it's label
	const getLabel = (value) => {
		const child = children && children.find(c => c.props.value === value)
		return child && child.props.children
	}

	return (<div className={classnames(classes.container, containerClassName)}>
		<FormControl variant="filled" className={classes.select} error={touched && !!error}>
			{!!label && <InputLabel htmlFor={id}>{label}</InputLabel>}
			<MuiSelect
				value={value}
				disableUnderline={disabled}
				disabled={disabled}
				input={<FilledInput {...inputProps} className={classes.input} />}
				id={id}
				onChange={handleChange}
				variant="filled"
				multiple
				MenuProps={{
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left'
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left'
					},
					getContentAnchorEl: null
				}}
				renderValue={(selected) => {
					if(selected.length && selected[0] !== 0) {
						return joinNode(value.map(getLabel), ', ')
					}
					return withDefault ? <FormattedMessage id="document.search.default" /> : ""
				}}
			>
				{withDefault && <MenuItem key={0} style={{display: "none"}} value={0}>
					<FormattedMessage id="document.search.default" />
				</MenuItem>}
				{children}
			</MuiSelect>
			{typeof error !== 'undefined' && touched && <FormHelperText>{toI18N(error)}</FormHelperText>}
		</FormControl>
	</div>)
}

MultiSelect.propTypes = {
	input: PropTypes.object,
	meta: PropTypes.object,
	classes: PropTypes.object,
	containerClassName: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	id: PropTypes.string,
	children: PropTypes.node,
	disabled: PropTypes.bool,
	withDefault: PropTypes.bool
}

MultiSelect.defaultProps = {
	containerClassName: ''
}

export default compose(
	mapError,
	withStyles(styles)
)(MultiSelect)

