import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { FormattedHTMLMessage, FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { Form, FormSpy } from 'react-final-form'
import Popin from '../../../../../components/Popin'
import Button from '../../../../../components/Button'
import arrayMutators from 'final-form-arrays'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import EditionDonneePartieParametrage from './EditionDonneePartieParametrage'
import EditionDonneePartieTraduction from './EditionDonneePartieTraduction'
import { injectLangues } from '../../../../common/langue/langueInjector'
import { DEFAULT_LANGUAGE_ID } from '../../../../../utils/constants'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import { ATTRIBUTE_DATA_MO_FIELD, FIELD_TYPE } from '../../../../fo/document/utils/documentConstants'

const styles = () => getStyles({
	popinResize: {
		maxWidth: 900
	},
	error: {
		paddingLeft: 10,
		marginBottom: 20
	}
})

const EditionDonneePopin = ({ open, initValues, closePopin, saveField, type, intl, sectionsList, langues, id, title, errorField, sectionPath, classes }) => {
	const defaultLanguage = langues.find(langue => langue.id === DEFAULT_LANGUAGE_ID)
	const languagesWithoutDefault = langues.filter(langue => langue.id !== DEFAULT_LANGUAGE_ID)

	const isEdition = !!id

	return <Form
		subscription={{ invalid: true, submitFailed: true }}
		onSubmit={saveField}
		validateOnBlur
		mutators={{ ...arrayMutators }}
		initialValues={initValues}
	>
		{({ handleSubmit }) =>
			<form onSubmit={handleSubmit}>
				<Popin
					dialogClasses={{ paper: classes.popinResize }}
					onClose={closePopin}
					open={open}
					actions={[
						<Button
							color="inherit"
							variant="outlined"
							key="fermer"
							onClick={closePopin}
						>
							<FormattedMessage id="global.buttons.annuler" />
						</Button>,
						<FormSpy subscription={{ pristine: true, submitting: true }} key="formSpyBtn">
							{({ pristine, submitting }) =>
								<Button
									key="valider"
									color="primary"
									variant="contained"
									type="submit"
									disabled={pristine || submitting}
									loading={submitting}
									onClick={handleSubmit}
								>
									<FormattedMessage id="global.buttons.save" />
								</Button>
							}
						</FormSpy>
					]}
					title={`${intl.formatMessage({ id: `parametrage.typeContenu.${type}` })} : ${isEdition ? title : intl.formatMessage({ id: 'parametrage.donneeForm.newTitle' })}`}
					content={<>
						{errorField && <Typography color="error" className={classes.error}><FormattedHTMLMessage {...errorField} /></Typography>}
						<Grid container justify="center">
							<EditionDonneePartieParametrage
								sectionsList={sectionsList}
								initParentSection={initValues.parentSection}
								defaultLanguage={defaultLanguage}
								otherLanguages={languagesWithoutDefault}
								isEdition={isEdition}
								uniteCodeList={!!initValues && initValues.type === FIELD_TYPE.NUMBER_UNIT ? initValues.attributeData[ATTRIBUTE_DATA_MO_FIELD.UNITE_LIST] : undefined}
								sectionPath={sectionPath}
								type={type}
							/>
							<Typography variant="h3">
								<FormattedMessage id={`parametrage.traduction`} />
							</Typography>
							<EditionDonneePartieTraduction langues={languagesWithoutDefault} />
						</Grid>
					</>
					}
				/>
			</form>}
	</Form>
}


EditionDonneePopin.propTypes = {
	open: PropTypes.bool,
	initValues: PropTypes.object,
	id: PropTypes.string,
	title: PropTypes.string,
	closePopin: PropTypes.func,
	saveField: PropTypes.func,
	type: PropTypes.string,
	intl: intlShape,
	sectionsList: PropTypes.object,
	langues: PropTypes.array,
	errorField: PropTypes.string,
	sectionPath: PropTypes.array,
	classes: PropTypes.object
}

export default compose(
	injectIntl,
	injectLangues,
	withStyles(styles)
)(EditionDonneePopin)
