import React from 'react'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { Box, Checkbox, FormControlLabel } from '@material-ui/core'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { DEFAULT_LANGUAGE_ID, ETATS_CHECKBOXES_COLUMNS } from '../../../../utils/constants'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classnames from 'classnames'
import Collapse from '@material-ui/core/Collapse'
import { connect } from 'react-redux'
import * as tabDocumentSelector from '../services/tabDocumentSelector'
import ItemFieldTabDocument from './ItemFieldTabDocument'
import { updateChecked, updateCheckedAdvanced, updateStatusCollapse } from '../services/tabDocumentActions'
import { ATTRIBUTE_DATA_MO_SECTION } from '../../document/utils/documentConstants'
import { FormattedMessage } from 'react-intl'
import * as modelSelectors from '../../../common/model/modelSelectors'
import { colors } from '../../../../utils/constants'

const styles = (theme) => getStyles({
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	},
	collapse: {
		width: '100%',
		borderLeft: `2px solid ${colors.primary}`,
		// boxShadow: '-12px -9px 0px -10px #00aec7',
		margin : '-16px 0 0 9px'
	},
	formControlLabel: {
		marginTop: '10px',
		position: 'relative',
		'&:before': {
			backgroundColor: colors.primary ,
			width: '21px',
			content: 'no-open-quote',
			display: 'block',
			position:'absolute',
			left : '-9px',
			top : '50%',
			height : '2px'
		}
	},
	formControlLabelRoot: {
		marginTop: '10px',
	}
})

/**
 * Composant représentant une section et ses enfants dans la popin de selection
 * des colonnes à afficher pour le tableau de modification de documents
 */
const InternalItemSectionTabDocument = ({
	section,
	parentIds,
	selectedLanguage,
	classes,
	isChecked,
	isAdvancedChecked,
	isCollapsed,
	sectionDependencies,
	updateChecked,
	updateCheckedAdvanced,
	updateStatusCollapse
}) => {
	const [isCollapse, setIsCollapse] = React.useState(isCollapsed? isCollapsed : false)
	const [isAdvancedCollapse, setIsAdvancedCollapse] = React.useState(isCollapsed? isCollapsed : false)
	/**
	 * Fonction gérant le rendu des fields d'une section
	 * @param fields - fields à afficher (provenant de sectionDependencies)
	 * @param parentIds - ids des sections parent
	 * @param isAdvancedSection - la section est une section avancée ou non
	 */
	const fieldsToFormControlLabel = (fields, parentIds, isAdvancedSection) => {
		return fields.map(field => (
			<ItemFieldTabDocument
				field={field}
				parentIds={parentIds}
				selectedLanguage={selectedLanguage}
				isAdvanced={isAdvancedSection}
			/>
		))

	}

	/**
	 * Fonction gérant le rendu des enfants d'une section
	 * @param parentSection - la section mère
	 * @param parentSectionIds - id des sections parent
	 */
	const renderChildrenFromSection = (parentSection, parentSectionIds) => {
		const hasAdvancedFields = sectionDependencies.fieldsAdvancedChildren.length > 0
		return (
			<Box sx={{ display: 'flex', flexDirection: 'column', ml: 3, marginLeft: 20 }}>
				{/*SECTIONS*/}
				{sectionDependencies.sectionsChildren.map(sectionChild => (
					<ItemSectionTabDocument
						section={sectionChild}
						parentIds={[...parentIds, parentSection.id]}
						selectedLanguage={selectedLanguage}
					/>
				))}

				{/*FIELDS*/}
				{fieldsToFormControlLabel(sectionDependencies.fieldsChildren, [...parentSectionIds, parentSection.id], false)}

				{hasAdvancedFields && <Grid>
					<FormControlLabel
						label={renderLabelSection(parentSection.id, null, true)}
						name={sectionName}
						className={classes.formControlLabel}
						control={
							<Checkbox
								id={parentSection.id}
								checked={isAdvancedChecked === ETATS_CHECKBOXES_COLUMNS.CHECKED}
								indeterminate={isAdvancedChecked === ETATS_CHECKBOXES_COLUMNS.INDETERMINATE}
								onChange={(e) => updateCheckedAdvanced(parentSection.id, e.target.checked, true)}
							/>
						}
					/>
					<Collapse in={isAdvancedCollapse} timeout="auto" className={classes.collapse}>
						<Box sx={{ display: 'flex', flexDirection: 'column', ml: 3, marginLeft: 20 }}>
							{fieldsToFormControlLabel(sectionDependencies.fieldsAdvancedChildren, [...parentSectionIds, parentSection.id], true)}
						</Box>
					</Collapse>
				</Grid>
				}
			</Box>
		)
	}

	/**
	 * Fonction assurant le rendu des labels des sections (NOMS + ExpandMoreIcon)
	 * @param idSection - id de la section
	 * @param nameSection - nom de la section
	 * @param isAdvanced - section avancée
	 */
	const renderLabelSection = (idSection, nameSection, isAdvanced) => {
		const isExpanded = isAdvanced ? isAdvancedCollapse : isCollapse
		return <Grid xs={12}>
			{isAdvanced ? <FormattedMessage id="oss.popinChoixFields.sectionChampsAvances" /> : nameSection}
			<IconButton onClick={() => updateCollapse(idSection, !isExpanded, isAdvanced)}>
				<ExpandMoreIcon className={classnames(classes.expand, { [classes.expandOpen]: isExpanded })} />
			</IconButton>
		</Grid>
	}


	const updateCollapse = (idField, isCollapse, isAdvanced) => {
		isAdvanced ? setIsAdvancedCollapse(isAdvancedCollapse => !isAdvancedCollapse) : setIsCollapse(isCollapse => !isCollapse)
		updateStatusCollapse(idField, isCollapse)
	}


	if (!section.sectionRegroupement && section.idMoParentSection) {
		return <></>
	}

	const data = section.attributeData && (section.attributeData[selectedLanguage] || section.attributeData[DEFAULT_LANGUAGE_ID])
	const sectionName = data && data[ATTRIBUTE_DATA_MO_SECTION.TITLE] ? data[ATTRIBUTE_DATA_MO_SECTION.TITLE].value : undefined

	return <Grid>
		<FormControlLabel
			label={renderLabelSection(section.id, sectionName, false)}
			name={sectionName}
			className={parentIds.length === 0 ? classes.formControlLabelRoot : classes.formControlLabel}
			control={
				<Checkbox
					id={section.id}
					checked={isChecked === ETATS_CHECKBOXES_COLUMNS.CHECKED}
					indeterminate={isChecked === ETATS_CHECKBOXES_COLUMNS.INDETERMINATE}
					onChange={(e) => updateChecked(section.id, e.target.checked, true, parentIds)}
				/>
			}
		/>
		<Collapse in={isCollapse} timeout="auto" className={classes.collapse}>
			{renderChildrenFromSection(section, parentIds)}
		</Collapse>
	</Grid>
}

InternalItemSectionTabDocument.propTypes = {
	section: PropTypes.object,
	parentIds: PropTypes.array,
	selectedLanguage: PropTypes.string,
	classes: PropTypes.object,
	isChecked: PropTypes.bool,
	isAdvancedChecked: PropTypes.bool,
	isCollapsed: PropTypes.bool,
	sectionDependencies: PropTypes.object,
	updateChecked: PropTypes.func,
	updateCheckedAdvanced: PropTypes.func,
	updateStatusCollapse: PropTypes.func,
}

const mapStateToProps = (state, { section }) => ({
	isChecked: tabDocumentSelector.isChecked(state, section.id, true),
	isAdvancedChecked: tabDocumentSelector.isAdvancedChecked(state, section.id),
	isCollapsed: tabDocumentSelector.isCollapsed(state, section.id),
	sectionDependencies: modelSelectors.getReferenceDependenciesBySection(state, section.id)
})

const actions = {
	updateChecked,
	updateCheckedAdvanced,
	updateStatusCollapse
}

const ItemSectionTabDocument = compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(InternalItemSectionTabDocument)

export default ItemSectionTabDocument
