import * as api from './parametrageApi'
import { formatAttributeData, formatFields } from '../../../fo/document/utils/documentUtils'

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_ERROR = 'UPDATE_ORDER_ERROR'
export const NEW_SECTION_REQUEST = 'NEW_SECTION_REQUEST'
export const NEW_SECTION_SUCCESS = 'NEW_SECTION_SUCCESS'
export const NEW_SECTION_ERROR = 'NEW_SECTION_ERROR'
export const EDIT_SECTION_REQUEST = 'EDIT_SECTION_REQUEST'
export const EDIT_SECTION_SUCCESS = 'EDIT_SECTION_SUCCESS'
export const EDIT_SECTION_ERROR = 'EDIT_SECTION_ERROR'
export const DELETE_SECTION_REQUEST = 'DELETE_SECTION_REQUEST'
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS'
export const DELETE_SECTION_ERROR = 'DELETE_SECTION_ERROR'

export const updateOrder = (modelType, fieldList, sectionPath) => dispatch => {
	dispatch({ type: UPDATE_ORDER_REQUEST })
	return api.updateOrder(modelType, fieldList)
		.then(() => dispatch({
			type: UPDATE_ORDER_SUCCESS,
			modelType,
			fieldList,
			sectionPath
		}))
		.catch(e => {
			dispatch({ type: UPDATE_ORDER_ERROR })
			throw e
		})
}

export const newSection = (modelType, values, sectionPath) => dispatch => {
	dispatch({ type: NEW_SECTION_REQUEST })
	return api.postSection(modelType, values)
		.then(section => dispatch({
			type: NEW_SECTION_SUCCESS,
			modelType,
			section,
			sectionPath
		}))
		.catch(e => {
			dispatch({ type: NEW_SECTION_ERROR })
			throw e
		})
}

export const editSection = (modelType, values, sectionPath, editedSection, oldSectionPath) => dispatch => {
	dispatch({ type: EDIT_SECTION_REQUEST })
	return api.updateSection(values.id, modelType, values)
		.then(section => dispatch({
			type: EDIT_SECTION_SUCCESS,
			modelType,
			section,
			sectionPath,
			editedSection,
			oldSectionPath
		}))
		.catch(e => {
			dispatch({ type: EDIT_SECTION_ERROR })
			throw e
		})
}

export const deleteSection = (modelType, sectionId, sectionPath) => dispatch => {
	dispatch({ type: DELETE_SECTION_REQUEST })
	return api.deleteSection(modelType, sectionId)
		.then(() => dispatch({
			type: DELETE_SECTION_SUCCESS,
			modelType,
			sectionId,
			sectionPath
		}))
		.catch(e => {
			dispatch({ type: DELETE_SECTION_ERROR })
			throw e
		})
}



export const POST_FIELD_REQUEST = 'POST_FIELD_REQUEST'
export const POST_FIELD_SUCCESS = 'POST_FIELD_SUCCESS'
export const POST_FIELD_ERROR = 'POST_FIELD_ERROR'
export const postField = (modelType, field, sectionPath) => dispatch => {
	dispatch({ type: POST_FIELD_REQUEST })
	return api.postField(field, modelType)
		.then(updatedField => dispatch({
			type: POST_FIELD_SUCCESS,
			modelType,
			field: {
				...updatedField,
				attributeData: formatAttributeData(updatedField.attributeData),
				fields: formatFields(updatedField.fields)
			},
			sectionPath
		}))
		.catch(e => {
			dispatch({ type: POST_FIELD_ERROR })
			throw e
		})
}

export const PUT_FIELD_REQUEST = 'PUT_FIELD_REQUEST'
export const PUT_FIELD_SUCCESS = 'PUT_FIELD_SUCCESS'
export const PUT_FIELD_ERROR = 'PUT_FIELD_ERROR'
export const putField = (modelType, field, id, sectionPath, oldSectionPath) => dispatch => {
	dispatch({ type: PUT_FIELD_REQUEST })
	return api.putField(field, modelType, id)
		.then(updatedField => dispatch({
			type: PUT_FIELD_SUCCESS,
			modelType,
			field: {
				...updatedField,
				attributeData: formatAttributeData(updatedField.attributeData),
				fields: formatFields(updatedField.fields)
			},
			sectionPath,
			oldSectionPath
		}))
		.catch(e => {
			dispatch({ type: PUT_FIELD_ERROR })
			throw e
		})
}

export const DELETE_FIELD_REQUEST = 'DELETE_FIELD_REQUEST'
export const DELETE_FIELD_SUCCESS = 'DELETE_FIELD_SUCCESS'
export const DELETE_FIELD_ERROR = 'DELETE_FIELD_ERROR'
export const deleteField = (modelType, id, sectionPath) => dispatch => {
	dispatch({ type: DELETE_FIELD_REQUEST })
	return api.deleteField(modelType, id)
		.then(() => dispatch({
			type: DELETE_FIELD_SUCCESS,
			modelType,
			idField: id,
			sectionPath
		}))
		.catch(e => {
			dispatch({ type: DELETE_FIELD_ERROR })
			throw e
		})
}