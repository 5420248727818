import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import { compose } from 'redux'
import Input from '../../../../../components/form/Input'
import Select from '../../../../../components/form/Select'
import { DEFAULT_DEVISE, DEFAULT_LANGUAGE_ID } from '../../../../../utils/constants'
import { injectDeviseList } from '../../../../common/valueList/ValueListInjector'
import { ATTRIBUTE_DATA_MO_FIELD } from '../../utils/documentConstants'
import { normalizeNumber, parseNumber } from '../../utils/documentUtils'
import DocumentRowField from './DocumentRowField'
import DatasetHandler from '../DatasetHandler'

const styles = theme => getStyles({
	titleAmount: {
		paddingLeft: 10
	},
	amountContainer: {
		border: `${theme.palette.placeholder} 1px dashed`,
		marginBottom: 20,
		alignItems: 'center'
	},
	uniteDefault: {
		paddingLeft: 10,
		paddingTop: 25,
		paddingBottom: 5
	},
	consultationContainer: {
		paddingBottom: 24
	},
	numberField: {
		paddingRight: 0
	}
})

const DocumentAmountField = ({
	field,
	addSectionInMap,
	inputName,
	selectedLanguage,
	deviseList,
	classes,
	consultation,
	initialValues,
	duplicatedNumber
}) => {

	const labelDefautCurrency = (deviseList || []).find(devise => devise.code === DEFAULT_DEVISE)
	const data = field.attributeData[selectedLanguage] || field.attributeData[DEFAULT_LANGUAGE_ID]
	if (consultation) {
		if (!initialValues.siValue && !initialValues.value) {
			return <></>
		}

		return <Grid item container xs={12} className={classes.consultationContainer}>
			<Grid item xs={4}>
				<Typography
					variant="caption">{data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] && `${data[ATTRIBUTE_DATA_MO_FIELD.NAME].value}`}</Typography>
			</Grid>
			<Grid item container xs={8}>
				<Typography variant="body1">{initialValues.siValue} {labelDefautCurrency ? labelDefautCurrency.label : ''}</Typography>
			</Grid>
			{initialValues.value &&
			<>
				<Grid item xs={4} />
				<Grid item container xs={8}>
					<Typography
						variant="body1">({initialValues.value} {(deviseList || []).filter(devise => devise.code === initialValues.unite).map(devise => `${devise.label}`)})</Typography>
				</Grid>
			</>
			}
		</Grid>
	} else {
		return <Grid container className={classes.amountContainer}>
			<Grid item xs={12}>
				<Typography variant="caption"
				            className={classes.titleAmount}>{data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] && `${data[ATTRIBUTE_DATA_MO_FIELD.NAME].value} ${field.required ? '*' : ''}`}</Typography>
			</Grid>
			<Grid container item xs={12} justify="flex-start" alignItems="center">
				<DatasetHandler field={field} duplicatedNumber={duplicatedNumber} suffix="EUR">
					<DocumentRowField addSectionInMap={addSectionInMap} duplicatedNumber={duplicatedNumber} field={field} attData={data}>

						<Grid container item xs={8} alignItems="flex-start" justify="flex-start">
							<Grid item xs={5}>
								<Field
									name={`${inputName}.siValue`}
									component={Input}
									parse={parseNumber}
									format={normalizeNumber}
									containerClassName={classes.numberField}
								/>
							</Grid>
							<Grid item xs={1} className={classes.uniteDefault}>
								<Typography variant="body1">{labelDefautCurrency ? labelDefautCurrency.label : ''}</Typography>
							</Grid>
							<Grid item xs={4}>
								<Field
									name={`${inputName}.value`}
									component={Input}
									parse={parseNumber}
									format={normalizeNumber}
									containerClassName={classes.numberField}
								/>
							</Grid>
							<Grid item xs={2}>
								<Field
									name={`${inputName}.unite`}
									component={Select}
								>
									<MenuItem key={`select-${field.id}-default`} value={0} />
									{(deviseList || []).filter(devise => devise.code !== DEFAULT_DEVISE).map(devise => <MenuItem
										key={`amount-select-${field.id}-${devise.code}`} value={devise.code}>{devise.label}</MenuItem>)}
								</Field>
							</Grid>
						</Grid>

					</DocumentRowField>
				</DatasetHandler>
			</Grid>
		</Grid>
	}
}

DocumentAmountField.propTypes = {
	field: PropTypes.object,
	selectedLanguage: PropTypes.string,
	inputName: PropTypes.string,
	deviseList: PropTypes.array,
	duplicatedNumber: PropTypes.number,
	classes: PropTypes.object,
	consultation: PropTypes.bool,
	initialValues: PropTypes.object
}

export default compose(
	injectDeviseList,
	withStyles(styles)
)(DocumentAmountField)
