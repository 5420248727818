import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { FormattedMessage } from 'react-intl'
import Popin from '../../../../../components/Popin'
import Button from '@material-ui/core/Button'
import { Field, reduxForm } from 'redux-form'
import Input from '../../../../../components/form/Input'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import Radio from '../../../../../components/form/Radio'
import CompleteEmail from '../../../../../components/form/CompleteEmail'

const styles = () => getStyles({
	paddingText: {
		paddingLeft: 10,
		marginBottom: 15
	}
})

const validate = (values) => {
	const errors = {}

	if (!values.email) {
		errors.email = { id: 'global.errors.obligatoire' }
	}

	return errors
}

const SharePopin = ({ open, displayRadio, onClose, onSubmit, handleSubmit, change, classes }) => {

	const actions = [
		<Button
			color="inherit"
			variant="outlined"
			key="fermer"
			onClick={onClose}
		>
			<FormattedMessage id="document.partage.close" />
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			type="submit"
			onClick={handleSubmit(onSubmit)}
		>
			<FormattedMessage id="document.partage.send" />
		</Button>
	]

	const choices = [
		{
			value: '0',
			label: <FormattedMessage id="document.partage.form.contribution" />
		},
		{
			value: '1',
			label: <FormattedMessage id="document.partage.form.consultation" />
		}
	]

	return <form onSubmit={onSubmit}><Popin
		open={open}
		actions={actions}
		title={<FormattedMessage id="document.partage.title" />}
		onClose={onClose}
		content={<>
			{displayRadio && <>
				<Typography variant="body2" className={classes.paddingText}><FormattedMessage id="document.partage.ask" /></Typography>
				<Field
					name="partage"
					component={Radio}
					choices={choices}
				/>
			</>}
			<Typography variant="body2" className={classes.paddingText}><FormattedMessage id="document.partage.description" /></Typography>
			<Field
				name="email"
				label={<FormattedMessage id="document.partage.form.email" />}
				component={CompleteEmail}
				change={change}
			/>
			<Field
				name="commentaire"
				label={<FormattedMessage id="document.partage.form.commentaire" />}
				component={Input}
				multiline
				rows={3}
			/>
		</>}
	/>
	</form>
}

SharePopin.propTypes = {
	open: PropTypes.bool,
	displayRadio: PropTypes.bool,
	classes: PropTypes.object,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	change: PropTypes.func
}

const FORM_NAME = 'PARTAGE_FORM'

export default compose(
	reduxForm({
		form: FORM_NAME,
		initialValues: {
			partage: '1'
		},
		validate
	}),
	withStyles(styles)
)(SharePopin)
