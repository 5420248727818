import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import ThumbUp from '@material-ui/icons/ThumbUp'
import { push } from 'connected-react-router'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import SvgIcon from '../../../../components/icon/SvgIcon'
import EmptyResult from '../../../../components/layout/EmptyResult'
import Link from '../../../../components/Link'
import { GlOBAL_LANGUAGE_ID, SVG_ICONS, TYPE_CONTENU } from '../../../../utils/constants'
import { getDocuments } from '../../document/services/documentApi'
import { DOCUMENT_STATUS } from '../../document/utils/documentConstants'
import { getUserEmail, getUserLanguage } from '../../user/services/userSelectors'
import PaperBlocAccueil from './PaperBloc'
import { isDocumentPublicOrToHideOrToDelete } from '../../../../utils/utils'

const styles = theme => getStyles({
	container: {
		width: 612
	},
	divTitre: {
		height: 56,
		marginBottom: 10
	},
	link: {
		color: theme.palette.primary.main
	},
	list: {
		margin: '0 -20px',
		padding: 0,
		height: 305,
		overflow: 'auto'
	},
	thumpUpNumber: {
		backgroundColor: theme.palette.secondary.main,
		padding: '5px 12px 5px 12px',
		color: 'white',
		boxShadow: 'none',
		borderRadius: 4,
		display: 'flex'
	},
	status: {
		backgroundColor: theme.palette.draft,
		padding: '5px 12px 5px 12px',
		color: 'white',
		boxShadow: 'none',
		borderRadius: 4,
		display: 'flex'
	},
	thumpUp: {
		marginRight: 5,
		fontSize: 16
	},
	icon: {
		fontSize: 32
	},
	iconBrown: {
		fontSize: 32,
		color: theme.palette.draft
	},
	draftIcon: {
		color: theme.palette.draft
	},
	publishedIcon: {
		color: theme.palette.secondary.main
	},
	button: {
		marginTop: 20
	},
	emptyState: {
		height: '10vw'
	}
})

const MesContributionsBloc = ({ contributor, intl, goToEdit, goToNewContent, userLanguage, classes }) => {
	const [contributions, setContributions] = React.useState([])

	React.useEffect(() => {
		getContributions()
			.catch(() => {
				// En cas d'erreur on effectue une nouvelle tentative
				getContributions()
			})
	}, [contributor])

	const getContributions = () => {
		return getDocuments(0, { fieldsAdmin: { contributor: [contributor] }, sortFields: [{ reference: `dateModification.valueField.${GlOBAL_LANGUAGE_ID}`, order: 'ASC' }] })
			.then(data => setContributions(data.results))
	}

	const renderIcon = (contribution) => {
		let iconPropertySelect = {}

		const iconProperty = {
			className: classes.icon,
			color: 'secondary',
			fontSize: 'large'
		}
		const iconPropertyBrown = {
			className: classes.iconBrown,
			color: 'secondary',
			fontSize: 'large'
		}

		if (isDocumentPublicOrToHideOrToDelete(contribution, true)) {
			iconPropertySelect = iconProperty
		} else {
			iconPropertySelect = iconPropertyBrown
		}

		switch (contribution.typeContenu.valueField[GlOBAL_LANGUAGE_ID]) {
			case `${TYPE_CONTENU.SAVOIR_FAIRE}`:
				return <SvgIcon path={SVG_ICONS.SAVOIR_FAIRE} {...iconPropertySelect} />
			case `${TYPE_CONTENU.PROPOSITION_COMMERCIALE}`:
				return <SvgIcon path={SVG_ICONS.PROPOSITION_COMMERCIALE} {...iconPropertySelect} />
			case `${TYPE_CONTENU.REFERENCE}`:
				return <SvgIcon path={SVG_ICONS.REFERENCE} {...iconPropertySelect} />
			default:
				return <></>

		}
	}

	const getSecondaryText = (contribution) => {
		const optDate = {
			year: 'numeric',
			month: 'short',
			day: 'numeric'
		}

		let date = undefined
		switch (contribution.statusWorkFlow.valueField[GlOBAL_LANGUAGE_ID]) {
			case DOCUMENT_STATUS.A_VALIDER:
				date = contribution.dateSubmitWorkFlow && contribution.dateSubmitWorkFlow.valueField[GlOBAL_LANGUAGE_ID]
				break
			case DOCUMENT_STATUS.VALIDE:
				date = contribution.dateValidationWorkFlow && contribution.dateValidationWorkFlow.valueField[GlOBAL_LANGUAGE_ID]
				break
			case DOCUMENT_STATUS.PUBLIE:
				date = contribution.datePublishedWorkFlow && contribution.datePublishedWorkFlow.valueField[GlOBAL_LANGUAGE_ID]
				break
			case DOCUMENT_STATUS.MASQUE:
				date = contribution.dateMaskedWorkFlow && contribution.dateMaskedWorkFlow.valueField[GlOBAL_LANGUAGE_ID]
				break
			case DOCUMENT_STATUS.A_MASQUER:
			case DOCUMENT_STATUS.A_SUPPRIMER:
				date = contribution.dateDemandWorkFlow && contribution.dateDemandWorkFlow.valueField[GlOBAL_LANGUAGE_ID]
				break
			default:
				date = contribution.dateCreation && contribution.dateCreation.valueField[GlOBAL_LANGUAGE_ID]
		}

		return `${intl.formatDate(contribution.dateModification.valueField[GlOBAL_LANGUAGE_ID], optDate)} - ${intl.formatMessage({ id: `enums.documentStatusContributions.${contribution.statusWorkFlow.valueField[GlOBAL_LANGUAGE_ID]}` })} ${intl.formatDate(date, optDate)}`
	}

	return (
		<PaperBlocAccueil className={classes.container}>
			<Grid container className={classes.divTitre}>
				<Grid item xs={12}>
					<Typography variant="h3">
						<FormattedMessage id="accueil.mesContributions.titre" />
					</Typography>
				</Grid>
				<Grid item spacing={1} container xs={12} justify="space-between">
					<Grid item xs={10}>
						<Typography variant="body2">
							<FormattedMessage id="accueil.mesContributions.description" />
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="body2">
							<Link className={classes.link} to="/mes-contributions"><FormattedMessage id="global.buttons.voirPlus" /></Link>
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<List className={classes.list}>
				{contributions.map((contribution, index) => (
						<ListItem
							key={contribution.idContent.valueField[GlOBAL_LANGUAGE_ID]}
							style={index % 2 === 0 ? { backgroundColor: '#f3f1ee' } : {}}
							button
							onClick={() => goToEdit(contribution.idContent.valueField[GlOBAL_LANGUAGE_ID])}
						>
							<ListItemIcon>
								{renderIcon(contribution)}
							</ListItemIcon>
							<ListItemText
								primary={contribution.title.valueField[userLanguage] ? contribution.title.valueField[userLanguage] : ((contribution.langue && contribution.title.valueField[contribution.langue.valueField[GlOBAL_LANGUAGE_ID]]) ? contribution.title.valueField[contribution.langue.valueField[GlOBAL_LANGUAGE_ID]] : '')}
								secondary={getSecondaryText(contribution)} />
							<ListItemIcon>
								{contribution && contribution.statusWorkFlow && isDocumentPublicOrToHideOrToDelete(contribution, true) ?
									<Paper className={classes.thumpUpNumber}><ThumbUp className={classes.thumpUp} />{contribution.nombreLike.valueField[GlOBAL_LANGUAGE_ID]}</Paper>
									: <Paper className={classes.status}><FormattedMessage
										id={`enums.documentStatus.${contribution.statusWorkFlow.valueField[GlOBAL_LANGUAGE_ID]}`} /></Paper>
								}
							</ListItemIcon>
						</ListItem>
					)
				)}
				{contributions.length === 0 &&
				<EmptyResult message={<FormattedMessage id="accueil.mesContributions.empty" />} imgClassName={classes.emptyState} />}
			</List>
			<Grid item container direction="row-reverse" className={classes.button}>
				<Button
					key="access"
					color="primary"
					variant="contained"
					onClick={goToNewContent}
				>
					<FormattedMessage id="accueil.mesContributions.add" />
				</Button>
			</Grid>
		</PaperBlocAccueil>
	)
}

const mapStateToProps = state => ({
	contributor: getUserEmail(state)
})

const actions = {
	goToNewContent: () => dispatch => dispatch(push('/new-document')),
	goToEdit: (id) => dispatch => dispatch(push(`consultation-document/${id}`))
}

MesContributionsBloc.propTypes = (state) => ({
	contributor: PropTypes.string,
	intl: PropTypes.object,
	classes: PropTypes.object,
	goToNewContent: PropTypes.func,
	userLanguage: getUserLanguage(state)
})

export default compose(
	injectIntl,
	connect(mapStateToProps, actions),
	withStyles(styles)
)(MesContributionsBloc)
