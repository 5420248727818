import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

const FieldPrefixContext = React.createContext({ prefix: 'attributeData' })
const DonneeAttributeField = ({ name, ...props }) => (
	<FieldPrefixContext.Consumer>
		{({ prefix }) => <Field name={`${prefix}.${name}`} {...props} />}
	</FieldPrefixContext.Consumer>
)

DonneeAttributeField.propTypes = {
	name: PropTypes.string
}

export default DonneeAttributeField
