import CircularProgress from '@material-ui/core/CircularProgress'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Autosuggest from 'react-autosuggest'
import { getStyles } from 'isotope-client'
import Input from './Input'
import { FormattedMessage } from 'react-intl'
/**
 * Champ autocomplete repris d'isotope
 * Récupération de la valeur au onClick sur un élément
 * @param theme
 */

const styles = theme => getStyles({
	root: {
		flexGrow: 1
	},
	container: {
		position: 'relative'
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		zIndex: 10,
		marginTop: 1,
		left: 1,
		right: 0,
		overflow: 'auto',
		marginLeft: 10,
		marginRight: 10
	},
	suggestionContainerPlacementTop: {
		top: 55
	},
	suggestionContainerPlacementTopWithoutLabel: {
		top: 25
	},
	suggestion: {
		display: 'block'
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none',
		maxHeight: 180,
		overflowY: 'auto'
	},
	loader: {
		position: 'absolute',
		top: 27,
		right: 15
	},
	loaderWithoutLabel: {
		position: 'absolute',
		top: 8,
		right: 10
	},
	highlighted: {
		color: theme.palette.primary.main,
		fontWeight: 500
	},
	item: {
		height: 30,
		paddingLeft: 12,
		paddingRight: 12
	},
	itemFromFilters: {
		height: 49,
		paddingLeft: 12,
		paddingRight: 12
	},
	suggestionLabel: {
		fontSize: 14,
		whiteSpace: 'nowrap'
	},
	suggestionPath: {
		fontSize: 12,
		paddingLeft: 8,
		color: theme.palette.placeholder,
		whiteSpace: 'nowrap'
	}
})

class Autocomplete extends React.Component {
	state = {
		loading: false,
		value: '',
		suggestions: []
	}

	timeout = null

	componentWillUnmount() {
		if (this.timeout) {
			clearTimeout(this.timeout)
		}
	}

	renderInputComponent = ({ ref, getSuggestion, canLoadIfEmpty, ...inputProps }) => {
		const { loading } = this.state
		const { classes, inputStyle } = this.props
		return (
			<>
				<Input
					{...inputProps}
					inputStyle={inputStyle}
					autoComplete="off"
				/>
				{loading && <CircularProgress className={!!inputProps.label ? classes.loader : classes.loaderWithoutLabel} size={15} />}
			</>
		)
	}

	renderSuggestion = (suggestion, { query, isHighlighted }) => {
		const { onClickItem, classes } = this.props

		return (
			<MenuItem selected={isHighlighted} component="div" onClick={() => onClickItem(suggestion, query)} className={classes.item}>
				<span key={suggestion.code}>{suggestion.label}</span>
			</MenuItem>
		)
	}

	renderSuggestionAdditionalFilters = (suggestion, { query, isHighlighted }) => {
		const { onClickItem, classes } = this.props

		return (
			<MenuItem selected={isHighlighted} component="div" onClick={() => onClickItem(suggestion, query)} className={classes.itemFromFilters}>
				<p key={suggestion.code} className={classes.suggestionLabel}>{suggestion.label} <br/>
					<span className={classes.suggestionPath}><FormattedMessage id={`enums.documentType.${suggestion.moType}`}/> {suggestion.path}</span>
				</p>
			</MenuItem>
		)
	}

	getSuggestionValue = (suggestion) => suggestion.label

	handleSuggestionsFetchRequested = ({ value }) => {
		const { canLoadIfEmpty, minLength, getSuggestion } = this.props
		const inputValue = value.trim().toLowerCase()
		const inputLength = inputValue.length

		if (inputLength < minLength && !canLoadIfEmpty) {
			return []
		}

		if (this.timeout) {
			clearTimeout(this.timeout)
		}
		this.setState({ loading: true })
		this.timeout = setTimeout(
			() => {
				getSuggestion(inputLength, inputValue)
					.then((suggestions) => {
						this.setState({
							suggestions,
							loading: false
						})
					})
					.catch(() => {
						this.setState({
							suggestions: [],
							loading: false
						})
					})
			},
			200
		)
	}
	shouldRenderSuggestions = (value) => {
		const { canLoadIfEmpty, minLength, disableSuggestions } = this.props

		if (disableSuggestions) {
			return false
		}

		return value.length < minLength ? canLoadIfEmpty : true
	}
	handleSuggestionsClearRequested = () => {
		this.setState({
			suggestions: []
		})
	}
	handleChange = () => (event, { newValue }) => {
		const { input: { onChange } } = this.props
		onChange(newValue)
	}

	render() {
		const {
			classes,
			input,
			containerStyle,
			multiSection,
			autosuggestProps,
			...otherProps
		} = this.props

		const autosuggestDefaultProps = {
			renderInputComponent: this.renderInputComponent,
			suggestions: this.state.suggestions,
			onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
			onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
			getSuggestionValue: this.getSuggestionValue,
			renderSuggestion: otherProps.fromFilters ? this.renderSuggestionAdditionalFilters : this.renderSuggestion,
			shouldRenderSuggestions: this.shouldRenderSuggestions,
			getSectionSuggestions: (section) => section.suggestions,
			...(autosuggestProps || {})
		}

		return (
			<div className={classes.root}>
				<Autosuggest
					multiSection={multiSection}
					{...autosuggestDefaultProps}
					focusInputOnSuggestionClick={false}
					inputProps={{
						...input,
						...otherProps,
						onChange: this.handleChange(),
						onBlur: () => {}
					}}
					theme={{
						container: classNames(
							classes.container,
							containerStyle
						),
						suggestionsContainerOpen: classNames(classes.suggestionsContainerOpen, !!otherProps.label ? classes.suggestionContainerPlacementTop : classes.suggestionContainerPlacementTopWithoutLabel),
						suggestionsList: classes.suggestionsList,
						suggestion: classes.suggestion
					}}
					renderSuggestionsContainer={options => (
						<Paper {...options.containerProps} square>
							{options.children}
						</Paper>
					)}
					onSuggestionSelected={(ev, { suggestion, method }) => {
						if (method === 'enter') {
							otherProps.onClickItem(suggestion, '')
							ev.preventDefault()
						}
					}}
				/>
			</div>
		)
	}
}

Autocomplete.defaultProps = {
	canLoadIfEmpty: false,
	multiSection: false,
	minLength: 0
}

Autocomplete.propTypes = {
	input: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	// Booleen pour forcer le chargement des suggestions même si nous n'avons rien saisi
	canLoadIfEmpty: PropTypes.bool,
	// Surcharge du style input
	inputStyle: PropTypes.string,
	// Surchage du style container
	containerStyle: PropTypes.string,
	// Promesse qui renvoie les suggestions
	getSuggestion: PropTypes.func.isRequired,
	// Possibilité d'avoir de multiples sections
	multiSection: PropTypes.bool,
	// Possibilité de surcharger les valeurs de l'autosuggest
	autosuggestProps: PropTypes.object,
	// Nombre de caractères saisis avant de fetch les suggestions
	minLength: PropTypes.number,
	// Callback au click sur un item
	onClickItem: PropTypes.func,
	// Permet de masquer le container de suggestions
	disableSuggestions: PropTypes.bool
}

export default withStyles(styles, { name: 'autocomplete' })(Autocomplete)
