import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import EmptyResult from '../../../../components/layout/EmptyResult'
import OverflowTypography from '../../../../components/OverflowTypography'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as tabDocumentSelector from '../services/tabDocumentSelector'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import classnames from 'classnames'
import SearchIcon from '@material-ui/icons/Search'
import { colors, DEFAULT_LANGUAGE_ID, TITLE_COLUMN_NAME, ZONE_COLUMN_NAME } from '../../../../utils/constants'
import { ATTRIBUTE_DATA_CONTENT, ATTRIBUTE_DATA_MO_SECTION, FIELD_TYPE } from '../../document/utils/documentConstants'
import { push } from 'connected-react-router'
import TooltipItem from '../../../../components/icon/TooltipItem'
import moment from 'moment'
import UpdateFieldPopin from '../updateField/UpdateFieldPopin'
import * as userSelectors from '../../user/services/userSelectors'
import * as valueListSelectors from '../../../common/valueList/valueListSelectors'
import Fab from '@material-ui/core/Fab'
import Link from '../../../../components/Link'

const styles = () => getStyles({
	tableContainer: {
		position: 'relative',
		maxHeight: 'calc(100vh - 225px)',
		overflow: 'auto',
		background: 'white',
		borderRadius: 10
	},
	loader: {
		position: 'fixed',
		top: '50%',
		left: '50%'
	},
	cellPadding: {
		padding: 10,
		minWidth: 140,
		maxWidth: 140,
		fontSize: 14
	},
	stickyCell: {
		position: 'sticky',
		left: 0,
		zIndex: 5,
		background: '#828F8F',
		color: 'white',
		border: '1px solid #99A8A8',
		verticalAlign: 'baseline'
	},
	stickyCellLink: {
		color: 'white'
	},
	headerCell: {
		background: '#C8DCDC',
		border: '1px solid #99A8A8',
		fontSize: 15
	},
	contentCell: {
		border: '1px solid #EEF4F4',
		verticalAlign: 'baseline',
		overflowWrap: 'break-word'
	},
	titleHeader: {
		position: 'relative'
	},
	titleCellHeader: {
		background: '#00AEC7',
		color: 'white',
		fontSize: 15,
		border: '1px solid #99A8A8',
		zIndex: 10,
		verticalAlign: 'top'
	},
	notEditableCell: {
		opacity: 0.4,
		background: '#E5E5E5',
		cursor: 'not-allowed'
	},
	editableCell: {
		cursor: 'copy'
	},
	pointerCursor: {
		cursor: 'pointer'
	},
	headerSectionParent: {
		background: '#BACFCF',
		borderTop: 0
	},
	headerField: {
		fontVariant: 'all-small-caps',
		textAlign: 'left',
		verticalAlign: 'top',
		height: '4vh'
	},
	buttonLoupe: {
		fill: colors.primary
	},
	stickyRow: {
		position: 'sticky',
		top: 0,
		zIndex: 4
	},
	emptyViewImg: {
		height: '10vw'
	},
	iconButtonTitle: {
		padding: 4
	},
	rows: {
		height: '7vh'
	},
	emptyTable: {
		minHeight: '40vh'
	},
	fiHeaderBottom: {
		borderBottom: 0
	},
	fiHeaderTop: {
		borderTop: 0
	},
	referenceCellFirstLine: {
		borderBottom: 0,
		zIndex: 12,
		padding: '4px 10px'
	},
	referenceCellSecondLine: {
		borderTop: 0,
		borderBottom: 0
	},
	referenceCellThirdLine: {
		borderTop: 0
	},
	racineStyle: {
		fontWeight: 'bold',
		fontSize: 16
	},
	addNewContent: {
		backgroundColor: 'white',
		border: '2px solid',
		'&:hover': {
			border: '2px solid'
		}
	}
})

const typeSectionFicheIdentite = 'FI'

const TabDocumentTable = ({
	classes,
	data,
	loading,
	selectedLanguage,
	goToEdition,
	handlePageChange,
	goToConsultation,
	onEdition,
	intl,
	idLanguage,
	allValueLists,
	headerLabels,
	setOpenSearchForm,
	langues,
	modelData,
	location
}) => {

	const initialStateUpdateFieldPopin = { open: false, initialValues: {} }
	const { page, size, total, content, selectedColumns } = data
	const [updateFieldPopin, setUpdateFieldPopin] = React.useState(initialStateUpdateFieldPopin)

	const handleChangeUpdateFieldPopin = (status, type, fieldValue, labelField, unite, idField, idRow, codeColumn, valueListShortcut, codesField) => {
		let formattedCodeFromLabel = fieldValue

		if ((type === FIELD_TYPE.SELECT || type === FIELD_TYPE.MULTI_SELECT) && fieldValue && fieldValue.length > 0) {
			if (formattedCodeFromLabel.startsWith('[')) {
				formattedCodeFromLabel = JSON.parse(codesField)
			} else {
				formattedCodeFromLabel = [codesField]
			}
		}

		setUpdateFieldPopin({
			open: status,
			initialValues: { type, fieldValue: formattedCodeFromLabel, labelField, unite, idField, idRow, codeColumn, valueListShortcut }
		})
	}

	const closeUpdateFieldPopin = () => {
		setUpdateFieldPopin(initialStateUpdateFieldPopin)
	}

	const renderNoDataCell = (maxIndexDuplication, editable, columnCode, fieldType, labelField, idRow, valueListShortcut, codesField) => (
		<TableCell
			rowSpan={parseInt(maxIndexDuplication) + 1}
			className={classnames(classes.cellPadding, classes.contentCell, { [classes.notEditableCell]: !editable && onEdition }, { [classes.editableCell]: editable && onEdition })}
			key={`${idRow}-${columnCode}-empty`}
			onClick={() => editable && onEdition && handleChangeUpdateFieldPopin(true, fieldType, null, labelField, null, null, idRow, columnCode, valueListShortcut, codesField)}
		/>
	)

	const renderData = (maxIndexDuplication, editable, rowValue, fieldType, unite, labelField, idRow, columnCode, valueListShortcut, codesField) => (
		<TableCell
			rowSpan={parseInt(maxIndexDuplication) + 1}
			className={classnames(classes.cellPadding, classes.contentCell, { [classes.notEditableCell]: !editable && onEdition }, { [classes.editableCell]: editable && onEdition })}
			key={rowValue.idField}
			onClick={() => editable && onEdition && handleChangeUpdateFieldPopin(true, fieldType, rowValue.value, labelField, unite, rowValue.idField, idRow, columnCode, valueListShortcut, codesField)}
		>
			{getValue(rowValue, fieldType, unite)}
		</TableCell>
	)

	const renderToolTipCell = (editable, content, title) => (
		<TooltipItem
			placement="top"
			title={onEdition
				? editable ? '' : title
				: ''
			}
			content={content}
		/>
	)

	/**
	 * Génère le label du Tooltip si la cellule est disabled
	 * @param editable
	 * @param canModify
	 * @param rowIdField
	 * @returns {string}
	 */
	const getLabelForTooltip = (editable, canModify, rowIdField) => {
		if (!editable) {
			return 'oss.tabDocumentPage.tooltip.notEditable'
		} else {
			if (rowIdField === null) {
				return 'oss.tabDocumentPage.tooltip.notExist'
			} else {
				if (!canModify) {
					return 'oss.tabDocumentPage.tooltip.cantModify'
				}
			}
		}
	}

	/**
	 * Formattage de la valeur selon le type du champ
	 * @param row les données de la ligne courante
	 * @param columnCode la clé du champ à afficher
	 * @param index l'index du champ en cas d'idField null
	 */
	const getFormattedValueFromRow = (row, indexDuplication, maxIndexDuplication, columnCode) => {
		let columnCodeFinal = columnCode
		if(indexDuplication > 0){
			columnCodeFinal = columnCodeFinal + "#" + indexDuplication
		}
		// Si rowValue est undefined → il n'existe pas de valeur pour ce champ dans aucune des langues
		const rowValue = row.values[columnCodeFinal]
		const canModify = rowValue ? rowValue.canModify : false
		const idField = rowValue ? rowValue.idField : null
		const codesField = rowValue ? rowValue.codes : null
		if (selectedColumns[columnCode] !== undefined) {
			const fieldType = selectedColumns[columnCode].type
			const unite = selectedColumns[columnCode].unite
			const labelField = selectedColumns[columnCode].label
			const editable = selectedColumns[columnCode].editable
			const valueListShortcut = selectedColumns[columnCode].sourceCode
			const tooltipTitle = getLabelForTooltip(editable, canModify, idField)

			const duplications = Object.keys(row.values).filter(col => col.startsWith(`${columnCode}#`))
			// VIDE
			if (!rowValue) {
				if(selectedColumns[columnCodeFinal]){
					if (duplications.length > 0) {
						return renderToolTipCell(editable && canModify && idField !== null, renderNoDataCell(0, editable && canModify, columnCodeFinal, fieldType, labelField, row.id, valueListShortcut, codesField), <FormattedMessage id={tooltipTitle}/>)
					} else {
						return renderToolTipCell(editable && canModify && idField !== null, renderNoDataCell(maxIndexDuplication, editable && canModify, columnCodeFinal, fieldType, labelField, row.id, valueListShortcut, codesField), <FormattedMessage id={tooltipTitle}/>)
					}
				} else {
					if (duplications.length > 0 && indexDuplication > 0) {
						return renderToolTipCell(editable && canModify && idField !== null, renderNoDataCell(0, editable && canModify, columnCodeFinal, fieldType, labelField, row.id, valueListShortcut, codesField), <FormattedMessage id={tooltipTitle}/>)
					} else {
						return <></>
					}
				}
			}

			// SIMPLE
			if (duplications.length > 0) {
				return renderToolTipCell(editable && canModify && idField !== null, renderData(0, editable && canModify && idField !== null, rowValue, fieldType, unite, labelField, row.id, columnCodeFinal, valueListShortcut, codesField),
					<FormattedMessage
						id={tooltipTitle}/>)
			} else {
				return renderToolTipCell(editable && canModify && idField !== null, renderData(maxIndexDuplication, editable && canModify && idField !== null, rowValue, fieldType, unite, labelField, row.id, columnCodeFinal, valueListShortcut, codesField),
					<FormattedMessage
						id={tooltipTitle}/>)
			}
		} else if(columnCode === ATTRIBUTE_DATA_CONTENT.ZONE){
			const tooltipTitle = getLabelForTooltip(false, false, idField)
			return renderToolTipCell(false, renderData(maxIndexDuplication, false, rowValue, 'TEXT', '', '', row.id, columnCodeFinal, '', codesField),
				<FormattedMessage
					id={tooltipTitle}/>)
		}
		if (indexDuplication > 0) {
			return renderToolTipCell(false && canModify && idField !== null, renderNoDataCell(maxIndexDuplication, false && canModify, columnCodeFinal, 'TEXT', '', row.id, null, codesField), <FormattedMessage
				id={'oss.tabDocumentPage.tooltip.notEditable'}/>)
		}
	}

	/**
	 * Formatte la valeur reçu pour l'afficher textuellement dans une cellule
	 */
	const getValue = (rowValue, type, unite) => {
		if (rowValue.confidential) {
			return intl.formatMessage({ id: 'document.field.confidentiable' })
		}

		if (rowValue && rowValue.value && !rowValue.confidential) {
			const fieldValue = rowValue.value
			switch (type) {
				case FIELD_TYPE.SELECT:
				case FIELD_TYPE.TEXT:
					return fieldValue
				case FIELD_TYPE.MULTI_SELECT:
					try {
						return JSON.parse(fieldValue)
							.sort((item1, item2) => item1.localeCompare(item2))
							.join(', ')
					} catch (e) {
						return 'Malformed data'
					}
				case FIELD_TYPE.DATE:
					return idLanguage === DEFAULT_LANGUAGE_ID
						? moment(fieldValue).format('MM/DD/YYYY')
						: moment(fieldValue).format('DD/MM/YYYY')
				case FIELD_TYPE.NUMBER:
					return formatDecimalNumber(fieldValue)
				case FIELD_TYPE.AMOUNT:
					return formatDecimalNumber(fieldValue).concat(' €')
				case FIELD_TYPE.NUMBER_UNIT:
					return formatDecimalNumber(fieldValue).concat(` ${unite}`)
				case FIELD_TYPE.TEXT_MULTILINE:
					return <Grid dangerouslySetInnerHTML={{ __html: fieldValue.replace(/<img .*?>/g,"") }} />
				default:
			}
		}
		return ''
	}

	const formatDecimalNumber = (value) => Number(parseFloat(value).toFixed(2)).toLocaleString()

	const getNbFieldInRacine = (racine) => {
		let nbFieldsInRacine = 0
		Object.values(racine.sections).forEach(section => {
			nbFieldsInRacine += section.fields.length
		})
		return nbFieldsInRacine
	}

	/**
	 * Retourne les labels de la section Fiche d'identité dans toutes les langues
	 * @type {function(): *[]}
	 */
	const getFiTitles = React.useCallback(() => {
		const attributeDataSectionFI = modelData.sections.find(section => section.type === typeSectionFicheIdentite).attributeData || {}
		return langues.map(lang => attributeDataSectionFI[lang.id][ATTRIBUTE_DATA_MO_SECTION.TITLE].value)
	}, [])

	const renderSectionsIntermediairesInHeader = () => {
		// Pas besoin de traiter la section Fiche d'Identité car pas de sous-section
		return headerLabels.map(racine => {
			const sectionsIntermediaires = Object.values(racine.sections)
				.map(sectionIntermediaire => <TableCell
						className={classnames(classes.cellPadding, classes.headerCell, classes.headerField, classes.headerSectionParent)}
						colSpan={sectionIntermediaire.fields.length}
					>
					<OverflowTypography variant="body1" className={classes.chipsFilter}>{sectionIntermediaire.title}</OverflowTypography>
					</TableCell>
				)

			// Si fields direct
			if (racine.fields.length > 0) {
				let cellules = []
				cellules.push(<TableCell
					className={classnames(classes.cellPadding, classes.headerCell, classes.headerField, { [classes.fiHeaderTop]: getFiTitles().includes(racine.title) })}
					colSpan={racine.fields.length}
				>&nbsp;</TableCell>)
				return cellules.concat(sectionsIntermediaires)
			}
			return sectionsIntermediaires
		})
	}

	const renderFieldsInHeader = () => {
		return headerLabels.map(racine => {
			const champsDirects = racine.fields.map(field => <TableCell
					className={classnames(classes.cellPadding, classes.headerField, classes.contentCell)}
				>
					{field.title}
				</TableCell>
			)
			const champsIndirects = Object.values(racine.sections).map(section =>
				section.fields.map(field => <TableCell
						className={classnames(classes.cellPadding, classes.headerField, classes.contentCell)}
					>
						{field.title}
					</TableCell>
				)
			)
			return champsDirects.concat(champsIndirects)
		})
	}

	const renderReferenceCell = () => <TableCell className={classnames(classes.cellPadding, classes.titleCellHeader, classes.referenceCellFirstLine)}>
		<Fab key="rechercher" size="small" color="tertiary" onClick={() => setOpenSearchForm(true)}
			 variant="circular" type="submit" style={{ backgroundColor: 'white'}}
		>
			<SearchIcon className={classes.buttonLoupe} />
		</Fab>
		<span style={{ marginLeft: 4 }}>{TITLE_COLUMN_NAME[selectedLanguage]}</span>
	</TableCell>


	const renderZoneCell = () => <TableCell className={classnames(classes.cellPadding, classes.headerCell, classes.headerField, classes.racineStyle, classes.fiHeaderBottom)}>
		<span style={{ marginLeft: 4 }}>{ZONE_COLUMN_NAME[selectedLanguage]}</span>
	</TableCell>

	const renderHeader = () => (
		<TableHead>
			{/* RACINE */}
			<TableRow>
				{renderReferenceCell()}
				{renderZoneCell()}
				{headerLabels.map(racine => (
					<TableCell
						className={classnames(classes.cellPadding, classes.headerCell, classes.headerField, classes.racineStyle , { [classes.fiHeaderBottom]: getFiTitles().includes(racine.title) && headerLabels.length > 1 })}
						colSpan={racine.fields.length + getNbFieldInRacine(racine)}
					>
						{racine.title}
					</TableCell>
				))}
			</TableRow>

			{/* SECTIONS INTERMEDIAIRES */}
			{!onlyFiSectionSelected() &&
				<TableRow>
					<TableCell className={classnames(classes.cellPadding, classes.titleCellHeader, classes.referenceCellSecondLine)} />
					<TableCell className={classnames(classes.cellPadding, classes.headerCell, classes.referenceCellSecondLine)} />
					{renderSectionsIntermediairesInHeader()}
				</TableRow>
			}

			{/* CHAMPS */}
			<TableRow>
				<TableCell className={classnames(classes.cellPadding, classes.titleCellHeader, classes.referenceCellThirdLine)} />
				<TableCell className={classnames(classes.cellPadding, classes.headerCell, classes.referenceCellThirdLine)} />
				{renderFieldsInHeader()}
			</TableRow>
		</TableHead>
	)


	const renderTableBody = () => {
		return (
			<TableBody>
				{content.length > 0 && content.map((row, keyContent) =>
					renderTableBodyRow(row, keyContent)
				)}
			</TableBody>
		)
	}

	const renderTableBodyRow = (row,keyContent) => {
		/* on va calculer le nb de ligne en recupérant dans les row.values celui a # le plus haut*/
		let maxIndexDuplication = 0

		Object.keys(row.values).forEach(keyValue => {
			if (keyValue.includes('#')) {
				const valueIndexDuplication = keyValue.substring(keyValue.indexOf('#') +1)
				if(valueIndexDuplication >=  maxIndexDuplication){
					maxIndexDuplication = valueIndexDuplication
				}
			}
		})

		return (
			<>
				<TableRow key={keyContent} className={classes.rows}>
					<TableCell
						rowSpan={parseInt(maxIndexDuplication) + 1}
						className={classnames(classes.cellPadding, classes.stickyCell)}
						key={`${keyContent}-${ATTRIBUTE_DATA_CONTENT.TITLE}`}
					>
						{onEdition ?
							<Link to={`/edition-document/${row.id}`} state={{ forceGoBack: 'true' }} className={classes.stickyCellLink}>
								<b><u>{row.values[ATTRIBUTE_DATA_CONTENT.TITLE].value}</u></b>
							</Link>:
							<Link to={`/consultation-document/${row.id}`} state={{ forceGoBack: 'true' }} className={classes.stickyCellLink}>
								<b><u>{row.values[ATTRIBUTE_DATA_CONTENT.TITLE].value}</u></b>
							</Link>
						}
					</TableCell>
					{getFormattedValueFromRow(row, 0,  maxIndexDuplication, ATTRIBUTE_DATA_CONTENT.ZONE)}
					{headerLabels.length > 0 && headerLabels.map(racine => {
						return [
							...racine.fields.map(field => getFormattedValueFromRow(row, 0, maxIndexDuplication, field.headerKey)),
							...racine.sections.map(section => section.fields.length > 0 && section.fields.map(field => getFormattedValueFromRow(row, 0, maxIndexDuplication, field.headerKey)))
						]
						})
					}
				</TableRow>
				{Array.from(Array(parseInt(maxIndexDuplication) + 1).keys()).filter(key => key>0).map(key =>
						<TableRow key={keyContent} className={classes.rows}>
							{headerLabels.length > 0 && headerLabels.map(racine => {
									return [
										...racine.fields.map(field => getFormattedValueFromRow(row, key, maxIndexDuplication, field.headerKey)),
										...racine.sections.map(section => section.fields.length > 0 && section.fields.map(field => getFormattedValueFromRow(row, key, maxIndexDuplication, field.headerKey)))
									]
								})
							}
						</TableRow>
					)
				}
			</>
		)
	}

	const onlyFiSectionSelected = () => headerLabels.length === 1 && getFiTitles().includes(headerLabels[0].title)

	return (
		<Grid>
			<Grid className={classnames(classes.tableContainer, { [classes.emptyTable]: content.length === 0 && !loading })}>
				<Table stickyHeader style={{ pointerEvents: loading ? 'none' : 'all' }}>
					{/* Header */}
					{headerLabels && content.length > 0 && renderHeader()}
					{content.length === 0 && <TableRow>{renderReferenceCell()}</TableRow>}

					{/* Loading state */}
					{loading && <Grid><CircularProgress className={classes.loader} /></Grid>}

					{/* Empty state */}
					{(!loading && content.length === 0) &&
						<Grid>
							<EmptyResult
								message={<FormattedMessage id="document.search.noResults" />}
								imgClassName={classes.emptyViewImg}
							/>
						</Grid>
					}
					{renderTableBody()}
				</Table>
			</Grid>
			{content.length > 0 &&
				<TablePagination
					component="div"
					count={total}
					rowsPerPage={size}
					rowsPerPageOptions={[]}
					page={page}
					onPageChange={(_, page) => handlePageChange(page)}
					labelRowsPerPage={intl.formatMessage({ id: 'isotope.datatable.footer.elementsPerPage' })}
					labelDisplayedRows={({ from, to, count }) => (
						intl.formatMessage({ id: 'isotope.datatable.footer.pagination' }, {
							startIndex: from,
							endIndex: to,
							total: count
						})
					)}
				/>
			}
			{/* Popin selection des colonnes */}
			{updateFieldPopin.open && <UpdateFieldPopin
				selectedLanguage={selectedLanguage}
				open={updateFieldPopin.open}
				onClose={closeUpdateFieldPopin}
				initialValues={updateFieldPopin.initialValues}
				data={data}
				closeUpdateFieldPopin={closeUpdateFieldPopin}
				location={location}
			/>}
		</Grid>
	)
}

const mapStateToProps = (state) => ({
	data: tabDocumentSelector.getTabDocumentData(state),
	idLanguage: userSelectors.getUserLanguage(state),
	allValueLists: valueListSelectors.getAllItemsByListCode(state)
})

const actions = {
	goToEdition: (id, state) => dispatch => dispatch(push({ pathname: `/edition-document/${id}`, state })),
	goToConsultation: (id, state) => dispatch => dispatch(push({ pathname: `/consultation-document/${id}`, state }))
}

TabDocumentTable.propTypes = {
	classes: PropTypes.object,
	data: PropTypes.object,
	loading: PropTypes.bool,
	selectedLanguage: PropTypes.number,
	setData: PropTypes.func,
	resetData: PropTypes.func,
	handlePageChange: PropTypes.func,
	onEdition: PropTypes.bool,
	goToEdition: PropTypes.func,
	goToConsultation: PropTypes.func,
	idLanguage: PropTypes.string,
	sources: PropTypes.array,
	headerLabels: PropTypes.array,
	langues: PropTypes.array,
	modelData: PropTypes.object,
	setOpenSearchForm: PropTypes.func,
	location: PropTypes.object
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(TabDocumentTable)
