import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { VALUE_LIST_SHORTCUTS } from '../../../utils/constants'
import { getItemsByListCode } from 'isotope-client/modules/valueList/services/valueListSelectors'
import * as userSelectors from '../../fo/user/services/userSelectors'
import { loadListFoByShortcut, loadAllLists } from './valueListActions'

export const injectZoneList = (WrappedComponent) => {
	const ZoneListInjector = ({ userLanguage, loadListFoByShortcut, ...props }) => {
		React.useEffect(() => {
			if (!props.zoneList || props.zoneList.length === 0) {
				loadListFoByShortcut(VALUE_LIST_SHORTCUTS.ZONE, userLanguage)
			}
		}, [])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		zoneList: getItemsByListCode(state)[VALUE_LIST_SHORTCUTS.ZONE],
		userLanguage: userSelectors.getUserLanguage(state)
	})

	const actions = {
		loadListFoByShortcut
	}

	ZoneListInjector.propTypes = {
		zoneList: PropTypes.array,
		userLanguage: PropTypes.string,
		loadListFoByShortcut: PropTypes.func
	}

	return connect(mapStateToProps, actions)(ZoneListInjector)
}

export const injectPaysList = (WrappedComponent) => {
	const PaysListInjector = ({ userLanguage, loadListFoByShortcut, ...props }) => {
		React.useEffect(() => {
			if (!props.paysList || props.paysList.length === 0) {
				loadListFoByShortcut(VALUE_LIST_SHORTCUTS.PAYS, userLanguage)
			}
		}, [])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		paysList: getItemsByListCode(state)[VALUE_LIST_SHORTCUTS.PAYS],
		userLanguage: userSelectors.getUserLanguage(state)
	})

	const actions = {
		loadListFoByShortcut
	}

	PaysListInjector.propTypes = {
		paysList: PropTypes.array,
		userLanguage: PropTypes.string,
		loadListFoByShortcut: PropTypes.func
	}

	return connect(mapStateToProps, actions)(PaysListInjector)
}

export const injectDeviseList = (WrappedComponent) => {
	const DevisListInjector = ({ userLanguage, loadListFoByShortcut, ...props }) => {
		React.useEffect(() => {
			if (!props.deviseList || props.deviseList.length === 0) {
				loadListFoByShortcut(VALUE_LIST_SHORTCUTS.DEVISE, userLanguage)
			}
		}, [])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		deviseList: getItemsByListCode(state)[VALUE_LIST_SHORTCUTS.DEVISE],
		userLanguage: userSelectors.getUserLanguage(state)
	})

	const actions = {
		loadListFoByShortcut
	}

	DevisListInjector.propTypes = {
		deviceList: PropTypes.array,
		userLanguage: PropTypes.string,
		loadListFoByShortcut: PropTypes.func
	}

	return connect(mapStateToProps, actions)(DevisListInjector)
}

export const injectSuList = (WrappedComponent) => {
	const SuListInjector = ({ userLanguage, loadListFoByShortcut, ...props }) => {
		React.useEffect(() => {
			if (!props.suList || props.suList.length === 0) {
				loadListFoByShortcut(VALUE_LIST_SHORTCUTS.SU, userLanguage)
			}
		}, [])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		suList: getItemsByListCode(state)[VALUE_LIST_SHORTCUTS.SU],
		userLanguage: userSelectors.getUserLanguage(state)
	})

	const actions = {
		loadListFoByShortcut
	}

	SuListInjector.propTypes = {
		suList: PropTypes.array,
		userLanguage: PropTypes.string,
		loadListFoByShortcut: PropTypes.func
	}

	return connect(mapStateToProps, actions)(SuListInjector)
}

export const injectSujetContactList = (WrappedComponent) => {
	const SujetContactListInjector = ({ userLanguage, loadListFoByShortcut, ...props }) => {
		React.useEffect(() => {
			if (!props.sujetContactList || props.sujetContactList.length === 0) {
				loadListFoByShortcut(VALUE_LIST_SHORTCUTS.SUJET_CONTACT, userLanguage)
			}
		}, [])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		sujetContactList: getItemsByListCode(state)[VALUE_LIST_SHORTCUTS.SUJET_CONTACT],
		userLanguage: userSelectors.getUserLanguage(state)
	})

	const actions = {
		loadListFoByShortcut
	}

	SujetContactListInjector.propTypes = {
		userLanguage: PropTypes.string,
		loadListFoByShortcut: PropTypes.func
	}

	return connect(mapStateToProps, actions)(SujetContactListInjector)
}

export const injectSujetDesContactList = (WrappedComponent) => {
	const SujetDesContactListInjector = ({ userLanguage, loadListFoByShortcut, ...props }) => {
		React.useEffect(() => {
			if (!props.sujetDesContactList || props.sujetDesContactList.length === 0) {
				loadListFoByShortcut(VALUE_LIST_SHORTCUTS.SUJET_DESCRIPTION_CONTACT, userLanguage)
			}
		}, [])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		sujetDesContactList: getItemsByListCode(state)[VALUE_LIST_SHORTCUTS.SUJET_DESCRIPTION_CONTACT],
		userLanguage: userSelectors.getUserLanguage(state)
	})

	const actions = {
		loadListFoByShortcut
	}

	SujetDesContactListInjector.propTypes = {
		sujetDesContactList: PropTypes.array,
		userLanguage: PropTypes.string,
		loadListFoByShortcut: PropTypes.func
	}

	return connect(mapStateToProps, actions)(SujetDesContactListInjector)
}

export const injectMarketList = (WrappedComponent) => {
	const MarketListInjector = ({ userLanguage, loadListFoByShortcut, ...props }) => {
		React.useEffect(() => {
			if (!props.marketList || props.marketList.length === 0) {
				loadListFoByShortcut(VALUE_LIST_SHORTCUTS.MARKET, userLanguage)
			}
		}, [])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		marketList: getItemsByListCode(state)[VALUE_LIST_SHORTCUTS.MARKET],
		userLanguage: userSelectors.getUserLanguage(state)
	})

	const actions = {
		loadListFoByShortcut
	}

	MarketListInjector.propTypes = {
		marketList: PropTypes.array,
		userLanguage: PropTypes.string,
		loadListFoByShortcut: PropTypes.func
	}

	return connect(mapStateToProps, actions)(MarketListInjector)
}

export const injectSectorList = (WrappedComponent) => {
	const SectorListInjector = ({ userLanguage, loadListFoByShortcut, ...props }) => {
		React.useEffect(() => {
			if (!props.sectorList || props.sectorList.length === 0) {
				loadListFoByShortcut(VALUE_LIST_SHORTCUTS.SECTOR, userLanguage)
			}
		}, [])
		
		return <WrappedComponent {...props} />
	}
	
	const mapStateToProps = state => ({
		sectorList: getItemsByListCode(state)[VALUE_LIST_SHORTCUTS.SECTOR],
		userLanguage: userSelectors.getUserLanguage(state)
	})
	
	const actions = {
		loadListFoByShortcut
	}
	
	SectorListInjector.propTypes = {
		sectorList: PropTypes.array,
		userLanguage: PropTypes.string,
		loadListFoByShortcut: PropTypes.func
	}
	
	return connect(mapStateToProps, actions)(SectorListInjector)
}

export const injectBusinessList = (WrappedComponent) => {
	const BusinessListInjector = ({ userLanguage, loadListFoByShortcut, ...props }) => {
		React.useEffect(() => {
			if (!props.businessList || props.businessList.length === 0) {
				loadListFoByShortcut(VALUE_LIST_SHORTCUTS.BUSINESS, userLanguage)
			}
		}, [])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		businessList: getItemsByListCode(state)[VALUE_LIST_SHORTCUTS.BUSINESS],
		userLanguage: userSelectors.getUserLanguage(state)
	})

	const actions = {
		loadListFoByShortcut
	}

	BusinessListInjector.propTypes = {
		businessList: PropTypes.array,
		userLanguage: PropTypes.string,
		loadListFoByShortcut: PropTypes.func
	}

	return connect(mapStateToProps, actions)(BusinessListInjector)
}

export const injectBusinessSegmentList = (WrappedComponent) => {
	const BusinessSegmentListInjector = ({ userLanguage, loadListFoByShortcut, ...props }) => {
		React.useEffect(() => {
			if (!props.businessSegmentList || props.businessSegmentList.length === 0) {
				loadListFoByShortcut(VALUE_LIST_SHORTCUTS.BUSINESS_SEGMENT, userLanguage)
			}
		}, [])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		businessSegmentList: getItemsByListCode(state)[VALUE_LIST_SHORTCUTS.BUSINESS_SEGMENT],
		userLanguage: userSelectors.getUserLanguage(state)
	})

	const actions = {
		loadListFoByShortcut
	}

	BusinessSegmentListInjector.propTypes = {
		businessSegmentList: PropTypes.array,
		userLanguage: PropTypes.string,
		loadListFoByShortcut: PropTypes.func
	}

	return connect(mapStateToProps, actions)(BusinessSegmentListInjector)
}

export const injectBusinessSubSegmentList = (WrappedComponent) => {
	const BusinessSubSegmentListInjector = ({ userLanguage, loadListFoByShortcut, ...props }) => {
		React.useEffect(() => {
			if (!props.businessSubSegmentList || props.businessSubSegmentList.length === 0) {
				loadListFoByShortcut(VALUE_LIST_SHORTCUTS.BUSINESS_SUB_SEGMENT, userLanguage)
			}
		}, [])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		businessSubSegmentList: getItemsByListCode(state)[VALUE_LIST_SHORTCUTS.BUSINESS_SUB_SEGMENT],
		userLanguage: userSelectors.getUserLanguage(state)
	})

	const actions = {
		loadListFoByShortcut
	}

	BusinessSubSegmentListInjector.propTypes = {
		businessSubSegmentList: PropTypes.array,
		userLanguage: PropTypes.string,
		loadListFoByShortcut: PropTypes.func
	}

	return connect(mapStateToProps, actions)(BusinessSubSegmentListInjector)
}

export const injectCoreBusinessList = (WrappedComponent) => {
	const CoreBusinessListInjector = ({ userLanguage, loadListFoByShortcut, ...props }) => {
		React.useEffect(() => {
			if (!props.coreBusinessList || props.coreBusinessList.length === 0) {
				loadListFoByShortcut(VALUE_LIST_SHORTCUTS.CORE_BUSINESS, userLanguage)
			}
		}, [])


		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		coreBusinessList: getItemsByListCode(state)[VALUE_LIST_SHORTCUTS.CORE_BUSINESS],
		userLanguage: userSelectors.getUserLanguage(state)
	})

	const actions = {
		loadListFoByShortcut
	}

	CoreBusinessListInjector.propTypes = {
		coreBusinessList: PropTypes.array,
		userLanguage: PropTypes.string,
		loadListFoByShortcut: PropTypes.func
	}

	return connect(mapStateToProps, actions)(CoreBusinessListInjector)
}

export const injectBidBriefList = (WrappedComponent) => {
	const BidBriefListInjector = ({ userLanguage, loadListFoByShortcut, ...props }) => {
		React.useEffect(() => {
			if (!props.bidBriefList || props.bidBriefList.length === 0) {
				loadListFoByShortcut(VALUE_LIST_SHORTCUTS.BID_BRIEF, userLanguage)
			}
		}, [])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		bidBriefList: getItemsByListCode(state)[VALUE_LIST_SHORTCUTS.BID_BRIEF],
		userLanguage: userSelectors.getUserLanguage(state)
	})

	const actions = {
		loadListFoByShortcut
	}

	BidBriefListInjector.propTypes = {
		bidBriefList: PropTypes.array,
		userLanguage: PropTypes.string,
		loadListFoByShortcut: PropTypes.func
	}

	return connect(mapStateToProps, actions)(BidBriefListInjector)
}

export const injectWinLoseList = (WrappedComponent) => {
	const WinLoseListInjector = ({ userLanguage, loadListFoByShortcut, ...props }) => {
		React.useEffect(() => {
			if (!props.winLoseList || props.winLoseList.length === 0) {
				loadListFoByShortcut(VALUE_LIST_SHORTCUTS.WIN_LOSE_LIST, userLanguage)
			}
		}, [])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		winLoseList: getItemsByListCode(state)[VALUE_LIST_SHORTCUTS.WIN_LOSE_LIST],
		userLanguage: userSelectors.getUserLanguage(state)
	})

	const actions = {
		loadListFoByShortcut
	}

	WinLoseListInjector.propTypes = {
		winLoseList: PropTypes.array,
		userLanguage: PropTypes.string,
		loadListFoByShortcut: PropTypes.func
	}

	return connect(mapStateToProps, actions)(WinLoseListInjector)
}

// Load des values list par défaut utilisées dans l'ensemble de l'application
export const loadDefaultValueList = (WrappedComponent) => {
	const DefaultValueListInjector = ({ loadListFoByShortcut, userLanguage, ...props }) => {
		React.useEffect(() => {
			Object.values(VALUE_LIST_SHORTCUTS).forEach(shortcut => loadListFoByShortcut(shortcut, userLanguage))
		}, [userLanguage])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		userLanguage: userSelectors.getUserLanguage(state)
	})

	const actions = {
		loadListFoByShortcut
	}

	DefaultValueListInjector.propTypes = {
		userLanguage: PropTypes.string,
		loadListFoByShortcut: PropTypes.func
	}

	return connect(mapStateToProps, actions)(DefaultValueListInjector)
}

// Load des listes pour le backOffice
export const loadAllValueLists = (WrappedComponent) => {
	const AllValueListInjector = ({ loadAllLists, ...props }) => {
		React.useEffect(() => {
			loadAllLists()
		}, [])

		return <WrappedComponent {...props} />
	}

	const actions = {
		loadAllLists
	}

	AllValueListInjector.propTypes = {
		loadAllLists: PropTypes.func
	}

	return connect(undefined, actions)(AllValueListInjector)
}
