import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { FormSwitch, getStyles } from 'isotope-client'
import Grid from '@material-ui/core/Grid'
import { Field, FormSpy, useField } from 'react-final-form'
import Input from '../../../../../components/form/Input'
import { injectIntl, intlShape } from 'react-intl'
import Select from '../../../../../components/form/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { OnChange } from 'react-final-form-listeners'
import {
	ATTRIBUTE_DATA_MO_FIELD,
	FI_TRIPLET,
	FIELD_TYPE,
	REFERENCES_FI,
	SECTION_TYPE
} from '../../../../fo/document/utils/documentConstants'
import Checkbox from '../../../../../components/form/Checkbox'
import DonneeSelect from './type/DonneeSelect'
import { isRequired } from '../../../../../utils/utils'
import DonneeUnite from './type/DonneeUnite'
import DonneeTableau from './type/DonneeTableau'
import DonneeAttributeField from './type/DonneeAttributeField'
import { TYPE_CONTENU } from '../../../../../utils/constants'

const styles = () => getStyles({
	sectionItem: {
		whiteSpace: 'pre'
	},
	switch: {
		paddingLeft: 10
	}
})

const fieldTypes = {
	[SECTION_TYPE.FI]: [
		FIELD_TYPE.TEXT,
		FIELD_TYPE.TEXT_MULTILINE,
		FIELD_TYPE.SELECT,
		FIELD_TYPE.MULTI_SELECT,
		FIELD_TYPE.NUMBER,
		FIELD_TYPE.NUMBER_UNIT,
		FIELD_TYPE.AMOUNT,
		FIELD_TYPE.DATE,
		FIELD_TYPE.LINK,
		FIELD_TYPE.LINK_PROPOSITION
	],
	[SECTION_TYPE.STANDARD]: [
		FIELD_TYPE.TEXT,
		FIELD_TYPE.TEXT_MULTILINE,
		FIELD_TYPE.SELECT,
		FIELD_TYPE.MULTI_SELECT,
		FIELD_TYPE.NUMBER,
		FIELD_TYPE.NUMBER_UNIT,
		FIELD_TYPE.AMOUNT,
		FIELD_TYPE.DATE,
		FIELD_TYPE.FILE,
		FIELD_TYPE.LINK,
		FIELD_TYPE.LINK_PROPOSITION,
		FIELD_TYPE.TABLE,
		FIELD_TYPE.DUNS
	],
	[SECTION_TYPE.DOCUMENT]: [
		FIELD_TYPE.FILE,
		FIELD_TYPE.DUNS
	]
}

const notConfidential = new Set(FI_TRIPLET.map(elem => elem.fieldName))

const FIELDS_WITH_DEFAULT = [FIELD_TYPE.NUMBER_UNIT, FIELD_TYPE.NUMBER, FIELD_TYPE.AMOUNT]
const FIELDS_WITHOUT_CONFIDENTIALITY = [FIELD_TYPE.TABLE]

const EditionByType = ({ type, uniteCodeList, ...otherProps }) => {
	switch (type) {
		case FIELD_TYPE.SELECT:
		case FIELD_TYPE.MULTI_SELECT:
			return <DonneeSelect />
		case FIELD_TYPE.NUMBER_UNIT:
			return <DonneeUnite uniteCodeList={uniteCodeList} />
		case FIELD_TYPE.TABLE:
			return <DonneeTableau {...otherProps} />
		default:
			return <></>
	}
}

const EditionDonneePartieParametrage = ({ sectionsList, intl, classes, initParentSection, defaultLanguage, otherLanguages, isEdition, uniteCodeList, sectionPath, type }) => {
	const [typeSection, setTypeSection] = React.useState(null)
	const typeField = useField('type').input.value
	const refField = useField('reference').input.value
	const visibility = useField('attributeData.visibility').input.value
	const hasDefaultValue = !!typeField && FIELDS_WITH_DEFAULT.includes(typeField)
	const canBeConfidential = !notConfidential.has(refField) && !(!!typeField && FIELDS_WITHOUT_CONFIDENTIALITY.includes(typeField))
	React.useEffect(() => {
		const parentSection = Object.values(sectionsList).find(section => section.id === initParentSection)
		setTypeSection(parentSection ? parentSection.type : null)
	}, [initParentSection])

	return (
		<>
			<Field name="type" subscription={{}}>
				{({ input: { onChange } }) =>
					<FormSpy subscription={{}}>
						{({ form }) =>
							<>
								<OnChange name="parentSection">
									{value => {
										const parentSection = Object.values(sectionsList).find(section => section.id === value)
										setTypeSection(parentSection ? parentSection.type : null)
										if (form.getState().values !== null && !isEdition) {
											onChange(null)
										}
									}}
								</OnChange>
								<OnChange name="type">{() => {
									form.change('attributeData', {})
								}}</OnChange>
							</>
						}
					</FormSpy>
				}
			</Field>
			<Grid item xs={12}>
				<Field
					name="parentSection"
					label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.sectionParent' })} *`}
					component={Select}
					validate={isRequired}
					disabled={isEdition}
				>
					{Object.values(sectionsList).filter(section => section.path[0] === sectionPath[0]).map((section, index) => <MenuItem
						key={index}
						value={section.id}
						className={classes.sectionItem}
						disabled={section.type === SECTION_TYPE.RUBRIQUE || (section.type === SECTION_TYPE.DOCUMENT && (!!typeField && typeField !== FIELD_TYPE.FILE))}
					>
						{section.label}
					</MenuItem>)}
				</Field>
			</Grid>
			<Grid item xs={12}>
				<Field
					name={`name.${defaultLanguage.codeLang}`}
					label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.nomDonnee' }, { langue: intl.formatMessage({ id: `enums.langue.${defaultLanguage.codeLang}` }) })} *`}
					component={Input}
					autoComplete="off"
					inputProps={{
						maxLength: 150
					}}
					validate={isRequired}
				/>
			</Grid>
			<Grid item xs={6}>
				<Field
					name="type"
					label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.type' })} *`}
					component={Select}
					validate={isRequired}
					disabled={!typeSection || isEdition}
				>
					{!!typeSection && (fieldTypes[typeSection] || []).map((typeField, index) => <MenuItem
						key={index}
						value={typeField}
					>
						{intl.formatMessage({ id: `parametrage.donneeForm.fieldTypes.${typeField}` })}
					</MenuItem>)}
				</Field>
			</Grid>
			<Grid item xs={6}>
				<Field
					name="reference"
					label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.reference' })} *`}
					component={Input}
					autoComplete="off"
					validate={isRequired}
					disabled={isEdition}
				/>
			</Grid>
			<EditionByType type={typeField} defaultLanguage={defaultLanguage} otherLanguages={otherLanguages} uniteCodeList={uniteCodeList} />
			{hasDefaultValue && <><Grid item xs={6}>
				<DonneeAttributeField
					name={ATTRIBUTE_DATA_MO_FIELD.DEFAULT_VALUE}
					label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.defaut' })}`}
					component={Input}
					autoComplete="off"
					inputProps={{
						maxLength: 150
					}}
				/>
			</Grid><Grid item xs={6} /></>}
			{(!!typeField && typeField !== FIELD_TYPE.DUNS) &&
				<>
					<Grid item xs={12}>
						<DonneeAttributeField
							name="visibility"
							label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.visibility.check' })}`}
							component={Checkbox}
							autoComplete="off"
						/>
					</Grid>
					{visibility && <Grid item xs={12} container>
						<Grid item xs={6}>
							<DonneeAttributeField
								name={`${ATTRIBUTE_DATA_MO_FIELD.VISIBILITY_REF}`}
								label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.visibility.reference' })}`}
								component={Input}
								autoComplete="off"
								inputProps={{
									maxLength: 150
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<DonneeAttributeField
								name={`${ATTRIBUTE_DATA_MO_FIELD.VISIBILITY_VALUE}`}
								label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.visibility.code' })}`}
								component={Input}
								autoComplete="off"
								inputProps={{
									maxLength: 150
								}}
							/>
						</Grid>
					</Grid>}
				</>
			}

			{type === TYPE_CONTENU.REFERENCE &&
			<Grid item xs={12}>
				<Field
					name="donneeAvancee"
					label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.donneeAvancee' })}`}
					component={Checkbox}
					autoComplete="off"
				/>
			</Grid>
			}

			{canBeConfidential && <>
				<Grid item xs={12}>
					<Field
						name="confidentiable"
						label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.confidentiable' })}`}
						component={Checkbox}
						autoComplete="off"
					/>
				</Grid>
				<Grid item xs={12}>
					<Field
						name={`help.${defaultLanguage.codeLang}`}
						label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.help' }, { langue: intl.formatMessage({ id: `enums.langue.${defaultLanguage.codeLang}` }) })}`}
						component={Input}
						autoComplete="off"
					/>
				</Grid>
			</>}

			<Grid item xs={12} className={classes.switch}>
				<Field
					name="published"
					label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.publier' })}`}
					component={FormSwitch}
					disabled={isEdition && REFERENCES_FI.includes(refField)}
					color="primary"
				/>
			</Grid>
		</>
	)
}

EditionDonneePartieParametrage.propTypes = {
	sectionsList: PropTypes.object,
	intl: intlShape,
	initParentSection: PropTypes.string,
	uniteCodeList: PropTypes.string,
	defaultLanguage: PropTypes.object,
	isEdition: PropTypes.bool,
	otherLanguages: PropTypes.array,
	classes: PropTypes.object,
	sectionPath: PropTypes.array,
	type: PropTypes.string
}

export default compose(
	injectIntl,
	withStyles(styles)
)(EditionDonneePartieParametrage)
