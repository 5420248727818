import { combineReducers } from 'redux'
import * as actions from './favorisActions'

const initRecherche = {}
const recherche = (state = initRecherche, action) => {
	switch (action.type) {
		case actions.GET_RECHERCHE_FAVORIS_SUCCESS:
			return action.favoris.reduce((acc, fav) => ({
				...acc,
				[fav.id]: fav
			}), {})
		case actions.ADD_RECHERCHE_TO_FAVORITE_SUCCESS:
			return {
				...state,
				[action.favoris.id]: action.favoris
			}
		case actions.REMOVE_RECHERCHE_FROM_FAVORITE_SUCCESS: {
			const newState = { ...state }
			delete newState[action.id]
			return newState
		}
		default:
			return state
	}
}

const initDocument = {}
const document = (state = initDocument, action) => {
	switch (action.type) {
		case actions.GET_DOCUMENT_FAVORIS_SUCCESS:
			return action.favoris.reduce((acc, fav) => ({
				...acc,
				[fav.idContent]: fav
			}), {})
		case actions.ADD_DOCUMENT_TO_FAVORITE_SUCCESS:
			return {
				...state,
				[action.favoris.idContent]: action.favoris
			}
		case actions.REMOVE_DOCUMENT_FROM_FAVORITE_SUCCESS: {
			const newState = { ...state }
			const docToDelete = Object.values(newState).find(doc => doc.idContent === action.id)
			if (!!docToDelete) {
				delete newState[docToDelete.idContent]
			}
			return newState
		}
		default:
			return state
	}
}

export const favoris = combineReducers({
	recherche,
	document
})
