import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import { compose } from 'redux'
import Select from '../../../../../components/form/Select'
import { getPropositions } from '../../services/documentApi'
import { ATTRIBUTE_DATA_MO_FIELD } from '../../utils/documentConstants'
import { getFieldName } from '../../utils/documentUtils'

const styles = theme => getStyles({
	consultationContainer: {
		paddingBottom: 24
	},
	consultationLink: {
		color: theme.palette.primary.main,
		fontWeight: 400
	}
})

const DocumentPropositionField = ({ field, duplicatedNumber, selectedLanguage, classes, consultation, initialValues }) => {
	const [propositions, setPropositions] = React.useState([])

	React.useEffect(() => {
		getPropositions()
			.then(propositions => setPropositions(propositions))
	}, [])

	if (!field) {
		return <></>
	}
	const data = field.attributeData[selectedLanguage]
	const fieldProps = {
		name: `${getFieldName(field, duplicatedNumber)}.value`,
		label: data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] && `${data[ATTRIBUTE_DATA_MO_FIELD.NAME].value} ${field.required ? '*' : ''}`
	}

	if (consultation) {
		return <Grid item container xs={12} className={classes.consultationContainer}>
			<Grid item xs={4}>
				<Typography variant="caption">{fieldProps.label}</Typography>
			</Grid>
			<Grid item container xs={8}>
				{propositions.filter(proposition => initialValues === proposition.id).map(proposition => {
					return <Typography key={`link_proposition-${proposition.id}`} variant="body1">
						<a target="_blank" rel="noopener noreferrer" className={classes.consultationLink}
						   href={`/consultation-document/${proposition.id}`}>
							{proposition.labels && (proposition.labels[selectedLanguage] || proposition.labels[Object.keys(proposition.labels)[0]])}
						</a>
					</Typography>
				})}
			</Grid>
		</Grid>
	} else {
		return <Field
			{...fieldProps}
			component={Select}
		>
			<MenuItem key={`select-${field.id}-default`} value={0} />
			{propositions.map(proposition => <MenuItem key={`propositionComm-${field.id}-${proposition.id}`} value={proposition.id}>
				{proposition.labels && (proposition.labels[selectedLanguage] || proposition.labels[Object.keys(proposition.labels)[0]])}
			</MenuItem>)}
		</Field>
	}
}

DocumentPropositionField.propTypes = {
	field: PropTypes.object,
	selectedLanguage: PropTypes.string,
	consultation: PropTypes.bool,
	initialValues: PropTypes.string
}

export default compose(
	withStyles(styles)
)(DocumentPropositionField)
