import * as api from './likeApi'

export const HANDLE_LIKE_REQUEST = 'HANDLE_LIKE_REQUEST'
export const HANDLE_LIKE_SUCCESS = 'HANDLE_LIKE_SUCCESS'
export const HANDLE_LIKE_ERROR = 'HANDLE_LIKE_ERROR'
export const handleLike = (documentId, documentType, isDeleted) => dispatch => {
	dispatch({ type: HANDLE_LIKE_REQUEST })
	return api.likeDocument(documentId, documentType)
		.then(() => dispatch({ type: HANDLE_LIKE_SUCCESS, documentId, isDeleted }))
		.catch(() => dispatch({ type: HANDLE_LIKE_ERROR }))
}

export const GET_LIKE_REQUEST = 'GET_LIKE_REQUEST'
export const GET_LIKE_SUCCESS = 'GET_LIKE_SUCCESS'
export const GET_LIKE_ERROR = 'GET_LIKE_ERROR'
export const getLikes = () => dispatch => {
	dispatch({ type: GET_LIKE_REQUEST })

	return api.getLikes()
		.then(likes => dispatch({ type: GET_LIKE_SUCCESS, likes }))
		.catch(() => dispatch({ type: GET_LIKE_ERROR }) )
}
