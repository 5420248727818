import React from 'react'
import { FormattedMessage } from 'react-intl'
import { DOCUMENT_STATUS, FI_FACILITATEUR, FI_TRIPLET, SECTION_TYPE } from '../modules/fo/document/utils/documentConstants'
import { FIELDS_ADMIN_POPIN, GlOBAL_LANGUAGE_ID, USER_PREFERENCES } from './constants'

/**
 * Retourne un composant React (si possible internationalisé) adapté au message passé en paramètre.
 *
 * @param message
 * @returns {*}
 */
export const toI18N = message => {
	if (React.isValidElement(message)) {
		return message
	}
	if (typeof message === 'string') {
		return <span>{message}</span>
	}
	return <FormattedMessage {...message} />
}

/**
 * Convertit un objet en une string pouvant être passée dans une requête GET
 * @param params l'objet à convertir
 * @returns {string} une string de la forme [cle1]=[valeur1]&[cle2]=[valeur2]&...
 */
export const formatQuery = (params) => Object.keys(params)
	.filter(key => params[key])
	.map(key => `${key}=${params[key]}`)
	.join('&')

/**
 *
 * @param valueParent
 * @param list
 * @returns {*} la liste de valeur filtrée si on avait une valeur parent et complète sinon
 */
export const splitSubListByValue = (valueParent, list) => {
	if(!valueParent || valueParent === '' || !list){
		// Comme on n'a pas de valeur parent, on retourne la liste complète
		return list
	} else {
		// On split la liste ici
		return Object.keys(list)
			.map(itemId => list[itemId])
			.filter(item => {
				if (!item.code) {
					return false
				}
				// Gestion multiselect
				if (Array.isArray(valueParent)) {
					return valueParent.some(valueParentItem =>
						item.code.startsWith(`${valueParentItem}$_`) || item.code.startsWith(`${getValueAll(valueParentItem)}$_`)
					)
				}

				return item.code.startsWith(`${valueParent}$_`) || item.code.startsWith(`${getValueAll(valueParent)}$_`)
			})
			.sort((item1, item2) => item1.label.localeCompare(item2.label))
	}
}

/**
 * Cas spécifique du filtrage des pays par zone
 * @param valueParent
 * @param list
 * @returns {*} la liste de valeur filtrée si on avait une valeur parent et complète sinon
 */
export const splitSubListPaysByValue = (valueParent, list) => {
	if(!valueParent || valueParent === '' || !list){
		// Comme on n'a pas de valeur parent, on retourne la liste complète
		return list
	} else {
		// On split la liste ici
		return Object.keys(list)
			.map(itemId => list[itemId])
			.filter(item => {
				if (!item.code) {
					return false
				}
				// Gestion multiselect
				if (Array.isArray(valueParent)) {
					return valueParent.some(valueParentItem =>
						(item.code.startsWith(`${valueParentItem}$_`) || item.code.startsWith(`${valueParentItem}&&&`)) || (item.code.startsWith(`${getValueAll(valueParentItem)}$_`) || item.code.startsWith(`${getValueAll(valueParentItem)}&&&`))
					)
				}

				return (item.code.startsWith(`${valueParent}$_`) || item.code.startsWith(`${valueParent}&&&`)) || (item.code.startsWith(`${getValueAll(valueParent)}$_`) || item.code.startsWith(`${getValueAll(valueParent)}&&&`) )
			})
			.sort((item1, item2) => item1.label.localeCompare(item2.label))
	}
}

/**
 * Fonction qui permet de constuire le string pour une recherche en #ALL
 * Utilisé pour le conditionnement des listes de valeurs
 * @param parentValue
 */
const ALL_REG = '#ALL'
const getValueAll = (parentValue) => {
	const lastIndex = parentValue.lastIndexOf('$_')
	return lastIndex !== -1 ? `${parentValue.substring(0, lastIndex)}$_${ALL_REG}` : ALL_REG
}

export const bytesToSize = (size) => {
	const sizes = ['Bytes', 'Ko', 'Mo', 'Go', 'To']
	const bytes = parseInt(size, 10)

	if (!bytes || bytes === 0) return undefined
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
	if (i === 0) return `${bytes} ${sizes[i]}`
	const toBytes = (bytes / Math.pow(1024, i)).toFixed(1)
	return `${toBytes} ${sizes[i]}`
}

/**
 * Vérifie que nous pouvons parser le tableau
 * @param str
 * @returns {boolean}
 */
export const isJsonArray = (str) => {
	try {
		const parsing = JSON.parse(str)
		return Array.isArray(parsing)
	} catch (e) {
		return false
	}
}

/**
 * Merge deep sur un niveau
 * @param o1
 * @param o2
 * @returns {{[p: string]: undefined}}
 */
export const mergeDeep = (o1, o2) => Object.keys(o1)
	.reduce((acc, curr) => ({
		...acc,
		[curr]: {
			...(o1[curr] || {}),
			...(acc[curr] || {})
		}
	}), o2)

/**
 * Validation "champ obligatoire" pour redux-final-form
 * @param value
 * @returns {*}
 */
export const isRequired = value => value ? undefined : { id: 'global.errors.obligatoire' }

/**
 * Déplace un object d'un index à un autre dans une liste
 * @param list la liste
 * @param startIndex l'index de départ
 * @param endIndex l'index de retour
 * @returns {any[]} la liste mise à jour
 */
export const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)
	return result
}

export const readPreferenceList = (user, preferenceName, filter) => {
	if(!user || !user.preferences){
		return []
	}
	const preferences = user.preferences
	const result = []
	Object.keys(preferences).forEach((key) => {
		if(key.startsWith(preferenceName)){
			result.push(key.replace(preferenceName, ''))
		}
	})
	if(filter) {
		return result.filter(element => element.startsWith(filter))
	}
	return result
}

export const isCorrespondantValidForDocumentSearch = (user, document) => {
	for (let index = 0; index < FI_TRIPLET.length; index++){
		const prefName = FI_TRIPLET[index].pref
		const fieldName = FI_TRIPLET[index].fieldNameSearch
		const prefs = readPreferenceList(user, prefName)
		const field = document[fieldName]
		if(!field || !field.valueField || !field.valueField[0]){
			return false
		}
		const attributeData =  field.valueField[0]
		const values = JSON.parse(attributeData)
		if(values.every(value => prefs.indexOf(value) === -1)){
			return false
		}
	}
	return true
}

export const isCorrespondantValidForDocument = (user, document) => {
	if(!document.sections){
		return false
	}
	const section = document.sections.find(section => section.type === SECTION_TYPE.FI)
	if(!section){
		return false
	}
	for (let index = 0; index < FI_TRIPLET.length; index++){
		const prefName = FI_TRIPLET[index].pref
		const fieldName = FI_TRIPLET[index].fieldName
		const prefs = readPreferenceList(user, prefName)
		const field = section.fields.find(field => field.reference === fieldName)

		if(!field || !field.attributeData || !field.attributeData[0]){
			return false
		}
		const attributeData =  field.attributeData[0]
		const values = JSON.parse(attributeData.VALUE.value)

		if(values.every(value => prefs.indexOf(value) === -1)){
			return false
		}
	}
	return true
}

export const isMemberValidForDocument = (user, document) => {
	if(!document.sections){
		return false
	}
	const section = document.sections.find(section => section.type === SECTION_TYPE.FI)
	if(!section){
		return false
	}

	for (let index = 0; index < FI_FACILITATEUR.length; index++){
		const prefName = FI_FACILITATEUR[index].pref
		const fieldName = FI_FACILITATEUR[index].fieldName
		const prefs = readPreferenceList(user, prefName)
		const field = section.fields.find(field => field.reference === fieldName)

		if(field && field.attributeData && field.attributeData[0]){
			const attributeData =  field.attributeData[0]
			const values = JSON.parse(attributeData.VALUE.value)

			for(let indexValues = 0; indexValues < values.length; indexValues++){
				if(prefs.indexOf(values[indexValues]) !== -1){
					return true
				}
			}
			if(values.every(value => prefs.indexOf(value) > -1)){
				return true
			}
		}
	}
	return false
}

export const isMemberValidForDocumentSearch = (user, document) => {
	for (let index = 0; index < FI_FACILITATEUR.length; index++){
		const prefName = FI_FACILITATEUR[index].pref
		const fieldName = FI_FACILITATEUR[index].fieldNameSearch
		const prefs = readPreferenceList(user, prefName)
		const field = document[fieldName]

		if(field && field.valueField && field.valueField[0]){
			const attributeData =  field.valueField[0]
			const values = JSON.parse(attributeData)

			for(let indexValues = 0; indexValues < values.length; indexValues++){
				if(prefs.indexOf(values[indexValues]) !== -1){
					return true
				}
			}
			if(values.every(value => prefs.indexOf(value) > -1)){
				return true
			}
		}
	}
	return false
}

export const isMemberValidForChamps = (user, sector, subSegment) => {
	for (let index = 0; index < FI_FACILITATEUR.length; index++){
		const prefName = FI_FACILITATEUR[index].pref
		const fieldName = FI_FACILITATEUR[index].fieldName
		const prefs = readPreferenceList(user, prefName)

		if(prefs && prefs.length >0 && sector && sector.length >0 && USER_PREFERENCES.FI_PRE_CLIENT_SECTOR === fieldName){
			for(let indexSector = 0; indexSector < sector.length; indexSector++){
				if(prefs.indexOf(sector[indexSector]) !== -1){
					return true
				}
			}
		}
		if(prefs && prefs.length >0 && subSegment && subSegment.length >0 && USER_PREFERENCES.FI_SUBSEGMENT === fieldName){
			for(let indexSubSegment = 0; indexSubSegment < subSegment.length; indexSubSegment++){
				if(prefs.indexOf(subSegment[indexSubSegment]) !== -1){
					return true
				}
			}
		}
	}
	return false
}

export const removeZeroFromList = list => {
	if(list && ((!list.length) || (list.length === 1 && list[0] === 0))){
		return undefined
	}
	return list
}

export const sortObjectByLabel = (array) => array.sort((a,b) => a.label.localeCompare(b.label))

// Change les fields de la popin filtre administrateur
// (utilisation mutuelle au montage de la popin et lors de l interaction inteerne de la popin)
export const loadFilterForEdition = (change, filter = undefined) => {
	if (filter && filter.typeFilter) {
		change(FIELDS_ADMIN_POPIN.SECTION_FIELD, filter.typeFilter)
		if (filter.typeFilter.startsWith('date')) {
			change(FIELDS_ADMIN_POPIN.CHOIX_TIMELINE, filter.value[0])
			change(FIELDS_ADMIN_POPIN.CHAMP_DATE_1, filter.value[1])
			if (filter.value.length === 3) {
				change(FIELDS_ADMIN_POPIN.CHAMP_DATE_2, filter.value[2])
			}
		} else {
			change(filter.typeFilter, filter.value)
		}
	} else {
		change(FIELDS_ADMIN_POPIN.SECTION_FIELD, null)
	}
}

/**
 * Un document apparaît comme étant publié si son status est PUBLIE ou si son previousStatus est PUBLIE
 * @param content - le document à contrôler
 * @param fromElastic - les données du contenu viennent d'Elastic ? (sinon de la BD)
 * @returns {*}
 */
export const isDocumentPublicOrToHideOrToDelete = (content, fromElastic) => {
	// Si le status actuel est PUBLIE
	// Ou si le status précédent est PUBLIE ET que le status actuel est A_SUPPRIMER ou A_MASQUER
	if (fromElastic) {
		const statusActuel = content.statusWorkFlow
		const statusPrecedent = content.previousStatusWorkFlow
		return (
			(statusActuel && statusActuel.valueField[GlOBAL_LANGUAGE_ID] === DOCUMENT_STATUS.PUBLIE) ||
			((statusPrecedent && statusPrecedent.valueField[GlOBAL_LANGUAGE_ID] === DOCUMENT_STATUS.PUBLIE) &&
				(statusActuel.valueField[GlOBAL_LANGUAGE_ID] === DOCUMENT_STATUS.A_MASQUER || statusActuel.valueField[GlOBAL_LANGUAGE_ID] === DOCUMENT_STATUS.A_SUPPRIMER))
		)
	}
	const statusActuel =  content.workflow.status
	const statusPrecedent = content.workflow.previousStatus
	return (statusActuel === DOCUMENT_STATUS.PUBLIE ||
		(statusPrecedent === DOCUMENT_STATUS.PUBLIE && (statusActuel === DOCUMENT_STATUS.A_MASQUER || statusActuel === DOCUMENT_STATUS.A_SUPPRIMER)))
}

/**
 * Vérifie si une demande de changement de status d'un document est en cours
 * @param documentTender - le document
 * @returns {boolean}
 */
export const isDocumentAMasquerOuASupprimer = (documentTender) => documentTender.workflow.status === DOCUMENT_STATUS.A_MASQUER || documentTender.workflow.status === DOCUMENT_STATUS.A_SUPPRIMER

/**
 * Vérifie si un document est BROUILLON ou A_VALIDER afin de bloquer l'option "MASQUER LE CONTENU"
 * @param value - le document
 * @returns {boolean}
 */
export const isDocumentBrouillonOuAValider = value => value === DOCUMENT_STATUS.BROUILLON || value === DOCUMENT_STATUS.A_VALIDER

/**
 * Vérifie si un document est VALIDE ou PUBLIE afin de bloquer
 * @param value - le document
 * @returns {boolean}
 */
export const isDocumentValideOuPublie = value => value === DOCUMENT_STATUS.VALIDE || value === DOCUMENT_STATUS.PUBLIE

export const findNameOrTitleFromAttributeData = (item, attributeName, idLanguage) =>
	Object.entries(item.attributeData)
		.filter(([key,value]) => value[attributeName] && value[attributeName].label === attributeName && value[attributeName].idLanguage === idLanguage)
		.map(([key, value]) => value[attributeName].value)

export const localCompare = (key, desc) => (element1, element2) => (desc ? -1 : 1) * element1[key].localeCompare(element2[key])

/**
 * Permet de sudivisé une liste en list de element égale
 */
export const spliceIntoChunks = (arr, chunkSize)  => {
	const res = [];
	while (arr.length > 0) {
		const chunk = arr.splice(0, chunkSize);
		res.push(chunk);
	}
	return res;
}
