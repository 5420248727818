import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import { ATTRIBUTE_DATA_MO_SECTION, COLLAPSE_MAX_LEVEL_SECTION_BO } from '../../../fo/document/utils/documentConstants'
import { DEFAULT_LANGUAGE_ID } from '../../../../utils/constants'
import { getUserLanguage } from '../../../fo/user/services/userSelectors'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const FONT_TITLE_LEVEL = {
	1: 'h2',
	2: 'h4',
	3: 'subtitle1',
	4: 'subtitle2'
}

const styles = theme => getStyles({
	divider: {
		backgroundColor: theme.palette.text.main,
		marginBottom: 10,
		height: 1
	},
	title: {
		marginBottom: 10,
		cursor: 'pointer'
	},
	titleSection: {
		paddingBottom: 10,
		cursor: 'pointer'
	},
	sectionChildren: {
		paddingLeft: 30
	},
	selected: {
		color: theme.palette.secondary.main
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	}
})

const InternalSummarySection = ({ idLanguage, key, section, level, selectedSectionId, handleSelectSection, classes, sectionPath }) => {
	const [collapse, setCollapse] = React.useState(false)
	const toggleCollapse = () => setCollapse(collapse => !collapse)

	const data = section.attributeData && (section.attributeData[idLanguage] || section.attributeData[DEFAULT_LANGUAGE_ID])
	const title = data[ATTRIBUTE_DATA_MO_SECTION.TITLE].value

	const hasChildren = Array.isArray(section.sections) && section.sections.length !== 0
	const isSelected = selectedSectionId === section.id

	const renderSectionChildren = () => section.sections.map((section) => <SummarySection
		key={section.id}
		section={section}
		level={level + 1}
		selectedSectionId={selectedSectionId}
		handleSelectSection={handleSelectSection}
		sectionPath={[...sectionPath, section.id]}
	/>)

	if (level <= COLLAPSE_MAX_LEVEL_SECTION_BO) {
		return <Grid item container key={key} direction="column">
			<Grid container alignItems="center">
				<Grid item xs={11}>
					<Typography
						id={key}
						variant={FONT_TITLE_LEVEL[level]}
						onClick={() => handleSelectSection({ ...section, level }, sectionPath)}
						className={classnames(classes.titleSection, { [classes.selected]: isSelected })}
					>
						{title}
					</Typography>
				</Grid>
				{hasChildren && <Grid item xs={1}>
					<IconButton
						className={classnames(classes.expand, { [classes.expandOpen]: !collapse })}
						onClick={toggleCollapse}
					>
						<ExpandMoreIcon />
					</IconButton>
				</Grid>}
			</Grid>
			<Divider classes={{ root: classes.divider }} />
			{hasChildren && <Collapse in={!collapse} timeout="auto">
				<Grid className={classes.sectionChildren}>
					{renderSectionChildren()}
				</Grid>
			</Collapse>}
		</Grid>
	}

	return <Grid item container key={key} direction="column">
		<Grid>
			<Typography
				variant={FONT_TITLE_LEVEL[level]}
				onClick={() => handleSelectSection({ ...section, level }, sectionPath)}
				className={classnames(classes.title, { [classes.selected]: isSelected })}
			>
				{data[ATTRIBUTE_DATA_MO_SECTION.TITLE].value}
			</Typography>
		</Grid>
		<Grid className={classes.sectionChildren}>
			{renderSectionChildren()}
		</Grid>
	</Grid>

}

InternalSummarySection.propTypes = {
	idLanguage: PropTypes.string,
	key: PropTypes.string,
	section: PropTypes.object,
	level: PropTypes.number,
	selectedSectionId: PropTypes.string,
	handleSelectSection: PropTypes.func,
	classes: PropTypes.object,
	sectionPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))
}

const mapStateToProps = state => ({
	idLanguage: getUserLanguage(state)
})
const SummarySection = compose(
	connect(mapStateToProps),
	withStyles(styles)
)(InternalSummarySection)

export default SummarySection
