import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FilledInput from '@material-ui/core/FilledInput'
import FormHelperText from '@material-ui/core/FormHelperText'
import MuiSelect from '@material-ui/core/Select'
import { getStyles } from 'isotope-client'
import mapError from './mapError'
import { toI18N } from '../../utils/utils'
import classnames from 'classnames'
import { colors } from '../../utils/constants'

const styles = () => getStyles({
	select: {
		width: '100%'
	},
	container: {
		paddingRight: 10,
		paddingLeft: 10,
		marginBottom: 15
	},
	input: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
	},
	root: {
		backgroundColor: colors.input
	},
	icon: {
		fontSize: 24
	}
})

const Select = ({ input: { value, active, ...inputProps }, meta: { error, touched }, id, label, children, disabled, classes, multiple, containerClassName }) => {
	const getMutlipleValues = () => {
		if (!!value && Array.isArray(value)) {
			return value
		}

		return []
	}

	return (<div className={classnames(classes.container, containerClassName)}>
		<FormControl variant="filled" className={classes.select} error={touched && !!error}>
			{!!label && <InputLabel htmlFor={id}>{label}</InputLabel>}
			<MuiSelect
				value={multiple ? getMutlipleValues() : value}
				disableUnderline={disabled}
				disabled={disabled}
				input={<FilledInput {...inputProps} className={classes.input} />}
				id={id}
				variant="filled"
				multiple={multiple}
				classes={{root: classes.root, icon: classes.icon}}
				MenuProps={{
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left'
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left'
					},
					getContentAnchorEl: null
				}}
			>
				{children}
			</MuiSelect>
			{typeof error !== 'undefined' && touched && <FormHelperText>{toI18N(error)}</FormHelperText>}
		</FormControl>
	</div>)
}

Select.propTypes = {
	input: PropTypes.object,
	meta: PropTypes.object,
	classes: PropTypes.object,
	containerClassName: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	id: PropTypes.string,
	children: PropTypes.node,
	disabled: PropTypes.bool,
	multiple: PropTypes.bool
}

Select.defaultProps = {
	containerClassName: ''
}

export default compose(
	mapError,
	withStyles(styles)
)(Select)

