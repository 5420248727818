import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { Field, useForm } from 'react-final-form'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Input from '../../../../../components/form/Input'
import { colors, DEFAULT_LANGUAGE_ID } from '../../../../../utils/constants'
import { loadListFoByShortcut } from '../../../../common/valueList/valueListActions'
import { ATTRIBUTE_DATA_MO_FIELD } from '../../utils/documentConstants'
import { parseNumber } from '../../utils/documentUtils'
import DocumentRowField from './DocumentRowField'
import { getFieldDunsContent } from '../../services/documentApi'
import { FormattedMessage, injectIntl } from 'react-intl'
import { OnChange } from 'react-final-form-listeners'
import DeleteIcon from '@material-ui/icons/Delete'
import LinkIcon from '@material-ui/icons/Link'
import FindReplaceIcon from '@material-ui/icons/FindReplace'
import Fab from '../../../../../components/Fab'

const styles = () => getStyles({
	consultationContainer: {
		paddingBottom: 24
	},
	fabContentSearch: {
		width: 40,
		height: 40,
		boxShadow: 'unset',
		color: 'white',
		backgroundColor: colors.primary
	},
	fabContentSearchWeb: {
		width: 40,
		height: 40,
		boxShadow: 'unset',
		color: 'white',
		backgroundColor: colors.primary
	},
	fabContentDelete: {
		width: 40,
		height: 40,
		boxShadow: 'unset',
		color: colors.text,
		backgroundColor: colors.tertiary
	},
	marginBottom: {
		marginBottom: 15
	},
	hidden:{
		display: "none"
	}
})

const DocumentUnitField = ({
	field,
	inputName,
	selectedLanguage,
	classes,
	duplicatedNumber,
	consultation,
	initialValues,
	intl
}) => {
	const data = field.attributeData[selectedLanguage] || field.attributeData[DEFAULT_LANGUAGE_ID]

	const form = useForm()
	const [dataDuns, setDataDuns] = React.useState(initialValues.dunsData ? JSON.parse(initialValues.dunsData) : {})
	const [dunsField, setDunsField] = React.useState(initialValues.value)
	const [loadingDuns, setloadingDuns] = React.useState(false)
	const [errorMessage, setErrorMessage] = React.useState("")

	React.useEffect(() => {
		if (initialValues && initialValues.value) {
			if(initialValues.dunsData){
				var dataBis = JSON.parse(initialValues.dunsData)
				setDataDuns(dataBis)
			}
			setDunsField(initialValues.value)

		} else {
			setDataDuns({})
			setDunsField("")
		}
	}, [selectedLanguage, initialValues])

	const visualData = () => {
		return (
			<>
				{(dataDuns && dataDuns.duns) &&
					<>
						<Grid item container xs={12}>
							<Grid item xs={4}>
								<Typography variant="caption"><FormattedMessage id="document.field.duns.dunsLabelName" /></Typography>
							</Grid>
							<Grid item container xs={8}>
								<Typography variant="body1">{`${dataDuns.name} - ${dataDuns.duns}`}</Typography>
							</Grid>
						</Grid>
						{(dataDuns.countryDuns) &&
							<Grid item container xs={12}>
								<Grid item xs={4}>
									<Typography variant="caption"><FormattedMessage id="document.field.duns.dunsCountryLabelName" /></Typography>
								</Grid>
								<Grid item container xs={8}>
									<Typography variant="body1">{`${dataDuns.countryName} - ${dataDuns.countryDuns}`}</Typography>
								</Grid>
							</Grid>
						}
						{(dataDuns.globalDuns) &&
							<Grid item container xs={12}>
								<Grid item xs={4}>
									<Typography variant="caption"><FormattedMessage id="document.field.duns.dunsGlobalLabelName" /></Typography>
								</Grid>
								<Grid item container xs={8}>
									<Typography variant="body1">{`${dataDuns.globalName} - ${dataDuns.globalDuns}`}</Typography>
								</Grid>
							</Grid>
						}
					</>
				}
			</>

		)
	}

	const loadDuns = () => {
		setloadingDuns(true)
		setErrorMessage(false)
		getFieldDunsContent(dunsField)
			.then(result => {
				if(!result.duns || result.duns === 0){
					setErrorMessage(intl.formatMessage({ id: 'document.field.duns.noresult' }))
					setDataDuns("")
				} else {
					setDataDuns(result)
				}
				setloadingDuns(false)
				form.change(`fields.${field.reference}.dunsData`,JSON.stringify(result))
			})
			.catch(() => {
				setDataDuns("")
				form.change(`fields.${field.reference}.dunsData`,"")
				setErrorMessage(intl.formatMessage({ id: 'document.field.duns.errorApi' }))
				setloadingDuns(false)
			})
	}

	const handleDelete = () => {
		setDataDuns("")
		setDunsField("")
		form.change(`fields.${field.reference}.value`, "")
		form.change(`fields.${field.reference}.dunsData`,"")
	}

	if (consultation) {
		if (!initialValues.value) {
			return <></>
		}
		return <>
			<Grid item container xs={12} className={classes.consultationContainer}>
				<Grid item xs={4}>
					<Typography variant="caption">{inputName}</Typography>
				</Grid>
				<Grid item container xs={8}>
				</Grid>
				{visualData()}
			</Grid>
		</>
	} else {
		return <>
			<DocumentRowField field={field} duplicatedNumber={duplicatedNumber} attData={data}>
				<Grid item xs={5}>
					<Field
						name={`fields.${field.reference}.value`}
						label={data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] && `${data[ATTRIBUTE_DATA_MO_FIELD.NAME].value} ${field.required ? '*' : ''}`}
						component={Input}
						parse={parseNumber}
						error={errorMessage}
						onKeyPress= {(e) => {
							if (e.key === 'Enter') {
								loadDuns(e.target.value)
							}
						}}
					/>
					<div className={classes.hidden}>
						<Field
							name={`fields.${field.reference}.dunsData`}
							component={Input}
							type="hidden"
						/>
					</div>
					<OnChange name={`fields.${field.reference}.value`}>{value =>{
						setDunsField(value)}}
					</OnChange>
				</Grid>
				<Grid item container direction="row" xs={3}>
					<Grid item xs={4} className={classes.marginBottom}>
						<Fab loading={loadingDuns} color="primary" variant="contained" className={classes.fabContentSearch} onClick={loadDuns}>
							<FindReplaceIcon />
						</Fab>
					</Grid>
					<Grid item xs={4} className={classes.marginBottom}>
						<Fab className={classes.fabContentDelete} onClick={handleDelete}>
							<DeleteIcon />
						</Fab>
					</Grid>
					<Grid item xs={4} className={classes.marginBottom}>
						<a href="https://www.dnb.com/de-de/upik-en/" target="_blank" rel="noopener noreferrer" title={intl.formatMessage({ id: 'document.field.duns.searchLink' })}>
							<Fab className={classes.fabContentSearchWeb}>
								<LinkIcon />
							</Fab>
						</a>
					</Grid>
				</Grid>
			</DocumentRowField>
			{visualData()}
		</>
	}
}

DocumentUnitField.propTypes = {
	field: PropTypes.object.isRequired,
	inputName: PropTypes.string.isRequired,
	consultation: PropTypes.bool,
	initialValues: PropTypes.object
}

const mapStateToProps = (state) => {
	return {}
}

const actions = {
	loadListFoByShortcut
}

export default compose(
	withStyles(styles),
	injectIntl,
	connect(mapStateToProps, actions)
)(DocumentUnitField)
