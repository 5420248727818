import React from 'react'
import PropTypes from 'prop-types'
import { getStyles } from 'isotope-client'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import SvgIcon from '../../../../components/icon/SvgIcon'
import { SVG_ICONS } from '../../../../utils/constants'

const styles = theme => getStyles({
	iconDup: {
		marginBottom: '-10px',
		marginLeft: 18
	},
	colorIcon: {
		color: theme.palette.text.primary
	}
})

const LoadingDuplication = ({ section, duplicateSection, classes }) => {
	const [loadingDuplication, setLoadingDuplication] = React.useState(false)

	return( <IconButton
		disabled={loadingDuplication}
		onClick={() => {
			setLoadingDuplication(true)
			setTimeout(() => duplicateSection()
				.then(setLoadingDuplication), 500)
		}}
		className={classes.iconDup}
	>
		{loadingDuplication ? <CircularProgress size={22} /> : <SvgIcon path={SVG_ICONS.DUPLICATE} className={classes.colorIcon} />}
	</IconButton>)
}

LoadingDuplication.propTypes = {
	duplicateSection: PropTypes.func,
	classes: PropTypes.object
}

export default withStyles(styles)(LoadingDuplication)
