import { fetchFactory } from 'isotope-client'

export const updateOrder = (modelType, fieldList) => fetchFactory(`/document-model/update-field-order/${modelType}`, {
	method: 'post',
	body: JSON.stringify(fieldList)
})

export const postSection = (modelType, values) => fetchFactory(`/document-model/section?modelType=${modelType}`, {
	method: 'post',
	body: JSON.stringify(values)
})

export const updateSection = (id, modelType, values) => fetchFactory(`/document-model/section/${id}?modelType=${modelType}`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const postField = (field, modelType) => fetchFactory(`/document-model/field?modelType=${modelType}`, {
	method: 'post',
	body: JSON.stringify(field)
})

export const putField = (field, modelType, id) => fetchFactory(`/document-model/field/${id}?modelType=${modelType}`, {
	method: 'put',
	body: JSON.stringify(field)
})

export const deleteField = (modelType, id) => fetchFactory(`/document-model/field/${id}?modelType=${modelType}`, {
	method: 'delete'
})

export const deleteSection = (modelType, id) => fetchFactory(`/document-model/section/${id}?modelType=${modelType}`, {
	method: 'delete',
})

export const refreshEsModel = (modelType) => fetchFactory(`/data/elasticsearch/indexOneMo/${modelType}`)
