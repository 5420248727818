import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, formValueSelector, reduxForm, isInvalid } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import { Grid, withStyles } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'
import { envoyerContact } from './services/contactApi'
import CenteredLayout from '../../../components/layout/CenteredLayout'
import Input from '../../../components/form/Input'
import Select from '../../../components/form/Select'
import { VALUE_LIST_SHORTCUTS } from '../../../utils/constants'
import { injectSujetContactList, injectSujetDesContactList } from '../../common/valueList/ValueListInjector'
import * as listesSelectors from '../../bo/listes/listesSelectors'
import Actions from '../../../components/form/Actions'
import Button from '@material-ui/core/Button'
import { injectFormSnackbar } from 'isotope-client/components/snackbar/services/snackbarInjector'
import Paper from '@material-ui/core/Paper'
import classnames from 'classnames'

const styles = () => getStyles({
	paper: {
		padding: 20,
		minHeight:500
	},
	subject: {
		marginBottom: 0
	},
	object: {
		marginTop: 15
	},
	descriptionSujet: {
		paddingLeft: 22,
		paddingRight: 42,
	},
	buttonEnvoyer: {
		margin: 10,
	},
})

const FORM_NAME = 'contactForm'
const SUJET = 'sujet'
const OBJET = 'objet'
const MESSAGE = 'message'

const validate = (values) => {
	const errors = {}

	if (!values.sujet) {
		errors.sujet = { id: 'global.errors.obligatoire' }
	}
	if (!values.objet) {
		errors.objet = { id: 'global.errors.obligatoire' }
	}
	if (!values.message) {
		errors.message = { id: 'global.errors.obligatoire' }
	}
	return errors
}


const ContactPage = ({ initialize, classes, handleSubmit, isInvalid, snackError, sujetDesContactList, sujetContactList }) => {

	const envoyerFrom = (values) => {
		const sujet = sujetContactList.find(suj => suj.code === values[SUJET])
		return envoyerContact({
			...values,
			[SUJET]: sujet && sujet.label
		});
	}

	return <CenteredLayout>
		<form onSubmit={values => {
				if (isInvalid) {
					snackError({ id: 'global.errors.formulaire' })
				}
				handleSubmit(envoyerFrom)(values)
			}} >
			<Typography variant="h1"><FormattedMessage id="contact.title"/></Typography>
			<Grid container>
				<Grid item xs={12} sm={9}>
					<Paper className={classes.paper}>
						<Field
							name={SUJET}
							label={<FormattedMessage id="contact.field.sujet.label"/>}
							component={Select}
							containerClassName={classes.subject}
						>
							<MenuItem key={0} value={0}>
								<FormattedMessage id='contact.field.sujet.placeholder'/>
							</MenuItem>
							{sujetContactList && sujetContactList.map(sujetElement =>
								<MenuItem key={sujetElement.code} value={sujetElement.code}>
									{sujetElement.label}
								</MenuItem>
							)}
						</Field>

						{sujetDesContactList && sujetDesContactList.length === 1  &&
							<Typography variant="caption" className={classes.descriptionSujet} >{sujetDesContactList[0].label}</Typography>
						}

						<Field
							name={OBJET}
							label={<FormattedMessage id="contact.field.objet"/>}
							component={Input}
							containerClassName={classes.object}
						/>

						<Field
							name={MESSAGE}
							label={<FormattedMessage id="contact.field.message"/>}
							component={Input}
							multiline
							rows={9}
						/>

						<Actions>
							<Button
								key="envoyer"
								color="primary"
								variant="contained"
								type="submit"
								className={classnames(classes.button, classes.buttonEnvoyer)}
							>
								<FormattedMessage id="global.buttons.send"/>
							</Button>
						</Actions>
					</Paper>
				</Grid>
			</Grid>
		</form>
	</CenteredLayout>
}

const mapStateToProps = (state, { location }) => {
	const sujetSelected = formValueSelector(FORM_NAME)(state, SUJET)
	return {
		isInvalid: isInvalid(FORM_NAME)(state),
		sujetDesContactList: listesSelectors.getFilteredLists(state, sujetSelected, VALUE_LIST_SHORTCUTS.SUJET_DESCRIPTION_CONTACT),
		initialValues: {
			sujet: location && location.state && location.state.initialSujet
		},
	}
}

ContactPage.propTypes = {
	initialize: PropTypes.func,
	classes: PropTypes.object,
	handleSubmit: PropTypes.func,
	sujetContactList: PropTypes.array,
	sujetDesContactList: PropTypes.array,
	initialSujet: PropTypes.string,
}

export default compose(
	injectSujetContactList,
	injectSujetDesContactList,
	connect(mapStateToProps),
	reduxForm({
		form: FORM_NAME,
		validate
	}),
	withStyles(styles),
	injectFormSnackbar({ success: { id: 'contact.validation.message' } })
)(ContactPage)
