import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { Field, Form, FormSpy } from 'react-final-form'
import Input from '../../../../../../components/form/Input'
import { isRequired } from '../../../../../../utils/utils'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { getLists } from '../../../../../common/valueList/valueListSelectors'
import { connect } from 'react-redux'
import { ATTRIBUTE_DATA_MO_FIELD, FIELD_TYPE } from '../../../../../fo/document/utils/documentConstants'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '../../../../../../components/form/Select'
import DonneeAttributeField from './DonneeAttributeField'
import { OnChange } from 'react-final-form-listeners'
import Button from '../../../../../../components/Button'
import Popin from '../../../../../../components/Popin'
import arrayMutators from 'final-form-arrays'

const types = [
	FIELD_TYPE.TEXT,
	FIELD_TYPE.SELECT,
	FIELD_TYPE.NUMBER,
	FIELD_TYPE.DATE
]

const DonneeTableauPopin = ({ open, closePopin, defaultLanguage, intl, sources, otherLanguages, submit, initialValues, position }) => {
	const [isSelect, setIsSelect] = React.useState(initialValues && (initialValues.type === FIELD_TYPE.SELECT || initialValues.type === FIELD_TYPE.MULTI_SELECT))
	const isCreation = typeof position === 'undefined'

	return (
		<Form
			subscription={{ invalid: true, submitFailed: true }}
			onSubmit={submit}
			initialValues={initialValues}
			validateOnBlur
			mutators={{ ...arrayMutators }}
		>
			{({ handleSubmit }) =>
				<Popin
					open={open}
					onClose={closePopin}
					actions={[
						<Button
							color="inherit"
							variant="outlined"
							key="fermer"
							onClick={closePopin}
						>
							<FormattedMessage id="global.buttons.annuler" />
						</Button>,
						<FormSpy subscription={{ pristine: true }} key="formSpyBtn">
							{({ pristine }) =>
								<Button
									key="valider"
									color="primary"
									variant="contained"
									type="submit"
									disabled={pristine}
									onClick={handleSubmit}
								>
									<FormattedMessage id="global.buttons.save" />
								</Button>
							}
						</FormSpy>
					]}
					title={`${intl.formatMessage({ id: isCreation ? 'parametrage.donneeForm.fields.table.popin.addTitle' : 'parametrage.donneeForm.fields.table.popin.editTitle' })}`}
					content={
						<form onSubmit={handleSubmit}>
							<Grid container>
								<OnChange name="type">
									{value => {
										setIsSelect(value === FIELD_TYPE.SELECT || value === FIELD_TYPE.MULTI_SELECT)
									}}
								</OnChange>
								<Grid item xs={12}>
									<Field
										name={`name.${defaultLanguage.codeLang}`}
										label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.table.columnName' }, { langue: intl.formatMessage({ id: `enums.langue.${defaultLanguage.codeLang}` }) })} *`}
										component={Input}
										autoComplete="off"
										inputProps={{
											maxLength: 150
										}}
										validate={isRequired}
									/>
								</Grid>
								<Grid item xs={6}>
									<Field
										name="type"
										label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.type' })} *`}
										component={Select}
										autoComplete="off"
										validate={isRequired}
									>
										{types.map((typeField, index) => <MenuItem
											key={index}
											value={typeField}
										>
											{intl.formatMessage({ id: `parametrage.donneeForm.fieldTypes.${typeField}` })}
										</MenuItem>)}
									</Field>
								</Grid>
								<Grid item xs={6}>
									<Field
										name="reference"
										label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.reference' })} *`}
										component={Input}
										autoComplete="off"
										validate={isRequired}
										inputProps={{
											maxLength: 150
										}}
										disabled={!isCreation}
									/>
								</Grid>
								{isSelect && <Grid item xs={6}>
									<DonneeAttributeField
										name={ATTRIBUTE_DATA_MO_FIELD.SOURCE}
										label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.select.source' })} *`}
										component={Select}
									>
										{sources.map(s => <MenuItem key={s.id} value={s.shortcut}>
												{`${s.name} (${s.shortcut})`}
											</MenuItem>
										)}
									</DonneeAttributeField>
								</Grid>}
								{otherLanguages.map(langue => <Grid item xs={12} key={`lang-${langue.codeLang}`}>
									<Field
										name={`name.${langue.codeLang}`}
										label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.table.columnName' }, { langue: intl.formatMessage({ id: `enums.langue.${langue.codeLang}` }) })}`}
										component={Input}
										autoComplete="off"
										inputProps={{
											maxLength: 150
										}}
										validate={isRequired}
									/>
								</Grid>)}
							</Grid>
						</form>
					}
				/>
			}
		</Form>
	)
}

DonneeTableauPopin.propTypes = {
	open: PropTypes.bool,
	closePopin: PropTypes.func,
	defaultLanguage: PropTypes.object,
	intl: intlShape,
	sources: PropTypes.array,
	otherLanguages: PropTypes.object,
	submit: PropTypes.func,
	initialValues: PropTypes.object,
	position: PropTypes.number
}

const mapStateToProps = state => ({
	sources: getLists(state)
})

export default compose(
	injectIntl,
	connect(mapStateToProps)
)(DonneeTableauPopin)
