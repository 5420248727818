import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import React from 'react'

const styles = () => getStyles({
	button: {
		marginRight: 7
	}
})

/**
 * Conteneur pour aligner les boutons à droite
 */
const actions = ({ children, classes, className }) => {
	if (!children) {
		return null
	}

	if (children.length === undefined) {
		children = [children]
	}
	return <Grid
		container
		justify="flex-end"
		className={className}
	>
		{children.map((button, index) =>
			<Grid className={index + 1 < children.length ? classes.button : null} key={index} item>{button}</Grid>
		)}
	</Grid>
}

export default withStyles(styles)(actions)
