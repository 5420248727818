import React, { useCallback } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { getStyles } from 'isotope-client'
import Grid from '@material-ui/core/Grid'
import SectionBloc from './section/SectionBloc'
import DonneeBloc from './donnee/DonneeBloc'
import Button from '../../../../components/Button'
import { ATTRIBUTE_DATA_MO_FIELD, SECTION_TYPE } from '../../../fo/document/utils/documentConstants'
import ParentIcon from '@material-ui/icons/SubdirectoryArrowLeft'
import IconButton from '@material-ui/core/IconButton'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { DEFAULT_LANGUAGE_ID, GlOBAL_LANGUAGE_ID, TYPE_CONTENU } from '../../../../utils/constants'
import { deleteField, postField, putField, updateOrder } from '../services/parametrageActions'
import { getModelByType } from '../../../common/model/modelActions'
import EditionDonneePopin from './donnee/EditionDonneePopin'
import { injectLangues } from '../../../common/langue/langueInjector'
import { reorder } from '../../../../utils/utils'
import SectionDeletePopin from './section/popin/SectionDeletePopin'
import SectionParametragePopin from './section/popin/SectionParametragePopin'
import { getUserLanguage } from '../../../fo/user/services/userSelectors'
import DeleteFieldPopin from './donnee/popin/DeleteFieldPopin'

const styles = () => getStyles({
	goToParentContainer: {
		padding: '5px 0'
	},
	goToParent: {
		transform: 'rotate(90deg)'
	},
	content: {
		padding: '10px 50px',
		height: 'calc(100vh - 350px)',
		overflow: 'auto'
	},
	actionBar: {
		padding: '15px 20px',
		boxSizing: 'border-box',
		height: 60,
		borderTop: '1px rgba(0,0,0,0.08)solid'
	},
	button: {
		marginLeft: 20
	}
})

const ACTION_INIT = 'init'
const ACTION_REORDER = 'reorder'
const ACTION_RESET = 'reset'
const initialState = {
	sections: [],
	fields: {},
	init: [],
	current: [],
	orderChanged: false
}

const reducer = (state, action) => {
	switch (action.type) {
		case ACTION_INIT:
			const sections = action.payload.sections || []
			const fields = action.payload.fields || []
			const initOrder = fields.map(field => field.id)
			return {
				sections,
				fields: fields.reduce((acc, field) => ({
					...acc,
					[field.id]: field
				}), {}),
				init: initOrder,
				current: initOrder,
				orderChanged: false
			}
		case ACTION_REORDER:
			return {
				...state,
				current: action.payload,
				orderChanged: true
			}
		case ACTION_RESET:
			return {
				...state,
				current: state.init,
				orderChanged: false
			}
		default:
			throw new Error('Invalid action')
	}
}

/**
 * Affichage de l'ensemble du contenu d'une section
 * + Boutons d'interaction (ajout section/field)
 */
const DetailsSection = ({
	section,
	handleSelectSection,
	sectionPath,
	goToParent,
	classes,
	snackError,
	intl,
	updateOrder,
	type,
	snackSuccess,
	sectionsList,
	langues,
	postField,
	putField,
	selectedLanguage,
	deleteField
}) => {
	if (!section) {
		return <></>
	}

	/***********************************************/
	/*               Drag and drop                 */
	/***********************************************/
	const [state, dispatch] = React.useReducer(reducer, initialState)
	const [submitting, setSubmitting] = React.useState(false)
	const [openDeleteSection, setOpenDeleteSection] = React.useState(false)
	const [deletedSection, setDeletedSection] = React.useState(false)

	const init = useCallback(section => dispatch({ type: ACTION_INIT, payload: section }), [])
	const reorderFields = useCallback(order => dispatch({ type: ACTION_REORDER, payload: order }), [])
	const resetOrder = useCallback(() => dispatch({ type: ACTION_RESET }), [])

	React.useEffect(() => {
		init(section || [])
	}, [init, section])

	const getFields = React.useCallback(() => state.current.map(id => state.fields[id]), [state])

	const onDragEnd = ({ destination, source }) => {
		if (!destination) {
			return
		}
		if (destination.index === source.index) {
			return
		}
		reorderFields(reorder(state.current, source.index, destination.index))
	}

	const handleUpdateOrder = () => {
		setSubmitting(true)
		updateOrder(type, state.current, sectionPath)
			.then(() => {
				setSubmitting(false)
				snackSuccess({ id: 'parametrage.updateOrder.success' })
			})
			.catch(() => {
				setSubmitting(false)
				snackError()
			})
	}

	const [openSection, setOpenSection] = React.useState(false)
	const [editedSection, setEditedSection] = React.useState([])
	const [errorField, setErrorField] = React.useState(undefined)

	/***********************************************/
	/*            Popin edition donnée             */
	/***********************************************/
	const [editionDonneePopinState, setEditionDonneePopinState] = React.useState({
		open: false,
		initValues: {}
	})

	const openPopinEditionDonnee = (field) => {
		let initValues = {}
		const popinTitle = {}
		if (field !== null) {
			initValues = {
				reference: field.reference,
				parentSection: field.idMoSection,
				type: field.type,
				confidentiable: field.confidentiable,
				donneeAvancee: field.donneeAvancee,
				published: field.published
			}
			// Récupération des données à traduire
			initValues.name = langues.reduce((acc, langue) => {
				if (field.attributeData && field.attributeData[langue.id] && field.attributeData[langue.id][ATTRIBUTE_DATA_MO_FIELD.NAME]) {
					popinTitle[langue.id] = field.attributeData[langue.id][ATTRIBUTE_DATA_MO_FIELD.NAME].value
					return {
						...acc,
						[langue.codeLang]: field.attributeData[langue.id][ATTRIBUTE_DATA_MO_FIELD.NAME].value
					}
				}
				return acc
			}, {})
			initValues.help = langues.reduce((acc, langue) => {
				if (field.attributeData && field.attributeData[langue.id] && field.attributeData[langue.id][ATTRIBUTE_DATA_MO_FIELD.NAME]) {
					return {
						...acc,
						[langue.codeLang]: (field.attributeData[langue.id][ATTRIBUTE_DATA_MO_FIELD.HELP] || {}).value
					}
				}
				return acc
			}, {})

			initValues.attributeData = {
				...Object.values(field.attributeData[GlOBAL_LANGUAGE_ID] || {})
					.reduce((acc, attr) => ({
						...acc,
						[attr.label]: attr.value
					}), {}),
				reference: !!Object.keys(field.attributeData[GlOBAL_LANGUAGE_ID] || {}).find(key => key === ATTRIBUTE_DATA_MO_FIELD.PARENT_REFERENCE),
				visibility: !!Object.keys(field.attributeData[GlOBAL_LANGUAGE_ID] || {}).find(key => key === ATTRIBUTE_DATA_MO_FIELD.VISIBILITY_REF ||  key === ATTRIBUTE_DATA_MO_FIELD.VISIBILITY_VALUE)
			}

			initValues.fields = field.fields.map(tableField => {
				const formField = {
					id: tableField.id,
					reference: tableField.reference,
					type: tableField.type
				}

				formField.name = langues.reduce((acc, langue) => {
					if (tableField.attributeData && tableField.attributeData[langue.id] && tableField.attributeData[langue.id][ATTRIBUTE_DATA_MO_FIELD.NAME]) {
						return {
							...acc,
							[langue.codeLang]: tableField.attributeData[langue.id][ATTRIBUTE_DATA_MO_FIELD.NAME].value
						}
					}
					return acc
				}, {})

				formField.attributeData = Object.values(tableField.attributeData[GlOBAL_LANGUAGE_ID] || {})
					.reduce((acc, attr) => ({
						...acc,
						[attr.label]: attr.value
					}), {})

				return formField
			})

		} else {
			initValues = {
				parentSection: sectionPath && sectionPath.length ? section.id : null,
				published: true
			}
		}
		return setEditionDonneePopinState({
			open: true,
			initValues,
			id: field ? field.id : undefined,
			title: field && Object.keys(popinTitle).length > 0 ? popinTitle[selectedLanguage] || popinTitle[DEFAULT_LANGUAGE_ID] : undefined
		})
	}

	const closePopinEditionDonnee = () => {
		setEditionDonneePopinState({
			open: false,
			initValues: {}
		})
		setErrorField(undefined)
	}

	const handleSaveField = (values) => {
		const parentSectionPath = Object.values(sectionsList || {}).find(section => section.id === values.parentSection).path
		const saveField = editionDonneePopinState.id ? putField(type, values, editionDonneePopinState.id, parentSectionPath, sectionPath) : postField(type, values, parentSectionPath)
		return saveField
			.then(() => {
				setErrorField(undefined)
				snackSuccess()
				return closePopinEditionDonnee()
			})
			.catch(e => {
				if (e.bodyError && e.bodyError.id) {
					setErrorField(e.bodyError)
				} else {
					snackError()
				}
				throw e
			})
	}

	/***********************************************/
	/*          Popin suppression donnée           */
	/***********************************************/
	const [deleteFieldPopinState, setDeleteFieldPopinState] = React.useState({
		open: false
	})

	const openDeleteFieldPopin = (idField, name) => setDeleteFieldPopinState({
		open: true,
		idField,
		name
	})
	const closeDeleteFieldPopin = () => setDeleteFieldPopinState({
		open: false
	})

	const handleDeleteField = id => deleteField(type, id, sectionPath)
		.then(() => {
			snackSuccess()
			closeDeleteFieldPopin()
		})
		.catch(e => {
			snackError()
			throw e
		})


	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Grid item container direction="column" wrap="nowrap" className={classes.content}>
				{Array.isArray(sectionPath) && sectionPath.length !== 0 &&
				<Grid item className={classes.goToParentContainer}>
					<IconButton onClick={goToParent}>
						<ParentIcon className={classes.goToParent} />
					</IconButton>
				</Grid>}
				<Droppable droppableId="droppable">
					{(provided) => <div {...provided.droppableProps} ref={provided.innerRef}>
						{getFields().map((field, index) =>
							<DonneeBloc
								key={`fieldBloc-${section.id}-${field.id}`}
								field={field}
								index={index}
								openPopinEditionDonnee={openPopinEditionDonnee}
								deleteField={openDeleteFieldPopin}
							/>
						)}
						{provided.placeholder}
					</div>}
				</Droppable>
				{state.sections.map(sectionFille =>
					<SectionBloc
						key={`sectionBloc-${section.id}-${sectionFille.id}`}
						level={(section.level || 0) + 1}
						section={sectionFille}
						handleSelectSection={section => handleSelectSection(section, [...sectionPath, section.id])}
						handleEdit={() => {
							setEditedSection(sectionFille)
							setOpenSection(true)
						}}
						handleDelete={() => {
							setDeletedSection(sectionFille)
							setOpenDeleteSection(true)
						}}
					/>
				)}
			</Grid>
			<Grid container justify="flex-end" alignContent="center" className={classes.actionBar}>
				{(section.type !== SECTION_TYPE.DOCUMENT && section.type !== SECTION_TYPE.FI && type !== TYPE_CONTENU.SAVOIR_FAIRE && (!section.level || section.level < 6)) && <Button
					color="primary"
					key="newSection"
					onClick={() => {
						setEditedSection(null)
						setOpenSection(true)
					}}
					className={classes.button}
					disabled={submitting}
				>
					<FormattedMessage id="parametrage.btnSection" />
				</Button>}
				{(section.level > 0 && section.type !== SECTION_TYPE.RUBRIQUE)&& <Button
					color="primary"
					key="newField"
					onClick={() => openPopinEditionDonnee(null)}
					className={classes.button}
					disabled={submitting}
				>
					<FormattedMessage id="parametrage.btnField" />
				</Button>}
				<Button
					key="annuler"
					color="inherit"
					onClick={resetOrder}
					disabled={!state.orderChanged || submitting}
					className={classes.button}
				>
					<FormattedMessage id="global.buttons.annuler" />
				</Button>
				<Button
					key="save"
					color="primary"
					className={classes.button}
					disabled={!state.orderChanged}
					onClick={handleUpdateOrder}
					loading={submitting}

				>
					<FormattedMessage id="global.buttons.save" />
				</Button>
			</Grid>
			{openSection && <SectionParametragePopin
				open={openSection}
				onClose={() => setOpenSection(false)}
				initParentSection={section}
				parentSection={section}
				sectionPath={sectionPath}
				editedSection={editedSection}
				type={type}
				sectionsList={sectionsList}
			/>}
			{openDeleteSection && <SectionDeletePopin
				open={openDeleteSection}
				onClose={() => setOpenDeleteSection(false)}
				deletedSection={deletedSection}
				type={type}
				sectionPath={sectionPath}
			/>}
			<EditionDonneePopin
				{...editionDonneePopinState}
				type={type}
				closePopin={closePopinEditionDonnee}
				saveField={handleSaveField}
				sectionsList={sectionsList}
				errorField={errorField}
				sectionPath={sectionPath}
			/>
			<DeleteFieldPopin
				{...deleteFieldPopinState}
				closePopin={closeDeleteFieldPopin}
				deleteField={handleDeleteField}
			/>
		</DragDropContext>
	)
}

DetailsSection.propTypes = {
	section: PropTypes.object,
	sectionPath: PropTypes.arrayOf(PropTypes.string),
	handleSelectSection: PropTypes.func,
	goToParent: PropTypes.func,
	snackError: PropTypes.func,
	intl: intlShape,
	updateOrder: PropTypes.func,
	type: PropTypes.string,
	selectedLanguage: PropTypes.string,
	snackSuccess: PropTypes.func,
	sectionsList: PropTypes.object,
	langues: PropTypes.array,
	postField: PropTypes.func,
	putField: PropTypes.func,
	classes: PropTypes.object
}

const mapStateToProps = state => ({
	selectedLanguage: getUserLanguage(state)
})

const actions = {
	updateOrder,
	getModelByType,
	postField,
	putField,
	deleteField,
}

export default compose(
	injectIntl,
	injectLangues,
	injectSnackActions,
	connect(mapStateToProps, actions),
	withStyles(styles)
)(DetailsSection)
