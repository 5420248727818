import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { compose } from 'redux'
import {colors, DEFAULT_LANGUAGE_ID, ETATS_CHECKBOXES_COLUMNS} from '../../../../utils/constants'
import { connect } from 'react-redux'
import * as tabDocumentSelector from '../services/tabDocumentSelector'
import { updateChecked, updateCheckedAdvanced } from '../services/tabDocumentActions'
import { ATTRIBUTE_DATA_MO_FIELD } from '../../document/utils/documentConstants'
import {getStyles} from "isotope-client"
import withStyles from "@material-ui/core/styles/withStyles"

const styles = (theme) => getStyles({
	controlLabel: {
		marginTop: '10px',
		position: 'relative',
		'&:before': {
			backgroundColor: colors.primary ,
			width: '21px',
			content: 'no-open-quote',
			display: 'block',
			position:'absolute',
			left : '-9px',
			top : '50%',
			height : '2px'
		}
	}
})

/**
 * Composant représentant un champ dans la popin de selection
 * des colonnes à afficher pour le tableau de modification de documents
 */
const ItemFieldTabDocument = ({
	field,
	parentIds,
	selectedLanguage,
	isChecked,
	isAdvanced,
	updateChecked,
	updateCheckedAdvanced,
	classes
}) => {
	const data = field.attributeData && (field.attributeData[selectedLanguage] || field.attributeData[DEFAULT_LANGUAGE_ID])
	const fieldName = data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] ? data[ATTRIBUTE_DATA_MO_FIELD.NAME].value : undefined

	return (
		<FormControlLabel
			label={fieldName}
			name={fieldName}
			className={classes.controlLabel}
			control={
				<Checkbox
					id={field.id}
					checked={isChecked === ETATS_CHECKBOXES_COLUMNS.CHECKED}
					onChange={(e) => {
						isAdvanced
							? updateCheckedAdvanced(field.id, e.target.checked, false, parentIds)
							: updateChecked(field.id, e.target.checked, false, parentIds)
					}}
				/>
			}
		/>
	)
}

ItemFieldTabDocument.propTypes = {
	field: PropTypes.object,
	classes: PropTypes.object,
	parentIds: PropTypes.array,
	selectedLanguage: PropTypes.string,
	isAdvanced: PropTypes.bool,
	isChecked: PropTypes.bool,
	updateChecked: PropTypes.func,
	updateCheckedAdvanced: PropTypes.func
}

const mapStateToProps = (state, { field }) => ({
	isChecked: tabDocumentSelector.isChecked(state, field.id, false)
})

const actions = {
	updateChecked,
	updateCheckedAdvanced
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(ItemFieldTabDocument)
