import { withStyles } from '@material-ui/core'
import React from 'react'
import { compose } from 'redux'
import Paper from '@material-ui/core/Paper'
import { getStyles } from 'isotope-client'
import classnames from 'classnames'

const styles = (theme) => getStyles({
	paper: {
		height: 30,
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: theme.palette.border,
		boxShadow: 'none',
		textAlign: 'center',
		borderRadius: 5,
		width: 'fit-content',
		paddingLeft: 10,
		paddingRight: 10,
		display: 'inline-block',
		marginRight: 10,
		marginTop: 10,
	},
	text: {
		transform: 'translateY(30%)'
	}
})

const ChipInformation = ({ classes, text, className }) => (
	<Paper className={classnames(classes.paper, className)} >
		<div className={classes.text}>{text}</div>
	</Paper>
)

export default compose(
	withStyles(styles)
)(ChipInformation)
