import React from 'react'
import PropTypes from 'prop-types'
import LoginForm from './component/LoginForm'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import LoaderAdvanced from 'react-loader-advanced'
import CircularProgress from '@material-ui/core/CircularProgress'
import { loadConfiguration } from '../configuration/configurationInjector'
import {compose} from 'redux'
import { GoogleOAuthProvider } from '@react-oauth/google'

const styles = () => getStyles({
	container: {
		width: 400,
		margin: 'auto'
	},
	paper: {
		background: 'rgba(255,255,255)',
		textAlign: 'center',
		width: 400,
		borderRadius: 10,
		position: 'absolute',
		top: '50vh',
		transform: 'translateY(-50%)'
	},
	content: {
		padding: 15
	},
	img: {
		marginBottom: 30
	}
})

const LoginPage = ({ configuration, location, classes }) => {
	React.useEffect(() => {
		document.body.classList.toggle('login-content', true)
		document.body.parentNode.classList.toggle('login-content', true)
	}, [])

	const [loading, setLoading] = React.useState(false)

	return (
		<>
			<div className={classes.container}>
				<Paper className={classes.paper}>
					<LoaderAdvanced
						show={loading}
						message={<CircularProgress />}
						backgroundStyle={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
					>
						<div className={classes.content}>
							<div className={classes.img}>
								<img src="/img/tenderBox.png" alt="logo" height={100} width="auto" />
							</div>
							{configuration &&
								<GoogleOAuthProvider clientId={configuration.GOOGLE_CLIENT_ID}>
									<LoginForm
										nextPathname={location && location.state ? location.state.nextPathname : undefined}
										setLoading={setLoading}
									/>
								</GoogleOAuthProvider>
							}
						</div>
					</LoaderAdvanced>
				</Paper>
			</div>
		</>
	)
}

LoginPage.propTypes = {
	location: PropTypes.object,
	classes: PropTypes.object
}


export default compose(
	loadConfiguration,
	withStyles(styles)
)(LoginPage)
