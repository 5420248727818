import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { push } from 'connected-react-router'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { getStyles } from 'isotope-client'
import Link from '../../../../components/Link'
import PaperBlocAccueil from './PaperBloc'
import { getUserLanguage } from '../../user/services/userSelectors'
import { getLanguagesWithoutDefault } from 'isotope-client/modules/langue/services/langueSelectors'
import { getLocalizedEntityWithDefaultValues } from 'isotope-client-cms'

const styles = theme => getStyles({
	container: {
		height: '100%',
		width: 384
	},
	containerBtn: {
		width: 612
	},
	divTitre: {
		height: 56,
		marginBottom: 10
	},
	verticalCenter: {
		position: 'relative',
		top: '30%'
	},
	divInfos: {
		marginTop: 10
	},
	divText: {
		marginBottom: 10,
		whiteSpace: 'pre-line'
	},
	link: {
		color: theme.palette.primary.main,
		cursor: 'pointer',
		fontWeight: 'bold'
	}
})


const AideBloc = ({ selectedLanguage, contenu, accessButton, goTo, classes }) => {
	const [inUseContent, setInUseContent] = React.useState({ unSet: true })

	React.useEffect(() => {
		if (inUseContent.unSet && selectedLanguage) {
			getLocalizedEntityWithDefaultValues(contenu.key, contenu, selectedLanguage).then(setInUseContent)
		}
	}, [inUseContent.unSet, selectedLanguage])

	return (
		<PaperBlocAccueil>
			<Grid container className={classnames(classes.container, { [classes.containerBtn]: accessButton })}>
				{/*Titre*/}
				<Grid item className={classes.divTitre}>
					<Typography variant="h3" className={classes.verticalCenter}>
						{inUseContent.titre}
					</Typography>
				</Grid>

				{/*Contenu information*/}
				<Grid item container className={classes.divText} justify="flex-start" alignItems="flex-start">
					<Typography variant="body2" align="justify" dangerouslySetInnerHTML={{ __html: inUseContent.texte }} />
				</Grid>

				{/*Lien*/}
				<Grid item container direction="row-reverse" className={classes.divInfos}>
					<Grid item container direction="row-reverse" alignItems="flex-end" justify="flex-start">
						{accessButton ?
							<Button
								key="access"
								color="primary"
								variant="contained"
								onClick={() => goTo(inUseContent.link)}
							>
								<FormattedMessage id={`accueil.libelleLien.${contenu.key}`} />
							</Button>
							: inUseContent.external ?
								<a href={inUseContent.link} className={classes.link} target="_blank"
								   rel="noopener noreferrer"><FormattedMessage id={`accueil.libelleLien.${contenu.key}`} /></a>
								:
								<Link to={inUseContent.link} className={classes.link}><FormattedMessage id={`accueil.libelleLien.${contenu.key}`} /></Link>
						}
					</Grid>
				</Grid>
			</Grid>
		</PaperBlocAccueil>
	)
}

const mapStateToProps = state => {
	const language = getLanguagesWithoutDefault(state).find(language => language.id === getUserLanguage(state))

	return ({
		selectedLanguage: !!language ? language.codeLang : 'en'
	});
}

const actions = {
	goTo: (link) => dispatch => dispatch(push(link))
}

AideBloc.propTypes = {
	selectedLanguage: PropTypes.string,
	contenu: PropTypes.object.isRequired,
	accessButton: PropTypes.bool.isRequired,
	classes: PropTypes.object,
	goTo: PropTypes.func.isRequired
}

AideBloc.defaultProps = {
	accessButton: false
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(AideBloc)
