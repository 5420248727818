import { combineReducers } from 'redux'
import * as actions from './documentActions'
import { formatSections, initDuplicatedSectionList } from '../utils/documentUtils'
import { ETATS_COLLAPSED_COLUMNS } from '../../../../utils/constants'

const documentData = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_DOCUMENT_SUCCESS:
		case actions.PUT_DOCUMENT_SUCCESS:
		case actions.POST_DOCUMENT_SUCCESS: {
			// On formatte le document
			const formattedDoc = { ...action.document }
			formattedDoc.sections = formatSections(formattedDoc.sections || [])
			return formattedDoc
		}
		case actions.UPDATE_STATUS_DOCUMENT_SUCCESS:
			return {
				...state,
				workflow: action.workflow
			}
		case actions.CLEAR_DOCUMENT:
			return {}
		default:
			return state
	}
}

const initialDuplicatedSections = {}
const duplicatedSections = (state = initialDuplicatedSections, action) => {
	switch (action.type) {
		case actions.GET_DOCUMENT_SUCCESS:
		case actions.PUT_DOCUMENT_SUCCESS:
		case actions.POST_DOCUMENT_SUCCESS:
			return initDuplicatedSectionList((action.document && action.document.sections) || {})
		case actions.CLEAR_DOCUMENT:
			return initialDuplicatedSections
		default:
			return state
	}
}

const loading = (state = false, action) => {
	switch(action.type) {
		case actions.GET_DOCUMENT_REQUEST:
			return true
		case actions.GET_DOCUMENT_SUCCESS:
			return false
		default:
			return state
	}
}

const initfolderPopin = {
	open: false,
	fieldId: undefined,
	fieldName: undefined,
	fieldLabel: undefined,
	selectedFolderOnOpen: undefined,
	isEdition: false
}
const folderPopin = (state = initfolderPopin, action) => {
	switch(action.type) {
		case actions.OPEN_FOLDER_POPIN:
			return {
				...action.field,
				open: true,
				isEdition: action.isEdition,
				selectedFolderOnOpen: action.selectedFolderOnOpen
			}
		case actions.CLOSE_FOLDER_POPIN:
			return {
				...state,
				open: false,
				selectedFolderOnOpen: undefined
			}
		case actions.CLEAR_FOLDER_POPIN:
			return initfolderPopin
		default:
			return state
	}
}

const initRechercheDocumentCollapsed = {
	collapsedSelectColumns: {}
}

const rechercheDocumentCollapsed= (state = initRechercheDocumentCollapsed, action) => {
	switch (action.type) {
		case actions.COLLAPSED_LIST_RECHERCHE_TRUE: {
			return {
				...state,
				collapsedSelectColumns: {
					...state.collapsedSelectColumns,
					[action.id]: ETATS_COLLAPSED_COLUMNS.COLLAPSED
				}
			}
		}
		case actions.COLLAPSED_LIST_RECHERCHE_FALSE: {
			return {
				...state,
				collapsedSelectColumns: {
					...state.collapsedSelectColumns,
					[action.id]: ETATS_COLLAPSED_COLUMNS.NOT_COLLAPSED
				}
			}
		}
		default:
			return state
	}
}

export const document = combineReducers({
	data: documentData,
	duplicatedSections,
	loading,
	folderPopin,
	rechercheDocumentCollapsed
})
