import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import { getStyles } from 'isotope-client'
import mapError from './mapError'
import classnames from 'classnames'
import { default as MuiAutocomplete } from '@material-ui/lab/Autocomplete'
import { FormattedMessage } from 'react-intl'
import Chip from '@material-ui/core/Chip'
import { colors, DEFAULT_LABEL } from '../../utils/constants'
import CircularProgress from '@material-ui/core/CircularProgress'
import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Tooltip, Zoom } from '@material-ui/core'

const styles = () => getStyles({
	container: {
		paddingRight: 10,
		paddingLeft: 10,
		marginBottom: 15
	},
	option: {
		'&[aria-selected="true"]': {
			color: colors.selectedText,
			backgroundColor: colors.appBarBackground
		}
	},
	containerTooltip: {
		tooltip: {
			fontSize: 40
		}
	}
})

const AutocompleteMulti = (Component) => ({ input: { onChange, value: inputValue, ...inputProps }, meta: { error, ...otherMeta }, disabled, staticOptions, containerClassName, classes, getOptions, intl, value, ...otherProps }
) => {
	const [options, setOptions] = React.useState(staticOptions || [])
	const [loading, setLoading] = React.useState(false)

	const getComponentOptions = React.useCallback((value) => {
		if (getOptions) {
			setLoading(true)
			getOptions(value).then(setOptions).finally(() => setLoading(false))
		}
	}, [value, getOptions, setOptions, setLoading])

	React.useEffect(() => {
		if(getOptions){
			getOptions(value).then((results) => setOptions(results || []))
		}
	},[getOptions])

	return <div className={classnames(classes.container, containerClassName)}>
		<Component
			options={options}
			disabled={disabled}
			getOptionLabel={option => option && option.label}
			onChange={(event, value) => {
				if (onChange) {
					onChange(value)
				}
			}}
			getOptionSelected={(option, value) => option.code === value.code}
			noOptionsText={(!options || options.length === 0) && (!inputValue || inputValue === '') ? <FormattedMessage id="document.search.field.noInput" /> : <FormattedMessage id="document.search.field.noValue" />}
			multiple
			value={inputValue || []}
			//Ici on filtre sur le début des mots ou le début de chaque mot pour un groupe de mots ; suivant un espace, une parenthèse ou une apostrophe
			// ex : Banque de France
			// recherche = banq -> affiche / de -> affiche / Fran -> affiche
			// anque -> n'affiche pas
			filterOptions={(options, state) => options.filter(option => {
				const label = option.label.toLowerCase()
				const value = state.inputValue.toLowerCase()
				return label.startsWith(value) || label.includes(` ${value}`) || label.includes(`(${value}`) || label.includes(`'${value}`)
			})}
			onOpen={() => {
				getComponentOptions()
			}}
			classes={{
				option: classes.option
			}}
			autoComplete={false}
			renderInput={params =>
				<TextField
					placeholder={intl.formatMessage({ id: 'autocompleteMulti.placeHolder' })}
					{...otherProps}
					{...inputProps}
					{...params}
					onBlur={() => {
					}}
					autoComplete="off"
					variant="filled"
					helperText={(!otherMeta || otherMeta.touched) && error}
					meta={otherMeta}
					error={(!otherMeta || otherMeta.touched) && !!error}
				/>

			}

			loading={loading}
			loadingText={<CircularProgress size={30} />}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => {
					let label = option.label
					if (!label) {
						const matchOption = options.find(opt => opt.code === option.code)
						label = matchOption ? matchOption.label : DEFAULT_LABEL
					}
					return (
						<Tooltip TransitionComponent={Zoom} className={classes.containerTooltip} arrow title={label} placement="bottom">
							<Chip
								size="small"
								label={label}
								style={{
									backgroundColor: colors.selected,
									color: colors.text,
									maxWidth: '47%',
									marginTop: 5
								}}
								{...getTagProps({ index })}
							/>
						</Tooltip>
					)
				})
			}
		/>
	</div>
}

AutocompleteMulti.defaultProps = {
	containerClassName: '',
	staticOptions: []
}

AutocompleteMulti.propTypes = {
	input: PropTypes.object,
	meta: PropTypes.object,
	staticOptions: PropTypes.array,
	classes: PropTypes.object,
	getOptions: PropTypes.func
}

const mapStateToProps = (state, { formName, input: { name } }) => ({
	value: formName ? (formValueSelector(formName)(state, name) || []) : undefined
})

export default compose(
	mapError,
	injectIntl,
	withStyles(styles),
	connect(mapStateToProps)
)(AutocompleteMulti(MuiAutocomplete))
