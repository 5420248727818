import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { useForm } from 'react-final-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import SvgIcon from '../../../../../components/icon/SvgIcon'
import Popin from '../../../../../components/Popin'
import { SVG_ICONS } from '../../../../../utils/constants'
import { getConversionMap } from '../../../../common/conversion/conversionSelectors'
import { getUserSu } from '../../../user/services/userSelectors'
import { extractSectionValues } from '../../utils/DocumentInitValues'
import { getFilteredSections, handleSectionsVisibility } from '../../utils/documentUtils'
import DocumentSummarySection from './DocumentSummarySection'
import { isDocumentPublicOrToHideOrToDelete } from '../../../../../utils/utils'

const styles = theme => getStyles({
	header: {
		height: 80,
		borderBottom: `${theme.palette.empty} 2px solid`
	},
	divider: {
		backgroundColor: theme.palette.text.main,
		height: 1,
		marginTop: 5
	},
	draftIcon: {
		color: theme.palette.draft
	},
	publishedIcon: {
		color: theme.palette.secondary.main
	},
	summary: {
		padding: 20
	},
	summaryConsultation: {
		padding: 20,
		width: '100%'
	},
	icon: {
		fontSize: 15
	},
	iconCheck: {
		fontSize: 20
	},
	summaryContent: {
		height: 'calc(100vh - 350px)',
		overflow: 'auto'
	},
	summaryContentConsultation: {
		height: 'calc(100vh - 382px)',
		overflow: 'auto'
	},
	summaryNew: {
		height: 'calc(100vh - 290px)'
	},
	disabled: {
		color: 'grey'
	},
	title1: {
		fontSize: 18,
		fontWeight: 500,
		color: theme.palette.text.main
	},
	title2: {
		fontSize: 16,
		fontWeight: 'bold',
		color: theme.palette.text.main
	},
	title3: {
		fontSize: 14,
		fontWeight: 400,
		color: theme.palette.text.main
	},
	marginTitle1: {
		marginTop: 32
	},
	marginTitle2: {
		marginTop: 24
	},
	marginTitle3: {
		marginTop: 12
	}
})

const DocumentSummary = ({
	model,
	document,
	openSections,
	selectedLanguage,
	isNewDocument,
	userSu,
	conversionMap,
	intl,
	classes,
	changeSections,
	consultation,
	sectionsWithContent,
	duplicatedSections
}) => {

	const form = useForm()
	const [infoDeleted, setInfoDeleted] = React.useState({ open: false })

	const handleClose = () => setInfoDeleted({ open: false })

	// Gestion ouverture des sections via le sommaire
	const handleOpenSections = (sectionKey) => {
		const newSections = handleSectionsVisibility(sectionKey, openSections, false, true)
		if (newSections) {
			changeSections(newSections)
		}
	}

	let duplicateSection
	let actions
	let deleteSection

	if (consultation) {
		deleteSection = () => {
		}
		duplicateSection = () => {
		}
		actions = []
	} else {
		duplicateSection = (section) => {
			const newDuplicatedNumber = duplicatedSections[section.id] ? duplicatedSections[section.id][duplicatedSections[section.id].length - 1].duplicatedNumber + 1 : 1

			// Ajout des init values du modèle
			const initValues = extractSectionValues(
				[section],
				selectedLanguage,
				true,
				newDuplicatedNumber,
				userSu,
				conversionMap
			)

			Object.keys(initValues.fields).forEach(keyValue => {
				form.change(`fields.${keyValue}`, initValues.fields[keyValue])
			})

			const sectionInfo = {
				id: section.id,
				idMoParentSection: section.idMoParentSection,
				position: newDuplicatedNumber,
				duplicatedNumber: newDuplicatedNumber,
				duplicated: true,
				deleted: false,
				finished: false
			}

			const newDuplicatedSections = {
				...duplicatedSections,
				[section.id]: [
					...(duplicatedSections[section.id] || []),
					sectionInfo
				]
			}
			// Changement des sections dupliquées
			form.change('duplicatedSections', newDuplicatedSections)

			// Valeur de retour utilisée pour le loading de la duplication
			return Promise.resolve(false)
		}

		deleteSection = () => {
			const { idSection, duplicatedNumber } = infoDeleted
			const newSections = [...duplicatedSections[idSection]]

			const indexFound = newSections.findIndex(sec => sec.duplicatedNumber === duplicatedNumber)
			if (indexFound !== -1) {
				newSections.splice(indexFound, 1, { ...newSections[indexFound], deleted: true })
				const newDuplicatedSections = {
					...duplicatedSections,
					[idSection]: newSections
				}
				// Changement des sections dupliquées
				form.change('duplicatedSections', newDuplicatedSections)
			}
			setInfoDeleted({ open: false })
		}

		actions = [
			<Button
				color="inherit"
				variant="outlined"
				key="fermer"
				onClick={handleClose}
			>
				<FormattedMessage id="global.buttons.annuler" />
			</Button>,
			<Button
				key="valider"
				color="primary"
				variant="contained"
				onClick={deleteSection}
			>
				<FormattedMessage id="global.buttons.confirm" />
			</Button>
		]
	}

	return (
		<Paper className={classnames(classes.summary, { [classes.summaryConsultation]: consultation })}>
			{!consultation && <Grid container className={classes.header} alignItems="center">
				<Grid item xs={2}><SvgIcon path={SVG_ICONS[model.type]}
				                           className={document && document.workflow && isDocumentPublicOrToHideOrToDelete(document, false) ? classes.publishedIcon : classes.draftIcon}
				                           fontSize="large" /></Grid>
				{model && model.type && <Typography variant="h2">
					{`${intl.formatMessage({ id: `enums.documentType.${model.type}` })}`}
					<br /> {`${document && document.workflow && document.workflow.status && !isDocumentPublicOrToHideOrToDelete(document, false)
								? intl.formatMessage({ id: `enums.documentStatusSummary.${document.workflow.status}` })
								: ''}`}
				</Typography>}
			</Grid>}
			<Grid
				container
				alignContent="flex-start"
				className={classnames(classes.summaryContent, { [classes.summaryNew]: isNewDocument }, { [classes.summaryContentConsultation]: consultation })}
			>
				{getFilteredSections(model.sections).map((section, index) =>
					<DocumentSummarySection
						key={`section-${section.id}-${index}`}
						section={section}
						level={1}
						duplicatedSections={duplicatedSections}
						selectedLanguage={selectedLanguage}
						handleOpenSections={handleOpenSections}
						duplicateSection={duplicateSection}
						deleteSection={(idSection, duplicatedNumber) => setInfoDeleted({ idSection, duplicatedNumber, open: true })}
						consultation={consultation}
						sectionsWithContent={sectionsWithContent}
						parentKey=""
					/>)}
			</Grid>
			{!consultation && <Popin
				open={infoDeleted.open}
				actions={actions}
				title={<FormattedMessage id="document.section.deleteTitle" />}
				onClose={handleClose}
				content={<FormattedMessage id="document.section.deleteMessage" />}
			/>}
		</Paper>
	)
}

const mapStateToProps = state => ({
	userSu: getUserSu(state),
	conversionMap: getConversionMap(state)
})

DocumentSummary.propTypes = {
	model: PropTypes.object,
	document: PropTypes.object,
	openSections: PropTypes.array,
	selectedLanguage: PropTypes.string,
	isNewDocument: PropTypes.bool,
	userSu: PropTypes.string,
	conversionMap: PropTypes.object,
	classes: PropTypes.object,
	intl: PropTypes.object,
	changeSections: PropTypes.func,
	consultation: PropTypes.bool,
	sectionsWithContent: PropTypes.object
}

export default compose(
	connect(mapStateToProps),
	injectIntl,
	withStyles(styles)
)(DocumentSummary)
