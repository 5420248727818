import { DEFAULT_LANGUAGE_ID, TIMELINE } from '../../../../utils/constants'
import { ATTRIBUTE_DATA_MO_SECTION, EXCLUDED_FIELDS_TABLE_VIEW, FIELD_TYPE } from './documentConstants'
import _ from 'lodash'
import moment from 'moment'

////////////////////////////////////////////////
// FONCTIONS DE FORMATTAGE DES DONNEES MODELE /
//////////////////////////////////////////////

/**
 * Fonction qui formatte les attribute data sous la forme { lang: { clé: {}, clé2: {}}, lang2: ...}
 * @param attributeData
 */
export const formatAttributeData = attributeData => {
	let formattedData = [...attributeData]

	// Reduce par langue
	formattedData = formattedData.reduce((acc, att) => {
		return {
			...acc,
			[att.idLanguage]: [...(acc[att.idLanguage] || []), att]
		}
	}, {})

	// Reduce par clé attribute data
	Object.keys(formattedData).forEach(lang => {
		formattedData[lang] = formattedData[lang].reduce((acc, att) => {
			return {
				...acc,
				[att.label]: att
			}
		}, {})
	})

	return formattedData
}

/**
 * Fonction qui formatte les attribute data d'une liste de fields
 * @param fields
 * @returns {*}
 */
export const formatFields = fields =>
	fields.map(field => ({
		...field,
		attributeData: formatAttributeData(field.attributeData),
		fields: formatFields(field.fields || [])
	}))

/**
 * Fonction qui parcourt les sections et formatte les attributs
 * @param sections
 * @returns {{attributeData: (...*|{}|*)}[]}
 */
export const formatSections = sections =>
	sections.map(section => {
		const formattedSection = { ...section }

		// On parcourt les sections filles
		if (section.sections) {
			formattedSection.sections = formatSections(section.sections)
		}

		// On retourne le noeud section en formattant les attributes datas
		return {
			...(formattedSection || {}),
			attributeData: formatAttributeData(formattedSection.attributeData),
			fields: formatFields(formattedSection.fields)
		}
	})

/**
 * Renvoie les dépendances pour les sections selon le critère de regroupement
 * @param sections sections de regroupement
 * @returns {*}
 */
export const getRegroupementSectionChild = (sections) => {
	return sections.filter(section => section.published).reduce((acc, section) => {
		// On récupère les sections de regroupement
		const childrenRegroupementSections = section.sections
			.filter(section => section.published && section.sectionRegroupement)
			.sort((item1, item2) => item1.position - item2.position)
		// On récupère les champs pour les sections qui ne sont pas flag en regroupement
		const fieldsFromChildrenSection = getNotRegroupementSectionFields(
			section.sections.sort((item1, item2) => item1.position - item2.position)
		)
		// On récupère les champs valides pour la section actuelle
		const validFields = section.fields
			.filter(field => field.published && !EXCLUDED_FIELDS_TABLE_VIEW.includes(field.type))
			.sort((item1, item2) => item1.position - item2.position)

		return {
			...acc,
			[section.id]: {
				sectionsChildren: childrenRegroupementSections.map(sectionChild => ({ id: sectionChild.id, attributeData: sectionChild.attributeData, fields: section.fields })),
				fieldsChildren: [...validFields.filter(field => !field.donneeAvancee).map(field => ({ id: field.id, attributeData: field.attributeData })), ...(fieldsFromChildrenSection.fieldsChildren || [])],
				fieldsAdvancedChildren: [...validFields.filter(field => field.donneeAvancee).map(field => ({ id: field.id, attributeData: field.attributeData })), ...(fieldsFromChildrenSection.fieldsAdvancedChildren || [])]
			},
			...getRegroupementSectionChild(childrenRegroupementSections)
		}
	}, {})
}

/**
 * Récupération des champs pour les sections non flag en regroupement
 * @param sections sections non regroupement
 * @returns {*}
 */
export const getNotRegroupementSectionFields = (sections) => {
	return sections.filter(section => section.published && !section.sectionRegroupement).reduce((acc, section) => {
		const validFields = section.fields
			.filter(field => field.published && !EXCLUDED_FIELDS_TABLE_VIEW.includes(field.type))
			.sort((item1, item2) => item1.position - item2.position)
		const fieldsInChildrenSections = getNotRegroupementSectionFields(
			section.sections
				.sort((item1, item2) => item1.position - item2.position)
		)
		return {
			fieldsChildren: [...acc.fieldsChildren, ...validFields.filter(field => !field.donneeAvancee).map(field => ({ id: field.id, attributeData: field.attributeData })), ...(fieldsInChildrenSections.fieldsChildren || [])],
			fieldsAdvancedChildren: [...acc.fieldsAdvancedChildren, ...validFields.filter(field => field.donneeAvancee).map(field => ({ id: field.id, attributeData: field.attributeData })), ...(fieldsInChildrenSections.fieldsAdvancedChildren || [])]
		}
	}, { fieldsChildren: [], fieldsAdvancedChildren: [] })
}

////////////////////////////////////////////////
//   FONCTIONS D'EXTRACTION FIELDS REQUIRED   /
//////////////////////////////////////////////

/**
 * Fonction qui extrait les fields required
 * @param fields
 * @param language
 * @returns {*}
 */
const extractFieldsRequired = (fields) =>
	fields.reduce((acc, field) => {
		if (field.reference && field.required) {
			return [...acc, { reference: field.reference, type: field.type }]
		}
		return acc
	}, [])

/**
 * Fonction qui récupère les fields required de l'ensemble du document(modélisé par une liste de sections) modèle
 * @param sections
 */
export const extractRequired = (sections) =>
	sections.reduce((acc, section) => {
		// Changement des règles required, on considère pour le moment que seulement FI peut avoir des champs requis
		// Donc section de niveau 1
		// let initSections = {}
		// if (section.sections) {
		// 	initSections = extractRequired(section.sections)
		// }

		return [
			...acc,
			// ...initSections,
			...extractFieldsRequired(section.fields)
		]
	}, [])

////////////////////////////////////////////////
//     FONCTIONS DE FORMATTAGE POUR ENVOI    //
//////////////////////////////////////////////

/**
 * Fonction qui formatte les fields pour les soumettre au serveur
 * @param field
 */
export const formatFieldToSubmit = field => {
	if (field.type === FIELD_TYPE.MULTI_SELECT || field.type === FIELD_TYPE.FILE) {
		return {
			...field,
			value: JSON.stringify(field.value)
		}
	} else if (field.type === FIELD_TYPE.TABLE) {
		return {
			...field,
			tabValue: Object.keys(field.value).map(column => ({
				...field.value[column],
				reference: column,
				value: JSON.stringify(field.value[column].value)
			})),
			value: undefined
		}
	} else if (field.type === FIELD_TYPE.AMOUNT) {
		return {
			...field,
			value: (field.value || '').replace(/,/g, '.').replace(/[^\d.]/g, ''),
			siValue: (field.siValue || '').replace(/,/g, '.').replace(/[^\d.]/g, '')
		}
	} else if (field.type === FIELD_TYPE.NUMBER) {
		return {
			...field,
			value: (field.value || '').replace(/,/g, '.').replace(/[^\d.]/g, '')
		}
	} else if (field.type === FIELD_TYPE.NUMBER_UNIT) {
		// Gestion de la conversion via le coeff si renseigné
		const uniteParsed = !!field.unite && JSON.parse(field.unite)
		const value = (field.value || '').replace(/,/g, '.').replace(/[^\d.]/g, '')

		if (uniteParsed) {
			return {
				...field,
				value: value !== '' ? value / (uniteParsed.coeff || 1) : '',
				unite: uniteParsed.code
			}
		} else {
			return {
				...field,
				value
			}
		}
	} else if (field.type === FIELD_TYPE.DATE) {
		return {
			...field,
			value: field.value && normalizeDate(field.value)
		}
	}
	return field
}

export const getFieldName = (field, duplicatedNumber) => `fields.${field.reference}${getDuplicateSuffix(duplicatedNumber)}`

////////////////////////////////////////////////
//   FONCTIONS DE GESTION DE LA VISIBILITE   //
//////////////////////////////////////////////

export const getDependencies = (technicalData, duplicatedNumber) => {
	// Par défaut la section n'a pas de dépendances
	let dependencies = {}

	// Si nous avons une restriction sur une section, on vérifie que le critère est respecté pour l'afficher
	if (technicalData && !!technicalData[ATTRIBUTE_DATA_MO_SECTION.VISIBILITY_REF] && !!technicalData[ATTRIBUTE_DATA_MO_SECTION.VISIBILITY_VALUE]) {
		// On découpe si plusieurs références
		const splitVisibilityRef = technicalData[ATTRIBUTE_DATA_MO_SECTION.VISIBILITY_REF].value.split('|')
		const splitVisibilityValue = technicalData[ATTRIBUTE_DATA_MO_SECTION.VISIBILITY_VALUE].value.split('|')

		// Si nous n'avons pas le même nombre entre référence et value => Erreur
		if (splitVisibilityRef.length !== splitVisibilityValue.length) {
			return null
		}

		splitVisibilityRef.forEach((ref, index) => {
			const fieldValue = splitVisibilityValue[index]
			// Gestion des paramètres globaux
			if (ref.startsWith('#')) {
				dependencies = {
					...dependencies,
					[ref.substring(1).toLowerCase()]: (dependencies[ref.substring(1).toLowerCase()] || []).concat(fieldValue)
				}
			} else if (ref.startsWith('FI_')) {
				// La section FI est unique
				dependencies = {
					...dependencies,
					[`fields.${ref}`]: (dependencies[`fields.${ref}`] || []).concat(fieldValue)
				}
			} else if (duplicatedNumber) {
				dependencies = {
					...dependencies,
					[`fields.${ref}#${duplicatedNumber}`]: (dependencies[`fields.${ref}#${duplicatedNumber}`] || []).concat(fieldValue)
				}
			} else {
				dependencies = {
					...dependencies,
					[`fields.${ref}`]: (dependencies[`fields.${ref}`] || []).concat(fieldValue)
				}
			}
		})
	}
	return dependencies
}

export const isSectionEmpty = (sectionsWithContent = [], idSection, duplicatedNumber = 0) => !sectionsWithContent[idSection] || !sectionsWithContent[idSection][duplicatedNumber]

////////////////////////////////////////////////
//       FONCTIONS DE GESTION DU DATASET     //
//////////////////////////////////////////////
export const getDatasetParameters = (defaultValue, duplicatedNumber) => {
	const params = []

	// On vérifie que nous avons une valeur par défaut
	if (!!defaultValue) {
		// On la découpe pour récupérer les fields à écouter
		// eslint-disable-next-line no-template-curly-in-string
		const fields = [...defaultValue.value.matchAll('\\${([^}]+)}')].map(item => item[1])
		fields.forEach(field => {
			// Gestion des paramètres globaux
			if (field.startsWith('#')) {
				params.push({ key: field, fieldName: field.substring(1).toLowerCase() })
			} else if (field.startsWith('FI_')) {
				// La section FI est unique
				params.push({ key: field, fieldName: `fields.${field}` })
			} else if (duplicatedNumber) {
				params.push({ key: field, fieldName: `fields.${field}#${duplicatedNumber}` })
			} else {
				params.push({ key: field, fieldName: `fields.${field}` })
			}
		})

	}
	// Renvoie la liste des parameters sous la forme d'une liste d'objet de type
	// { key: "référence", fieldName: "nom du champ dans le form"
	return params
}

////////////////////////////////////////////////
//     FONCTIONS DE GESTION DUPLICATION      //
//////////////////////////////////////////////
export const initDuplicatedSectionList = sections => {
	if (sections) {
		return sections.filter(section => section.duplicated)
			.reduce((acc, section) => ({
				...acc,
				[section.idMoSection]: [
					...(acc[section.idMoSection] || []),
					{
						id: section.id,
						idMoSection: section.idMoSection,
						position: section.position,
						duplicatedNumber: section.duplicatedNumber
					}
				]
			}), {})
	}
	return {}
}

export const getDuplicateSuffix = (duplicatedNumber) => `${duplicatedNumber ? `#${duplicatedNumber}` : ''}`

////////////////////////////////////////////////
//     FONCTIONS DE GESTION COLLAPSE         //
//////////////////////////////////////////////

/**
 * Fonction qui gère l'ouverture/fermeture d'une section
 * Retourne les sections
 * @param sectionKey
 * @param atBottom
 * @param openSections
 * @param fromSummary
 */
export const handleSectionsVisibility = (sectionKey, openSections, atBottom = false, fromSummary = false) => {
	const indexFound = openSections.findIndex(section => section === sectionKey)
	if (indexFound === -1) {
		// Ouverture -> on passe la section dans le tableau et ses éventuels parents
		if (atBottom) {
			document.getElementById(`${sectionKey}`).scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			})
		}
		const keysSplit = sectionKey.split('-')
		keysSplit.shift()

		let previousKey = ''
		const newSections = [...openSections]
		// On boucle sur le tableau et ouvre les parents
		keysSplit.forEach(key => {
			const refKey = `${previousKey}-${key}`
			const indexFound = openSections.findIndex(section => section === refKey)
			// Si ce n'est pas présent dans la liste, on l'ajoute
			if (indexFound === -1) {
				newSections.push(refKey)
			}
			previousKey = refKey
		})

		return newSections
	} else if (!fromSummary) {
		// On ne supprime la section que si le clic ne provient pas du sommaire
		const newSections = [...openSections]
		newSections.splice(indexFound, 1)
		return newSections
	}
	return undefined
}

export const normalizeNumber = (value) => {
	if (!value && value !== 0) {
		return ''
	}

	let onlyNums = `${value}`.replace(/,/g, '.')

	// Gestion de l'éventuelle puissance renvoyée par Elastic
	if (onlyNums.indexOf('E') !== -1) {
		const splitPow = onlyNums.split('E')
		onlyNums = Number.parseFloat(splitPow[0]) * Math.pow(10, Number.parseInt(splitPow[1]))
	}

	onlyNums = `${onlyNums}`.replace(/[^\d.]/g, '')

	const unitPart = onlyNums.split('.')

	let firstPart = unitPart[0]
	let lastPart = unitPart[1]

	if (firstPart.length > 3) {
		firstPart = firstPart.replace(/ /g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
	}

	let result
	if (!!lastPart && lastPart.length > 2) {
		// Arrondi
		result = `${firstPart},${Number.parseFloat(onlyNums).toFixed(2).split('.')[1]}`
	} else if (unitPart.length > 1) {
		result = `${firstPart},${lastPart}`
	} else {
		result = firstPart
	}

	return result.replace(/\./g, ',')
}

export const parseNumber = (value) => {
	if (!value) {
		return value
	}
	return value.replace(/,/g, '.').replace(/[^\d.]/g, '')
}

export const normalizeDate = (value) => {
	// Gestion de la date à 10 caractères
	if (value && value.length > 10) {
		return value.substring(value.length - 10)
	}
	return value || ''
}

export const tableHasValue = (valueField) => {
	return !!valueField.value && Object.values(valueField.value).filter(colValue => {
		const arrayVal = colValue.value
		if (!!arrayVal && Array.isArray(arrayVal)) {
			return arrayVal.length > 0 && arrayVal.some(valParsed => !!valParsed && valParsed.length > 0)
		}
		return false
	}).length > 0
}

export const getSectionTitle = (section, idLanguage) => section.attributeData && (
	(section.attributeData[idLanguage] && section.attributeData[idLanguage][ATTRIBUTE_DATA_MO_SECTION.TITLE] && section.attributeData[idLanguage][ATTRIBUTE_DATA_MO_SECTION.TITLE].value)
	|| (section.attributeData[DEFAULT_LANGUAGE_ID] && section.attributeData[DEFAULT_LANGUAGE_ID][ATTRIBUTE_DATA_MO_SECTION.TITLE] && section.attributeData[DEFAULT_LANGUAGE_ID][ATTRIBUTE_DATA_MO_SECTION.TITLE].value))

/**
 * Retourne les fields publiés
 * @param fields
 */
export const getFilteredFields = fields => fields ? fields.filter(field => field.published) : []

/**
 * Retourne les sections publiées
 * @param sections
 */
export const getFilteredSections = sections => sections ? sections.filter(section => section.published) : []

/**
 * Retourne le tableau de duplicatedNumber si le document a une section dupliquer de la section modéle
 * @param sectionModel
 * @param listSectionDocument
 */
export const getDuplicatedNumberOfModelSectionFromDocument = (sectionModel, listSectionDocument) => {
	let listSectionDocumentFiltred = listSectionDocument ? listSectionDocument.filter(sectionDoc => sectionDoc.idMoSection === sectionModel.id) : []

	return listSectionDocumentFiltred.map(section => {
		if(section.duplicatedNumber){
			return section.duplicatedNumber
		} else {
			return ""
		}
	} )
}


export const getFiSectionFromDocument = (document) =>
	document && document.sections && document.sections.find(section => +section.id === 0)

export const initFiltersFavoriteSearch = (filters, setAdminCorrespFilters, fromTabDocument) => {
	let keys = []
	for (const [key, value] of Object.entries(filters.fieldsAdmin)) {
		if (value != null) {
			keys.push(key)
		}
	}
	let newAdminCorrespFilters = Object.keys(filters.fieldsAdmin)
		.filter(key => !!filters.fieldsAdmin[key])
		.map(key => {
				const copyKey = key.replaceAll('Fin', '').replaceAll('Debut', '')
				if (key.startsWith('date')) {
					//Cas between
					if (keys.includes(`${copyKey}Debut`) && keys.includes(`${copyKey}Fin`)) {
						if (filters.fieldsAdmin[`${copyKey}Debut`] === filters.fieldsAdmin[`${copyKey}Fin`]) {
							return ({
								typeFilter: copyKey,
								value: [TIMELINE.AT, filters.fieldsAdmin[`${copyKey}Debut`], filters.fieldsAdmin[`${copyKey}Fin`]]
							})
						} else {
							return ({
								typeFilter: copyKey,
								value: [TIMELINE.BETWEEN, filters.fieldsAdmin[`${copyKey}Debut`], filters.fieldsAdmin[`${copyKey}Fin`]]
							})
						}
					}
					//Cas avec une seule date
					else {
						if (key.includes('Debut')) {
							return ({
								typeFilter: copyKey,
								value: [TIMELINE.AFTER, filters.fieldsAdmin[key]]
							})
						} else {
							return ({
								typeFilter: copyKey,
								value: [TIMELINE.BEFORE, filters.fieldsAdmin[key]]
							})
						}
					}
				} else {
					// Cas filtre status/utilisateur
					return ({
						typeFilter: key,
						value: filters.fieldsAdmin[key].map(item => ({ code: item }))
					})
				}
			}
		)
	setAdminCorrespFilters(_.sortedUniqBy(newAdminCorrespFilters, item => item.typeFilter))
}

 export const getLastMonthDate = () => moment().subtract(1, 'months').format('YYYY-MM-DD')
