import { Grid, Typography, withStyles } from '@material-ui/core'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { goBack, push } from 'connected-react-router'
import arrayMutators from 'final-form-arrays'
import { getStyles } from 'isotope-client'
import { injectLangues } from '../../../common/langue/langueInjector'
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Element } from 'react-scroll'
import { compose } from 'redux'
import CenteredLayout from '../../../../components/layout/CenteredLayout'
import Loader from '../../../../components/layout/Loader'
import Link from '../../../../components/Link'
import { hasAccess } from '../../../../components/security/AccessChecker'
import { PROFILS, TYPE_CONTENU } from '../../../../utils/constants'
import { getConversionMap } from '../../../common/conversion/conversionSelectors'
import { injectZoneList } from '../../../common/valueList/ValueListInjector'
import * as userSelectors from '../../user/services/userSelectors'
import { getUserSu } from '../../user/services/userSelectors'
import DocumentActionsBar from '../components/DocumentActionsBar'
import DocumentSummary from '../components/summary/DocumentSummary'
import { getFilteredSections, getFiSectionFromDocument, handleSectionsVisibility } from '../utils/documentUtils'
import validate from '../utils/documentValidate'
import * as modelSelectors from './../../../common/model/modelSelectors'
import DocumentSection from './../components/DocumentSection'
import * as documentActions from './../services/documentActions'
import * as documentSelectors from './../services/documentSelectors'
import { extractValues, initContentDocumentValues } from '../utils/DocumentInitValues'
import BlockWorflow from './BlockWorkflow'
import { OnChange } from 'react-final-form-listeners'
import { isCorrespondantValidForDocument, isMemberValidForDocument } from '../../../../utils/utils'

const styles = theme => getStyles({
	retour: {
		color: theme.palette.text.main,
		display: 'flex',
		alignItems: 'center',
		marginLeft: '-10px',
		marginTop: '-20px',
		marginBottom: 10
	},
	title: {
		marginBottom: 10,
		height: 50,
		marginLeft: 20,
		marginTop: -20
	},
	titleNew: {
		marginBottom: 30
	},
	paper: {
		padding: 20,
		height: 'calc(100vh - 288px)',
		overflow: 'auto',
		backgroundColor: 'white'
	},
	paperSummaryReference: {
		height: 'calc(100vh - 342px)',
		overflow: 'auto',
		backgroundColor: 'white'
	},
	paperSummary: {
		padding: 20,
		height: 'calc(100vh - 382px)',
		overflow: 'auto',
		backgroundColor: 'white'
	},
	actionBar: {
		padding: '15px 45px',
		boxSizing: 'border-box',
		height: 60,
		borderTop: '3px rgba(0,0,0,0.08)solid'
	},
	button: {
		marginRight: 50
	}
})


const DocumentPage = ({
	model,
	document,
	zoneList,
	langues,
	isCorrespondantValid,
	isMemberValid,
	isAdmin,
	documentId,
	idLanguage,
	userZone,
	isLoading,
	userEmail,
	getDocument,
	goToListe,
	clearDocument,
	classes,
	contributor,
	duplicatedSections,
	userSu,
	conversionMap,
	goBackAction,
	location
}) => {
	const validator = React.useMemo(() => validate(getFiSectionFromDocument(model)), [model])
	const [selectedLanguage, setSelectedLanguage] = React.useState(idLanguage)
	const [openSections, setOpenSections] = React.useState([])

	React.useEffect(() => {
		// On charge le document
		if (document && document.id !== documentId) {
			clearDocument()
			getDocument(documentId)
		}
	}, [documentId])

	const boucleIdSection= (result, sectionsParent, parentId) => {
		// En mode consultation on ouvre toute les sections
		sectionsParent.forEach(sectionOpenChildren => {
			var sectionKeyChildren =""
			if(sectionOpenChildren.duplicated){
				sectionKeyChildren = `${parentId}-${sectionOpenChildren.idMoSection}#${sectionOpenChildren.duplicatedNumber}`
				result.push(`-${sectionKeyChildren}`)
			} else {
				sectionKeyChildren = `${parentId}-${sectionOpenChildren.idMoSection}`
				result.push(`-${sectionKeyChildren}`)
			}
			if (sectionOpenChildren.sections && sectionOpenChildren.sections.length > 0) {
				boucleIdSection(result, sectionOpenChildren.sections, sectionKeyChildren)
			}
		})
	}

	// Gestion ouverture/fermeture des sections via le document
	const handleOpenSections = (sectionKey) => {
		const newSections = handleSectionsVisibility(sectionKey, openSections)
		if (newSections) {
			setOpenSections(newSections)
		}
		return Promise.resolve(false)
	}

	const [initialValues, setInitialValues] = React.useState({})
	const [sectionsWithContent, setSectionsWithContent] = React.useState({})

	const documentContributor = initialValues && initialValues.contributor

	React.useEffect(() => {
		if (model.sections && document.sections) {
			const { status, fields, sectionsWithContent: sectionsContent } = extractValues(model && getFilteredSections(model.sections), document && document.sections, selectedLanguage, userSu, conversionMap)
			setInitialValues({
				selectedLanguage,
				duplicatedSections,
				...initContentDocumentValues(document, selectedLanguage),
				fields,
				status
			})
			setSectionsWithContent(sectionsContent)
		}
	}, [model.sections, document.sections, contributor, selectedLanguage, conversionMap])

	// Au montage du formulaire, on ouvre la première section
	React.useEffect(() => {
		if (document.sections) {
			// En mode consultation on ouvre toute les sections
			var result = []
			document.sections.forEach(sectionOpen => {
				var nomSection = ""
				if(sectionOpen.duplicated){
					nomSection = `${sectionOpen.idMoSection}#${sectionOpen.duplicatedNumber}`
				} else {
					nomSection = `${sectionOpen.idMoSection}`
				}
				result.push(`-${nomSection}`)
				if (sectionOpen.sections && sectionOpen.sections.length > 0) {
					boucleIdSection(result, sectionOpen.sections, nomSection)
				}
			})
			setOpenSections(result)
		}
	}, [document.sections,sectionsWithContent])

	if (!model || isLoading || !model.sections || !initialValues.contributor || !langues || langues.length === 0) {
		return <Loader />
	}

	return <Form
		validate={validator}
		subscription={{ submitting: true, pristine: true }}
		onSubmit={() => {
		}}
		mutators={{ ...arrayMutators }}
		initialValues={initialValues}
	>
		{() => {
			return <CenteredLayout>
				<OnChange name="selectedLanguage">{(values) => setSelectedLanguage(values)}</OnChange>
				<form>
					<Grid container>
						<Grid item xs={1}>
							<Link to="/documents" className={classes.retour} onClick={() => location.state ? goBackAction() : goToListe()} isReturn>
								<ArrowLeftIcon fontSize="large" />
								<FormattedMessage id="document.retour" />
							</Link>
						</Grid>
						<Grid item xs={11}>
							<Typography variant="h1"
							            className={classes.title}>{(initialValues.title || initialValues.defaultTitle)}</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						<DocumentActionsBar
							documentTender={document}
							isCorrespondantValid={isCorrespondantValid}
							isMemberValid={isMemberValid}
							isAdmin={isAdmin}
							langues={langues}
							userZone={userZone}
							userEmail={userEmail}
							consultation
							documentType={model.type}
						/>
						{model.type !== TYPE_CONTENU.REFERENCE ?
							<>
								<Grid item xs={6}>
									<Element name="docPage" id="docPageFI" className={classes.paperSummary}>
										{model.sections && model.sections.filter(section => section.type === 'FI').map((section, index) =>
											<DocumentSection
												key={`section-${section.id}-${index}`}
												section={section}
												level={1}
												parentKey=""
												documentType={model.type}
												consultation
												handleOpenSections={handleOpenSections}
												openSections={openSections}
												setOpenSections={setOpenSections}
												sectionsWithContent={sectionsWithContent}
												documentContributor={documentContributor}
												isCorrespondantValid={isCorrespondantValid}
												isMemberValid={isMemberValid}
											/>)}
									</Element>
									<BlockWorflow
										document={document}
										zoneList={zoneList}
									/>
								</Grid>
								<Grid item xs={6}>
									<Element name="docPage" id="docPage" className={classes.paper}>
										{getFilteredSections(model.sections).filter(section => section.type !== 'FI').map((section, index) =>
											<DocumentSection
												key={`section-${section.id}-${index}`}
												section={section}
												level={1}
												parentKey=""
												selectedLanguage={selectedLanguage}
												documentType={model.type}
												consultation
												handleOpenSections={handleOpenSections}
												openSections={openSections}
												setOpenSections={setOpenSections}
												sectionsWithContent={sectionsWithContent}
												documentContributor={documentContributor}
												isCorrespondantValid={isCorrespondantValid}
												isMemberValid={isMemberValid}
											/>)}
									</Element>
								</Grid>
							</>
							:
							<>
								<Grid item container xs={4}>
									<Grid item container xs={12} className={classes.paperSummaryReference}>
										<DocumentSummary
											model={model}
											document={document}
											selectedLanguage={selectedLanguage}
											isNewDocument={false}
											consultation
											sectionsWithContent={sectionsWithContent}
											duplicatedSections={duplicatedSections}
											openSections={openSections}
											changeSections={setOpenSections}
										/>
									</Grid>
									<BlockWorflow
										document={document}
										zoneList={zoneList}
										selectedLanguage={selectedLanguage}
									/>
								</Grid>
								<Grid item xs={8}>
									<Element name="docPage" id="docPage" className={classes.paper}>
										{getFilteredSections(model.sections).map((section, index) => <DocumentSection
											key={`section-${section.id}-${index}`}
											section={section}
											level={1}
											parentKey=""
											selectedLanguage={selectedLanguage}
											documentType={model.type}
											consultation
											handleOpenSections={handleOpenSections}
											openSections={openSections}
											setOpenSections={setOpenSections}
											sectionsWithContent={sectionsWithContent}
											documentContributor={documentContributor}
											isCorrespondantValid={isCorrespondantValid}
											isMemberValid={isMemberValid}
										/>)}
									</Element>
								</Grid>
							</>
						}
					</Grid>
				</form>
			</CenteredLayout>
		}}
	</Form>
}

const mapStateToProps = (state, { match }) => {
	const document = documentSelectors.getDocument(state)
	const model = modelSelectors.getModelByType(state, document && document.type)
	const authorities = userSelectors.getAuthorities(state)
	const documentId = match && match.params && match.params.id
	const userZone = userSelectors.getUserZone(state)
	const userEmail = userSelectors.getUserEmail(state)
	const idLanguage = userSelectors.getUserLanguage(state)
	return {
		model,
		document,
		contributor: userSelectors.getUserEmail(state),
		isAdmin: hasAccess(PROFILS.ADMIN, authorities),
		isCorrespondantValid: hasAccess(PROFILS.CORRESPONDANT, authorities) && isCorrespondantValidForDocument(userSelectors.getUser(state), documentSelectors.getDocument(state)),
		isMemberValid: hasAccess(PROFILS.MEMBRE, authorities) && isMemberValidForDocument(userSelectors.getUser(state), documentSelectors.getDocument(state)),
		documentId,
		isLoading: documentSelectors.isLoading(state),
		idLanguage,
		userEmail,
		duplicatedSections: documentSelectors.getDuplicatedSectionList(state),
		userZone,
		userSu: getUserSu(state),
		conversionMap: getConversionMap(state)
	}
}

const actions = {
	getDocument: documentActions.getDocument,
	clearDocument: documentActions.clearDocument,
	goToEdition: (id) => dispatch => dispatch(push({ pathname: `/edition-document/${id}`, state: { redirectNew: true } })),
	goToListe: () => dispatch => dispatch(push('/documents')),
	goBackAction: () => dispatch => dispatch(goBack())
}

DocumentPage.propTypes = {
	classes: PropTypes.object,
	model: PropTypes.object,
	document: PropTypes.object,
	langues: PropTypes.array,
	isAdmin: PropTypes.bool,
	documentId: PropTypes.string,
	userZone: PropTypes.string,
	isLoading: PropTypes.bool,
	userEmail: PropTypes.string,
	getDocument: PropTypes.func,
	goToEdition: PropTypes.func,
	goBackAction: PropTypes.func,
	clearDocument: PropTypes.func
}

export default compose(
	injectLangues,
	connect(mapStateToProps, actions),
	injectZoneList,
	withStyles(styles)
)(DocumentPage)
