import { fetchFactory } from 'isotope-client'

export const getDocument = (id) => fetchFactory(`/document/${id}`)

export const postDocument = (document) => fetchFactory('/document', {
	method: 'POST',
	body: JSON.stringify(document)
})

export const putDocument = (document) => fetchFactory('/document', {
	method: 'PUT',
	body: JSON.stringify(document)
})

export const getDocuments = (pageNo, params) => fetchFactory(`/recherche/${pageNo}`, {
	method: 'POST',
	body: JSON.stringify(params)
})

export const getTopLike = () => fetchFactory('/recherche/top_like')

export const getListFieldMoDocuments = (params) => fetchFactory('/recherche/moDocumentField', {
	method: 'POST',
	body: JSON.stringify(params)
})

export const getPropositions = () => fetchFactory('/document/propositions')

export const updateDocumentStatus = (id, status, message) => fetchFactory(`/document/${id}`, {
	method: 'PUT',
	body: JSON.stringify({ status: status, message: message })
})

export const shareDocument = (id, values) => fetchFactory(`/document/${id}`, {
	method: 'POST',
	body: JSON.stringify(values)
})

/**
 * Partage de la recherche
 * @param values
 * @returns {*}
 */
export const shareRecherche = (values) => fetchFactory('/recherche/partage', {
	method: 'POST',
	body: JSON.stringify(values)
})

/**
 * Récupération d'une recherche enregistrée par id
 * @param id
 * @param isFavorite
 * @returns {*}
 */
export const getRechercheById = (id, isFavorite) => fetchFactory(`/recherche/${id}?isFavorite=${isFavorite}`)

export const exportRecherche = (params) => fetchFactory('/recherche/export', {
	method: 'POST',
	body: JSON.stringify(params)
})

export const rechercheDataLake = (search) => fetchFactory(`/dataLake/recherche`, {
	method: 'POST',
	body: JSON.stringify({ value: search })
})

export const rechercheListDataLake = (search) => fetchFactory(`/dataLake/rechercheListResult`, {
	method: 'POST',
	body: JSON.stringify(search)
})

export const exportContent = (id, idLang) => fetchFactory(`/document/${id}/export?idLanguage=${idLang}`)

export const getFieldFileContent = (idField) => fetchFactory(`/document/field/${idField}`)

export const getFileUploaded = (idField) => fetchFactory(`/document/field/${idField}/uploaded`)

export const exportFollow = (id) => fetchFactory(`/document/${id}/exportFollow`)

export const getAdditionalFilters = (idLanguage, subSegments) => fetchFactory(`/additional-filters/sections/${idLanguage}`, {
	method: 'POST',
	body: JSON.stringify(subSegments.map(subSegment => subSegment.code))
})

export const rechercheDrivePopin = (idField,search,listIdFolder) => fetchFactory(`/document/field/${idField}/searchDriver`, {
	method: 'POST',
	body: JSON.stringify({ queryRechercheDrive: search.queryRechercheDrive, listIdFolder: listIdFolder })
})

export const getFieldDunsContent = (idDuns) => fetchFactory(`/document/duns/${idDuns}`)
