import { Grid, withStyles } from '@material-ui/core'
import Button from '../../../../components/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'
import { readPreferenceList, removeZeroFromList } from '../../../../utils/utils'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { formValueSelector, getFormValues, reduxForm } from 'redux-form'
import EmptyResult from '../../../../components/layout/EmptyResult'
import Loader from '../../../../components/layout/Loader'
import SearchResultHeader from '../../../../components/layout/SearchResultHeader'
import Link from '../../../../components/Link'
import AccessChecker, { hasAccess } from '../../../../components/security/AccessChecker'
import checkRole from '../../../../components/security/RoleChecker'
import {
	BUSINESS_SEGMENT,
	BUSINESS_SUB_SEGMENT,
	colors,
	DEFAULT_LANGUAGE_ID,
	FIELDS_NO_FORMAT,
	GlOBAL_LANGUAGE_ID,
	MARKET,
	PROFILS,
	ROLE_WEIGHT,
	TYPE_CONTENU,
	TYPE_CONTENU_FIELD,
	USER_PREFERENCES,
	VALUE_LIST_SHORTCUTS, ZONE
} from '../../../../utils/constants'
import * as listesSelectors from '../../../bo/listes/listesSelectors'
import { getAllUsers, getUsersForCorrespondant } from '../../user/services/usersApi'
import * as userSelectors from '../../user/services/userSelectors'
import { getUserEmail, getUserLanguage } from '../../user/services/userSelectors'
import RechercheDocumentForm from '../components/recherche/RechercheDocumentForm'
import { getDocuments, getRechercheById, shareRecherche } from '../services/documentApi'
import { DOCUMENT_STATUS, FIELD_TYPE } from '../utils/documentConstants'
import AddRecherchePopin from './AddRecherchePopin'
import DocumentCard from './DocumentCard'
import ShareRecherchePopin from './ShareRecherchePopin'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import * as favorisActions from '../../favoris/services/favorisActions'
import _ from 'lodash'
import { exportRecherche } from '../services/documentActions'
import ExportRecherchePopin from './ExportRecherchePopin'
import { push } from 'connected-react-router'
import { initFiltersFavoriteSearch } from '../utils/documentUtils'
import { formatAdvancedFilter, newParamsCreation, transformIntoStringTab } from '../../../../utils/utilsRecherche'
import { injectBidBriefList, injectBusinessSegmentList, injectBusinessSubSegmentList, injectCoreBusinessList, injectMarketList, injectPaysList, injectSectorList, injectWinLoseList, injectZoneList } from '../../../common/valueList/ValueListInjector'
import { injectLangues } from '../../../common/langue/langueInjector'

const styles = theme => getStyles({
	container: {
		margin: '18px 10px 6px 10px',
		height: 36,
		fontSize: 24,
		alignItems: 'center'
	},
	h2: {
		fontWeight: 'lighter'
	},
	search: {
		margin: '10px 0px 5px 10px',
		padding: 10,
		height: '76vh',
		overflowY: 'auto',
		overflowX: 'hidden'
	},
	filterTitle: {
		marginBottom: 20,
		marginTop: 14,
		marginLeft: 16
	},
	voirplus: {
		margin: 'auto'
	},
	input: {
		marginBottom: 10
	},
	link: {
		color: colors.primary,
		float: 'right',
		fontWeight: 500,
		paddingBottom: 15,
		marginRight: 10
	},
	noResultContainer: {
		position: 'relative',
		height: 'calc(100vh - 265px)',
		margin: 10,
		backgroundColor: theme.palette.empty,
		minHeight: 590
	},
	noResult: {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%) translateX(-50%)',
		left: '50%'
	},
	addNewContent: {
		backgroundColor: 'white',
		border: '2px solid',
		'&:hover': {
			border: '2px solid'
		}
	},
	searchResultContainer: {
		padding: '0px 7px'
	},
	searchResultEntries: {
		maxHeight: 'calc(100vh - 200px)',
		overflowY: 'auto',
		overflowX: 'hidden'
	},
	searchColumn: {
		position: 'relative'
	},
	divVoirPlus: {
		textAlign: 'center'
	}
})

const RechercheDocumentPage = ({
	classes,
	handleSubmit,
	reset,
	change,
	initialQuery,
	initialTypeContenu,
	initialFieldsAdvance,
	initialValues,
	intl,
	mesContrib,
	contenuAValider,
	contenuAPublier,
	isCorrespondant,
	formValues,
	history,
	snackSuccess,
	snackError,
	initialId,
	addToFavorite,
	isFavorite,
	exportRecherche,
	goToNewContent,
	typeContenu,
	winLoseList,
	userLanguage,
	initialFieldsAdmin,
	contenuARetirer,
	goToEditList,
	zoneList,
	paysList,
	langues,
	marketList,
	sectorList,
	businessSegmentList,
	businessSubSegmentList,
	coreBusinessList,
	bidBriefList
}) => {

	const [documents, setDocuments] = React.useState([])
	const [fieldsAdvance, setFieldsAdvance] = React.useState([])
	const [lastSubmittedFieldsAdvance, setLastSubmittedFieldsAdvance] = React.useState(fieldsAdvance)
	const [fieldsAdmin, setFieldsAdmin] = React.useState({})
	const [loading, setLoading] = React.useState(false)
	const [pageNo, setPageNo] = React.useState(0)
	const [pageLoading, setPageLoading] = React.useState(false)
	const [totalElements, setTotalElements] = React.useState(null)
	const [params, setParams] = React.useState({})
	const [usersList, setUsersList] = React.useState([])
	const [openShare, setOpenShare] = React.useState(false)
	const [openExport, setOpenExport] = React.useState(false)
	const [openAddFavorite, setOpenAddFavorite] = React.useState(false)
	const [anchorElTris, setAnchorElTris] = React.useState(undefined)
	const [anchorElMore, setAnchorElMore] = React.useState(undefined)
	const [resetNbChipRequired, setResetNbChipRequired] = React.useState(false)
	const [adminCorrespFilters, setAdminCorrespFilters] = React.useState([])
	const [doScrolled, setDoScrolled] = React.useState(false)
	const [isSearchLaunch, setIsSearchLaunch] = React.useState(true)
	const [notificationEnable, setNotificationEnable] = React.useState(false)

	React.useEffect(() => {
		if (!formValues || !formValues.idConsulte) {
			return
		}

		if(!doScrolled) {
			return;
		}

		const anchorEl = document.getElementById(formValues.idConsulte.toString())
		if (anchorEl) {
			anchorEl.scrollIntoView()
			setDoScrolled(false);
		}
		// Si on arrive ici c'est qu'on doit scroll donc on clean
		change('idConsulte', undefined)
	}, [doScrolled]);


	React.useEffect(() => {
		if (isCorrespondant) {
			getUsersForCorrespondant()
				.then(setUsersList)
		} else {
			getAllUsers()
				.then(setUsersList)
		}
	}, [isCorrespondant])

	React.useEffect(() => {
		launchSearch()
	}, [initialId, initialQuery, initialTypeContenu, initialFieldsAdvance])

	React.useEffect(() => {
		renderResults()
	}, [documents])

	const launchSearch = () => {
		if(initialId){
			launchIdSearch()
		} else if((initialQuery !== undefined || initialTypeContenu !== undefined || initialFieldsAdvance !== undefined)){
			launchHomeSearch()
		} else {
			launchReturnSearch()
		}
	}

	const launchHomeSearch = () => {
		// Si je viens de la page d'accueil, je réinitialise le form
		reset()
		change('idConsulte', undefined)
		if (initialQuery) {
			change('query', initialQuery)
			const state = { ...history.location.state }
			delete state.initialQuery
			history.replace({ ...history.location, state })
		}
		if (initialTypeContenu || initialTypeContenu === 0) {
			if (initialTypeContenu) {
				change('typeContenu', [{ code: initialTypeContenu }])
			}
			const state = { ...history.location.state }
			delete state.initialTypeContenu
			history.replace({ ...history.location, state })
		}
		if (initialFieldsAdvance) {
			change('fieldsAdvance', initialFieldsAdvance)
			const state = { ...history.location.state }
			delete state.initialFieldsAdvance
			history.replace({ ...history.location, state })
		}
		if (initialFieldsAdmin) {
			change('fieldsAdmin', initialFieldsAdmin)
			const state = { ...history.location.state }
			delete state.initialFieldsAdmin
			history.replace({ ...history.location, state })
		}

		getDocument(
			0,
			{
				...initialValues,
				query: initialQuery || '',
				sortFields: [],
				fieldsAdvance: initialFieldsAdvance || '',
				fieldsAdmin: initialFieldsAdmin || '',
				isSearcheAuto: false
			}
		)
	}

	const launchIdSearch = () => {
		// Dans le cas d'une recherche en favori ou partagée
		change('idConsulte', undefined)
		setLoading(true)
		getRechercheById(initialId, isFavorite)
			.then(data => {
				setParams(data.filters)
				// Formattage des values pour l'init du formulaire
				const filters = {
					...data.filters,
					fieldsAdvance: data.filters.fieldsAdvance.map(f => {
						let fieldValue = f.value
						const unite = f.unite && JSON.parse(f.unite)

						// Conversion des éventuels fitres de type unité en unité non SI
						if (f.type === FIELD_TYPE.NUMBER_UNIT && unite) {
							fieldValue = fieldValue * unite.coeff
						}
						return ({
							...f,
							unite,
							value: fieldValue
						})
					})
				}
				if (filters.fieldsAdmin && Object.values(filters.fieldsAdmin).length > 0) {
					initFiltersFavoriteSearch(filters, setAdminCorrespFilters)
				}

				Object.keys(filters).forEach(filterKey => {
					change(filterKey, filters[filterKey])
				})
				handleRechercheResults(data.documents, data.filters)
			})
			.catch(() => {
				setLoading(false)
				snackError(<FormattedMessage id="document.search.notFound" />)
			})
	}

	const launchReturnSearch = () => {
		// Récupération des filtres admins
		if (formValues && formValues.fieldsAdmin && formValues.fieldsAdmin.length > 0) {
			setAdminCorrespFilters(formValues.fieldsAdmin)
		}
		if(formValues && formValues.idConsulte) {
			checkIdConsulte(
				0,
				{
					...initialValues,
					...(formValues || {}),
					query: (formValues && formValues.query) || '',
					isSearcheAuto: true,
					id:formValues.idConsulte
				}
			)
		} else {
			getDocument(
				0,
				{
					...initialValues,
					...(formValues || {}),
					query: (formValues && formValues.query) || '',
					isSearcheAuto: false
				}
			)
		}
	}

	const checkIdConsulte = (pageNo, params, avanceeParams, newTypeContenu = undefined, adminFilters) => {
		const newAvanceeParams = []
		formatAdvancedFilter(params, newAvanceeParams)

		const paramFilters = adminFilters || params.fieldsAdmin
		const newParams = newParamsCreation(params, initialTypeContenu, paramFilters, newAvanceeParams)

		getDocuments(pageNo, newParams)
			.then((data) => {
				if(data.nbResult < 0){
					getDocument(
						0,
						{
							...initialValues,
							...(formValues || {}),
							query: (formValues && formValues.query) || '',
							isSearcheAuto: true
						}
					)
				} else {
					getDocument(
						0,
						{
							...initialValues,
							...(formValues || {}),
							query: (formValues && formValues.query) || '',
							isSearcheAuto: false
						}
					)
				}
			})
			.catch((e) => {
				setDocuments(null)
				setLoading(false)
				setTotalElements(0)
			})
	}

	const getDocument = (pageNo, params, avanceeParams, newTypeContenu = undefined, adminFilters) => {
		//fieldsAdmin "|| null" sinon les tuiles de result pop avec une Chip "Contributeur :"
		const newAvanceeParams = []
		formatAdvancedFilter(params, newAvanceeParams)

		const paramFilters = adminFilters || params.fieldsAdmin
		const newParams = newParamsCreation(params, initialTypeContenu, paramFilters, newAvanceeParams)
		setParams(newParams)
		setLoading(true)

		// Enregistrement dans redux
		if (!!adminFilters) {
			change('fieldsAdmin', adminFilters)
		}

		getDocuments(pageNo, newParams)
			.then((data) => {
				handleRechercheResults(data, newParams)
			})
			.catch((e) => {
				setDocuments(null)
				setLoading(false)
				setTotalElements(0)
			})
	}

	const handleRechercheResults = (data, newParams) => {
		setDocuments(data.results)
		setTotalElements(data.nbResult)
		// On reduce via les clés pour ne pas avoir deux fois la tuile pour un même critère
		setFieldsAdvance(_.uniqBy(data.fieldsAdvance, 'code'))
		setFieldsAdmin(data.fieldsAdmin)
		if(newParams.isSearcheAuto) {
			// car de retour arriére si besoin on relance la recherche, mais ne faut pas faire de trace api coté BO
			const dataFilter = data.results.filter( element => {
				return element.idContent.valueField[0] === formValues.idConsulte.toString()
			})
			if(dataFilter.length > 0){
				setLoading(false)
				setDoScrolled(true);
			} else {
				voirPlus(newParams, data.results, pageNo)
			}
		} else {
			setLoading(false)
		}
	}

	const voirPlus = (newParams, results, pageNoLoad) => {
		setPageLoading(true)
		voirPlusDoc(newParams, results, pageNoLoad)
			.catch(() => {
				// 1 tentative en cas de retour infructueux
				voirPlusDoc(newParams, results, pageNoLoad)
					.catch(() => {
						setPageLoading(false)
					})
			})
	}

	const voirPlusDoc = (newParams, results, pageNoLoad) => {
		if(newParams){
			return getDocuments(pageNoLoad + 1, newParams)
				.then(data => {
					setPageNo(pageNoLoad + 1)
					params.pageNo = pageNoLoad
					var docu = results.concat(data.results)
					setDocuments(docu)
					if(formValues.idConsulte) {
						const dataFilter = data.results.filter( element => {
							return element.idContent.valueField[0] === formValues.idConsulte.toString()
						})
						if(dataFilter.length > 0){
							setLoading(false)
							setPageLoading(false)
							setDoScrolled(true);
						} else {
							voirPlus(newParams, docu, pageNoLoad+1)
						}
					} else {
						setLoading(false)
						setPageLoading(false)
					}
				})
		} else {
			params.isSearcheAuto = false
			return getDocuments(pageNo + 1, params)
				.then(data => {
					setPageNo(pageNo + 1)
					params.pageNo = pageNo
					setDocuments(documents.concat(data.results))
					setLoading(false)
					setPageLoading(false)
				})
		}
	}

	const rechercher = (values) => {
		setIsSearchLaunch(true)
		values.idConsulte = undefined
		values.isSearcheAuto = false
		setPageNo(0)
		getDocument(0, values, null, null, adminCorrespFilters)
	}
	const rechercherTris = (valuesTris) => {
		setAnchorElTris(null)
		change('sortFields', valuesTris)
		valuesTris.isSearcheAuto = false
		setPageNo(0)
		getDocument(0, { ...formValues, sortFields: valuesTris })
	}

	const rechercherAvancee = (avanceeValues) => {
		change('fieldsAdvance', avanceeValues)
	}

	const partageRecherche = (values) => {
		return shareRecherche({
			...values,
			recherche: params,
			isTabSearch: false
		})
			.then(() => {
				setOpenShare(false)
				snackSuccess(<FormattedMessage id="document.search.share.success" />)
			})
	}

	const addSearchToFavorite = (isNotificationEnabled) => {
		setNotificationEnable(isNotificationEnabled) // On preremplit la valeur dans la popin
		setAnchorElMore(null)
		setOpenAddFavorite(true)
	}

	const exportSearch = () => {
		setAnchorElMore(null)
		return exportRecherche({
			...params,
			viewFields: {
				fieldsAdvance: params.fieldsAdvance
			}
		})
			.then(() => snackSuccess(<FormattedMessage id="document.search.export.sent" />))
	}

	let titlePage = 'search.title'
	if (mesContrib) {
		titlePage = 'search.titleMesContrib'
	} else if (contenuAValider) {
		titlePage = 'search.titleAValider'
	} else if (contenuAPublier) {
		titlePage = 'search.titleAPublier'
	} else if (contenuARetirer) {
		titlePage = 'search.titleARetirer'
	}

	const getEmptyFilter = () => {
		reset()
		setResetNbChipRequired(true)
	}

	const setAnchorIdInValue = (id) => {
		if(isSearchLaunch){
			formValues.idConsulte = id
		} else {
			formValues.idConsulte = undefined
		}
	}

	const renderResults = () => {
		if (!documents || documents.length === 0) {
			return <Grid item sm={12} className={classes.noResultContainer}><EmptyResult className={classes.noResult} message={<FormattedMessage
				id="document.search.noResults" />} /></Grid>
		}
		return (<div className={classes.searchResultEntries}>
			{documents && documents.map(element =>
				<DocumentCard
					usersList={usersList}
					document={element}
					fieldsAdvance={fieldsAdvance}
					fieldsAdmin={fieldsAdmin}
					key={element.idContent.valueField[GlOBAL_LANGUAGE_ID]}
					mesContribScreen={mesContrib}
					setAnchorId={setAnchorIdInValue}
				/>
			)}
			{(documents && totalElements > documents.length) &&
			<div className={classes.divVoirPlus}>
				<Button
					key="voirPlus"
					color="primary"
					onClick={voirPlus}
					loading={pageLoading}
					className={classes.voirplus}
				>
					<FormattedMessage id="global.buttons.voirPlus" />
				</Button>
			</div>}
		</div>)
	}

	/**
	 * Permet de savoir si on doit afficher les boutons d'accès au tableau OSS. True si on cherche des ref et false sinon
	 * @returns {boolean}
	 */
	const shouldDisplayAccessOssList = () => {
		if(typeContenu) {
			if (typeContenu.length === 0) { return true } else {
				for (const element of typeContenu) {
					if (element.code === TYPE_CONTENU.REFERENCE) { return true}
				}
			}
		}
	  	return false
	}

	// actualise les données de la recherche favorite
	const handleUpdateFavoriteSearch = (values) => {
		const id = window.location.pathname.split('/').reverse()[0]
		if (id && params && values) {
			const newAvanceeParams = []
			formatAdvancedFilter(params, newAvanceeParams)
			const newParams = newParamsCreation(values, initialTypeContenu, adminCorrespFilters, newAvanceeParams)

			const body = {
				...newParams,
				idRecherche: id
			}
			rechercher(values)
			addToFavorite(body)
				.then(() => snackSuccess(<FormattedMessage id="favoris.updateSearch.success"/>))
				.catch(() => snackError(<FormattedMessage id="favoris.updateSearch.error" />))
		}
	}

	const isFavoriteSearchUpdating = () => {
		return window.location.pathname.includes('favoris')
	}

	return (
		<>
			<Grid container>
				<Grid item container className={classes.container} justifyContent="space-between">
					<Grid item>
						<Typography variant="h2" className={classes.h2}><FormattedMessage id={`document.${titlePage}`} /></Typography>
					</Grid>
					<AccessChecker access={[PROFILS.MEMBRE, PROFILS.CORRESPONDANT, PROFILS.ADMIN]}>
						<Grid item>
							<Button
								key="access"
								className={classes.addNewContent}
								color="primary"
								variant="outlined"
								onClick={goToNewContent}
							>
								<FormattedMessage id="accueil.mesContributions.add" />
							</Button>
						</Grid>
					</AccessChecker>
				</Grid>
				<Grid item container sm={12} alignItems="flex-start">
					<Grid item sm={3} className={classes.searchColumn}>
						<Paper className={classes.search}>
							<Typography variant="h2" className={classes.filterTitle}><FormattedMessage
								id="document.search.filterTitle" /></Typography>
							<Link onClick={getEmptyFilter} className={classes.link}><FormattedMessage id="global.link.viderFiltre" /></Link>
							<RechercheDocumentForm
								langues={langues}
								zoneList={zoneList}
								businessSegmentList={businessSegmentList}
								businessSubSegmentList={businessSubSegmentList}
								coreBusinessList={coreBusinessList}
								bidBriefList={bidBriefList}
								marketList={marketList}
								sectorList={sectorList}
								paysList={paysList}
								winLoseList={winLoseList}
								resetNbChipRequired={resetNbChipRequired}
								setResetNbChipRequired={setResetNbChipRequired}
								usersList={usersList}
								intl={intl}
								lastSubmittedFieldsAdvance={lastSubmittedFieldsAdvance}
								setLastSubmittedFieldsAdvance={setLastSubmittedFieldsAdvance}
								onSubmit={handleSubmit(rechercher)}
								isCorrespondant={isCorrespondant}
								contenuAValider={contenuAValider}
								formValues={formValues}
								addField={(field) => rechercherAvancee([...((formValues && formValues.fieldsAdvance) || []), field])}
								rechercherAvancee={rechercherAvancee}
								adminCorrespFilters={adminCorrespFilters}
								setAdminCorrespFilters={setAdminCorrespFilters}
								initialValues={initialValues}
								clearSearchAdvance={() => {
									if (formValues && formValues.fieldsAdvance && formValues.fieldsAdvance.length > 0) {
										rechercherAvancee([])
									} else {
										change('fieldsAdvance', [])
									}
								}}
								change={change}
								typeContenu={typeContenu}
								initialTypeContenu={initialTypeContenu}
								initialFieldsAdmin={initialFieldsAdmin}
								setIsSearchLaunch={setIsSearchLaunch}
								handleUpdate={handleSubmit(handleUpdateFavoriteSearch)}
								isUpdateButtonDisplayed={isFavoriteSearchUpdating()}
							/>
						</Paper>
					</Grid>
					{loading ? <Grid item sm={9} xs={10} className={classes.searchResultContainer}><Loader minHeight={false} /></Grid> : <Grid item container sm={9} className={classes.searchResultContainer}>
						<Grid item sm={12}>
							<SearchResultHeader
								title={<FormattedMessage id="global.resultTitle" values={{ results: totalElements }} />}
								setAnchorElTris={setAnchorElTris}
								setAnchorElMore={setAnchorElMore}
								fromSearchDocumentPage
								displayAccessOssList={shouldDisplayAccessOssList()}
								goToEditList={(onEdition) => goToEditList(onEdition ? '/edition-table' : '/consultation-table', params)}
								addSearchToFavorite={addSearchToFavorite}
								isNotificationButtonDisplayed={!isFavoriteSearchUpdating()}
							/>
						</Grid>
						{renderResults()}
					</Grid>}
				</Grid>
			</Grid>
			<Menu
				id="menuTris"
				anchorEl={anchorElTris}
				open={Boolean(anchorElTris)}
				onClose={() => setAnchorElTris(null)}
			>
				<MenuItem onClick={() => rechercherTris([{ reference: '', order: '' }])}><FormattedMessage id="global.tris.pertinence" /></MenuItem>
				<MenuItem onClick={() => rechercherTris([{ reference: userLanguage || DEFAULT_LANGUAGE_ID, order: 'ASC' }])}><FormattedMessage id="global.tris.a_z" /></MenuItem>
				<MenuItem onClick={() => rechercherTris([{ reference: userLanguage || DEFAULT_LANGUAGE_ID, order: 'DESC' }])}><FormattedMessage id="global.tris.z_a" /></MenuItem>
				<MenuItem onClick={() => rechercherTris([{ reference: `dateModification.valueField.${GlOBAL_LANGUAGE_ID}`, order: 'DESC' }])}><FormattedMessage id="global.tris.date_modification_desc" /></MenuItem>
				<MenuItem onClick={() => rechercherTris([{ reference: `dateModification.valueField.${GlOBAL_LANGUAGE_ID}`, order: 'ASC' }])}><FormattedMessage id="global.tris.date_modification_asc" /></MenuItem>
				<MenuItem onClick={() => rechercherTris([{ reference: `nombreLike.valueField.${GlOBAL_LANGUAGE_ID}`, order: 'DESC' }])}><FormattedMessage id="global.tris.like" /></MenuItem>
			</Menu>
			<Menu
				id="menuMore"
				anchorEl={anchorElMore}
				open={Boolean(anchorElMore)}
				onClose={() => setAnchorElMore(null)}
			>
				<AccessChecker access={[PROFILS.MEMBRE, PROFILS.CORRESPONDANT]}>
					<MenuItem disabled={totalElements === 0} onClick={exportSearch}><FormattedMessage id="global.more.export_result" /></MenuItem>
				</AccessChecker>
				<AccessChecker access={[PROFILS.ADMIN]}>
					<MenuItem disabled={totalElements === 0} onClick={() => setOpenExport(true)}><FormattedMessage id="global.more.export_result" /></MenuItem>
				</AccessChecker>
				<AccessChecker access={[PROFILS.MEMBRE, PROFILS.CORRESPONDANT, PROFILS.ADMIN]}>
					<MenuItem onClick={() => setOpenShare(true)}><FormattedMessage id="global.more.partage_result" /></MenuItem>
					<MenuItem onClick={() => {
						addSearchToFavorite(false)
					}}><FormattedMessage id="global.more.ajouter_favoris_result" /></MenuItem>
					{/*<MenuItem onClick={() => {*/}
					{/*	addSearchToFavorite(true)*/}
					{/*}}><FormattedMessage id="global.more.ajouter_favoris_result_notif_on" /></MenuItem>*/}
				</AccessChecker>
			</Menu>

			{openShare && <ShareRecherchePopin
				title={<FormattedMessage id="document.search.share.title" />}
				open={openShare}
				onClose={() => setOpenShare(false)}
				onSubmit={partageRecherche}
			/>}
			{openExport && <ExportRecherchePopin
				open={openExport}
				onClose={() => setOpenExport(false)}
				params={params}
				snackSuccess={snackSuccess}
			/>}
			{openAddFavorite && <AddRecherchePopin
				title={<FormattedMessage id="document.search.addToFavorite.title" />}
				open={openAddFavorite}
				onClose={() => setOpenAddFavorite(false)}
				params={params}
				notificationEnable={notificationEnable}
			/>}
		</>
	)
}

const getDefaultMapStateToProps = (formName) => (state) => {
	//Formattage des list pour matcher avec les attentes de parentValue
	//Value d'un champ avec autocompleteMulti : "code" -> {label:"Test", code: "code"}

	const zoneSelected = removeZeroFromList(formValueSelector(formName)(state, ZONE))
	const zoneSelectedFormat = transformIntoStringTab(zoneSelected)
	const segmentSelected = removeZeroFromList(formValueSelector(formName)(state, BUSINESS_SEGMENT))
	const segmentSelectedFormat = transformIntoStringTab(segmentSelected)
	const subSegmentSelected = removeZeroFromList(formValueSelector(formName)(state, BUSINESS_SUB_SEGMENT))
	const subSegmentSelectedFormat = transformIntoStringTab(subSegmentSelected)
	const marketSelected = removeZeroFromList(formValueSelector(formName)(state, MARKET))
	const marketSelectedFormat = transformIntoStringTab(marketSelected)

	const formValues = getFormValues(formName)(state)
	const authorities = userSelectors.getAuthorities(state)
	const isCorrespondant = hasAccess(PROFILS.CORRESPONDANT, authorities)

	const selector = formValueSelector(formName)

	// Lors du partage de recherche, on stocke en base les informations des champs AutoComplete multi
	// Sous forme { zone: ["ZONE_A","ZONE_B"] ...
	// Ici on formatte ces valeurs de facons à ce que les autocomplete puissent les interpreter et se charger
	// Lors de l'accès à l'URL tenderbox/documents/{idRecherche}
	// On obtient donc { zone: [{code: "ZONE_A"}, {code: "ZONE_B"}] ...
	if (formValues) {
		Object.entries(formValues).forEach(([key,value]) => {
			if (key !== FIELDS_NO_FORMAT.FILTRES_ADMIN && key !== FIELDS_NO_FORMAT.FILTRES_AVANCE
				&& key !== FIELDS_NO_FORMAT.QUERY && key !== FIELDS_NO_FORMAT.VIEW_FIELDS
				&& key !== FIELDS_NO_FORMAT.SORT_FIELDS && key !== FIELDS_NO_FORMAT.ID_CONSULTE
				&& key !== FIELDS_NO_FORMAT.TITLE && key !== FIELDS_NO_FORMAT.WEEKLY_NOTIFICATION) {
				if (value && key !== 'idRecherche') {
					formValues[key] = value.map(val => {
						if (typeof val === 'string') {
							return ({ code: val })
						}
						return val
					})
				}
			}
		})
	}

	return {
		paysList: listesSelectors.getFilteredListsPays(state, zoneSelectedFormat, VALUE_LIST_SHORTCUTS.PAYS),
		sectorList: listesSelectors.getFilteredLists(state, marketSelectedFormat, VALUE_LIST_SHORTCUTS.SECTOR),
		businessSubSegmentList: listesSelectors.getFilteredLists(state, segmentSelectedFormat, VALUE_LIST_SHORTCUTS.BUSINESS_SUB_SEGMENT),
		coreBusinessList: listesSelectors.getFilteredLists(state, subSegmentSelectedFormat || segmentSelectedFormat, VALUE_LIST_SHORTCUTS.CORE_BUSINESS),
		userLanguage: getUserLanguage(state),
		formValues,
		isCorrespondant,
		typeContenu: selector(state, TYPE_CONTENU_FIELD),
		initialValues: {
			query: '',
			fieldsAdvance: [],
			sortFields: []
		}
	}
}

RechercheDocumentPage.propTypes = {
	classes: PropTypes.object,
	formValues: PropTypes.object,
	handleSubmit: PropTypes.func,
	reset: PropTypes.func,
	dirty: PropTypes.bool,
	mesContrib: PropTypes.bool,
	contenuAValider: PropTypes.bool,
	contenuAPublier: PropTypes.bool,
	contenuARetirer: PropTypes.bool,
	isFavorite: PropTypes.bool,
	snackSuccess: PropTypes.func,
	snackError: PropTypes.func,
	addToFavorite: PropTypes.func,
	goToEditList: PropTypes.func,
	initialId: PropTypes.string,
	initialFieldsAdmin: PropTypes.object
}

const getDefaultCompose = () => compose(
	injectZoneList,
	injectPaysList,
	injectLangues,
	injectMarketList,
	injectSectorList,
	injectBusinessSegmentList,
	injectBusinessSubSegmentList,
	injectCoreBusinessList,
	injectBidBriefList,
	injectWinLoseList,
	injectSnackActions,
	injectIntl,
	withStyles(styles)
)

const getDefaultActions = {
	addToFavorite: favorisActions.addRechercheToFavorite,
	exportRecherche,
	goToNewContent: () => dispatch => dispatch(push('/new-document')),
	goToEditList: (pathname, state) => dispatch => dispatch(push({ pathname, state }))
}

const CONTRIBUTIONS_SEARCH_FORM = 'contributionsSearchForm'
export const MesContributions = compose(
	getDefaultCompose(),
	connect((state) => {
		const commonMapState = getDefaultMapStateToProps(CONTRIBUTIONS_SEARCH_FORM)(state)

		return {
			...commonMapState,
			mesContrib: true,
			contenuAValider: false,
			initialValues: {
				...commonMapState.initialValues,
				fieldsAdmin: {
					contributor: [{ code: getUserEmail(state) }]
				}
			}
		}
	}, getDefaultActions),
	reduxForm({
		form: CONTRIBUTIONS_SEARCH_FORM,
		destroyOnUnmount: false
	}),
	withStyles(styles)
)(RechercheDocumentPage)

const CONTENU_RETRAIT_SEARCH_FORM = 'contenuRetraitSearchForm'
export const ContenuARetirer = compose(
	checkRole(ROLE_WEIGHT.ROLE_ADMIN),
	getDefaultCompose(),
	connect((state) => {
		const commonMapState = getDefaultMapStateToProps(CONTENU_RETRAIT_SEARCH_FORM)(state)

		return {
			...commonMapState,
			contenuARetirer: true,
			initialValues: {
				...commonMapState.initialValues,
				fieldsAdmin: {
					status: [{ code: DOCUMENT_STATUS.A_MASQUER }, { code: DOCUMENT_STATUS.A_SUPPRIMER }]
				}
			}
		}
	}, getDefaultActions),
	reduxForm({
		form: CONTENU_RETRAIT_SEARCH_FORM,
		destroyOnUnmount: false
	}),
	withStyles(styles)
)(RechercheDocumentPage)

const CONTENU_VALIDER_SEARCH_FORM = 'contenuValiderSearchForm'
export const ContenuAValider = compose(
	checkRole(ROLE_WEIGHT.ROLE_CORRESPONDANT),
	getDefaultCompose(),
	connect((state) => {
		const commonMapState = getDefaultMapStateToProps(CONTENU_VALIDER_SEARCH_FORM)(state)
		const authorities = userSelectors.getAuthorities(state)
		const isCorrespondant = hasAccess(PROFILS.CORRESPONDANT, authorities)
		const isMember = hasAccess(PROFILS.MEMBRE, authorities)
		const userZone = userSelectors.getUserZone(state)
		const user = userSelectors.getUser(state)
		return {
			...commonMapState,
			isCorrespondant,
			contenuAValider: true,
			initialValues: {
				...commonMapState.initialValues,
				pays: isCorrespondant ? readPreferenceList(user, USER_PREFERENCES.FI_PAYS).map(preference => ({code: preference})) : [],
				market: isCorrespondant ? readPreferenceList(user, USER_PREFERENCES.FI_MARCHES).map(preference => ({code: preference})) : [],
				businessSegment: isCorrespondant ? readPreferenceList(user, USER_PREFERENCES.FI_SEGMENT).map(preference => ({code: preference})) : [],
				sector: isMember ? readPreferenceList(user, USER_PREFERENCES.FI_PRE_CLIENT_SECTOR).map(preference => ({code: preference})) : [],
				businessSubSegment: isMember ? readPreferenceList(user, USER_PREFERENCES.FI_SUBSEGMENT).map(preference => ({code: preference})) : [],
				zone: isCorrespondant ? [{ code: userZone }] : [],
				fieldsAdmin: {
					status: [{ code: DOCUMENT_STATUS.A_VALIDER }]
				}
			}
		}
	}, getDefaultActions),
	reduxForm({
		form: CONTENU_VALIDER_SEARCH_FORM,
		enableReinitialize: true,
		destroyOnUnmount: false
	}),
	withStyles(styles)
)(RechercheDocumentPage)

const CONTENU_PUBLIER_SEARCH_FORM = 'contenuPublierSearchForm'
export const ContenuAPublier = compose(
	checkRole(ROLE_WEIGHT.ROLE_ADMIN),
	getDefaultCompose(),
	connect((state) => {
		const commonMapState = getDefaultMapStateToProps(CONTENU_PUBLIER_SEARCH_FORM)(state)

		return {
			...commonMapState,
			contenuAPublier: true,
			initialValues: {
				...commonMapState.initialValues,
				fieldsAdmin: {
					status: [{ code: DOCUMENT_STATUS.VALIDE }]
				}
			}
		}
	}, getDefaultActions),
	reduxForm({
		form: CONTENU_PUBLIER_SEARCH_FORM,
		destroyOnUnmount: false
	}),
	withStyles(styles)
)(RechercheDocumentPage)

export const FORM_NAME_SEARCH = 'formSearch'
export default compose(
	getDefaultCompose(),
	connect((state, { location, match }) => {
		const commonMapState = getDefaultMapStateToProps(FORM_NAME_SEARCH)(state)
		const initialFieldsAdmin= location && location.state && location.state.initialFieldsAdmin


		return {
			...commonMapState,
			mesContrib: false,
			contenuAValider: false,
			initialQuery: location && location.state && location.state.initialQuery,
			initialTypeContenu: location && location.state && location.state.initialTypeContenu,
			initialFieldsAdvance: location && location.state && location.state.initialFieldsAdvance,
			initialFieldsAdmin: location && location.state && location.state.initialFieldsAdmin,
			isFavorite: (match && match.params && !!match.params.type),
			initialId: (match && match.params && match.params.id),
			initialValues: {
				...commonMapState.initialValues,
				fieldsAdmin: initialFieldsAdmin || {
					status: [{ code: DOCUMENT_STATUS.PUBLIE }]
				}
			}
		}
	}, getDefaultActions),
	reduxForm({
		form: FORM_NAME_SEARCH,
		destroyOnUnmount: false
	}),
	withStyles(styles)
)(RechercheDocumentPage)
