import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { FormattedMessage } from 'react-intl'
import Popin from '../../../../components/Popin'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import { colors, DEFAULT_LANGUAGE_ID } from '../../../../utils/constants'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Autocomplete from '../../../../components/form/Autocomplete'
import { getListFieldMoDocuments } from '../services/documentApi'
import { getUserLanguage } from '../../user/services/userSelectors'
import { loadListFoByShortcut } from '../../../common/valueList/valueListActions'
import { connect } from 'react-redux'
import Checkbox from '../../../../components/form/Checkbox'
import { exportRecherche } from '../services/documentActions'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import IconButton from '@material-ui/core/IconButton'

const styles = () => getStyles({
	popinResize: {
		maxWidth: 1000,
		height: 750
	},
	paddingText: {
		marginBottom: 15
	},
	paddingTitle: {
		paddingLeft: 10,
		paddingTop: 15,
		paddingBottom: 15
	},
	bloc: {
		backgroundColor: colors.background,
		minHeight: 500,
		paddingLeft: 20,
		paddingRight: 20
	},
	buttonAdd: {
		marginTop: 150,
		marginLeft: 12
	},
	buttonRemove: {
		marginTop: 20,
		marginLeft: 12
	},
	buttonRoot: {
		paddingRight: 10,
		paddingLeft: 10,
		width: '85%',
		display: 'flex',
		justifyContent: 'space-between'
	},
	buttonAction: {
		marginLeft: 50,
		marginRight: 50
	},
	checkbox: {
		marginBottom: '-15px'
	},
	defaultUnselected: {
		marginBottom: 20
	}
})

const DEFAULT_LABEL = {
	typeContenu: 'document.search.typeContenuLabel',
	titre: 'document.entete.title',
	langue: 'document.search.langueLabel',
	pays: 'document.search.paysLabel',
	zone: 'document.search.zoneLabel',
	market: 'document.search.marketLabel',
	businessSegment: 'document.search.businessSegmentLabel',
	businessSubSegment: 'document.search.businessSubSegmentLabel',
	coreBusiness: 'document.search.coreBusinessLabel',
	bidBrief: 'document.search.bidBriefLabel'
}

const ExportRecherchePopin = ({
								  open,
								  onClose,
								  onSubmit,
								  classes,
								  defaultFields,
								  advancedFields,
								  change,
								  handleSubmit,
								  selectTypeContenu,
								  userLanguage,
								  params,
								  exportRecherche,
								  snackSuccess,
								  disabledAdd,
								  disabledRemove
							  }) => {
	const [suggestionList, setSuggestionList] = React.useState([])
	const [expanded, setExpanded] = React.useState(false)

	React.useEffect(() => {
		if (Object.keys(defaultFields).every(key => defaultFields[key])) {
			setExpanded(false)
		}
	}, [defaultFields])

	const handleExpandClick = () => setExpanded(!expanded)

	const submit = (values) => {
		onClose()
		exportRecherche({
			...params,
			viewFields: {
				...values.defaultFields,
				fieldsAdvance: [...values.advancedFields.map(advancedField => ({
						...advancedField,
						searchType: '',
						value: 0
					})
				)]
			}
		})
			.then(() => snackSuccess({ id: 'document.search.export.sent' }))
	}

	const handleRemoveFields = values => {
		// Cas des default fields
		// On récupère l'ensemble des fields cochés pour les passer à non sélectionnés
		const removeDefaultFields = Object.keys(values.defaultSelected || {})
			.filter(key => values.defaultSelected[key])
			.reduce((acc, key) => ({
				...acc,
				[key]: false
			}), {})

		if (Object.keys(removeDefaultFields).length > 0) {
			change('defaultFields', {
				...defaultFields,
				...removeDefaultFields
			})
		}

		// Cas des advanced fields
		if (Object.keys(values.advancedSelected || []).length > 0) {
			change('advancedFields',
				advancedFields.filter(advanced => !values.advancedSelected[advanced.code])
			)
		}

		resetFieldsList()
	}

	const handleAddFields = values => {
		// Cas des default fields
		// On récupère l'ensemble des fields cochés pour les passer à sélectionnés
		if (Object.keys(values.defaultUnselected || []).length > 0) {
			change('defaultFields', {
				...defaultFields,
				...values.defaultUnselected
			})
		}

		// Cas des advanced fields
		if (Object.keys(values.advancedUnselected || []).length > 0) {
			change('advancedFields', [
				...advancedFields,
				...suggestionList.filter(suggestion =>
					values.advancedUnselected[suggestion.code] &&
					!advancedFields.map(selected => selected.code).includes(suggestion.code)
				)
			])
		}

		resetFieldsList()
	}

	const resetFieldsList = () => {
		change('defaultSelected', {})
		change('defaultUnselected', {})
		change('advancedSelected', {})
		change('advancedUnselected', {})
	}

	const actions = [
		<Grid container justify="center" key="actionExport">
			<Button
				className={classes.buttonAction}
				color="inherit"
				variant="outlined"
				key="annuler"
				onClick={onClose}
			>
				<FormattedMessage id="global.buttons.annuler" />
			</Button>
			<Button
				key="valider"
				color="primary"
				variant="contained"
				type="submit"
				onClick={handleSubmit(submit)}
			>
				<FormattedMessage id="document.search.export.send" />
			</Button>
		</Grid>
	]

	return <Popin
		dialogClasses={{ paper: classes.popinResize }}
		open={open}
		actions={actions}
		title={<FormattedMessage id="document.search.export.title" />}
		onClose={onClose}
		content={<form onSubmit={onSubmit}>
			<Typography variant="body2" className={classes.paddingText}><FormattedMessage
				id="document.search.export.description" /></Typography>
			<Grid container direction="row">
				<Grid item xs={5} className={classes.bloc}>
					<Typography variant="h4" className={classes.paddingTitle}><FormattedMessage
						id="document.search.export.selection" /></Typography>
					{!Object.keys(defaultFields).every(key => defaultFields[key]) &&
					<Grid container direction="row">
						<Typography className={classes.paddingTitle} variant="body1"><FormattedMessage
							id="document.search.export.default" /></Typography>
						<IconButton
							onClick={handleExpandClick}
						>
							{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</IconButton>
					</Grid>
					}
					<Collapse in={expanded} timeout="auto" unmountOnExit className={classes.defaultUnselected}>
						{Object.keys(defaultFields).map(key =>
							!defaultFields[key] &&
							<Field
								name={`defaultUnselected.${key}`}
								component={Checkbox}
								className={classes.checkbox}
								label={<FormattedMessage id={DEFAULT_LABEL[key]} />}
							/>
						)}
					</Collapse>
					<Field
						name="searchAutocomplete"
						label={<FormattedMessage id="document.search.export.placeholder" />}
						component={Autocomplete}
						getSuggestion={(inputLength, inputValue) => getListFieldMoDocuments({
							'query': inputValue,
							'typeContenu': selectTypeContenu
						}).then(fields => fields.map(field => ({
								...field,
								label: field.label[userLanguage] || field.label[DEFAULT_LANGUAGE_ID],
								path: field.path[userLanguage] || field.path[DEFAULT_LANGUAGE_ID]
							}))
						)}
						minLength={3}
						disableSuggestions
						onClickItem={value => {
							setSuggestionList([value])
						}}
						onChange={(ev, value) => {
							if (value.length >= 3) getListFieldMoDocuments({
								'query': value,
								'typeContenu': selectTypeContenu
							}).then(fields => setSuggestionList(fields.map(field => ({
									...field,
									label: field.label[userLanguage] || field.label[DEFAULT_LANGUAGE_ID],
									path: field.path[userLanguage] || field.path[DEFAULT_LANGUAGE_ID]
								}))
							))
								.then(() => change('advancedUnselected', {}))
						}}
					/>
					{suggestionList
						.filter(column => !advancedFields.find(adv => adv.code === column.code))
						.map(column => <Field
						name={`advancedUnselected.${column.code}`}
						component={Checkbox}
						className={classes.checkbox}
						label={column.label}
					/>)}
				</Grid>
				< Grid item xs={2}>
					<Button
						color='primary'
						variant='contained'
						key='ajouter'
						className={classes.buttonAdd}
						classes={{
							root: classes.buttonRoot
						}}
						disabled={disabledRemove}
						onClick={handleSubmit(handleAddFields)}
					>
						<FormattedMessage id='document.search.export.add' />
						<ArrowForwardIcon style={{ fontSize: 30 }} />
					</Button>
					<Button
						color="primary"
						variant="contained"
						key="retirer"
						className={classes.buttonRemove}
						classes={{
							root: classes.buttonRoot
						}}
						disabled={disabledAdd}
						onClick={handleSubmit(handleRemoveFields)}
					>
						<ArrowBackIcon style={{ fontSize: 30 }} />
						<FormattedMessage id="document.search.export.remove" />
					</Button>
				</Grid>
				<Grid item xs={5} className={classes.bloc}>
					<Typography variant="h4" className={classes.paddingTitle}><FormattedMessage
						id="document.search.export.selected" /></Typography>
					{Object.keys(defaultFields).map(key =>
						defaultFields[key] && <Field
							name={`defaultSelected.${key}`}
							component={Checkbox}
							className={classes.checkbox}
							label={<FormattedMessage id={DEFAULT_LABEL[key]} />}
						/>
					)}
					{advancedFields.map(column => <Field
						name={`advancedSelected.${column.code}`}
						component={Checkbox}
						label={column.label}
						className={classes.checkbox}
					/>)}
				</Grid>
			</Grid>
		</form>}
	/>
}

ExportRecherchePopin.propTypes = {
	open: PropTypes.bool,
	defaultFields: PropTypes.object,
	advancedFields: PropTypes.array,
	params: PropTypes.object,
	selectTypeContenu: PropTypes.string,
	userLanguage: PropTypes.string,
	change: PropTypes.func,
	handleSubmit: PropTypes.func,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	exportRecherche: PropTypes.func,
	snackSuccess: PropTypes.func,
	classes: PropTypes.object,
	disabledAdd: PropTypes.bool,
	disabledRemove: PropTypes.bool
}

const FORM_NAME = 'EXPORT_RECHERCHE_FORM'

const formSelector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, { params }) => {
	const defaultUnselected = formSelector(state, 'defaultUnselected') || {}
	const advancedUnselected = formSelector(state, 'advancedUnselected') || {}
	const defaultSelected = formSelector(state, 'defaultSelected') || {}
	const advancedSelected = formSelector(state, 'advancedSelected') || {}

	return ({
		userLanguage: getUserLanguage(state),
		defaultFields: formSelector(state, 'defaultFields') || {},
		advancedFields: formSelector(state, 'advancedFields') || [],
		disabledAdd: Object.keys(defaultSelected).every(key => !defaultSelected[key]) && Object.keys(advancedSelected).every(key => !advancedSelected[key]),
		disabledRemove: Object.keys(defaultUnselected).every(key => !defaultUnselected[key]) && Object.keys(advancedUnselected).every(key => !advancedUnselected[key]),
		initialValues: {
			defaultFields: {
				typeContenu: true,
				titre: true,
				langue: true,
				pays: true,
				zone: true,
				market: true,
				businessSegment: true,
				businessSubSegment: true,
				coreBusiness: true,
				bidBrief: true
			},
			advancedFields: params.fieldsAdvance || [],
		},
	})
}

const actions = {
	loadListFoByShortcut,
	exportRecherche
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: FORM_NAME
	}),
	withStyles(styles)
)(ExportRecherchePopin)
