import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import { Grid } from '@material-ui/core'
import Popin from '../../../../components/Popin'
import { connect } from 'react-redux'
import { TYPE_CONTENU } from '../../../../utils/constants'
import ItemSectionTabDocument from './ItemSectionTabDocument'
import * as modelSelectors from '../../../common/model/modelSelectors'
import Loader from '../../../../components/layout/Loader'
import * as tabDocumentSelector from '../services/tabDocumentSelector'

const styles = () => getStyles({
	popinResize: {
		maxWidth: 900,
		height: 'inherit'
	}
})

const formattedMessageBase = 'oss.popinChoixFields'

/**
 * Composant représentant la popin de selection des colonnes que l'user
 * souhaite voir apparaître dans le tableau de modification de documents
 */
const SelectionColonnesTabDocumentPopin = ({
	open,
	model,
	selectedLanguage,
	onClose,
	onSubmit,
	classes,
	 idsColumnsSelectedLength,
	isDataLoading
}) => {
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		setTimeout(() => {
			setLoading(false)
		}, 2000);
	}, []);

	const actions = [
		<Button color="inherit" variant="outlined"
		        key="fermer" onClick={onClose}
		        className={classes.button}>
			<FormattedMessage id="global.buttons.close" />
		</Button>,

		<Button key="valider" color="primary"
		        variant="contained" type="submit"
		        onClick={onSubmit}
		        className={classes.button}
		>
			<FormattedMessage id="global.buttons.validate" />
		</Button>
	]
	return (
		<div className={classes.popin}>
			<Popin
				fromSearch
				open={open}
				onClose={onClose}
				actions={actions}
				dialogClasses={{ paper: classes.popinResize }}
				title={<FormattedMessage id={`${formattedMessageBase}.title`}
					values={{ sizeColumns: idsColumnsSelectedLength }}
				/>}
				content={
					<Grid container className={classes.content} alignContent={'flex-start'} style={{ marginTop: '-20px' }}>
						<Grid item xs={12}>
							{(loading || isDataLoading)
								? <Loader absolute />
								: model.sections.map(sectionRacine =>
									<ItemSectionTabDocument
										section={sectionRacine}
										parentIds={[]}
										selectedLanguage={selectedLanguage}
									/>
								)}
						</Grid>
					</Grid>
				}
			/>
		</div>
	)
}

SelectionColonnesTabDocumentPopin.propTypes = {
	open: PropTypes.bool,
	model: PropTypes.object,
	selectedLanguage: PropTypes.string,
	allFieldsChecked: PropTypes.array,
	classes: PropTypes.object,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
}

const mapStateToProps = (state) => ({
	idsColumnsSelectedLength: Object.keys(tabDocumentSelector.getAllFieldsChecked(state)).length,
	model: modelSelectors.getModelByType(state, TYPE_CONTENU.REFERENCE)
})

export default compose(
	injectIntl,
	connect(mapStateToProps),
	withStyles(styles)
)(SelectionColonnesTabDocumentPopin)