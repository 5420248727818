import * as api from './usersApi'

const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const updatePreferences = (preferences) => dispatch => dispatch({
	type: GET_USER_SUCCESS,
	user: { preferences }
})

export const EXPORT_RECHERCHE_USER_REQUEST = 'EXPORT_RECHERCHE_USER_REQUEST'
export const EXPORT_RECHERCHE_USER_SUCCESS = 'EXPORT_RECHERCHE_USER_SUCCESS'
export const EXPORT_RECHERCHE_USER_ERROR = 'EXPORT_RECHERCHE_USER_ERROR'
export const exportRecherche = params => dispatch => {
	dispatch({ type: EXPORT_RECHERCHE_USER_REQUEST })
	return api.exportRecherche(params)
		.then(() => dispatch({ type: EXPORT_RECHERCHE_USER_SUCCESS }))
		.catch(e => {
			dispatch({ type: EXPORT_RECHERCHE_USER_ERROR, e })
			throw e
		})
}

export const OPEN_VISIT_POPUP = 'OPEN_VISIT_POPUP'
export const CLOSE_VISIT_POPUP = 'CLOSE_VISIT_POPUP'
export const openVisitPopup = () => dispatch => dispatch({ type: OPEN_VISIT_POPUP })
export const closeVisitPopup = () => dispatch => dispatch({ type: CLOSE_VISIT_POPUP })
