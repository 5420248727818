import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ShareIcon from '@material-ui/icons/Share'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import TranslateIcon from '@material-ui/icons/Translate'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'
import TimelineIcon from '@material-ui/icons/Timeline'
import { push } from 'connected-react-router'
import { getStyles } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React from 'react'
import { Field, useField } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Button from '../../../../components/Button'
import SvgIcon from '../../../../components/icon/SvgIcon'
import AccessChecker from '../../../../components/security/AccessChecker'
import { colors, DEFAULT_LANGUAGE_ID, PROFILS, SVG_ICONS } from '../../../../utils/constants'
import { getDocumentLike } from '../../like/likeApi'
import { exportContent, updateDocumentStatus } from '../services/documentActions'
import { exportFollow, shareDocument } from '../services/documentApi'
import { ATTRIBUTE_DATA_CONTENT, DOCUMENT_STATUS_ADVANCED, DOCUMENT_STATUS } from '../utils/documentConstants'
import SharePopin from './popin/SharePopin'
import * as likeActions from '../../like/likeActions'
import * as likeSelectors from '../../like/likeSelectors'
import * as favorisActions from '../../favoris/services/favorisActions'
import * as favorisSelectors from '../../favoris/services/favorisSelectors'
import DriveFolderPopin from './popin/drive/DriveFolderPopin'
import SuppressionDocumentPopin from './popin/SuppressionDocumentPopin'
import { isDocumentAMasquerOuASupprimer, isDocumentBrouillonOuAValider, isDocumentPublicOrToHideOrToDelete, isDocumentValideOuPublie } from '../../../../utils/utils'
import CustomSelect from '../../../../components/form/CustomSelect'

const styles = theme => getStyles({
	langRoot: {
		backgroundColor: colors.background
	},
	langWidth: {
		width: 160
	},
	langIcon: {
		fontSize: 36
	},
	tabSelected: {
		color: 'white',
		backgroundColor: theme.palette.text.main
	},
	marginIcon: {
		marginLeft: 30
	},
	marginIconStatus: {
		marginLeft: 30,
		maxHeight: 36,
		marginTop: 'auto',
		marginBottom: 'auto'
	},
	menuIcon: {
		marginRight: 20
	},
	tabConsultation: {
		marginRight: 10
	},
	draftIcon: {
		fontSize: 25,
		color: theme.palette.draft
	},
	publishedIcon: {
		fontSize: 25,
		color: theme.palette.secondary.main
	},
	docType: {
		fontWeight: 'bold'
	},
	likeButton: {
		borderRadius: 20,
		'& svg': {
			marginRight: 5
		},
		'& p': {
			margin: 0,
			fontSize: '1rem',
			fontWeight: 'bold'
		}
	}
})

const DocumentActionsBar = ({
	documentTender,
	documentType,
	isCorrespondantValid,
	isMemberValid,
	isAdmin,
	langues,
	userEmail,
	isDirty,
	updateDocumentStatus,
	snackSuccess,
	snackError,
	classes,
	consultation,
	goToContribution,
	workflowLoader,
	setWorkflowLoader,
	goToConsultation,
	openTranslatePopin,
	openGlobalReportPopin,
	handleLike,
	isLiked,
	isFavorite,
	addToFavorite,
	removeFromFavorite,
	exportContent,
	goToDocuments
}) => {
	const selectedLanguageField = useField('selectedLanguage')
	const [anchorEl, setAnchorEl] = React.useState(undefined)
	const [openShare, setOpenShare] = React.useState(false)
	const [likeNumber, setLikeNumber] = React.useState()
	const [suppressionPopinOpen, setSuppressionPopinOpen] = React.useState(false)

	const handleClosePopinSuppression = () => {
		setSuppressionPopinOpen(false)
	}

	const handleOpenPopinSuppression = () => {
		setAnchorEl(null)
		setSuppressionPopinOpen(true)
	}

	const getLikes = () => getDocumentLike(documentTender.id).then((like) => setLikeNumber(like))

	React.useEffect(() => {
		getLikes()
	}, [])

	const share = (values) =>
		shareDocument(documentTender.id, values)
			.then(() => {
				snackSuccess({ id: 'document.partage.success' })
				setOpenShare(false)
			})

	const handleSubmitWorkflowButton = () => {
		const btnProperty = {
			color: 'primary',
			variant: 'contained',
			className: classes.marginIconStatus,
			id: 'btnStatus',
			disabled: isDirty && !consultation
		}
		const workflow = getSubmitWorkflow()
		if (workflow === null) {
			return <></>
		}
		return <Button{...btnProperty} loading={workflowLoader} onClick={workflow.action}>
			<FormattedMessage id={`document.button.${workflow.label}`} />
		</Button>
	}

	const handleSubmitWorkflowMenu = () => {
		const workflow = getSubmitWorkflow()
		if (workflow === null) {
			return
		}
		return <MenuItem onClick={workflow.action}>
			{workflow.icon}
			<FormattedMessage id={`document.button.${workflow.label}`} />
		</MenuItem>
	}

	const getSubmitWorkflow = () => {
		if (!documentTender.workflow || !documentTender.workflow.status) {
			return null
		}
		switch (documentTender.workflow.status) {
			case DOCUMENT_STATUS.BROUILLON: {
				let label = 'soumettre'
				let statusOnClick = DOCUMENT_STATUS.A_VALIDER
				if (isAdmin) {
					label = 'publier'
					statusOnClick = DOCUMENT_STATUS.PUBLIE
				} else if (isCorrespondantValid) {
					label = 'valider'
					statusOnClick = DOCUMENT_STATUS.VALIDE
				}
				return {
					label,
					action: () => changeDocumentStatus(statusOnClick),
					icon: <CheckCircleIcon className={classes.menuIcon} />
				}
			}
			case DOCUMENT_STATUS.A_VALIDER: {
				if (isCorrespondantValid || isAdmin) {
					return {
						label: isCorrespondantValid ? 'valider' : 'publier',
						action: () => changeDocumentStatus(isCorrespondantValid ? DOCUMENT_STATUS.VALIDE : DOCUMENT_STATUS.PUBLIE),
						icon: <CheckCircleIcon className={classes.menuIcon} />
					}
				}
				return null
			}
			case DOCUMENT_STATUS.PUBLIE:
				return null
			case DOCUMENT_STATUS.A_MASQUER:
			case DOCUMENT_STATUS.A_SUPPRIMER:
				if (isAdmin) {
					return {
						label: 'retirer',
						action: () => handleOpenPopinSuppression(),
						icon: <CancelIcon className={classes.menuIcon} />
					}
				}
				return null
			case DOCUMENT_STATUS.VALIDE:
			case DOCUMENT_STATUS.MASQUE: {
				if (isAdmin) {
					return {
						label: 'publier',
						action: () => changeDocumentStatus(DOCUMENT_STATUS.PUBLIE),
						icon: <CheckCircleIcon className={classes.menuIcon} />
					}
				}
				return null
			}
			default:
				return null
		}
	}

	const changeDocumentStatus = (status, message = null, demandeEnCours = false) => {
		workflowLoader && setWorkflowLoader(true)
		updateDocumentStatus(documentTender.id, status, message)
			.then(() => {
				handleClosePopinSuppression()
				workflowLoader && setWorkflowLoader(false)
				// Si on supprime le contenu -> impossible de consulter ce dernier
				status === DOCUMENT_STATUS_ADVANCED.SUPPRIME
					? goToDocuments()
					: !consultation && goToConsultation(documentTender.id)
				snackSuccess({ id: `document.statusResponse.${demandeEnCours && status === DOCUMENT_STATUS_ADVANCED.ROLLBACK_DEMAND ? 'refus' : status}` })
			})
			.catch(() => {
				snackError({ id: 'document.statusResponse.error' })
				workflowLoader && setWorkflowLoader(false)
			})
	}

	const [submittingLike, setSubmittingLike] = React.useState(false)
	const likeDocument = () => {
		setSubmittingLike(true)
		handleLike(documentTender.id, documentType, isLiked)
			.then(() => {
				setSubmittingLike(false)
				setLikeNumber(likeNumber + (isLiked ? -1 : 1))
			})
			.catch(() => {
				setSubmittingLike(false)
			})
	}

	const handleFavorite = () => {
		const idContent = documentTender.id
		if (isFavorite) {
			return removeFromFavorite(idContent)
		}
		return addToFavorite(idContent)
	}

	const handleExportContent = () => {
		return exportContent(documentTender.id, selectedLanguageField.input.value || DEFAULT_LANGUAGE_ID)
			.then(() => {
				setAnchorEl(null)
				snackSuccess(<FormattedMessage id="document.search.export.sent" />)
			})
	}

	const renderLang = () => (<Field
		component={CustomSelect}
		name="selectedLanguage"
		disabled={isDirty && !consultation}
	>
		{langues && langues.map(lang => <MenuItem
			key={`lang-${lang.id}`}
			value={lang.id}
			disabled={isDirty && !consultation}
		>
			<FormattedMessage id={`enums.langue.${lang.codeLang}`} />
		</MenuItem>)}
	</Field>)

	const renderTranslateButton = () => <IconButton disabled={isDirty && !consultation} onClick={openTranslatePopin}>
		<TranslateIcon />
	</IconButton>

	const contributor = documentTender && documentTender.attributeData && documentTender.attributeData.find(att => att.label === ATTRIBUTE_DATA_CONTENT.CONTRIBUTOR)

	const exportSuivi = () => {
		setAnchorEl(null)
		snackSuccess(<FormattedMessage id="document.suivi.success" />)
		exportFollow(documentTender.id)
			.catch(() => {
				snackError()
			})
	}

	return (<Grid item container xs={12} alignItems="center">
			<Grid item xs={2} container alignItems="center">
				{consultation &&
				<>
					<Grid item xs={2}>
						<SvgIcon
							path={SVG_ICONS[documentType]}
							className={documentTender && documentTender.workflow && isDocumentPublicOrToHideOrToDelete(documentTender, false) ? classes.publishedIcon : classes.draftIcon}
						/>
					</Grid>
					<Grid item xs={10}>
						<Typography className={classes.docType}><FormattedMessage id={`enums.documentType.${documentType}`} /></Typography>
					</Grid>
				</>
				}

			</Grid>
			{consultation ?
				<Grid item container xs={10} direction="row-reverse" alignItems="center">
					<IconButton onClick={event => setAnchorEl(event.currentTarget)}>
						<MoreVertIcon fontSize="large" />
					</IconButton>
					<AccessChecker access={[PROFILS.MEMBRE, PROFILS.CORRESPONDANT, PROFILS.ADMIN]}>
						<IconButton onClick={handleFavorite}>
							{isFavorite ?
								<StarIcon fontSize="inherit" /> :
								<StarBorderIcon fontSize="inherit" />
							}
						</IconButton>
						{(documentTender && documentTender.workflow && isDocumentPublicOrToHideOrToDelete(documentTender, false)) &&
						<IconButton onClick={likeDocument} className={classes.likeButton} disabled={submittingLike}>
							{isLiked ? <ThumbUpIcon fontSize="inherit" /> : <ThumbUpOutlinedIcon fontSize="inherit" />}
							<p>{likeNumber}</p>
						</IconButton>
						}
					</AccessChecker>
					{renderLang()}
					<Menu
						id="menuDoc"
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={() => setAnchorEl(null)}
					>
						<MenuItem onClick={() => {
							setOpenShare({ open: true, consultation: true })
							setAnchorEl(null)
						}}>
							<ShareIcon className={classes.menuIcon} />
							<FormattedMessage id="document.menuConsultation.share" />
						</MenuItem>
						<AccessChecker access={[PROFILS.MEMBRE, PROFILS.CORRESPONDANT, PROFILS.ADMIN]}>
							<MenuItem onClick={handleExportContent}>
								<SvgIcon path={SVG_ICONS.DRIVE} className={classes.menuIcon} color="inherit" viewBox="0 0 32 32" />
								<FormattedMessage id="document.menuConsultation.save" />
							</MenuItem>
						</AccessChecker>
						{isAdmin && documentTender.workflow && (isDocumentPublicOrToHideOrToDelete(documentTender, false) || documentTender.workflow.status === DOCUMENT_STATUS.MASQUE) &&
						<MenuItem onClick={() => exportSuivi()}><TimelineIcon className={classes.menuIcon} /><FormattedMessage
							id="document.menuConsultation.exportFollow" /></MenuItem>
						}
						{(isAdmin || isCorrespondantValid || isMemberValid || (contributor && contributor.value === userEmail)) &&
						<MenuItem onClick={() => goToContribution(documentTender.id)}><EditIcon className={classes.menuIcon} /><FormattedMessage
							id="document.menuConsultation.contribute" /></MenuItem>
						}
						{handleSubmitWorkflowMenu()}
						{(isAdmin || isCorrespondantValid || (contributor && contributor.value === userEmail)) &&
						(documentTender.workflow &&
							(isDocumentBrouillonOuAValider(documentTender.workflow.status) || isDocumentValideOuPublie(documentTender.workflow.status))) &&
						<MenuItem onClick={() => handleOpenPopinSuppression()}>
							<CancelIcon className={classes.menuIcon} />
							<FormattedMessage id="document.button.retirer" />
						</MenuItem>
						}
					</Menu>
				</Grid>
				:
				<Grid item container xs={10} direction="row-reverse" alignItems="center">
					{(isDirty && !consultation) ?
						<Tooltip title={<FormattedMessage id="document.saveRequiredPublication" />} placement="right"><span>{handleSubmitWorkflowButton()}</span></Tooltip> : handleSubmitWorkflowButton()}
					<IconButton onClick={() => {
						openGlobalReportPopin()
						setAnchorEl(null)
					}}>
						<SettingsBackupRestoreIcon />
					</IconButton>
					{(isAdmin || isCorrespondantValid) && <IconButton onClick={() => {
						setOpenShare({ open: true })
						setAnchorEl(null)
					}}>
						<ShareIcon />
					</IconButton>}
					{(isDirty && !consultation) ? <Tooltip title={<FormattedMessage id="document.saveRequired" />} placement="right"><span>{renderTranslateButton()}</span></Tooltip> : renderTranslateButton()}
					{(isDirty && !consultation) ? <Tooltip title={<FormattedMessage id="document.saveRequired" />} placement="right"><span>{renderLang()}</span></Tooltip> : renderLang()}
				</Grid>
			}
			{openShare && <SharePopin
				open={openShare}
				displayRadio={isAdmin || isCorrespondantValid}
				onClose={() => setOpenShare(false)}
				onSubmit={share}
			/>}
			{suppressionPopinOpen && <SuppressionDocumentPopin
				onSubmit={(values) => changeDocumentStatus(values.choixStatus, values.message, isDocumentAMasquerOuASupprimer(documentTender))}
				open={suppressionPopinOpen}
				onClose={handleClosePopinSuppression}
				isAdmin={isAdmin}
				documentTender={documentTender}
			/>}
			<DriveFolderPopin />
		</Grid>
	)
}

const mapStateToProps = (state, { documentTender }) => ({
	isLiked: likeSelectors.isLiked(state, documentTender.id),
	isFavorite: favorisSelectors.isDocumentFavorited(state, documentTender.id)
})

const actions = {
	updateDocumentStatus,
	goToContribution: (id) => dispatch => dispatch(push(`/edition-document/${id}`)),
	goToConsultation: (id) => dispatch => dispatch(push(`/consultation-document/${id}`)),
	goToDocuments: () => dispatch => dispatch(push(`/documents`,{ initialId: undefined, initialQuery: undefined, initialTypeContenu: undefined, initialFieldsAdmin: undefined, initialFieldsAdvance: undefined })),
	handleLike: likeActions.handleLike,
	addToFavorite: favorisActions.addDocumentToFavorite,
	removeFromFavorite: favorisActions.removeDocumentFromFavorite,
	exportContent
}

DocumentActionsBar.propTypes = {
	documentTender: PropTypes.object.isRequired,
	documentType: PropTypes.string,
	isCorrespondantValid: PropTypes.bool,
	isMemberValid: PropTypes.bool,
	isAdmin: PropTypes.bool,
	langues: PropTypes.array,
	userEmail: PropTypes.string,
	classes: PropTypes.object,
	consultation: PropTypes.bool,
	isDirty: PropTypes.bool,
	updateDocumentStatus: PropTypes.func,
	snackSuccess: PropTypes.func,
	snackError: PropTypes.func,
	goToContribution: PropTypes.func,
	setWorkflowLoader: PropTypes.func,
	goToConsultation: PropTypes.func,
	goToDocuments: PropTypes.func,
	handleLike: PropTypes.func,
	workflowLoader: PropTypes.bool,
	isLiked: PropTypes.bool,
	isFavorite: PropTypes.bool,
	addToFavorite: PropTypes.func,
	removeFromFavorite: PropTypes.func,
	setOpenSections: PropTypes.func
}

export default compose(
	connect(mapStateToProps, actions),
	injectSnackActions,
	withStyles(styles)
)(DocumentActionsBar)
