import { createBrowserHistory } from 'history'
import { configureFetch, createIsotopeTheme, fetchUser as fetchUserApi, saveStore as saveStoreIso } from 'isotope-client'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import ReactDOM from 'react-dom'
import { addLocaleData } from 'react-intl'
import { Provider } from 'react-redux'
import { IntlProviderWrapper } from './config/IntlContext'
import fr from 'react-intl/locale-data/fr'
import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'
import pl from 'react-intl/locale-data/pl'
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk'
import reducers from './config/reducers'
import Root from './containers/Root'
import './index.css'
import { colors, LOCAL_STORAGE_ITEM } from './utils/constants'
import { customFetchFactory, saveStore } from './utils/fetchFactory'
import * as api from './modules/fo/configuration/configurationApi'
import * as ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'

const theme = createIsotopeTheme({
	overrides: {
		MuiMenuItem: {
			root: {
				whiteSpace: 'inherit',
				'&$selected': {
					color: colors.primary
				}
			}
		},
		MuiTextField: {
			root: {
				'& .MuiInput-root': {
					backgroundColor: colors.input
				}
			}
		},
		MuiCheckbox: {
			root: {
				color: colors.primary
			},
			colorSecondary: {
				'&$checked': {
					color: colors.primary
				}
			}
		},
		MuiButton: {
			root: {
				borderRadius: 4,
				padding: '5px 40px'
			},
			outlined: {
				padding: '4px 40px',
				borderWidth: 2
			}
		},
		MuiRadio: {
			colorSecondary: {
				'&$checked': {
					color: colors.primary
				}
			}
		},
		MuiSwitch: {
			root: {
				width: 'auto',
				alignItems: 'center',
				justifyContent: 'center'
			},
			colorSecondary: {
				'&$checked': {
					color: colors.primary
				},
				'&$checked + .MuiSwitch-track': {
					backgroundColor: colors.primary
				},
			},
			track: {
				width: 38,
				marginLeft: -32,
				opacity: 0.1
			}
		},
		MuiSelect: {
			root: {
				backgroundColor: colors.input
			}
		},
		MuiFilledInput: {
			root: {
				backgroundColor: colors.input
			},
			underline: {
				borderBottomWidth: '0px !important'
			}
		},
		MuiTooltip: {
			tooltip: {
				fontSize: 12
			}
		},
		page: {
			contentFrame: {
				backgroundColor: colors.background
			}
		},
		formrichtext: {
			editor: {
				paddingLeft: 10,
				width: '100%'
			},
			bootstrapFormLabel: {
				color: 'rgba(0, 0, 0, 0.54)'
			}
		},
		'cms-richText': {
			editor: {
				paddingLeft: 0,
				width: '100%'
			},
			bootstrapFormLabel: {
				color: colors.primary
			}
		},
		forminput: {
			bootstrapFormLabel: {
				top: '-2px'
			}
		},
		snackbar: {
			success: {
				backgroundColor: `${colors.primary} !important`,
				color: 'white !important'
			},
			error: {
				backgroundColor: `${colors.error} !important`,
				color: 'white !important'
			}
		}
	},
	login: {
		background: 'url(\'img/connexion.png\')'
	},
	typography: {
		fontFamily: 'Roboto',
		h1: {
			fontSize: 30,
			fontWeight: 300,
			marginBottom: 40,
			color: colors.text
		},
		h2: {
			fontSize: 24,
			fontWeight: 400,
			color: colors.text
		},
		h3: {
			fontSize: 20,
			fontWeight: 'bold',
			color: colors.text
		},
		h4: {
			fontSize: 18,
			fontWeight: 500,
			color: colors.text
		},
		h5: {
			fontSize: 18,
			fontWeight: 500,
			color: colors.text
		},
		h6: {
			fontSize: 14,
			fontWeight: 500,
			color: colors.text
		},
		caption: {
			fontSize: 12,
			fontWeight: 400,
			color: colors.text
		},
		body1: {
			fontSize: 16,
			fontWeight: 400,
			color: colors.text
		},
		body2: {
			fontSize: 14,
			fontWeight: 400,
			color: colors.text
		},
		subtitle1: {
			fontSize: 18,
			fontWeight: 300,
			color: colors.text
		},
		subtitle2: {
			fontSize: 18,
			fontWeight: 200,
			color: colors.text
		}
	},
	palette: {
		primary: {
			main: colors.primary,
			contrastText: '#FFFFFF'
		},
		secondary: {
			main: colors.secondary,
			contrastText: '#FFFFFF'
		},
		text: {
			main: colors.text
		},
		icon: {
			main: '#55555a'
		},
		border: colors.border,
		placeholder: colors.placeholder,
		empty: colors.emptyColor,
		draft: colors.draftColor,
		input: colors.input
	}
})

// Cette méthode permet d'enfermer tout le code à exécuter après les vérifications sur le login
const reactInit = (user) => {
	// Initialisation de la partie i18n (react-intl)
	addLocaleData([...fr, ...en, ...es, ...pl])

	// Create a history of your choosing (we're using a browser history in this case)
	const history = createBrowserHistory()

	// Initialisation de tous les enhancers utilisés avec Redux
	const enhancers = [
		applyMiddleware(
			thunk,
			routerMiddleware(history)
		)
	]

	// Initialisation de google analytics
	api.getConfiguration().then(configuration => {
		((d, s, id, cb) => {
			const fjs = d.getElementsByTagName(s)[0]
			let js = d.createElement(s)
			js.id = id
			js.src = '//apis.google.com/js/client:platform.js'
			fjs.parentNode.insertBefore(js, fjs)
			js.onload = cb
		})(document, 'script', 'google-login', () => {

			// Si on est sur l'url technique, on redirige sur l'url classique
			if (configuration.ENVIRONMENT_DOMAIN_TECHNIQUE && configuration.ENVIRONMENT_URL) {
				const winLocation = window.location
				if (winLocation && winLocation.href && winLocation.href.includes(configuration.ENVIRONMENT_DOMAIN_TECHNIQUE)) {
					const winHref = winLocation.href
					winLocation.replace(`${configuration.ENVIRONMENT_URL}${winHref.substring(winHref.indexOf(configuration.ENVIRONMENT_DOMAIN_TECHNIQUE) + configuration.ENVIRONMENT_DOMAIN_TECHNIQUE.length)}`)
				}
			}

			if (configuration.GOOGLE_ANALYTICS_KEY) {
				ReactGA.initialize(configuration.GOOGLE_ANALYTICS_KEY)
				// Récupération de la page
				history.listen(location => {
					ReactGA.set({ page: location.pathname + location.search })
					ReactGA.pageview(location.pathname + location.search)
				});
			}

			// Google Tag Manager
			if(configuration && configuration.GOOGLE_TAGMANAGER_ID){
				TagManager.initialize({ gtmId: configuration.GOOGLE_TAGMANAGER_ID })
			}
		})
	})

	// En mode dév, on utilise le devtools
	if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
		enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
	}

	// Création du store de redux
	let store = createStore(
		combineReducers({
			...reducers,
			router: connectRouter(history),
			form: formReducer
		}), {
			user
		},
		compose(...enhancers)
	)

	configureFetch({ fetchFactory: customFetchFactory })

	saveStore(store)
	saveStoreIso(store)

	ReactDOM.render(
		<Provider store={store}>
			<IntlProviderWrapper>
				<ConnectedRouter history={history}>
					<MuiThemeProvider theme={theme}>
						<Root />
					</MuiThemeProvider>
				</ConnectedRouter>
			</IntlProviderWrapper>
		</Provider>,
		document.getElementById('root')
	)
}

const token = window.localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN)
if (token) {
	fetchUserApi(token).then(user => {
		if (user) {
			user.token = token
			user.authenticated = true
		}

		reactInit(user)
	}).catch(() => {
		reactInit()
	})
} else {
	reactInit()
}

// Webpack Hot Module Replacement API
if (module.hot) {
	module.hot.accept('./containers/Root', () => {
		reactInit()
	})
}
