import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import { Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { colors } from '../../../../utils/constants'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Input from '../../../../components/form/Input'
import { FIELD_TYPE } from '../../document/utils/documentConstants'
import { Field, Form } from 'react-final-form'
import RichText from '../../../../components/form/RichText'
import * as userSelectors from '../../user/services/userSelectors'
import { updateOneField } from '../services/tabDocumentActions'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { normalizeDate, normalizeNumber, parseNumber } from '../../document/utils/documentUtils'
import DocumentSelectField from '../../document/components/fields/DocumentSelectField'

const styles = () => getStyles({
	popinResize: {
		maxWidth: '50vw'
	},
	dialogTitle: {
		borderBottom: '3px solid',
		paddingBottom: 10
	},
	dialogContent: {
		paddingTop: 10
	},
	actions: {
		display: 'flex',
		justifyContent: 'space-around',
		marginBottom: 20
	},
	closeButton: {
		position: 'absolute',
		right: 8,
		top: 2,
		color: colors.text
	},
	fromSearch: {
		display: 'flex',
		justifyContent: 'space-evenly',
		padding: 12,
		boxShadow: '0px -3px 3px 0px lightgrey'
	},
	field: {
		width: '35vw'
	},
	smallField: {
		width: '28vw'
	},
	gridContainer: {
		display: 'flex',
		alignItems: 'center'
	}

})

const FIELD_VALUE_NAME = 'fieldValue'

/**
 * Composant représentant la popin de modification d'un champ
 * dans le tableau de modification de documents
 */
const UpdateFieldPopin = ({
	open,
	onClose,
	classes,
	initialValues,
	selectedLanguage,
	updateOneField,
	closeUpdateFieldPopin,
	snackSuccess,
	snackError,
	location
}) => {
	const { type, labelField, unite, idField, idRow, codeColumn, valueListShortcut } = initialValues

	const updateField = (values) => {
		updateOneField(idRow, codeColumn, values.fieldValue, idField, selectedLanguage, type, unite, valueListShortcut)
			.then(() => snackSuccess({ id: 'oss.tabDocumentPage.snackBar.editFieldSucces' }))
			.catch(() => snackError({ id: 'oss.tabDocumentPage.snackBar.editFieldError' }))
			.finally(closeUpdateFieldPopin())
	}

	const getActions = (handleSubmit, pristine) => <Grid container justifyContent="space-evenly">
		<Button color="inherit" variant="outlined"
		        key="fermer" onClick={onClose}
		        className={classes.button}>
			<FormattedMessage id="global.buttons.annuler" />
		</Button>
		<Button key="valider" color="primary"
		        variant="contained" type="submit"
		        onClick={handleSubmit}
		        disabled={pristine}
		        className={classes.button}
		>
			<FormattedMessage id="global.buttons.validate" />
		</Button>
	</Grid>

	const renderInputText = () => {
		return <Field
			name={FIELD_VALUE_NAME}
			label={labelField}
			component={Input}
			autoComplete="off"
			inputProps={{
				maxLength: 150
			}}
		/>
	}

	const renderInputDate = () => {
		return <Field
			name={FIELD_VALUE_NAME}
			label={labelField}
			type="date"
			component={Input}
			inputProps={{
				maxLength: 150
			}}
			format={normalizeDate}
		/>
	}

	const renderInputNumber = () => {
		return <Field
			name={FIELD_VALUE_NAME}
			label={labelField}
			component={Input}
			autoComplete="off"
			inputProps={{
				maxLength: 15
			}}
			parse={parseNumber}
			format={normalizeNumber}
		/>
	}

	const renderInputRichText = () => {
		return <Field
			name={FIELD_VALUE_NAME}
			label={labelField}
			component={RichText}
			autoComplete="off"
			inputProps={{
				maxLength: 150
			}}
		/>
	}

	const renderSelectOrMultiSelect = () => {
		return 	<DocumentSelectField
			fromTabDocument
			selectedLanguage={selectedLanguage}
			fieldProps={{ name: FIELD_VALUE_NAME, label: labelField }}
			field={{ id: idField, type: type, valueListShortcut }}
			idContent={idRow}
			location={location}
		/>
	}

	const renderField = () => {
		switch (type) {
			case FIELD_TYPE.DATE :
				return renderInputDate()
			case FIELD_TYPE.NUMBER :
				return renderInputNumber()
			case FIELD_TYPE.TEXT :
				return renderInputText()
			case FIELD_TYPE.AMOUNT :
			case FIELD_TYPE.NUMBER_UNIT :
				return <Grid className={classes.gridContainer}>
					<Grid xs={11}>
						{renderInputNumber()}
					</Grid>
					<Grid xs={1}>
						{type === FIELD_TYPE.AMOUNT ? '€' : unite}
					</Grid>
				</Grid>
			case FIELD_TYPE.TEXT_MULTILINE:
				return renderInputRichText()
			case FIELD_TYPE.SELECT:
			case FIELD_TYPE.MULTI_SELECT:
				return renderSelectOrMultiSelect()
			default:
		}
	}

	const isSmallField = () => type === FIELD_TYPE.DATE || type === FIELD_TYPE.NUMBER || type === FIELD_TYPE.NUMBER_UNIT || type === FIELD_TYPE.AMOUNT

	return (
		<Form onSubmit={updateField} initialValues={initialValues}>
		{({ handleSubmit, pristine }) =>
				<form onSubmit={handleSubmit} className={classes.popin}>
					<Dialog
						open={open}
						onExited={onClose}
						onBackdropClick={onClose}
						classes={{ paper: classes.popinResize }}
						PaperProps={{ style: { backgroundColor: colors.container } }}
					>
						<DialogContent className={classes.dialogContent}>
							{
								<Grid container className={classes.content} alignContent={'flex-start'}>
									<Grid className={isSmallField() ? classes.smallField : classes.field}>
										{renderField()}
									</Grid>
								</Grid>
							}
						</DialogContent>
						<DialogActions className={classes.fromSearch}>
							{getActions(handleSubmit, pristine)}
						</DialogActions>
					</Dialog>
				</form>
			}
		</Form>
	)
}

UpdateFieldPopin.propTypes = {
	open: PropTypes.bool,
	model: PropTypes.object,
	classes: PropTypes.object,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
	snackSuccess: PropTypes.func,
	snackError: PropTypes.func,
	location: PropTypes.object
}

const actions = {
	updateOneField
}

const mapStateToProps = (state) => ({
	userLanguage: userSelectors.getUserLanguage(state)
})

export default compose(
	injectIntl,
	injectSnackActions,
	connect(mapStateToProps, actions),
	withStyles(styles)
)(UpdateFieldPopin)
