import { withStyles } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getStyles } from 'isotope-client'
import { loadListFoByShortcut } from '../modules/common/valueList/valueListActions'
import { getUserLanguage } from '../modules/fo/user/services/userSelectors'
import { GlOBAL_LANGUAGE_ID } from '../utils/constants'
import { getItemsByListCode } from 'isotope-client/modules/valueList/services/valueListSelectors'
import ChipInformation from './ChipInformation'
import OverflowTypography from './OverflowTypography'
import { injectIntl } from 'react-intl'

const styles = (theme) => getStyles({
	chipsFilter: {
		display: 'inline-flex',
		maxWidth: 250
	},
	fieldsAdvance: {
		backgroundColor: theme.palette.border,
		color: 'black'
	},
})

const ChipInformationSelect = ({fieldAdvance, fieldSearch, loadListFoByShortcut, userLanguage, selectItems, isConfidential, classes, intl}) => {

	React.useEffect(() => {
		if (!selectItems || selectItems.length === 0 || selectItems[0].idLanguage !== userLanguage) {
			loadListFoByShortcut(fieldAdvance.source, userLanguage)
		}
	}, [])
		const valueConcat = fieldSearch.map(fieldAdvanceSearch => {
			if(fieldAdvanceSearch.confidential === "false" ||(isConfidential && fieldAdvanceSearch.confidential === "true")) {
				return selectItems.filter(item => fieldAdvanceSearch.valueField[GlOBAL_LANGUAGE_ID].includes(item.code)).map(item => item.label).join(', ')
			}
			return "******"
		}).join(' // ')

		return <ChipInformation className={classes.fieldsAdvance} text={<OverflowTypography variant="body2" className={classes.chipsFilter}>{fieldAdvance.label} : {!!valueConcat ? valueConcat : intl.formatMessage({id: 'global.NA'})}</OverflowTypography>} />
}

const mapStateToProps = (state, {fieldAdvance}) => {
	let selectItems = getItemsByListCode(state)[fieldAdvance.source] || []

	return ({
		userLanguage: getUserLanguage(state),
		selectItems,
	});
}

const actions = {
	loadListFoByShortcut
}

ChipInformationSelect.propTypes = {
	fieldAdvance: PropTypes.object,
	fieldSearch: PropTypes.array,
	selectItems: PropTypes.array,
	isConfidential: PropTypes.bool,
}

export default compose(
	connect(mapStateToProps,actions),
	injectIntl,
	withStyles(styles)
)(ChipInformationSelect)
