import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { getStyles } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ChipInformation from '../../../components/ChipInformation'
import { ETAT_ACCESS_REQUEST, PROFILS, SVG_ICONS, USER_FORM, USER_PREFERENCES } from '../../../utils/constants'
import moment from 'moment'
import AccessChecker from '../../../components/security/AccessChecker'
import SvgIcon from '../../../components/icon/SvgIcon'
import { readPreferenceList } from '../../../utils/utils'
import { injectBusinessSegmentList, injectMarketList, injectPaysList, injectZoneList } from '../../common/valueList/ValueListInjector'
import OverflowTypography from '../../../components/OverflowTypography'

const style = (theme) => getStyles({
	card: {
		margin: '12px 6px 12px 6px'
	},
	icon: {
		display: 'flex',
		margin: '10px auto'
	},
	iconValidation: {
		marginLeft: 32,
		marginTop: 10,
		color: theme.palette.draft
	},
	h4: {
		marginTop: 5,
		marginBottom: 12
	},
	email: {
		color: theme.palette.primary.main
	},
	moreIcon: {
		marginLeft: '30%'
	},
	chipsRefus: {
		maxWidth: 200,
		display: 'inline-flex'
	},
	chipsPerimetre: {
		backgroundColor: theme.palette.border,
		color: 'black'
	},
	typoPerimetre: {
		display: 'inline-flex',
		maxWidth: 250
	}
})

const UserCard = ({
	classes,
	user,
	setAnchorEl,
	paysList,
	zoneList,
	displayMenu,
	setAValider,
	setUserClick,
	businessSegmentList,
	marketList
}) => {
	const pays = paysList ? paysList.find(x => x.code === user.preferences[USER_PREFERENCES.PAYS]) : {}
	const zone = zoneList ? zoneList.find(x => x.code === user.preferences[USER_PREFERENCES.ZONE]) : {}

	const renderIcon = () => {
		// On regarde le rôle et renvoie selon le code
		const userRole = user && user.roles && user.roles.length > 0 && user.roles[0]

		if (!userRole || !userRole.id) {
			return <></>
		}

		const iconProperty = {
			className: classes.icon,
			color: 'secondary',
			fontSize: 'large'
		}

		switch (userRole.id) {
			case `${PROFILS.ADMIN.id}`:
				return <SvgIcon path={SVG_ICONS.ADMIN} {...iconProperty} />
			case `${PROFILS.CORRESPONDANT.id}`:
				return <PersonPinCircleIcon {...iconProperty} />
			case `${PROFILS.MEMBRE.id}`:
				return <SvgIcon path={SVG_ICONS.MEMBRE_VALIDER} {...iconProperty} />
			case `${PROFILS.VISITEUR.id}`: {
				if (user.accessRequest && user.accessRequest.etat && user.accessRequest.etat === ETAT_ACCESS_REQUEST.EN_ATTENTE) {
					return <SvgIcon path={SVG_ICONS.MEMBRE_VALIDER} className={classes.iconValidation} fontSize="large" />
				}
				return <SvgIcon path={SVG_ICONS.VISITEUR} {...iconProperty} />
			}
			default:
				return <></>

		}


	}
	const getLabel = valueList => code => {
		const value = valueList && valueList.find(value => value.code === code)
		return value && value.label
	}

	const getValueOfPreference = (preferenceType, valueList) => {
		const labeledValues = readPreferenceList(user, preferenceType).map(getLabel(valueList))
		return labeledValues && labeledValues.join(', ')
	}

	const makeChip = (code, valueList, name) => {
		const values = getValueOfPreference(code, valueList)
		if (!values || values === '') {
			return
		}
		return (<ChipInformation
			className={classes.chipsPerimetre}
			text={
				<OverflowTypography variant="body2" className={classes.typoPerimetre}>
					<FormattedMessage id={`users.search.${name}Label`} /> : {values}
				</OverflowTypography>
			}
		/>)
	}

	return (
		<Card className={classes.card}>
			<CardContent>
				<Grid container alignItems="flex-start">
					<Grid item sm={1}>{renderIcon()}</Grid>

					<Grid item sm={10} container justify="space-between" alignItems="flex-end">
						<Grid item sm={6}>
							<Typography variant="h4" className={classes.h4}>{user.firstname} {user.lastname}</Typography>
						</Grid>
						<Grid item sm={6}>
							{zone && <Typography variant="h4" className={classes.h4}>{zone.label}</Typography>}
						</Grid>

						<Grid item sm={6}>
							<Typography variant="body2"><a href={`mailto:${user.email}`} className={classes.email} target="_blank"
							                               rel="noopener noreferrer">{user.email}</a></Typography>
						</Grid>
						<Grid item sm={6}>
							{pays && <Typography variant="body2">{pays.label}</Typography>}
						</Grid>
						<Grid item sm={12}>
							{makeChip(USER_PREFERENCES.FI_PAYS, paysList, USER_FORM.PAYS)}
							{makeChip(USER_PREFERENCES.FI_SEGMENT, businessSegmentList, USER_FORM.SEGMENT)}
							{makeChip(USER_PREFERENCES.FI_MARCHES, marketList, USER_FORM.MARKET)}
						</Grid>
						{displayMenu && <Grid item sm={12}>
							{user.preferences[USER_PREFERENCES.DATE_CONNECTION] &&
							<AccessChecker access={PROFILS.ADMIN}>
								<ChipInformation text={<Typography variant="body2"><FormattedMessage
									id="users.accesRequest.derniereCo" /> {moment(user.preferences[USER_PREFERENCES.DATE_CONNECTION]).format('DD/MM/YYYY')}
								</Typography>} />
							</AccessChecker>}
							{user.accessRequest.dateDemande && <ChipInformation text={<Typography variant="body2"><FormattedMessage
								id="users.accesRequest.demande" /> {moment(user.accessRequest.dateDemande).format('DD/MM/YYYY')}
							</Typography>} />}
							{!!user.accessRequest.dateRefus &&
							<ChipInformation
								text={<OverflowTypography variant="body2" className={classes.chipsRefus}><FormattedMessage
									id="users.accesRequest.refuse" /> {user.accessRequest.texteRefus ||
								<FormattedMessage id="users.accesRequest.refusAuto" />}</OverflowTypography>} />}
							{user.accessRequest.etat === ETAT_ACCESS_REQUEST.VALIDE &&
							<ChipInformation text={<Typography variant="body2"><FormattedMessage
								id="users.accesRequest.valide" /> {moment(user.accessRequest.dateValidation).format('DD/MM/YYYY')}
							</Typography>} />}
						</Grid>}
					</Grid>
					<Grid item sm={1}>
						{displayMenu && <IconButton className={classes.moreIcon} onClick={event => {
							setAValider(user.accessRequest.etat === ETAT_ACCESS_REQUEST.EN_ATTENTE)
							setAnchorEl(event.currentTarget)
							const userRole = user && user.roles && user.roles.length > 0 && user.roles[0]
							setUserClick({
								name: `${user.firstname} ${user.lastname}`,
								id: `${user.id}`,
								refuse: `${!!user.accessRequest.dateRefus}`,
								role: userRole && userRole.id
							})
						}}>
							<MoreVertIcon fontSize="large" />
						</IconButton>}

					</Grid>
				</Grid>
			</CardContent>
		</Card>
	)
}

UserCard.propTypes = {
	user: PropTypes.object,
	setAnchorEl: PropTypes.func,
	paysList: PropTypes.array,
	zoneList: PropTypes.array,
	displayMenu: PropTypes.bool,
	setAValider: PropTypes.func,
	setUserClick: PropTypes.func
}

export default compose(
	injectZoneList,
	injectPaysList,
	injectBusinessSegmentList,
	injectMarketList,
	withStyles(style)
)(UserCard)
