import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getConversionList } from './conversionActions'

export const loadConversion = (WrappedComponent) => {
	const ConversionInjector = ({ getConversionList, ...props }) => {
		React.useEffect(() => {
			getConversionList()
		}, [])

		return <WrappedComponent {...props} />
	}

	const actions = {
		getConversionList
	}

	ConversionInjector.propTypes = {
		getConversionList: PropTypes.func
	}

	return connect(undefined, actions)(ConversionInjector)
}
