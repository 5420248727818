import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field, Form } from 'react-final-form'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { FormSwitch, getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '../../../../components/Button'
import Popin from '../../../../components/Popin'
import Input from '../../../../components/form/Input'
import { isRequired } from '../../../../utils/utils'
import { injectIntl, intlShape } from 'react-intl'

const styles = () => getStyles({
	popinResize: {
		maxWidth: 900
	},
	error: {
		paddingLeft: 10,
		marginBottom: 20
	},
	switch: {
		alignItems: 'center',
		justifyContent: 'center'
	},
	rowSwitch: {
		paddingLeft: 10,
		marginTop: 10,
		marginBottom: 50
	},
	rowReference: {
		marginTop: 10
	},
	buttonAction: {
		marginRight: 50
	}
})

const EditionResearchFieldPopin = ({
	open,
	initValues,
	closePopin,
	saveField,
	classes,
	intl
}) => {
	const validate = (values) => {
		const errors = {}
		errors.reference = isRequired(values.reference)
		if(values && !values.reference){
			errors.reference = <FormattedMessage id="global.errors.obligatoire" />
		}
		return errors
	}

	const getActions = (handleSubmit) => <Grid container justifyContent="center">
		<Button
			color="inherit"
			variant="outlined"
			key="fermer"
			className={classes.buttonAction}
			onClick={closePopin}
		>
			<FormattedMessage id="global.buttons.annuler" />
		</Button>
		<Button
			key="valider"
			color="primary"
			variant="contained"
			type="submit"
			onClick={handleSubmit}
		>
			<FormattedMessage id="global.buttons.save" />
		</Button>
	</Grid>

	return <Form
		onSubmit={saveField}
		initialValues={initValues}
		validate={validate}
	>
		{({ handleSubmit, form }) =>
			<form onSubmit={handleSubmit}>
				<Popin
					dialogClasses={{ paper: classes.popinResize }}
					onClose={() => {
						closePopin()
						form.reset()
					}}
					open={open}
					actions={getActions(handleSubmit)}
					title={initValues && initValues.idResearchField ? intl.formatMessage({ id: 'additionalFilters.updateField' }) : intl.formatMessage({ id: 'additionalFilters.newField' })}
					content={<>
						<Grid container>
							<Grid container item alignItems={'center'} className={classes.rowReference}>
								<Grid item xs={6}>
									<Field
										name="reference"
										label={<FormattedMessage id="additionalFilters.form.reference" />}
										component={Input}
									/>
								</Grid>
							</Grid>
							<Grid container item alignItems={'center'} className={classes.rowSwitch}>
								<Grid item>
									<Typography>
										<FormattedMessage id="additionalFilters.form.publishedField" />
									</Typography>
								</Grid>
								<Grid item>
									<Field
										name="published"
										classes={{ root: classes.switch }}
										component={FormSwitch}
									/>
								</Grid>
							</Grid>
						</Grid>
					</>
					}
				/>
			</form>}
	</Form>
}


EditionResearchFieldPopin.propTypes = {
	open: PropTypes.bool,
	initValues: PropTypes.object,
	closePopin: PropTypes.func,
	saveField: PropTypes.func,
	classes: PropTypes.object,
	errorSubmission: PropTypes.object,
	intl: intlShape
}

export default compose(
	withStyles(styles),
	injectIntl
)(EditionResearchFieldPopin)
