import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { getStyles } from 'isotope-client'
import { withStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'

const styles = getStyles({
	paper: {
		height: 'auto',
		width: 'auto',
		padding: 20
	},
	divContent: {
		height: '100%'
	},
})

const PaperBloc = ({ classes, children, className, onClick }) => (
	<Paper className={classnames(classes.paper, className)} onClick={onClick}>
		<div className={classes.divContent}>
			{children}
		</div>
	</Paper>
)

PaperBloc.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func
}

PaperBloc.defaultProps = {
	className: '',
	onClick:() => {}
}

export default withStyles(styles)(PaperBloc)
