import React from 'react'
import { GlOBAL_LANGUAGE_ID } from '../../../../utils/constants'
import { ATTRIBUTE_DATA_MO_FIELD } from '../utils/documentConstants'
import { FormSpy, useField } from 'react-final-form'
import { getDuplicateSuffix } from '../utils/documentUtils'
import { OnChange } from 'react-final-form-listeners'

export const getParentValue = (WrappedComponent) => {
	const ParentValueInjector = (props) => {
		const { field, duplicatedNumber } = props

		const data = field && field.attributeData && field.attributeData[GlOBAL_LANGUAGE_ID]
		let parentValue = undefined
		let parentName = undefined
		// Récupération de la référence parent
		if (data && data[ATTRIBUTE_DATA_MO_FIELD.PARENT_REFERENCE]) {
			// Gestion du cas particulier où l'on veut récupérer une valeur de l'en-tête, la ref est préfixée d'un #
			if (data[ATTRIBUTE_DATA_MO_FIELD.PARENT_REFERENCE].value.startsWith('#')) {
				parentName = data[ATTRIBUTE_DATA_MO_FIELD.PARENT_REFERENCE].value.substring(1).toLowerCase()
				const parentField = useField(parentName)
				parentValue = parentField.input.value
			} else if (data[ATTRIBUTE_DATA_MO_FIELD.PARENT_REFERENCE].value.startsWith('FI_')) {
				parentName = `fields.${data[ATTRIBUTE_DATA_MO_FIELD.PARENT_REFERENCE].value}.value`
				const parentField = useField(parentName)
				parentValue = parentField.input.value
			} else {
				parentName = `fields.${data[ATTRIBUTE_DATA_MO_FIELD.PARENT_REFERENCE].value}${getDuplicateSuffix(duplicatedNumber)}.value`
				const parentField = useField(`fields.${data[ATTRIBUTE_DATA_MO_FIELD.PARENT_REFERENCE].value}${getDuplicateSuffix(duplicatedNumber)}.value`)
				parentValue = parentField.input.value
			}
		}

		// Ajout d'un form spy pour clear la donnée de l'enfant si la valeur du parent est modifiée
		return <>
			{parentName && <FormSpy subscription={{}}>
				{({ form }) =>
					<OnChange name={parentName}>
						{(val, prev) => {
							if (!val.toString().includes(prev.toString()) && props.fieldProps && props.fieldProps.name) {
								form.change(props.fieldProps.name, '')
							}
						}}
					</OnChange>
				}
			</FormSpy>}
			<WrappedComponent parentValue={parentValue} {...props} />
		</>
	}

	return ParentValueInjector
}
