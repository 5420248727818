import { createSelector } from 'reselect'

const getLocalState = state => state.document

export const getDocument = createSelector(
	getLocalState,
	document => document.data
)

export const getDuplicatedSectionList = createSelector(
	getLocalState,
	document => document.duplicatedSections
)

export const isLoading = createSelector(
	getLocalState,
	document => document.loading
)

export const getFolderPopin = createSelector(
	getLocalState,
	document => document.folderPopin
)

export const isCollapsedRecherche = (state, id) => createSelector(
	getLocalState,
	document => {
		const collapsedSelectColumns = document.rechercheDocumentCollapsed.collapsedSelectColumns
		return collapsedSelectColumns[id]
	}
)(state)
