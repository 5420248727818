import React from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import FolderIcon from '../../../../../../components/icon/FolderIcon'
import { DRIVE_UPLOAD_TYPE } from '../../../utils/documentConstants'
import OverflowTypography from '../../../../../../components/OverflowTypography'
import classnames from 'classnames'

const styles = theme => getStyles({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
		padding: 0
	},
	item: {
		display: 'flex',
		alignItems: 'center'
	},
	nested: {
		paddingLeft: theme.spacing(4)
	},
	expandIcon: {
		cursor: 'pointer',
		marginRight: 10
	},
	iconFolder: {
		minWidth: 40
	},
	listItem: {
		padding: 0
	},
	itemSelected: {
		backgroundColor: 'rgba(0, 174, 199, 0.1) !important',
		borderRadius: '0px 25px 25px 0px'
	},
	marginFolder: {
		paddingLeft: 35
	},
	selectedText: {
		color: theme.palette.primary.main
	}
})

/**
 * Représente un dossier de l'arborescence et ses enfants
 * (appel récursif)
 */
const DriveFolder = ({ folder, level, selectedFolder, deletedContentFolder, setSelectedFolder, classes }) => {
	const [open, setOpen] = React.useState(folder.defaultOpen || selectedFolder === folder.id)

	React.useEffect(() => {
		if (selectedFolder === folder.id) {
			setOpen(true)
		}
	}, [selectedFolder])

	const handleBrowser = (e) => {
		setOpen(!open)
		e.stopPropagation()
	}

	const handleFolder = () => {
		setOpen(true)
		setSelectedFolder(folder.id)
	}

	// Filtrage sur les dossiers
	const filteredContent = (folder.content || []).filter(item => item.type === DRIVE_UPLOAD_TYPE.FOLDER && deletedContentFolder.findIndex(content => content.id === item.id) === -1)

	return (<List
			component="nav"
			className={classes.root}
			dense
		>
			<div className={classes.item}>
				<ListItem
					button
					selected={selectedFolder === folder.id}
					classes={{ selected : classes.itemSelected }}
					className={classnames(classes.listItem, { [classes.marginFolder]: filteredContent.length === 0 })}
					onClick={handleFolder}
				>
					{filteredContent.length > 0 && (open ? <ExpandLess className={classes.expandIcon} onClick={handleBrowser} /> : <ExpandMore className={classes.expandIcon} onClick={handleBrowser} />)}
					<ListItemIcon className={classes.iconFolder}>
						<FolderIcon height="1.5rem" width="1.5rem" />
					</ListItemIcon>
					<ListItemText primary={<OverflowTypography variant="body1" className={classnames({[classes.selectedText]: selectedFolder === folder.id})}>{folder.name}</OverflowTypography>} />
				</ListItem>
			</div>
			{filteredContent.length > 0 &&
				<Collapse in={open} timeout="auto" unmountOnExit className={classes.nested}>
					{open && filteredContent.map(folderItem =>
						<DriveBrowseFolder
							key={`browse-folder-${level + 1}-${folderItem.id}`}
							folder={folderItem}
							level={level + 1}
							deletedContentFolder={deletedContentFolder}
							selectedFolder={selectedFolder}
							setSelectedFolder={setSelectedFolder}
						/>
					)}
				</Collapse>
			}
		</List>
	)
}

DriveFolder.propTypes = {
	folder: PropTypes.object,
	level: PropTypes.number,
	selectedFolder: PropTypes.string,
	deletedContentFolder: PropTypes.array,
	setSelectedFolder: PropTypes.func,
	classes: PropTypes.object
}

const DriveBrowseFolder = withStyles(styles)(DriveFolder)

export default DriveBrowseFolder
