import { Grid } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'
import { getItemsByListCode } from 'isotope-client/modules/valueList/services/valueListSelectors'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Select from '../../../../../components/form/Select'
import OverflowTypography from '../../../../../components/OverflowTypography'
import { hasAccess } from '../../../../../components/security/AccessChecker'
import { DEFAULT_LANGUAGE_ID, GlOBAL_LANGUAGE_ID, PROFILS } from '../../../../../utils/constants'
import { loadListFoByShortcut } from '../../../../common/valueList/valueListActions'
import * as userSelectors from '../../../user/services/userSelectors'
import { ATTRIBUTE_DATA_MO_FIELD, DOCUMENT_STATUS, FI_TRIPLET, FIELD_TYPE } from '../../utils/documentConstants'
import { getParentValue } from '../ParentValueInjector'
import { getFilteredListsForDocumentForm } from '../../../../bo/listes/listesSelectors'
import { getFieldOptions } from '../../../tabDocument/services/tabDocumentApi'

const styles = () => getStyles({
	tooltip: {
		marginTop: '-12px'
	},
	selectedItem: {
		backgroundColor: 'rgba(0, 0, 0, 0.08) !important'
	},
	consultationContainer: {
		paddingBottom: 24
	},
	consultationBloc: {
		padding: '10px 10px 10px 10px'
	},
	emptyOption: {
		height: '36px !important'
	}
})

const isFiTriplet = (field) => FI_TRIPLET.some(({fieldName}) => fieldName === field.reference)

const pathnameEditionTab = '/edition-table'

/**
 * Composant select dans un document
 * Gestion du chargement de la liste (filtrée si ref parent) + gestion du multiple
 */
const DocumentSelectField = ({
	location,
	field,
	selectItems,
	selectedLanguage,
	fieldProps,
	classes,
	loadListFoByShortcut,
	consultation,
	tableAffichage,
	userAuthorities,
	workflow,
	fromTabDocument,
	idContent,
	isMemberValid
}) => {
	if (!field) {
		return <></>
	}

	const isFiTripletDisable = field => !fromTabDocument
		&& isFiTriplet(field)
		&& workflow && workflow.status !== DOCUMENT_STATUS.BROUILLON
		&& !(hasAccess(PROFILS.ADMIN, userAuthorities) || (isMemberValid && field.reference === "FI_COUNTRY"))
	const [fieldOptions, setFieldOptions] = React.useState([])

	const getOptions = React.useCallback(() => new Promise((resolve) => resolve(
		getFieldOptions(field.id, idContent, field.valueListShortcut, selectedLanguage)
	)), [field.id])

	React.useEffect(() => {
		if(location && location.pathname === pathnameEditionTab){
			getOptions().then(setFieldOptions)
		}
	}, [getOptions])

	React.useEffect(() => {
		if (fromTabDocument) {
			loadListFoByShortcut(field.valueListShortcut, selectedLanguage)
		} else {
			if (selectedLanguage) {
				if ((!selectItems || selectItems.length === 0 || selectItems[0].idLanguage !== `${selectedLanguage}` || selectedLanguage === DEFAULT_LANGUAGE_ID) &&
					field.attributeData[GlOBAL_LANGUAGE_ID] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.SOURCE]
				) {
					loadListFoByShortcut(field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.SOURCE].value, selectedLanguage)
				}
			}
		}
	}, [selectedLanguage])

	const fieldLabel = fromTabDocument
		? ""
		: (field.attributeData[selectedLanguage] && field.attributeData[selectedLanguage][ATTRIBUTE_DATA_MO_FIELD.NAME] && field.attributeData[selectedLanguage][ATTRIBUTE_DATA_MO_FIELD.NAME].value) ||
		(field.attributeData[DEFAULT_LANGUAGE_ID] && field.attributeData[DEFAULT_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.NAME] && field.attributeData[DEFAULT_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.NAME].value)

	const renderSelectField = () => {
		const optionList = fromTabDocument ? fieldOptions : selectItems
		return <Field {...fieldProps}>
			{props => <Select {...props}>
				<MenuItem key={`select-${field.id}-default`} value=" " className={classes.emptyOption} />
				{optionList.map(item => <MenuItem
					key={`select-${field.id}-${item.code}`}
					value={item.code}
				>{item.label}</MenuItem>)}
			</Select>}
		</Field>
	}

	const renderMultiSelectField = () => {
		const optionList = fromTabDocument ? fieldOptions : selectItems
		return <Field {...fieldProps} >
			{props => <Select {...props} disabled={isFiTripletDisable(field)} multiple>
				{optionList.map(item => <MenuItem
						classes={{ selected: classes.selectedItem }}
						key={`select-${field.id}-${item.code}`}
						value={item.code}
					>
						{item.label}
					</MenuItem>
				)}
			</Select>}
		</Field>
	}

	switch (field.type) {
		case FIELD_TYPE.SELECT: {
			if (consultation) {
				// Dans le cas du champ default 0, on n'affiche pas la valeur
				if (!fieldProps || !fieldProps.value || fieldProps.value === '0') {
					return <></>
				}
				if (tableAffichage) {
					return <OverflowTypography
						className={classes.consultationBloc}
						variant="body2"
					>
						{selectItems.filter(item => item.code === fieldProps.value).map(item => item.label)}
					</OverflowTypography>
				} else {
					return <Grid item container xs={12} className={classes.consultationContainer}>
						<Grid item xs={4}>
							<Typography variant="caption">{fieldLabel}</Typography>
						</Grid>
						<Grid item container xs={8}>
							<Typography
								variant="body1"> {selectItems.filter(item => item.code === fieldProps.value).map(item => item.label)}</Typography>
						</Grid>
					</Grid>
				}
			} else {
				return renderSelectField()
			}
		}
		case FIELD_TYPE.MULTI_SELECT: {
			if (consultation) {
				if (!fieldProps || !fieldProps.value || fieldProps.value.length === 0) {
					return <></>
				}
				if (tableAffichage) {
					return <Typography
						className={classes.consultationBloc}
						variant="body2"
					>
						{selectItems.filter(item => fieldProps.value.includes(item.code)).map(item => item.label).join(', ')}
					</Typography>
				} else {
					return <Grid item container xs={12} className={classes.consultationContainer}>
						<Grid item xs={4}>
							<Typography variant="caption">{fieldLabel}</Typography>
						</Grid>
						<Grid item container xs={8}>
							<Typography variant="body1">
								{selectItems.filter(item => fieldProps.value.includes(item.code)).map(item => item.label).join(', ')}
							</Typography>
						</Grid>
					</Grid>
				}
			} else {
				return renderMultiSelectField()
			}
		}
		default:
			return <></>
	}

}


const mapStateToProps = (state, { field, parentValue, fromTabDocument }) => {
	let selectItems = []

	if (fromTabDocument) {
		selectItems = parentValue && parentValue.length > 0 && parentValue !== '[]' ?
			getFilteredListsForDocumentForm(state, parentValue, field.valueListShortcut, field.reference) || []
			: getItemsByListCode(state)[field.valueListShortcut] || []
	} else {
		const data = field && field.attributeData && field.attributeData[GlOBAL_LANGUAGE_ID]

		// Dans le cas d'un select, on charge les items via la source
		if (data && data[ATTRIBUTE_DATA_MO_FIELD.SOURCE]) {
			// On filtre la liste que dans le cas où une valeur est sélectionnée
			selectItems = parentValue && parentValue.length > 0 && parentValue !== '[]' ?
				getFilteredListsForDocumentForm(state, parentValue, data[ATTRIBUTE_DATA_MO_FIELD.SOURCE].value, field.reference) || []
				: getItemsByListCode(state)[data[ATTRIBUTE_DATA_MO_FIELD.SOURCE].value] || []
		}
	}

	return ({
		userAuthorities: userSelectors.getAuthorities(state),
		selectItems
	})
}

const actions = {
	loadListFoByShortcut
}

DocumentSelectField.propTypes = {
	field: PropTypes.object.isRequired,
	fieldProps: PropTypes.object.isRequired, // Nom du field dans le cas d'un tableau
	selectedLanguage: PropTypes.string,
	selectItems: PropTypes.array,
	duplicatedNumber: PropTypes.number,
	classes: PropTypes.object,
	loadListByShortcut: PropTypes.func,
	consultation: PropTypes.bool,
	tableAffichage: PropTypes.bool,
	fromTabDocument: PropTypes.bool,
	idContent: PropTypes.string,
	location: PropTypes.object,
	isMemberValid: PropTypes.bool,
}

export default compose(
	getParentValue,
	connect(mapStateToProps, actions),
	withStyles(styles)
)(DocumentSelectField)
