import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import ClearIcon from '@material-ui/icons/Clear'
import EditIcon from '@material-ui/icons/Edit'
import HelpIcon from '@material-ui/icons/HelpOutline'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { Field, useField, useForm } from 'react-final-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { DEFAULT_LANGUAGE_ID, LOCAL_STORAGE_ITEM, TYPE_CONTENU } from '../../../../../utils/constants'
import { bytesToSize } from '../../../../../utils/utils'
import { loadConfiguration } from '../../../configuration/configurationInjector'
import { ATTRIBUTE_DATA_MO_FIELD, DRIVE_UPLOAD_TYPE } from '../../utils/documentConstants'
import { getFieldName } from '../../utils/documentUtils'
import classNames from 'classnames'
import { refreshToken } from '../../../../../utils/fetchFactory'
import { storeLocalUser } from '../../../login/services/loginApi'
import { openFolderPopin } from '../../services/documentActions'
import FolderCard from '../card/FolderCard'
import FileCard from '../card/FileCard'
import CircularProgress from '@material-ui/core/CircularProgress'
import { getFileUploaded } from '../../services/documentApi'
import Checkbox from '../../../../../components/form/Checkbox'

const NB_MAX_RETRY = 3

const styles = theme => getStyles({
	padding: {
		paddingLeft: 10,
		paddingRight: 10
	},
	titleFile: {
		paddingLeft: 10
	},
	driveContainer: {
		border: `${theme.palette.placeholder} 1px dashed`,
		height: 60,
		marginBottom: 10,
		backgroundColor: theme.palette.input
	},
	info: {
		textAlign: 'center',
		color: theme.palette.placeholder,
		fontSize: 16,
		fontWeight: 'bold'
	},
	infoWarn: {
		textAlign: 'center',
		color: theme.palette.placeholder,
		fontSize: 14
	},
	file: {
		backgroundColor: theme.palette.input,
		marginBottom: 10
	},
	fileError: {
		height: 40
	},
	fileName: {
		fontWeight: 'bold',
		paddingLeft: 20
	},
	error: {
		color: '#FF0000'
	},
	fileSize: {
		paddingLeft: 20
	},
	consultationContainer: {
		paddingBottom: 24
	},
	containerFile: {
		paddingRight: 24,
		cursor: 'pointer',
		marginBottom: 10
	},
	editFolder: {
		padding: 8
	},
	delete: {
		padding: 8,
		marginRight: 20
	},
	openBtn: {
		backgroundColor: 'white',
		border: '2px solid #00aec7',
		fontWeight: 500,
		marginRight: '-16px'
	},
	inputMargin: {
		marginBottom: 10
	},
	marginHelp: {
		marginRight: '-20px'
	},
	helpIcon: {
		color: theme.palette.text.main,
		fontSize: '1.9rem'
	},
	helpText: {
		lineHeight: 1.3
	},
	circularProgress: {
		padding: 8,
		marginRight: 20,
		color: theme.palette.text.main
	}
})

let pickerApiLoaded = false
const MIME_TYPES = 'application/pdf,' +
	'image/png,' +
	'image/jpeg,image/jpg,' +
	'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,' +
	'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
	'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
	'application/vnd.google-apps.spreadsheet,' +
	'application/vnd.google-apps.document,' +
	'application/vnd.google-apps.presentation,' +
	'video/mp4'

const DocumentDriveField = ({
	field,
	selectedLanguage,
	configuration,
	duplicatedNumber,
	documentType,
	intl,
	classes,
	consultation,
	initialValues,
	openFolderPopin
}) => {
	const data = field.attributeData[selectedLanguage] || field.attributeData[DEFAULT_LANGUAGE_ID]
	const form = useForm()
	const formField = useField(`${getFieldName(field, duplicatedNumber)}`)
	const filesField = formField.input.value
	const fieldId = filesField.id

	const files = filesField.value || []

	const [errorFiles, setErrorFiles] = React.useState([])

	const [initialFiles, setInitialFiles] = React.useState(null)
	const [uploaded, setUploaded] = React.useState(true)

	React.useEffect(() => {
		if (!initialFiles) {
			setInitialFiles(files)
		}
	}, [files])

	let interval = null
	React.useEffect(() => {
		const getUploaded = () => getFileUploaded(filesField.id)
			.then(setUploaded)

		const deleteInterval = () => {
			if (!!interval) {
				clearInterval(interval)
				interval = null
			}
		}

		if (!uploaded && (consultation || (initialFiles && initialFiles.length !== 0))) {
			if(filesField.id !== undefined){
				getUploaded()
			}

			if (!interval)
			interval = setInterval(getUploaded, 10000)
			return () => {
				deleteInterval()
			}
		} else if (uploaded){
			deleteInterval()
		}
	}, [uploaded, initialFiles, filesField.id])

	const getDocView = () => {
		const google = window.google
		// Vue documents
		const docsView = new google.picker.DocsView()
		docsView.setIncludeFolders(true)
		docsView.setMimeTypes(MIME_TYPES)
		docsView.setSelectFolderEnabled(true)
		return docsView
	}

	const getUploadView = () => {
		const google = window.google

		const uploadView = new google.picker.DocsUploadView()
		uploadView.setMimeTypes(MIME_TYPES)
		return uploadView
	}

	const createPicker = (token) => {
		if (pickerApiLoaded && token) {
			const google = window.google
			const picker = new google.picker.PickerBuilder()
				.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
				.enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES)
				.setLocale(intl.locale)
				.setAppId(configuration.GOOGLE_APP_ID)
				.setOAuthToken(token)
				.addView(getDocView().setOwnedByMe(true))
				.addView(getDocView().setOwnedByMe(false))
				.addView(getDocView().setEnableDrives(true))
				.addView(getUploadView())
				.setDeveloperKey(configuration.GOOGLE_DRIVE_DEV_KEY)
				.setCallback(data => handleResultPicker(data, token))
				.build()
			picker.setVisible(true)
			pickerApiLoaded = false
		}

	}

	const openPicker = () => {
		// Refresh du token en cas de longue inactivité
		refreshToken(localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN), { code : localStorage.getItem(LOCAL_STORAGE_ITEM.REFRESH_TOKEN)}).then((json) => {
			if (json) {
				storeLocalUser(json)
				window.gapi.load('picker', {
					'callback': () => {
						pickerApiLoaded = true
						createPicker(json.accessToken)
					}
				})
			}
		})
	}

	const handleResultPicker = (data, token) => {
		if (data.action === window.google.picker.Action.PICKED && data.docs && data.docs.length > 0) {
			setErrorFiles([])
			const uploadsToAdd = []
			const errors = []
			window.gapi.client.setToken({ access_token: token })
			window.gapi.client.load('drive', 'v3', () => {
				Promise.all(data.docs.map(doc => checkAccessUpload(doc, errors, uploadsToAdd, token, 0)))
					.then(() => {
						form.change(`${getFieldName(field, duplicatedNumber)}.value`, (files || []).concat(uploadsToAdd))
						if (errors.length) {
							setErrorFiles(errors)
						}
					})
			})

		}
	}

	const delay = (time) => new Promise(resolve => setTimeout(resolve, time));

	const checkAccessUpload = (doc, errors, uploadsToAdd, token, cpt) => {
		return window.gapi.client.drive.files.get({
				fileId: doc.id,
				fields: 'capabilities,teamDriveId,viewersCanCopyContent',
				supportsAllDrives: 'true'
			})
			.then(({ result }) => {
				// Si nous sommes dans le cas d'un fichier, on vérifie sa taille
				// TB_210 - Faire sauter la limitation de 50 MB sur les video
				if (doc.type !== DRIVE_UPLOAD_TYPE.FOLDER && !doc.mimeType.includes('video') && doc.sizeBytes && doc.sizeBytes > 52428800) {
					errors.push({ fileName: doc.name, name: 'errorSize' })
				} else if ((doc.type === DRIVE_UPLOAD_TYPE.FOLDER || result.capabilities.canShare || result.capabilities.canCopy) && result.viewersCanCopyContent) {
					// Pour un dossier, nous ne vérifions que l'accès à son contenu
					// Pour un fichier, nous vérifions que l'on peut le copier ou le partager
					uploadsToAdd.push({
						id: doc.id,
						name: doc.name,
						type: doc.type,
						url: doc.url,
						sizeBytes: doc.sizeBytes,
						mimeType: doc.mimeType,
						token,
						canShare: result.capabilities.canShare,
						teamDriveId: result.teamDriveId
					})
				} else {
					errors.push({ fileName: doc.name, name: 'errorRights' })
				}
			})
			.catch(e => {
				if (cpt < NB_MAX_RETRY) {
					return delay(1000 * (cpt + 1)).then(() => checkAccessUpload(doc, errors, uploadsToAdd, token, cpt + 1))
				} else {
					console.log(`erreur max retries - ${doc.name} (${doc.type})`, e)
					errors.push({ fileName: doc.name, name: 'errorGlobal' })
				}
			})
	}

	const deleteFile = (index) => {
		const newFiles = [...files]
		newFiles.splice(index, 1)
		form.change(`${getFieldName(field, duplicatedNumber)}.value`, newFiles)
	}

	const openFolder = (isEdition, selectedFolderOnOpen) => {
		openFolderPopin({
				fieldId: fieldId,
				fieldName: getFieldName(field, duplicatedNumber),
				fieldLabel: data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] && `${data[ATTRIBUTE_DATA_MO_FIELD.NAME].value}`
			},
			isEdition,
			selectedFolderOnOpen)
	}
	const confidentiableFieldName = `${getFieldName(field, duplicatedNumber)}.confidential`
	if (consultation) {
		if (initialValues.length === 0) {
			return <></>
		}

		const sizeBlock = initialValues.length > 1 ? 6 : 12
		return <Grid item container xs={12} className={classes.consultationContainer}>
			{documentType === TYPE_CONTENU.REFERENCE && <Grid item xs={12}>
				<Typography variant="caption">{data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] && `${data[ATTRIBUTE_DATA_MO_FIELD.NAME].value}`}</Typography>
			</Grid>}
			<Grid item container xs={12}>
				{initialValues.map(file => <Grid key={`file-${file.id}`} item container xs={sizeBlock} className={classes.containerFile}>
					{file.type === DRIVE_UPLOAD_TYPE.FOLDER ? <FolderCard folder={file} uploaded={uploaded} onClick={() => uploaded && openFolder(false, file.id)} /> : <FileCard file={file} uploaded={uploaded} />}
				</Grid>)}
			</Grid>
		</Grid>
	} else {
		return <Grid item container>
			<Grid item xs={field.confidentiable? 9:12}>
				<Typography variant="caption" className={classes.titleFile}>{data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] && `${data[ATTRIBUTE_DATA_MO_FIELD.NAME].value} ${field.required ? '*' : ''}`}</Typography>
			</Grid>
			{field.confidentiable && <Grid item xs={3}>
				<Grid item xs={12} className={classes.checkbox}>
					<Field
						name={confidentiableFieldName}
						label={intl.formatMessage({ id: 'document.field.confidentiable' })}
						component={Checkbox}
					/>
				</Grid>
			</Grid>}
			<Grid item xs={12} className={classNames(classes.padding, classes.inputMargin)}>
				<Grid item container className={classes.driveContainer} alignItems="center" justify="center">
					<Grid item xs={8}>
						<Typography className={classes.info}><FormattedMessage id="document.field.drive.selectFiles" /></Typography>
					</Grid>
					<Grid item>
						<Button
							className={classes.openBtn}
							color="primary"
							variant="outlined"
							onClick={openPicker}
						>
							<FormattedMessage id="document.field.drive.open" />
						</Button>
					</Grid>
				</Grid>
				<Grid item container>
					<Grid item xs={1} className={classes.marginHelp}><HelpIcon className={classes.helpIcon} /></Grid>
					<Grid item xs={11}><Typography variant="caption" className={classes.helpText}><FormattedMessage id="document.field.drive.infoFolder" /></Typography></Grid>
				</Grid>
			</Grid>
			{files.map((file, index) => {
				const size = bytesToSize(file.sizeBytes)
				const isFolder = file.type === DRIVE_UPLOAD_TYPE.FOLDER
				const isNewFile = initialFiles && !initialFiles.map(initialFile => initialFile.id).includes(file.id)
				return (<Grid
					key={`file-${field.reference}-${file.name}-${index}`}
					item
					xs={12}
					className={classes.padding}
				>
					<Grid item container alignItems="center" className={classes.file}>
						<Grid item xs={10}>
							{isFolder ?
								<Typography variant="body2" className={classes.fileName}>
									<FormattedMessage id="document.field.drive.prefixFolder" />
									{`${file.name} `}
									{(!uploaded || isNewFile) && <FormattedMessage id="document.field.drive.uploading" />}
								</Typography>
								:
								<a href={file.url} target="_blank" rel="noopener noreferrer">
									<Typography variant="body2" className={classes.fileName}>
										{`${file.name} ${size ? `(${size}) ` : ''}`}
										{(!uploaded || isNewFile) && <FormattedMessage id="document.field.drive.uploading" />}
									</Typography>
								</a>
							}
						</Grid>
						<Grid item container xs={2} justify="flex-end">
							{(isFolder && file.saved) && <Grid item><IconButton size="small" onClick={() => openFolder(true, file.id)} className={classes.editFolder}><EditIcon /></IconButton></Grid>}
							<Grid item>{uploaded || isNewFile ? <IconButton size="small" onClick={() => deleteFile(index)} className={classes.delete}><ClearIcon /></IconButton> : <CircularProgress size={24} className={classes.circularProgress} />}</Grid>
						</Grid>
					</Grid>
				</Grid>)
			})}
			{errorFiles.map((error, index) => {
				return (<Grid
					key={`file-error-${field.reference}-${error.fileName}-${index}`}
					item
					xs={12}
					className={classes.padding}
				>
					<Grid item container alignItems="center" className={classNames(classes.file, classes.fileError)}>
						<Grid item xs={12}>
							<Typography variant="body2" className={classNames(classes.fileName, classes.error)}><FormattedMessage id={`document.field.drive.${error.name}`} values={{ nomFichier: error.fileName }} /></Typography>
						</Grid>
					</Grid>
				</Grid>)
			})}
		</Grid>
	}
}

const actions = {
	openFolderPopin
}

DocumentDriveField.propTypes = {
	field: PropTypes.object,
	files: PropTypes.array,
	configuration: PropTypes.object,
	selectedLanguage: PropTypes.string,
	duplicatedNumber: PropTypes.number,
	documentType: PropTypes.string,
	intl: PropTypes.object,
	classes: PropTypes.object,
	consultation: PropTypes.bool,
	initialValues: PropTypes.any,
	openFolderPopin: PropTypes.func
}

export default compose(
	injectIntl,
	loadConfiguration,
	connect(null, actions),
	withStyles(styles)
)(DocumentDriveField)
