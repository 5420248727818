import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FormattedMessage } from 'react-intl'
import { Grid, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import Typography from '@material-ui/core/Typography'
import SvgIcon from '../../../../components/icon/SvgIcon'
import Button from '@material-ui/core/Button'
import { push } from 'connected-react-router'

const styles = getStyles({
	divNewContenu: {
		padding: 48,
		height: 383,
		position: 'relative'
	},
	iconElement: {
		fontSize: 42
	},
	divTitre: {
		marginTop: 12,
		marginBottom: 12
	},
	divText: {
		paddingRight: 32,
		paddingLeft: 32,
		marginTop: 12,
		marginBottom: 12,
		whiteSpace: 'pre-line'
	},
	divButton: {
		position: 'absolute',
		bottom: 25
	},
	buttonElement: {
		width: 272,
		fontWeight: 500
	},
})

const NewDocumentTypeBloc = ({ classes, contenu, goTo }) => {
	return (
		<Grid container className={classes.divNewContenu} direction="column" alignItems="center">
			{/*Titre*/}
			<Grid item>
				<SvgIcon path={contenu.icon} color='secondary' className={classes.iconElement}/>
			</Grid>
			<Grid item className={classes.divText}>
				<Typography variant="h3">
					<FormattedMessage id={`document.newDocumentPage.${contenu.type}.titre`}/>
				</Typography>
			</Grid>


			{/*Contenu information*/}
			<Grid item className={classes.divText}>
				<Typography variant="body2" align="justify">
					<FormattedMessage id={`document.newDocumentPage.${contenu.type}.description`}/>
				</Typography>
			</Grid>

			{/*Bouton*/}
			<Grid item className={classes.divButton}>
				<Button
					key="nouveauContenu"
					variant="outlined"
					color="primary"
					onClick={() => goTo(`/edition-document/new/${contenu.typeContenuEnum}`)}
					classes={{ root: classes.buttonElement }}
				>
					<FormattedMessage id={`document.newDocumentPage.${contenu.type}.libelleLien`}/>
				</Button>
			</Grid>
		</Grid>
	)
}

const actions = {
	goTo: (pathname, state) => dispatch => dispatch(push({
		pathname,
		state
	}))
}

NewDocumentTypeBloc.propTypes = {
	contenu: PropTypes.object.isRequired
}

export default compose(
	withStyles(styles),
	connect(null, actions),
)(NewDocumentTypeBloc)
