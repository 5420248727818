import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '../../../../../../components/Button'
import Popin from '../../../../../../components/Popin'
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl'

const DeleteFieldPopin = ({ open, closePopin, deleteField, intl, idField, name }) => {

	const [deleting, setDeleting] = React.useState(false)
	const [error, setError] = React.useState(null)

	const handleDelete = React.useCallback(() => {
		setDeleting(true)
		setError(null)
		return deleteField(idField)
			.then(() => setDeleting(false))
			.catch((e) => {
				setDeleting(false)
				if (e.bodyError && e.bodyError.id) {
					setError(e.bodyError)
				} else {
					setError({ id: 'parametrage.deleteField.error.generic' })
				}
				throw e
			})
	}, [idField, deleteField])

	return (
		<Popin
			open={open}
			onExited={() => setError(null)}
			onClose={closePopin}
			actions={[
				<Button
					color="inherit"
					variant="outlined"
					key="fermer"
					onClick={closePopin}
				>
					 <FormattedMessage id={`global.buttons.${error === null ? 'annuler' : 'close'}`} />
				</Button>,
				error === null ? <Button
					key="valider"
					color="primary"
					variant="contained"
					loading={deleting}
					onClick={handleDelete}
				>
					<FormattedMessage id="global.buttons.confirm" />
				</Button> : undefined
			]}
			title={intl.formatMessage({ id: 'parametrage.deleteField.popin.title' }, { name })}
			content={<Grid container direction="column">
				{error !== null ?
					<Typography color="error"><FormattedHTMLMessage {...error} /></Typography> :
					<Typography><FormattedMessage id="parametrage.deleteField.popin.content" values={{ name }}/></Typography>
				}
			</Grid>}
		/>
	)
}

DeleteFieldPopin.propTypes = {
	open: PropTypes.bool,
	closePopin: PropTypes.func,
	deleteField: PropTypes.func,
	intl: intlShape,
	idField: PropTypes.number,
	name: PropTypes.string
}

export default compose(
	injectIntl
)(DeleteFieldPopin)
