import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import { colors } from '../../utils/constants'
import Select from './Select'

const styles = () => getStyles({
	langRoot: {
		backgroundColor: colors.background
	},
	langWidth: {
		width: 160
	},
	langIcon: {
		fontSize: 36
	}
})

const CustomSelect = ({ classes, children, ...otherProps }) => (
	<Select
		classes={{ root: classes.langRoot, icon: classes.langIcon, select: classes.langWidth }}
		{...otherProps}
	>
		{children}
	</Select>
)

CustomSelect.propTypes = {
	children: PropTypes.object,
	otherProps: PropTypes.object,
	classes: PropTypes.object
}

export default compose(withStyles(styles))(CustomSelect)

