import { fetchFactory } from 'isotope-client'

export const getAllAdditionalFilters = () => fetchFactory('/additional-filters/')

export const saveResearchFields = (idSection, fieldList) => fetchFactory(`/additional-filters/saveResearchFields/${idSection}`, {
	method: 'post',
	body: JSON.stringify(fieldList)
})

export const postResearchSection = (values) => fetchFactory('/additional-filters/section', {
	method: 'post',
	body: JSON.stringify(values)
})

export const postResearchField = (values, idSection) => fetchFactory(`/additional-filters/section/${idSection}/field`, {
	method: 'post',
	body: JSON.stringify(values)
})

export const getFields = (idSection) => fetchFactory(`/additional-filters/section/${idSection}`)