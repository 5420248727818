import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { ROLE_WEIGHT } from '../../utils/constants'
import * as userSelectors from '../../modules/fo/user/services/userSelectors'

/**
 * Vérifie si le rôle de l'utilisateur est supérieur ou égal à celui requis
 * @param role
 * @param userAuthorities
 * @returns {boolean|*}
 */
const hasRole = (role, userAuthorities) => !role || (userAuthorities.length > 0 && ROLE_WEIGHT[userAuthorities[0].authority] && ROLE_WEIGHT[userAuthorities[0].authority] >= role )

/**
 * Vérifie que le rôle utilisateur connecté a accès à la page sinon on le déconnecte
 * Placer ce wrapper au sommet de la chaine du compose, afin de vérifier les accès avant de faire des requêtes serveur
 * @param role
 */
const checkRole = (...role) => (WrappedComponent) => {
	const RoleChecker = (props) => {

		const [hasAccess, setHasAccess] = React.useState(false)

		React.useEffect(() => {
			const { userAuthorities, goToAccueil, snackError } = props
			if (!hasRole(role, userAuthorities)) {
				snackError({ id: 'global.errors.droitsInsuffisants' })
				goToAccueil()
			} else {
				setHasAccess(true)
			}
		},[])

		return hasAccess ? <WrappedComponent {...props} /> : null

	}

	RoleChecker.propTypes = {
		userAuthorities: PropTypes.arrayOf(PropTypes.shape({
			authority: PropTypes.string
		})),
		goToAccueil: PropTypes.func,
		snackError: PropTypes.func
	}

	const mapStateToProps = state => ({
		userAuthorities: userSelectors.getAuthorities(state),
	})

	const actions = {
		goToAccueil: () => dispatch => dispatch(push('/accueil'))
	}

	return compose(
		connect(mapStateToProps, actions),
		injectSnackActions
	)(RoleChecker)
}

export default checkRole
