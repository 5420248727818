import { Grid, withStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ErrorIcon from '@material-ui/icons/Error'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import classnames from 'classnames'
import { goBack, push } from 'connected-react-router'
import arrayMutators from 'final-form-arrays'
import { getStyles } from 'isotope-client'
import { injectActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { FormattedMessage, injectIntl } from 'react-intl'
import LoaderAdvanced from 'react-loader-advanced'
import { connect } from 'react-redux'
import { animateScroll, Element } from 'react-scroll'
import { compose } from 'redux'
import Button from '../../../components/Button'
import Input from '../../../components/form/Input'
import Select from '../../../components/form/Select'
import CenteredLayout from '../../../components/layout/CenteredLayout'
import Loader from '../../../components/layout/Loader'
import Link from '../../../components/Link'
import { hasAccess } from '../../../components/security/AccessChecker'
import { PROFILS, USER_PREFERENCES } from '../../../utils/constants'
import { getConversionMap } from '../../common/conversion/conversionSelectors'
import { injectLangues } from '../../common/langue/langueInjector'
import { injectZoneList } from '../../common/valueList/ValueListInjector'
import { getContributeurs } from '../user/services/usersApi'
import * as userSelectors from '../user/services/userSelectors'
import { getUserSu } from '../user/services/userSelectors'
import * as modelSelectors from './../../common/model/modelSelectors'
import DocumentActionsBar from './components/DocumentActionsBar'
import DocumentSection from './components/DocumentSection'
import TranslatePopin from './components/popin/TranslatePopin'
import DocumentSummary from './components/summary/DocumentSummary'
import * as documentActions from './services/documentActions'
import * as documentSelectors from './services/documentSelectors'
import { DOCUMENT_STATUS, FIELD_TYPE } from './utils/documentConstants'
import { extractValues, initContentDocumentValues } from './utils/DocumentInitValues'
import { formatFieldToSubmit, getFilteredSections, getFiSectionFromDocument, handleSectionsVisibility, tableHasValue } from './utils/documentUtils'
import validate from './utils/documentValidate'
import { isCorrespondantValidForDocument, isMemberValidForChamps, isMemberValidForDocument } from '../../../utils/utils'
import GlobalReportPopin from './components/popin/GlobalReportPopin'
import { getItemsByListCode } from 'isotope-client/modules/valueList/services/valueListSelectors'

const styles = theme => getStyles({
	retour: {
		color: theme.palette.text.main,
		display: 'flex',
		alignItems: 'center',
		marginLeft: '-10px',
		marginTop: '-20px',
		marginBottom: 10
	},
	title: {
		marginBottom: 10,
		height: 50,
		marginLeft: 20,
		marginTop: -20
	},
	titleNew: {
		marginBottom: 30
	},
	paper: {
		padding: 20,
		height: 'calc(100vh - 330px)',
		overflow: 'auto',
		backgroundColor: 'white'
	},
	paperNew: {
		height: 'calc(100vh - 270px)'
	},
	actionBar: {
		padding: '15px 45px',
		boxSizing: 'border-box',
		height: 60,
		borderTop: '3px rgba(0,0,0,0.08)solid'
	},
	button: {
		marginRight: 50
	},
	errorContainer: {
		padding: 10,
		marginBottom: 20
	},
	errorRow: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: 8
	},
	errorIcon: {
		color: '#d32f2f'
	},
	errorText: {
		marginLeft: 10,
		color: '#d32f2f'
	},
	submitMessage: {
		position: 'absolute',
		left: '50%',
		bottom: '40%',
		fontWeight: 'bold',
		width: 1000,
		transform: 'translateX(-50%)',
		color: theme.palette.primary.main
	}
})

const ID_NEW = 'new'

const ZoneField = ({ input, isAdmin, isCorrespondant, isCorrespondantValid, isMemberValid, isMember, userEmail, intl, form, document }) => {
	const [contributorList, setContributorList] = React.useState([])
	const zoneSelected = input.value

	// Chargement des contributeurs à chaque modification de la zone
	React.useEffect(() => {
		let contributorDocu = ""
		if (document && document.attributeData) {
			document.attributeData.forEach(attribute => {
				if(attribute.label === "CONTRIBUTOR"){
					contributorDocu = attribute.value
				}
			})
		}
		if (zoneSelected) {
			getContributeurs(zoneSelected)
				.then(list => {
					setContributorList(list)
					if (isCorrespondant || (isMember && !isMemberValid)) {
						form.change('contributor', userEmail)
					} else if(list.filter(contrib => (contrib.email === contributorDocu).size > 0)){
						form.change('contributor', contributorDocu)
					}
				})
				.catch(() => setContributorList([]))
		}
	}, [zoneSelected])

	return (<Field name="contributor">
		{(props) => {
			return (
				<Select
					{...props}
					label={`${intl.formatMessage({ id: 'document.entete.contributor' })} *`}
					disabled={!(isAdmin || isCorrespondantValid || isMemberValid)}
				>
					{contributorList && contributorList.filter(contrib => contrib.preferences.IS_ALIVE !== '0').map(contributor =>
						<MenuItem key={`contributor-${contributor.email}`}
						          value={contributor.email}>
							{contributor.lastname} {contributor.firstname}
						</MenuItem>
					)}
				</Select>
			)
		}}
	</Field>)
}

const DocumentPage = ({
	user,
	model,
	document,
	zoneList,
	langues,
	idLanguage,
	isCorrespondantValid,
	isMemberValid,
	isAdmin,
	isCorrespondant,
	isMember,
	documentId,
	isNew,
	redirectNew,
	userZone,
	userSu,
	duplicatedSections,
	conversionMap,
	isLoading,
	getDocument,
	postDocument,
	putDocument,
	goToEdition,
	goToListe,
	clearDocument,
	classes,
	intl,
	contributor,
	snackSuccess,
	snackError,
	goToConsultation,
	goBackAction,
	location
}) => {
	const validator = React.useMemo(() => validate(getFiSectionFromDocument(model)), [model])
	const [workflowLoader, setWorkflowLoader] = React.useState(false)
	const [openTranslatePopin, setOpenTranslatePopin] = React.useState(false)
	const [selectedLanguage, setSelectedLanguage] = React.useState(idLanguage)
	const [openGlobalReportPopin, setOpenGlobalReportPopin] = React.useState(false)
	const [duplicatedSectionsForm, setDuplicatedSectionsForm] = React.useState({})
	const [deletedSections, setDeletedSections] = React.useState([])
	const [mapSectionByField, setMapSection] = React.useState({})
	const [openSections, setOpenSections] = React.useState([])
	// Gestion du dirty indépendamment de la fermeture des sections
	const [isDirty, setIsDirty] = React.useState(false)

	const [isMemberValidEdit, setIsMemberValidEdit] = React.useState(isMemberValid)
	const [selectedSector, setSelectedSector] = React.useState([])
	const [selectedSubSegment, setSelectedSubSegment] = React.useState([])

	const setMapSectionByField = (section) => {
		setMapSection(oldSections => {
			let fieldNamesFound = false
			const sectionFound = Object.keys(section).filter(fieldName => !oldSections[fieldName]).reduce((newmap, fieldName) => {
				fieldNamesFound = true
				return {
					...newmap,
					[fieldName]: section[fieldName]
				}
			}, {})
			if (fieldNamesFound) {
				return {
					...oldSections,
					...sectionFound
				}
			}
			return oldSections
		})
	}

	React.useEffect(() => {
		if (isNew) {
			clearDocument()
		}
		// On charge le document
		if ((!isNew && document && document.id !== documentId) || (location && location.state && location.state.forceClean) ){
			clearDocument()
			getDocument(documentId)
		}
	}, [])

	const boucleIdSection= (result, sectionsParent, parentId) => {
		// En mode consultation on ouvre toute les sections
		sectionsParent.forEach(sectionOpenChildren => {
			var sectionKeyChildren =""
			if(sectionOpenChildren.duplicated){
				sectionKeyChildren = `${parentId}-${sectionOpenChildren.id}#${sectionOpenChildren.duplicatedNumber}`
				result.push(`-${sectionKeyChildren}`)
			} else {
				sectionKeyChildren = `${parentId}-${sectionOpenChildren.id}`
				result.push(`-${sectionKeyChildren}`)
			}
		})
	}

	// Au montage du formulaire, on ouvre la première section
	React.useEffect(() => {
		const sectionsFiltered = getFilteredSections(model.sections)
		var result = []
		sectionsFiltered.forEach(sectionOpen => {
			var nomSection = ""
			if(sectionOpen.duplicated){
				nomSection = `${sectionOpen.id}#${sectionOpen.duplicatedNumber}`
			} else {
				nomSection = `${sectionOpen.id}`
			}
			result.push(`-${nomSection}`)
			if (sectionOpen.sections && sectionOpen.sections.length > 0) {
				boucleIdSection(result, sectionOpen.sections, nomSection)
			}
		})
		setOpenSections(result)
	}, [model.sections])


	React.useEffect(() => {
		if ((model.sections || (model.sections && document.id !== initialValues.id))
			&&
			((!isNew && document.id) || (isNew && JSON.stringify(document) === "{}")
			)) {
			const { fields, status } = extractValues(model && getFilteredSections(model.sections), document && document.sections, selectedLanguage, userSu, conversionMap)
			setInitialValues({
				selectedLanguage,
				contributor,
				zone: userZone,
				idLanguage,
				duplicatedSections,
				...initContentDocumentValues(document, selectedLanguage),
				fields,
				status
			})

			setDuplicatedSectionsForm(duplicatedSections)
		}
	}, [model.sections, document, selectedLanguage, conversionMap])

	const flattenSection = (section) => {
		const { sectionParentInfo, ...otherProps } = section
		if (sectionParentInfo) {
			return {
				...flattenSection(sectionParentInfo),
				[`${section.id}-${section.position}-${section.duplicatedNumber}`]: otherProps
			}
		}
		return {
			[`${section.id}-${section.position}-${section.duplicatedNumber}`]: otherProps
		}
	}

	const save = (values) => {
		// On ne doit récupérer que les champs qui ont été modifiées
		const modifiedFieldsNames = Object.keys(values.fields).filter(valKey => {
			const valueField = values.fields[valKey]
			const initField = initialValues.fields[valKey] || {}

			if (!valueField) {
				return false
			}

			// Check de différents champs selon le type

			const fieldType = values.fields[!valKey.includes('#') ? valKey : valKey.substring(0, valKey.indexOf('#'))]

			let defaultFieldType = FIELD_TYPE.TEXT
			if(fieldType){
				defaultFieldType = fieldType.type
			} else if(values.fields[valKey]){
				defaultFieldType = values.fields[valKey].type
			}

			return (defaultFieldType !== FIELD_TYPE.TABLE && valueField.value !== undefined && ((valueField.value.length > 0 && valueField.value !== initField.value) || (initField.value !== undefined && valueField.value.length === 0 && initField.value.length !== 0))) ||
				(defaultFieldType === FIELD_TYPE.TABLE && tableHasValue(valueField) && JSON.stringify(valueField.value) !== JSON.stringify(initField.value)) ||
				(defaultFieldType === FIELD_TYPE.AMOUNT && ((!!valueField.siValue && valueField.siValue !== initField.siValue) || (!valueField.siValue && !!initField.siValue))) ||
				(defaultFieldType === FIELD_TYPE.AMOUNT && !!valueField.unite && valueField.unite !== initField.unite) ||
				(defaultFieldType === FIELD_TYPE.DUNS && !!valueField.dunsData && valueField.dunsData !== initField.dunsData) ||
				(defaultFieldType === FIELD_TYPE.LINK && !!valueField.libelle && valueField.libelle !== initField.libelle) ||
				(defaultFieldType === FIELD_TYPE.DATE && ((!!valueField.value && valueField.value !== initField.value) || (!valueField.value && !!initField.value))) ||
				(!!valueField.confidential && valueField.confidential !== initField.confidential)
		}).map(valKey => `fields.${valKey}`)

		// Pendant la traduction, on récupère tous les champs text et rich text, même ceux qui n'ont pas été modifiés
		const shouldTranslate = values.translateTo && values.translateTo.length

		// On filtre les sections "finished" pour ne retourner que celles qui ont eu un changement de statut
		const sectionFinished = Object.keys(values.status)
			.filter(statutKey => !initialValues.status[statutKey] || (initialValues.status[statutKey] && values.status[statutKey].finished !== initialValues.status[statutKey].finished))
			.map(statutKey => values.status[statutKey])

		const modifiedSections = {
			...modifiedFieldsNames.map(fieldName => mapSectionByField[fieldName]).filter(section => section)
				.reduce((sections, section) => ({
					...sections,
					...flattenSection(section)
				}), {}),
			...sectionFinished
				.reduce((sections, section) => ({
					...sections,
					...flattenSection(section)
				}), {}),
			...deletedSections
				.reduce((sections, section) => ({
					...sections,
					[section.id]: section
				}), {})
		}

		// On envoie les champs modifiés
		// On filtre via le nom de la clé et on vérifie la présence de # dans le cas d'une duplication

		const fieldsValues = values.fields ? Object.keys(values.fields)
			.filter(key => (shouldTranslate && !!values.fields[key].value && (values.fields[key].type === FIELD_TYPE.TEXT || values.fields[key].type === FIELD_TYPE.TEXT_MULTILINE || (values.fields[key].type === FIELD_TYPE.TABLE && tableHasValue(values.fields[key])))) ||
				!!modifiedFieldsNames.find(modifiedName => {
					const isDuplicatedField = key.includes('#') && modifiedName.includes('#')
					const isNotDuplicatedField = !key.includes('#') && !modifiedName.includes('#')
					const splitField = modifiedName.split('#')
					const valueFieldName = splitField[0]

					return (isNotDuplicatedField && valueFieldName === `fields.${key}`) || (isDuplicatedField && `${valueFieldName}#${splitField[1]}` === `fields.${key}`)
				})
			)
			/*
			 * en cas de maj GR, on met à jour des champs qui ne sont pas affiché/editable car conditionné par d'autre champs
			 * Il faut les retirer, pour cela on va regarder si leur idMoSection et dans la liste des modifiedSections
			 */
			.filter(key => values.fields[key].idSection || Object.values(modifiedSections).find(modifiedSection => {
				if (key.includes('#') && !values.fields[key].id) {
					return modifiedSection.id === values.fields[key.substring(0, key.indexOf('#'))].idMoSection
				} else {
					return modifiedSection.id === values.fields[key].idMoSection
				}
				})
			)
			.reduce((acc, valKey) => {
				let fieldValue = values.fields[valKey]
				// Dans le cas d'un nouveau champ dupliqué, on doit reprendre les infos du modèle
				if (valKey.includes('#') && !values.fields[valKey].id) {
					const defaultFieldValue = values.fields[valKey.substring(0, valKey.indexOf('#'))]
					fieldValue = {
						...fieldValue,
						type: defaultFieldValue.type,
						idMoField: defaultFieldValue.idMoField,
						idMoSection: defaultFieldValue.idMoSection
					}

					// Gestion tableau dupliqué
					if (defaultFieldValue.type === FIELD_TYPE.TABLE) {
						fieldValue = {
							...fieldValue,
							value: Object.keys(fieldValue.value).map(columnKey => {
								const columnDefaultValue = defaultFieldValue.value[columnKey]
								return {
									...fieldValue.value[columnKey],
									reference: columnKey,
									type: columnDefaultValue.type,
									idMoField: columnDefaultValue.idMoField,
									idMoSection: columnDefaultValue.idMoSection
								}
							}).reduce((acc, curr) => ({
								...acc,
								[curr.reference]: curr
							}), {})
						}
					}
				}
				return ([
					...acc,
					{ reference: valKey, ...formatFieldToSubmit(fieldValue) }
				]);
			}, []) : []

		let promise

		if (isNew) {
			promise = postDocument({
				typeModel: model.type,
				title: values.title,
				idLanguage: values.idLanguage,
				zone: values.zone,
				contributor: values.contributor,
				commentaire: values.commentaire,
				fields: fieldsValues,
				sections: Object.values(modifiedSections)
			}).then(docResult => goToEdition(docResult.document.id))
		} else {
			promise = putDocument({
				id: values.id,
				typeModel: model.type,
				title: values.title,
				idLanguage: values.selectedLanguage,
				zone: values.zone,
				contributor: values.contributor,
				commentaire: values.commentaire,
				fields: fieldsValues,
				sections: Object.values(modifiedSections),
				translateTo: values.translateTo,
				deletedFolderContent: (values.deletedFolderContent || []).filter(item => !item.isRoot).map(item => item.id)
			}).then(() => {
				// Rechargement du document
				getDocument(documentId, false)
			}).then(() => (location.state && location.state.forceGoBack)
				? goBackAction()
				: goToConsultation(documentId, location.state)
			)
		}

		return promise
			.then(() => snackSuccess({ id: 'document.saveSuccess' }, { autoHideDuration: 7000 }))
			.catch((e) => {
				snackError()
				throw e
			})
	}

	// Gestion ouverture/fermeture des sections via le document
	const handleOpenSections = (sectionKey, atBottom) => {
		const newSections = handleSectionsVisibility(sectionKey, openSections, atBottom)
		if (newSections) {
			setOpenSections(newSections)
		}
		// Valeur de retour utilisée pour le loading du collapse
		return Promise.resolve(false)
	}

	const getLoader = (isSubmitting) => {
		if (isSubmitting) {
			return <>
				<CircularProgress />
				<span className={classes.submitMessage}><FormattedMessage id="document.submittingInfo" /></span>
			</>
		}

		return <CircularProgress />
	}

	const [initialValues, setInitialValues] = React.useState({})
	const documentContributor = initialValues && initialValues.contributor
	const documentZone = initialValues && initialValues.zone

	const [formErrors, setFormErrors] = React.useState()

	if (isLoading || !model.sections || !contributor || !initialValues.contributor || !langues || langues.length === 0) {
		return <Loader />
	}

	return <Form
		validateOnBlur
		validate={validator}
		subscription={{ submitting: true, invalid: true, submitFailed: true }}
		onSubmit={save}
		mutators={{ ...arrayMutators }}
		initialValues={initialValues}
	>
		{({ handleSubmit, invalid, submitFailed, form, submitting }) => <CenteredLayout>
			<OnChange name="selectedLanguage">{(values) => setSelectedLanguage(values)}</OnChange>
			<OnChange name="duplicatedSections">{(values) => {
				const sections = []
				Object.values(values).forEach(duplicatedSections => {
					sections.push(...duplicatedSections.filter(section => section.deleted))
				})
				setDuplicatedSectionsForm(values)
				setDeletedSections(sections)
			}}</OnChange>
			<OnChange name="zone">{() => form.change('contributor', undefined)}</OnChange>
			<OnChange name={"fields."+USER_PREFERENCES.FI_PRE_CLIENT_SECTOR+".value"}>{(value) => {
				setSelectedSector(value)
				var valide = isMember && isMemberValidForChamps(user, value, selectedSubSegment)
				setIsMemberValidEdit(valide)
				if(isMember && !valide){
					form.change('zone', documentZone)
					form.change('contributor', documentContributor)
				}
			}}</OnChange>
			<OnChange name={"fields."+USER_PREFERENCES.FI_SUBSEGMENT+".value"}>{(value) => {
				setSelectedSubSegment(value)
				var valide = isMember && isMemberValidForChamps(user, selectedSector, value)
				setIsMemberValidEdit(valide)
				if(isMember && !valide){
					form.change('zone', documentZone)
					form.change('contributor', documentContributor)
				}
			}}</OnChange>
			<FormSpy
				subscription={{ errors: true }}
				onChange={state => {
					let { errors } = state
					// Affichage des erreurs synchrones
					// On supprime le noeud fields pour afficher les champs en erreurs via leur libellé
					if (errors && errors.fields) {
						errors = {
							...errors,
							fields: {},
							...errors.fields
						}
						delete (errors.fields)
					}
					setFormErrors(errors)
				}}
			/>
			<FormSpy
				subscription={{ hasValidationErrors: true, submitFailed: true, submitSucceeded: true }}
				onChange={() => {
					form.change('translateTo', undefined)
				}}
			/>
			<form>
				<Grid container>
					{!isNew && !redirectNew && <Grid item xs={1}>
						<Link to="/documents" className={classes.retour} onClick={() => location.state ? goBackAction() : goToListe()} isReturn>
							<ArrowLeftIcon fontSize="large" />
							<FormattedMessage id="document.retour" />
						</Link>
					</Grid>}
					<Grid item xs={(!isNew && !redirectNew) ? 11 : 12}>
						<Typography variant="h1" className={isNew ? classes.titleNew : classes.title}>{isNew ?
							<FormattedMessage id="document.newContent" /> : (initialValues && initialValues.title) ||
							<FormattedMessage id="document.editContent" />}</Typography>
					</Grid>
				</Grid>
				<LoaderAdvanced
					show={submitting || workflowLoader}
					message={getLoader(submitting)}
					backgroundStyle={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
				>
					<Grid container spacing={1}>
						{!isNew && <FormSpy
							subscription={{ dirty: true, dirtyFields: true }}
							onChange={({ dirty, dirtyFields }) => {
								// On passe en dirty si c'est un autre champ que selectedLanguage
								const filterDirty = { ...dirtyFields, selectedLanguage: undefined }
								// Champs Duns => on fait que si value ET dataDuns sont dirty
								if (dirty && Object.values(filterDirty).some(item => !!item) && !isDirty) {
									setIsDirty(true)
								}
							}}

						/>}
						{!isNew && <DocumentActionsBar
							documentTender={document}
							isCorrespondantValid={isCorrespondantValid}
							isAdmin={isAdmin}
							langues={langues}
							isDirty={isDirty}
							documentType={model.type}
							workflowLoader={workflowLoader}
							setWorkflowLoader={setWorkflowLoader}
							openTranslatePopin={() => setOpenTranslatePopin(true)}
							openGlobalReportPopin={() => setOpenGlobalReportPopin(true)}
							setOpenSections={setOpenSections}
						/>}
						<Grid item xs={4}>
							<DocumentSummary
								model={model}
								document={document}
								selectedLanguage={selectedLanguage}
								isNewDocument={isNew}
								duplicatedSections={duplicatedSectionsForm}
								openSections={openSections}
								changeSections={setOpenSections}
							/>
						</Grid>
						<Grid item xs={8}>
							<Element name="docPage" id="docPage"
							         className={classnames(classes.paper, { [classes.paperNew]: isNew })}>
								<Grid container>
									{submitFailed && formErrors && Object.keys(formErrors).length > 0 &&
									<Grid item container xs={12} className={classes.errorContainer}>
										{Object.keys(formErrors).map(errKey => {
											return <Grid item xs={12} key={`error-${errKey}`}
											             className={classes.errorRow}
											><ErrorIcon className={classes.errorIcon} />
												<Typography className={classes.errorText}><FormattedMessage
													id="document.errorField"
													values={{ fieldName: formErrors[errKey].mustBeTranslated ? intl.formatMessage({ id: formErrors[errKey].fieldKey }) : formErrors[errKey].fieldKey }}
												/>
												</Typography>
											</Grid>
										})}
									</Grid>}
									<Grid item xs={12}>
										<Field
											name="title"
											label={`${intl.formatMessage({ id: 'document.entete.title' })} *`}
											component={Input}
											autoComplete="off"
											inputProps={{
												maxLength: 150
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<Field
											name="zone"
											label={`${intl.formatMessage({ id: 'document.entete.zone' })} *`}
											component={Select}
											disabled={!(isAdmin || isMemberValidEdit)}
										>
											{zoneList && zoneList.map(zoneElement =>
												<MenuItem key={`ZONE-${zoneElement.code}`} value={zoneElement.code}>
													{zoneElement.label}
												</MenuItem>
											)}
										</Field>
									</Grid>
									<Grid item xs={6}>
										<Field
											name="idLanguage"
											label={`${intl.formatMessage({ id: 'document.entete.language' })} *`}
											component={Select}
											disabled={!isNew}
										>
											{langues && langues.map(lang =>
												<MenuItem key={`langue-${lang.id}`} value={lang.id}>
													{lang.name}
												</MenuItem>
											)}
										</Field>
									</Grid>
									<Grid item xs={6}>
										<Field
											name="zone"
											component={ZoneField}
											isCorrespondantValid={isCorrespondantValid}
											isMemberValid={isMemberValidEdit}
											isAdmin={isAdmin}
											isCorrespondant={isCorrespondant}
											isMember={isMember}
											userEmail={contributor}
											intl={intl}
											form ={form}
											document = {document}
										/>
									</Grid>
									<Grid item xs={12}>
										<Field
											name="commentaire"
											label={<FormattedMessage id="document.entete.commentaire" />}
											component={Input}
											autoComplete="off"
										/>
									</Grid>
								</Grid>
								{getFilteredSections(model.sections).map((section, index) => <DocumentSection
									key={`section-${section.id}-${index}`}
									section={section}
									level={1}
									parentKey=""
									selectedLanguage={selectedLanguage}
									documentType={model.type}
									workflow={document.workflow}
									handleOpenSections={handleOpenSections}
									openSections={openSections}
									setOpenSections={setOpenSections}
									setMapSectionByField={setMapSectionByField}
									documentContributor={documentContributor}
									isCorrespondantValid={isCorrespondantValid}
									isMemberValid={isMemberValidEdit}
								/>)}
							</Element>

							<Paper className={classes.actionBar} elevation={8}>
								<Grid container justify="center">
									<Button
										color="inherit"
										variant="outlined"
										key="annuler"
										onClick={() => {
											form.reset()
											setIsDirty(false)
										}}
										className={classes.button}
									>
										<FormattedMessage id="global.buttons.annuler" />
									</Button>
									<Button
										color="primary"
										variant="contained"
										onClick={() => {
											if (invalid) {
												snackError({ id: 'global.errors.formulaire' })
												animateScroll.scrollTo('docPage', {
													duration: 1500,
													delay: 100,
													smooth: true,
													containerId: 'docPage'
												})
											}
											handleSubmit()
										}}
										loading={submitting}
									>
										<FormattedMessage id="global.buttons.save" />
									</Button>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</LoaderAdvanced>
			</form>
			<TranslatePopin
				open={openTranslatePopin}
				closePopin={() => setOpenTranslatePopin(false)}
				selectedLanguage={selectedLanguage}
			/>
			<GlobalReportPopin
				open={openGlobalReportPopin}
				closePopin={() => setOpenGlobalReportPopin(false)}
				setMapSectionByField={setMapSectionByField}
			/>
		</CenteredLayout>}
	</Form>
}

const mapStateToProps = (state, { match, location }) => {
	const document = documentSelectors.getDocument(state)
	const documentType = (document && document.type) || (match && match.params && match.params.typeDocument)
	const model = modelSelectors.getModelByType(state, documentType)
	const authorities = userSelectors.getAuthorities(state)
	const documentId = match && match.params && match.params.id
	const redirectNew = location && location.state && location.state.redirectNew

	return {
		user: userSelectors.getUser(state),
		model,
		document,
		contributor: userSelectors.getUserEmail(state),
		isCorrespondantValid: hasAccess(PROFILS.CORRESPONDANT, authorities) && isCorrespondantValidForDocument(userSelectors.getUser(state), documentSelectors.getDocument(state)),
		isMemberValid: hasAccess(PROFILS.MEMBRE, authorities) && isMemberValidForDocument(userSelectors.getUser(state), documentSelectors.getDocument(state)),
		isAdmin: hasAccess(PROFILS.ADMIN, authorities),
		isCorrespondant: hasAccess(PROFILS.CORRESPONDANT, authorities),
		isMember: hasAccess(PROFILS.MEMBRE, authorities),
		documentId,
		isNew: documentId === ID_NEW,
		redirectNew: redirectNew,
		isLoading: documentSelectors.isLoading(state),
		idLanguage: userSelectors.getUserLanguage(state),
		userSu: getUserSu(state),
		conversionMap: getConversionMap(state),
		duplicatedSections: documentSelectors.getDuplicatedSectionList(state),
		userZone: userSelectors.getUserZone(state),
		su: getUserSu(state),
		items: getItemsByListCode(state)
	}
}

const actions = {
	getDocument: documentActions.getDocument,
	postDocument: documentActions.postDocument,
	putDocument: documentActions.putDocument,
	clearDocument: documentActions.clearDocument,
	goToEdition: (id) => dispatch => dispatch(push({
		pathname: `/edition-document/${id}`,
		state: { redirectNew: true }
	})),
	goToConsultation: (id, state) => dispatch => dispatch(push({ pathname: `/consultation-document/${id}`, state })),
	goToListe: () => dispatch => dispatch(push('/documents',{
		initialId: undefined,
		initialQuery: undefined,
		initialTypeContenu: undefined,
		initialFieldsAdvance: undefined,
		initialFieldsAdmin: {
			status: [{ code: DOCUMENT_STATUS.PUBLIE }]
		}})),
	goBackAction: () => dispatch => dispatch(goBack())
}

DocumentPage.propTypes = {
	user: PropTypes.object,
	classes: PropTypes.object,
	model: PropTypes.object,
	document: PropTypes.object,
	zoneList: PropTypes.array,
	langues: PropTypes.array,
	isCorrespondantValid: PropTypes.bool,
	isMemberValid: PropTypes.bool,
	isAdmin: PropTypes.bool,
	isMember: PropTypes.bool,
	documentId: PropTypes.string,
	isNew: PropTypes.bool,
	redirectNew: PropTypes.bool, // Dans le cas de la redirection à partir d'un ajout, on enlève la flèche de retour
	userZone: PropTypes.string,
	userSu: PropTypes.string,
	userLanguage: PropTypes.string,
	userEmail: PropTypes.string,
	duplicatedSections: PropTypes.object,
	conversionMap: PropTypes.object,
	isLoading: PropTypes.bool,
	getDocument: PropTypes.func,
	postDocument: PropTypes.func,
	putDocument: PropTypes.func,
	goToEdition: PropTypes.func,
	snackError: PropTypes.func,
	clearDocument: PropTypes.func,
	openSections: PropTypes.array, // Sections ouvertes
	changeSections: PropTypes.func,
	clearSections: PropTypes.func,
	goBackAction: PropTypes.func,
	goToConsultation: PropTypes.func,
	goToEditTabDocument: PropTypes.func
}

export default compose(
	injectLangues,
	connect(mapStateToProps, actions),
	injectZoneList,
	injectActions,
	injectIntl,
	withStyles(styles)
)(DocumentPage)
