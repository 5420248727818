import { getStyles } from 'isotope-client'
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Grid, MenuItem, Typography } from '@material-ui/core'
import Select from '../../../../components/form/Select'
import AccessChecker from '../../../../components/security/AccessChecker'
import { PROFILS } from '../../../../utils/constants'
import { FormattedMessage } from 'react-intl'
import { Field } from 'redux-form'
import { readPreferenceList } from '../../../../utils/utils'
import { FI_FACILITATEUR } from '../../document/utils/documentConstants'

const styles = () => getStyles({
	perimeterContainer: {
		marginLeft: 10
	},
	names: {
		marginBottom: '1rem',
		color: '#88888a',
		fontSize: '0.75rem'
	}
})

const getValues = preferences => (acc, curr) => {
	if (!preferences || preferences.indexOf(curr.code) === -1) {
		return acc
	}
	const label = curr.label
	return acc ? `${acc}, ${label}` : label
}


const PerimetreField = ({ elementList, preferences, preferenceName, name, classes }) => {

	const required = value => {
		if(FI_FACILITATEUR.find(element => element.pref === name) ){
			return undefined
		}
		return (value && value.length) ? undefined : { id: 'modificationUser.form.error.mandatory' }
	}

	return (
		<>
			<AccessChecker access={PROFILS.ADMIN}>
				<Grid item sm={6}>
					<Field
						component={Select}
						multiple
						name={name}
						validate={required}
						label={<FormattedMessage id={`modificationUser.form.${name}`} />}
					>
						{elementList && elementList.map(element =>
							<MenuItem key={`BUSINESS_SEGMENT-${element.code}`}
							          value={element.code}>
								{element.label}
							</MenuItem>
						)}
					</Field>
				</Grid>
			</AccessChecker>
			<AccessChecker access={[PROFILS.MEMBRE, PROFILS.CORRESPONDANT]}>
				<Grid container item xs={12} className={classes.perimeterContainer}>
					<Grid item xs={3}>
						<Typography variant="body1" className={classes.names}>
							<FormattedMessage id={`modificationUser.form.${name}`} /> :
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<Typography variant="body1">
							{elementList && elementList.reduce(getValues(readPreferenceList({ preferences }, preferenceName)), '')}
						</Typography>
					</Grid>
				</Grid>
			</AccessChecker>
		</>
	)
}

PerimetreField.propType = {
	elementList: PropTypes.array,
	preferences: PropTypes.array,
	preferenceName: PropTypes.string,
	name : PropTypes.string
}

export default withStyles(styles)(PerimetreField)
