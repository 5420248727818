import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { getStyles } from 'isotope-client'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { getUserLanguage } from '../../../../fo/user/services/userSelectors'
import { ATTRIBUTE_DATA_MO_FIELD, REFERENCES_FI } from '../../../../fo/document/utils/documentConstants'
import { DEFAULT_LANGUAGE_ID, SVG_ICONS } from '../../../../../utils/constants'
import SvgIcon from '../../../../../components/icon/SvgIcon'
import { Draggable } from 'react-beautiful-dnd'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = theme => getStyles({
	container: {
		border: '1px solid #c8dcdc',
		borderRadius: 5,
		padding: 5,
		cursor: 'pointer',
		backgroundColor: '#FFFFFF'
	},
	dragContainer: {
		paddingBottom: 15
	},
	iconPublished: {
		color: theme.palette.secondary.main
	},
	iconNotPublished: {
		color: theme.palette.draft
	}
})

const NAME = 'NAME'

const getFieldTitleFromFilters = (field, idLanguage) => {
	let attributeField = field.attributeData.find(attr => (attr.idLanguage === idLanguage) && (attr.label === NAME))
	if (attributeField && attributeField.value) {
		return attributeField.value
	} else {
		attributeField = field.attributeData.find(attr => (attr.idLanguage === DEFAULT_LANGUAGE_ID) && (attr.label === NAME))
		if (attributeField && attributeField.value) {
			return attributeField.value
		}else{
			return ""
		}
	}
}

/**
 * Affichage d'une tuile section
 */
const DonneeBloc = ({ field, idLanguage, intl, index, openPopinEditionDonnee, deleteField, classes, fromFilters, publishedResearchField }) => {
	let fieldTitle
	let iconClass
	if (fromFilters) {
		fieldTitle = getFieldTitleFromFilters(field, idLanguage)
		iconClass = publishedResearchField ? classes.iconPublished : classes.iconNotPublished
	} else {
		fieldTitle = field.attributeData && ((field.attributeData[idLanguage] && field.attributeData[idLanguage][ATTRIBUTE_DATA_MO_FIELD.NAME] && field.attributeData[idLanguage][ATTRIBUTE_DATA_MO_FIELD.NAME].value)
			|| (field.attributeData[DEFAULT_LANGUAGE_ID] && field.attributeData[DEFAULT_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.NAME] && field.attributeData[DEFAULT_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.NAME].value))
		iconClass = field.published ? classes.iconPublished : classes.iconNotPublished
	}

	const handleDelete = e => {
		e.stopPropagation()
		return deleteField(field.id, fieldTitle)
	}

	return (
		<Draggable draggableId={field.id} index={index}>
			{provided =>
				<div ref={provided.innerRef} {...provided.draggableProps} className={classes.dragContainer}>
					<Grid
						item
						container
						className={classes.container}
						onClick={() => openPopinEditionDonnee(field)}
						alignItems="center"
					>
						<Grid item xs={1}>
							<SvgIcon path={SVG_ICONS.DATA} className={iconClass} fontSize="large" />
						</Grid>
						<Grid item xs={9} container direction="column">
							<Typography variant="h6">{fieldTitle || intl.formatMessage({ id: 'parametrage.field.defaultTitle' })}</Typography>
							<Typography variant="h6"><FormattedMessage id={`enums.field.${field.type}`} /> - {field.reference}</Typography>
						</Grid>
						<Grid item xs={1}>
							{!REFERENCES_FI.includes(field.reference) && <Grid container justify="center" alignContent="center">
								<IconButton onClick={handleDelete}>
									<DeleteIcon />
								</IconButton>
							</Grid>}
						</Grid>
						<Grid item xs={1}>
							<div {...provided.dragHandleProps}>
								<Grid container justify="center" alignContent="center">
									<SvgIcon path={SVG_ICONS.DRAG} className={field.published ? classes.iconPublished : classes.iconNotPublished} fontSize="large" />
								</Grid>
							</div>
						</Grid>
					</Grid>
				</div>
			}
		</Draggable>
	)
}

DonneeBloc.propTypes = {
	field: PropTypes.object,
	idLanguage: PropTypes.string,
	intl: intlShape,
	index: PropTypes.number,
	openPopinEditionDonnee: PropTypes.func,
	deleteField: PropTypes.func,
	classes: PropTypes.object
}

const mapStateToProps = state => ({
	idLanguage: getUserLanguage(state)
})

export default compose(
	connect(mapStateToProps),
	injectIntl,
	withStyles(styles)
)(DonneeBloc)
