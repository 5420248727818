import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { getStyles } from 'isotope-client'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const styles = theme => getStyles({
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	}
})

const LoadingCollapse = ({ sectionKey, isOpen, handleOpenSections, classes }) => {
	const [loadingCollapse, setLoadingCollapse] = React.useState(false)

	return (<IconButton
		disabled={loadingCollapse}
		className={classnames(classes.expand, {
			[classes.expandOpen]: isOpen
		})}
		onClick={() => {
			setLoadingCollapse(true)
			setTimeout(() => handleOpenSections(sectionKey)
				.then(setLoadingCollapse), 500)
		}}
	>
		{loadingCollapse ? <CircularProgress size={24} /> : <ExpandMoreIcon />}
	</IconButton>)
}

LoadingCollapse.propTypes = {
	sectionKey: PropTypes.string,
	isOpen: PropTypes.bool,
	handleOpenSections: PropTypes.func,
	classes: PropTypes.object
}

export default withStyles(styles)(LoadingCollapse)
