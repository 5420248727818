import {
	DISPATCH_SAVE_ACTION,
	DROP_RESEARCH_FIELD,
	GET_RESEARCH_FIELD_SUCCESS,
	GET_RESEARCH_SECTION_SUCCESS,
	POST_RESEARCH_FIELD_SUCCESS,
	POST_RESEARCH_SECTION_SUCCESS,
	REORDER_FIELDS,
	SET_SECTION_SELECTED,
	UNDO_CHANGES_FIELDS
} from './additionalFiltersActions'
import { reorder } from '../../../../utils/utils'

export const additionalFilters = (state = {sectionSelected: {}}, action) => {
	switch (action.type) {
		case GET_RESEARCH_SECTION_SUCCESS:
			return {
				...state,
				sections: action.sections,
				fields: [],
				lastSave: [],
				sectionSelected: {}
			}
		case SET_SECTION_SELECTED:
			return {
				...state,
				sectionSelected: action.payload
			}
		case POST_RESEARCH_SECTION_SUCCESS:
			return{
				...state,
				sections: state.sections.find(section => section.researchSection.id === action.payload.researchSection.id) ? state.sections.map(section => {
					if(section.researchSection.id === action.payload.researchSection.id){
						return action.payload
					}else{
						return section
					}
				}) : state.sections.concat(action.payload) ,
				sectionSelected: action.payload
			}
		case POST_RESEARCH_FIELD_SUCCESS:
			return {
				...state,
				fields: state.fields.find(field => field.id === action.payload.id) ? state.fields.map(field => {
					if(field.id === action.payload.id){
						return action.payload
					}else{
						return field
					}
				}) : state.fields.concat(action.payload)
			}
		case GET_RESEARCH_FIELD_SUCCESS:
			return {
				...state,
				fields: action.fields,
				lastSave: action.fields
			}
		case REORDER_FIELDS:
			return {
				...state,
				fields: reorder(state.fields, action.payload.source.index, action.payload.destination.index)
			}
		case DROP_RESEARCH_FIELD:
			return {
				...state,
				fields: state.fields.filter(field => field.id !== action.payload)
			}
		case UNDO_CHANGES_FIELDS: {
			return {
				...state,
				fields: state.lastSave
			}
		}
		case DISPATCH_SAVE_ACTION: {
			return {
				...state,
				lastSave: state.fields || undefined
			}
		}
		default:
			return state
	}
}