import React from 'react'
import PropTypes from 'prop-types'

const FolderIcon = ({ width, height }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 468.293 468.293">
			<path
				d="M443.317 413.9H24.976C11.182 413.9 0 402.718 0 388.924V79.368c0-13.794 11.182-24.976 24.976-24.976h118.096a24.977 24.977 0 0121.769 12.732l13.773 24.487a24.974 24.974 0 0021.769 12.732h242.936c13.794 0 24.976 11.182 24.976 24.976v259.605c-.002 13.794-11.184 24.976-24.978 24.976z"
				fill="#fcd462"
			/>
			<path
				d="M24.976 413.9h418.341c13.794 0 24.976-11.182 24.976-24.976V129.32c0-13.794-11.182-24.976-24.976-24.976H262.782a24.977 24.977 0 00-21.769 12.732l-13.773 24.487a24.974 24.974 0 01-21.769 12.732H24.976C11.182 154.295 0 165.477 0 179.271v209.653C0 402.718 11.182 413.9 24.976 413.9z"
				fill="#f6c358"
			/>
			<path
				d="M453.559 106.59c1.414 3.132 2.245 6.582 2.245 10.242v259.604c0 13.793-11.182 24.976-24.976 24.976H12.488c-3.66 0-7.11-.831-10.242-2.245 3.916 8.671 12.597 14.733 22.73 14.733h418.341c13.794 0 24.976-11.182 24.976-24.976V129.32c0-10.134-6.061-18.814-14.734-22.73z"
				fill="#fcd462"
			/>
		</svg>
	)
}

FolderIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string
}

FolderIcon.defaultProps = {
	height: '2rem',
	width: '2rem'
}


export default FolderIcon
