import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { useField } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-scroll'
import { DEFAULT_LANGUAGE_ID, GlOBAL_LANGUAGE_ID } from '../../../../../utils/constants'
import { ATTRIBUTE_DATA_MO_SECTION, DISPLAY_LEVEL_SUMMARY, SECTION_TYPE } from '../../utils/documentConstants'
import { getDuplicateSuffix, getFilteredSections, isSectionEmpty } from '../../utils/documentUtils'
import { SpyField } from '../SpyField'
import LoadingDuplication from '../LoadingDuplication'

const styles = theme => getStyles({
	header: {
		height: 80,
		borderBottom: `${theme.palette.empty} 2px solid`
	},
	divider: {
		backgroundColor: theme.palette.text.main,
		height: 1,
		marginTop: 5
	},
	mainIcon: {
		color: theme.palette.draft
	},
	summary: {
		padding: 20
	},
	icon: {
		fontSize: 15
	},
	iconCheck: {
		fontSize: 20
	},
	disabled: {
		color: 'grey'
	},
	title1: {
		fontSize: 18,
		fontWeight: 500,
		color: theme.palette.text.main,
		cursor: 'pointer'
	},
	title2: {
		fontSize: 16,
		fontWeight: 'bold',
		color: theme.palette.text.main,
		cursor: 'pointer'
	},
	title3: {
		fontSize: 14,
		fontWeight: 400,
		color: theme.palette.text.main,
		cursor: 'pointer'
	},
	marginTitle1: {
		marginTop: 32
	},
	marginTitle2: {
		marginTop: 24
	},
	marginTitle3: {
		marginTop: 12
	},
	iconDup: {
		marginBottom: '-10px',
		marginLeft: 16
	},
	colorIcon: {
		color: theme.palette.text.primary
	}
})

const InternalSection = ({
	section,
	level,
	parentKey,
	selectedLanguage,
	duplicatedSections,
	duplicatedNumber,
	handleOpenSections,
	duplicateSection,
	deleteSection,
	classes,
	consultation,
	sectionsWithContent
}) => {
	if (level > DISPLAY_LEVEL_SUMMARY || section.isDeleted) {
		return <React.Fragment />
	}

	// On boucle sur les sections pour afficher le titre et les sous-sections
	// On s'arrête au niveau 3
	const data = (section.attributeData && section.attributeData[selectedLanguage]) || section.attributeData[DEFAULT_LANGUAGE_ID]
	const sectionKey = `${parentKey}-${section.id}${getDuplicateSuffix(duplicatedNumber)}`
	const isRubrique = section.type === SECTION_TYPE.RUBRIQUE

	const [sectionsFilles, setSectionsFilles] = React.useState([])
	const addMeInSectionsFilles = (section) => {
		const index = sectionsFilles.indexOf(section)
		if (index < 0) {
			setSectionsFilles([...sectionsFilles, section])
		}
	}
	const removeMeInSectionsFilles = (section) => {
		const index = sectionsFilles.indexOf(section)
		if (index > -1) {
			setSectionsFilles([
				...sectionsFilles.slice(0, index),
				...sectionsFilles.slice(index + 1)
			])
		}
	}

	// La section est terminée si (écoute du form) :
	// - l'utilisateur l'a indiqué
	// - si le niveau 1 n'a que des enfants terminés et affichés
	let isFinished = false
	if (level < DISPLAY_LEVEL_SUMMARY) {
		const fieldStatus = useField('status')
		if (level === 1) {
			// Si je suis en niveau 1, je regarde le statut de tous mes enfants
			isFinished |= section.sections
				.filter(sectionEnfant => sectionsFilles.find(sectionFille => sectionFille.id === sectionEnfant.id && sectionFille.position && sectionEnfant.position))
				.every(sectionEnfant => {
					const idStatusSection = `${sectionEnfant.idMoParentSection}-${duplicatedNumber ? `dup#${duplicatedNumber}-` : ''}${sectionEnfant.position}-${sectionEnfant.id}`
					if (fieldStatus.input.value[idStatusSection]) {
						return fieldStatus.input.value[idStatusSection].finished
					} else {
						return false
					}
				})
		} else {
			// Sinon, je regarde seulement mon propre statut
			const idStatusSection = `${section.idMoParentSection}-${duplicatedNumber ? `dup#${duplicatedNumber}-` : ''}${section.position}-${section.id}`
			if (fieldStatus.input.value[idStatusSection]) {
				isFinished |= fieldStatus.input.value[idStatusSection].finished
			}
		}
	}

	//Code de l'alias de rubrique
	const titleCode = section.attributeData && (section.attributeData[GlOBAL_LANGUAGE_ID] || {})[ATTRIBUTE_DATA_MO_SECTION.TITLE_CODE]

	const aliasField = titleCode ? useField(`fields.${titleCode.value}${getDuplicateSuffix(duplicatedNumber)}`) : {}

	const [alias, setAlias] = React.useState('')
	React.useEffect(() => {
		if (level === 1 && titleCode) {
			setAlias(aliasField.input.value.value)
		}
	}, [aliasField])

	return (<Grid key={`sectionN${level}-${section.id}`} container item xs={12}>
		{(!consultation || (consultation && !isSectionEmpty(sectionsWithContent, section.id, duplicatedNumber))) && <>
			<Grid container item xs={12} direction="row-reverse" alignItems="flex-end" className={classes[`marginTitle${level}`]}>
				{(!consultation && isRubrique) && <Grid item xs={2}>
					{duplicatedNumber ?
						<IconButton onClick={() => deleteSection(section.id, duplicatedNumber)}
						            className={classes.iconDup}><DeleteForeverOutlinedIcon
							className={classes.colorIcon} /></IconButton>
						: <LoadingDuplication duplicateSection={() => duplicateSection(section)} />
					}
				</Grid>}
				<Grid item xs={(level === DISPLAY_LEVEL_SUMMARY ? 10 : 11) - ((isRubrique && !consultation) ? 2 : 0)}>
					<Link to={sectionKey} spy smooth duration={250} containerId="docPage" offset={-400}
					      onClick={() => handleOpenSections(sectionKey)}>
						<Typography className={classes[`title${level}`]}>
							{data && data[ATTRIBUTE_DATA_MO_SECTION.TITLE] ? data[ATTRIBUTE_DATA_MO_SECTION.TITLE].value :
								<FormattedMessage id="document.section.defaultTitle" />} {alias ? `- ${alias}` : duplicatedNumber && level === 1 ? `(${duplicatedNumber})` : ''}
						</Typography>
					</Link>
				</Grid>

				{(!consultation && level < DISPLAY_LEVEL_SUMMARY) && <Grid item xs={1}>
					{isFinished ? <CheckOutlinedIcon color="secondary" className={classes.iconCheck} /> :
						<RadioButtonUncheckedOutlinedIcon className={classes.icon} />}
				</Grid>}
			</Grid>
			{level === 1 && <Grid item xs={12}><Divider classes={{ root: classes.divider }} /></Grid>}
		</>}
		{section.sections && <Grid container item xs={12}>
			{getFilteredSections(section.sections).map((sectionChild, index) => (<DocumentSummarySection
					key={`${parentKey}-${sectionChild.id}-${index}`}
					section={sectionChild}
					level={level + 1}
					parentKey={sectionKey}
					parentId={section.id}
					selectedLanguage={selectedLanguage}
					duplicatedSections={duplicatedSections}
					handleOpenSections={handleOpenSections}
					duplicatedNumber={duplicatedNumber}
					duplicateSection={duplicateSection}
					deleteSection={deleteSection}
					consultation={consultation}
					sectionsWithContent={sectionsWithContent}
					addMe={addMeInSectionsFilles}
					removeMe={removeMeInSectionsFilles}
				/>
			))}
		</Grid>}
		{level === 1 && !duplicatedNumber && duplicatedSections && duplicatedSections[section.id] && duplicatedSections[section.id].map(sectionDup => {
			return (
				<DocumentSummarySection
					key={`${parentKey}-${section.id}-${sectionDup.position}`}
					section={{ ...section, isDeleted: sectionDup.deleted }}
					level={1}
					parentKey=""
					parentId={section.id}
					selectedLanguage={selectedLanguage}
					duplicatedSections={duplicatedSections}
					handleOpenSections={handleOpenSections}
					duplicatedNumber={sectionDup.duplicatedNumber}
					duplicateSection={duplicateSection}
					deleteSection={deleteSection}
					consultation={consultation}
					sectionsWithContent={sectionsWithContent}
				/>
			);
		})}
	</Grid>)
}

const DocumentSection = (props) => <SpyField
	part={props.section}
	duplicatedNumber={props.duplicatedNumber}
	addMe={props.addMe}
	removeMe={props.removeMe}
	level={props.level}
>
	<InternalSection {...props} />
</SpyField>

DocumentSection.propTypes = {
	section: PropTypes.object,
	level: PropTypes.number,
	parentKey: PropTypes.string,
	parentId: PropTypes.string,
	selectedLanguage: PropTypes.string,
	sectionsStatus: PropTypes.object,
	duplicatedNumber: PropTypes.number,
	duplicatedSections: PropTypes.object,
	classes: PropTypes.object,
	handleOpenSections: PropTypes.func,
	duplicateSection: PropTypes.func,
	deleteSection: PropTypes.func,
	consultation: PropTypes.bool,
	sectionsWithContent: PropTypes.object
}

DocumentSection.defaultProps = {
	section: {},
	level: 1,
	parentKey: '',
	parentId: undefined,
	duplicatedNumber: undefined
}

const DocumentSummarySection = withStyles(styles)(DocumentSection)

export default DocumentSummarySection
