import ListItem from '@material-ui/core/ListItem'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import React from 'react'
import { getStyles } from 'isotope-client'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import TenderBoxConnectedMenu from './TenderBoxInternalMenu'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as menuActions from 'isotope-client/components/menu/services/menuActions'

const BLOC_LOGO_HEIGHT = 64

const styles = () => getStyles({
	logo: {
		marginLeft: 0,
		marginRight: 10,
		height: 50,
		width: 100
	},
	blocLogo: {
		height: BLOC_LOGO_HEIGHT,
		paddingLeft: 24,
		paddingRight: 24
	},
	title: {
		marginBottom: 0
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20
	}
})

const TendexBoxMenu = ({ classes, closeMenu, ...otherProps }) => {
	React.useEffect(() => {
		return () => closeMenu(otherProps.code)
	}, [otherProps.code])

	return (

		<React.Fragment>
			<ListItem className={classes.blocLogo}>
				{/*Menu Burger*/}
				<IconButton
					className={classes.menuButton}
					color="inherit"
					onClick={() => closeMenu(otherProps.code)}
				>
					<MenuIcon />
				</IconButton>

				{/*Logo tender Box*/}
				<img src="/img/tenderBox.png" alt="Tender Box" height={55} width={'auto'} />
			</ListItem>
			<TenderBoxConnectedMenu {...otherProps} />
		</React.Fragment>
	)
}

TendexBoxMenu.propTypes = {
	/**
	 * Code du menu à afficher (récupéré sur un serveur isotope)
	 */
	code: PropTypes.string.isRequired,
	/**
	 * Menu ouvert par défaut ou non
	 */
	initOpened: PropTypes.bool,
	/**
	 * Location utilisée pour récupérer le pathname
	 */
	location: PropTypes.object.isRequired,
	/**
	 * Fonction appelée lorsqu'on clique sur un élément (en plus de la sélection du menu qui change l'url)
	 */
	onItemClick: PropTypes.func
}

const actions = {
	closeMenu: menuActions.closeMenu
}

export default compose(
	connect(undefined, actions),
	withStyles(styles)
)(TendexBoxMenu)
