import { createSelector } from 'reselect'

const getLocalState = state => state.valueList

// Récupération des valeurs des listes
export const getLists = createSelector(
	getLocalState,
	valueList => (Object.values(valueList.listsById || {}) || [])
		.sort((item1, item2) => item1.name.localeCompare(item2.name))
)


// Récupération de toutes les valueList
export const getAllItemsByListCode = createSelector(
	getLocalState,
	valueList => valueList.itemsByListCode
)
