import { Paper, Typography, Grid, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useField } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { DEFAULT_LANGUAGE_ID } from '../../../../utils/constants'
import { getContributeur } from '../../user/services/usersApi'

const styles = () => getStyles({
	paperSummaryWorkflow: {
		padding: 24,
		maxHeight: 46,
		borderTop: '1px solid #55555a'
	},
	consultationContainer: {
		paddingBottom: 3
	}
})

const BlockWorflow = ({ document, classes, zoneList }) => {

	const contributorDocument = useField('contributor')
	const zoneDocument = useField('zone')
	const selectedLanguageField = useField('selectedLanguage')
	const selectedLanguage = selectedLanguageField.input.value

	const [contributorList, setContributorList] = React.useState([])

	React.useEffect(() => {
		var zoneDocu = ""
		document.attributeData.forEach(attribute => {
			if(attribute.label === "ZONE"){
				zoneDocu = attribute.value
			}
		})
		if (zoneDocu) {
			getContributeur(contributorDocument.input.value)
				.then(list => setContributorList(list))
				.catch(() => setContributorList([]))
		}
	}, [])

	return <Paper elevation={0} className={classes.paperSummaryWorkflow}>
		<Grid item container xs={12} direction="row">
			<Grid item container xs={8} className={classes.consultationContainer}>
				<Grid item xs={4}>
					<Typography variant="caption"><FormattedMessage id="document.entete.contributor" /> :</Typography>
				</Grid>
				<Grid item container xs={8}>
					{contributorList.filter(contributor => contributor.email === contributorDocument.input.value).map(contributor => {
						return <Typography key={`select-${contributor.email}`} variant="caption">
							{contributor.firstname} {contributor.lastname}
						</Typography>
					})}
				</Grid>
			</Grid>
			<Grid item container xs={4} className={classes.consultationContainer}>
				<Grid item xs={7}>
					<Typography variant="caption"><FormattedMessage id="document.card.modified" /></Typography>
				</Grid>
				<Grid item container xs={5}>
					{document.dateModification && <>
						{selectedLanguage === DEFAULT_LANGUAGE_ID ?
							<Typography variant="caption">{moment(document.dateModification).format('MM/DD/YYYY')}</Typography> :
							<Typography variant="caption">{moment(document.dateModification).format('DD/MM/YYYY')}</Typography>}
					</>}
				</Grid>
			</Grid>
			<Grid item container xs={8} className={classes.consultationContainer}>
				<Grid item xs={4}>
					<Typography variant="caption"><FormattedMessage id="document.entete.zone" /> :</Typography>
				</Grid>
				<Grid item container xs={8}>
					{zoneList && zoneList.filter(zoneElement => zoneElement.code === zoneDocument.input.value).map(zoneElement =>
						<Typography key={`ZONE-${zoneElement.code}`} variant="caption">{zoneElement.label}</Typography>
					)}
				</Grid>
			</Grid>
			<Grid item container xs={4} className={classes.consultationContainer}>
				<Grid item xs={7}>
					<Typography variant="caption"><FormattedMessage id="document.card.published" /></Typography>
				</Grid>
				<Grid item container xs={5}>
					{(document.workflow && document.workflow.datePublished) && <>
						{selectedLanguage === DEFAULT_LANGUAGE_ID ?
							<Typography variant="caption">{moment(document.workflow.datePublished).format('MM/DD/YYYY')}</Typography> :
							<Typography variant="caption">{moment(document.workflow.datePublished).format('DD/MM/YYYY')}</Typography>}
					</>}
				</Grid>
			</Grid>
		</Grid>
	</Paper>
}

BlockWorflow.propTypes = {
	document: PropTypes.object,
	classes: PropTypes.object,
	zoneList: PropTypes.array
}

export default withStyles(styles)(BlockWorflow)
