import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import CancelIcon from '@material-ui/icons/Cancel'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'redux'
import { colors, FIELD_SEARCH_ADMIN, FIELDS_ADMIN_CORRESP, TIMELINE } from '../../../../utils/constants'
import moment from 'moment'
import classnames from 'classnames'
import { loadFilterForEdition } from '../../../../utils/utils'

const styles = theme => getStyles({
	card: {
		border: `${theme.palette.border} 1px solid`,
		padding: 5,
		borderRadius: 5,
		position: 'relative',
		cursor: 'pointer'
	},
	cardSelected: {
		borderColor: colors.selectedText100Percent
	},
	marginTop: {
		marginTop: 10
	},
	deleteIcon: {
		position: 'absolute',
		top: 5,
		right: 5,
		cursor: 'pointer',
		fontSize: 20
	},
	filterPath: {
		marginRight: 24,
		wordBreak: 'break-word'
	},
	text: {
		color: colors.text,
		fontSize: 14,
		fontWeight: 500
	}
})

const AdminFiltersApplied = ({ adminCorrespFilters, initialValues, classes, intl, usersList, removeAdminFilters, change, inPopin = undefined, setAdminPopinState }) => {
	const [indexSelected, setIndexSelected] = React.useState(undefined)

	React.useEffect(() => {
		setIndexSelected(undefined)
	}, [adminCorrespFilters])

	//Permet de récupérer ou de générer le label pour une value ne comprenant qu'un code (initValues)
	//Cas concernés contributor & status (initialValues - RechercheDocumentPage)
	const getLabel = (filter) => {
		return ''.concat(filter.value.map((val, index) => {
			if (!val.label) {
				//initialValue : status
				if (filter.typeFilter === FIELDS_ADMIN_CORRESP.STATUS.name) {
					if (index !== 0) {
						return ` ${intl.formatMessage({ id: `enums.documentStatus.${val.code}` })} `
					}
					return `${intl.formatMessage({ id: `enums.documentStatus.${val.code}` })} `
				}

				//initialValue : contributor
				let contributorMail
				if (initialValues.fieldsAdmin && initialValues.fieldsAdmin.contributor && initialValues.fieldsAdmin.contributor.length > 0) {
					contributorMail = initialValues.fieldsAdmin.contributor[0].code
				} else if (filter && filter.typeFilter === FIELD_SEARCH_ADMIN.CONTRIBUTOR && filter.value && filter.value.length > 0) {
					contributorMail = filter.value[0].code
				}

				if (index !== 0) {
					const actualUser = usersList.find(opt => opt.email === contributorMail)
					if (!actualUser) {
						return ''
					}
					return `${actualUser.lastname} ${actualUser.firstname}`
				}
				const actualUser = usersList.find(opt => opt.email === contributorMail)
				if (!actualUser) {
					return ''
				}
				return `${actualUser.lastname} ${actualUser.firstname} `
			} else {
				return index !== 0 ? ` ${val.label} ` : `${val.label} `
			}
		})).replaceAll(',', ';')
	}

	return (
		<Grid item container xs={inPopin ? 9 : 12}>
			{adminCorrespFilters.map((filter, index) => {
					return (
						<Grid key={`rechAv-${index}`} item container xs={12}
						      className={(indexSelected === index)
							      ? index === 0
								      ? classnames(classes.card, classes.cardSelected)
								      : classnames(classes.card, classes.cardSelected, classes.marginTop)
							      : index === 0
								      ? classes.card
								      : classnames(classes.card, classes.marginTop)
						      }
						      onClick={() => {
							      setIndexSelected(index)
							      if (!inPopin) {
								      setAdminPopinState({
									      filter: filter, open: true
								      })
							      } else {
								      loadFilterForEdition(change, filter)
							      }
						      }}
						>
							<CancelIcon color="primary" onClick={(e) => {
								removeAdminFilters(filter.typeFilter)
								indexSelected === index && loadFilterForEdition(change)
								setIndexSelected(undefined)
								e.stopPropagation()
							}} className={classes.deleteIcon} />
							<Grid item container xs={12} className={classes.filterPath}>
								<Typography className={classes.text}>
									{intl.formatMessage({ id: `document.search.adminCorrespondantPopin.cardTitle.${filter.typeFilter}` })}
								</Typography>
							</Grid>
							<Grid item container xs={12} className={classes.text}>
								<Typography className={classes.text}>

									{filter.typeFilter.startsWith('date')
										? filter.value[0] === TIMELINE.BETWEEN
											? <FormattedMessage id={'document.search.adminCorrespondantPopin.filterDateBetween'}
											                    values={{
												                    dateDebut: moment(filter.value[1]).format('DD/MM/YYYY'),
												                    dateFin: moment(filter.value[2]).format('DD/MM/YYYY')
											                    }}
											/>
											: `${intl.formatMessage({ id: `document.search.adminCorrespondantPopin.radioGroup.${filter.value[0].toLowerCase()}` })} ${moment(filter.value[1]).format('DD/MM/YYYY')}`
										: getLabel(filter)}
								</Typography>
							</Grid>
						</Grid>
					)
				}
			)}
		</Grid>
	)
}

export default compose(
	withStyles(styles),
	injectIntl
)(AdminFiltersApplied)
