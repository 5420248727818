import React from 'react'
import { withStyles } from '@material-ui/core'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'


const styles = (theme) => getStyles({
	chipsFilter: {
		display: 'inline-flex',
		maxWidth: 250
	},
	fieldsAdvance: {
		backgroundColor: theme.palette.border,
		color: 'black'
	},
	tooltipsCss: {
		maxWidth: 500,
		fontSize: 14
	},
})

const OverflowTypography = ({ className, variant, children, classes }) => {
	const [overflowed, setOverflowed] = React.useState(false)
	const textElement = React.createRef()

	React.useEffect(() => {
		setOverflowed(textElement.current.scrollWidth > textElement.current.clientWidth)
	}, [])

	return <Tooltip
		title={children}
		disableHoverListener={!overflowed}
		classes={{ tooltip: classes.tooltipsCss }}
	>
		<Typography
			className={className}
			variant={variant}
			component="div"
		>
			<div
				ref={textElement}
				style={{
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis'
				}}
			>
				{children}
			</div>
		</Typography>
	</Tooltip>
}

OverflowTypography.propTypes = {
	className: PropTypes.string,
	variant: PropTypes.string,
	children: PropTypes.node
}

OverflowTypography.defaultProps = {
	className: '',
	variant: 'body1'
}

export default compose(
	withStyles(styles)
)(OverflowTypography)
