import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'recompose'
import { getStyles } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'

const styles = () => getStyles({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		color: '#c0c0c0',
		width: '100%',
		paddingTop: 25
	},
	image: {
		display: 'block',
		height: '25vw',
		color: '#c0c0c0',
		marginBottom: 10
	},
	message: {
		fontSize: '1.5em'
	}
})

const EmptyResult = ({ classes, message, image, className, imgClassName }) => {
	return (
		<div className={classnames(classes.wrapper, className)}>
			<img src={image} alt="empty" className={classnames(classes.image, imgClassName)} />
			<span className={classes.message}>{message}</span>
		</div>
	)
}

EmptyResult.defaultProps = {
	image: '/img/empty_state.png',
	message: <FormattedMessage id="global.no_results" />,
	className: '',
	imgClassName: ''
}

EmptyResult.propTypes = {
	image: PropTypes.string,
	classes: PropTypes.object.isRequired,
	message: PropTypes.node.isRequired,
	className: PropTypes.string,
	imgClassName: PropTypes.string
}

export default compose(
	withStyles(styles),
	withTheme
)(EmptyResult)
