import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import { Field, formValueSelector, getFormValues, reduxForm } from 'redux-form'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import { Grid, MenuItem } from '@material-ui/core'
import Popin from '../../../../../components/Popin'
import Select from '../../../../../components/form/Select'
import { colors, FIELDS_ADMIN_CORRESP, FIELDS_ADMIN_POPIN, PROFILS, TIMELINE } from '../../../../../utils/constants'
import { connect } from 'react-redux'
import Radio from '../../../../../components/form/Radio'
import { DOCUMENT_STATUS } from '../../utils/documentConstants'
import { loadFilterForEdition, sortObjectByLabel } from '../../../../../utils/utils'
import AutocompleteMulti from '../../../../../components/form/AutocompleteMulti'
import { normalizeDate } from '../../utils/documentUtils'
import Input from '../../../../../components/form/Input'
import AdminFiltersApplied from '../AdminFiltersApplied'
import _ from 'lodash'
import * as moment from 'moment'

const styles = () => getStyles({
	paddingText: {
		marginBottom: 24
	},
	popinResize: {
		maxWidth: 900
	},
	content: {
		minHeight: 700,
		fontFamily: 'Roboto'
	},
	detailsFiltersDate: {
		border: '2px solid #88888a80',
		borderRadius: 5,
		padding: 10
	},
	radioGroup: {
		marginTop: -5
	},
	addTitle: {
		fontSize: 14,
		color: colors.placeholder,
		marginBottom: 10
	},
	dateField: {
		marginLeft: -10
	},
	addButton: {
		width: 35,
		height: 35
	},
	addButtonFAB: {
		width: 43,
		height: 43,
		marginLeft: '89%'
	},
	text: {
		color: colors.text,
		fontSize: 14
	},
	leftSidePopin: {
		maxHeight: 'calc(100vh - 40vh)',
		overflowY: 'auto',
		overflowX: 'hidden',
		marginTop: 10
	},
	popin: {
		overflow: 'hidden'
	},
	radio: {
		width: '100%',
		'& > div': {
			'& > label': {
				marginTop: 5,
				'& > span': {
					fontSize: 14,
					color: colors.text
				}
			}
		}
	},
	emptyFilters: {
		fontSize: 14,
		color: colors.placeholder
	},
	selectWithoutPadding: {
		padding: 'inherit'
	},
	formInputSelect: {
		'& > div': {
			padding: 0,
			'& div': {
				'& div': {
					fontSize: 14
				}
			}
		},
		'& label': {
			fontSize: 14
		}
	},
	formInputAutocomplete: {
		'& label': {
			fontSize: 14
		}
	},
	button: {
		width: 196,
		heigh: 36
	},
	cancelLink: {
		color: colors.text,
		fontSize: 14,
		cursor: 'pointer',
		marginRight: 20
	},
	submitLink: {
		color: colors.primary,
		textAlign: 'center',
		fontSize: 14,
		cursor: 'pointer'
	}
})

const ADMIN_POPIN_FORM = 'ADMIN_CORRESPONDANT_FILTERS'

const radioOptions = [
	{
		value: TIMELINE.AFTER,
		label: <FormattedMessage id={'document.search.adminCorrespondantPopin.radioGroup.after'} />
	},
	{
		value: TIMELINE.AT,
		label: <FormattedMessage id={'document.search.adminCorrespondantPopin.radioGroup.at'} />
	},
	{
		value: TIMELINE.BEFORE,
		label: <FormattedMessage id={'document.search.adminCorrespondantPopin.radioGroup.before'} />
	},
	{
		value: TIMELINE.BETWEEN,
		label: <FormattedMessage id={'document.search.adminCorrespondantPopin.radioGroup.between'} />
	}
]

const validate = (values) => {
	const errors = {}
	if (values.sectionField) {
		if (values.sectionField.startsWith('date')) {
			if (!values.choixTimeline) {
				return { [FIELDS_ADMIN_POPIN.CHOIX_TIMELINE]: { id: 'global.errors.obligatoire' } }
			}
			if (!values.champDate1) {
				return { [FIELDS_ADMIN_POPIN.CHAMP_DATE_1]: { id: 'global.errors.obligatoire' } }
			}
			if (values.choixTimeline === TIMELINE.BETWEEN) {
				if (values.champDate2) {
					if (moment(values.champDate2).isBefore(moment(values.champDate1))) {
						return { [FIELDS_ADMIN_POPIN.CHAMP_DATE_2]: { id: 'document.search.adminCorrespondantPopin.errors.date2Beforedate1' } }
					}
				} else {
					return { [FIELDS_ADMIN_POPIN.CHAMP_DATE_2]: { id: 'global.errors.obligatoire' } }
				}
			}
		}
		let valuesInForm = values[values.sectionField]
		if ((valuesInForm && valuesInForm.length === 0) || (!valuesInForm)) {
			return { [values.sectionField]: { id: 'global.errors.obligatoire' } }
		}
	}
	return errors
}

const AdminCorrespondantFiltersPopin = ({
	onClose, onSubmit,
	handleSubmit, classes,
	isCorrespondant, adminCorrespFilters,
	setAdminCorrespFilter,
	sectionField, intl, initValues,
	usersList, change, choixTimeline,
	removeAdminFilters,
	adminPopinState, reset
}) => {

	const actions = [
		<Button color="inherit" variant="outlined"
		        key="fermer" onClick={onClose}
		        className={classes.button}>
			<FormattedMessage id="global.buttons.close" />
		</Button>,

		<Button key="valider" color="primary"
		        variant="contained" type="submit"
		        onClick={handleSubmit(onSubmit)}
		        className={classes.button}
		        disabled={!!sectionField}
		>
			<FormattedMessage id="global.buttons.validate" />
		</Button>
	]

	const formatStatus = () => sortObjectByLabel(Object.keys(DOCUMENT_STATUS).filter(status => !isCorrespondant || DOCUMENT_STATUS.MASQUE !== status).map((key) => {
		return { label: intl.formatMessage({ id: `enums.documentStatus.${key}` }), code: `${key}` }
	}))
	const getStatus = () => Promise.resolve(formatStatus())

	const formatContributors = () => usersList && usersList.map((user) => {
		return { label: `${user.lastname} ${user.firstname}`, code: user.email }
	})
	const getContributors = () => Promise.resolve(formatContributors())

	const formatPublishers = () => usersList && usersList.filter(user => user.roles && user.roles.length > 0 && user.roles[0] && user.roles[0].id === PROFILS.ADMIN.id.toString()).map((user) => {
		return { label: `${user.lastname} ${user.firstname}`, code: user.id }
	})
	const getPublishers = () => Promise.resolve(formatPublishers())

	const formatEditors = () => usersList && usersList.map((user) => {
		return { label: `${user.lastname} ${user.firstname}`, code: user.id }
	})
	const getEditors = () => Promise.resolve(formatEditors())

	const getUsersHide = () => Promise.resolve(getPublishers())

	React.useEffect(() => {
		if (adminPopinState.filter) {
			loadFilterForEdition(change, adminPopinState.filter)
		}
	}, [adminPopinState.filter])

	const addFilter = (formValues) => {
		if (formValues.sectionField.startsWith('date')) {
			let valuesTab
			if (formValues.choixTimeline === TIMELINE.BETWEEN) {
				valuesTab = [formValues.choixTimeline, formValues.champDate1, formValues.champDate2]
			} else {
				valuesTab = [formValues.choixTimeline, formValues.champDate1]
			}

			if (!valuesTab.some(val => !val)) {
				setAdminCorrespFilter([
					{ typeFilter: sectionField, value: [...valuesTab] },
					...adminCorrespFilters.filter(filter => filter.typeFilter !== sectionField)
				])
			}
		} else {
			let valuesInForm = formValues[formValues.sectionField]
			if (valuesInForm && valuesInForm.length > 0) {
				setAdminCorrespFilter([
					{ typeFilter: sectionField, value: _.uniq([...valuesInForm]) },
					...adminCorrespFilters.filter(filter => filter.typeFilter !== sectionField)
				])
			}
		}
		reset()
	}

	const renderBtnFilters = () => (<Grid container justify="flex-end">
		<Typography
			variant="body1"
			className={classes.cancelLink}
			component="span"
			onClick={() => change(FIELDS_ADMIN_POPIN.SECTION_FIELD, null)}
		>
			<FormattedMessage id="global.buttons.annuler" />
		</Typography>
		<Typography
			variant="body1"
			className={classes.submitLink}
			component="span"
			onClick={() => handleSubmit(addFilter)()}
		>
			<FormattedMessage id="global.buttons.save_filter" />
		</Typography>
	</Grid>)

	return (
		<form onSubmit={onSubmit} className={classes.popin}>
			<Popin
				fromSearch
				open={adminPopinState.open}
				onClose={onClose}
				actions={actions}
				dialogClasses={{ paper: classes.popinResize }}
				title={isCorrespondant
					? <FormattedMessage id="document.search.correspondantPopin.title" />
					: <FormattedMessage id="document.search.adminPopin.title" />
				}
				content={
					<Grid container className={classes.content} alignContent={'flex-start'}>
						<Grid item>
							<Typography variant="body2" className={classes.paddingText}>
								{isCorrespondant
									? <FormattedMessage id="document.search.correspondantPopin.description" />
									: <FormattedMessage id="document.search.adminPopin.description" />
								}
							</Typography>
						</Grid>
						<Grid container item xs={6} alignContent={'flex-start'}>
							<Grid item xs={12}>
								<b className={classes.text}><FormattedMessage id="document.search.adminCorrespondantPopin.appliedFilters" /></b>
							</Grid>
							<Grid item xs={12} className={classes.leftSidePopin}>
								{adminCorrespFilters && adminCorrespFilters.length > 0
									? <AdminFiltersApplied initialValues={initValues} adminCorrespFilters={adminCorrespFilters} inPopin usersList={usersList} removeAdminFilters={removeAdminFilters} change={change} />
									: <span className={classes.emptyFilters}><FormattedMessage id="document.search.adminCorrespondantPopin.emptyStateFilters" /></span>
								}
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<form onSubmit={(event) => {
								handleSubmit(addFilter)(event)
								event.stopPropagation()
							}}>
								<Grid item xs={12} className={classes.formInputSelect}>
									<Field
										containerClassName={classes.selectWithoutPadding}
										name={FIELDS_ADMIN_POPIN.SECTION_FIELD}
										label={isCorrespondant
											? <FormattedMessage id="document.search.correspondantPopin.fields.correspondantFilter" />
											: <FormattedMessage id="document.search.adminPopin.fields.adminFilter" />
										}
										component={Select}
										onChange={() => reset()}
									>
										{isCorrespondant
											? Object.values(FIELDS_ADMIN_CORRESP).filter(field => field.permission === 1).map((field) =>
												<MenuItem key={field.name} value={field.name}>
													<FormattedMessage id={`document.search.adminCorrespondantPopin.fields.${field.name}`} />
												</MenuItem>
											)

											: Object.values(FIELDS_ADMIN_CORRESP).map((field) =>
												<MenuItem key={field.name} value={field.name}>
													<FormattedMessage id={`document.search.adminCorrespondantPopin.fields.${field.name}`} />
												</MenuItem>)
										}
									</Field>
								</Grid>

								{/* Filtre type date */}
								{(sectionField && sectionField.startsWith('date')) &&
								<Grid container className={classes.detailsFiltersDate}>
									<Grid xs={12} className={classes.addTitle}>
										<FormattedMessage id={`document.search.adminCorrespondantPopin.fields.${sectionField}`} />
									</Grid>

									<Grid xs={12} className={classes.radioGroup}>
										<Field name={FIELDS_ADMIN_POPIN.CHOIX_TIMELINE} component={Radio}
										       choices={radioOptions}
										       column
										       style={{ justifyContent: 'space-between' }}
										       containerClass={classes.radio}
										       onChange={() => {
											       change(FIELDS_ADMIN_POPIN.CHAMP_DATE_1, null)
											       change(FIELDS_ADMIN_POPIN.CHAMP_DATE_2, null)
										       }}
										/>
									</Grid>

									<Grid container xs={12} className={classes.dateField}>
										<Grid item xs={6}>
											<Field
												name={FIELDS_ADMIN_POPIN.CHAMP_DATE_1}
												label=""
												component={Input}
												type="date"
												InputLabelProps={{
													shrink: true
												}}
												format={normalizeDate}
											/>
										</Grid>
										{choixTimeline && choixTimeline === TIMELINE.BETWEEN &&
										<Grid item xs={6}>
											<Field
												name={FIELDS_ADMIN_POPIN.CHAMP_DATE_2}
												label=""
												component={Input}
												type="date"
												InputLabelProps={{
													shrink: true
												}}
												format={normalizeDate}
											/>
										</Grid>
										}
									</Grid>
									{renderBtnFilters()}
								</Grid>
								}

								<Grid item xs={12} className={classes.formInputAutocomplete}>
									{sectionField === FIELDS_ADMIN_CORRESP.STATUS.name &&
									<Field
										component={AutocompleteMulti}
										name="status"
										label={<FormattedMessage id={`document.search.adminCorrespondantPopin.fields.${sectionField}`} />}
										getOptions={getStatus}
										staticOptions={formatStatus()}
										forceGetOptions
										containerClassName={classes.selectWithoutPadding}
									/>}

									{sectionField === FIELDS_ADMIN_CORRESP.CONTRIBUTOR.name &&
									<Field
										component={AutocompleteMulti}
										name="contributor"
										label={<FormattedMessage id={`document.search.adminCorrespondantPopin.fields.${sectionField}`} />}
										getOptions={getContributors}
										staticOptions={formatContributors()}
										forceGetOptions
										containerClassName={classes.selectWithoutPadding}
									/>}

									{sectionField === FIELDS_ADMIN_CORRESP.ADMIN_PUBLICATION.name &&
									<Field
										component={AutocompleteMulti}
										name="publierPar"
										label={<FormattedMessage id={`document.search.adminCorrespondantPopin.fields.${sectionField}`} />}
										getOptions={getPublishers}
										forceGetOptions
										containerClassName={classes.selectWithoutPadding}
									/>}

									{sectionField === FIELDS_ADMIN_CORRESP.USER_LAST_MODIF.name &&
									<Field
										component={AutocompleteMulti}
										name="modifierPar"
										label={<FormattedMessage id={`document.search.adminCorrespondantPopin.fields.${sectionField}`} />}
										getOptions={getEditors}
										forceGetOptions
										containerClassName={classes.selectWithoutPadding}
									/>}

									{sectionField === FIELDS_ADMIN_CORRESP.ADMIN_DELETE.name &&
									<Field
										component={AutocompleteMulti}
										name="masquerPar"
										label={<FormattedMessage id={`document.search.adminCorrespondantPopin.fields.${sectionField}`} />}
										getOptions={getUsersHide}
										forceGetOptions
										containerClassName={classes.selectWithoutPadding}
									/>}
								</Grid>

								{(sectionField && !sectionField.startsWith('date')) && renderBtnFilters()}
							</form>
						</Grid>
					</Grid>
				}
			/>
		</form>
	)
}

AdminCorrespondantFiltersPopin.propTypes = {
	open: PropTypes.bool,
	classes: PropTypes.object,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	change: PropTypes.func,
	handleSubmit: PropTypes.func,
	isCorrespondant: PropTypes.bool,
	adminCorrespFilters: PropTypes.object,
	setAdminCorrespFilter: PropTypes.func,
	sectionField: PropTypes.string,
	initValues: PropTypes.object,
	usersList: PropTypes.array,
	choixTimeline: PropTypes.string,
	formValues: PropTypes.object,
	removeAdminFilters: PropTypes.func,
	adminPopinState: PropTypes.object,
	reset: PropTypes.func
}

const selector = formValueSelector(ADMIN_POPIN_FORM)

const mapStateToProps = (state) => {
	const formValues = getFormValues(ADMIN_POPIN_FORM)(state)
	return ({
		sectionField: selector(state, FIELDS_ADMIN_POPIN.SECTION_FIELD),
		choixTimeline: selector(state, FIELDS_ADMIN_POPIN.CHOIX_TIMELINE),
		formValues: formValues,
		initialValues: {
			choixTimeline: TIMELINE.AFTER
		}
	})
}

export default compose(
	injectIntl,
	connect(mapStateToProps),
	reduxForm({
		form: ADMIN_POPIN_FORM,
		validate
	}),
	withStyles(styles)
)(AdminCorrespondantFiltersPopin)
