import React from 'react'
import { compose } from 'redux'
import { FormattedMessage } from 'react-intl'
import { Grid, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import CenteredLayout from '../../../../components/layout/CenteredLayout'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import NewDocumentTypeBloc from './NewDocumentTypeBloc'
import { SVG_ICONS, TYPE_CONTENU } from '../../../../utils/constants'

const styles = getStyles({
	paper: {
		padding: 48
	},
})


const infoTypeNewDocument = [
	{
		type: 'propositionCommercial',
		typeContenuEnum: TYPE_CONTENU.PROPOSITION_COMMERCIALE,
		icon: SVG_ICONS.PROPOSITION_COMMERCIALE
	},
	{
		type: 'savoirFaire',
		typeContenuEnum: TYPE_CONTENU.SAVOIR_FAIRE,
		icon: SVG_ICONS.SAVOIR_FAIRE
	},
	{
		type: 'reference',
		typeContenuEnum: TYPE_CONTENU.REFERENCE,
		icon: SVG_ICONS.REFERENCE
	}
]

const NewDocumentPage = (classes) => {
	return (
		<CenteredLayout>
			<Typography variant="h1"><FormattedMessage id="document.newContent"/></Typography>
			<Paper className={classes.paper}>
				<Grid container spacing={1}>
					{infoTypeNewDocument.map(element =>
						<Grid item xs={12} sm={4} key={element.type}>
							<NewDocumentTypeBloc contenu={element}/>
						</Grid>
					)}
				</Grid>
			</Paper>
		</CenteredLayout>
	)
}

export default compose(
	withStyles(styles),
)(NewDocumentPage)
