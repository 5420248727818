import { combineReducers } from 'redux'
import * as actions from './tabDocumentActions'
import { localCompare } from '../../../../utils/utils'
import { ETATS_CHECKBOXES_COLUMNS, ETATS_COLLAPSED_COLUMNS } from '../../../../utils/constants'

const initTabDocData = {
	page: 0,
	size: 10,
	total: 0,
	content: [],
	selectedColumns: {}
}

const tabDocumentData = (state = initTabDocData, action) => {
	switch (action.type) {
		case actions.SET_DATA: {
			return {
				...action.payload,
				content: action.payload.content
			}
		}
		case actions.RESET_DATA: {
			return initTabDocData
		}
		case actions.UPDATE_ONE_FIELD: {
			const rowToUpdate = state.content.find(row => row.id === action.idRow)
			if (rowToUpdate != null) {
				const fieldToUpdate = rowToUpdate.values[action.codeColumn]
				fieldToUpdate.value = action.newValue
				if (fieldToUpdate.codes) {
					fieldToUpdate.codes = action.newCodesInStore
				}

				return {
					...state,
					content: [
						...state.content.filter(row => row.id !== action.idRow),
						rowToUpdate
					].sort(localCompare('id'))
				}
			}
			return state
		}
		case actions.COLLAPSED_LIST_TRUE: {
			return {
				...state,
				collapsedSelectColumns: {
					...state.collapsedSelectColumns,
					[action.id]: ETATS_COLLAPSED_COLUMNS.COLLAPSED
				}
			}
		}
		case actions.COLLAPSED_LIST_FALSE: {
			return {
				...state,
				collapsedSelectColumns: {
					...state.collapsedSelectColumns,
					[action.id]: ETATS_COLLAPSED_COLUMNS.NOT_COLLAPSED
				}
			}
		}
		default:
			return state
	}
}

const initCheckedState = {
	fieldsChecked: {},
	sectionsChecked: {},
	sectionsAdvancedChecked: {},
	checkedState: {}
}

const checkedState = (state = initCheckedState, action) => {
	switch (action.type) {
		case actions.CANCEL_POPIN: {
			return {
				...state.checkedState,
				checkedState: {}
			}
		}
		case actions.OPEN_POPIN: {
			return {
				...state,
				checkedState: { ...state }
			}
		}
		case actions.FIELD_LIST_CHECKED:
			return {
				...state,
				fieldsChecked: {
					...state.fieldsChecked,
					...action.ids.reduce((acc, id) => ({
						...acc,
						[id]: ETATS_CHECKBOXES_COLUMNS.CHECKED
					}), {})
				}
			}
		case actions.SECTION_LIST_CHECKED:
			return {
				...state,
				sectionsChecked: {
					...state.sectionsChecked,
					...action.ids.reduce((acc, id) => ({
						...acc,
						[id]: ETATS_CHECKBOXES_COLUMNS.CHECKED
					}), {})
				}
			}
		case actions.SECTION_LIST_UPDATE_CHECK:
			return {
				...state,
				sectionsChecked: {
					...state.sectionsChecked,
					...action.ids.reduce((acc, id) => ({
						...acc,
						[id]: action.status
					}), {})
				}
			}
		case actions.SECTION_ADVANCED_CHECKED:
			return {
				...state,
				sectionsAdvancedChecked: {
					...state.sectionsAdvancedChecked,
					[action.id]: action.status
				}
			}
		case actions.FIELD_LIST_UNCHECKED: {
			const newState = {...state}
			action.ids.forEach(id => delete newState.fieldsChecked[id])
			return newState
		}
		case actions.SECTION_LIST_UNCHECKED: {
			const newState = {...state}
			action.ids.forEach(id => delete newState.sectionsChecked[id])
			return newState
		}
		case actions.SECTION_ADVANCED_UNCHECKED: {
			const newState = {...state}
			delete newState.sectionsAdvancedChecked[action.id]
			return newState
		}
		case actions.CLEAR_COLUMNS_SELECTED: {
			return initCheckedState
		}
		default:
			return state
	}
}

const initTabDocCollapsed = {
	collapsedSelectColumns: {}
}

const tabDocumentCollapsed= (state = initTabDocCollapsed, action) => {
	switch (action.type) {
		case actions.COLLAPSED_LIST_TRUE: {
			return {
				...state,
				collapsedSelectColumns: {
					...state.collapsedSelectColumns,
					[action.id]: ETATS_COLLAPSED_COLUMNS.COLLAPSED
				}
			}
		}
		case actions.COLLAPSED_LIST_FALSE: {
			return {
				...state,
				collapsedSelectColumns: {
					...state.collapsedSelectColumns,
					[action.id]: ETATS_COLLAPSED_COLUMNS.NOT_COLLAPSED
				}
			}
		}
		default:
			return state
	}
}

export const tabDocument = combineReducers({
	tabDocumentData,
	checkedState,
	tabDocumentCollapsed
})
