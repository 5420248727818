import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import { getStyles } from 'isotope-client'
import { DEFAULT_LANGUAGE_ID, TYPE_CONTENU } from '../../../utils/constants'
import * as modelSelectors from '../../common/model/modelSelectors'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import SummarySection from './components/SummarySection'
import DetailsSection from './components/DetailsSection'
import { getUserLanguage } from '../../fo/user/services/userSelectors'
import Button from '../../../components/Button'
import { refreshEsModel } from './services/parametrageApi'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import * as modelActions from '../../common/model/modelActions'
import Loader from '../../../components/layout/Loader'

const styles = theme => getStyles({
	titleContent: {
		borderBottom: `1px solid ${theme.palette.borderColor}`,
		padding: 30,
		position: 'relative'
	},
	title: {
		borderBottom: `1px solid ${theme.palette.borderColor}`,
		padding: 30
	},
	plan: {
		borderRight: `1px solid ${theme.palette.borderColor}`
	},
	paper: {
		backgroundColor: 'white'
	},
	content: {
		padding: '10px 50px',
		height: 'calc(100vh - 290px)',
		overflow: 'auto'
	},
	btnMaj: {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		right: 20
	}
})

const typeContenu = {
	proposal: TYPE_CONTENU.PROPOSITION_COMMERCIALE,
	knowhow: TYPE_CONTENU.SAVOIR_FAIRE,
	reference: TYPE_CONTENU.REFERENCE
}

const buildSectionList = (sections, idLanguage, prefix = '', level = 1, path = [], index = 0) => {
	if (!sections || !sections.length) {
		return []
	}
	return sections.reduce((acc, section, index) => ({
		...acc,
		[`${index}-${section.id}`]: {
			id: section.id,
			type: section.type,
			label: `${prefix}${(section.attributeData[idLanguage] || section.attributeData[DEFAULT_LANGUAGE_ID]).TITLE.value}`,
			level: level,
			sections: section.sections,
			path: [...path, section.id]
		},
		...buildSectionList(section.sections, idLanguage, `${prefix}\t`, level + 1, [...path, section.id], index + 1)
	}), {})
}

const getSection = (path, model) => path.reduce((current, id) => current && Array.isArray(current.sections) ? current.sections.find(child => child.id === id) : null, model)

const ParametrageContenu = ({ type, model, classes, idLanguage, snackSuccess, getModelByType }) => {
	const [selectedSection, setSelectedSection] = React.useState(null)
	const [currentPath, setCurrentPath] = React.useState([])
	const [initModel, setInitModel] = React.useState(null)
	const [sectionsList, setSectionsList] = React.useState({})

	const handleSelectSection = (section, path) => {
		setSelectedSection(section)
		setCurrentPath(path)
	}

	// A l'initialisation, on affiche le niveau 1
	React.useEffect(() => {
		if (model && model.sections) {
			if (!initModel || model.id !== initModel.id) {
				setInitModel(model)
				setSelectedSection(null)
				setCurrentPath([])
				setSectionsList(buildSectionList(model.sections, idLanguage))
			} else if (model !== initModel) {
				setInitModel(model)
				const newSection = getSection(currentPath, model)
				setSelectedSection(newSection ? { ...newSection, level: currentPath ? currentPath.length :  0} : null)
				setSectionsList(buildSectionList(model.sections, idLanguage))
			} else {
				setInitModel(model)
				const newSection = getSection(currentPath, model)
				setSelectedSection(newSection ? { ...newSection, level: currentPath ? currentPath.length :  0} : null)
			}
		}
	}, [model])

	// Lorsque l'on quitte la page d'administration, on reload le modèle
	React.useEffect(() => {
		return () => {
			if (type) {
				getModelByType(type)
			}
		}
	}, [])

	const goToParent = () => {
		if (Array.isArray(currentPath) && currentPath.length !== 0) {
			const parentPath = currentPath.slice(0, -1)
			if (parentPath.length !== 0) {
				const parent = getSection(parentPath, initModel)
				if (parent !== null) {
					setCurrentPath(parentPath)
					setSelectedSection({ ...parent, level: parentPath.length })
				}
			} else {
				setCurrentPath([])
				setSelectedSection(null)
			}
		}
	}
	return (
		<>
			<Grid container justify="center" className={classes.titleContent}>
				<Typography variant="h3">
					<FormattedMessage id={`parametrage.typeContenu.${type}`} />
				</Typography>
				{!!model.sections && <Button
					color="primary"
					variant="contained"
					key="majSearch"
					className={classes.btnMaj}
					onClick={() => refreshEsModel(type).then(() => snackSuccess({ id: 'parametrage.majSearchSuccess' }))}
				>
					<FormattedMessage id="parametrage.btnMajSearch" />
				</Button>}
			</Grid>
			<Grid container className={classes.paper}>
				{!!model.sections ?
					<>
						<Grid item container xs={6} direction="column" className={classes.plan}>
							<Grid item container justify="center" className={classes.title}>
								<Typography variant="h4">
									<FormattedMessage id="parametrage.plan" />
								</Typography>
							</Grid>
							<Grid item container direction="column" wrap="nowrap" className={classes.content}>
								{model && model.sections && model.sections.map((section) => <SummarySection
									key={section.id}
									section={section}
									level={1}
									selectedSectionId={selectedSection && selectedSection.id}
									handleSelectSection={handleSelectSection}
									sectionPath={[section.id]}
								/>)}
							</Grid>
						</Grid>
						<Grid item container xs={6} direction="column">
							<Grid item container direction="column" alignItems="center" className={classes.title}>
								<Typography variant="h4">
									<FormattedMessage id="parametrage.detail" />
								</Typography>
							</Grid>
							<DetailsSection
								section={selectedSection || initModel}
								sectionPath={currentPath}
								handleSelectSection={handleSelectSection}
								goToParent={goToParent}
								type={type}
								sectionsList={sectionsList}
							/>
						</Grid>
					</>
					: <Loader />
				}

			</Grid>
		</>
	)
}

ParametrageContenu.propTypes = {
	type: PropTypes.string,
	model: PropTypes.object,
	classes: PropTypes.object,
	idLanguage: PropTypes.string,
	snackSuccess: PropTypes.func,
	getModelByType: PropTypes.func
}

const mapStateToProps = (state, { match }) => {
	const type = match && match.params ? typeContenu[match.params.typeContenu] : undefined
	const model = modelSelectors.getModelByType(state, type)
	return {
		type,
		model,
		idLanguage: getUserLanguage(state)
	}
}

const actions = {
	getModelByType: modelActions.getModelByType
}

export default compose(
	connect(mapStateToProps, actions),
	injectSnackActions,
	withStyles(styles)
)(ParametrageContenu)
