import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import DonneeAttributeField from './DonneeAttributeField'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '../../../../../../components/form/Select'
import Checkbox from '../../../../../../components/form/Checkbox'
import { ATTRIBUTE_DATA_MO_FIELD } from '../../../../../fo/document/utils/documentConstants'
import Input from '../../../../../../components/form/Input'
import { useField } from 'react-final-form'
import { getLists } from '../../../../../common/valueList/valueListSelectors'

const DonneeSelect = ({ sources, intl }) => {
	const reference = useField('attributeData.reference').input.value

	return (<Grid item container xs={12}>
		<Grid item xs={6}>
			<DonneeAttributeField
				name={ATTRIBUTE_DATA_MO_FIELD.SOURCE}
				label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.select.source' })} *`}
				component={Select}
			>
				{sources.map(s => <MenuItem key={s.id} value={s.shortcut}>
						{`${s.name} (${s.shortcut})`}
					</MenuItem>
				)}
			</DonneeAttributeField>
		</Grid>
		<Grid item xs={12} container>
			<Grid item xs={6}>
				<DonneeAttributeField
					name="reference"
					label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.select.reference' })}`}
					component={Checkbox}
					autoComplete="off"
				/>
			</Grid>
			{reference && <Grid item xs={6}>
				<DonneeAttributeField
					name={ATTRIBUTE_DATA_MO_FIELD.PARENT_REFERENCE}
					label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.select.referenceParent' })} *`}
					component={Input}
					autoComplete="off"
				/>
			</Grid>}
		</Grid>


	</Grid>)
}

const mapStateToProps = state => ({
	sources: getLists(state)
})

DonneeSelect.propTypes = {
	sources: PropTypes.array,
	intl: PropTypes.object
}

export default compose(
	injectIntl,
	connect(mapStateToProps)
)(DonneeSelect)
