import React from 'react'
import PropTypes from 'prop-types'
import { Zoom } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'

const TooltipItem = ({ title, placement, content, transitionComponent }) => {
	return (
		<Tooltip TransitionComponent={transitionComponent} arrow title={title} placement={placement}>
			{content}
		</Tooltip>
	)
}

TooltipItem.propType = {
	title: PropTypes.string,
	placement: PropTypes.string,
	content: PropTypes.element,
	transitionComponent: PropTypes.element
}

TooltipItem.defaultProps = {
	title: '',
	placement: 'bottom',
	content: <></>,
	transitionComponent: Zoom
}


export default TooltipItem
