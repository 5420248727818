import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import { Field, useField } from 'react-final-form'
import Input from '../../../../../components/form/Input'
import { injectIntl, intlShape } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import { isRequired } from '../../../../../utils/utils'
import { FIELD_TYPE } from '../../../../fo/document/utils/documentConstants'

const styles = () => getStyles({
	container: {
		marginTop: 10
	}
})


const FIELDS_WITHOUT_CONFIDENTIALITY = [FIELD_TYPE.TABLE, FIELD_TYPE.FILE]

const EditionDonneePartieTraduction = ({ langues, classes, intl }) => {
	const typeField = useField('type').input.value
	const canBeConfidential = !(!!typeField && FIELDS_WITHOUT_CONFIDENTIALITY.includes(typeField))

	return (
		<Grid item container xs={12} className={classes.container}>
			{langues.map(langue => <React.Fragment key={`lang-${langue.codeLang}`}>
				<Grid item xs={12}>
					<Field
						name={`name.${langue.codeLang}`}
						label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.nomDonnee' }, { langue: intl.formatMessage({ id: `enums.langue.${langue.codeLang}` }) })} *`}
						component={Input}
						autoComplete="off"
						validate={isRequired}
						inputProps={{
							maxLength: 150
						}}
					/>
				</Grid>
				{canBeConfidential && <Grid item xs={12}>
					<Field
						name={`help.${langue.codeLang}`}
						label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.help' }, { langue: intl.formatMessage({ id: `enums.langue.${langue.codeLang}` }) })}`}
						component={Input}
						autoComplete="off"
						inputProps={{
							maxLength: 150
						}}
					/>
				</Grid>}

			</React.Fragment>)}
		</Grid>
	)
}


EditionDonneePartieTraduction.propTypes = {
	langues: PropTypes.array,
	intl: intlShape,
	classes: PropTypes.object
}

export default compose(
	injectIntl,
	withStyles(styles)
)(EditionDonneePartieTraduction)
