import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import Popin from '../../../../components/Popin'
import Button from '@material-ui/core/Button'
import { reduxForm, formValueSelector } from 'redux-form'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import { Grid } from '@material-ui/core'
import RechercheDocumentFormAvancee from '../components/recherche/RechercheDocumentFormAvancee'

const styles = theme => getStyles({
	paddingText: {
		paddingLeft: 10,
		marginBottom: 24
	},
	popinResize: {
		maxWidth: 949
	},
	content: {
		minHeight: 700
	},
	card: {
		border: `${theme.palette.border} 1px solid`,
		padding: 5,
		marginTop: 10,
		borderRadius: 5,
		position: 'relative'
	},
	deleteIcon: {
		position: 'absolute',
		top: 5,
		right: 5,
		cursor: 'pointer',
		fontSize: 20
	},
	selectContainer: {
		minWidth: 125,
		width: 125
	},
	button: {
		width: 196,
		heigh: 36
	}
})


const AdditionalFiltersPopin = (
	{
		open,
		canValidate,
		onClose,
		onSubmit,
		handleSubmit,
		classes,
		formValues,
		rechercherAvancee,
		addField,
		removeField,
		updateField,
		modifyFilterIndex,
		setModifyFilterIndex,
		initValues
	}) => {

	const actions = [
		<Button
			color="inherit"
			variant="outlined"
			key="fermer"
			onClick={onClose}
			className={classes.button}
		>
			<FormattedMessage id="global.buttons.annuler" />
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			type="submit"
			onClick={handleSubmit(onSubmit)}
			className={classes.button}
			disabled={!canValidate}
		>
			<FormattedMessage id="global.buttons.validate" />
		</Button>
	]
	return <form onSubmit={onSubmit}><Popin
		fromSearch
		open={open}
		onClose={onClose}
		actions={actions}
		dialogClasses={{ paper: classes.popinResize }}
		title={<FormattedMessage id="document.search.advance.title" />}
		content={<Grid container className={classes.content} alignContent="flex-start">
			<Grid item>
				<Typography variant="body2" className={classes.paddingText}>
					<FormattedHTMLMessage
						id="document.search.advance.description"
					/>
				</Typography>
			</Grid>
			<Grid container item>
				<RechercheDocumentFormAvancee
					selectTypeContenu={(formValues && formValues.typeContenu) || []}
					rechercheAvancee={(formValues && formValues.fieldsAdvance) || []}
					addField={addField}
					rechercherAvancee={rechercherAvancee}
					removeField={removeField}
					updateField={(data, index) => updateField(index, data)}
					subSegments={(formValues && formValues.businessSubSegment) || []}
					formValues={formValues}
					modifyFilterIndex={modifyFilterIndex}
					setModifyFilterIndex={setModifyFilterIndex}
					initValues={initValues}
				/>
			</Grid>
		</Grid>}
	/>
	</form>

}

AdditionalFiltersPopin.propTypes = {
	open: PropTypes.bool,
	canValidate: PropTypes.bool,
	classes: PropTypes.object,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	change: PropTypes.func,
	adminCorrespFilters: PropTypes.object,
	initialValues: PropTypes.object,
	usersList: PropTypes.array,
	removeAdminFilters: PropTypes.func,
	setAdminPopinState: PropTypes.func,
	inPopin: PropTypes.bool
}

const FORM_NAME = 'ADDITIONAL_FILTERS'

const mapStateToProps = state => {
	const sectionField = formValueSelector('documentsSearchAvancee')(state, 'sectionField')
	return {
		canValidate: !sectionField
	}
}

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: FORM_NAME
	}),
	withStyles(styles)
)(AdditionalFiltersPopin)
