import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Autocomplete from './Autocomplete'
import { getVeoliaEmail } from '../../modules/fo/user/services/usersApi'
import { Field } from 'redux-form'
import { getStyles } from 'isotope-client'
import CancelIcon from '@material-ui/icons/Cancel'

const styles = (theme) => getStyles({
	paper: {
		marginLeft: 10,
		boxShadow: 'none',
		marginBottom: 10
	},
	item: {
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.palette.primary.main,
		borderRadius: 5,
		paddingLeft: 5,
		paddingRight: 5,
		marginRight: 5,
		marginTop: 5
	},
	icon: {
		fontSize: 18,
		cursor: 'pointer',
		marginTop: 4,
		color: theme.palette.primary.main
	},
	itemText: {
		paddingRight: 5
	}
})
/**
 * Gestion autocomplete pour email avec tuiles
 */
const CompleteEmail = ({ change, input, classes, ...props }) => {
	return <><Field
		{...props}
		name={`${input.name}-autocomplete`}
		component={Autocomplete}
		minLength={3}
		getSuggestion={(inputLength, inputValue) => getVeoliaEmail(inputValue)
			.then(fields => fields.filter(email => (input.value || []).indexOf(email) === -1).map(email => ({
					value: email,
					label: email
				}))
			)}
		onClickItem={(item, query) => {
			change(input.name, (input.value || []).concat(item.value))
			setTimeout(() => change(`${input.name}-autocomplete`, query), 0)
		}}
	/>
		<Paper className={classes.paper}>
			<Grid container>
			{(input.value || []).map((item, index) => <Grid item key={item} className={classes.item}>
				<Grid container alignItems="center" direction="row-reverse" justify="space-between">
					<Grid item>
						<CancelIcon
							className={classes.icon}
							onClick={() => {
								const newItems = [...input.value]
								newItems.splice(index, 1)
								change(input.name, newItems)
							}}
						/>
					</Grid>
					<Grid item className={classes.itemText}><Typography>{item}</Typography></Grid>
				</Grid>
			</Grid>)}
			</Grid>
		</Paper>
	</>

}

CompleteEmail.propTypes = {
	inputName: PropTypes.string,
	inputValue: PropTypes.array,
	change: PropTypes.func
}

export default withStyles(styles)(CompleteEmail)
