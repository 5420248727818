import PropTypes from 'prop-types'
import React from 'react'
import { useField } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { GlOBAL_LANGUAGE_ID } from '../../../../utils/constants'
import { getDependencies } from '../utils/documentUtils'

export const SpyField = ({
	                         part,
	                         duplicatedNumber,
	                         addMe,
	                         removeMe,
	                         level,
	                         children
                         }) => {

	// Si nous n'avons pas d'attribute data, la section n'est pas visible
	if (!part || !part.attributeData) {
		return null
	}

	const technicalData = part.attributeData[GlOBAL_LANGUAGE_ID]
	const dependencies = getDependencies(technicalData, duplicatedNumber)

	if (!dependencies) {
		return null
	}

	const initialVisibles = Object.keys(dependencies)
		.map(fieldName => {
			const { value } = useField(fieldName).input.value
			if (dependencies[fieldName].some(fieldValue => value && value.includes(fieldValue))) {
				return {
					...visibles,
					[fieldName]: true
				}
			} else {
				return {
					[fieldName]: false
				}
			}
		})
		.reduce((visibles, visible) => ({
			...visibles,
			...visible
		}), {})

	const [visibles, setVisibles] = React.useState(initialVisibles)

	if (addMe && level === 2) {
		if (Object.keys(dependencies).length === 0 || Object.values(visibles).some(visible => visible)) {
			addMe(part)
		} else {
			removeMe(part)
		}
	}

	return <>
		{Object.keys(dependencies).map(fieldName => (
			<OnChange key={fieldName} name={fieldName}>
				{({ value }) => {
					if (dependencies[fieldName].some(fieldValue => value && value.includes(fieldValue))) {
						setVisibles({
							...visibles,
							[fieldName]: true
						})
					} else {
						setVisibles({
							...visibles,
							[fieldName]: false
						})
					}
				}}
			</OnChange>
		))}
		{(Object.keys(dependencies).length === 0 || Object.values(visibles).some(visible => visible)) && children}
	</>
}

SpyField.propTypes = {
	part: PropTypes.object.isRequired,
	selectedLanguage: PropTypes.string,
	duplicatedNumber: PropTypes.number
}
