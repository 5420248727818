import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { getStyles } from 'isotope-client'
import Grid from '@material-ui/core/Grid'
import SectionIcon from '@material-ui/icons/Assessment'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { getUserLanguage } from '../../../../fo/user/services/userSelectors'
import { ATTRIBUTE_DATA_MO_SECTION, SECTION_TYPE } from '../../../../fo/document/utils/documentConstants'
import { DEFAULT_LANGUAGE_ID } from '../../../../../utils/constants'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = theme => getStyles({
	container: {
		border: '1px solid #c8dcdc',
		borderRadius: 5,
		padding: 5,
		marginBottom: 15,
		cursor: 'pointer'
	},
	iconPublished: {
		color: theme.palette.secondary.main
	},
	iconNotPublished: {
		color: theme.palette.draft
	}
})

/**
 * Affichage d'une tuile section
 */
const SectionBloc = ({
	section,
	idLanguage,
	level,
	handleSelectSection,
	handleDelete,
	intl,
	classes,
	handleEdit
}) => {
	const sectionTitle = section.attributeData && (
		(section.attributeData[idLanguage] && section.attributeData[idLanguage][ATTRIBUTE_DATA_MO_SECTION.TITLE] && section.attributeData[idLanguage][ATTRIBUTE_DATA_MO_SECTION.TITLE].value)
		|| (section.attributeData[DEFAULT_LANGUAGE_ID] && section.attributeData[DEFAULT_LANGUAGE_ID][ATTRIBUTE_DATA_MO_SECTION.TITLE] && section.attributeData[DEFAULT_LANGUAGE_ID][ATTRIBUTE_DATA_MO_SECTION.TITLE].value))

	return (
		<Grid item container className={classes.container} onClick={() => handleSelectSection({ ...section, level })} alignItems="center">
			<Grid item xs={1}>
				<SectionIcon fontSize="large" className={section.published ? classes.iconPublished : classes.iconNotPublished}/>
			</Grid>
			<Grid item xs={9} container direction="column">
				<Typography variant="h6">{sectionTitle || intl.formatMessage({ id: 'parametrage.section.defaultTitle' })}</Typography>
				<Typography variant="h6"><FormattedMessage id="parametrage.section.level" values={{ level }} /> - <FormattedMessage id={`parametrage.section.type.${section.type}`} /></Typography>
			</Grid>
			<Grid item xs={1}>
				{section.type !== SECTION_TYPE.FI && <IconButton onClick={(e) => {
					e.stopPropagation()
					handleDelete()
				}}>
					<DeleteIcon />
				</IconButton>}
			</Grid>
			<Grid item xs={1}>
				<IconButton onClick={(e) => {
					e.stopPropagation()
					handleEdit()
				}}>
					<EditIcon className={section.published ? classes.iconPublished : classes.iconNotPublished}/>
				</IconButton>
			</Grid>
		</Grid>

	);
}

SectionBloc.propTypes = {
	section: PropTypes.object,
	idLanguage: PropTypes.string,
	level: PropTypes.number,
	handleSelectSection: PropTypes.func,
	intl: intlShape,
	classes: PropTypes.object
}

const mapStateToProps = state => ({
	idLanguage: getUserLanguage(state)
})

export default compose(
	connect(mapStateToProps),
	injectIntl,
	withStyles(styles)
)(SectionBloc)
