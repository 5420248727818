import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'

const styles = () => getStyles({
	title: {
		padding: 30,
		lineHeight: 1.4
	}
})

const AccueilBoPage = ({ classes }) => {
	return (
		<Typography variant="h2" classes={{ root: classes.title }}>
			<FormattedMessage id="accueilBo.title" />
		</Typography>
	)
}


export default withStyles(styles)(AccueilBoPage)
