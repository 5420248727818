import { push } from 'connected-react-router'
import { getUser as setUser } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { IntlContext } from '../../../../config/IntlContext'
import { USER_PREFERENCES } from '../../../../utils/constants'
import { loadConfiguration } from '../../configuration/configurationInjector'
import { getMe } from '../../user/services/usersApi'
import * as actions from '../services/loginActions'
import { useGoogleLogin } from '@react-oauth/google'
import Button from '../../../../components/Button'
import { FormattedMessage } from 'react-intl'

const LoginForm = ({configuration, logUser, logOutLoginPage, getUser, setUser, redirect, nextPathname, setLoading}) => {
    const {changeLanguage} = React.useContext(IntlContext)
    const [userDisabled, setUserDisabled] = React.useState(false)

    const login = useGoogleLogin({
        onSuccess: (response) => postLogin(response),
        flow: 'auth-code',
        onError: (e) => console.log('Erreur lors de la connexion google', e),
        scope: "email profile https://www.googleapis.com/auth/drive openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"
    });

    const postLogin = (response) => {
        setLoading(true)
        setUserDisabled(false)
        setTimeout(() => {
            logUser({
                code: response.code
            })
                .then(getUser)
                .then(() => {
                    getMe().then(me => {
                        if (me && me.preferences && me.preferences[USER_PREFERENCES.LANGUE]) {
                            changeLanguage(me.preferences[USER_PREFERENCES.LANGUE])
                        }
                        setUser({id: me.id, accessRequest: me.accessRequest})
                        redirect(nextPathname)
                    })
                })
                .catch((e) => {
                    if (e && e.disabledUser) {
                        setUserDisabled(true)
                    }
                    setLoading(false)
                })
        }, 2000)
    }

    return (<>
            {userDisabled && <div style={{color: '#d32f2f', marginBottom: 20}}>
                Your account is disabled. Please contact your administrator.
            </div>}
            <div className="signGoogle">
                {configuration &&
                    <Button
                        onClick={login}
                    >
                        <FormattedMessage id="global.buttons.loginGoogle"/>
                    </Button>
                }
            </div>
        </>
    )
}

LoginForm.propTypes = {
    logUser: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
    redirect: PropTypes.func.isRequired,
    configuration: PropTypes.object,
    nextPathname: PropTypes.string
}

const mapStateToProps = (state) => ({
    user: state.user
})

const dispatchToProps = {
    logUser: actions.logUser,
    getUser: actions.getUser,
    logOutLoginPage: actions.logOutLoginPage,
    setUser,
    redirect: (nextPathname) => (dispatch) => {
        if (nextPathname) {
            dispatch(push(nextPathname))
        } else {
            dispatch(push('/accueil'))
        }
    }
}

export default compose(
    loadConfiguration,
    connect(mapStateToProps, dispatchToProps)
)(LoginForm)
