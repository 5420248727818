import * as api from './conversionApi'

export const GET_CONVERSION_LIST_REQUEST = 'GET_CONVERSION_LIST_REQUEST'
export const GET_CONVERSION_LIST_SUCCESS = 'GET_CONVERSION_LIST_SUCCESS'
export const GET_CONVERSION_LIST_ERROR = 'GET_CONVERSION_LIST_ERROR'

export const getConversionList = () => dispatch => {
	dispatch({ type: GET_CONVERSION_LIST_REQUEST })

	return api.getConversionList()
		.then(conversionList => dispatch({ type: GET_CONVERSION_LIST_SUCCESS, conversionList }))
		.catch(e => dispatch({ type: GET_CONVERSION_LIST_ERROR, e }))
}
