import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { push } from 'connected-react-router'
import { getStyles } from 'isotope-client'
import { colors, TYPE_CONTENU } from '../../utils/constants'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import SearchSelect from '../form/SearchSelect'
import MenuItem from '@material-ui/core/MenuItem'
import Fab from '@material-ui/core/Fab'
import { DOCUMENT_STATUS } from '../../modules/fo/document/utils/documentConstants';

const styles = getStyles({
	iconButton: {
		padding: 10
	},
	form: {
		display: 'flex',
		width: '100%'
	},
	rootHeader: {
		padding: 0,
		marginLeft: 80,
		display: 'flex',
		alignItems: 'center',
		width: 600,
		height: 40,
		borderRadius: '30px',
		overflow: 'hidden',
		backgroundColor: colors.background,
		boxShadow: 'none'
	},
	inputHeader: {
		marginLeft: 8,
		flex: 1,
		fontSize: '14px'
	},
	fabHeader: {
		marginTop: 8,
		height: 40,
		width: 40,
		boxShadow: 'unset',
		color: colors.text,
		backgroundColor: colors.tertiary
	},

	rootContent: {
		padding: 0,
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		borderRadius: '30px',
		overflow: 'hidden',
		backgroundColor: 'white'
	},
	inputContent: {
		marginLeft: 8,
		flex: 1,
		fontSize: '16px'
	},
	fabContent: {
		boxShadow: 'unset',
		color: 'white',
		backgroundColor: colors.primary
	}
})

const CONTENU = 'contenuSelected'
const QUERY_RECHERCHE = 'queryRecherche'

const renderInputBase = (props) => (<InputBase {...props} onChange={(event) => {
	props.input.onChange(event.target.value)
}} />)

export const SearchBar = ({ classes, isHeader, intl, handleSubmit, goTo }) => {

	const goToRechercher = (values) => {
		goTo('/documents', {
			initialId: undefined,
			initialQuery: values.queryRecherche,
			initialTypeContenu: values.contenuSelected,
			initialFieldsAdvance: undefined,
			initialFieldsAdmin: {
				status: [{ code: DOCUMENT_STATUS.PUBLIE }]
			} })
	}

	return (
		<React.Fragment>
			<Paper className={isHeader ? classes.rootHeader : classes.rootContent} elevation={1}>
				<form className={classes.form} onSubmit={handleSubmit(goToRechercher)}>
					{/*Selecteur de contenu*/}
					<Field component={SearchSelect} name={CONTENU} isHeader={isHeader}>
						<MenuItem key={0} value={0}>
							<FormattedMessage id="searchBar.all" />
						</MenuItem>
						{Object.keys(TYPE_CONTENU).map((key) =>
							<MenuItem key={`TYPE_CONTENU-${key}`} value={key}>
								<FormattedMessage id={`enums.documentType.${key}`} />
							</MenuItem>
						)}
					</Field>

					{/*Champ de recherche*/}
					<Field
						component={renderInputBase}
						name={QUERY_RECHERCHE}
						placeholder={intl.formatMessage({ id: 'searchBar.placeHolder' })}
						className={isHeader ? classes.inputHeader : classes.inputContent}
					/>

					{/*Bouton de recherche*/}
					<Fab aria-label="Search" className={isHeader ? classes.fabHeader : classes.fabContent} type="submit">
						<SearchIcon />
					</Fab>
				</form>
			</Paper>
		</React.Fragment>
	)
}

SearchBar.propTypes = {
	classes: PropTypes.object,
	isHeader: PropTypes.bool
}

const actions = {
	goTo: (pathname, state) => dispatch => dispatch(push({
		pathname,
		state
	}))
}

const getDefaultCompose = () => compose(
	withStyles(styles),
	injectIntl
)

export const SearchBarHeader = compose (
	getDefaultCompose(),
	connect(() => ({ isHeader: true }), actions),
	reduxForm({
		form: 'searchBarHeader',
		initialValues: {
			contenuSelected: 0
		}
	}),
)(SearchBar)

export default compose(
	getDefaultCompose(),
	connect(() => ({ isHeader: false }), actions),
	reduxForm({
		form: 'searchBar',
		initialValues: {
			contenuSelected: 0
		}
	})
)(SearchBar)
