export const ROOT_BO = '/bo'

export const colors = {
	primary: '#00aec7',
	secondary: '#f49f25',
	tertiary: '#c8dcdc',
	input: '#f6f6f6',
	placeholder: '#88888a',
	text: '#55555a',
	foBackgroundColorPage: '#e2e8e8',
	background: '#EEF4F4',
	border: '#D9D1C8',
	appBarBackground: '#ffffff',
	appBarColor: '#000000',
	emptyColor: 'rgba(255, 255, 255, 0.7)',
	draftColor: '#b4a696',
	selected: '#00aec730',
	selectedText: '#02adc787',
	collapseTitle: '#56555b',
	selectedText100Percent: '#02adc7',
	error: 'rgb(129, 31, 0)'
}

// Enums listant les icônes material-design
// A utiliser avec le composant SvgIcon
export const SVG_ICONS = {
	SAVOIR_FAIRE: 'M16,9H19L14,16M10,9H14L12,17M5,9H8L10,16M15,4H17L19,7H16M11,4H13L14,7H10M7,4H9L8,7H5M6,2L2,8L12,22L22,8L18,2H6Z',
	REFERENCE: 'M18,22H6A2,2 0 0,1 4,20V4C4,2.89 4.9,2 6,2H7V9L9.5,7.5L12,9V2H18A2,2 0 0,1 20,4V20A2,2 0 0,1 18,22M18,18V16H12V18H18Z',
	PROPOSITION_COMMERCIALE: 'M12.6,2.86C15.27,4.1 18,5.39 20.66,6.63C20.81,6.7 21,6.75 21,6.95C21,7.15 20.81,7.19 20.66,7.26C18,8.5 15.3,9.77 12.62,11C12.21,11.21 11.79,11.21 11.38,11C8.69,9.76 6,8.5 3.32,7.25C3.18,7.19 3,7.14 3,6.94C3,6.76 3.18,6.71 3.31,6.65C6,5.39 8.74,4.1 11.44,2.85C11.73,2.72 12.3,2.73 12.6,2.86M12,21.15C11.8,21.15 11.66,21.07 11.38,20.97C8.69,19.73 6,18.47 3.33,17.22C3.19,17.15 3,17.11 3,16.9C3,16.7 3.19,16.66 3.34,16.59C3.78,16.38 4.23,16.17 4.67,15.96C5.12,15.76 5.56,15.76 6,15.97C7.79,16.8 9.57,17.63 11.35,18.46C11.79,18.67 12.23,18.66 12.67,18.46C14.45,17.62 16.23,16.79 18,15.96C18.44,15.76 18.87,15.75 19.29,15.95C19.77,16.16 20.24,16.39 20.71,16.61C20.78,16.64 20.85,16.68 20.91,16.73C21.04,16.83 21.04,17 20.91,17.08C20.83,17.14 20.74,17.19 20.65,17.23C18,18.5 15.33,19.72 12.66,20.95C12.46,21.05 12.19,21.15 12,21.15M12,16.17C11.9,16.17 11.55,16.07 11.36,16C8.68,14.74 6,13.5 3.34,12.24C3.2,12.18 3,12.13 3,11.93C3,11.72 3.2,11.68 3.35,11.61C3.8,11.39 4.25,11.18 4.7,10.97C5.13,10.78 5.56,10.78 6,11C7.78,11.82 9.58,12.66 11.38,13.5C11.79,13.69 12.21,13.69 12.63,13.5C14.43,12.65 16.23,11.81 18.04,10.97C18.45,10.78 18.87,10.78 19.29,10.97C19.76,11.19 20.24,11.41 20.71,11.63C20.77,11.66 20.84,11.69 20.9,11.74C21.04,11.85 21.04,12 20.89,12.12C20.84,12.16 20.77,12.19 20.71,12.22C18,13.5 15.31,14.75 12.61,16C12.42,16.09 12.08,16.17 12,16.17Z',
	VISITEUR: 'M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14M7,22H9V24H7V22M11,22H13V24H11V22M15,22H17V24H15V22Z',
	MEMBRE_VALIDER: 'M21.1,12.5L22.5,13.91L15.97,20.5L12.5,17L13.9,15.59L15.97,17.67L21.1,12.5M10,17L13,20H3V18C3,15.79 6.58,14 11,14L12.89,14.11L10,17M11,4A4,4 0 0,1 15,8A4,4 0 0,1 11,12A4,4 0 0,1 7,8A4,4 0 0,1 11,4Z',
	ADMIN: 'M8.999 3.999a4.002 4.002 0 0 0 0 8.003a3.999 3.999 0 0 0 3.998-4.005A3.998 3.998 0 0 0 9 4zm0 10C6.329 13.999 1 15.332 1 17.997V20H12.08a6.233 6.233 0 0 1-.078-1.001c0-1.514.493-2.988 1.407-4.199c-1.529-.523-3.228-.801-4.41-.801zm8.99 0a.261.261 0 0 0-.25.21l-.19 1.319a4.091 4.091 0 0 0-.85.492l-1.24-.502a.265.265 0 0 0-.308.112l-1.001 1.729a.255.255 0 0 0 .059.322l1.06.83a3.95 3.95 0 0 0 0 .981l-1.06.83a.26.26 0 0 0-.059.318l1.001 1.729c.059.111.19.151.308.111l1.24-.497c.258.2.542.366.85.488l.19 1.318c.02.122.122.21.25.21h2.001c.122 0 .23-.088.25-.21l.19-1.318c.297-.132.59-.288.84-.488l1.25.497c.111.04.239 0 .313-.111l.996-1.729a.256.256 0 0 0-.059-.317l-1.07-.83c.02-.162.04-.323.04-.494c0-.171-.01-.328-.04-.488l1.06-.83c.087-.084.121-.21.059-.322l-.996-1.729a.263.263 0 0 0-.313-.113l-1.24.503c-.26-.2-.543-.37-.85-.492l-.19-1.32a.238.238 0 0 0-.24-.21M18.989 17.5c.83 0 1.5.669 1.5 1.499c0 .83-.67 1.498-1.5 1.498S17.49 19.83 17.49 19s.669-1.499 1.499-1.499z',
	NOTE_PLUS: 'M15,10H20.5L15,4.5V10M4,3H16L22,9V19A2,2 0 0,1 20,21H4C2.89,21 2,20.1 2,19V5C2,3.89 2.89,3 4,3M4,5V19H20V12H13V5H4M8,17V15H6V13H8V11H10V13H12V15H10V17H8Z',
	NOTE_MULTIPLE: 'M3,6V22H21V24H3A2,2 0 0,1 1,22V6H3M16,9H21.5L16,3.5V9M7,2H17L23,8V18A2,2 0 0,1 21,20H7C5.89,20 5,19.1 5,18V4A2,2 0 0,1 7,2M7,4V18H21V11H14V4H7Z',
	CHECKBOX_MULTIPLE: 'M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,16H8V4H20V16M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16M18.53,8.06L17.47,7L12.59,11.88L10.47,9.76L9.41,10.82L12.59,14L18.53,8.06Z',
	CONTENT_PASTE: 'M19,20H5V4H7V7H17V4H19M12,2A1,1 0 0,1 13,3A1,1 0 0,1 12,4A1,1 0 0,1 11,3A1,1 0 0,1 12,2M19,2H14.82C14.4,0.84 13.3,0 12,0C10.7,0 9.6,0.84 9.18,2H5A2,2 0 0,0 3,4V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V4A2,2 0 0,0 19,2Z',
	FILE_EXCEL: 'M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M15.8,20H14L12,16.6L10,20H8.2L11.1,15.5L8.2,11H10L12,14.4L14,11H15.8L12.9,15.5L15.8,20M13,9V3.5L18.5,9H13Z',
	FILE_PDF: 'M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2M10.1,11.4C10.08,11.44 9.81,13.16 8,16.09C8,16.09 4.5,17.91 5.33,19.27C6,20.35 7.65,19.23 9.07,16.59C9.07,16.59 10.89,15.95 13.31,15.77C13.31,15.77 17.17,17.5 17.7,15.66C18.22,13.8 14.64,14.22 14,14.41C14,14.41 12,13.06 11.5,11.2C11.5,11.2 12.64,7.25 10.89,7.3C9.14,7.35 9.8,10.43 10.1,11.4M10.91,12.44C10.94,12.45 11.38,13.65 12.8,14.9C12.8,14.9 10.47,15.36 9.41,15.8C9.41,15.8 10.41,14.07 10.91,12.44M14.84,15.16C15.42,15 17.17,15.31 17.1,15.64C17.04,15.97 14.84,15.16 14.84,15.16M7.77,17C7.24,18.24 6.33,19 6.1,19C5.87,19 6.8,17.4 7.77,17M10.91,10.07C10.91,10 10.55,7.87 10.91,7.92C11.45,8 10.91,10 10.91,10.07Z',
	FILE_POWERPOINT: 'M12.6,12.3H10.6V15.5H12.7C13.3,15.5 13.6,15.3 13.9,15C14.2,14.7 14.3,14.4 14.3,13.9C14.3,13.4 14.2,13.1 13.9,12.8C13.6,12.5 13.2,12.3 12.6,12.3M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M15.2,16C14.6,16.5 14.1,16.7 12.8,16.7H10.6V20H9V11H12.8C14.1,11 14.7,11.3 15.2,11.8C15.8,12.4 16,13 16,13.9C16,14.8 15.8,15.5 15.2,16M13,9V3.5L18.5,9H13Z',
	FILE_WORD: 'M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M15.2,20H13.8L12,13.2L10.2,20H8.8L6.6,11H8.1L9.5,17.8L11.3,11H12.6L14.4,17.8L15.8,11H17.3L15.2,20M13,9V3.5L18.5,9H13Z',
	FILE_VIDEO: 'M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M17,19V13L14,15.2V13H7V19H14V16.8L17,19Z',
	FILE_PHOTO: 'M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M6,20H15L18,20V12L14,16L12,14L6,20M8,9A2,2 0 0,0 6,11A2,2 0 0,0 8,13A2,2 0 0,0 10,11A2,2 0 0,0 8,9Z',
	FILE_DOC: 'M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15,18V16H6V18H15M18,14V12H6V14H18Z',
	FILE_SHEET: 'M 14 2 L 6 2 C 4.894531 2 4 2.894531 4 4 L 4 20 C 4 21.105469 4.894531 22 6 22 L 18 22 C 19.105469 22 20 21.105469 20 20 L 20 8 Z M 11 20 L 7 20 L 7 18 L 11 18 Z M 11 17 L 7 17 L 7 15 L 11 15 Z M 11 14 L 7 14 L 7 12 L 11 12 Z M 17 20 L 13 20 L 13 18 L 17 18 Z M 17 17 L 13 17 L 13 15 L 17 15 Z M 17 14 L 13 14 L 13 12 L 17 12 Z M 13 9 L 13 3.5 L 18.5 9 Z',
	FILE_SLIDE: 'M 6 2 C 4.894531 2 4 2.894531 4 4 L 4 20 C 4 21.105469 4.894531 22 6 22 L 18 22 C 19.105469 22 20 21.105469 20 20 L 20 8 L 14 2 Z M 13 3.5 L 18.5 9 L 13 9 Z M 9 11 L 15 11 C 16.105469 11 17 11.800781 17 12.78125 L 17 17.21875 C 17 18.199219 16.105469 19 15 19 L 9 19 C 7.894531 19 7 18.199219 7 17.21875 L 7 12.78125 C 7 11.800781 7.894531 11 9 11 Z M 9 13 L 9 17 L 15 17 L 15 13 Z"',
	DRIVE: 'M 11.4375 5 L 11.15625 5.46875 L 3.15625 18.46875 L 2.84375 18.96875 L 3.125 19.5 L 7.125 26.5 L 7.40625 27 L 24.59375 27 L 24.875 26.5 L 28.875 19.5 L 29.15625 18.96875 L 28.84375 18.46875 L 20.84375 5.46875 L 20.5625 5 Z M 13.78125 7 L 19.4375 7 L 26.21875 18 L 20.5625 18 Z M 12 7.90625 L 14.96875 12.75 L 8.03125 24.03125 L 5.15625 19 Z M 16.15625 14.65625 L 18.21875 18 L 14.09375 18 Z M 12.875 20 L 26.28125 20 L 23.40625 25 L 9.78125 25 Z',
	SORT: 'M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V7H1.5L5,3.5L8.5,7H6V17Z',
	DUPLICATE: 'M11,17H4A2,2 0 0,1 2,15V3A2,2 0 0,1 4,1H16V3H4V15H11V13L15,16L11,19V17M19,21V7H8V13H6V7A2,2 0 0,1 8,5H19A2,2 0 0,1 21,7V21A2,2 0 0,1 19,23H8A2,2 0 0,1 6,21V19H8V21H19Z',
	CITY: 'M19,15H17V13H19M19,19H17V17H19M13,7H11V5H13M13,11H11V9H13M13,15H11V13H13M13,19H11V17H13M7,11H5V9H7M7,15H5V13H7M7,19H5V17H7M15,11V5L12,2L9,5V7H3V21H21V11H15Z',
	DATA: 'M12,3C7.58,3 4,4.79 4,7C4,9.21 7.58,11 12,11C16.42,11 20,9.21 20,7C20,4.79 16.42,3 12,3M4,9V12C4,14.21 7.58,16 12,16C16.42,16 20,14.21 20,12V9C20,11.21 16.42,13 12,13C7.58,13 4,11.21 4,9M4,14V17C4,19.21 7.58,21 12,21C16.42,21 20,19.21 20,17V14C20,16.21 16.42,18 12,18C7.58,18 4,16.21 4,14Z',
	DRAG: 'M7,19V17H9V19H7M11,19V17H13V19H11M15,19V17H17V19H15M7,15V13H9V15H7M11,15V13H13V15H11M15,15V13H17V15H15M7,11V9H9V11H7M11,11V9H13V11H11M15,11V9H17V11H15M7,7V5H9V7H7M11,7V5H13V7H11M15,7V5H17V7H15Z',
	CHART_DONUT: 'M13,2.05C18.05,2.55 22,6.82 22,12C22,13.45 21.68,14.83 21.12,16.07L18.5,14.54C18.82,13.75 19,12.9 19,12C19,8.47 16.39,5.57 13,5.08V2.05M12,19C14.21,19 16.17,18 17.45,16.38L20.05,17.91C18.23,20.39 15.3,22 12,22C6.47,22 2,17.5 2,12C2,6.81 5.94,2.55 11,2.05V5.08C7.61,5.57 5,8.47 5,12A7,7 0 0,0 12,19M12,6A6,6 0 0,1 18,12C18,14.97 15.84,17.44 13,17.92V14.83C14.17,14.42 15,13.31 15,12A3,3 0 0,0 12,9L11.45,9.05L9.91,6.38C10.56,6.13 11.26,6 12,6M6,12C6,10.14 6.85,8.5 8.18,7.38L9.72,10.05C9.27,10.57 9,11.26 9,12C9,13.31 9.83,14.42 11,14.83V17.92C8.16,17.44 6,14.97 6,12Z',
	FILTER_FULL: 'M 15 19.88 C 15.04 20.18 14.94 20.5 14.71 20.71 C 14.32 21.1 13.69 21.1 13.3 20.71 L 9.29 16.7 C 9.06 16.47 8.96 16.16 9 15.87 L 9 10.75 L 4.21 4.62 C 3.87 4.19 3.95 3.56 4.38 3.22 C 4.57 3.08 4.78 3 5 3 L 19 3 C 19.22 3 19.43 3.08 19.62 3.22 C 20.05 3.56 20.13 4.19 19.79 4.62 L 15 10.75 L 15 19.88',
	FILTER_OUTLINED: 'M15,19.88C15.04,20.18 14.94,20.5 14.71,20.71C14.32,21.1 13.69,21.1 13.3,20.71L9.29,16.7C9.06,16.47 8.96,16.16 9,15.87V10.75L4.21,4.62C3.87,4.19 3.95,3.56 4.38,3.22C4.57,3.08 4.78,3 5,3V3H19V3C19.22,3 19.43,3.08 19.62,3.22C20.05,3.56 20.13,4.19 19.79,4.62L15,10.75V19.88M7.04,5L11,10.06V15.58L13,17.58V10.05L16.96,5H7.04z',
	EDIT_LIST: 'M3,10h11v2H3V10z M3,8h11V6H3V8z M3,16h7v-2H3V16z M18.01,12.87l0.71-0.71c0.39-0.39,1.02-0.39,1.41,0l0.71,0.71 c0.39,0.39,0.39,1.02,0,1.41l-0.71,0.71L18.01,12.87z M17.3,13.58l-5.3,5.3V21h2.12l5.3-5.3L17.3,13.58z',
	CREATE_CONTENT_OSS: 'M15,10H20.5L15,4.5V10M4,3H16L22,9V19A2,2 0 0,1 20,21H4C2.89,21 2,20.1 2,19V5C2,3.89 2.89,3 4,3M4,5V19H20V12H13V5H4M8,17V15H6V13H8V11H10V13H12V15H10V17H8Z'
}

// Profils de l'application
export const PROFILS = {
	VISITEUR: { nom: 'VISITEUR', id: 4, role: 'ROLE_VISITEUR' },
	MEMBRE: { nom: 'MEMBRE', id: 3, role: 'ROLE_MEMBRE' },
	CORRESPONDANT: { nom: 'CORRESPONDANT', id: 2, role: 'ROLE_CORRESPONDANT' },
	ADMIN: { nom: 'ADMIN', id: 1, role: 'ROLE_ADMIN' }
}

export const ETAT_ACCESS_REQUEST = {
	EN_ATTENTE: 'EN_ATTENTE',
	VALIDE: 'VALIDE',
	REFUSE: 'REFUSE'
}

export const FIELD_SEARCH_ADMIN = {
	STATUS: 'status',
	CONTRIBUTOR: 'contributor',
	DATE_CREATION_DEBUT: 'dateCreationDebut',
	DATE_CREATION_FIN: 'dateCreationFin',
	DATE_SOUMISSION_DEBUT: 'dateSoumissionDebut',
	DATE_SOUMISSION_FIN: 'dateSoumissionfin',
	MODIFIER_BY: 'modifierPar',
	DATE_MODIFICATION_DEBUT: 'dateModificationDebut',
	DATE_MODIFICATION_FIN: 'dateModificationFin',
	PUBLIER_BY: 'publierPar',
	DATE_PUBLICATION_DEBUT: 'datePublicationDebut',
	DATE_PUBLICATION_FIN: 'datePublicationFin',
	MASQUER_BY: 'masquerPar',
	DATE_MASQUE_DEBUT: 'dateMasqueDebut',
	DATE_MASQUE_FIN: 'dateMasqueFin'
}

// Préférences utilisateur
export const USER_PREFERENCES = {
	ZONE: 'ZONE',
	PAYS: 'PAYS',
	EN_ATTENTE_VALIDATION: 'EN_ATTENTE_VALIDATION',
	DATE_CONNECTION: 'DATE_CONNECTION',
	LANGUE: 'LANGUE',
	SU: 'SU',
	DEVISE: 'DEVISE',
	FI_MARCHES: 'FI_MARCHES',
	FI_SEGMENT: 'FI_SEGMENT',
	FI_PAYS: 'FI_PAYS',
	FI_PRE_CLIENT_SECTOR: 'FI_PRE_CLIENT_SECTOR',
	FI_SUBSEGMENT: 'FI_SUBSEGMENT'
}

// Value list de l'application
export const VALUE_LIST_SHORTCUTS = {
	ZONE: 'ZONE',
	PAYS: 'COUNTRY',
	SU: 'SU',
	DEVISE: 'DEVISE',
	SUJET_CONTACT: 'SUJET_CONTACT',
	SUJET_DESCRIPTION_CONTACT: 'SUJET_DESCRIPTION_CONTACT',
	MARKET: 'LIST_MARKET',
	SECTOR: 'LIST_SECTOR',
	BUSINESS: 'LIST_BUSINESS_LINE',
	BUSINESS_SEGMENT: 'LIST_SEGMENT',
	BUSINESS_SUB_SEGMENT: 'LIST_SUB_SEGMENT',
	CORE_BUSINESS: 'CORE_BUSINESS',
	BID_BRIEF: 'BID_BRIEF',
	WIN_LOSE_LIST: 'WIN-LOSE-LIST'
}

// Poids des rôles utilisateurs
// Utile pour savoir si un rôle a accès à une page
export const ROLE_WEIGHT = {
	ROLE_ADMIN: 4,
	ROLE_CORRESPONDANT: 3,
	ROLE_MEMBRE: 2,
	ROLE_VISITEUR: 1
}

export const TYPE_CONTENU = {
	PROPOSITION_COMMERCIALE: 'PROPOSITION_COMMERCIALE',
	SAVOIR_FAIRE: 'SAVOIR_FAIRE',
	REFERENCE: 'REFERENCE'
}

// Valeur default
export const DEFAULT_ZONE = 'FI-Zone-VESA'

export const DEFAULT_LANGUAGE_ID = '2' //// Anglais par défaut
export const DEFAULT_LANGUAGE_CODE = 'en' //// Anglais par défaut
export const DEFAULT_SU = 'DEF_SU'
export const DEFAULT_DEVISE = 'DEVISE_EURO'
export const DEFAULT_DEVISE_LABEL = 'EUR'
export const DEFAULT_UNIT = 'SYST_UNIT_INT'
export const ACCUEIL_PATH = '/accueil'
export const RECHERCHE_DOCUMENT_PATH = '/documents'
export const GlOBAL_LANGUAGE_ID = '0' // Code de la langue non multivalué (sélecteurs, listes, ...)
export const CODE_SUJET_CORRESPONDANT_FORMULAIRE = 'OTHER_QUESTIONS' // Code pour la redirection avec le sujet correspondant
export const FR_LANGUAGE_ID = '1'
export const SP_LANGUAGE_ID = '3'
export const PL_LANGUAGE_ID = '4'
export const DEFAULT_LABEL = 'N/A' // Si nous n'avons pas de valeur pour une entrée de liste

// Enums utiles pour la recherche utilisateur
export const REQUEST = {
	VALID: 'VALID',
	TO_VALIDATE: 'TO_VALIDATE'
}
export const USER_CONNECTE = {
	SEMAINE: 'SEMAINE',
	MOIS: 'MOIS',
	TRIMESTRE: 'TRIMESTRE',
	ANNEE: 'ANNEE'
}

export const USER_CONNECTE_NB = {
	SEMAINE: 7,
	MOIS: 30,
	TRIMESTRE: 90,
	ANNEE: 365
}

export const USER_FORM = {
	PAYS: 'pays',
	MARKET: 'marche',
	SEGMENT: 'segment'
}

// Valeurs proposées dans le champ 'With attachment'
export const SEARCH_FORM_WITH_ATTACHMENT_CODES = {
	CASE_STUDY: 'CASE_STUDY',
	CERTIFICATE: 'CERTIFICATE'
}

// Valeurs proposées dans le champ 'Interne - Externe'
export const SEARCH_FORM_INTERNE_EXTERNE_CODES = {
	INTERNAL: 'Select-Oui',
	EXTERNAL: 'Select-Non'
}

export const UNSPECIFIED = 'Unspecified'

//Champs utilisés dans pour la recherche (RechercheDocumentForm.jsx)
export const TYPE_CONTENU_FIELD = 'typeContenu'
export const ZONE = 'zone'
export const BUSINESS_SEGMENT = 'businessSegment'
export const BUSINESS_SUB_SEGMENT = 'businessSubSegment'
export const MARKET = 'market'

//Champs du formulaire de la popin de filtre administrateur
//+ Options du selecteurs du premier champ
export const FIELDS_ADMIN_CORRESP = {
	STATUS: { name: 'status', permission: 1 },
	CONTRIBUTOR: { name: 'contributor', permission: 1 },
	DATE_CREATION: { name: 'dateCreation', permission: 1 },
	DATE_SOUMISSION: { name: 'dateSoumission', permission: 1 },
	DATE_PUBLICATION: { name: 'datePublication', permission: 1 },
	ADMIN_PUBLICATION: { name: 'publierPar', permission: 1 },
	DATE_LAST_MODIF: { name: 'dateLastModif', permission: 1 },
	USER_LAST_MODIF: { name: 'modifierPar', permission: 1 },
	DATE_MASQUED: { name: 'dateMasqued', permission: 1 },
	ADMIN_DELETE: { name: 'masquerPar', permission: 2 }
}

// Codes des options pour la checkbox type date du formulaire - filtre administrateur
export const TIMELINE = {
	AFTER: 'After',
	AT: 'At',
	BEFORE: 'Before',
	BETWEEN: 'Between'
}

// Champs non compris dans le selecteur de la popin de filtres administrateur
export const FIELDS_ADMIN_POPIN = {
	SECTION_FIELD : 'sectionField',
	CHOIX_TIMELINE: 'choixTimeline',
	CHAMP_DATE_1: 'champDate1',
	CHAMP_DATE_2: 'champDate2'
}

export const FIELDS_NO_FORMAT = {
	FILTRES_ADMIN : 'fieldsAdmin',
	FILTRES_AVANCE: 'fieldsAdvance',
	QUERY: 'query',
	ID_CONSULTE: 'idConsulte',
	SORT_FIELDS: 'sortFields',
	VIEW_FIELDS: 'viewFields',
	CONSULTATION: 'consultation',
	TITLE: 'title',
	WEEKLY_NOTIFICATION:'weeklyNotification'
}

export const FIELDS_SUPPRESSION_DOCUMENT_POPIN = {
	CHOIX_STATUS: 'choixStatus',
	MESSAGE: 'message'
}

export const ETATS_CHECKBOXES_COLUMNS = {
	NOT_CHECKED : 0,
	CHECKED: 1,
	INDETERMINATE: 2
}

export const ETATS_COLLAPSED_COLUMNS = {
	NOT_COLLAPSED : false,
	COLLAPSED: true,
}

export const SUFFIX_ID_ITEMS = {
	FIELD : '-F',
	SECTION: '-S',
	ADVANCED_FIELD : '-AF',
	ADVANCED_SECTION : '-AS',
}

export const iconPropertiesInheritLarge = {
	color: 'inherit',
	fontSize: 'large'
}

// Traduction du header qui affiche les titres des documents
export const TITLE_COLUMN_NAME = {
	1: 'Références',
	2: 'References',
	3: 'Referencias',
	4: 'Referencje'
}

// Traduction du header zone qui affiche les titres des documents
export const ZONE_COLUMN_NAME = {
	1: 'Zone',
	2: 'Zone',
	3: 'Zona',
	4: 'Strefa'
}
export const LOCAL_STORAGE_ITEM = {
	TOKEN : 'token',
	ACCESS_TOKEN: 'accessToken',
	REFRESH_TOKEN : 'refreshToken',
	TOKEN_EXPIRES_IN : 'expiresIn',
	TOKEN_EXPIRES_TIMESTAMP : 'expiresTimeStamp'
}

export const DIDOMI_CONFIG = {
	API_KEY: 'bde57b83-6753-44cf-b2df-b2b9d12c32f3',
	NOTICE_ID: 'jBRXFbiT'
}
