import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { push } from 'connected-react-router'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { getStyles } from 'isotope-client'
import GradientSeparateur from '../../../../components/GradientSeparateur'
import PaperBlocAccueil from './PaperBloc'

const styles = theme => getStyles({
	paper: {
		padding: 30,
	},
	container: {
		height: '100%',
		width: 594,
	},
	divTitre: {
		height: 40,
		marginBottom: 10
	},
	divInfos: {
		height: 32,
		marginBottom: 10
	},
	verticalCenter: {
		position: 'relative',
		top: '30%'
	},
	floatRight: {
		float: 'right'
	},
	picto: {
		fontSize: 56
	},
	nombreLevel2: {
		color: theme.palette.secondary.main,
		fontSize: 24,
		fontWeight: 700
	},
	nombreLevel3: {
		color: theme.palette.secondary.main,
		fontSize: 18,
		fontWeight: 600
	}
})

const ValidationBloc = ({ contenu: { key, number, link }, goTo, classes }) => {
	const blocIsEmpty = number === 0

	return (
		<PaperBlocAccueil className={classes.paper}>
			<Grid container direction="row" justify="space-between" alignContent="flex-start" className={classes.container}>
				{/*Titre*/}
				<Grid item container className={classes.divTitre} justify="space-between" alignItems="center">
					<Grid item xs={10}>
						<Typography variant="h3"><FormattedMessage id={`accueil.${key}.${blocIsEmpty ? 'emptyTitre' : 'titre'}`} /></Typography>
					</Grid>
					{!blocIsEmpty && <Grid item>
						<span className={classnames(classes.nombreLevel2, classes.verticalCenter, classes.floatRight)}>{number}</span>
					</Grid>}
				</Grid>

				<GradientSeparateur />

				{/*Contenu information*/}
				<Grid item xs={12}>
					<Typography variant="body2" align="justify">
						<FormattedMessage id={`accueil.${key}.${blocIsEmpty ? 'emptyDescription' : 'emptyDescription'}`} />
					</Typography>
				</Grid>
				{!blocIsEmpty && <Grid item container direction="row-reverse">
					<Button
						key="access"
						color="primary"
						variant="contained"
						onClick={() => goTo(link)}
					>
						<FormattedMessage id={`accueil.${key}.bouton`} />
					</Button>
				</Grid>}
			</Grid>
		</PaperBlocAccueil>
	)
}

const actions = {
	goTo: (link) => dispatch => dispatch(push(link))
}

ValidationBloc.propTypes = {
	contenu: PropTypes.object.isRequired, // Contient une clé représentant le bloc, la valeur et le lien de redirection
	goTo: PropTypes.func.isRequired,
	classes: PropTypes.object
}

export default compose(
	connect(undefined, actions),
	withStyles(styles)
)(ValidationBloc)
