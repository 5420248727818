import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import Typography from '@material-ui/core/Typography'
import Popin from '../../../../components/Popin'
import { validerDemande } from '../services/usersApi'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'

const ValiderPopin = ({ open, closePopup, userName, idUser, snackSuccess, snackError, getUsersList }) => {

	const valider = () => {
		return validerDemande(idUser)
			.then(() => {
				handleClose()
				snackSuccess(<FormattedMessage id="users.popin.validation.success" />)
				getUsersList()
			})
			.catch(() => snackError(<FormattedMessage id="users.popin.validation.error" />))
	}

	const handleClose = () => closePopup()

	const actions = [
		<Button
			color="inherit"
			variant="outlined"
			key="fermer"
			onClick={handleClose}
		>
			<FormattedMessage id="global.buttons.annuler" />
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			onClick={valider}
		>
			<FormattedMessage id="global.buttons.confirm" />
		</Button>
	]

	return (
		<Popin
			open={open}
			actions={actions}
			title={<FormattedMessage id="users.popin.validation.titlePopin" />}
			content={<Typography><FormattedMessage id="users.popin.validation.messagePopin" /> {userName} ?</Typography>}
			onClose={handleClose}
		/>
	)
}

ValiderPopin.propTypes = {
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	userName: PropTypes.string,
	idUser: PropTypes.string,
	getUsersList: PropTypes.func
}

export default compose(
	injectSnackActions
)(ValiderPopin)
