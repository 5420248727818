import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { FormattedDate, FormattedMessage, injectIntl } from 'react-intl'
import { getStyles } from 'isotope-client'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Grid, withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { DEFAULT_DEVISE, FIELD_SEARCH_ADMIN, GlOBAL_LANGUAGE_ID, PROFILS, SVG_ICONS, TYPE_CONTENU } from '../../../../utils/constants'
import SvgIcon from '../../../../components/icon/SvgIcon'
import LanguageIcon from '@material-ui/icons/Language'
import RoomIcon from '@material-ui/icons/Room'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'
import EditIcon from '@material-ui/icons/Edit'
import { injectLangues } from '../../../common/langue/langueInjector'
import {
	injectBusinessSegmentList,
	injectBusinessSubSegmentList,
	injectCoreBusinessList,
	injectDeviseList,
	injectMarketList,
	injectPaysList,
	injectSectorList,
	injectZoneList
} from '../../../common/valueList/ValueListInjector'
import ChipInformation from '../../../../components/ChipInformation'
import ChipInformationSelect from '../../../../components/ChipInformationSelect'
import AccessChecker, { hasAccess } from '../../../../components/security/AccessChecker'
import * as userSelectors from '../../user/services/userSelectors'
import { getUserEmail, getUserId, getUserLanguage, getUserSu } from '../../user/services/userSelectors'
import OverflowTypography from '../../../../components/OverflowTypography'
import { DOCUMENT_STATUS, FIELD_TYPE } from '../utils/documentConstants'
import { getConversionMap } from '../../../common/conversion/conversionSelectors'
import Link from '../../../../components/Link'
import * as favorisSelectors from '../../favoris/services/favorisSelectors'
import * as favorisActions from '../../favoris/services/favorisActions'
import { isCorrespondantValidForDocumentSearch, isDocumentPublicOrToHideOrToDelete, isJsonArray, isMemberValidForDocumentSearch } from '../../../../utils/utils'
import { normalizeNumber } from '../utils/documentUtils'

const style = (theme) => getStyles({
	card: {
		width: '96vw',
		maxWidth: '-webkit-fill-available',
		margin: 6,
		'& .MuiCardContent-root:last-child': {
			paddingBottom: 16
		}
	},
	icon: {
		display: 'flex',
		margin: '0px 32px 0px 10px'
	},
	iconBrown: {
		display: 'flex',
		margin: '0px 32px 0px 10px',
		color: theme.palette.draft
	},
	iconAttribut: {
		marginRight: 16,
		color: '#55555a'
	},
	iconEditAndFavorite: {
		margin: 10,
		float: 'right',
		color: '#55555a'
	},
	labelAttribut: {
		fontSize: 14
	},
	attributContainer: {
		alignItems: 'center'
	},
	h4: {
		fontSize: 18,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical'
	},
	business: {
		marginLeft: 10,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		fontSize: 14
	},
	coreBusiness: {
		marginLeft: 10,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
		fontSize: 14
	},
	fieldsAdvance: {
		backgroundColor: theme.palette.border,
		color: 'black'
	},
	pointer: {
		cursor: 'pointer',
		alignItems: 'center'
	},
	pointerTitle: {
		cursor: 'pointer',
		height: '100%'
	},
	chipsFilter: {
		display: 'inline-flex',
		maxWidth: 250
	},
	rowInfo: {
		height: 30
	}
})

const DocumentCard = ({
	usersList,
	document,
	fieldsAdvance,
	fieldsAdmin,
	paysList,
	zoneList,
	langues,
	businessSegmentList,
	businessSubSegmentList,
	coreBusinessList,
	marketList,
	sectorList,
	deviseList,
	isAdmin,
	isCorrespondantValid,
	isMemberValid,
	mesContribScreen,
	userLanguage,
	isFavorite,
	classes,
	addToFavorite,
	removeFromFavorite,
	setAnchorId,
	intl
}) => {
	const handleFiValues = (list, value) => list ? list.filter(x => JSON.parse(value) && JSON.parse(value).indexOf(x.code) !== -1).map(x => x.label).join(', ') : ''
	const labelDefautCurrency = (deviseList || []).find(devise => devise.code === DEFAULT_DEVISE)

	const renderIcon = () => {
		let iconPropertySelect

		const iconProperty = {
			className: classes.icon,
			color: 'secondary',
			fontSize: 'large'
		}
		const iconPropertyBrown = {
			className: classes.iconBrown,
			color: 'secondary',
			fontSize: 'large'
		}

		if (isDocumentPublicOrToHideOrToDelete(document, true)) {
			iconPropertySelect = iconProperty
		} else {
			iconPropertySelect = iconPropertyBrown
		}

		switch (document.typeContenu.valueField[GlOBAL_LANGUAGE_ID]) {
			case `${TYPE_CONTENU.SAVOIR_FAIRE}`:
				return <SvgIcon path={SVG_ICONS.SAVOIR_FAIRE} {...iconPropertySelect} />
			case `${TYPE_CONTENU.PROPOSITION_COMMERCIALE}`:
				return <SvgIcon path={SVG_ICONS.PROPOSITION_COMMERCIALE} {...iconPropertySelect} />
			case `${TYPE_CONTENU.REFERENCE}`:
				return <SvgIcon path={SVG_ICONS.REFERENCE} {...iconPropertySelect} />
			default:
				return <></>

		}
	}

	const handleFavorite = () => {
		const idContent = document.idContent.valueField[GlOBAL_LANGUAGE_ID]
		if (isFavorite) {
			return removeFromFavorite(idContent)
		}
		return addToFavorite(idContent)
	}

	const getValueInfo = (value) => {
		if (!!value) {
			return value
		}
		return 'NA'
	}

	const renderContributionChips = () => {

		if (!document.statusWorkFlow) {
			return <></>
		}
		return <>
			<ChipInformation text={<Typography variant="body2"><FormattedMessage id={`enums.documentStatus.${document.statusWorkFlow.valueField[GlOBAL_LANGUAGE_ID]}`} /></Typography>} />
			<ChipInformation text={<Typography variant="body2"><FormattedMessage id="document.card.created" /><FormattedDate value={document.dateCreation.valueField[GlOBAL_LANGUAGE_ID]} /></Typography>} />
			<ChipInformation text={<Typography variant="body2"><FormattedMessage id="document.card.modified" /><FormattedDate value={document.dateModification.valueField[GlOBAL_LANGUAGE_ID]} /></Typography>} />
			{document.dateSubmitWorkFlow && <ChipInformation text={<Typography variant="body2"><FormattedMessage id="document.card.submit" /><FormattedDate value={document.dateSubmitWorkFlow.valueField[GlOBAL_LANGUAGE_ID]} /></Typography>} />}
			{document.dateValidationWorkFlow &&
			<ChipInformation text={<Typography variant="body2"><FormattedMessage id="document.card.validated" /><FormattedDate value={document.dateValidationWorkFlow.valueField[GlOBAL_LANGUAGE_ID]} /></Typography>} />}
			{document.datePublishedWorkFlow &&
			<ChipInformation text={<Typography variant="body2"><FormattedMessage id="document.card.published" /><FormattedDate value={document.datePublishedWorkFlow.valueField[GlOBAL_LANGUAGE_ID]} /></Typography>} />}
			{(document.dateMaskedWorkFlow && document.statusWorkFlow.valueField[GlOBAL_LANGUAGE_ID] === DOCUMENT_STATUS.MASQUE) &&
			<ChipInformation text={<Typography variant="body2"><FormattedMessage id="document.card.masked" /><FormattedDate value={document.dateMaskedWorkFlow.valueField[GlOBAL_LANGUAGE_ID]} /></Typography>} />}
		</>
	}

	// Champs dans l'en-tête du document
	const zone = (zoneList && document.zone) ? zoneList.find(x => x.code === document.zone.valueField[GlOBAL_LANGUAGE_ID]) : {}
	const langue = (langues && document.langue) ? langues.find(x => x.id === document.langue.valueField[GlOBAL_LANGUAGE_ID]) : {}
	const title_langue = document.title.valueField[userLanguage] ? document.title.valueField[userLanguage] : ((document.langue && document.title.valueField[document.langue.valueField[GlOBAL_LANGUAGE_ID]]) ? document.title.valueField[document.langue.valueField[GlOBAL_LANGUAGE_ID]] : '')

	// Correspond aux multi-select de la sectionFI
	const pays = document.fi_COUNTRY_VALUE && document.fi_COUNTRY_VALUE.valueField[GlOBAL_LANGUAGE_ID] ? handleFiValues(paysList, document.fi_COUNTRY_VALUE.valueField[GlOBAL_LANGUAGE_ID]) : ''
	const businessSegment = document.fi_SEGMENTS_VALUE && document.fi_SEGMENTS_VALUE.valueField[GlOBAL_LANGUAGE_ID] ? handleFiValues(businessSegmentList, document.fi_SEGMENTS_VALUE.valueField[GlOBAL_LANGUAGE_ID]) : ''
	const businessSubSegment = document.fi_SUBSEGMENT_VALUE && document.fi_SUBSEGMENT_VALUE.valueField[GlOBAL_LANGUAGE_ID] ? handleFiValues(businessSubSegmentList, document.fi_SUBSEGMENT_VALUE.valueField[GlOBAL_LANGUAGE_ID]) : ''
	const coreBusiness = document.fi_CORE_BUSINESS_VALUE && document.fi_CORE_BUSINESS_VALUE.valueField[GlOBAL_LANGUAGE_ID] ? handleFiValues(coreBusinessList, document.fi_CORE_BUSINESS_VALUE.valueField[GlOBAL_LANGUAGE_ID]) : ''
	const market = document.fi_PRE_CLIENT_MARKET_VALUE && document.fi_PRE_CLIENT_MARKET_VALUE.valueField[GlOBAL_LANGUAGE_ID] ? handleFiValues(marketList, document.fi_PRE_CLIENT_MARKET_VALUE.valueField[GlOBAL_LANGUAGE_ID]) : ''
	const sector = document.fi_PRE_CLIENT_SECTOR_VALUE && document.fi_PRE_CLIENT_SECTOR_VALUE.valueField[GlOBAL_LANGUAGE_ID] ? handleFiValues(sectorList, document.fi_PRE_CLIENT_SECTOR_VALUE.valueField[GlOBAL_LANGUAGE_ID]) : ''

	return (
		<Card className={classes.card} id={document.idContent.valueField[GlOBAL_LANGUAGE_ID]}>
			<Link to={`/consultation-document/${document.idContent.valueField[GlOBAL_LANGUAGE_ID]}`} state={{ forceGoBack: 'true' }} onClick={() => setAnchorId(document.idContent.valueField[GlOBAL_LANGUAGE_ID])}>
				<CardContent>
					<Grid container>
						<Grid item container alignItems="center">
							<Grid item sm={1}>{renderIcon()}</Grid>
							<Grid item container sm={10} className={classes.pointerTitle} alignItems="center">
								<Grid item><Typography variant="h4" className={classes.h4}>{title_langue}</Typography></Grid>
							</Grid>
							<Grid item container justify="flex-end" sm={1}>
								{(isAdmin || isCorrespondantValid || isMemberValid) &&
								<Grid item sm={6}><Link to={`/edition-document/${document.idContent.valueField[GlOBAL_LANGUAGE_ID]}`} onClick={(e) => {
										e.stopPropagation()
										setAnchorId(document.idContent.valueField[GlOBAL_LANGUAGE_ID]) }}
								     state={{ forceGoBack: 'true' }}>
									<EditIcon className={classes.iconEditAndFavorite} />
								</Link>
								</Grid>
								}
								<AccessChecker access={[PROFILS.ADMIN, PROFILS.CORRESPONDANT, PROFILS.MEMBRE]}>
									<Grid item sm={6}>
										<Link onClick={(e) => {
											handleFavorite()
											e.stopPropagation()
										}}>
											{isFavorite ?
												<StarIcon className={classes.iconEditAndFavorite} /> :
												<StarBorderIcon className={classes.iconEditAndFavorite} />
											}
										</Link>
									</Grid>
								</AccessChecker>
							</Grid>
						</Grid>

						<Grid item sm={1} className={classes.pointer} />
						<Grid item sm={11} className={classes.pointer} container>
							<Grid item container sm={4} className={classes.rowInfo}>
								{langue && <Grid item container sm={12} className={classes.attributContainer}>
									<Grid item sm={2}><LanguageIcon className={classes.iconAttribut} /></Grid>
									<Grid item sm={10}><OverflowTypography className={classes.labelAttribut} variant="body1" noWrap><FormattedMessage id={`enums.langue.${langue.codeLang}`} /></OverflowTypography></Grid>
								</Grid>}
							</Grid>
							<Grid item sm={6}>
								<Grid item sm={12}>
									<OverflowTypography className={classes.business} variant="body1" noWrap>{businessSegment}</OverflowTypography>
								</Grid>
							</Grid>
							<Grid item container sm={4} className={classes.rowInfo}>
								<Grid item container sm={12} className={classes.attributContainer}>
									<Grid item sm={2}><RoomIcon className={classes.iconAttribut} /></Grid>
									<Grid item sm={10}><OverflowTypography className={classes.labelAttribut} variant="body1">{zone && <span>{zone.label}</span>} - {pays}</OverflowTypography></Grid>
								</Grid>
							</Grid>
							<Grid item sm={6}>
								<Grid item sm={12}>
									<OverflowTypography className={classes.business} variant="body1" noWrap>
										{businessSubSegment}
									</OverflowTypography>
								</Grid>
							</Grid>

							<Grid item container sm={4} className={classes.rowInfo}>
								{market.length > 0 && <Grid item container sm={12} className={classes.attributContainer}>
									<Grid item sm={2}><SvgIcon path={SVG_ICONS.CITY} color="inherit" fontSize="medium" className={classes.iconAttribut} /></Grid>
									<Grid item sm={10}><OverflowTypography className={classes.labelAttribut} variant="body1" noWrap>{market}{sector.length > 0 && <span> - {sector}</span>}</OverflowTypography></Grid>
								</Grid>}
							</Grid>
							<Grid item sm={6}>
								<Grid item sm={12}>
									<OverflowTypography className={classes.coreBusiness} variant="body1">
										{coreBusiness}
									</OverflowTypography>
								</Grid>
							</Grid>
						</Grid>


						<Grid item sm={1} />
						<Grid item sm={12}>
							{(!mesContribScreen && isAdmin && fieldsAdmin) &&
							<>
								{(fieldsAdmin[FIELD_SEARCH_ADMIN.STATUS] && fieldsAdmin[FIELD_SEARCH_ADMIN.STATUS] !== '0') && <>
									{(document.statusWorkFlow.valueField[GlOBAL_LANGUAGE_ID] !== DOCUMENT_STATUS.PUBLIE) &&
									<ChipInformation
										text={<OverflowTypography variant="body2" className={classes.chipsFilter}><FormattedMessage id={`enums.documentStatus.${document.statusWorkFlow.valueField[GlOBAL_LANGUAGE_ID]}`} /></OverflowTypography>} />
									}
								</>
								}
								{(fieldsAdmin[FIELD_SEARCH_ADMIN.CONTRIBUTOR]) &&
								<ChipInformation text={<OverflowTypography variant="body2" className={classes.chipsFilter}><FormattedMessage
									id="document.card.contributor" />{usersList && usersList.filter(item => item.email === document.contributor.valueField[GlOBAL_LANGUAGE_ID]).map(user => (`${user.firstname} ${user.lastname}`))}
								</OverflowTypography>} />
								}
								{((fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_CREATION_DEBUT] && fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_CREATION_DEBUT] !== '0') || (fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_CREATION_FIN] && fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_CREATION_FIN] !== '0')) &&
								<ChipInformation text={<OverflowTypography variant="body2" className={classes.chipsFilter}><FormattedMessage id="document.card.created" /><FormattedDate
									value={document.dateCreation.valueField[GlOBAL_LANGUAGE_ID]} /></OverflowTypography>} />
								}
								{((fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_SOUMISSION_DEBUT] && fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_SOUMISSION_DEBUT] !== '0') || (fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_SOUMISSION_FIN] && fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_SOUMISSION_FIN] !== '0')) &&
								<ChipInformation text={<OverflowTypography variant="body2" className={classes.chipsFilter}><FormattedMessage id="document.card.submit" /><FormattedDate
									value={document.dateSubmitWorkFlow && document.dateSubmitWorkFlow.valueField[GlOBAL_LANGUAGE_ID]} /></OverflowTypography>} />
								}
								{(fieldsAdmin[FIELD_SEARCH_ADMIN.MODIFIER_BY]) &&
								<ChipInformation text={<OverflowTypography variant="body2" className={classes.chipsFilter}><FormattedMessage
									id="document.card.modifiedBy" />{usersList && usersList.filter(item => item.id === document.idModifier.valueField[GlOBAL_LANGUAGE_ID]).map(user => (`${user.firstname} ${user.lastname}`))}
								</OverflowTypography>} />
								}
								{((fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_MODIFICATION_DEBUT] && fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_MODIFICATION_DEBUT] !== '0') || (fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_MODIFICATION_FIN] && fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_MODIFICATION_FIN] !== '0')) &&
								<ChipInformation text={<OverflowTypography variant="body2" className={classes.chipsFilter}><FormattedMessage id="document.card.modified" /><FormattedDate
									value={document.dateModification.valueField[GlOBAL_LANGUAGE_ID]} /></OverflowTypography>} />
								}
								{(fieldsAdmin[FIELD_SEARCH_ADMIN.PUBLIER_BY]) &&
								<ChipInformation text={<OverflowTypography variant="body2" className={classes.chipsFilter}><FormattedMessage
									id="document.card.publishedBy" />{(usersList && document.idPublishedWorkFlow && document.idPublishedWorkFlow.valueField) &&
								usersList.filter(item => item.id && document.idPublishedWorkFlow).filter(item => item.id === document.idPublishedWorkFlow.valueField[GlOBAL_LANGUAGE_ID]).map(user => (`${user.firstname} ${user.lastname}`))}
								</OverflowTypography>} />
								}
								{((fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_PUBLICATION_DEBUT] && fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_PUBLICATION_DEBUT] !== '0') || (fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_PUBLICATION_FIN] && fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_PUBLICATION_FIN] !== '0')) &&
								<ChipInformation text={<OverflowTypography variant="body2" className={classes.chipsFilter}><FormattedMessage id="document.card.published" /><FormattedDate
									value={document.datePublishedWorkFlow && document.datePublishedWorkFlow.valueField[GlOBAL_LANGUAGE_ID]} /></OverflowTypography>} />
								}
								{(fieldsAdmin[FIELD_SEARCH_ADMIN.MASQUER_BY]) &&
								<ChipInformation text={<OverflowTypography variant="body2" className={classes.chipsFilter}><FormattedMessage
									id="document.card.maskedBy" />{(usersList && document.idMaskedWorkFlow && document.idMaskedWorkFlow.valueField) &&
								usersList.filter(item => item.id === document.idMaskedWorkFlow.valueField[GlOBAL_LANGUAGE_ID]).map(user => (`${user.firstname} ${user.lastname}`)
								)}
								</OverflowTypography>} />
								}
								{((fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_MASQUE_DEBUT] && fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_MASQUE_DEBUT] !== '0') || (fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_MASQUE_FIN] && fieldsAdmin[FIELD_SEARCH_ADMIN.DATE_MASQUE_FIN] !== '0')) &&
								<ChipInformation
									text={<OverflowTypography variant="body2" className={classes.chipsFilter}><FormattedMessage id="document.card.masked" /><FormattedDate
										value={document.dateMaskedWorkFlow && document.dateMaskedWorkFlow.valueField[GlOBAL_LANGUAGE_ID]} /></OverflowTypography>} />
								}
							</>
							}
							{fieldsAdvance &&
							<>
								{fieldsAdvance.map((fieldAdvance, indexAdv) => {
										if (document.fieldAdvanceSearch && document.fieldAdvanceSearch[fieldAdvance.code]) {
											let fieldAdvanceSearchs = document.fieldAdvanceSearch[fieldAdvance.code]
											switch (fieldAdvance.type) {
												case FIELD_TYPE.TEXT: {
													const valueConcat = fieldAdvanceSearchs.map(fieldAdvanceSearch => {
														if (fieldAdvanceSearch.confidential === 'false' || ((isAdmin || isCorrespondantValid || isMemberValid ) && fieldAdvanceSearch.confidential === 'true')) {
															if (fieldAdvanceSearch.valueField[userLanguage]) {
																if (isJsonArray(fieldAdvanceSearch.valueField[userLanguage])) {
																	return JSON.parse(fieldAdvanceSearch.valueField[userLanguage]).filter(item => item !== '').map(valueText => valueText).join(', ')
																} else {
																	return fieldAdvanceSearch.valueField[userLanguage]
																}
															}
														}
														return '******'
													}).join(' // ')
													return <ChipInformation key={`fieldAdvance-${indexAdv}`} className={classes.fieldsAdvance}
													                        text={<OverflowTypography variant="body2" className={classes.chipsFilter}>{fieldAdvance.label} : {getValueInfo(valueConcat)}</OverflowTypography>} />
												}
												case FIELD_TYPE.SELECT:
												case FIELD_TYPE.MULTI_SELECT: {
													return <ChipInformationSelect key={`fieldAdvance-${indexAdv}`} fieldAdvance={fieldAdvance} fieldSearch={fieldAdvanceSearchs}
													                              isConfidential={(isAdmin || isCorrespondantValid || isMemberValid)}
													/>
												}
												case FIELD_TYPE.NUMBER: {
													const valueConcat = fieldAdvanceSearchs.map(fieldAdvanceSearch => {
														if (fieldAdvanceSearch.confidential === 'false' || ((isAdmin || isCorrespondantValid || isMemberValid) && fieldAdvanceSearch.confidential === 'true')) {
															if (isJsonArray(fieldAdvanceSearch.valueField[GlOBAL_LANGUAGE_ID])) {
																return JSON.parse(fieldAdvanceSearch.valueField[GlOBAL_LANGUAGE_ID]).filter(item => item !== '').map(valueNumber => {
																	return normalizeNumber(valueNumber)
																}).join(', ')
															} else {
																return normalizeNumber(fieldAdvanceSearch.valueField[GlOBAL_LANGUAGE_ID])
															}
														}
														return '******'
													}).join(' // ')
													return <ChipInformation key={`fieldAdvance-${indexAdv}`} className={classes.fieldsAdvance}
													                        text={<OverflowTypography variant="body2" className={classes.chipsFilter}>{fieldAdvance.label} : {getValueInfo(valueConcat)}</OverflowTypography>} />
												}
												case FIELD_TYPE.NUMBER_UNIT: {
													const valueConcat = fieldAdvanceSearchs.map(fieldAdvanceSearch => {
														if (fieldAdvanceSearch.confidential === 'false' || ((isAdmin || isCorrespondantValid || isMemberValid) && fieldAdvanceSearch.confidential === 'true')) {
															const unite = JSON.parse(fieldAdvance.unite)
															return normalizeNumber(unite.coeff * fieldAdvanceSearch.valueField[GlOBAL_LANGUAGE_ID]) + ' ' + unite.label
														}
														return '******'
													}).join(' // ')
													return <ChipInformation key={`fieldAdvance-${indexAdv}`} className={classes.fieldsAdvance}
													                        text={<OverflowTypography variant="body2" className={classes.chipsFilter}>{fieldAdvance.label} : {getValueInfo(valueConcat)}</OverflowTypography>} />
												}
												case FIELD_TYPE.AMOUNT: {
													const valueConcat = fieldAdvanceSearchs.map(fieldAdvanceSearch => {
														if (fieldAdvanceSearch.confidential === 'false' || ((isAdmin || isCorrespondantValid || isMemberValid) && fieldAdvanceSearch.confidential === 'true')) {
															return normalizeNumber(fieldAdvanceSearch.valueField[GlOBAL_LANGUAGE_ID]) + ' ' + (labelDefautCurrency ? labelDefautCurrency.label : '')
														}
														return '******'
													}).join(' // ')
													return <ChipInformation key={`fieldAdvance-${indexAdv}`} className={classes.fieldsAdvance}
													                        text={<OverflowTypography variant="body2" className={classes.chipsFilter}>{fieldAdvance.label} : {getValueInfo(valueConcat)}</OverflowTypography>} />
												}
												case FIELD_TYPE.DATE: {
													const valueConcat = fieldAdvanceSearchs.map(fieldAdvanceSearch => {
														if (fieldAdvanceSearch.confidential === 'false' || ((isAdmin || isCorrespondantValid || isMemberValid) && fieldAdvanceSearch.confidential === 'true')) {
															if (fieldAdvanceSearch.valueField[GlOBAL_LANGUAGE_ID].includes('[')) {
																const value = fieldAdvanceSearch.valueField[GlOBAL_LANGUAGE_ID].replace('[', '').replace(']', '')
																return value.split(',').filter(item => item !== '').map(valueDate => {
																	return intl.formatDate(valueDate, {
																		year: 'numeric',
																		month: 'numeric',
																		day: 'numeric'
																	})
																}).join(', ')
															} else {
																return intl.formatDate(fieldAdvanceSearch.valueField[GlOBAL_LANGUAGE_ID], {
																	year: 'numeric',
																	month: 'numeric',
																	day: 'numeric'
																})
															}
														}
														return '******'
													}).join(' // ')
													return <ChipInformation key={`fieldAdvance-${indexAdv}`} className={classes.fieldsAdvance}
													                        text={<OverflowTypography variant="body2" className={classes.chipsFilter}>{fieldAdvance.label} : {getValueInfo(valueConcat)}</OverflowTypography>} />
												}
												case FIELD_TYPE.FILE: {
													const valueConcat = fieldAdvanceSearchs.map(fieldAdvanceSearch => {
														if (fieldAdvanceSearch.confidential === 'false' || ((isAdmin || isCorrespondantValid || isMemberValid) && fieldAdvanceSearch.confidential === 'true')) {
															return (JSON.parse(fieldAdvanceSearch.valueField[GlOBAL_LANGUAGE_ID])).map(file => file).join(', ')
														}
														return '******'
													}).join(' // ')
													return <ChipInformation key={`fieldAdvance-${indexAdv}`} className={classes.fieldsAdvance}
													                        text={<OverflowTypography variant="body2" className={classes.chipsFilter}>{fieldAdvance.label} : {getValueInfo(valueConcat)}</OverflowTypography>} />
												}
												default:
													return <></>
											}
										}
										return <></>
									}
								)}
							</>
							}
							{mesContribScreen && renderContributionChips()}
						</Grid>
					</Grid>
				</CardContent>
			</Link>
		</Card>

	)
}

const actions = {
	goTo: (pathname, state) => dispatch => dispatch(push({
		pathname,
		state
	})),
	addToFavorite: favorisActions.addDocumentToFavorite,
	removeFromFavorite: favorisActions.removeDocumentFromFavorite
}

DocumentCard.propTypes = {
	usersList: PropTypes.array,
	document: PropTypes.object,
	zoneList: PropTypes.array,
	paysList: PropTypes.array,
	langues: PropTypes.array,
	deviseList: PropTypes.array,
	businessSegmentList: PropTypes.array,
	businessSubSegmentList: PropTypes.array,
	coreBusinessList: PropTypes.array,
	marketList: PropTypes.array,
	mesContribScreen: PropTypes.bool,
	isFavorite: PropTypes.bool,
	fieldsAdvance: PropTypes.array,
	fieldsAdmin: PropTypes.object,
	addToFavorite: PropTypes.func,
	removeFromFavorite: PropTypes.func,
	setAnchorId: PropTypes.func,
}

const mapStateToProps = (state, { document }) => {
	const authorities = userSelectors.getAuthorities(state)
	const userEmail=  getUserEmail(state)

	return {
		userId: getUserId(state),
		userLanguage: getUserLanguage(state),
		userEmail,
		isAdmin: hasAccess(PROFILS.ADMIN, authorities),
		isCorrespondant: hasAccess(PROFILS.CORRESPONDANT, authorities),
		isCorrespondantValid: hasAccess(PROFILS.CORRESPONDANT, authorities) && ((document.contributor && document.contributor.valueField[GlOBAL_LANGUAGE_ID] === userEmail) || isCorrespondantValidForDocumentSearch(userSelectors.getUser(state), document)),
		isMemberValid: hasAccess(PROFILS.MEMBRE, authorities) && ((document.contributor && document.contributor.valueField[GlOBAL_LANGUAGE_ID] === userEmail) || isMemberValidForDocumentSearch(userSelectors.getUser(state), document)),
		conversionMap: getConversionMap(state),
		userSu: getUserSu(state),
		isFavorite: favorisSelectors.isDocumentFavorited(state, document && document.idContent && document.idContent.valueField[GlOBAL_LANGUAGE_ID])
	}
}

export default compose(
	injectZoneList,
	injectPaysList,
	injectLangues,
	injectBusinessSegmentList,
	injectBusinessSubSegmentList,
	injectCoreBusinessList,
	injectMarketList,
	injectSectorList,
	injectDeviseList,
	injectIntl,
	connect(mapStateToProps, actions),
	withStyles(style)
)(DocumentCard)
