import { reducers as reducersIsotope } from 'isotope-client'
import { entityReducer } from 'isotope-client-cms'
import loginReducerFactory from '../modules/fo/login/services/loginReducers'
import { configuration } from '../modules/fo/configuration/configurationReducer'
import { document } from '../modules/fo/document/services/documentReducers'
import { model } from '../modules/common/model/modelReducers'
import { langues } from '../modules/common/langue/langueReducers'
import { conversion } from '../modules/common/conversion/conversionReducers'
import { favoris } from '../modules/fo/favoris/services/favorisReducers'
import { likes } from '../modules/fo/like/likeReducers'
import { openVisitPopup } from '../modules/fo/user/services/userReducer'
import { additionalFilters } from '../modules/bo/additionalFilters/services/additionalFiltersReducer'
import { tabDocument } from '../modules/fo/tabDocument/services/tabDocumentReducers'

export default {
	...reducersIsotope,
	langues,
	user: loginReducerFactory(window.localStorage),
	configuration,
	document,
	conversion,
	model,
	favoris,
	likes,
	openVisitPopup,
	entityModels: entityReducer,
	additionalFilters,
	tabDocument
}
