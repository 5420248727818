import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { goBack, push } from 'connected-react-router'
import { getStyles, getUser as setUser } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, formValueSelector, getFormValues, isInvalid, isSubmitting, reduxForm } from 'redux-form'
import Button from '../../../components/Button'
import Input from '../../../components/form/Input'
import Select from '../../../components/form/Select'
import CenteredLayout from '../../../components/layout/CenteredLayout'
import Link from '../../../components/Link'
import Popin from '../../../components/Popin'
import AccessChecker, { hasAccess } from '../../../components/security/AccessChecker'
import checkRole from '../../../components/security/RoleChecker'
import { IntlContext } from '../../../config/IntlContext'
import {
	DEFAULT_DEVISE,
	DEFAULT_LANGUAGE_ID,
	DEFAULT_UNIT,
	ETAT_ACCESS_REQUEST,
	PROFILS,
	ROLE_WEIGHT,
	USER_PREFERENCES,
	VALUE_LIST_SHORTCUTS
} from '../../../utils/constants'
import { readPreferenceList } from '../../../utils/utils'
import * as listesSelectors from '../../bo/listes/listesSelectors'
import { injectLangues } from '../../common/langue/langueInjector'
import {
	injectBusinessSegmentList, injectBusinessSubSegmentList,
	injectDeviseList,
	injectMarketList,
	injectPaysList, injectSectorList,
	injectSuList,
	injectZoneList
} from '../../common/valueList/ValueListInjector'
import { FI_ALL_USER, FI_FACILITATEUR, FI_TRIPLET } from '../document/utils/documentConstants'
import EnregistrerPopin from './component/EnregistrerPopin'
import PerimetreField from './component/PerimetreField'
import { loadUser } from './services/injectUser'
import { closeVisitPopup, openVisitPopup, updatePreferences } from './services/userActions'
import { askAccess, enregistrerUser, getMe } from './services/usersApi'
import * as userSelectors from './services/userSelectors'
import { getOpenVisitPopup } from './services/userSelectors'

const styles = (theme) => getStyles({
	retour: {
		color: theme.palette.text.main,
		display: 'flex',
		alignItems: 'center',
		marginLeft: '-10px',
		marginBottom: 20
	},
	paper: {
		padding: 40,
		marginTop: 20,
		width: '100%',
		//empeche le form de changer de taille s'il y a trop d'elements selectionés dans un multiselect
		overflow: 'hidden'
	},
	secondPartTitle: {
		marginLeft: 10,
		marginTop: 30,
		marginBottom: 30
	},
	buttonAnnuler: {
		margin: 10,
		float: 'right',
		fontWeight: 500
	},
	buttonSave: {
		margin: 10
	},
	zoneVisiteur: {
		paddingLeft: 10,
		paddingRight: 10,
		marginTop: -10,
		"& > span":{
			display: "inline-block"
		}
	},
	preInfoVisiteur: {
		fontFamily: 'inherit'
	},
	marginTopActions: {
		marginTop: '6rem'
	}
})

const validate = (values) => {
	const errors = {}

	if (!values.zone) {
		errors.zone = { id: 'global.errors.obligatoire' }
	}

	if (!values.profil) {
		errors.profil = { id: 'global.errors.obligatoire' }
	}

	if (!values.pays) {
		errors.pays = { id: 'global.errors.obligatoire' }
	}

	if (!values.langue) {
		errors.langue = { id: 'global.errors.obligatoire' }
	}

	if (!values.devise) {
		errors.devise = { id: 'global.errors.obligatoire' }
	}

	if (!values.su) {
		errors.su = { id: 'global.errors.obligatoire' }
	}

	return errors
}

const PROFIL = 'profil'

const UsersPage = ({
	classes,
	user,
	paysList,
	businessSegmentList,
	marketList,
	zoneList,
	businessSubSegmentList,
	sectorList,
	langues,
	suList,
	deviseList,
	goToSearch,
	goToHome,
	invalid,
	handleSubmit,
	monCompte,
	isAdmin,
	canAskForAccess,
	hasAccessDeclined,
	preferences,
	submitting,
	reset,
	change,
	snackSuccess,
	snackError,
	updatePreferences,
	setUser,
	openVisitPopup,
	closeVisitPopup,
	visitOpen,
	formValues
}) => {
	const [openSave, setOpenSave] = React.useState(false)
	const [params, setParams] = React.useState({})
	const { changeLanguage } = React.useContext(IntlContext)

	const enregistrer = (values) => {
		if (!monCompte) {
			setParams(values)
			setOpenSave(true)
		} else if (canAskForAccess) {
			askAccess(values)
				.then(() => {
					getMe()
						.then(user => {
							setUser({ id: user.id, accessRequest: user.accessRequest, preferences: user.preferences })
							openVisitPopup()
							changeLanguage(values.langue)
						})
				})
		} else {
			enregistrerUtilisateur(values)
		}
	}

	const annuler = () => {
		if (!monCompte) {
			reset()
			goToSearch()
		} else {
			reset()
		}
	}

	const enregistrerUtilisateur = (params) => {
		return enregistrerUser(user.id, params)
			.then(() => {
				setOpenSave(false)
				snackSuccess(<FormattedMessage id="modificationUser.popin.success" />)

				// Rechargement de la langue et des préférences
				const listToDic = (key, list) => list.reduce((acc, curr) => ({
					...acc,
					[FI_ALL_USER.find(elem => elem.fieldName === key).pref + curr]: '1'
				}), {})

				const fiPref = Object.keys(params).reduce((acc, key) => key.startsWith('FI_') ? { ...acc, ...listToDic(key, params[key]) } : acc, {})

				if (monCompte) {
					changeLanguage(params.langue)
					let newPref = {
						...preferences,
						LANGUE: params.langue,
						DEVISE: params.devise,
						PAYS: params.pays,
						SU: params.su,
						ZONE: params.zone
					}
					newPref = Object.keys(newPref).reduce((acc, key) => key.startsWith('FI_') ? acc : {
						...acc,
						[key]: newPref[key]
					}, {})
					updatePreferences({
						...newPref,
						...fiPref
					})
				}

			})
			.catch((e) => {
				console.log(e)
				snackError(<FormattedMessage id="modificationUser.popin.error" />)
			})
	}
	const profileId = formValues && formValues[PROFIL]

	const hasFiTriplet = profileId => +profileId === PROFILS.ADMIN.id || +profileId === PROFILS.CORRESPONDANT.id

	const hasFiSubElement = profileId => +profileId === PROFILS.MEMBRE.id

	const clearTripletFields = (event, value) => {
		if (!hasFiTriplet(value)) {
			FI_TRIPLET.forEach(element => {
				change(element.fieldName, [])
			})
		}
	}

	return (
		<CenteredLayout>
			{!monCompte && <Link to="/utilisateurs" className={classes.retour} onClick={goToSearch} isReturn>
				<ArrowLeftIcon fontSize="large" />
				<FormattedMessage id="modificationUser.retour" />
			</Link>}
			<Typography variant="h1">
				<FormattedMessage
					id={`modificationUser.${monCompte ? 'titleCompte' : 'title'}`} /> {!monCompte && <>{user.firstname} {user.lastname}</>}
			</Typography>
			<Grid container>
				<Grid item xs={12} sm={9} container>
					<Paper className={classes.paper}>
						<form>
							<Grid container>
								<Grid item sm={6}>
									<Field
										component={Input}
										name="nom"
										label={<FormattedMessage id="modificationUser.form.nom" />}
										disabled
									/>
								</Grid>
								<Grid item sm={6}>
									<Field
										component={Input}
										name="prenom"
										label={<FormattedMessage id="modificationUser.form.prenom" />}
										disabled
									/>
								</Grid>
								<Grid item sm={6}>
									<Field
										component={Input}
										name="email"
										label={<FormattedMessage id="modificationUser.form.email" />}
										disabled
									/>
								</Grid>
								<Grid item sm={6}>
									<Field
										component={Select}
										name="zone"
										label={<FormattedMessage id="modificationUser.form.zone" />}
										disabled={!(isAdmin || (canAskForAccess && !hasAccessDeclined))}
										onChange={() => {
											change('pays', null)
											change('FI_COUNTRY', [])
										}}
									>
										{zoneList && zoneList.map(zoneElement =>
											<MenuItem key={`ZONE-${zoneElement.code}`} value={zoneElement.code}>
												{zoneElement.label}
											</MenuItem>
										)}
									</Field>
								</Grid>
								<Grid item sm={6}>
									<AccessChecker access={PROFILS.ADMIN}>
										<Field
											component={Select}
											name={PROFIL}
											label={<FormattedMessage id="modificationUser.form.profil" />}
											disabled={monCompte}
											onChange={clearTripletFields}
										>
											{Object.keys(PROFILS).map((key) =>
												<MenuItem key={key} value={PROFILS[key].id}>
													<FormattedMessage id={`constants.profils.${key}`} />
												</MenuItem>
											)}
										</Field>
									</AccessChecker>
								</Grid>
								<Grid item sm={6}>
									<Field
										component={Select}
										name="pays"
										disabled={!(isAdmin || (canAskForAccess && !hasAccessDeclined))}
										label={<FormattedMessage id="modificationUser.form.paysOrigine" />}
									>
										{paysList && paysList.map(paysElement =>
											<MenuItem key={`PAYS-${paysElement.code}`} value={paysElement.code}>
												{paysElement.label}
											</MenuItem>
										)}
									</Field>
								</Grid>
								<Grid item sm={6}/>
								{canAskForAccess && !hasAccessDeclined && <Grid item sm={6}>
									<Typography variant="caption" className={classes.zoneVisiteur}><FormattedMessage
										id="modificationUser.form.zoneVisiteur" /></Typography>
								</Grid>}
							</Grid>
							<Typography variant="h3" className={classes.secondPartTitle}><FormattedMessage
								id="modificationUser.form.titlePart" /></Typography>
							<Grid container>
								<Grid item sm={6}>
									<Field
										component={Select}
										name="langue"
										label={<FormattedMessage id="modificationUser.form.langue" />}
									>
										{langues && langues.map(l =>
											<MenuItem key={`LANGUE-${l.id}`} value={l.id}>
												<FormattedMessage id={`enums.langue.${l.codeLang}`} />
											</MenuItem>
										)}
									</Field>
								</Grid>
								<Grid item sm={6}>
									<Field
										component={Select}
										name="devise"
										label={<FormattedMessage id="modificationUser.form.devise" />}
									>
										{deviseList && deviseList.map(d =>
											<MenuItem key={`DEVISE-${d.code}`} value={d.code}>
												{d.label}
											</MenuItem>
										)}
									</Field>
								</Grid>
								<Grid item sm={6}>
									<Field
										component={Select}
										name="su"
										label={<FormattedMessage id="modificationUser.form.su" />}
									>
										{suList && suList.map(s =>
											<MenuItem key={`SU-${s.code}`} value={s.code}>
												{s.label}
											</MenuItem>
										)}
									</Field>
								</Grid>
							</Grid>
							{hasFiTriplet(profileId) &&
							<>
								<Typography variant="h3" className={classes.secondPartTitle}>
									<FormattedMessage id="modificationUser.form.paramGestion" />
								</Typography>
								<Grid container>

									<PerimetreField name={FI_TRIPLET.find(element => element.pref === USER_PREFERENCES.FI_PAYS).fieldName}
													elementList={paysList}
									                preferences={preferences}
									                preferenceName={USER_PREFERENCES.FI_PAYS}
									/>
									<PerimetreField name={FI_TRIPLET.find(element => element.pref === USER_PREFERENCES.FI_MARCHES).fieldName}
									                elementList={marketList} preferences={preferences}
									                preferenceName={USER_PREFERENCES.FI_MARCHES} />
									<PerimetreField name={FI_TRIPLET.find(element => element.pref === USER_PREFERENCES.FI_SEGMENT).fieldName}
													elementList={businessSegmentList} preferences={preferences}
									                preferenceName={USER_PREFERENCES.FI_SEGMENT} />
								</Grid>
							</>
							}
							{hasFiSubElement(profileId) &&
								<>
									<Typography variant="h3" className={classes.secondPartTitle}>
										<FormattedMessage id="modificationUser.form.paramSubElement" />
									</Typography>
									<Grid container>

										<PerimetreField name={FI_FACILITATEUR.find(element => element.pref === USER_PREFERENCES.FI_PRE_CLIENT_SECTOR).fieldName}
														elementList={sectorList}
										                preferences={preferences}
										                preferenceName={USER_PREFERENCES.FI_PRE_CLIENT_SECTOR}
										/>
										<PerimetreField name={FI_FACILITATEUR.find(element => element.pref === USER_PREFERENCES.FI_SUBSEGMENT).fieldName}
										                elementList={businessSubSegmentList} preferences={preferences}
										                preferenceName={USER_PREFERENCES.FI_SUBSEGMENT} />
									</Grid>
								</>
							}

							<Grid container className={classes.marginTopActions}>
								{canAskForAccess && <Grid item sm={12}>
									<Typography variant="body1" className={classes.zoneVisiteur}>
										<pre className={classes.preInfoVisiteur}><FormattedMessage
											id="modificationUser.infoVisiteur" /></pre>
									</Typography>
								</Grid>}
								<Grid item sm={6}>
									<Button className={classes.buttonAnnuler} color="inherit" variant="outlined" onClick={annuler}>
										<FormattedMessage id="global.buttons.annuler" />
									</Button>
								</Grid>
								<Grid item sm={6}>
									<Button
										className={classes.buttonSave}
										color="primary"
										variant="contained"
										onClick={handleSubmit(enregistrer)}
										disabled={invalid}
										loading={submitting}
									>
										<FormattedMessage id={canAskForAccess ? 'modificationUser.btnAsk' : 'global.buttons.save'} />
									</Button>
								</Grid>
							</Grid>
						</form>

						{monCompte &&
							<div className="beyableanalyticsoptout" style={{ minHeight: 20 }}></div>
						}

					</Paper>
				</Grid>
			</Grid>
			<EnregistrerPopin open={openSave} closePopup={() => setOpenSave(false)}
			                  userName={`${user.firstname} ${user.lastname}`} params={params}
			                  enregistrerUser={enregistrerUtilisateur} />
			<Popin
				open={visitOpen}
				actions={[
					<Button
						key="valider"
						color="primary"
						variant="contained"
						onClick={() => {
							closeVisitPopup()
							goToHome()
						}}
					>
						<FormattedMessage id="modificationUser.backHome" />
					</Button>
				]}
				title={<FormattedMessage id="modificationUser.askTitle" />}
				content={<FormattedMessage id="modificationUser.askMessage" />}
			/>
		</CenteredLayout>
	)
}

const FORM_NAME = 'modificationUser'

const getDefaultMapStateToProps = (state, user) => {
	const authorities = userSelectors.getAuthorities(state)
	const isAdmin = hasAccess(PROFILS.ADMIN, authorities)
	const accessRequest = user && user.accessRequest
	const canAskForAccess = hasAccess(PROFILS.VISITEUR, authorities) &&
		((accessRequest && !accessRequest.dateValidation && (!accessRequest.dateDemande || (!!accessRequest.dateDemande && !!accessRequest.dateRefus && accessRequest.etat !== ETAT_ACCESS_REQUEST.EN_ATTENTE))) || !accessRequest)

	const hasAccessDeclined = accessRequest && accessRequest.dateRefus
	const zoneSelected = formValueSelector(FORM_NAME)(state, 'zone')
	const zone = user && user.preferences && user.preferences[USER_PREFERENCES.ZONE]
	return ({
		paysList: listesSelectors.getFilteredListsPays(state, zoneSelected, VALUE_LIST_SHORTCUTS.PAYS),
		preferences: (user && user.preferences) || {},
		submitting: isSubmitting(FORM_NAME)(state),
		initialValues: {
			nom: user && user.lastname,
			prenom: user && user.firstname,
			email: user && user.email,
			zone: zone,
			pays: user && user.preferences && user.preferences[USER_PREFERENCES.PAYS],
			FI_COUNTRY: readPreferenceList(user, USER_PREFERENCES.FI_PAYS, zone),
			FI_PRE_CLIENT_MARKET: readPreferenceList(user, USER_PREFERENCES.FI_MARCHES),
			FI_SEGMENTS: readPreferenceList(user, USER_PREFERENCES.FI_SEGMENT),
			FI_PRE_CLIENT_SECTOR: readPreferenceList(user, USER_PREFERENCES.FI_PRE_CLIENT_SECTOR),
			FI_SUBSEGMENT: readPreferenceList(user, USER_PREFERENCES.FI_SUBSEGMENT),
			profil: user && user.roles && user.roles[0].id,
			langue: (user && user.preferences && user.preferences[USER_PREFERENCES.LANGUE]) ? user.preferences[USER_PREFERENCES.LANGUE] : DEFAULT_LANGUAGE_ID,
			devise: (user && user.preferences && user.preferences[USER_PREFERENCES.DEVISE]) ? user.preferences[USER_PREFERENCES.DEVISE] : DEFAULT_DEVISE,
			su: (user && user.preferences && user.preferences[USER_PREFERENCES.SU]) ? user.preferences[USER_PREFERENCES.SU] : DEFAULT_UNIT
		},
		isAdmin,
		canAskForAccess,
		hasAccessDeclined,
		invalid: isInvalid(FORM_NAME)(state),
		visitOpen: getOpenVisitPopup(state),
		formValues: getFormValues(FORM_NAME)(state)
	})
}

UsersPage.propTypes = {
	user: PropTypes.object,
	paysList: PropTypes.array,
	zoneList: PropTypes.array,
	langues: PropTypes.array,
	suList: PropTypes.array,
	deviseList: PropTypes.array,
	businessSubSegmentList: PropTypes.array,
	sectorList: PropTypes.array,
	monCompte: PropTypes.bool,
	isAdmin: PropTypes.bool,
	canAskForAccess: PropTypes.bool,
	hasAccessDeclined: PropTypes.bool,
	preferences: PropTypes.object,
	submitting: PropTypes.bool,
	goToSearch: PropTypes.func,
	goToHome: PropTypes.func,
	updatePreferences: PropTypes.func,
	change: PropTypes.func,
	reset: PropTypes.func,
	setUser: PropTypes.func
}

const actions = {
	goToSearch: () => dispatch => dispatch(goBack()),
	goToHome: () => dispatch => dispatch(push(`/accueil`)),
	updatePreferences,
	setUser,
	openVisitPopup,
	closeVisitPopup
}

export const getDefaultCompose = () => compose(
	loadUser,
	injectZoneList,
	injectPaysList,
	injectDeviseList,
	injectSuList,
	injectSectorList,
	injectBusinessSubSegmentList,
	injectLangues,
	withStyles(styles),
	injectSnackActions,
	injectMarketList,
	injectBusinessSegmentList
)

export const MonCompte = compose(
	getDefaultCompose(),
	connect((state) => {
		const user = userSelectors.getUser(state)
		const defaultMapState = getDefaultMapStateToProps(state, user)

		let profil = PROFILS.VISITEUR.id
		if (user && user.authorities && user.authorities.length > 0 && user.authorities[0].authority) {
			const profilFound = Object.values(PROFILS).find(profil => profil.role === user.authorities[0].authority)
			if (profilFound) {
				profil = profilFound.id
			}
		}

		return ({
			...defaultMapState,
			initialValues: {
				...(defaultMapState.initialValues || {}),
				profil
			},
			monCompte: true,
			user
		})
	}, actions),
	reduxForm({
		form: FORM_NAME,
		enableReinitialize: true,
		validate
	})
)(UsersPage)

export default compose(
	checkRole(ROLE_WEIGHT.ROLE_ADMIN),
	getDefaultCompose(),
	connect((state, { user }) => getDefaultMapStateToProps(state, user), actions),
	reduxForm({
		form: FORM_NAME,
		enableReinitialize: true,
		validate
	})
)(UsersPage)
