import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'
import { getItemsByListCode } from 'isotope-client/modules/valueList/services/valueListSelectors'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Input from '../../../../../components/form/Input'
import { DEFAULT_LANGUAGE_ID, DEFAULT_SU, GlOBAL_LANGUAGE_ID } from '../../../../../utils/constants'
import { getConversionBySuAndCode } from '../../../../common/conversion/conversionSelectors'
import { loadListFoByShortcut } from '../../../../common/valueList/valueListActions'
import { getUserSu } from '../../../user/services/userSelectors'
import { ATTRIBUTE_DATA_MO_FIELD } from '../../utils/documentConstants'
import { normalizeNumber, parseNumber } from '../../utils/documentUtils'
import DatasetHandler from '../DatasetHandler'
import DocumentRowField from './DocumentRowField'

const styles = () => getStyles({
	consultationContainer: {
		paddingBottom: 24
	}
})

const DocumentUnitField = ({
	field,
	inputName,
	selectedLanguage,
	unite,
	uniteDefault,
	uniteList,
	loadListFoByShortcut,
	classes,
	consultation,
	initialValues,
	duplicatedNumber,
	coeff,
	attData
}) => {
	const data = field.attributeData[selectedLanguage] || field.attributeData[DEFAULT_LANGUAGE_ID]

	React.useEffect(() => {
		if (selectedLanguage) {
			if (!unite &&
				(!uniteList || uniteList.length === 0 || uniteList[0].idLanguage !== `${selectedLanguage}`) &&
				field.attributeData[GlOBAL_LANGUAGE_ID] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.UNITE_LIST]
			) {
				loadListFoByShortcut(field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.UNITE_LIST].value, selectedLanguage)
			}
		}
	}, [selectedLanguage])

	if (consultation) {
		if (!initialValues.value && !initialValues.userValue) {
			return <></>
		}
		return <>
			<Grid item container xs={12} className={classes.consultationContainer}>
				<Grid item xs={4}>
					<Typography variant="caption">
						{data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] && `${data[ATTRIBUTE_DATA_MO_FIELD.NAME].value}`}
					</Typography>
				</Grid>
				<Grid item container xs={8}>
					{!!initialValues.userValue ?
						<Typography variant="body1">
							{Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(initialValues.userValue)} {uniteDefault}
						</Typography>
						:
						<Typography variant="body1">
							{initialValues.value} {unite}
						</Typography>
					}

				</Grid>
				{!!initialValues.userValue && <>
					<Grid item xs={4} />
					<Grid item container xs={8}>
						<Typography
							variant="body1">({initialValues.value} {unite})</Typography>
					</Grid>
				</>}
			</Grid>
		</>
	} else {
		return <>
			<DatasetHandler field={field} duplicatedNumber={duplicatedNumber} suffix={unite} coeff={coeff}>
				<DocumentRowField field={field} duplicatedNumber={duplicatedNumber} attData={attData}>
					<Grid item xs={7}>
						<Field
							name={inputName}
							label={data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] && `${data[ATTRIBUTE_DATA_MO_FIELD.NAME].value} ${field.required ? '*' : ''}`}
							component={Input}
							parse={parseNumber}
							format={normalizeNumber}
						/>
					</Grid>
					<Grid item xs={1}>
						<Typography variant="body1">{unite}</Typography>
					</Grid>
				</DocumentRowField>
			</DatasetHandler>
		</>
	}
}

DocumentUnitField.propTypes = {
	field: PropTypes.object.isRequired,
	inputName: PropTypes.string.isRequired,
	unite: PropTypes.string,
	uniteDefault: PropTypes.string,
	uniteList: PropTypes.array,
	attData: PropTypes.object,
	loadListFoByShortcut: PropTypes.func,
	consultation: PropTypes.bool,
	initialValues: PropTypes.object
}

const mapStateToProps = (state, { field }) => {
	const su = getUserSu(state)
	const data = field.attributeData[GlOBAL_LANGUAGE_ID]
	let uniteLabel = ''
	let uniteDefault = ''
	let uniteList = []
	let coeff = 1

	if (data && data[ATTRIBUTE_DATA_MO_FIELD.UNITE_LIST] && data[ATTRIBUTE_DATA_MO_FIELD.UNITE_LIST].value &&
		data[ATTRIBUTE_DATA_MO_FIELD.DEFAULT_UNITE] && data[ATTRIBUTE_DATA_MO_FIELD.DEFAULT_UNITE].value
	) {
		uniteList = getItemsByListCode(state)[data[ATTRIBUTE_DATA_MO_FIELD.UNITE_LIST].value] || []
		const uniteFound = uniteList.find(unite => unite.code === data[ATTRIBUTE_DATA_MO_FIELD.DEFAULT_UNITE].value)
		if (uniteFound) {
			uniteDefault = uniteFound.label
			uniteLabel = uniteFound.label
			// On affiche les libellés dans son système si
			if (su !== DEFAULT_SU) {
				const conversion = getConversionBySuAndCode(state, su, uniteFound.code)
				if (conversion) {
					uniteLabel = conversion.uniteLocale
					coeff = conversion.coeff
				}
			}
		}
	}

	return { unite: uniteLabel, uniteDefault, uniteList, coeff }
}

const actions = {
	loadListFoByShortcut
}

export default compose(
	withStyles(styles),
	connect(mapStateToProps, actions)
)(DocumentUnitField)
