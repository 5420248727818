import fetch from 'isomorphic-fetch'
import { checkErrors } from '../../../../utils/fetchFactory'
import { LOCAL_STORAGE_ITEM } from '../../../../utils/constants'

const SERVER_URL =''

export function fetchUser(token) {
	return fetch(`${SERVER_URL}/auth/user`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json; charset=utf-8',
			Authorization: token
		}
	})
		.then(response => {
			if (response.ok) {
				return response.json()
			}
		})
}

export function login(data) {
	return fetch(`${SERVER_URL}/auth/login-veolia`, {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	})
		.then(response => {
			if (response.ok) {
				return response.json()
			} else if (response.status === 400) {
				return Promise.all([response, response.json()])
					.then(checkErrors)
			} else {
				throw response
			}
		})
}

export const storeLocalUser = ({token, accessToken, refreshToken, expiresIn}) => {
	localStorage.setItem(LOCAL_STORAGE_ITEM.TOKEN, token)
	localStorage.setItem(LOCAL_STORAGE_ITEM.ACCESS_TOKEN, accessToken)
	localStorage.setItem(LOCAL_STORAGE_ITEM.REFRESH_TOKEN, refreshToken)
	localStorage.setItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_IN, expiresIn.toString())
	localStorage.setItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_TIMESTAMP, (Date.now() + expiresIn*1000 ).toString())
}

export const removeLocalUser = () => {
	localStorage.removeItem(LOCAL_STORAGE_ITEM.TOKEN)
	localStorage.removeItem(LOCAL_STORAGE_ITEM.ACCESS_TOKEN)
	localStorage.removeItem(LOCAL_STORAGE_ITEM.REFRESH_TOKEN)
	localStorage.removeItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_IN)
	localStorage.removeItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_TIMESTAMP)
}

const getExpirationTimestamp = () => Number(localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_TIMESTAMP) ? localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_TIMESTAMP) : 0)
const ONE_MINUTE_IN_MS = 60 * 1000

export const getTokenTTL = () => {
	const expiration = getExpirationTimestamp()
	return expiration - Date.now() - ONE_MINUTE_IN_MS
}

export const isTokenExpired = () => getTokenTTL() <= 0

export const isTokenValid = () => !isTokenExpired()

export const loadTokensFromStorage = () => {
	const token =localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN)
	const accessToken = localStorage.getItem(LOCAL_STORAGE_ITEM.ACCESS_TOKEN)
	const refreshToken = localStorage.getItem(LOCAL_STORAGE_ITEM.REFRESH_TOKEN)
	const expiresIn = localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_IN)
	const expiresTimeStamp = localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_TIMESTAMP)

	if (!token || !accessToken || !refreshToken || !expiresIn) {
		removeLocalUser()
		return undefined
	}

	return {
		token,
		accessToken,
		refreshToken,
		expiresIn: Number(expiresIn),
		expiresTimeStamp
	}
}



