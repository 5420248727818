import PropTypes from 'prop-types'
import React from 'react'
import { OnChange } from 'react-final-form-listeners'
import { GlOBAL_LANGUAGE_ID } from '../../../../utils/constants'
import { getDatasetParameters, getFieldName, normalizeNumber } from '../utils/documentUtils'
import { ATTRIBUTE_DATA_MO_FIELD, FIELD_TYPE } from '../utils/documentConstants'
import { rechercheDataLake } from '../services/documentApi'
import { useField, useForm } from 'react-final-form'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import { FormattedMessage } from 'react-intl'

const styles = () => getStyles({
	containerInfo: {
		marginTop: '-10px',
		paddingLeft: 20,
		marginBottom: 10
	},
	labelInfo: {
		color: 'rgba(0, 0, 0, 0.54)',
		fontSize: 12
	}
})

/**
 * Handler pour gérer les calculs via le dataset
 * Affiche également le libellé d'information data set en contribution
 * @param field
 * @param suffix
 * @param coeff
 * @param duplicatedNumber
 * @param children
 * @param classes
 * @returns {*}
 * @constructor
 */
const DatasetHandler = ({
	field,
	suffix,
	coeff,
	duplicatedNumber,
	children,
	classes
}) => {
	// Si nous n'avons pas d'attribute data, le field n'a pas de calcul à effectuer
	if (!field || !field.attributeData) {
		return null
	}
	const defaultValue = field.attributeData[GlOBAL_LANGUAGE_ID] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.DEFAULT_VALUE]

	// On ne calcule les defaultValues que si nous sommes en contribution et qu'il y a une formule de calcul
	if (!defaultValue) {
		return children
	}

	const form = useForm()
	const fieldValue = useField(`${getFieldName(field, duplicatedNumber)}`).input.value
	const [previousCalculatedValue, setPreviousCalculatedValue] = React.useState(undefined)
	const [initCalculatedValue, setInitCalculatedValue] = React.useState(undefined)

	const datasetParams = getDatasetParameters(defaultValue, duplicatedNumber)
	const initialParams = datasetParams
			.reduce((acc, fieldParam) => {
				const { value } = useField(fieldParam.fieldName).input.value
				return ({
					...acc,
					[fieldParam.fieldName]: {
						key: fieldParam.key,
						value
					}
				});
			}, {})
	const [datasetParameters, setDatasetParameters] = React.useState(initialParams)

	// A chaque modification des paramètres on recalcule
	React.useEffect(() => {
		recherche(datasetParameters)
	}, [datasetParameters])

	const recherche = (values, isInit) => {
		// On vérifie qu'au moins un des champs est renseigné
		const valueParams = Object.values(values)
		if (valueParams.length > 0 && valueParams.filter(param => param.value).length > 0) {
			let valueToSend = defaultValue.value
			valueParams.forEach(valueParam => {
				valueToSend = valueToSend.replaceAll(`\${${valueParam.key}}`, valueParam.value)
			})
			return rechercheDataLake(valueToSend)
				.then(res => {
					const suffixField = field.type === FIELD_TYPE.AMOUNT ? 'siValue' : 'value'
					let fieldResultat = ''
					let reportingYear = ''

					if (res.resultat.length > 0 && res.resultat[0].value !== '') {
						fieldResultat = `${field.type === FIELD_TYPE.NUMBER_UNIT
							? res.resultat[0].value * coeff
							: res.resultat[0].value}`

						reportingYear = res.resultat[0].annee

						setPreviousCalculatedValue({ value: fieldResultat, annee: reportingYear })
						console.log(`Retour bigQuery ${valueToSend} = ${fieldResultat}`)
						console.log(`Temps d'exécution : ${res.executionTime}ms`)
						// Dans le cas de l'init, on conserve la valeur pour ne plus la modifier
						if (isInit && fieldResultat !== '') {
							setInitCalculatedValue(fieldResultat)
						}
						// Si la valeur dans le champ est égale à celle calculée ou si nous n'avons pas de valeur -> on peut la modifier
						if (!initCalculatedValue && ((previousCalculatedValue && fieldValue[suffixField] === previousCalculatedValue.value) || !fieldValue[suffixField])) {
							form.change(`${getFieldName(field, duplicatedNumber)}.${suffixField}`, fieldResultat)
						}
					}
				})
		}
	}


	return <>
		{Object.keys(datasetParameters).map(fieldName => (
			<OnChange key={fieldName} name={fieldName}>
				{({ siValue, value }) => {
					// Récupération de siValue dans le cas du montant
					setDatasetParameters({
						...datasetParameters,
						[fieldName]: {
							...datasetParameters[fieldName],
							value: siValue || value
						}
					})
				}}
			</OnChange>
		))}
		{children}

		{previousCalculatedValue && (previousCalculatedValue.value === '' || previousCalculatedValue.annee === '') &&
			<Grid item xs={8} className={classes.containerInfo}>
				<Typography className={classes.labelInfo}>
					<FormattedMessage id="document.field.noValueInGR" />
				</Typography>
			</Grid>
		}

		{previousCalculatedValue && previousCalculatedValue.value.trim() !== '' && previousCalculatedValue.annee !== '' &&
			<Grid item xs={8} className={classes.containerInfo}>
				<Typography className={classes.labelInfo}>
					<FormattedMessage id="document.field.defaultValueInfo" /> {normalizeNumber(previousCalculatedValue.value)} {`${suffix ? suffix : ''} (${previousCalculatedValue.annee})`}
				</Typography>
			</Grid>
		}
	</>
}

DatasetHandler.propTypes = {
	field: PropTypes.object.isRequired,
	duplicatedNumber: PropTypes.number,
	suffix: PropTypes.string, // Suffixe à afficher après la valeur de référence
	coeff: PropTypes.number, // Coefficient à prendre en compte lors de la conversion
	classes: PropTypes.object
}

export default withStyles(styles)(DatasetHandler)
