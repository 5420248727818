import React from 'react'
import PropTypes from 'prop-types'
import OverflowTypography from '../../../../../components/OverflowTypography'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import FolderIcon from '../../../../../components/icon/FolderIcon'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => getStyles({
	padding: {
		paddingLeft: 10,
		paddingRight: 10
	},
	fileName: {
		fontWeight: 'bold',
		paddingLeft: 20
	},
	fileSize: {
		paddingLeft: 20
	},
	paperFile: {
		position: 'relative',
		width: '100%'
	},
	cardFile: {
		padding: 16,
		height: 74
	},
	deleteIconButton: {
		position: 'absolute',
		top: 8,
		right: 8
	},
	deleteIcon: {
		fontSize: '1.2rem'
	},
	circularProgress: {
		marginLeft: 8,
		color: theme.palette.text.main
	}
})

const FolderCard = ({ folder, onClick, canDelete, onDelete, uploaded, classes }) => {
	return <>
		<Paper className={classes.paperFile} onClick={onClick}>
			{uploaded && canDelete && <IconButton
				size="small"
				onClick={(e) => {
					onDelete()
					e.stopPropagation()
				}}
				className={classes.deleteIconButton}
			><ClearIcon className={classes.deleteIcon}/></IconButton>}
			<Grid direction="row" item container xs={12} className={classes.cardFile} alignItems="center">
				<Grid item xs={1}>
					<FolderIcon />
				</Grid>
				<Grid item xs={10}>
					<OverflowTypography variant="body1" className={classes.fileName}>{folder.name}</OverflowTypography>
					<Typography variant="body2" className={classes.fileSize}>{uploaded === false && <FormattedMessage id="document.field.drive.uploading" />}</Typography>
				</Grid>
				<Grid item xs={1}>
					{uploaded === false && <CircularProgress size={24} className={classes.circularProgress} />}
				</Grid>
			</Grid>
		</Paper>
	</>
}

FolderCard.propTypes = {
	folder: PropTypes.object,
	canDelete: PropTypes.bool,
	onClick: PropTypes.func,
	onDelete: PropTypes.func,
	classes: PropTypes.object
}

export default withStyles(styles)(FolderCard)
