import { getUser, IsotopeContext } from 'isotope-client'
import { loadLangues } from 'isotope-client/modules/langue/services/langueActions'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { compose } from 'redux'
import Loader from '../components/layout/Loader'
import Page from '../components/layout/Page'
import { IntlContext } from '../config/IntlContext'
import BackOffice from '../modules/bo/BackOffice'
import { loadConfiguration } from '../modules/fo/configuration/configurationInjector'
import FrontOffice from '../modules/fo/FrontOffice'
import { getTokenTTL, loadTokensFromStorage, storeLocalUser } from '../modules/fo/login/services/loginApi'
import { getMe } from '../modules/fo/user/services/usersApi'
import * as userSelectors from '../modules/fo/user/services/userSelectors'
import { LOCAL_STORAGE_ITEM, DIDOMI_CONFIG, ROOT_BO, USER_PREFERENCES } from '../utils/constants'
import { refreshToken } from '../utils/fetchFactory'
import { DidomiSDK } from '@didomi/react'

const App = ({ user, location, loadLanguesAction, getUser }) => {
	const { changeLanguage } = React.useContext(IntlContext)
	const [loading, setLoading] = React.useState(true)
	const [tokens, setTokens] = React.useState(loadTokensFromStorage())
	const [didomiObject, setDidomiObject] = React.useState()

	React.useEffect(() => {
		refresh()
	}, [])

	React.useEffect(() => {
		const ttl = getTokenTTL()
		if (tokens && tokens.token && ttl > 0 ) {
			setLoading(false)
			const timeout = setTimeout(refresh, ttl)
			return () => clearTimeout(timeout)
		} else {
			refresh()
		}
	}, [tokens])

	async function refresh() {
		if (user && user.token && !user.id) {
			const token = localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN)
			const refToken = localStorage.getItem(LOCAL_STORAGE_ITEM.REFRESH_TOKEN)
			refreshToken(token, { code : refToken}).then((json) => {
				if (json) {
					storeLocalUser(json)
					setTokens(json)
				}
			})
			getMe()
				.then(user => {
					if (user && user.preferences && user.preferences[USER_PREFERENCES.LANGUE]) {
						changeLanguage(user.preferences[USER_PREFERENCES.LANGUE])
					}
					getUser({ id: user.id, accessRequest: user.accessRequest })
				})
				.catch(() => {
					setLoading(false)
				})
		}
		loadLanguesAction()
	}

	if (!user.authenticated) {
		return (<Redirect to={{
			pathname: "/login",
			state: { nextPathname: location.pathname }
		}}/>)
	} else if (loading && !user.id) {
		return <Loader />
	} else {
		return (
			<IsotopeContext.Provider value={{ appName: 'Tender box' }}>
				<DidomiSDK
					apiKey={DIDOMI_CONFIG.API_KEY}
					noticeId={DIDOMI_CONFIG.NOTICE_ID}
					iabVersion={2}
					gdprAppliesGlobally={true}
					onReady={(didomi) => {
						if (didomi.shouldConsentBeCollected()) {
							didomi.notice.show()
						}
						setDidomiObject(didomi)
					}}
				/>
				<Page location={location} menu={location && location.pathname && location.pathname.startsWith(ROOT_BO) ? 'bo' : 'fo'} didomi={didomiObject}>
					<Switch>
						<Route path="/bo" component={BackOffice} />
						<Route path="/" component={FrontOffice} />
					</Switch>
				</Page>
			</IsotopeContext.Provider>
		)
	}
}

App.propTypes = {
	user: PropTypes.object,
	loadLanguesAction: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
	user: userSelectors.getUser(state)
})

const actions = {
	loadLanguesAction: loadLangues,
	getUser
}

export default compose(
	connect(mapStateToProps, actions),
	loadConfiguration
)(App)
