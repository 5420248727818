import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'
import GradientSeparateur from '../../../../components/GradientSeparateur'
import PaperBlocAccueil from './PaperBloc'
import SvgIcon from '../../../../components/icon/SvgIcon'
import { push } from 'connected-react-router'
import Button from '../../../../components/Button'
import Link from '../../../../components/Link'
import { getLastMonthDate } from '../../document/utils/documentUtils'
import { PROFILS, TIMELINE } from '../../../../utils/constants'
import { hasAccess } from '../../../../components/security/AccessChecker';
import * as userSelectors from '../../user/services/userSelectors';
import { DOCUMENT_STATUS } from '../../document/utils/documentConstants';

const styles = theme => getStyles({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		padding: 20,
		width: 384,
		cursor: 'pointer'
	},
	description: {
		height: 135
	},
	divTitre: {
		height: 56,
		marginBottom: 10
	},
	divInfos: {
		height: 32,
		marginBottom: 10
	},
	verticalCenter: {
		position: 'relative',
		top: '30%'
	},
	floatRight: {
		float: 'right'
	},
	picto: {
		fontSize: 40
	},
	nombreLevel2: {
		color: theme.palette.secondary.main,
		fontSize: 24,
		fontWeight: 700
	},
	nombreLevel3: {
		color: theme.palette.secondary.main,
		fontSize: 18,
		fontWeight: 600
	},
	button: {
		backgroundColor: 'white',
		border: '2px solid',
		'&:hover': {
			border: '2px solid'
		}
	},
	divInfosPublication: {
		height: 32,
		marginBottom: 10,
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.03)',
			borderRadius: 2,
			'& .MuiTypography-body2' :{
				fontWeight: 'bold'
			}
		}
	}
})

const urlDocuments = '/documents'

const DetailBloc = ({ contenu: { key, data, icon, enumType }, classes, goTo, intl, isCorrespondant, isAdmin,  }) => {

	const goToRechercher = () => {
		goTo(urlDocuments, {
			initialId: undefined,
			initialQuery: undefined,
			initialFieldsAdvance: undefined,
			initialTypeContenu: enumType,
			initialFieldsAdmin: {
				status: [{ code: DOCUMENT_STATUS.PUBLIE }]
			}
		})
	}

	const goToRechercherLastMonthPublication = () => {
		goTo(urlDocuments, {
			initialId: undefined,
			initialQuery: undefined,
			initialFieldsAdvance: undefined,
			initialTypeContenu: enumType,
			initialFieldsAdmin: {
				datePublication: [TIMELINE.AFTER, getLastMonthDate()],
				status: [{ code: DOCUMENT_STATUS.PUBLIE }]
			}
		})
	}

	const goToRechercherCaseStudy = () => {
		goTo(
			urlDocuments,
			{
				initialId: undefined,
				initialQuery: undefined,
				initialTypeContenu: enumType,
				initialFieldsAdmin: {
					status: [{ code: DOCUMENT_STATUS.PUBLIE }]
				},
				initialFieldsAdvance: [{
					code: "CONTRAT_SCOPE_IMG",
					label: "Business Case Study",
					moType: "REFERENCE",
					path: " / Contract / Contract presentation / Major results",
					searchType: "INCLUDE",
					source: "",
					type: "FILE"
				}]
			}
		)
	}

	const texteDescription = `${intl.formatMessage({ id: `accueil.${key}.description` })} `

	return (
		<PaperBlocAccueil className={classes.paper} onClick={() => goToRechercher()}>
			<Grid container direction="row" justify="space-between" alignContent="flex-start">
				{/*Titre*/}
				<Grid item container className={classes.divTitre} justify="space-between" alignItems="center">
					<Grid item xs={10}>
						<Typography variant="h3"><FormattedMessage id={`accueil.${key}.titre`} /></Typography>
					</Grid>
					<Grid item>
						<SvgIcon path={icon} className={classes.picto} color="secondary" />
					</Grid>
				</Grid>

				{/*Contenu information*/}
				<Typography variant="body2"
				            align="justify"
				            className={classes.description}
				            dangerouslySetInnerHTML={{ __html: texteDescription }} />
			</Grid>

			{/*Separateur*/}
			<GradientSeparateur />

			{/*Nombre level 2*/}
			<Grid container className={classes.divInfos}>
				<Grid item xs={10}>
					<Typography variant="body1" className={classes.verticalCenter}><FormattedMessage id="accueil.disponible" /></Typography>
				</Grid>
				<Grid item xs={2}>
					<span className={classnames(classes.nombreLevel2, classes.verticalCenter, classes.floatRight)}>{data.disponible || 0}</span>
				</Grid>

			</Grid>

			{/*Nombre level 3*/}

			{(!!data.publication) && <Grid
				container
				className={classes.divInfosPublication}
				onClick={(e) => {
					e.stopPropagation()
					goToRechercherLastMonthPublication()
				}}>
				<>
					<Grid item xs={10}>
						<Typography variant="body2" className={classes.verticalCenter}><FormattedMessage id="accueil.publication" /></Typography>
					</Grid>
					<Grid item xs={2}>
						<span className={classnames(classes.nombreLevel3, classes.verticalCenter, classes.floatRight)}>+ {data.publication || 0}</span>
					</Grid>
				</>
			</Grid>}

			{key === 'reference' &&
			<Link onClick={(e) => {
				goToRechercherCaseStudy()
				e.stopPropagation()
			}}>
				<Button
					key="access"
					className={classes.button}
					color="primary"
					variant="outlined"
				>
					<FormattedMessage id={`accueil.${key}.button`} />
				</Button>
			</Link>
			}
		</PaperBlocAccueil>
	)
}

const actions = {
	goTo: (pathname, state) => dispatch => dispatch(push({
		pathname,
		state
	}))
}

const mapStateToProps = state => ({
	isAdmin: hasAccess(PROFILS.ADMIN, userSelectors.getAuthorities(state)),
	isCorrespondant: hasAccess(PROFILS.CORRESPONDANT, userSelectors.getAuthorities(state)),
})

DetailBloc.propTypes = {
	count: PropTypes.object,
	contenu: PropTypes.object.isRequired, // Contient une clé représentant le bloc et les datas associées
	classes: PropTypes.object
}

export default compose(
	connect(mapStateToProps, actions),
	injectIntl,
	withStyles(styles)
)(DetailBloc)
