import * as api from './documentApi'

export const GET_DOCUMENT_REQUEST = 'GET_DOCUMENT_REQUEST'
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS'
export const GET_DOCUMENT_ERROR = 'GET_DOCUMENT_ERROR'

export const getDocument = (id, displayLoading = true) => dispatch => {
	if (displayLoading) dispatch({ type: GET_DOCUMENT_REQUEST })
	return api.getDocument(id)
		.then(document => {
			return dispatch({ type: GET_DOCUMENT_SUCCESS, document })
		})
		.catch(e => dispatch({ type: GET_DOCUMENT_ERROR, e }))
}

export const POST_DOCUMENT_REQUEST = 'POST_DOCUMENT_REQUEST'
export const POST_DOCUMENT_SUCCESS = 'POST_DOCUMENT_SUCCESS'
export const POST_DOCUMENT_ERROR = 'POST_DOCUMENT_ERROR'
export const postDocument = (document) => dispatch => {
	dispatch({ type: POST_DOCUMENT_REQUEST })
	return api.postDocument(document)
		.then((docResult) => {
			if (docResult.id) {
				// On va charger le document
				return api.getDocument(docResult.id)
					.then((newDoc) => dispatch({ type: POST_DOCUMENT_SUCCESS, document: newDoc }))
			}
		})
		.catch(e => {
			dispatch({ type: POST_DOCUMENT_ERROR, e })
			throw e
		})
}

export const PUT_DOCUMENT_REQUEST = 'PUT_DOCUMENT_REQUEST'
export const PUT_DOCUMENT_SUCCESS = 'PUT_DOCUMENT_SUCCESS'
export const PUT_DOCUMENT_ERROR = 'PUT_DOCUMENT_ERROR'
export const putDocument = (document) => dispatch => {
	dispatch({ type: PUT_DOCUMENT_REQUEST })
	return api.putDocument(document)
		.then((document) => {
			return dispatch({ type: PUT_DOCUMENT_SUCCESS, document })
		})
		.catch(e => {
			dispatch({ type: PUT_DOCUMENT_ERROR, e })
			throw e
		})
}

export const UPDATE_STATUS_DOCUMENT_REQUEST = 'UPDATE_STATUS_DOCUMENT_REQUEST'
export const UPDATE_STATUS_DOCUMENT_SUCCESS = 'UPDATE_STATUS_DOCUMENT_SUCCESS'
export const UPDATE_STATUS_DOCUMENT_ERROR = 'UPDATE_STATUS_DOCUMENT_ERROR'
export const updateDocumentStatus = (id, status, message) => dispatch => {
	dispatch({ type: UPDATE_STATUS_DOCUMENT_REQUEST, id, status })
	return api.updateDocumentStatus(id, status, message)
		.then((workflow) => dispatch({ type: UPDATE_STATUS_DOCUMENT_SUCCESS, workflow }))
		.catch((e) => {
			dispatch({ type: UPDATE_STATUS_DOCUMENT_ERROR, e })
			throw e
		})
}

export const CLEAR_DOCUMENT = 'CLEAR_DOCUMENT'
export const clearDocument = () => dispatch => dispatch({ type: CLEAR_DOCUMENT })

export const EXPORT_RECHERCHE_REQUEST = 'EXPORT_RECHERCHE_REQUEST'
export const EXPORT_RECHERCHE_SUCCESS = 'EXPORT_RECHERCHE_SUCCESS'
export const EXPORT_RECHERCHE_ERROR = 'EXPORT_RECHERCHE_ERROR'
export const exportRecherche = params => dispatch => {
	dispatch({ type: EXPORT_RECHERCHE_REQUEST })
	return api.exportRecherche(params)
		.then(() => dispatch({ type: EXPORT_RECHERCHE_SUCCESS }))
		.catch(e => {
			dispatch({ type: EXPORT_RECHERCHE_ERROR, e })
			throw e
		})
}


export const EXPORT_CONTENT_REQUEST = 'EXPORT_CONTENT_REQUEST'
export const EXPORT_CONTENT_SUCCESS = 'EXPORT_CONTENT_SUCCESS'
export const EXPORT_CONTENT_ERROR = 'EXPORT_CONTENT_ERROR'
export const exportContent = (id, idLang) => dispatch => {
	dispatch({ type: EXPORT_CONTENT_REQUEST })
	return api.exportContent(id, idLang)
		.then(() => dispatch({ type: EXPORT_CONTENT_SUCCESS }))
		.catch(e => {
			dispatch({ type: EXPORT_CONTENT_ERROR, e })
			throw e
		})
}

export const OPEN_FOLDER_POPIN = 'OPEN_FOLDER_POPIN'
export const openFolderPopin = (field, isEdition, selectedFolderOnOpen) => dispatch => dispatch({ type: OPEN_FOLDER_POPIN, field, isEdition, selectedFolderOnOpen })

export const CLEAR_FOLDER_POPIN = 'CLEAR_FOLDER_POPIN'
export const clearFolderPopin = () => dispatch => dispatch({ type: CLEAR_FOLDER_POPIN })

export const CLOSE_FOLDER_POPIN = 'CLOSE_FOLDER_POPIN'
export const closeFolderPopin = () => dispatch => dispatch({ type: CLOSE_FOLDER_POPIN })

export const COLLAPSED_LIST_RECHERCHE_TRUE = 'COLLAPSED_LIST_RECHERCHE_TRUE'
export const COLLAPSED_LIST_RECHERCHE_FALSE = 'COLLAPSED_LIST_RECHERCHE_FALSE'
export const updateRechercheStatusCollapse = (id, status) => dispatch => {
	status ? dispatch({ type: COLLAPSED_LIST_RECHERCHE_TRUE, id }) : dispatch({ type: COLLAPSED_LIST_RECHERCHE_FALSE, id })
}
