import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import MuiCheckbox from '@material-ui/core/Checkbox'
import { getStyles } from 'isotope-client'
import mapError from './mapError'
import { toI18N } from '../../utils/utils'
import classnames from 'classnames'

const styles = () => getStyles({
	marginError: {
		marginTop: '-5px'
	},
	container: {
		marginRight: 10,
		marginLeft: 10
	},
	label: {
		lineHeight: '1 !important'
	}
})

const Checkbox = ({ input: { value, ...inputProps }, meta: { error, touched }, label, disabled, classes, className }) => {
	return <div className={classnames(classes.container, className)}>
		<FormControl error={!!error}>
			<FormGroup>
				<FormControlLabel
					control={<MuiCheckbox
						{...inputProps}
						checked={value}
						variant="filled"
						disabled={disabled}
					/>}
					classes={{
						label: classes.label
					}}
					label={label} />
			</FormGroup>
			{touched && error && <FormHelperText className={classes.marginError}>{toI18N(error)}</FormHelperText>}
		</FormControl>
	</div>
}

Checkbox.propTypes = {
	input: PropTypes.object,
	meta: PropTypes.object,
	classes: PropTypes.object,
	label: PropTypes.string,
	className: PropTypes.string
}

Checkbox.defaultProps = {
	className: ''
}

export default compose(
	mapError,
	withStyles(styles)
)(Checkbox)

