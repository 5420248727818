import * as api from './modelApi'
import { formatSections, getRegroupementSectionChild } from '../../fo/document/utils/documentUtils'
import { TYPE_CONTENU } from '../../../utils/constants'

export const GET_MODEL_BY_TYPE_REQUEST = 'GET_MODEL_BY_TYPE_REQUEST'
export const GET_MODEL_BY_TYPE_SUCCESS = 'GET_MODEL_BY_TYPE_SUCCESS'
export const GET_MODEL_BY_TYPE_ERROR = 'GET_MODEL_BY_TYPE_ERROR'

export const getModelByType = (type) => dispatch => {
	dispatch({ type: GET_MODEL_BY_TYPE_REQUEST })
	return api.getDocumentModelByType(type)
		.then(documentModel => {
			const formattedModel = { ...documentModel }
			formattedModel.sections = formatSections(formattedModel.sections || [])

			// On construit les dépendances entre les sections
			// Effectué actuellement uniquement pour les références
			const dependenciesSection = type === TYPE_CONTENU.REFERENCE ? getRegroupementSectionChild(formattedModel.sections || []) : {}

			return dispatch({ type: GET_MODEL_BY_TYPE_SUCCESS, model: formattedModel, modelType: type, dependenciesSection })
		})
		.catch(e => dispatch({ type: GET_MODEL_BY_TYPE_ERROR, e }))
}
