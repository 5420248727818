import { withStyles } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import StarIcon from '@material-ui/icons/Star'
import classnames from 'classnames'
import { push } from 'connected-react-router'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { logOut } from '../../../modules/fo/login/services/loginActions'
import * as userSelectors from '../../../modules/fo/user/services/userSelectors'
import FavorisPopover from '../../../modules/fo/favoris/FavorisPopover'
import { PROFILS } from '../../../utils/constants'
import AccessChecker from '../../security/AccessChecker'

const styles = theme => getStyles({
	div: {
		right: 0,
		position: 'absolute',
		marginRight: 10,
		display: 'flex'
	},
	favoriteBar: {
		marginTop: 12,
		marginRight: 48,
		fontSize: 30,
		cursor: 'pointer'
	},
	buttonUserMenu: {
		border: 'solid 1px',
		borderRadius: 30,
		marginRight: 10,
		padding: '0px 6px'
	},
	bigAvatar: {
		margin: 10,
		width: 30,
		height: 30
	},
	avatarColor: {
		color: theme.palette.placeholder
	},
	profile: {
		fontWeight: 300,
		color: theme.palette.placeholder
	},
	infos: {
		lineHeight: '15px',
		margin: 0,
		fontSize: 14,
		textAlign: 'left',
		textTransform: 'none'
	},
	arrow: {
		marginLeft: 10
	}
})

export const UserMenu = ({ classes, logout, goToMonCompte, user, didomi }) => {
	const [anchorEl, setAnchorEl] = React.useState(undefined)
	const [anchorFavoris, setAnchorFavoris] = React.useState(undefined)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const handleClickMonCompte = () => {
		handleClose()
		goToMonCompte()
	}

	const handlePreferencesCookies = () => {
		handleClose()
		didomi.preferences.show()
	}

	return (
		<React.Fragment>
			<div className={classes.div}>
				<AccessChecker access={[PROFILS.MEMBRE, PROFILS.CORRESPONDANT, PROFILS.ADMIN]}>
					<div className={classes.favoriteBar} onClick={(ev) => setAnchorFavoris(ev.currentTarget)}>
						<StarIcon fontSize="inherit" className={classes.iconFavorite} />
						<ArrowDropDown fontSize="inherit" className={classes.arrow} />
					</div>
				</AccessChecker>
				<FavorisPopover anchorFavoris={anchorFavoris} onClose={() => setAnchorFavoris(undefined)} />
				<Button
					aria-owns={anchorEl ? 'simple-menu' : undefined}
					aria-haspopup="false"
					onClick={handleClick}
					className={classes.buttonUserMenu}
				>
					{user.icon ? <Avatar alt="avatar" src={user.icon} className={classes.bigAvatar} /> :
						<AccountCircleIcon className={classnames(classes.bigAvatar, classes.avatarColor)} />}
					<p className={classes.infos}>
						<span>{`${user.firstname} ${user.lastname}`}</span>
						<br />
						<span className={classes.profile}>{user && user.authorities && user.authorities.length > 0 && user.authorities[0].authority ?
							<FormattedMessage id={`enums.profil.${user.authorities[0].authority}`} /> : <FormattedMessage id="enums.profil.ROLE_VISITEUR" />}</span>
					</p>
					<ArrowDropDown className={classes.arrow} />
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={handleClickMonCompte}><FormattedMessage id="appBar.menus.monCompte" /></MenuItem>
					<MenuItem onClick={handlePreferencesCookies}><FormattedMessage id="appBar.menus.cookies" /></MenuItem>
					<MenuItem onClick={logout}><FormattedMessage id="appBar.menus.logout" /></MenuItem>
				</Menu>
			</div>
		</React.Fragment>
	)
}

UserMenu.propTypes = {
	user: PropTypes.object,
	didomi: PropTypes.object
}

const mapStateToProps = (state) => ({
	user: userSelectors.getUser(state)
})

const actions = {
	logout: logOut,
	goToMonCompte: () => dispatch => dispatch(push('/me'))
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(UserMenu)
