import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'
import { FormSwitch, getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { Field, useField, useForm } from 'react-final-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Element } from 'react-scroll'
import { compose } from 'redux'
import { DEFAULT_LANGUAGE_ID, GlOBAL_LANGUAGE_ID, TYPE_CONTENU } from '../../../../utils/constants'
import { ATTRIBUTE_DATA_MO_SECTION, COLLAPSE_MAX_LEVEL_SECTION } from '../utils/documentConstants'
import { getDuplicateSuffix, getFilteredFields, getFilteredSections, isSectionEmpty } from '../utils/documentUtils'
import DocumentField from './DocumentField'
import { SpyField } from './SpyField'
import LoadingCollapse from './LoadingCollapse'

const styles = theme => getStyles({
	divider: {
		backgroundColor: theme.palette.text.main,
		marginBottom: 10,
		height: 2
	},
	dividerHeight1: {
		height: 2
	},
	dividerHeight2: {
		height: 1
	},
	title: {
		marginBottom: 10
	},
	titleSectionConsultation: {
		paddingBottom: 15
	}
})

const FONT_TITLE_LEVEL = {
	1: 'h2',
	2: 'h4',
	3: 'subtitle1',
	4: 'subtitle2'
}

const SectionAlias = ({titleCode, dupNumber, level}) => {
	if (!titleCode) {
		return !!dupNumber && level === 1 ? `(${dupNumber})` : ''
	}

	const aliasField = useField(`fields.${titleCode.value}${getDuplicateSuffix(dupNumber)}`)
	const [alias, setAlias] = React.useState('')
	React.useEffect(() => {
		if (level === 1 && aliasField) {
			setAlias(aliasField.input.value.value)
		}
	}, [aliasField])

	if (!alias) {
		return !!dupNumber && level === 1 ? `(${dupNumber})` : ''
	}

	return `- ${alias}`
}

const InternalSection = ({
	setMapSectionByField,
	section,
	level,
	parentKey,
	openSections,
	setOpenSections,
	duplicatedNumber,
	documentType,
	intl,
	handleOpenSections,
	classes,
	consultation,
	sectionsWithContent,
	sectionParentInfo,
	documentContributor,
	workflow,
	isCorrespondantValid,
	isMemberValid
}) => {

	const sectionKey = `${parentKey}-${section.id}${getDuplicateSuffix(duplicatedNumber)}`

	const form = useForm()
	const selectedLanguageField = useField('selectedLanguage')
	const selectedLanguage = selectedLanguageField.input.value
	const duplicatedSections = form.getState().values['duplicatedSections']
	const status = useField('status').input.value

	// Vérifie que la clé est dans la liste des sections ouvertes
	// Utilisé que dans le cas d'une référence
	const sectionIsOpen = (key) => (consultation && documentType !== TYPE_CONTENU.REFERENCE) || openSections.findIndex(section => section === key) !== -1

	const data = section.attributeData && (section.attributeData[selectedLanguage] || section.attributeData[DEFAULT_LANGUAGE_ID])

	//Code de l'alias de rubrique
	const titleCode = section.attributeData && (section.attributeData[GlOBAL_LANGUAGE_ID] || {})[ATTRIBUTE_DATA_MO_SECTION.TITLE_CODE]

	// Rend le contenu d'une section
	const renderSectionChildren = (dupNumber) => {

		const sectionInfo = {
			id: section.id,
			idMoParentSection: section.idMoParentSection,
			position: level === 1 ? dupNumber || section.position : section.position,
			duplicated: dupNumber > 0,
			duplicatedNumber: dupNumber,
			deleted: dupNumber > 0 && false,
			finished: false,
			sectionParentInfo
		}

		const addSectionInMap = (fieldName) => setMapSectionByField({
			[fieldName]: sectionInfo
		})

		const sectionKey = `${parentKey}-${section.id}${getDuplicateSuffix(dupNumber)}`
		if (!consultation && !(sectionIsOpen(sectionKey) || level > 2)) {
			return <></>
		}
		return (<>
			{getFilteredFields(section.fields).map((field, index) => <DocumentField
				key={`field-${field.id}-${index}`}
				field={field}
				selectedLanguage={selectedLanguage}
				duplicatedNumber={dupNumber}
				consultation={consultation}
				addSectionInMap={addSectionInMap}
				documentContributor={documentContributor}
				documentType={documentType}
				workflow={workflow}
				isCorrespondantValid={isCorrespondantValid}
				isMemberValid={isMemberValid}
			/>)}
			{getFilteredSections(section.sections).map((sousSection, sIndex) => <DocumentSection
				key={`sectionN${level}-${section.id}-${sIndex}`}
				section={sousSection}
				level={level + 1}
				parentKey={sectionKey}
				openSections={openSections}
				setOpenSections={setOpenSections}
				handleOpenSections={handleOpenSections}
				selectedLanguage={selectedLanguage}
				documentType={documentType}
				duplicatedNumber={dupNumber}
				consultation={consultation}
				sectionsWithContent={sectionsWithContent}
				setMapSectionByField={setMapSectionByField}
				sectionParentInfo={sectionInfo}
				documentContributor={documentContributor}
				isCorrespondantValid={isCorrespondantValid}
				isMemberValid={isMemberValid}
			/>)}
			{(!consultation && level === 2) && <Grid container alignItems="center" direction="column">
				<Grid item xs={6}>
					<Field name={`status.${sectionParentInfo.id}-${sectionInfo.duplicated ? `dup#${dupNumber}-` : '' }${section.position}-${section.id}`}>
						{props => {
							return (
								<FormSwitch
									label={intl.formatMessage({ id: 'document.section.switch' })}
									color="primary"
									name={props.input.name}
									value={props.input.value.finished}
									onChange={(ev, value) => {
										if (value) {
											handleOpenSections(sectionKey, true)
										}
										form.change('status', {
											...status,
											[`${sectionParentInfo.id}-${sectionInfo.duplicated ? `dup#${dupNumber}-` : '' }${section.position}-${section.id}`]: {
												id: sectionInfo.id,
												idMoParentSection: sectionInfo.idMoParentSection,
												position: sectionInfo.position,
												duplicated: sectionInfo.duplicated,
												deleted: sectionInfo.deleted,
												duplicatedNumber: dupNumber,
												finished: value,
												sectionParentInfo
											}
										})
									}}
									meta={props.meta}
								/>
							)
						}}
					</Field>
				</Grid>
			</Grid>}
		</>)
	}

	// Rend la section collapse
	const renderCollapseSection = (key, dupNumber, isDeleted) => {
		const isOpen = sectionIsOpen(key)

		if (isDeleted || (consultation && isSectionEmpty(sectionsWithContent, section.id, dupNumber))) {
			return <React.Fragment key={key} />
		}

		return (<React.Fragment key={key}>
			<Grid container alignItems="center">
				<Grid item xs={11} className={consultation ? classes.titleSectionConsultation : ''}>
					<Element name={`section-${section.id}`}>
						<Typography id={key} variant={FONT_TITLE_LEVEL[level]}>
							{data && data[ATTRIBUTE_DATA_MO_SECTION.TITLE] ? data[ATTRIBUTE_DATA_MO_SECTION.TITLE].value :
								<FormattedMessage id="document.section.defaultTitle" />} <SectionAlias titleCode={titleCode} dupNumber={dupNumber} level={level} />
						</Typography>
					</Element>
				</Grid>
				{!(consultation && documentType !== TYPE_CONTENU.REFERENCE) && <Grid item xs={1}>
					<LoadingCollapse isOpen={isOpen} sectionKey={key} handleOpenSections={handleOpenSections} />
				</Grid>}
			</Grid>
			<Divider classes={{ root: classnames(classes.divider, classes[`dividerHeight${level}`]) }} />
			<Collapse in={isOpen} timeout="auto">
				{renderSectionChildren(dupNumber)}
			</Collapse>
		</React.Fragment>)
	}
	// Seul le niveau 1 et 2 est collapse<
	// Pour gérer le collapse on formate les sections via le level et index
	if (level <= COLLAPSE_MAX_LEVEL_SECTION) {
		return <>
			{renderCollapseSection(sectionKey, duplicatedNumber)}
			{!duplicatedNumber && duplicatedSections && duplicatedSections[section.id] && duplicatedSections[section.id].map(sectionDup => renderCollapseSection(`-${section.id}#${sectionDup.position}`, level === 1 ? sectionDup.position : duplicatedNumber, sectionDup.deleted)
			)}
		</>
	}


	if (consultation && isSectionEmpty(sectionsWithContent, section.id, duplicatedNumber)) {
		return <></>
	}

	return <>
		<Element name={sectionKey}>
			<Typography id={sectionKey} variant={FONT_TITLE_LEVEL[level]} className={classes.title}>
				{data && data[ATTRIBUTE_DATA_MO_SECTION.TITLE] ? data[ATTRIBUTE_DATA_MO_SECTION.TITLE].value :
					<FormattedMessage id="document.section.defaultTitle" />} <SectionAlias titleCode={titleCode} dupNumber={duplicatedNumber} level={level} />
			</Typography>
		</Element>
		{renderSectionChildren(duplicatedNumber)}
	</>
}

const Section = (props) => <SpyField
	part={props.section}
	duplicatedNumber={props.duplicatedNumber}
>
	<InternalSection {...props} />
</SpyField>

Section.propTypes = {
	classes: PropTypes.object,
	setMapSectionByField: PropTypes.func.isRequired,
	section: PropTypes.object.isRequired, // Section à afficher
	level: PropTypes.number, // Niveau de section
	parentKey: PropTypes.string, // Clé de la section parent (utilisée pour la gestion du collapse)
	openSections: PropTypes.array, // Sections fermées
	duplicatedNumber: PropTypes.number, // Index de la section dupliquée
	documentType: PropTypes.string, // Type de document
	documentContributor: PropTypes.string, // Contributeur initial du document
	intl: PropTypes.object,
	sectionParentInfo: PropTypes.object, // Si la section est fille d'une autre, on doit lui fournir les infos de la section parente
	consultation: PropTypes.bool,
	isCorrespondantValid: PropTypes.bool,
	isMemberValid: PropTypes.bool,
	sectionsWithContent: PropTypes.object
}

Section.defaultProps = {
	setMapSectionByField: () => {
	}
}

const DocumentSection = compose(
	withStyles(styles),
	injectIntl
)(Section)

export default DocumentSection
