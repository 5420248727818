import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'
import HelpIcon from '@material-ui/icons/HelpOutline'
import classnames from 'classnames'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import Checkbox from '../../../../../components/form/Checkbox'
import { ATTRIBUTE_DATA_MO_FIELD } from '../../utils/documentConstants'
import { getFieldName } from '../../utils/documentUtils'

const styles = () => getStyles({
	tooltip: {
		marginTop: '-12px'
	},
	checkbox: {
		marginTop: '-16px'
	}
})

// Rendu de la row d'un field avec tooltip et checkbox
const DocumentRowField = ({ field, children, attData, duplicatedNumber, intl, className, classes }) => {
	const confidentiableFieldName = `${getFieldName(field, duplicatedNumber)}.confidential`
	return (<>
		{children}
		<Grid item xs={1} className={classnames(classes.tooltip, className)}>
			{attData && attData[ATTRIBUTE_DATA_MO_FIELD.HELP] && <Tooltip title={attData[ATTRIBUTE_DATA_MO_FIELD.HELP].value} placement="right">
				<HelpIcon />
			</Tooltip>
			}
		</Grid>
		{field.confidentiable && <Grid item xs={3} className={classes.checkbox}>
			<Field
				name={confidentiableFieldName}
				label={intl.formatMessage({ id: 'document.field.confidentiable' })}
				component={Checkbox}
			/>
		</Grid>}
	</>)
}

DocumentRowField.propTypes = {
	children: PropTypes.node,
	field: PropTypes.object.isRequired,
	attData: PropTypes.object,
	intl: PropTypes.object,
	className: PropTypes.string,
	classes: PropTypes.object,
	duplicatedNumber: PropTypes.number
}

DocumentRowField.defaultProps = {
	children: <></>,
	attData: {},
	className: ''
}

export default compose(
	injectIntl,
	withStyles(styles)
)(DocumentRowField)
