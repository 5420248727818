import { Box, Grid, Slide, Typography, withStyles } from '@material-ui/core'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { push } from 'connected-react-router'
import { getStyles } from 'isotope-client'
import { injectLangues } from '../../common/langue/langueInjector'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import CenteredLayout from '../../../components/layout/CenteredLayout'
import Link from '../../../components/Link'
import { Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import TabDocumentActionsBar from './TabDocumentActionsBar'
import ShareRecherchePopin from '../document/recherche/ShareRecherchePopin'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import SelectionColonnesTabDocumentPopin from './selectionColonnes/SelectionColonnesTabDocumentPopin'
import { BUSINESS_SEGMENT, BUSINESS_SUB_SEGMENT, colors, DEFAULT_LANGUAGE_CODE, DEFAULT_LANGUAGE_ID, FIELDS_NO_FORMAT, MARKET, PROFILS, TYPE_CONTENU, TYPE_CONTENU_FIELD, VALUE_LIST_SHORTCUTS, ZONE } from '../../../utils/constants'
import * as userSelectors from '../user/services/userSelectors'
import * as tabDocumentSelector from './services/tabDocumentSelector'
import * as modelSelectors from '../../common/model/modelSelectors'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import TabDocumentTable from './table/TabDocumentTable'
import { cancelPopin, getDataTable, initColumnsSelected, initColumnsSelectedFromSharedSearch, resetDataTable, setDataTable } from './services/tabDocumentActions'
import Loader from '../../../components/layout/Loader'
import { goBack } from 'connected-react-router'
import { getRechercheById } from './services/tabDocumentApi'
import { ATTRIBUTE_DATA_MO_FIELD, ATTRIBUTE_DATA_MO_SECTION, FIELD_TYPE } from '../document/utils/documentConstants'
import RechercheDocumentForm from '../document/components/recherche/RechercheDocumentForm'
import Paper from '@material-ui/core/Paper'
import { hasAccess } from '../../../components/security/AccessChecker'
import { getAllUsers, getUsersForCorrespondant } from '../user/services/usersApi'
import { getFormValues, reduxForm, formValueSelector } from 'redux-form'
import { FORM_NAME_SEARCH } from '../document/recherche/RechercheDocumentPage'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { formatAdvancedFilter, newParamsCreation, transformIntoStringTab } from '../../../utils/utilsRecherche'
import { initFiltersFavoriteSearch } from '../document/utils/documentUtils'
import { shareRecherche } from './services/tabDocumentApi'
import { injectBidBriefList, injectBusinessSegmentList, injectBusinessSubSegmentList, injectCoreBusinessList, injectMarketList, injectPaysList, injectSectorList, injectWinLoseList, injectZoneList } from '../../common/valueList/ValueListInjector'
import { removeZeroFromList } from '../../../utils/utils'
import * as listesSelectors from '../../bo/listes/listesSelectors'

const CONSULTATION_VIEW = '/consultation-table'

const styles = theme => getStyles({
	retour: {
		color: theme.palette.text.main,
		display: 'flex',
		alignItems: 'center',
		marginLeft: '-10px',
		marginTop: '-20px',
		marginBottom: 10
	},
	title: {
		marginBottom: 10,
		height: 50,
		marginLeft: 20,
		marginTop: -20
	},
	headerTab: {
		backgroundColor: '#c0c0c0',
		height: 100
	},
	centeredLayout: {
		width: 'unset',
		margin: '25px 25px 0 25px'
	},
	searchFormContainer: {
		position: 'absolute',
		top: '12%',
		zIndex: 12
	},
	button: {
		position: 'absolute',
		top: '8%',
		left: '1%',
		zIndex: 12
	},
	link: {
		color: colors.primary,
		float: 'right',
		fontWeight: 500,
		paddingBottom: 15,
		marginRight: 10
	},
	search: {
		margin: '10px 0px 5px 10px',
		padding: 10,
		height: '76vh',
		overflowY: 'auto',
		overflowX: 'hidden'
	},
	filterTitle: {
		marginBottom: 20,
		marginTop: 14,
		marginLeft: 16
	},
	searchColumn: {
		position: 'relative'
	},
	closeButton: {
		position: 'absolute',
		right: 8,
		top: 2,
		color: colors.text
	},
	containerNavigation: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		flex: 1,
	}
})

const TabDocumentPage = ({
	idLanguage,
	goToListe,
	location,
	onEdition,
	classes,
	snackSuccess,
	snackError,
	getDataTable,
	initColumnsSelected,
	initColumnsSelectedFromSharedSearch,
	resetDataTable,
	modelData,
	cancelPopin,
	goBackAction,
	goToEditList,
	referenceDependecies,
	data,
	intl,
	langues,
	isCorrespondant,
	formValues,
	change,
	reset,
	handleSubmit,
	initialValues,
	typeContenu,
	initialTypeContenu,
	idsColumnsSelected,
	initialId,
	setDataTable,
	fieldsChecked,
	zoneList,
	paysList,
	marketList,
	sectorList,
	businessSegmentList,
	businessSubSegmentList,
	coreBusinessList,
	bidBriefList,
	winLoseList
}) => {
	const [selectedLanguage, setSelectedLanguage] = React.useState(idLanguage)
	const [usersList, setUsersList] = React.useState([])

	const [openShare, setOpenShare] = React.useState(false)
	const [openSelectColumns, setOpenSelectColumns] = React.useState(false)
	const [openSearchForm, setOpenSearchForm] = React.useState(false)

	const [loading, setLoading] = React.useState(false)
	const { selectedColumns } = data
	const [headerLabels, setHeaderLabels] = React.useState([])

	const [resetNbChipRequired, setResetNbChipRequired] = React.useState(false)
	const [fieldsAdvance] = React.useState([])
	const [lastSubmittedFieldsAdvance, setLastSubmittedFieldsAdvance] = React.useState(fieldsAdvance)
	const [adminCorrespFilters, setAdminCorrespFilters] = React.useState([])
	const [params, setParams] = React.useState({})

	React.useEffect(() => {
		if (isCorrespondant) {
			getUsersForCorrespondant().then(setUsersList)
		} else {
			getAllUsers().then(setUsersList)
		}
	}, [isCorrespondant])

	React.useEffect(() => {
		if (initialId) {
			setLoading(true)
			getRechercheById(initialId, onEdition)
				.then(data => {
					const {isConsultation, selectedColumns, targetLanguage, ...dataFilters } = data.filters
					const initSelectedColumnsIds = selectedColumns
					const initIdLanguage = langues.find(lang => lang.codeLang === targetLanguage).id

					if (initIdLanguage !== selectedLanguage) {
						setSelectedLanguage(initIdLanguage)
					}

					// Formattage des values pour l'init du formulaire
					const filters = {
						...dataFilters,
						fieldsAdvance: dataFilters.fieldsAdvance.map(f => {
							let fieldValue = f.value
							const unite = f.unite && JSON.parse(f.unite)

							// Conversion des éventuels fitres de type unité en unité non SI
							if (f.type === FIELD_TYPE.NUMBER_UNIT && unite) {
								fieldValue = fieldValue * unite.coeff
							}
							return ({
								...f,
								unite,
								value: fieldValue
							})
						})
					}

					if (filters && filters.fieldsAdmin && Object.values(filters.fieldsAdmin).length > 0) {
						initFiltersFavoriteSearch(filters, setAdminCorrespFilters)
					}

					Object.keys(filters).forEach(filterKey => {
						change(filterKey, filters[filterKey])
					})

					setDataTable({
						page: 0,
						size: data.responseTabDocument.results.length,
						total: data.responseTabDocument.count,
						content: data.responseTabDocument.results,
						selectedColumns: data.responseTabDocument.selectedColumns
					})
					setParams(dataFilters)
					initColumnsSelectedFromSharedSearch(initSelectedColumnsIds)
				})
				.catch(() => snackError(<FormattedMessage id="document.search.notFound" />))
				.finally(() => setLoading(false))
		} else {
			// On récupère les valeurs de location.state (Vient de recherche into visu. doc. tableau
			location && location.state && Object.values(params).length === 0 && setParams(location.state)
		}
	}, [initialId])

	React.useEffect(() => {
		// Récupération des filtres admins afin de les insérer dans le form
		if (location.state && location.state.fieldsAdmin) {
			// Si plusieurs filtres admin ou si un seul avec N éléments
			if (Array.isArray(location.state.fieldsAdmin)) {
				setAdminCorrespFilters(location.state.fieldsAdmin)
			} else {
				// Si initValues uniquement on aura un objet
				initFiltersFavoriteSearch(location.state, setAdminCorrespFilters, true)
			}
		}
		snackSuccess({ id: `oss.tabDocumentPage.snackBar.${onEdition ? 'tabModificationInfo' : 'tabConsultationInfo'}` }, { autoHideDuration: 15000 })
	}, [])

	React.useEffect(() => {
		// Il faudrait selectedColumns comme dépendance mais étant un tab, la référence n'est plus la même alors que le contenu l'est bien
		// Ici on recalcule le header à la fermeture de la popin de choix des colonnes (a voir car ouverture + fermeture sans rien changer)
		if (!openSelectColumns) {
			generateHierarchieHeaders()
		}
	}, [selectedLanguage, openSelectColumns, selectedColumns])

	React.useEffect(() => {
		return () => {
			resetDataTable()
			setOpenSearchForm(false)
		}
	}, [])

	React.useEffect(() => {
		if (!loading) {
			const paramsLength = Object.values(params).length
			// On init les fields checked uniquement si première apparition sur la page
			if (modelData && modelData.sections) {
				Object.values(fieldsChecked).length === 0
					? initColumnsSelected()
						.then(() => rechercherTabDocuments(0, paramsLength === 0 ? location.state : params, adminCorrespFilters))
					: rechercherTabDocuments(0, paramsLength === 0 ? location.state : params, adminCorrespFilters)
			}
		}
	}, [modelData, langues])

	const submitSearchForm = (values) => {
		change('idConsulte', undefined)
		setOpenSearchForm(false)
		values.idConsulte = undefined
		rechercherTabDocuments(0, values, adminCorrespFilters, true)
	}

	const getData = (page, params, locationUrl, lang) => {
		setLoading(true)
		getDataTable(page, params, locationUrl.startsWith(CONSULTATION_VIEW), lang)
			.finally(() => {
				setLoading(false)
			})

	}

	const getCodeLang = (idLang) => (langues.find(langue => langue.id === idLang) || {}).codeLang || DEFAULT_LANGUAGE_CODE

	/**
	 * Formattage du contenu des champs de la requete avant lancement de la recherche
	 * @param pageNo
	 * @param currentValues
	 * @param adminFilters
	 * @param fromForm
	 * @param langue
	 */
	const rechercherTabDocuments = (pageNo, currentValues, adminFilters, fromForm, langue) => {
		if (fromForm) {
			const newAvanceeParams = []
			formatAdvancedFilter(currentValues, newAvanceeParams)
			const paramFilters = adminFilters || currentValues.fieldsAdmin
			const newParams = newParamsCreation(currentValues, initialTypeContenu, paramFilters, newAvanceeParams)

			// Enregistrement dans redux
			if (!!adminFilters) {
				change('fieldsAdmin', adminFilters)
			}
			setParams(newParams)
			getData(pageNo, newParams, location.pathname, getCodeLang(langue ? langue : selectedLanguage))
		} else {
			getData(pageNo, currentValues, location.pathname, getCodeLang(langue ? langue : selectedLanguage))
		}
	}

	const handlePageChange = React.useCallback(newPage => {
		rechercherTabDocuments(newPage, params, adminCorrespFilters)
	}, [rechercherTabDocuments, params])

	const handleLanguageChange = React.useCallback(language => {
		// Si l'on vient d'un partage de recherche : on set la langue mais on ne souhaite pas refaire de call
		if (!loading) {
			rechercherTabDocuments(0, params, adminCorrespFilters, false, language)
		}
	}, [rechercherTabDocuments, params, loading])

	const handleChangeSelectColumnsTabPopin = (status) => {
		setOpenSelectColumns(status)
	}

	const handleColumnsChange = React.useCallback(() => {
		setLoading(true)
		getDataTable(0, params, location.pathname.startsWith(CONSULTATION_VIEW), getCodeLang(selectedLanguage))
			.then(() => handleChangeSelectColumnsTabPopin(false))
			.catch(() => snackError({ id: 'oss.popinChoixFields.maxColumnReached' }, { autoHideDuration: 7000 }))
			.finally(() => setLoading(false))

	}, [selectedLanguage, params])

	const renderHeader = React.useCallback(() => {
		return (
			<Grid container>
				<Grid item xs={2}>
					<Link to="/documents" className={classes.retour} onClick={() => location.state ? goBackAction() : goToListe()} isReturn>
						<ArrowLeftIcon fontSize="large" />
						<FormattedMessage id="document.retour" />
					</Link>
				</Grid>
				<Grid item xs={10}>
					<Typography variant="h1" className={classes.title}>
						<FormattedMessage id={`oss.tabDocumentPage.${onEdition ? 'titleEdition' : 'titleConsultation'}`} />
					</Typography>
				</Grid>
			</Grid>
		)
	}, [])

	const partagerRecherche = (values) => {
		//Ici on passe dans le language du selectedLanguage et non de l'user pour récupérer la recherche à l'identique lors du partage
		const targetLanguage = (langues.find(langue => langue.id === selectedLanguage) || {}).codeLang || DEFAULT_LANGUAGE_CODE

		return shareRecherche({
			...values,
			recherche: { ...params, targetLanguage, selectedColumns: Object.keys(idsColumnsSelected), isConsultation: !onEdition },
			isTabSearch: true,
			onEdition
		})
			.then(() => {
				setOpenShare(false)
				snackSuccess(<FormattedMessage id="document.search.share.success" />)
			})
	}

	const findMoFieldInModel = (sections, key, field) => {
		let searchedField = field ? field : null

		sections.forEach(section => {
			if (section.fields.length > 0 && !searchedField) {
				const moField = section.fields.find(field => field.reference === key)
				if (moField) {
					searchedField = moField
					return
				}
			}

			if (section.sections.length > 0 && !searchedField) {
				searchedField = findMoFieldInModel(section.sections, key, searchedField)
			}
		})
		return searchedField
	}

	const getRacineAndParentSections = (tabHierarchie, sections, tabEntities) => {
		// Section mère directement racine
		if (tabHierarchie.length === 1) {
			const firstElement = tabHierarchie.shift()
			const sectionMere = sections.find(section => section.id === firstElement)
			if (sectionMere) {
				tabEntities.push(sectionMere)
			}
		} else {
			//Section mère + section racine
			const firstElement = tabHierarchie.shift()
			const sectionRacine = sections.find(section => section.id === firstElement)
			if (sectionRacine) {
				// Le premier élément sera le nom racine et le dernier le nom du parent direct
				if (tabEntities.length === 0) {
					tabEntities.push(sectionRacine)
				}

				// On va naviguer dans les sections recursivement pour get le parent direct
				getRacineAndParentSections(tabHierarchie, sectionRacine.sections, tabEntities)
			}
		}
		return tabEntities
	}

	const findParentAndRacineSection = (idField) => {
		const hierarchieFieldSections = []

		// On get la section parent directe
		const idSectionMere = Object.entries(referenceDependecies).find(([key, value]) => value.fieldsChildren.find(child => child.id === idField) || value.fieldsAdvancedChildren.find(child => child.id === idField))[0]
		hierarchieFieldSections.push(idSectionMere)

		// On get toute la hiérarchie des parents pour aller chercher les infos plus facilement dans model.data.REFERENCE.sections
		findIdSectionRacine(hierarchieFieldSections, idSectionMere)
		return hierarchieFieldSections
	}

	const findIdSectionRacine = (hierarchieFieldSections, idSectionMere) => {
		const idSectionParent = Object.entries(referenceDependecies).find(([key, value]) => value.sectionsChildren.find(child => child.id === idSectionMere))
		if (idSectionParent != null) {
			hierarchieFieldSections.push(idSectionParent[0])
			findIdSectionRacine(hierarchieFieldSections, idSectionParent[0])
		}
	}

	const generateField = (moField, titleField) => ({
		id: moField.id,
		title: titleField,
		headerKey: moField.reference,
		position: moField.position
	})

	const addFieldInRacine = (dataHeaders, moField, titleField, racine, titleRacine) => {
		if (dataHeaders.hasOwnProperty(racine.id)) {
			// Update des fields de la racine en ne surchargeant pas ses attributs title, id, position
			dataHeaders[racine.id].fields = [
				...dataHeaders[racine.id].fields,
				generateField(moField, titleField)
			]
		} else {
			// Création de la racine et de ses attributs
			dataHeaders[racine.id] = {
				title: titleRacine,
				position: racine.position,
				sections: {},
				fields: [generateField(moField, titleField)]
			}
		}
	}

	const addFieldInSection = (dataHeaders, moField, titleField, racine, titleRacine, section, titleSection) => {
		if (dataHeaders.hasOwnProperty(racine.id)) {
			// La racine existe
			if (dataHeaders[racine.id].sections && dataHeaders[racine.id].sections.hasOwnProperty(section.id)) {
				// La section existe dans la racine
				dataHeaders[racine.id].sections[section.id].fields = [
					...dataHeaders[racine.id].sections[section.id].fields,
					generateField(moField, titleField)
				]
			} else {
				// La section n'existe pas dans la racine
				dataHeaders[racine.id].sections = {
					...dataHeaders[racine.id].sections,
					[section.id]: {
						title: titleSection,
						position: section.position,
						fields: [generateField(moField, titleField)]
					}
				}
			}
		} else {
			// Création de la racine et de ses attributs
			dataHeaders[racine.id] = {
				title: titleRacine,
				position: racine.position,
				sections: {
					[section.id]: {
						title: titleSection,
						position: section.position,
						fields: [generateField(moField, titleField)]
					}
				},
				fields: []
			}
		}
	}

	const buildHeaders = (moField, dataHeaders) => {
		const titleField = moField.attributeData[selectedLanguage][ATTRIBUTE_DATA_MO_FIELD.NAME].value
		const idsHierarchie = findParentAndRacineSection(moField.id).reverse()
		const entities = getRacineAndParentSections(idsHierarchie, modelData.sections, [])
		const labelsRacineAndParentDirect = entities.map(section => section.attributeData[selectedLanguage][ATTRIBUTE_DATA_MO_SECTION.TITLE].value)
		entities.push(moField)
		labelsRacineAndParentDirect.push(titleField)

		if (labelsRacineAndParentDirect && labelsRacineAndParentDirect.length > 0) {
			if (labelsRacineAndParentDirect.length === 2) {
				// La racine est le parent direct
				addFieldInRacine(dataHeaders, moField, titleField, entities[0], labelsRacineAndParentDirect[0])
			} else if (labelsRacineAndParentDirect.length === 3) {
				// Il existe un parent entre le field et la racine
				addFieldInSection(dataHeaders, moField, titleField, entities[0], labelsRacineAndParentDirect[0], entities[1], labelsRacineAndParentDirect[1])
			}
		}
	}

	const generateHierarchieHeaders = () => {
		let dataHeaders = {}

		Object.keys(selectedColumns).forEach(key => {
			const moFieldByReference = findMoFieldInModel(modelData.sections, key)
			// Création de l'objet contenant les data : titre, position, id, sections, fields
			buildHeaders(moFieldByReference, dataHeaders)
		})

		const sortedHeaders = Object.values(dataHeaders)
			.sort((sect1, sect2) => sect1.position - sect2.position)
			.map(racine => ({
				...racine,
				fields: racine.fields.sort((f1, f2) => f1.position - f2.position),
				sections: Object.values(racine.sections)
					.map(sectionEnfant => ({
						...sectionEnfant,
						fields: sectionEnfant.fields.sort((f1, f2) => f1.position - f2.position)
					}))
			}))
		setHeaderLabels(sortedHeaders)
	}

	const initForm = React.useMemo(() => ({ selectedLanguage: selectedLanguage || idLanguage || DEFAULT_LANGUAGE_ID }
	), [idLanguage, selectedLanguage])

	if (!modelData || !modelData.sections) {
		return <Loader />
	}

	const getEmptyFilter = () => {
		reset()
		setResetNbChipRequired(true)
	}

	const rechercherAvancee = (avanceeValues) => {
		change('fieldsAdvance', avanceeValues)
	}

	return <Form
		onSubmit={() => {
		}}
		initialValues={initForm}
	>
		{() => {
			return (
				<Grid>
					<CenteredLayout className={classes.centeredLayout}>
						<OnChange name="selectedLanguage">
							{(values) => {
								handleLanguageChange(values)
								setSelectedLanguage(values)
							}}
						</OnChange>

						{/*Header + Action bar*/}
						<div className={classes.containerNavigation}>
							{renderHeader()}
							<Grid container spacing={1}>
								<TabDocumentActionsBar
									langues={langues}
									setOpenShare={setOpenShare}
									setOpenSelectColumns={setOpenSelectColumns}
									selectedLanguage={selectedLanguage}
									goToEditList={(onEdition) => goToEditList(onEdition ? '/edition-table' : '/consultation-table', params)}
									onEdition={onEdition}
								/>
							</Grid>
						</div>

						{/*Tableau*/}
						<TabDocumentTable
							selectedLanguage={selectedLanguage}
							onEdition={onEdition}
							goToListe={goToListe}
							loading={loading}
							handlePageChange={handlePageChange}
							headerLabels={headerLabels}
							generateHierarchieHeaders={generateHierarchieHeaders}
							modelData={modelData}
							langues={langues}
							intl={intl}
							setOpenSearchForm={setOpenSearchForm}
							location={location}
						/>

						{/*Popin partage tableau*/}
						{openShare &&
							<ShareRecherchePopin
								title={<FormattedMessage id="oss.popin.shareTitle.title" />}
								open={openShare}
								onClose={() => setOpenShare(false)}
								onSubmit={partagerRecherche}
							/>}

						{/*Popin selection des colonnes*/}
						{openSelectColumns && <SelectionColonnesTabDocumentPopin
							selectedLanguage={selectedLanguage}
							open={openSelectColumns}
							onSubmit={handleColumnsChange}
							isDataLoading={loading}
							onClose={() => {
								cancelPopin()
								handleChangeSelectColumnsTabPopin(false)
							}}
						/>}
					</CenteredLayout>

					{
						<Box className={classes.searchFormContainer} style={{ maxWidth: '25%', display: openSearchForm ? 'block' : 'none', top: '8%' }}>
							<Slide direction="right" in={openSearchForm} timeout={800}>
								{
									<Grid item className={classes.searchColumn}>
										<Paper className={classes.search}>
											<Grid>
												<Typography variant="h2" className={classes.filterTitle}>
													<FormattedMessage id="document.search.filterTitle" />
												</Typography>
												<IconButton className={classes.closeButton} onClick={() => setOpenSearchForm(false)}>
													<CloseIcon />
												</IconButton>
											</Grid>
											<Link onClick={getEmptyFilter} className={classes.link}><FormattedMessage id="global.link.viderFiltre" /></Link>
											<RechercheDocumentForm
												langues={langues}
												zoneList={zoneList}
												businessSegmentList={businessSegmentList}
												businessSubSegmentList={businessSubSegmentList}
												coreBusinessList={coreBusinessList}
												bidBriefList={bidBriefList}
												marketList={marketList}
												sectorList={sectorList}
												paysList={paysList}
												winLoseList={winLoseList}
												setOpenSearchForm={setOpenSearchForm}
												resetNbChipRequired={resetNbChipRequired}
												setResetNbChipRequired={setResetNbChipRequired}
												usersList={usersList}
												intl={intl}
												isCorrespondant={isCorrespondant}
												change={change}
												formValues={formValues}
												onSubmit={handleSubmit(submitSearchForm)}
												lastSubmittedFieldsAdvance={lastSubmittedFieldsAdvance}
												setLastSubmittedFieldsAdvance={setLastSubmittedFieldsAdvance}
												addField={(field) => rechercherAvancee([...((formValues && formValues.fieldsAdvance) || []), field])}
												rechercherAvancee={rechercherAvancee}
												adminCorrespFilters={adminCorrespFilters}
												setAdminCorrespFilters={setAdminCorrespFilters}
												initialValues={initialValues}
												typeContenu={typeContenu}
												initialTypeContenu={initialTypeContenu}
												clearSearchAdvance={() => {
													if (formValues && formValues.fieldsAdvance && formValues.fieldsAdvance.length > 0) {
														rechercherAvancee([])
													} else {
														change('fieldsAdvance', [])
													}
												}}
											/>
										</Paper>
									</Grid>
								}
							</Slide>
						</Box>
					}
				</Grid>)
		}}
	</Form>
}

const mapStateToProps = (state, { location, match }) => {
	const authorities = userSelectors.getAuthorities(state)
	const isCorrespondant = hasAccess(PROFILS.CORRESPONDANT, authorities)
	const formValues = getFormValues(FORM_NAME_SEARCH)(state)
	const selector = formValueSelector(FORM_NAME_SEARCH)

	const zoneSelected = removeZeroFromList(selector(state, ZONE))
	const zoneSelectedFormat = transformIntoStringTab(zoneSelected)
	const segmentSelected = removeZeroFromList(selector(state, BUSINESS_SEGMENT))
	const segmentSelectedFormat = transformIntoStringTab(segmentSelected)
	const subSegmentSelected = removeZeroFromList(selector(state, BUSINESS_SUB_SEGMENT))
	const subSegmentSelectedFormat = transformIntoStringTab(subSegmentSelected)
	const marketSelected = removeZeroFromList(selector(state, MARKET))
	const marketSelectedFormat = transformIntoStringTab(marketSelected)

	if (formValues) {
		Object.entries(formValues).forEach(([key, value]) => {
			if (key !== FIELDS_NO_FORMAT.FILTRES_ADMIN && key !== FIELDS_NO_FORMAT.FILTRES_AVANCE
				&& key !== FIELDS_NO_FORMAT.QUERY && key !== FIELDS_NO_FORMAT.VIEW_FIELDS
				&& key !== FIELDS_NO_FORMAT.SORT_FIELDS && key !== FIELDS_NO_FORMAT.CONSULTATION
				&& key !== FIELDS_NO_FORMAT.ID_CONSULTE) {
				if (value && typeof formValues[key] === 'object') {
					formValues[key] = value.map(val => {
						if (typeof val === 'string') {
							return ({ code: val })
						}
						return val
					})
				}
			}
		})
	}

	// TODO initialValues : non vide si l'on vient uniquement d'un partage de recherche
	// Sinon on récupère le form de la recherche
	return ({
		idLanguage: userSelectors.getUserLanguage(state),
		modelData: modelSelectors.getModelByType(state, TYPE_CONTENU.REFERENCE),
		onEdition: location.pathname.includes('edition-table'),
		referenceDependecies: modelSelectors.getReferenceDependencies(state),
		data: tabDocumentSelector.getTabDocumentData(state),
		idsColumnsSelected: tabDocumentSelector.getAllFieldsChecked(state),
		initialTypeContenu: location && location.state && location.state.initialTypeContenu,
		fieldsChecked: tabDocumentSelector.getAllFieldsChecked(state),
		isCorrespondant,
		formValues,
		typeContenu: selector(state, TYPE_CONTENU_FIELD),
		initialId: (match && match.params && match.params.id),
		paysList: listesSelectors.getFilteredListsPays(state, zoneSelectedFormat, VALUE_LIST_SHORTCUTS.PAYS),
		sectorList: listesSelectors.getFilteredLists(state, marketSelectedFormat, VALUE_LIST_SHORTCUTS.SECTOR),
		businessSubSegmentList: listesSelectors.getFilteredLists(state, segmentSelectedFormat, VALUE_LIST_SHORTCUTS.BUSINESS_SUB_SEGMENT),
		coreBusinessList: listesSelectors.getFilteredLists(state, subSegmentSelectedFormat || segmentSelectedFormat, VALUE_LIST_SHORTCUTS.CORE_BUSINESS),
		initialValues: {
			query: '',
			fieldsAdvance: [],
			fieldsAdmin: {},
			sortFields: []
		}
	})
}

const actions = {
	getDataTable,
	initColumnsSelected,
	initColumnsSelectedFromSharedSearch,
	resetDataTable,
	cancelPopin,
	setDataTable,
	goToListe: () => dispatch => dispatch(push('/documents',{ initialId: undefined, initialQuery: undefined, initialTypeContenu: undefined, initialFieldsAdmin: undefined, initialFieldsAdvance: undefined })),
	goToEditList: (pathname, state) => dispatch => dispatch(push({ pathname, state })),
	goBackAction: () => dispatch => dispatch(goBack())
}

TabDocumentPage.propTypes = {
	classes: PropTypes.object,
	langues: PropTypes.array,
	idLanguage: PropTypes.string,
	onEdition: PropTypes.bool,
	intl: intlShape,
	goToListe: PropTypes.func,
	snackSuccess: PropTypes.func,
	snackError: PropTypes.func,
	getDataTable: PropTypes.func,
	goBackAction: PropTypes.func,
	location: PropTypes.object,
	initColumnsSelected: PropTypes.func,
	initColumnsSelectedFromSharedSearch: PropTypes.func,
	resetDataTable: PropTypes.func,
	setDataTable: PropTypes.func,
	modelData: PropTypes.object,
	cancelPopin: PropTypes.func,
	referenceDependecies: PropTypes.object,
	data: PropTypes.object,
	isCorrespondant: PropTypes.bool,
	formValues: PropTypes.object,
	change: PropTypes.func,
	reset: PropTypes.func,
	handleSubmit: PropTypes.func,
	initialValues: PropTypes.object,
	typeContenu: PropTypes.string,
	initialTypeContenu: PropTypes.string,
	initialId: PropTypes.string,
	fieldsChecked: PropTypes.object
}

export default compose(
	injectIntl,
	injectLangues,
	injectSnackActions,
	injectZoneList,
	injectPaysList,
	injectMarketList,
	injectSectorList,
	injectBusinessSegmentList,
	injectBusinessSubSegmentList,
	injectCoreBusinessList,
	injectBidBriefList,
	injectWinLoseList,
	connect(mapStateToProps, actions),
	reduxForm({
		form: FORM_NAME_SEARCH,
		destroyOnUnmount: false
	}),
	withStyles(styles)
)(TabDocumentPage)
