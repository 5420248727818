import { FIELDS_ADMIN_CORRESP, TIMELINE, UNSPECIFIED } from './constants'
import { FIELD_TYPE, SELECT_OPTIONS_AVANCEE } from '../modules/fo/document/utils/documentConstants'

/**
 * Fichier regroupant toutes les méthodes communes des deux types de recherche :
 * → Recherche de document (RechercheDocumentPage.jsx)
 * → Recherche sous forme de tableau (TabDocumentPage.jsx)
 */

/**
 * Formatage des params du formulaire
 * Passage de la forme MultiSelect: {label:"Test", code: "code"} → "code"
 * Le partage des recherches sont de la forme "code"
 * V1: Select : "code" de base
 * V2: Update vers des MultiSelect
 * @param param - le contenu d'un champ
 */
// Value d'un champ avec autocompleteMulti : {label:"Test", code: "code"} -> "code"
export const toCodeList = (param) => param && param.length > 0 ? param.map(option => option.code) : null

// Dans le cas d'un init des fieldsAdmin, on récupère uniquement la date
const formatInitFieldsAdmin = (param) => param && param.length > 1 && param[1]

/**
 * Formatage des fieldsAdmin s'il y en a
 * @param param - le contenu d'un champ admin
 * @param champ - le nom du champ
 */
export const toCodeListFromAdmin = (param, champ) => {
	// Check de la présence d'un filtre admin
	const typeField = (param && param.length > 0) ? param.filter(option => option.typeFilter === champ) : null
	return (typeField && typeField.length > 0)
		? typeField[0].value.map(val => val.code)
		: null
}

/**
 * Formatage de la requête pour les dates
 * @param param - les filtres admin
 * @param champ - le code du champ
 * @param dateDebut - souhaite-t-on une date de debut ou de fin
 */
export const formatForDateFilter = (param, champ, dateDebut = false) => {
	const typeField = (param && param.length > 0) ? param.filter(option => option.typeFilter === champ) : null

	if (typeField && typeField.length > 0) {
		const filtre = typeField[0]
		//Affectation (dateCreation)Debut
		if (dateDebut) {
			if (filtre.value[0] === TIMELINE.AFTER || filtre.value[0] === TIMELINE.BETWEEN) {
				return filtre.value[1]
			}
		} else {
			//Affectation (dateCreation)Fin
			if (filtre.value[0] === TIMELINE.BEFORE) {
				return filtre.value[1]
			}
			if (filtre.value[0] === TIMELINE.BETWEEN) {
				return filtre.value[2]
			}
		}
		if (filtre.value[0] === TIMELINE.AT) {
			return filtre.value[1]
		}
	}
}

/**
 * Formatage final des fieldsAdmin
 * @param paramFilters - les filtres admin
 */
export const formatAdminFilters = (paramFilters) => (
	{
		status: toCodeListFromAdmin(paramFilters, FIELDS_ADMIN_CORRESP.STATUS.name) || toCodeList(paramFilters.status),
		contributor: toCodeListFromAdmin(paramFilters, FIELDS_ADMIN_CORRESP.CONTRIBUTOR.name) || toCodeList(paramFilters.contributor),
		publierPar: toCodeListFromAdmin(paramFilters, FIELDS_ADMIN_CORRESP.ADMIN_PUBLICATION.name) || toCodeList(paramFilters.publierPar),
		modifierPar: toCodeListFromAdmin(paramFilters, FIELDS_ADMIN_CORRESP.USER_LAST_MODIF.name) || toCodeList(paramFilters.modifierPar),
		masquerPar: toCodeListFromAdmin(paramFilters, FIELDS_ADMIN_CORRESP.ADMIN_DELETE.name) || toCodeList(paramFilters.masquerPar),
		dateCreationDebut: formatForDateFilter(paramFilters, FIELDS_ADMIN_CORRESP.DATE_CREATION.name, true),
		dateCreationFin: formatForDateFilter(paramFilters, FIELDS_ADMIN_CORRESP.DATE_CREATION.name),
		dateSoumissionDebut: formatForDateFilter(paramFilters, FIELDS_ADMIN_CORRESP.DATE_SOUMISSION.name, true),
		dateSoumissionFin: formatForDateFilter(paramFilters, FIELDS_ADMIN_CORRESP.DATE_SOUMISSION.name),
		dateModificationDebut: formatForDateFilter(paramFilters, FIELDS_ADMIN_CORRESP.DATE_LAST_MODIF.name, true),
		dateModificationFin: formatForDateFilter(paramFilters, FIELDS_ADMIN_CORRESP.DATE_LAST_MODIF.name),
		datePublicationDebut: formatForDateFilter(paramFilters, FIELDS_ADMIN_CORRESP.DATE_PUBLICATION.name, true) || formatInitFieldsAdmin(paramFilters.datePublication),
		datePublicationFin: formatForDateFilter(paramFilters, FIELDS_ADMIN_CORRESP.DATE_PUBLICATION.name),
		dateMasqueDebut: formatForDateFilter(paramFilters, FIELDS_ADMIN_CORRESP.DATE_MASQUED.name, true),
		dateMasqueFin: formatForDateFilter(paramFilters, FIELDS_ADMIN_CORRESP.DATE_MASQUED.name)
	}
)

/**
 * Formatage des filtres admin de type date
 * @param params
 * @param newAvanceeParams
 */
export const formatAdvancedFilter = (params, newAvanceeParams) => {
	//  fieldsAdvance : On divise en deux filtres les champ de type BETWEEN: inférieur/supérieur ou avant/après dans le cas d'une date
	if (params && params.fieldsAdvance && params.fieldsAdvance.length > 0) {
		params.fieldsAdvance.forEach(field => {
			if (field.searchType === SELECT_OPTIONS_AVANCEE.BETWEEN) {
				newAvanceeParams.push({ ...field, searchType: field.type === FIELD_TYPE.DATE ? SELECT_OPTIONS_AVANCEE.AFTER : SELECT_OPTIONS_AVANCEE.SUPERIOR, value: field.value[0] })
				newAvanceeParams.push({ ...field, searchType: field.type === FIELD_TYPE.DATE ? SELECT_OPTIONS_AVANCEE.BEFORE : SELECT_OPTIONS_AVANCEE.INFERIOR, value: field.value[1] })
			} else if (field.searchType === SELECT_OPTIONS_AVANCEE.EQUAL_DATE) {
				newAvanceeParams.push({ ...field, searchType: SELECT_OPTIONS_AVANCEE.AFTER, value: field.value })
				newAvanceeParams.push({ ...field, searchType: SELECT_OPTIONS_AVANCEE.BEFORE, value: field.value })
			} else {
				newAvanceeParams.push(field)
			}
		})
	}
}

/**
 * Création des nouveaux params pour la recherche
 * @param params - les anciens params
 * @param initialTypeContenu - le type de contenu
 * @param paramFilters - les filtres admins
 * @param newAvanceeParams - les filtres avancés
 */
export const newParamsCreation = (params, initialTypeContenu, paramFilters, newAvanceeParams) => (
	{
	...params,
		typeContenu: (initialTypeContenu && [initialTypeContenu]) || (toCodeList(params.typeContenu)),
		fieldsAdmin: paramFilters ? formatAdminFilters(paramFilters) : {},
		fieldsAdvance: (newAvanceeParams).map(rechAv => ({
			...rechAv,
			value: rechAv.type === FIELD_TYPE.NUMBER_UNIT ? rechAv.value / (rechAv.unite ? rechAv.unite.coeff : 1) : rechAv.value,
			unite: JSON.stringify(rechAv.unite)
		})
	),
		zone: toCodeList(params.zone),
		langue: toCodeList(params.langue),
		market: toCodeList(params.market),
		sector: toCodeList(params.sector),
		pays: toCodeList(params.pays),
		businessSegment: toCodeList(params.businessSegment),
		businessSubSegment: toCodeList(params.businessSubSegment),
		coreBusiness: toCodeList(params.coreBusiness),
		bidBrief: toCodeList(params.bidBrief),
		internalExternal: toCodeList(params.internalExternal),
		proposalStatus: params.proposalStatus && !(params.proposalStatus.some(opti => opti.code === UNSPECIFIED)) ? toCodeList(params.proposalStatus) : null,
		withAttachment: toCodeList(params.withAttachment)
	}
)

export const transformIntoStringTab = (param) => {
	if(param){
		if(typeof param[0] === 'string'){
			return param
		}
		return param.map(val => val.code)
	}
	return null
}
