import { fetchFactory } from 'isotope-client'

export const getUsers = (pageNo, params) => fetchFactory(`/utilisateurs/${pageNo}`, {
	method: 'POST',
	body: JSON.stringify(params)
})

export const getContributeurs = (zone) => fetchFactory(`/utilisateurs/contributeur?zone=${encodeURI(zone)}&email=`)

export const getContributeur = (contributorEmail) => fetchFactory(`/utilisateurs/contributeur?zone=&email=${encodeURI(contributorEmail)}`)

export const validerDemande = (userId) => fetchFactory(`/utilisateurs/valider/${userId}`, {
	method: 'put'
})

export const refuserDemande = (userId, message) => fetchFactory(`/utilisateurs/refuser/${userId}`, {
	method: 'put',
	body: message
})

export const getUsersList = (nbUser, params) => fetchFactory(`/utilisateurs/liste/${nbUser}`, {
	method: 'POST',
	body: JSON.stringify(params)
})

export const getUser = (idUser) => fetchFactory(`/utilisateurs/user/${idUser}`)

export const getMe = () => fetchFactory(`/utilisateurs/me`)

export const enregistrerUser = (idUser, params) => fetchFactory(`/utilisateurs/enregistrer/${idUser}`, {
	method: 'put',
	body: JSON.stringify(params)
})

export const askAccess = (params) => fetchFactory(`/utilisateurs/ask-access`, {
	method: 'put',
	body: JSON.stringify(params)
})

export const getAllUsers = () => fetchFactory('/utilisateurs/all')

export const getUsersForCorrespondant = () => fetchFactory('/utilisateurs/for-correspondant')

export const getVeoliaEmail = (prefix) => fetchFactory(`/utilisateurs/emails?prefix=${prefix}`)

export const exportRecherche = (params) => fetchFactory('/utilisateurs/export', {
	method: 'POST',
	body: JSON.stringify(params)
})
