import * as api from './loginApi'
import { googleLogout } from '@react-oauth/google'

export const TOKEN_REFRESH = 'TOKEN_REFRESH'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_ERROR = 'GET_USER_ERROR'

/**
 * Cette fonction prend en paramètre un googleId et un username et s'occupe d'authentifier l'utilisateur
 * @param user
 * @return token Un token JWT isotope
 */
const USER_DISABLED = 'auth.userDisabled'
export const logUser = (infoUser) => dispatch => {
	dispatch({ type: LOGIN_REQUEST })
	return new Promise((resolve, reject) => {
		api.login(infoUser)
			.then(data => {
				dispatch({ type: LOGIN_SUCCESS, data })
				api.storeLocalUser(data)
				resolve(data.token)
			})
			.catch(e => {
				dispatch({ type: LOGIN_ERROR, e })
				if (e.bodyError && e.bodyError.id === USER_DISABLED) {
					reject({ disabledUser: true })
				} else {
					reject(e)
				}
			})
	})
}

export const logOutLoginPage = () => dispatch => {
	return new Promise((resolve, reject) => {
		googleLogout()
		dispatch({ type: LOGOUT_SUCCESS})
		resolve()
	})
}

export const logOut = () => dispatch => {
	googleLogout()
	return dispatch({ type: LOGOUT_SUCCESS})
}

/**
 * Récupère un utilisateur à partir du token passé
 * @param token
 * @return utilisateur Un user isotope
 */
export const getUser = token => dispatch => {
	dispatch({ type: GET_USER_REQUEST })
	return new Promise((resolve, reject) => {
		api.fetchUser(token)
			.then(user => {
				dispatch({ type: GET_USER_SUCCESS, user })
				resolve(user)
			})
			.catch(e => {
				dispatch({ type: GET_USER_ERROR, e })
				reject(e)
			})
	})
}
