import { splitSubListByValue, splitSubListPaysByValue } from '../../../utils/utils'
import { getItemsByListCode } from 'isotope-client/modules/valueList/services/valueListSelectors'

const getList = function getList(state, id) {
	return getItemsByListCode(state)[id]
}

const REF_COUNTRY = 'FI_COUNTRY'
export const getFilteredListsForDocumentForm = (state, valueParent, codeListToFilter, refField) => {
	if (refField === REF_COUNTRY) {
		return getFilteredListsPays(state, valueParent, codeListToFilter)
	}

	return getFilteredLists(state, valueParent, codeListToFilter)
}

export const getFilteredLists = (state, valueParent, codeListToFilter) => splitSubListByValue(valueParent, getList(state, codeListToFilter))

export const getFilteredListsPays = (state, valueParent, codeListToFilter) => splitSubListPaysByValue(valueParent, getList(state, codeListToFilter))
