import { MenuItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import MultiSelect from '../../../../components/form/MultiSelect'
import { hasAccess } from '../../../../components/security/AccessChecker'
import { PROFILS, USER_FORM } from '../../../../utils/constants'
import { getAuthorities } from '../services/userSelectors'

const MultiSelectUserForm = ({ name, elements, isDisabled, isHidden }) => {
	if (isHidden) {
		return null
	}
	return (
		<Field
			component={MultiSelect}
			withDefault
			name={name}
			disabled={isDisabled}
			label={<FormattedMessage id={`users.search.${name}Label`} />}
		>
			{elements && elements.map(element =>
				<MenuItem key={`${name}-${element.code}`} value={element.code}>
					{element.label}
				</MenuItem>
			)}
		</Field>
	)
}


MultiSelectUserForm.propType = {
	name: PropTypes.string.isRequired,
	elements: PropTypes.array,
	correspondantsScreen: PropTypes.bool
}

const mapStateToProps = (state, { correspondantsScreen, name }) => {
	const authorities = getAuthorities(state)
	const isAdmin = hasAccess(PROFILS.ADMIN, authorities)
	const isDisabled = !(isAdmin || correspondantsScreen)
	const isNotPays = [USER_FORM.SEGMENT, USER_FORM.MARKET].indexOf(name) !== -1
	return {
		isDisabled: isDisabled,
		isHidden: (isDisabled && isNotPays)
	}
}

export default connect(mapStateToProps)(MultiSelectUserForm)
