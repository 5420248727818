import Button from '@material-ui/core/Button'
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Input from '../../../../../components/form/Input'
import { BUSINESS_SEGMENT, BUSINESS_SUB_SEGMENT, colors, FIELDS_ADMIN_CORRESP, MARKET, PROFILS, SEARCH_FORM_INTERNE_EXTERNE_CODES, SEARCH_FORM_WITH_ATTACHMENT_CODES, SVG_ICONS, TYPE_CONTENU, TYPE_CONTENU_FIELD } from '../../../../../utils/constants'
import Actions from '../../../../../components/form/Actions'
import { Field, FormSection } from 'redux-form'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import AutocompleteMulti from '../../../../../components/form/AutocompleteMulti'
import { sortObjectByLabel } from '../../../../../utils/utils'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classnames from 'classnames'
import Divider from '@material-ui/core/Divider'
import PlaceIcon from '@material-ui/icons/Place'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import DescriptionIcon from '@material-ui/icons/Description'
import SearchIcon from '@material-ui/icons/Search'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Fab from '@material-ui/core/Fab'
import AccessChecker from '../../../../../components/security/AccessChecker'
import AdminCorrespondantFiltersPopin from '../popin/AdminCorrespondantFiltersPopin'
import AdminFiltersApplied from '../AdminFiltersApplied'
import SvgIcon from '../../../../../components/icon/SvgIcon'
import AdditionalFiltersPopin from '../../recherche/AdditionalFiltersPopin'
import AdditionalFiltersApplied from '../AdditionalFiltersApplied'
import { updateRechercheStatusCollapse } from '../../services/documentActions'
import { RECHERCHE_FORM_COLLAPSED_ID } from '../../utils/documentConstants'
import * as documentSelector from '../../services/documentSelectors'

const QUERY = 'query'
const PAGE_NO = 'PageNo'
const ID_CONSULTE = 'idConsulte'
const LANGUE = 'langue'
const ZONE = 'zone'
const PAYS = 'pays'
const SECTOR = 'sector'
const CORE_BUSINESS = 'coreBusiness'
const BID_BRIEF = 'bidBrief'
const PROPOSAL_STATUS = 'proposalStatus'
const WITH_ATTACHMENT = 'withAttachment'
const INTERNAL_EXTERNAL = 'internalExternal'

const styles = (theme) => getStyles({
	button: {
		width: 56,
		height: 56,
		borderRadius: '50%',
		position: 'absolute',
		bottom: '3%',
		right: '6%'
	},
	secondaryButton: {
		width: 56,
		height: 56,
		position: 'absolute',
		bottom: '3%',
		right: 'calc(6% + 80px)'
	},
	collapse: {
		width: '100%'
	},
	divider: {
		backgroundColor: theme.palette.text.main,
		marginBottom: 10,
		height: 1
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	},
	padding: {
		paddingLeft: 10,
		paddingRight: 10
	},
	info: {
		paddingLeft: 10,
		paddingRight: 10,
		marginBottom: 15
	},
	collapseTitle: {
		color: colors.collapseTitle
	},
	collapseTitleText: {
		color: colors.collapseTitle,
		paddingLeft: 10
	},
	searchMoreFilters: {
		color: colors.primary,
		textAlign: 'center',
		fontSize: 14,
		marginTop: 10
	},
	moreFilters: {
		color: colors.primary,
		textAlign: 'center',
		fontSize: 14,
		marginTop: 10,
		fontWeight: 'bold'
	},
	numberChip: {
		background: colors.selectedText100Percent,
		textAlign: 'center',
		width: 24,
		height: 24,
		borderRadius: 100
	},
	spanChip: {
		color: '#ffffff',
		fontSize: 14,
		lineHeight: '25px',
		fontWeight: 600
	},
	buttonLoupe: {
		width: 30,
		height: 30
	},
	icon: {
		height: 24,
		width: 24,
		fill: theme.palette.icon.main
	},
	collapseContainer: {
		cursor: 'pointer'
	},
	iconMoreFilters: {
		marginRight: 5
	},
	hiddenField: {
		padding: '0 !important',
		margin: '0 !important',
		height: '0 !important'
	}
})

const getNbChip = (formValues, param) => {
	return formValues && formValues[param] ? formValues[param].length : 0
}

const formatOptions = (valueList) => valueList && valueList.map((element) => {
	return { label: element.label, code: element.code }
})

const InternalRechercheDocumentForm = ({
	langues,
	zoneList,
	businessSegmentList,
	businessSubSegmentList,
	coreBusinessList,
	marketList,
	sectorList,
	bidBriefList,
	paysList,
	intl,
	lastSubmittedFieldsAdvance,
	setLastSubmittedFieldsAdvance,
	onSubmit,
	classes,
	isCorrespondant,
	contenuAValider,
	change,
	resetNbChipRequired,
	setResetNbChipRequired,
	formValues,
	rechercherAvancee,
	addField,
	typeContenu,
	winLoseList,
	initialValues,
	adminCorrespFilters,
	setAdminCorrespFilters,
	usersList,
	initialTypeContenu,
	initialFieldsAdmin,
	isLocationCollapsed,
	isClientsCollapsed,
	isActivitiesCollapsed,
	isDocumentCollapsed,
	isAdditionnalsCollapsed,
	isAdminCollapsed,
	updateRechercheStatusCollapse,
	setIsSearchLaunch,
	handleUpdate,
	isUpdateButtonDisplayed
}) => {
	const businessSubSegmentRef = React.useRef()
	const coreBusinessRef = React.useRef()
	const countryRef = React.useRef()
	const sectorRef = React.useRef()

	//Condition ouverture collapse
	// TODO : Transférer le tout dans un Array pour gérer plus simlplement
	const [location, setLocation] = React.useState(isLocationCollapsed? isLocationCollapsed : false)
	const [clients, setClients] = React.useState(isClientsCollapsed? isClientsCollapsed : false)
	const [activities, setActivities] = React.useState(isActivitiesCollapsed? isActivitiesCollapsed : false)
	const [document, setDocument] = React.useState(isDocumentCollapsed? isDocumentCollapsed : false)
	const [additionnals, setAdditionnals] = React.useState(isAdditionnalsCollapsed? isAdditionnalsCollapsed : false)
	const [admin, setAdmin] = React.useState(isAdminCollapsed? isAdminCollapsed : false)

	//M.A.J. collapse title (nombre de filtre dans le collapse)
	const [nbChipLocation, setNbChipLocation] = React.useState({
		location: getNbChip(formValues, ZONE) + getNbChip(formValues, PAYS),
		locPays: getNbChip(formValues, PAYS),
		locZone: getNbChip(formValues, ZONE)
	})
	const [nbChipClients, setNbChipClients] = React.useState({
		clients: getNbChip(formValues, MARKET) + getNbChip(formValues, SECTOR),
		cltMarket: getNbChip(formValues, MARKET),
		cltSector: getNbChip(formValues, SECTOR)
	})
	const [nbChipActivities, setNbChipActivities] = React.useState({
		activities: getNbChip(formValues, BUSINESS_SEGMENT) + getNbChip(formValues, BUSINESS_SUB_SEGMENT) + getNbChip(formValues, CORE_BUSINESS),
		actSegment: getNbChip(formValues, BUSINESS_SEGMENT),
		actSubSegment: getNbChip(formValues, BUSINESS_SUB_SEGMENT),
		actCore: getNbChip(formValues, CORE_BUSINESS)
	})
	const [nbChipAdditionalFilters, setNbChipAdditionalFilters] = React.useState(0)
	const [nbChipDocument, setNbChipDocument] = React.useState(
		{
			document: getNbChip(formValues, LANGUE) + getNbChip(formValues, BID_BRIEF) + getNbChip(formValues, TYPE_CONTENU_FIELD) + getNbChip(formValues, PROPOSAL_STATUS) + getNbChip(formValues, WITH_ATTACHMENT) + getNbChip(formValues, INTERNAL_EXTERNAL),
			docLang: getNbChip(formValues, LANGUE),
			docTypeContent: getNbChip(formValues, TYPE_CONTENU_FIELD),
			docBidBrief: getNbChip(formValues, BID_BRIEF),
			docProposalStatus: getNbChip(formValues, PROPOSAL_STATUS),
			docAttachment: getNbChip(formValues, WITH_ATTACHMENT),
			docInternalExt: getNbChip(formValues, INTERNAL_EXTERNAL)
		})

	//Condition ouvertures popins
	const [adminPopinState, setAdminPopinState] = React.useState({ filter: {}, open: false })
	const [additionalFiltersPopinState, setAdditionalFiltersPopinState] = React.useState({ open: false })

	const initAdminCorrespFilter = () => {
		if (initialValues && initialValues.fieldsAdmin) {
			// Initialisation du filtre STATUT
			const tabInitFilters = [
				...Object.entries(initialValues && initialValues.fieldsAdmin)
					.filter(([key, value]) => {
						return key !== FIELDS_ADMIN_CORRESP.DATE_PUBLICATION.name
					})
					.map(val => ({
						typeFilter: val[0], value: val[1].map(value => ({ code: value.code }))
					}))
			]
			/* const datePublicationFilter = Object.entries(initialValues && initialValues.fieldsAdmin)
				.filter(([key, value]) => key === FIELDS_ADMIN_CORRESP.DATE_PUBLICATION.name)

			if (datePublicationFilter.length > 0) {
				const formattedFilter = datePublicationFilter.map(val => ({ typeFilter: val[0], value: val[1] }))
				tabInitFilters.push(...formattedFilter)
			}*/
			return tabInitFilters
		}
		return []
	}

	React.useEffect(() => {
		setAdminCorrespFilters(initAdminCorrespFilter())
		if (initialTypeContenu && initialTypeContenu !==0) {
			setNbChipDocument(nbChipDocument => ({
				...nbChipDocument,
				document: 1,
				docTypeContent: 1
			}))
		}
	}, [])

	React.useEffect(() => {
		if (initialValues.zone) {
			setNbChipLocation(nbChipLocation => ({
				...nbChipLocation,
				location: 1,
				locZone: 1
			}))
		}
	}, [initialValues.zone])

	const removeAdminFilters = (typeFilterToDelete) => {
		setAdminCorrespFilters(adminCorrespFilters => ([
			...adminCorrespFilters.filter(filter => filter.typeFilter !== typeFilterToDelete)
		]))
	}

	const cleanIdConsulteField = () => {
		if (setIsSearchLaunch) {
			setIsSearchLaunch(false)
		}
		change('idConsulte', undefined)
	}

	const handleCloseAdminPopin = () => {
		cleanIdConsulteField()
		setAdminPopinState({ filter: {}, open: false })
	}

	const handleOpenAdminPopin = () => {
		setAdminPopinState({ ...adminPopinState, open: true })
	}

	const linkedSelected = {
		[BUSINESS_SEGMENT]: [businessSubSegmentRef, coreBusinessRef],
		[BUSINESS_SUB_SEGMENT]: [coreBusinessRef],
		[ZONE]: [countryRef],
		[MARKET]: [sectorRef]
	}

	const handleLinkedSelect = (event, value, name) => {
		let values = value.map(val => val.code)

		// parcours tous les champs dont la valeur depend du champ qui vient d'etre modifié
		//Si l'on supprime le contenu du multi select -> on clear les champs enfants de ce dernier
		if (!values.length || values[0] === 0) {
			linkedSelected[name].forEach((reference) => {
				change(reference.current.props.name, [])
			})
			return
		}

		// crée une regex qui recupère la nouvelle valeur et escape les $
		const regexList = values.map(value => new RegExp(`^${value.replace(/\$/, '\\$')}`))

		const checkValue = value => value && regexList.some(regex => (value && value.code) && value.code.match(regex))
		const newCountNbChipByField = linkedSelected[name].map((reference) => {
			const value = reference.current.value
			//si la valeur ne correspond pas, la remet en indefini
			if (value) {
				if (Array.isArray(value)) {
					const newValue = value.filter(checkValue)
					change(reference.current.props.name, newValue)
					return { name: reference.current.props.name, nbChip: newValue.length }
				} else if (!checkValue(value)) {
					change(reference.current.props.name, [])
					return { name: reference.current.props.name, nbChip: 0 }
				}
			}
			return undefined
		})
		return newCountNbChipByField.filter(res => !!res)
	}

	const formatAttachment = () => [
		{ label: intl.formatMessage({ id: 'enums.withAttachment.CERTIFICATE' }), code: SEARCH_FORM_WITH_ATTACHMENT_CODES.CERTIFICATE },
		{ label: intl.formatMessage({ id: 'enums.withAttachment.CASE_STUDY' }), code: SEARCH_FORM_WITH_ATTACHMENT_CODES.CASE_STUDY }
	]

	const formatInternalExternal = () => [
		{ label: intl.formatMessage({ id: 'enums.internalExternal.INTERNAL' }), code: SEARCH_FORM_INTERNE_EXTERNE_CODES.INTERNAL },
		{ label: intl.formatMessage({ id: 'enums.internalExternal.EXTERNAL' }), code: SEARCH_FORM_INTERNE_EXTERNE_CODES.EXTERNAL }
	]

	const getZones = () => Promise.resolve(formatOptions(zoneList))
	const getMarkets = () => Promise.resolve(formatOptions(marketList))
	const getSectors = () => Promise.resolve(formatOptions(sectorList))
	const getPays = () => Promise.resolve(formatOptions(paysList))
	const getBusinessSegment = () => Promise.resolve(formatOptions(businessSegmentList))
	const getBusinessSubSegment = () => Promise.resolve(formatOptions(businessSubSegmentList))
	const getCoreBusiness = () => Promise.resolve(formatOptions(coreBusinessList))
	const getBidBrief = () => Promise.resolve(formatOptions(bidBriefList))
	const getWinLoseUnspecified = () => Promise.resolve(formatOptions(winLoseList))
	const getWithAttachment = () => Promise.resolve(formatAttachment())
	const getInternalExternal = () => Promise.resolve(formatInternalExternal())

	const formatZonesForAutocomplete = () => formatOptions(zoneList)

	const formatLanguesForAutocomplete = () => langues && sortObjectByLabel(langues.map((langue) => {
		return { label: intl.formatMessage({ id: `enums.langue.${langue.codeLang}` }), code: langue.id }
	}))
	const getLangues = () => Promise.resolve(formatLanguesForAutocomplete())

	const formatTypesContenuForAutocomplete = () => sortObjectByLabel(Object.keys(TYPE_CONTENU).map((key) => {
		return { label: intl.formatMessage({ id: `enums.documentType.${key}` }), code: `${key}` }
	}))
	const getTypesContenu = () => Promise.resolve(formatTypesContenuForAutocomplete())

	const titleCollapse = (title, constante, idSectionCollapse, icon, nbChipParam) => {
		return (
			<Grid container alignItems="center" className={classnames(classes.padding, classes.collapseContainer)} onClick={() => updateRechercheCollapse(idSectionCollapse, !constante)}>
				<Grid item xs={1} className={classes.collapseTitle}> {icon} </Grid>
				<Grid item xs={8}> <Typography variant="h6" className={classes.collapseTitleText}><FormattedMessage id={title} /></Typography> </Grid>
				<Grid item xs={1}>
					{(!constante && nbChipParam && nbChipParam > 0)
						? <div className={classes.numberChip}><span className={classes.spanChip}> {nbChipParam}</span></div>
						: <></>
					}
				</Grid>
				<Grid item xs={2} className={classes.collapseTitleText}>
					<IconButton onClick={(e) => {
						updateRechercheCollapse(idSectionCollapse, !constante)
						e.stopPropagation()
					}}>
						<ExpandMoreIcon
							className={classnames(classes.expand, {
								[classes.expandOpen]: constante
							})}
						/>
					</IconButton>
				</Grid>
				<Grid item xs={12}>
					<Divider classes={{ root: classes.divider }} />
				</Grid>
			</Grid>
		)
	}

	const getNbChipInField = (newValues, fieldName) => {
		if (newValues) {
			return newValues.filter(option => option.name === fieldName)
				.map(option => option.nbChip)
		}
		return [0]
	}

	const removeField = React.useCallback((index) => {
		const newRecherche = [...((formValues && formValues.fieldsAdvance) || [])]
		newRecherche.splice(index, 1)
		rechercherAvancee(newRecherche)
	}, [formValues, rechercherAvancee])

	const updateFields = React.useCallback((index, field) => {
		const newRecherche = [...((formValues && formValues.fieldsAdvance) || [])]
		newRecherche.splice(index, 1, field)
		rechercherAvancee(newRecherche)
	}, [formValues, rechercherAvancee])

	const [modifyFilterIndex, setModifyFilterIndex] = React.useState(null)

	const resetIfAdminOnly = (param) => (isCorrespondant && param) ? param.length : 0

	React.useEffect(() => {
		if (resetNbChipRequired) {
			!isCorrespondant && setNbChipLocation({ location: 0, locPays: 0, locZone: 0 })
			setNbChipClients({ clients: resetIfAdminOnly(formValues.market), cltMarket: resetIfAdminOnly(formValues.market), cltSector: 0 })
			setNbChipActivities({ activities: resetIfAdminOnly(formValues.businessSegment), actSegment: resetIfAdminOnly(formValues.businessSegment), actSubSegment: 0, actCore: 0 })
			setNbChipAdditionalFilters(0)
			setNbChipDocument({
				document: 0, docLang: 0, docTypeContent: 0, docBidBrief: 0,
				docProposalStatus: 0, docAttachment: 0, docInternalExt: 0
			})
			setAdminCorrespFilters(initAdminCorrespFilter())
		}
		setResetNbChipRequired(false)
	}, [resetNbChipRequired])

	React.useEffect(() => {
		if (formValues) {
			setNbChipLocation({
				location: getNbChip(formValues, PAYS) + getNbChip(formValues, ZONE),
				locPays: getNbChip(formValues, PAYS),
				locZone: getNbChip(formValues, ZONE)
			})
			setNbChipClients({
				clients: getNbChip(formValues, MARKET) + getNbChip(formValues, SECTOR),
				cltMarket: getNbChip(formValues, MARKET),
				cltSector: getNbChip(formValues, SECTOR)
			})
			setNbChipActivities({
				activities: getNbChip(formValues, BUSINESS_SEGMENT) + getNbChip(formValues, BUSINESS_SUB_SEGMENT) + getNbChip(formValues, CORE_BUSINESS),
				actSegment: getNbChip(formValues, BUSINESS_SEGMENT),
				actSubSegment: getNbChip(formValues, BUSINESS_SUB_SEGMENT),
				actCore: getNbChip(formValues, CORE_BUSINESS)
			})
			setNbChipAdditionalFilters(formValues.fieldsAdvance ? formValues.fieldsAdvance.length : 0)

			setNbChipDocument({
				document: getNbChip(formValues, LANGUE) + getNbChip(formValues, TYPE_CONTENU_FIELD) + getNbChip(formValues, BID_BRIEF)
					+ getNbChip(formValues, PROPOSAL_STATUS) + getNbChip(formValues, WITH_ATTACHMENT) + getNbChip(formValues, INTERNAL_EXTERNAL),
				docLang: getNbChip(formValues, LANGUE),
				docTypeContent: getNbChip(formValues, TYPE_CONTENU_FIELD),
				docBidBrief: getNbChip(formValues, BID_BRIEF),
				docProposalStatus: getNbChip(formValues, PROPOSAL_STATUS),
				docAttachment: getNbChip(formValues, WITH_ATTACHMENT),
				docInternalExt: getNbChip(formValues, INTERNAL_EXTERNAL)
			})
		}
	}, [formValues])

	const updateRechercheCollapse = (idField, isCollapse) => {
		updateRechercheStatusCollapse(idField, isCollapse)
		switch (idField) {
			case `${RECHERCHE_FORM_COLLAPSED_ID.LOCATION}`:
				return  setLocation(location => !location)
			case `${RECHERCHE_FORM_COLLAPSED_ID.CLIENTS}`:
				return  setClients(clients => !clients)
			case `${RECHERCHE_FORM_COLLAPSED_ID.ACTIVITIES}`:
				return  setActivities(activities => !activities)
			case `${RECHERCHE_FORM_COLLAPSED_ID.DOCUMENT}`:
				return  setDocument(document => !document)
			case `${RECHERCHE_FORM_COLLAPSED_ID.ADDITIONNALS}`:
				return  setAdditionnals(additionnals => !additionnals)
			case `${RECHERCHE_FORM_COLLAPSED_ID.ADMIN}`:
				return  setAdmin(admin => !admin)
			default:
		}
	}

	return (<form onSubmit={onSubmit}>
			<Field
				component={Input}
				name={QUERY}
				placeholder={intl.formatMessage({ id: 'document.search.queryPlaceholder' })}
				autoComplete="off"
			/>
			<div className={classes.hiddenField}>
				<Field
					name={PAGE_NO}
					component={Input}
					type="hidden"
					containerClassName={classes.hiddenField}
					InputProps={{ disableUnderline: true }}
				/>
				<Field
					name={ID_CONSULTE}
					component={Input}
					type="hidden"
					containerClassName={classes.hiddenField}
					InputProps={{ disableUnderline: true }}
				/>
			</div>
			{titleCollapse('document.search.collapse.location', location, RECHERCHE_FORM_COLLAPSED_ID.LOCATION, <PlaceIcon />, nbChipLocation.location)}
			<Collapse in={location} timeout="auto" className={classes.collapse}>
				<Field
					component={AutocompleteMulti}
					name={ZONE}
					label={<FormattedMessage id="document.search.zoneLabel" />}
					disabled={isCorrespondant && contenuAValider}
					getOptions={getZones}
					staticOptions={formatZonesForAutocomplete()}
					forceGetOptions
					onChange={(event, value) => {
						//Ici on mets à jour les autocompletes enfants de ZONE
						//Et on M.A.J. le nombre de chip pour l'afficher dans le titre du collapse
						cleanIdConsulteField()
						const newValues = handleLinkedSelect(event, value, ZONE)
						let nbChipChildPays = getNbChipInField(newValues, PAYS)
						setNbChipLocation({
							location: nbChipChildPays.length > 0 ? nbChipChildPays[0] + value.length : nbChipLocation.locPays + value.length,
							locZone: value.length,
							locPays: nbChipChildPays.length > 0 ? nbChipChildPays[0] : nbChipLocation.locPays
						})
					}}
				/>
				<Field
					component={AutocompleteMulti}
					ref={countryRef}
					name={PAYS}
					label={<FormattedMessage id="document.search.paysLabel" />}
					disabled={isCorrespondant && contenuAValider}
					getOptions={getPays}
					forceGetOptions
					onChange={(event, value) => {
						cleanIdConsulteField()
						setNbChipLocation(nbChipLocation => ({
							...nbChipLocation,
							location: nbChipLocation.locZone + value.length,
							locPays: value.length
						}))
					}}
				/>
			</Collapse>

			{titleCollapse('document.search.collapse.clients', clients, RECHERCHE_FORM_COLLAPSED_ID.CLIENTS, <LocationCityIcon />, nbChipClients.clients)}
			<Collapse in={clients} timeout="auto" className={classes.collapse}>
				<Field
					component={AutocompleteMulti}
					name={MARKET}
					label={<FormattedMessage id="document.search.marketLabel" />}
					disabled={isCorrespondant && contenuAValider}
					getOptions={getMarkets}
					forceGetOptions
					onChange={(event, value) => {
						//Ici on mets à jour les autocompletes enfants de MARKET
						//Et on M.A.J. le nombre de chip pour l'afficher dans le titre du collapse
						cleanIdConsulteField()
						const newValues = handleLinkedSelect(event, value, MARKET)
						let nbChipChildSector = getNbChipInField(newValues, SECTOR)
						setNbChipClients({
							clients: nbChipChildSector.length > 0 ? nbChipChildSector[0] + value.length : nbChipClients.cltSector + value.length,
							cltMarket: value.length,
							cltSector: nbChipChildSector.length > 0 ? nbChipChildSector[0] : nbChipClients.cltSector
						})
					}}
				/>
				<Field
					component={AutocompleteMulti}
					ref={sectorRef}
					name={SECTOR}
					label={<FormattedMessage id="document.search.sectorLabel" />}
					getOptions={getSectors}
					forceGetOptions
					onChange={(event, value) => {
						cleanIdConsulteField()
						setNbChipClients(nbChipClients => ({
							...nbChipClients,
							clients: nbChipClients.cltMarket + value.length,
							cltSector: value.length
						}))
					}}
				/>
			</Collapse>

			{titleCollapse('document.search.collapse.activities', activities, RECHERCHE_FORM_COLLAPSED_ID.ACTIVITIES, <SvgIcon path={SVG_ICONS.CHART_DONUT} className={classes.icon} />, nbChipActivities.activities)}
			<Collapse in={activities} timeout="auto" className={classes.collapse}>
				<Field
					component={AutocompleteMulti}
					name={BUSINESS_SEGMENT}
					disabled={isCorrespondant && contenuAValider}
					label={<FormattedMessage id="document.search.businessSegmentLabel" />}
					getOptions={getBusinessSegment}
					forceGetOptions
					onChange={(event, value) => {
						//Ici on mets à jour les autocompletes enfants de BUSINESS_SEGMENT
						//Et on M.A.J. le nombre de chip pour l'afficher dans le titre du collapse
						cleanIdConsulteField()
						const newValues = handleLinkedSelect(event, value, BUSINESS_SEGMENT)
						const nbChipChildSubSegment = getNbChipInField(newValues, BUSINESS_SUB_SEGMENT)
						const nbChipChildCoreBusiness = getNbChipInField(newValues, CORE_BUSINESS)

						let activitiesTotalChip
						if (nbChipChildSubSegment.length > 0) {
							activitiesTotalChip = nbChipChildCoreBusiness.length > 0
								? nbChipChildCoreBusiness[0] + nbChipChildSubSegment[0]
								: nbChipActivities.actCore + nbChipChildSubSegment[0]
						} else {
							activitiesTotalChip = nbChipChildCoreBusiness.length > 0
								? nbChipChildCoreBusiness[0] + nbChipActivities.actSubSegment
								: nbChipActivities.actCore + nbChipActivities.actSubSegment
						}

						setNbChipActivities({
							activities: activitiesTotalChip + value.length,
							actSegment: value.length,
							actSubSegment: nbChipChildSubSegment.length > 0 ? nbChipChildSubSegment[0] : nbChipActivities.actSubSegment,
							actCore: nbChipChildCoreBusiness.length > 0 ? nbChipChildCoreBusiness[0] : nbChipActivities.actCore
						})
					}}
				/>
				<Field
					component={AutocompleteMulti}
					ref={businessSubSegmentRef}
					name={BUSINESS_SUB_SEGMENT}
					label={<FormattedMessage id="document.search.businessSubSegmentLabel" />}
					getOptions={getBusinessSubSegment}
					forceGetOptions
					onChange={(event, value) => {
						//Ici on mets à jour les autocompletes enfants de BUSINESS_SUB_SEGMENT
						//Et on M.A.J. le nombre de chip pour l'afficher dans le titre du collapse
						cleanIdConsulteField()
						const newValues = handleLinkedSelect(event, value, BUSINESS_SUB_SEGMENT)
						const nbChipChildCoreBusiness = getNbChipInField(newValues, CORE_BUSINESS)
						setNbChipActivities(nbChipActivities => ({
							...nbChipActivities,
							activities: nbChipChildCoreBusiness.length > 0
								? nbChipChildCoreBusiness[0] + value.length + nbChipActivities.actSegment
								: nbChipActivities.actCore + value.length + nbChipActivities.actSegment,
							actSubSegment: value.length,
							actCore: nbChipChildCoreBusiness.length > 0 ? nbChipChildCoreBusiness[0] : nbChipActivities.actCore
						}))
					}}
				/>
				<Field
					component={AutocompleteMulti}
					ref={coreBusinessRef}
					name={CORE_BUSINESS}
					label={<FormattedMessage id="document.search.coreBusinessLabel" />}
					getOptions={getCoreBusiness}
					forceGetOptions
					onChange={(event, value) => {
						cleanIdConsulteField()
						setNbChipActivities(nbChipActivities => ({
							...nbChipActivities,
							activities: nbChipActivities.actSegment + nbChipActivities.actSubSegment + value.length,
							actCore: value.length
						}))
					}}
				/>
			</Collapse>

			{titleCollapse('document.search.collapse.document', document, RECHERCHE_FORM_COLLAPSED_ID.DOCUMENT, <DescriptionIcon />, nbChipDocument.document)}
			<Collapse in={document} timeout="auto" className={classes.collapse}>
				<Field
					component={AutocompleteMulti}
					name={LANGUE}
					label={<FormattedMessage id="document.search.langueLabel" />}
					getOptions={getLangues}
					forceGetOptions
					onChange={(event, value) => {
						cleanIdConsulteField()
						setNbChipDocument(nbChipDocument => ({
							...nbChipDocument,
							document: value.length + nbChipDocument.docTypeContent + nbChipDocument.docBidBrief + nbChipDocument.docProposalStatus
								+ nbChipDocument.docAttachment + nbChipDocument.docInternalExt,
							docLang: value.length
						}))
					}}
				/>
				<Field
					name={TYPE_CONTENU_FIELD}
					component={AutocompleteMulti}
					label={<FormattedMessage id="document.search.typeContenuLabel" />}
					getOptions={getTypesContenu}
					staticOptions={formatTypesContenuForAutocomplete()}
					forceGetOptions
					onChange={(event, value) => {
						cleanIdConsulteField()
						setNbChipDocument(nbChipDocument => ({
							...nbChipDocument,
							document: nbChipDocument.docLang + value.length,
							docTypeContent: value.length,
							docBidBrief: 0,
							docProposalStatus: 0,
							docAttachment: 0,
							docInternalExt: 0
						}))
						change(BID_BRIEF, [])
						change(PROPOSAL_STATUS, [])
						change(WITH_ATTACHMENT, [])
						change(INTERNAL_EXTERNAL, [])
					}}
				/>

				{(typeContenu && typeContenu.length === 1 && typeContenu[0].code === TYPE_CONTENU.SAVOIR_FAIRE) &&
				<Field
					component={AutocompleteMulti}
					name={BID_BRIEF}
					label={<FormattedMessage id="document.search.bidBriefLabel" />}
					getOptions={getBidBrief}
					forceGetOptions
					onChange={(event, value) => {
						cleanIdConsulteField()
						setNbChipDocument(nbChipDocument => ({
							...nbChipDocument,
							document: nbChipDocument.docLang + nbChipDocument.docTypeContent + value.length,
							docBidBrief: value.length
						}))
					}}
				/>
				}

				{(typeContenu && typeContenu.length === 1 && typeContenu[0].code === TYPE_CONTENU.PROPOSITION_COMMERCIALE) &&
				<Field
					component={AutocompleteMulti}
					name={PROPOSAL_STATUS}
					label={<FormattedMessage id="document.search.proposalStatusLabel" />}
					getOptions={getWinLoseUnspecified}
					forceGetOptions
					onChange={(event, value) => {
						cleanIdConsulteField()
						setNbChipDocument(nbChipDocument => ({
							...nbChipDocument,
							document: nbChipDocument.docLang + nbChipDocument.docTypeContent + value.length,
							docProposalStatus: value.length
						}))
					}}
				/>
				}

				{(typeContenu && typeContenu.length === 1 && typeContenu[0].code === TYPE_CONTENU.REFERENCE) &&
				<Grid>
					<Field
						component={AutocompleteMulti}
						name={WITH_ATTACHMENT}
						label={<FormattedMessage id="document.search.withAttachmentLabel" />}
						getOptions={getWithAttachment}
						forceGetOptions
						onChange={(event, value) => {
							cleanIdConsulteField()
							setNbChipDocument(nbChipDocument => ({
								...nbChipDocument,
								document: nbChipDocument.docLang + nbChipDocument.docTypeContent + value.length + nbChipDocument.docInternalExt,
								docAttachment: value.length
							}))
						}}
					/>
					<Field
						component={AutocompleteMulti}
						name={INTERNAL_EXTERNAL}
						label={<FormattedMessage id="document.search.internalExternalLabel" />}
						getOptions={getInternalExternal}
						forceGetOptions
						onChange={(event, value) => {
							cleanIdConsulteField()
							setNbChipDocument(nbChipDocument => ({
								...nbChipDocument,
								document: nbChipDocument.docLang + nbChipDocument.docTypeContent + nbChipDocument.docAttachment + value.length,
								docInternalExt: value.length
							}))
						}}
					/>
				</Grid>
				}
			</Collapse>

			{titleCollapse('document.search.collapse.additional', additionnals, RECHERCHE_FORM_COLLAPSED_ID.ADDITIONNALS, <SvgIcon path={SVG_ICONS.FILTER_OUTLINED} className={classes.icon} />, nbChipAdditionalFilters)}
			<Collapse in={additionnals} timeout="auto" className={classes.collapse}>
				<Grid container style={{ padding: '0px 10px 10px 10px' }}>
					{formValues && formValues.fieldsAdvance &&
					<AdditionalFiltersApplied rechercheAvancee={formValues.fieldsAdvance} additionalFiltersPopinState={additionalFiltersPopinState} setAdditionalFiltersPopinState={setAdditionalFiltersPopinState} removeField={removeField}
					                          modifyFilterIndex={modifyFilterIndex} setModifyFilterIndex={setModifyFilterIndex} />}
					<Grid
						container
						justify="center"
						style={{ cursor: 'pointer' }}
						onClick={() => setAdditionalFiltersPopinState({ ...additionalFiltersPopinState, open: true })}
					>
						<Typography variant="body1" className={classes.searchMoreFilters} component="span"><FormattedMessage id="document.search.additionalInfos" /></Typography>
						<Grid container alignItems="flex-end" justify="center">
							<AddCircleIcon color="primary" className={classes.iconMoreFilters} />
							<Typography variant="body1" style={{ marginTop: 0 }} className={classes.moreFilters} component="span"><FormattedMessage id="document.search.additionalAddInfos" /></Typography>
						</Grid>
					</Grid>
				</Grid>
			</Collapse>
			<AdditionalFiltersPopin
				onSubmit={() => {
					cleanIdConsulteField()
					setAdditionalFiltersPopinState({ initialValues: {}, open: false })
					setModifyFilterIndex(null)
					setLastSubmittedFieldsAdvance(formValues.fieldsAdvance || [])
					onSubmit()
				}}
				onClose={() => {
					cleanIdConsulteField()
					setAdditionalFiltersPopinState({ initialValues: {}, open: false })
					setModifyFilterIndex(null)
					rechercherAvancee(lastSubmittedFieldsAdvance)
				}}
				open={additionalFiltersPopinState.open}
				formValues={formValues}
				rechercherAvancee={rechercherAvancee}
				addField={addField}
				updateField={updateFields}
				removeField={removeField}
				modifyFilterIndex={modifyFilterIndex}
				setModifyFilterIndex={setModifyFilterIndex}
				initValues={additionalFiltersPopinState.initialValues}
			/>
			<AccessChecker access={[PROFILS.ADMIN, PROFILS.CORRESPONDANT]}>
				<FormSection name="fieldsAdmin">
					{titleCollapse(isCorrespondant ? 'document.search.correspondant.title' : 'document.search.collapse.admin', admin, RECHERCHE_FORM_COLLAPSED_ID.ADMIN, <SvgIcon path={SVG_ICONS.FILTER_FULL} className={classes.icon} />, adminCorrespFilters.length)}
					<Collapse in={admin} timeout="auto" className={classes.collapse}>
						<Grid container style={{ padding: '0px 10px 10px 10px', cursor: 'pointer' }}>
							<Grid item xs={12}>
								{adminCorrespFilters && adminCorrespFilters.length > 0 &&
								<AdminFiltersApplied
									initialValues={initialValues}
									adminCorrespFilters={adminCorrespFilters}
									setAdminPopinState={setAdminPopinState}
									usersList={usersList.filter(user => user.preferences.IS_ALIVE !== '0')}
									removeAdminFilters={removeAdminFilters}
									change={change}
								/>
								}
								<Grid container onClick={handleOpenAdminPopin} alignItems="flex-end" justify="center" style={{ marginTop: 5 }}>
									<AddCircleIcon color="primary" className={classes.iconMoreFilters} />
									<Typography variant="body1" style={{ marginTop: 0 }} className={classes.moreFilters} component="span">
										<FormattedMessage id={isCorrespondant
											? 'document.search.correspondantInfos'
											: 'document.search.adminInfos'
										} />
									</Typography>
								</Grid>

								<AdminCorrespondantFiltersPopin
									onSubmit={() => {
										cleanIdConsulteField()
										handleCloseAdminPopin()
										onSubmit()
									}}
									adminPopinState={adminPopinState}
									onClose={handleCloseAdminPopin}
									isCorrespondant={isCorrespondant}
									initValues={initialValues.fieldsAdmin}
									usersList={usersList}
									change={change}
									adminCorrespFilters={adminCorrespFilters}
									setAdminCorrespFilter={setAdminCorrespFilters}
									removeAdminFilters={removeAdminFilters}
								/>
							</Grid>
						</Grid>
					</Collapse>
				</FormSection>
			</AccessChecker>
			<Actions>
				{isUpdateButtonDisplayed && <Button variant="contained" className={classes.secondaryButton} onClick={handleUpdate}>
					<Typography><FormattedMessage id="global.buttons.update" /></Typography>
				</Button>}
				<Fab key="rechercher" color="primary" variant="contained" className={classes.button} type="submit">
					<SearchIcon className={classes.buttonLoupe} />
				</Fab>
			</Actions>
		</form>
	)
}

InternalRechercheDocumentForm.propTypes = {
	langues: PropTypes.array,
	zoneList: PropTypes.array,
	businessSegmentList: PropTypes.array,
	businessSubSegmentList: PropTypes.array,
	coreBusinessList: PropTypes.array,
	marketList: PropTypes.array,
	sectorList: PropTypes.array,
	bidBriefList: PropTypes.array,
	paysList: PropTypes.array,
	usersList: PropTypes.array,
	intl: PropTypes.object,
	onSubmit: PropTypes.func,
	classes: PropTypes.object,
	isCorrespondant: PropTypes.bool,
	contenuAValider: PropTypes.bool,
	clearSearchAdvance: PropTypes.func,
	isLocationCollapsed: PropTypes.bool,
	isClientsCollapsed: PropTypes.bool,
	isActivitiesCollapsed: PropTypes.bool,
	isDocumentCollapsed: PropTypes.bool,
	isAdditionnalsCollapsed: PropTypes.bool,
	isAdminCollapsed: PropTypes.bool,
	isUpdateButtonDisplayed: PropTypes.bool,
	updateRechercheStatusCollapse: PropTypes.func,
	setIsSearchLaunch:  PropTypes.func,
	handleUpdate: PropTypes.func
}

const mapStateToProps = (state) => ({
	isLocationCollapsed: documentSelector.isCollapsedRecherche(state, RECHERCHE_FORM_COLLAPSED_ID.LOCATION),
	isClientsCollapsed: documentSelector.isCollapsedRecherche(state, RECHERCHE_FORM_COLLAPSED_ID.CLIENTS),
	isActivitiesCollapsed: documentSelector.isCollapsedRecherche(state, RECHERCHE_FORM_COLLAPSED_ID.ACTIVITIES),
	isDocumentCollapsed: documentSelector.isCollapsedRecherche(state, RECHERCHE_FORM_COLLAPSED_ID.DOCUMENT),
	isAdditionnalsCollapsed: documentSelector.isCollapsedRecherche(state, RECHERCHE_FORM_COLLAPSED_ID.ADDITIONNALS),
	isAdminCollapsed: documentSelector.isCollapsedRecherche(state, RECHERCHE_FORM_COLLAPSED_ID.ADMIN)
})

const actions = {
	updateRechercheStatusCollapse
}

const RechercheDocumentForm = compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(InternalRechercheDocumentForm)

export default RechercheDocumentForm
