import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl'
import Popin from '../../../../../../components/Popin'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import Typography from '@material-ui/core/Typography'
import { FormSwitch, getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import Select from '../../../../../../components/form/Select'
import Input from '../../../../../../components/form/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '../../../../../../components/form/Checkbox'
import { ATTRIBUTE_DATA_MO_SECTION, FIELD_TYPE, SECTION_TYPE } from '../../../../../fo/document/utils/documentConstants'
import { getUserLanguage } from '../../../../../fo/user/services/userSelectors'
import { DEFAULT_LANGUAGE_ID, GlOBAL_LANGUAGE_ID, TYPE_CONTENU } from '../../../../../../utils/constants'
import { editSection, newSection } from '../../../services/parametrageActions'
import { getLangues } from 'isotope-client/modules/langue/services/langueSelectors'
import { getModelByType } from '../../../../../common/model/modelSelectors'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { getSectionTitle } from '../../../../../fo/document/utils/documentUtils'

const styles = () => getStyles({
	popinResize: {
		maxWidth: 900
	},
	buttonAction: {
		marginRight: 50
	},
	traduction: {
		marginBottom: 10,
		marginTop: 20
	},
	sectionItem: {
		whiteSpace: 'pre'
	},
	switch: {
		paddingLeft: 10
	},
	error: {
		paddingLeft: 10,
		marginBottom: 20
	}
})

const NULL_ID = '-1'

const validate = (values, { first, section, langues }) => {
	let errors = {}

	if (!values.parent) {
		errors.parent = { id: 'global.errors.obligatoire' }
	}
	Object.values(langues).filter(lang => lang.id !== GlOBAL_LANGUAGE_ID).forEach(langue => {
		if (!values.name || !values.name[langue.codeLang]) {
			errors.name = {...errors.name}
			errors.name[langue.codeLang] = { id: 'global.errors.obligatoire' }
		}
	})

	if (!values.type) {
		errors.type = { id: 'global.errors.obligatoire' }
	}
	if (!values.after && !first && section.sections.length !== 0) {
		errors.after = { id: 'global.errors.obligatoire' }
	}
	return errors
}

const SectionParametragePopin = ({
	open,
	onClose,
	onSubmit,
	classes,
	initParentSection,
	sectionPath,
	first,
	visibility,
	idLanguage,
	handleSubmit,
	editedSection,
	type,
	isEdition,
	model,
	isTypeFI,
	isTypeRubrique,
	snackSuccess,
	snackError,
	newSection,
	editSection,
	langues,
	intl,
	sectionsList,
	change,
	pristine
}) => {
	const [parentSection, setParentSection] = React.useState({ ...initParentSection, path: sectionPath })
	const [error, setError] = React.useState(undefined)
	const isTopNiveau = (parentSection.level || 0) + 1 === 1

	React.useEffect(() => {
		if (!pristine) {
			change('first', parentSection.sections.length === 0 || isTypeFI)
			const lastSection = parentSection.sections[parentSection.sections.length - 1] ? parentSection.sections[parentSection.sections.length - 1].id : NULL_ID
			change('after', lastSection)
		}
	}, [parentSection])

	const handleParentChange = (e) => {
		const newParent = e.target.value
		const newParentSection = Object.values(sectionsList).find(section => section.id === newParent)
		setParentSection(newParentSection !== undefined ? newParentSection : { ...model, path: [] })
	}

	const submit = (values) => {
		if (!editedSection) {
			return newSection(type, values, parentSection.path)
				.then(() => {
					setError(undefined)
					onClose()
					snackSuccess({ id: 'parametrage.section.createsuccess' })
				})
				.catch(e => {
					if (e.bodyError && e.bodyError.id) {
						setError(e.bodyError)
					} else {
						snackError()
					}
				})
		}
		return editSection(type, {
			...values,
			id: editedSection.id
		}, parentSection.path, editedSection, sectionPath)
			.then(() => {
				setError(undefined)
				onClose()
				snackSuccess({ id: 'parametrage.section.editsuccess' })
			})
			.catch(e => {
				if (e.bodyError && e.bodyError.id) {
					setError(e.bodyError)
				} else {
					snackError()
				}
			})

	}

	const actions =
		<Grid container justify="center">
			<Button
				key="annuler"
				className={classes.buttonAction}
				color="inherit"
				variant="outlined"
				onClick={onClose}
			>
				<FormattedMessage id="global.buttons.annuler" />
			</Button>
			<Button
				key="enregistrer"
				color="primary"
				variant="contained"
				type="submit"
				onClick={handleSubmit(submit)}
				disabled={pristine}
			>
				<FormattedMessage id="global.buttons.save" />
			</Button>
		</Grid>


	return <Popin
		dialogClasses={{ paper: classes.popinResize }}
		open={open}
		actions={actions}
		onClose={onClose}
		title={
			<Typography variant="inherit">
				<FormattedMessage
					id={`parametrage.typeContenu.${type}`} /> : {editedSection ? getSectionTitle(editedSection, idLanguage) :
				<FormattedMessage id="parametrage.section.form.title" />}
			</Typography>
		}
		content={<>
			{error && <Typography color="error" className={classes.error}><FormattedHTMLMessage {...error} /></Typography>}
			<form onSubmit={onSubmit}>
				<Field
					name="parent"
					component={Select}
					label={<FormattedMessage id="parametrage.section.form.parent" />}
					onChange={handleParentChange}
					disabled={isTopNiveau || isEdition}
				>
					{isTopNiveau && <MenuItem key="PARENT-new" value={NULL_ID}>
						<FormattedMessage id={`parametrage.section.form.new`} />
					</MenuItem>}
					{Object.values(sectionsList).filter(section => section.path[0] === sectionPath[0]).map((section, index) => <MenuItem
						key={index}
						value={section.id}
						className={classes.sectionItem}
						disabled={section.type === SECTION_TYPE.DOCUMENT || section.type === SECTION_TYPE.FI || (isTypeRubrique && !!section.level) || (editedSection && (section.path).includes(editedSection.id))}
					>
						{section.label}
					</MenuItem>)}
				</Field>
				<Field
					name="name.en"
					component={Input}
					label={<FormattedMessage id="parametrage.section.form.name.en" />}
				/>
				<Grid container>
					<Grid item xs={6}>
						<Field
							name="type"
							component={Select}
							label={<FormattedMessage id="parametrage.section.form.type" />}
							disabled={isEdition && (isTypeFI || editedSection.type === SECTION_TYPE.RUBRIQUE)}
						>
							{isTopNiveau && parentSection.sections.filter(section => !editedSection || section.id !== editedSection.id).every(section => section.type !== SECTION_TYPE.FI) &&
							<MenuItem key={`TYPE-${SECTION_TYPE.FI}`} value={SECTION_TYPE.FI}>
								<FormattedMessage id={`parametrage.section.type.${SECTION_TYPE.FI}`} />
							</MenuItem>}
							{isTopNiveau && type !== TYPE_CONTENU.PROPOSITION_COMMERCIALE &&
							<MenuItem key={`TYPE-${SECTION_TYPE.RUBRIQUE}`} value={SECTION_TYPE.RUBRIQUE}>
								<FormattedMessage id={`parametrage.section.type.${SECTION_TYPE.RUBRIQUE}`} />
							</MenuItem>}
							{type !== TYPE_CONTENU.PROPOSITION_COMMERCIALE && <MenuItem key={`TYPE-${SECTION_TYPE.STANDARD}`} value={SECTION_TYPE.STANDARD}>
								<FormattedMessage id={`parametrage.section.type.${SECTION_TYPE.STANDARD}`} />
							</MenuItem>}
							{(!editedSection || (editedSection.sections.length === 0 && (editedSection.fields.length === 0 || editedSection.fields.every(field => field.type === FIELD_TYPE.FILE)))) &&
							<MenuItem key={`TYPE-${SECTION_TYPE.DOCUMENT}`} value={SECTION_TYPE.DOCUMENT}>
								<FormattedMessage id={`parametrage.section.type.${SECTION_TYPE.DOCUMENT}`} />
							</MenuItem>}
						</Field>
					</Grid>
					{editedSection && editedSection.type === SECTION_TYPE.RUBRIQUE && <Grid item xs={6}>
						<Field
							name="titleCode"
							component={Input}
							label={<FormattedMessage id="parametrage.section.form.titleCode" />}
						/>
					</Grid>}
				</Grid>
				<Grid container>
					<Grid item xs={6}>
						<Field
							name="after"
							component={Select}
							label={<FormattedMessage id="parametrage.section.form.after" />}
							disabled={first || parentSection.sections.length === 0}
						>
							{parentSection.sections.filter(section => !editedSection || section.id !== editedSection.id).map(section =>
								<MenuItem
									key={`AFTER-${section.id}`}
									value={section.id}>
									{getSectionTitle(section, idLanguage)}
								</MenuItem>
							)}
						</Field>
					</Grid>
					<Grid item xs={6}>
						<Field
							name="first"
							component={Checkbox}
							label={intl.formatMessage({ id: 'parametrage.section.form.first' })}
							disabled={parentSection.sections.filter(section => !editedSection || section.id !== editedSection.id).length === 0 || isTypeFI || isTopNiveau}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={6}>
						<Field
							name="visibility"
							component={Checkbox}
							label={intl.formatMessage({ id: 'parametrage.section.form.visibility' })}
							disabled={isTypeFI}
						/>
					</Grid>
				</Grid>

				{type === TYPE_CONTENU.REFERENCE &&
				<Grid container>
					<Grid item xs={6}>
						<Field
							name="sectionRegroupement"
							component={Checkbox}
							label={intl.formatMessage({ id: 'parametrage.section.form.sectionRegroupement' })}
						/>
					</Grid>
				</Grid>
				}

				{visibility && <Grid container>
					<Grid item xs={6}>
						<Field
							name="reference"
							component={Input}
							label={<FormattedMessage id="parametrage.section.form.reference" />}
						/>
					</Grid>
					<Grid item xs={6}>
						<Field
							name="code"
							component={Input}
							label={<FormattedMessage id="parametrage.section.form.code" />}
						/>
					</Grid>
				</Grid>}
				<Grid item xs={12} className={classes.switch}>
					<Field
						name="published"
						label={`${intl.formatMessage({ id: 'parametrage.section.form.publier' })}`}
						component={FormSwitch}
						color="primary"
						disabled={isTypeFI}
					/>
				</Grid>
				<Typography variant="h4" align="center" className={classes.traduction}><FormattedMessage
					id="parametrage.section.form.traduction" /></Typography>
				{Object.keys(langues).filter(key => langues[key].id !== DEFAULT_LANGUAGE_ID && langues[key].id !== GlOBAL_LANGUAGE_ID).map(key => <Field
					key={key}
					name={`name.${langues[key].codeLang}`}
					component={Input}
					label={<FormattedMessage id={`parametrage.section.form.name.${langues[key].codeLang}`} />}
				/>)}
			</form>
		</>}
	/>
}

SectionParametragePopin.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	classes: PropTypes.object,
	parentSection: PropTypes.object,
	sectionPath: PropTypes.array,
	first: PropTypes.bool,
	visibility: PropTypes.bool,
	idLanguage: PropTypes.string,
	handleSubmit: PropTypes.func,
	editedSection: PropTypes.object,
	type: PropTypes.string,
	model: PropTypes.object,
	isTypeFI: PropTypes.bool,
	isTypeRubrique: PropTypes.bool,
	isEdition: PropTypes.bool,
	snackSuccess: PropTypes.func,
	snackError: PropTypes.func,
	newSection: PropTypes.func,
	editSection: PropTypes.func,
	langues: PropTypes.array
}

const FORM_NAME = 'SECTION_FORM'
const formSelector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, { initParentSection, editedSection, type }) => {
	const langues = getLangues(state)

	// Liste des noms de la section dans chaque langue
	const name = {}
	editedSection && Object.keys(langues).map(key => name[langues[key].codeLang] = getSectionTitle(editedSection, langues[key].id))

	// Affichage de la section par défaut dans le champ "S'affiche après la section"
	// Nouvelle section -> on affiche la dernière section, sinon aucune
	// Edition de section -> on affiche la section précédente, sinon la dernière
	const lastSection = initParentSection.sections[initParentSection.sections.length - 1] ? initParentSection.sections[initParentSection.sections.length - 1].id : NULL_ID
	let afterSection = lastSection
	if (editedSection) {
		const previousSection = initParentSection.sections.find(section => section.position === editedSection.position - 1)
		afterSection = previousSection ? previousSection.id : lastSection
	}

	let visibilityParams = {}
	let titleCode
	if (editedSection && editedSection.attributeData[GlOBAL_LANGUAGE_ID]) {
		const globalData = editedSection.attributeData[GlOBAL_LANGUAGE_ID]
		if (globalData[ATTRIBUTE_DATA_MO_SECTION.VISIBILITY_REF]) {
			visibilityParams.reference = globalData[ATTRIBUTE_DATA_MO_SECTION.VISIBILITY_REF].value
		}
		if (globalData[ATTRIBUTE_DATA_MO_SECTION.VISIBILITY_VALUE]) {
			visibilityParams.code = globalData[ATTRIBUTE_DATA_MO_SECTION.VISIBILITY_VALUE].value
		}
		titleCode = globalData[ATTRIBUTE_DATA_MO_SECTION.TITLE_CODE] ? globalData[ATTRIBUTE_DATA_MO_SECTION.TITLE_CODE].value : ''
	}

	return {
		parent: formSelector(state, 'parent') || NULL_ID,
		first: formSelector(state, 'first') || false,
		visibility: formSelector(state, 'visibility') || false,
		idLanguage: getUserLanguage(state),
		model: getModelByType(state, type),
		langues: getLangues(state),
		isTypeFI: formSelector(state, 'type') === SECTION_TYPE.FI,
		isTypeRubrique: formSelector(state, 'type') === SECTION_TYPE.RUBRIQUE,
		isEdition: !!editedSection,
		initialValues: {
			parent: initParentSection.level ? initParentSection.id : NULL_ID,
			name: name,
			type: editedSection ? editedSection.type : '',
			after: afterSection,
			first: (editedSection && editedSection.position <= 1) || initParentSection.sections.length === 0,
			visibility: !!visibilityParams && Object.keys(visibilityParams).length > 0,
			published: editedSection ? editedSection.published : true,
			sectionRegroupement: editedSection ? editedSection.sectionRegroupement : false,
			titleCode,
			...visibilityParams
		}
	}
}

const actions = {
	newSection,
	editSection
}

export default compose(
	injectIntl,
	connect(mapStateToProps, actions),
	reduxForm({
		form: FORM_NAME,
		validate
	}),
	injectSnackActions,
	withStyles(styles)
)(SectionParametragePopin)
