import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import { getStyles } from 'isotope-client'

const styles = theme => getStyles({
	hrOrange: {
		width: '100%',
		height: 8,
		background: `linear-gradient(to right, ${theme.palette.secondary.main}, white)`,
		boxShadow: 'none',
		borderRadius: 20,
		margin: '10px 0'
	}
})

const GradientSeparateur = ({ classes }) => (
	<Paper className={classes.hrOrange} />
)

GradientSeparateur.propTypes = {
	classes: PropTypes.object
}

export default withStyles(styles)(GradientSeparateur)
