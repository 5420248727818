import * as actions from './conversionActions'

// On reduce la list par locale et uniteSi
export const conversion = (state = null , action = {}) => {
	switch (action.type) {
		case actions.GET_CONVERSION_LIST_SUCCESS:
			return action.conversionList.reduce((acc, current) => ({
				...acc,
				[current.locale]: {
					...(acc[current.locale] || {}),
					[current.uniteSi]: current
				}
			}), {})
		default:
			return state
	}
}
