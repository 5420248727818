import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import React from 'react'
import {compose} from 'recompose'
import {getStyles} from 'isotope-client'
import {FormattedMessage} from 'react-intl'
import classnames from 'classnames'
import {PROFILS, iconPropertiesInheritLarge, SVG_ICONS} from '../../utils/constants'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SvgIcon from '../../components/icon/SvgIcon'
import {connect} from 'react-redux'
import * as userSelectors from '../../modules/fo/user/services/userSelectors'
import TooltipItem from '../icon/TooltipItem'
import ViewListIcon from '@material-ui/icons/ViewList'
import AccessChecker from '../../components/security/AccessChecker'
import NotificationsIcon from '@material-ui/icons/Notifications'

const styles = theme => getStyles({
	container: {
		paddingLeft: 20,
		paddingRight: 20,
		marginTop: 10,
		marginLeft: 5,
		marginBottom: 6,
		marginRight: 7,
		height: 49,
		width: 'auto',
		backgroundColor: theme.palette.empty
	},
	message: {
		fontSize: '3em'
	},
	iconButton: {
		height: 49
	},
	accessViewListButton: {
		//marginRight: '50px'
	},
	title: {
		marginLeft: 22,
		fontWeight: 'normal'
	}
})

const SearchResultHeader = ({ title, className, classes, setAnchorElMore, setAnchorElTris, goToEditList, addSearchToFavorite, fromSearchDocumentPage, userAuthorities, displayAccessOssList, isNotificationButtonDisplayed }) => {
	return (
		<Grid container className={classnames(classes.container, className)} alignItems="center">
			<Grid item xs={6}>
				<Typography variant="h4" className={classes.title}>{title}</Typography>
			</Grid>
			<Grid item container xs={6} justify="flex-end">
				{isNotificationButtonDisplayed &&
					<TooltipItem
						title={<FormattedMessage id="global.more.ajouter_favoris_result_notif_on" />}
						content={<IconButton
							key="notifications"
							className={classnames(classes.iconButton,classes.accessViewListButton)}
							onClick={() => addSearchToFavorite(true)}
						>
							<NotificationsIcon {...iconPropertiesInheritLarge} />
						</IconButton>}
					/>
				}
				{fromSearchDocumentPage && displayAccessOssList &&
					<TooltipItem
						title={<FormattedMessage id="oss.tooltip.accesViewList" />}
						content={<IconButton
							key="view-list"
							className={classnames(classes.iconButton,classes.accessViewListButton)}
							onClick={() => goToEditList(false)}
						>
							<ViewListIcon {...iconPropertiesInheritLarge} />
						</IconButton>}
					/>
				}
				<IconButton key="action-sort" className={classes.iconButton} onClick={event => {
					setAnchorElTris(event.currentTarget)
				}}><SvgIcon path={SVG_ICONS.SORT} fontSize="large" color="inherit" /></IconButton>
				<AccessChecker access={[PROFILS.ADMIN, PROFILS.CORRESPONDANT, PROFILS.MEMBRE ]}>
					{setAnchorElMore &&
						<IconButton key="action-more" className={classes.iconButton} onClick={event => {
							setAnchorElMore(event.currentTarget)
						}}><MoreVertIcon fontSize="large" color="inherit" /></IconButton>
					}
				</AccessChecker>
			</Grid>
		</Grid>
	)
}

SearchResultHeader.defaultProps = {
	title: <FormattedMessage id="global.resultTitle" values={{ results: 0 }} />,
	actions: [],
	className: ''
}

SearchResultHeader.propTypes = {
	title: PropTypes.node.isRequired,
	setAnchorElMore: PropTypes.func,
	setAnchorElTris: PropTypes.func,
	className: PropTypes.string,
	classes: PropTypes.object.isRequired,
	goToEditList: PropTypes.func,
	addSearchToFavorite: PropTypes.func,
	displayAccessOssList: PropTypes.bool,
	isNotificationButtonDisplayed: PropTypes.bool
}

const mapStateToProps = state => ({
	userAuthorities: userSelectors.getAuthorities(state),
})

export default compose(
	connect(mapStateToProps, null),
	withStyles(styles),
	withTheme,
)(SearchResultHeader)
