import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { push } from 'connected-react-router'
import Popover from '@material-ui/core/Popover'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { getStyles } from 'isotope-client'
import Typography from '@material-ui/core/Typography'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import { connect } from 'react-redux'
import AddRecherchePopin from '../document/recherche/AddRecherchePopin'
import * as favorisActions from './services/favorisActions'
import * as selectors from './services/favorisSelectors'
import classnames from 'classnames'
import * as userSelectors from '../user/services/userSelectors'
import { DEFAULT_LANGUAGE_ID } from '../../../utils/constants'
import Popin from '../../../components/Popin'
import Button from '@material-ui/core/Button'
import NotificationsIcon from '@material-ui/icons/Notifications'
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff'
import EditIcon from '@material-ui/icons/Edit'

const styles = theme => getStyles({
	popover: {
		width: 492,
		height: 436,
		overflow: 'auto',
		padding: 20
	},
	divider: {
		backgroundColor: `${theme.palette.secondary.main} !important`,
		marginTop: '10px !important',
		marginBottom: '20px !important',
		height: '2px !important',
		width: '164px !important'
	},
	list: {
		maxHeight: 370,
		overflow: 'auto'
	},
	row: {
		backgroundColor: '#f3f1ee'
	},
	title: {
		marginTop: 20
	},
	item: {
		cursor: 'pointer'
	},
	notifItem: {
		marginRight: '45px'
	}
})

const initDeleteItem = {
	open: false,
	key: '',
	callback: () => {}
}


const initSearchItem = {
	open: false,
	search: null
}

const FavorisPopover = ({
	anchorFavoris,
	recherche,
	document,
	userLanguage,
	onClose,
	goToSearch,
	goToConsultation,
	deleteDocument,
	classes
}) => {
	const [deleteItem, setDeleteItem] = React.useState(initDeleteItem)
	const [openAddFavoritePopin, setOpenAddFavoritePopin] = React.useState(initSearchItem)

	const rechercheItems = Object.values(recherche).reverse()
	const documentItems = Object.values(document)

	const actions = [
		<Button
			color="inherit"
			variant="outlined"
			key="no"
			onClick={() => setDeleteItem(initDeleteItem)}
		>
			<FormattedMessage id="global.no" />
		</Button>,
		<Button
			key="yes"
			color="primary"
			variant="contained"
			onClick={deleteItem.callback}
		>
			<FormattedMessage id="global.yes" />
		</Button>
	]

	return (<Popover
		anchorEl={anchorFavoris}
		open={Boolean(anchorFavoris)}
		onClose={onClose}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right'
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right'
		}}
		classes={{
			paper: classes.popover
		}}
	>
		<Typography variant="h2"><FormattedMessage id="favoris.document" /></Typography>
		<Divider classes={{ root: classes.divider }} />
		{documentItems.length > 0 ?
			<List className={classes.list}>
				{documentItems.map((document, index) => {
						const labelDoc = document.labels[userLanguage] || document.labels[DEFAULT_LANGUAGE_ID] || Object.values(document.labels)[0]
						return (
							<ListItem key={document.idContent} className={classnames(classes.item, { [classes.row]: index % 2 === 0 })} onClick={() => {
								onClose()
								goToConsultation(document.idContent)
							}}>
								<ListItemText primary={labelDoc} />
								<ListItemSecondaryAction>
									<IconButton
										disableRipple
										onClick={(e) => {
											setDeleteItem({
												open: true,
												key: 'Document',
												callback: () => {
													deleteDocument(document.idContent)
													setDeleteItem(initDeleteItem)
												}
											})
											e.stopPropagation()
										}}
									>
										<CloseIcon />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						);
					}
				)}
			</List> : <FormattedMessage id="favoris.emptyDocument" />
		}

		<Typography variant="h2" className={classes.title}><FormattedMessage id="favoris.search" /></Typography>
		<Divider classes={{ root: classes.divider }} />

		{rechercheItems.length > 0 ?
			<List className={classes.list}>
				{rechercheItems.map((recherche, index) => (
						<ListItem key={recherche.id} className={classnames(classes.item, { [classes.row]: index % 2 === 0 })} onClick={() => {
							onClose()
							goToSearch(recherche.id)
						}}>
							{recherche.title ? (
								<ListItemText primary={recherche.title} />
							) : (
								<ListItemText primary={<><FormattedMessage id="favoris.searchItem" /> <FormattedDate value={recherche.dateCreation} /></>} />
							)}
							<ListItemSecondaryAction>
								<IconButton
									disableRipple
									onClick={(e) => {
										setOpenAddFavoritePopin({
											open: true,
											search: recherche
										})
										e.stopPropagation()
									}}
								>
									{recherche.weeklyNotification ? <NotificationsIcon /> : <NotificationsOffIcon />}
								</IconButton>
								<IconButton
									disableRipple
									onClick={(e) => {
										setOpenAddFavoritePopin({
											open: true,
											search: recherche
										})
										e.stopPropagation()
									}}
								>
									<EditIcon />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					)
				)}
			</List>
			:
			<FormattedMessage id="favoris.emptySearch" />
		}
		{deleteItem.open && <Popin
			open={deleteItem.open}
			actions={actions}
			title={<FormattedMessage id={`favoris.delete${deleteItem.key}Title`} />}
			content={<Typography><FormattedMessage id={`favoris.delete${deleteItem.key}Description`} /></Typography>}
			onClose={onClose}
		/>}
		{openAddFavoritePopin && <AddRecherchePopin
			title={<FormattedMessage id="document.search.addToFavorite.title" />}
			open={openAddFavoritePopin.open}
			onClose={() => setOpenAddFavoritePopin({
				open: false,
				search: null
			})}
			recherche={openAddFavoritePopin.search}
		/>}
	</Popover>)
}

const mapStateToProps = state => ({
	recherche: selectors.getRechercheFavoris(state),
	document: selectors.getDocumentFavoris(state),
	userLanguage: userSelectors.getUserLanguage(state)
})

const actions = {
	goToSearch: (id) => dispatch => dispatch(push(`/documents/favoris/${id}`)),
	goToConsultation: (id) => dispatch => dispatch(push(`/consultation-document/${id}`)),
	deleteRecherche: favorisActions.removeRechercheFromFavorite,
	deleteDocument: favorisActions.removeDocumentFromFavorite
}

FavorisPopover.propTypes = {
	anchorFavoris: PropTypes.object,
	recherche: PropTypes.object,
	document: PropTypes.object,
	userLanguage: PropTypes.string,
	deleteRecherche: PropTypes.func,
	deleteDocument: PropTypes.func,
	onClose: PropTypes.func,
	goToSearch: PropTypes.func,
	goToConsultation: PropTypes.func,
	classes: PropTypes.object
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(FavorisPopover)
