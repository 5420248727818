import * as api from './favorisApi'

export const ADD_RECHERCHE_TO_FAVORITE_REQUEST = 'ADD_RECHERCHE_TO_FAVORITE_REQUEST'
export const ADD_RECHERCHE_TO_FAVORITE_SUCCESS = 'ADD_RECHERCHE_TO_FAVORITE_SUCCESS'
export const ADD_RECHERCHE_TO_FAVORITE_ERROR = 'ADD_RECHERCHE_TO_FAVORITE_ERROR'
export const addRechercheToFavorite = (values) => dispatch => {
	dispatch({ type: ADD_RECHERCHE_TO_FAVORITE_REQUEST })
	return api.addRechercheToFavorite(values)
		.then(favoris => dispatch({ type: ADD_RECHERCHE_TO_FAVORITE_SUCCESS, favoris }))
		.catch(e => {
			dispatch({type: ADD_RECHERCHE_TO_FAVORITE_ERROR, payload: e})
			throw e
		})
}

export const REMOVE_RECHERCHE_FROM_FAVORITE_REQUEST = 'REMOVE_RECHERCHE_FROM_FAVORITE_REQUEST'
export const REMOVE_RECHERCHE_FROM_FAVORITE_SUCCESS = 'REMOVE_RECHERCHE_FROM_FAVORITE_SUCCESS'
export const REMOVE_RECHERCHE_FROM_FAVORITE_ERROR = 'REMOVE_RECHERCHE_FROM_FAVORITE_ERROR'
export const removeRechercheFromFavorite = (id) => dispatch => {
	dispatch({ type: REMOVE_RECHERCHE_FROM_FAVORITE_REQUEST })
	return api.removeRechercheFromFavorite(id)
		.then(() => dispatch({ type: REMOVE_RECHERCHE_FROM_FAVORITE_SUCCESS, id }))
		.catch(() => dispatch({ type: REMOVE_RECHERCHE_FROM_FAVORITE_ERROR }))
}

export const GET_RECHERCHE_FAVORIS_REQUEST = 'GET_RECHERCHE_FAVORIS_REQUEST'
export const GET_RECHERCHE_FAVORIS_SUCCESS = 'GET_RECHERCHE_FAVORIS_SUCCESS'
export const GET_RECHERCHE_FAVORIS_ERROR = 'GET_RECHERCHE_FAVORIS_ERROR'
export const getRechercheFavoris = () => dispatch => {
	dispatch({ type: GET_RECHERCHE_FAVORIS_REQUEST })

	return api.getRechercheFavoris()
		.then((favoris) => dispatch({ type: GET_RECHERCHE_FAVORIS_SUCCESS, favoris }))
		.catch(() => dispatch({ type: GET_RECHERCHE_FAVORIS_ERROR }) )
}

export const GET_DOCUMENT_FAVORIS_REQUEST = 'GET_DOCUMENT_FAVORIS_REQUEST'
export const GET_DOCUMENT_FAVORIS_SUCCESS = 'GET_DOCUMENT_FAVORIS_SUCCESS'
export const GET_DOCUMENT_FAVORIS_ERROR = 'GET_DOCUMENT_FAVORIS_ERROR'
export const getDocumentFavoris = () => dispatch => {
	dispatch({ type: GET_DOCUMENT_FAVORIS_REQUEST })

	return api.getDocumentFavoris()
		.then((favoris) => dispatch({ type: GET_DOCUMENT_FAVORIS_SUCCESS, favoris }))
		.catch(() => dispatch({ type: GET_DOCUMENT_FAVORIS_ERROR }) )
}

export const ADD_DOCUMENT_TO_FAVORITE_REQUEST = 'ADD_DOCUMENT_TO_FAVORITE_REQUEST'
export const ADD_DOCUMENT_TO_FAVORITE_SUCCESS = 'ADD_DOCUMENT_TO_FAVORITE_SUCCESS'
export const ADD_DOCUMENT_TO_FAVORITE_ERROR = 'ADD_DOCUMENT_TO_FAVORITE_ERROR'
export const addDocumentToFavorite = (id) => dispatch => {
	dispatch({ type: ADD_DOCUMENT_TO_FAVORITE_REQUEST })
	return api.addDocumentToFavorite(id)
		.then(favoris => dispatch({ type: ADD_DOCUMENT_TO_FAVORITE_SUCCESS, favoris }))
		.catch(() => dispatch({ type: ADD_DOCUMENT_TO_FAVORITE_ERROR }))
}

export const REMOVE_DOCUMENT_FROM_FAVORITE_REQUEST = 'REMOVE_DOCUMENT_FROM_FAVORITE_REQUEST'
export const REMOVE_DOCUMENT_FROM_FAVORITE_SUCCESS = 'REMOVE_DOCUMENT_FROM_FAVORITE_SUCCESS'
export const REMOVE_DOCUMENT_FROM_FAVORITE_ERROR = 'REMOVE_DOCUMENT_FROM_FAVORITE_ERROR'
export const removeDocumentFromFavorite = (id) => dispatch => {
	dispatch({ type: REMOVE_DOCUMENT_FROM_FAVORITE_REQUEST })
	return api.removeDocumentFromFavorite(id)
		.then(() => dispatch({ type: REMOVE_DOCUMENT_FROM_FAVORITE_SUCCESS, id }))
		.catch(() => dispatch({ type: REMOVE_DOCUMENT_FROM_FAVORITE_ERROR }))
}
