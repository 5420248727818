import { connect } from 'react-redux'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import ThumbUp from '@material-ui/icons/ThumbUp'
import { push } from 'connected-react-router'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import SvgIcon from '../../../../components/icon/SvgIcon'
import PaperBlocAccueil from './PaperBloc'
import { GlOBAL_LANGUAGE_ID, SVG_ICONS, TYPE_CONTENU } from '../../../../utils/constants'
import { getTopLike } from '../../document/services/documentApi'
import { DOCUMENT_STATUS } from '../../document/utils/documentConstants'
import { getUserEmail } from '../../user/services/userSelectors'
import { FormattedMessage, injectIntl } from 'react-intl'

const styles = theme => getStyles({
	container: {
		width: 612
	},
	row: {
		backgroundColor: '#f3f1ee'
	},
	divTitre: {
		height: 56,
		marginBottom: 10
	},
	divText: {
		color: theme.palette.text.main,
		fontSize: 14,
		fontWeight: 600
	},
	list: {
		margin: '0 -20px',
		padding: 0,
		height: 370,
		overflow: 'auto'
	},
	thumpUpNumber: {
		backgroundColor: theme.palette.secondary.main,
		padding: '5px 12px 5px 12px',
		color: 'white',
		boxShadow: 'none',
		borderRadius: 4,
		display: 'flex'
	},
	thumpUp: {
		marginRight: 5,
		fontSize: 16
	},
	icon: {
		fontSize: 32
	}
})

const TopBloc = ({ intl, goToEdit, userLanguage, classes }) => {
	const [topLikes, setTopLikes] = React.useState([])

	React.useEffect(() => {
		getTop()
			.catch(() => {
				// En cas d'erreur on effectue une nouvelle tentative
				getTop()
			})
	}, [])

	const getTop = () => {
		return getTopLike()
			.then(data => setTopLikes(data.results))
	}

	const renderIcon = (topLike) => {
		const iconProperty = {
			className: classes.icon,
			color: 'secondary',
			fontSize: 'large'
		}

		switch (topLike.typeContenu.valueField[GlOBAL_LANGUAGE_ID]) {
			case `${TYPE_CONTENU.SAVOIR_FAIRE}`:
				return <SvgIcon path={SVG_ICONS.SAVOIR_FAIRE} {...iconProperty} />
			case `${TYPE_CONTENU.PROPOSITION_COMMERCIALE}`:
				return <SvgIcon path={SVG_ICONS.PROPOSITION_COMMERCIALE} {...iconProperty} />
			case `${TYPE_CONTENU.REFERENCE}`:
				return <SvgIcon path={SVG_ICONS.REFERENCE} {...iconProperty} />
			default:
				return <></>

		}
	}

	const getSecondaryText = (topLike) => {
		const optDate = {
			year: 'numeric',
			month: 'short',
			day: 'numeric'
		}

		let date = undefined
		switch (topLike.statusWorkFlow.valueField[GlOBAL_LANGUAGE_ID]) {
			case DOCUMENT_STATUS.A_VALIDER:
				date = topLike.dateSubmitWorkFlow.valueField[GlOBAL_LANGUAGE_ID]
				break
			case DOCUMENT_STATUS.VALIDE:
				date = topLike.dateValidationWorkFlow.valueField[GlOBAL_LANGUAGE_ID]
				break
			case DOCUMENT_STATUS.PUBLIE:
				date = topLike.datePublishedWorkFlow.valueField[GlOBAL_LANGUAGE_ID]
				break
			case DOCUMENT_STATUS.MASQUE:
				date = topLike.dateMaskedWorkFlow.valueField[GlOBAL_LANGUAGE_ID]
				break
			case DOCUMENT_STATUS.A_SUPPRIMER:
			case DOCUMENT_STATUS.A_MASQUER:
				date = topLike.dateDemandWorkFlow.valueField[GlOBAL_LANGUAGE_ID]
				break
			default:
				date = topLike.dateCreation.valueField[GlOBAL_LANGUAGE_ID]
		}

		return `${intl.formatDate(topLike.dateModification.valueField[GlOBAL_LANGUAGE_ID], optDate)} - ${intl.formatMessage({ id: `enums.documentStatusContributions.${topLike.statusWorkFlow.valueField[GlOBAL_LANGUAGE_ID]}` })} ${intl.formatDate(date, optDate)}`
	}

	return (<PaperBlocAccueil className={classes.container}>
			<Grid container className={classes.divTitre}>
				<Grid item xs={12}>
					<Typography variant="h3">
						<FormattedMessage id="accueil.top.titre" />
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body2">
						<FormattedMessage id="accueil.top.description" />
					</Typography>
				</Grid>
			</Grid>
			<List className={classes.list}>
				{topLikes.map((topLike, index) => (
						<ListItem
							key={topLike.idContent.valueField[GlOBAL_LANGUAGE_ID]}
							style={index % 2 === 0 ? { backgroundColor: '#f3f1ee' } : {}}
							button
							onClick={() => goToEdit(topLike.idContent.valueField[GlOBAL_LANGUAGE_ID])}
						>
							<ListItemIcon>
								{renderIcon(topLike)}
							</ListItemIcon>
							<ListItemText
								primary={topLike.title.valueField[userLanguage] ? topLike.title.valueField[userLanguage] : ((topLike.langue && topLike.title.valueField[topLike.langue.valueField[GlOBAL_LANGUAGE_ID]]) ? topLike.title.valueField[topLike.langue.valueField[GlOBAL_LANGUAGE_ID]] : '')}
								secondary={getSecondaryText(topLike)} />
							<ListItemIcon>
								<Paper className={classes.thumpUpNumber}><ThumbUp className={classes.thumpUp} />{topLike.nombreLike.valueField[GlOBAL_LANGUAGE_ID]}</Paper>
							</ListItemIcon>
						</ListItem>
					)
				)}
			</List>
		</PaperBlocAccueil>
	)
}

const actions = {
	goToEdit: (id) => dispatch => dispatch(push(`consultation-document/${id}`))
}

TopBloc.propTypes = {
	classes: PropTypes.object,
	goToEdit: PropTypes.func
}

const mapStateToProps = state => ({
	contributor: getUserEmail(state)
})

export default compose(
	injectIntl,
	connect(mapStateToProps, actions),
	withStyles(styles)
)(TopBloc)
