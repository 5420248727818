import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { FormattedMessage } from 'react-intl'
import Popin from '../../../../components/Popin'
import Button from '@material-ui/core/Button'
import { Field, reduxForm } from 'redux-form'
import Input from '../../../../components/form/Input'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import CompleteEmail from '../../../../components/form/CompleteEmail'

const styles = () => getStyles({
	paddingText: {
		paddingLeft: 10,
		marginBottom: 15
	}
})

const validate = (values) => {
	const errors = {}

	if (!values.mailTo) {
		errors.mailTo = { id: 'global.errors.obligatoire' }
	}

	return errors
}

const ShareRecherchePopin = ({ open, onClose, onSubmit, handleSubmit, change, classes, title }) => {

	const actions = [
		<Button
			color="inherit"
			variant="outlined"
			key="fermer"
			onClick={onClose}
		>
			<FormattedMessage id="global.buttons.close" />
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			type="submit"
			onClick={handleSubmit(onSubmit)}
		>
			<FormattedMessage id="global.buttons.send" />
		</Button>
	]

	return <form onSubmit={onSubmit}><Popin
		open={open}
		onClose={onClose}
		actions={actions}
		title={title}
		content={<>
			<Typography variant="body2" className={classes.paddingText}><FormattedMessage id="document.search.share.description" /></Typography>
			<Field
				name="mailTo"
				label={<FormattedMessage id="document.search.share.form.email" />}
				component={CompleteEmail}
				change={change}
			/>
			<Field
				name="message"
				label={<FormattedMessage id="document.search.share.form.commentaire" />}
				component={Input}
				multiline
				rows={3}
			/>
		</>}
	/>
	</form>

}

ShareRecherchePopin.propTypes = {
	open: PropTypes.bool,
	classes: PropTypes.object,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	change: PropTypes.func,
	title: PropTypes.string
}

const FORM_NAME = 'PARTAGE_RECHERCHE_FORM'

export default compose(
	reduxForm({
		form: FORM_NAME,
		validate
	}),
	withStyles(styles)
)(ShareRecherchePopin)
