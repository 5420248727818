import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import ShareIcon from '@material-ui/icons/Share'
import { push } from 'connected-react-router'
import { getStyles } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Button from '../../../components/Button'
import {iconPropertiesInheritLarge, SVG_ICONS, TYPE_CONTENU} from '../../../utils/constants'
import CustomSelect from '../../../components/form/CustomSelect'
import { openPopin } from './services/tabDocumentActions'
import { colors } from '../../../utils/constants'
import TooltipItem from "../../../components/icon/TooltipItem"
import SvgIcon from "../../../components/icon/SvgIcon"

const styles = () => getStyles({
	marginIcon: {
		marginLeft: 30
	},
	addNewContent: {
		backgroundColor: 'white',
		color: colors.primary,
		border: '2px solid',
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0)'
		},
		marginLeft: 24
	},
	editColumnsButton: {
		marginLeft: 12
	}
})

const TabDocumentActionsBar = ({ langues, classes, goToCreateReference, goToEditList, setOpenShare, setOpenSelectColumns, openPopin, onEdition }) => {
	const renderLang = () => (
		<Field
			component={CustomSelect}
			name="selectedLanguage"
		>
			{langues && langues.map(lang => <MenuItem
				key={`lang-${lang.id}`}
				value={lang.id}
			>
				<FormattedMessage id={`enums.langue.${lang.codeLang}`} />
			</MenuItem>)}
		</Field>
	)

	const renderIconButtons = (onClick, icon) => (
		<IconButton onClick={onClick}>
			{icon}
		</IconButton>
	)

	const renderAccessEditListIconButtons = () => (
		<TooltipItem
			title={<FormattedMessage id="oss.tooltip.accesEditList"/>}
			content={<IconButton
				key="edit-list"
				className={classes.iconButton}
				onClick={() => goToEditList(true)}
			>
				<SvgIcon path={SVG_ICONS.EDIT_LIST} {...iconPropertiesInheritLarge} />
			</IconButton>}
		/>
	)

	const renderEditColumns = () => (
		<Button
			key="edit-fields"
			color="primary"
			variant="contained"
			onClick={() => {
				// On save les infos dans un temp
				openPopin()
				setOpenSelectColumns(true)
			}}
			className={classes.editColumnsButton}
		>
			<FormattedMessage id="oss.actionBar.editColumns" />
		</Button>
	)

	const renderAddContent = () => (
		<Button
			key="access"
			className={classes.addNewContent}
			color="primary"
			variant="contained"
			onClick={createReference}
		>
			<FormattedMessage id="accueil.mesContributions.add" />
		</Button>
	)

	const shareTab = () => {
		setOpenShare(true)
	}

	const createReference = () => {
		goToCreateReference(TYPE_CONTENU.REFERENCE)
	}

	return (
		<Grid item container xs={12} alignItems="center">
			<Grid item container xs={12} direction="row-reverse" alignItems="center" >
				{renderAddContent()}
				{renderEditColumns()}
				{renderIconButtons(shareTab, <ShareIcon />)}
				{!onEdition && renderAccessEditListIconButtons()}
				{renderLang()}
			</Grid>
		</Grid>
	)
}

const actions = {
	goToCreateReference: (typeContenu) => dispatch => dispatch(push(`/edition-document/new/${typeContenu}`)),
	openPopin
}

TabDocumentActionsBar.propTypes = {
	langues: PropTypes.array,
	classes: PropTypes.object,
	onEdition: PropTypes.bool,
	goToCreateReference: PropTypes.func,
	goToEditList: PropTypes.func,
	setOpenShare: PropTypes.func,
	setOpenSelectColumns: PropTypes.func
}

export default compose(
	connect(undefined, actions),
	injectSnackActions,
	withStyles(styles)
)(TabDocumentActionsBar)
