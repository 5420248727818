import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getRechercheFavoris, getDocumentFavoris } from './favorisActions'

export const loadFavoris = (WrappedComponent) => {
	const FavorisInjector = ({ getRechercheFavoris, getDocumentFavoris, ...props }) => {
		React.useEffect(() => {
			getRechercheFavoris()
			getDocumentFavoris()
		}, [])

		return <WrappedComponent {...props} />
	}

	const actions = {
		getRechercheFavoris,
		getDocumentFavoris
	}

	FavorisInjector.propTypes = {
		getRechercheFavoris: PropTypes.func,
		getDocumentFavoris: PropTypes.func
	}

	return connect(undefined, actions)(FavorisInjector)
}
