import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { Field, Form } from 'react-final-form'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '../../../../components/Button'
import Popin from '../../../../components/Popin'
import Input from '../../../../components/form/Input'
import { isRequired } from '../../../../utils/utils'
import FormSwitch from 'isotope-client/components/form/fields/FormSwitch'

const styles = () => getStyles({
	popinResize: {
		maxWidth: 900
	},
	error: {
		paddingLeft: 10,
		marginBottom: 20
	},
	published: {
		paddingLeft: 22
	},
	traduction: {
		marginTop: 20,
		marginBottom: 36
	},
	switch: {
		alignItems: 'center',
		justifyContent: 'center'
	},
	firstRow: {
		marginTop: 4
	},
	lastRow: {
		marginBottom: 12
	},
	buttonAction: {
		marginRight: 50
	}
})

const validate = (values) => {
	return { name: isRequired(values.name) }
}

const GENERAL_RESEARCH_SECTION_ID = '1'

const EditionResearchSectionPopin = ({
	open,
	initValues,
	closePopin,
	saveSection,
	classes,
	intl
}) => {

	const getActions = (handleSubmit) => <Grid container justifyContent="center">
		<Button
			color="inherit"
			variant="outlined"
			key="fermer"
			className={classes.buttonAction}
			onClick={closePopin}
		>
			<FormattedMessage id="global.buttons.annuler" />
		</Button>
		<Button
			key="valider"
			color="primary"
			variant="contained"
			type="submit"
			onClick={handleSubmit}
		>
			<FormattedMessage id="global.buttons.save" />
		</Button>
	</Grid>

	return <Form
		onSubmit={saveSection}
		initialValues={initValues}
		validate={validate}
	>
		{({ handleSubmit, form }) =>
			<form onSubmit={handleSubmit}>
				<Popin
					dialogClasses={{ paper: classes.popinResize }}
					onClose={() => {
						closePopin()
						form.reset()
					}}
					open={open}
					actions={getActions(handleSubmit)}
					title={initValues && initValues.idResearchSection ? intl.formatMessage({ id: 'additionalFilters.updateSection' }) : intl.formatMessage({ id: 'additionalFilters.newSection' })}
					content={<>
						<Grid container>
							<Grid item xs={12} className={classes.firstRow}>
								<Field
									name="name"
									label={<FormattedMessage id="additionalFilters.form.name" />}
									component={Input}
									inputProps={{
										readOnly: initValues && initValues.idResearchSection === GENERAL_RESEARCH_SECTION_ID
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<Field
									name="subSegments"
									label={<FormattedMessage id="additionalFilters.form.subSegments" />}
									component={Input}
									inputProps={{
										readOnly: initValues && initValues.idResearchSection === GENERAL_RESEARCH_SECTION_ID
									}}
								/>
							</Grid>
							<Grid container alignItems={'center'} className={classes.published}>
								<Grid item>
									<Typography>
										<FormattedMessage id="additionalFilters.form.publishedSection" />
									</Typography>
								</Grid>
								<Grid item container xs={8}>
									<Field
										name="published"
										component={FormSwitch}
										classes={{ root: classes.switch }}
										value={initValues && initValues.idResearchSection === GENERAL_RESEARCH_SECTION_ID ? true : undefined }
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid container justify="center" className={classes.traduction}>
							<Typography variant="h3">
								<FormattedMessage id={`parametrage.traduction`} />
							</Typography>
						</Grid>
						<Grid container>
							<Grid item xs={12}>
								<Field
									name="nameFr"
									label={<FormattedMessage id="additionalFilters.form.nameFr" />}
									component={Input}
									inputProps={{
										readOnly: initValues && initValues.idResearchSection === GENERAL_RESEARCH_SECTION_ID
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Field
									name="nameEs"
									label={<FormattedMessage id="additionalFilters.form.nameEs" />}
									component={Input}
									inputProps={{
										readOnly: initValues && initValues.idResearchSection === GENERAL_RESEARCH_SECTION_ID
									}}
								/>
							</Grid>
							<Grid item xs={12} className={classes.lastRow}>
								<Field
									name="namePl"
									label={<FormattedMessage id="additionalFilters.form.namePl" />}
									component={Input}
									inputProps={{
										readOnly: initValues && initValues.idResearchSection === GENERAL_RESEARCH_SECTION_ID
									}}
								/>
							</Grid>
						</Grid>
					</>
					}
				/>
			</form>}
	</Form>
}


EditionResearchSectionPopin.propTypes = {
	open: PropTypes.bool,
	initValues: PropTypes.object,
	closePopin: PropTypes.func,
	saveSection: PropTypes.func,
	classes: PropTypes.object,
	intl: intlShape
}

export default compose(
	withStyles(styles),
	injectIntl
)(EditionResearchSectionPopin)
