import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getLangues } from 'isotope-client/modules/langue/services/langueSelectors'
import { loadLangues } from 'isotope-client/modules/langue/services/langueActions'

export const injectLangues = (WrappedComponent) => {
	const LangueInjector = ({ langues, loadLangues, ...props}) => {
		React.useEffect(() => {
			if (!langues || langues.length === 0) {
				loadLangues()
			}
		}, [])


		return <WrappedComponent langues={langues} {...props} />
	}

	LangueInjector.propTypes = {
		langues: PropTypes.array,
		loadLangues: PropTypes.func
	}

	const actions = {
		loadLangues
	}

	const mapStateToProps = state => ({
		langues: getLangues(state)
	})

	return connect(mapStateToProps, actions)(LangueInjector)
}
