import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import { SVG_ICONS } from '../../../../../../utils/constants'
import SvgIcon from '../../../../../../components/icon/SvgIcon'
import { Draggable } from 'react-beautiful-dnd'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { injectLangues } from '../../../../../common/langue/langueInjector'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = theme => getStyles({
	container: {
		border: '2px solid #c8dcdc',
		padding: 5,
		cursor: 'pointer',
		backgroundColor: '#FFFFFF',
		marginBottom: 5
	},
	icon: {
		color: theme.palette.tertiary
	}
})

const DonneeTableauColonne = ({ field, idLanguage, langues, index, intl, handleSelect, deleteColonne, classes }) => {

	const codeLang = (langues.find(langue => langue.id === idLanguage) || {}).codeLang
	const fieldTitle = field.name ? field.name[codeLang] : undefined

	const handleDelete = e => {
		e.stopPropagation()
		return deleteColonne()
	}

	return <Draggable draggableId={index + 1} index={index}>
		{provided =>
			<div ref={provided.innerRef} {...provided.draggableProps}>
				<Grid
					item
					container
					className={classes.container}
					alignItems="center"
					onClick={handleSelect}
				>
					<Grid item xs={10} container direction="column">
						<Typography variant="h6">{fieldTitle || intl.formatMessage({ id: 'parametrage.field.defaultTitle' })}</Typography>
						<Typography variant="h6"><FormattedMessage id={`enums.field.${field.type}`} /> - {field.reference}</Typography>
					</Grid>
					<Grid item xs={1}>
						<Grid container justify="center" alignContent="center">
							<IconButton onClick={handleDelete}>
								<DeleteIcon color="tertiary" />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item xs={1}>
						<div {...provided.dragHandleProps}>
							<Grid container justify="center" alignContent="center">
								<SvgIcon path={SVG_ICONS.DRAG} fontSize="large" />
							</Grid>
						</div>
					</Grid>
				</Grid>
			</div>
		}
	</Draggable>
}

DonneeTableauColonne.propTypes = {
	field: PropTypes.object,
	idLanguage: PropTypes.string,
	langues: PropTypes.array,
	index: PropTypes.number,
	intl: intlShape,
	handleSelect: PropTypes.func,
	deleteColonne: PropTypes.func,
	classes: PropTypes.object
}

export default compose(
	injectIntl,
	injectLangues,
	withStyles(styles)
)(DonneeTableauColonne)
