import React from 'react'
import PropTypes from 'prop-types'
import Input from '../../../../components/form/Input'
import Select from '../../../../components/form/Select'
import { PROFILS, REQUEST, USER_CONNECTE, USER_CONNECTE_NB, USER_FORM } from '../../../../utils/constants'
import AccessChecker from '../../../../components/security/AccessChecker'
import Actions from '../../../../components/form/Actions'
import { FormattedMessage } from 'react-intl'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import { Field } from 'redux-form'
import MultiSelectUserForm from './MultiSelectUserForm'

const styles = () => getStyles({
	button: {
		marginRight: 10,
		marginTop: 30,
		paddingLeft: 50,
		paddingRight: 50
	}
})

const NAME = 'name'
const ZONE = 'zone'
const PAYS_ORIGINE = 'paysOrigine'
const PROFIL = 'profil'
const DEMANDE = 'demande'
const CONNECTE = 'connecte'

const UsersForm = ({
	demandesScreen,
	correspondantsScreen,
	isCorrespondant,
	zoneList,
	paysList,
	marketList,
	businessSegmentList,
	onSubmit,
	classes,
	change,
	isAdmin
}) => {
	return (<form onSubmit={onSubmit}>
		{!correspondantsScreen && <Field
			component={Input}
			name={NAME}
			label={<FormattedMessage id="users.search.nameLabel" />}
		/>}
		<Field
			component={Select}
			name={ZONE}
			label={<FormattedMessage id="users.search.zoneLabel" />}
			disabled={isCorrespondant && !correspondantsScreen}
			onChange={() => change('pays', [0])}
		>
			<MenuItem key={0} value={0}>
				<FormattedMessage id="users.search.default" />
			</MenuItem>
			{zoneList && zoneList.map(zoneElement =>
				<MenuItem key={`ZONE-${zoneElement.code}`} value={zoneElement.code}>
					{zoneElement.label}
				</MenuItem>
			)}
		</Field>
		{/*recherche sur le pays d'origine*/}
		{!correspondantsScreen && <MultiSelectUserForm name={PAYS_ORIGINE} elements={paysList} correspondantsScreen={correspondantsScreen}/>}

		{/*recherche sur le pays du triplet*/}
		{((correspondantsScreen || isAdmin) && !demandesScreen) && <MultiSelectUserForm name={USER_FORM.PAYS} elements={paysList} correspondantsScreen={correspondantsScreen}/>}

		<MultiSelectUserForm name={USER_FORM.SEGMENT} elements={businessSegmentList} correspondantsScreen={correspondantsScreen} />

		<MultiSelectUserForm name={USER_FORM.MARKET} elements={marketList} correspondantsScreen={correspondantsScreen} />

		{!correspondantsScreen && <Field
			component={Select}
			name={PROFIL}
			label={<FormattedMessage id="users.search.profilLabel" />}
			disabled={demandesScreen}
		>
			<MenuItem key={0} value={0}>
				<FormattedMessage id="users.search.default" />
			</MenuItem>
			{Object.keys(PROFILS).map((key) =>
				<MenuItem key={key} value={PROFILS[key].id}>
					<FormattedMessage id={`constants.profils.${key}`} />
				</MenuItem>
			)}
		</Field>}
		{!correspondantsScreen && <Field
			component={Select}
			name={DEMANDE}
			label={<FormattedMessage id="users.search.demandeLabel" />}
			disabled={demandesScreen}
		>
			<MenuItem key={0} value={0}>
				<FormattedMessage id="users.search.default" />
			</MenuItem>
			{Object.keys(REQUEST).map((key) =>
				<MenuItem key={key} value={key}>
					<FormattedMessage id={`constants.request.${key}`} />
				</MenuItem>
			)}
		</Field>}

		{(!demandesScreen && !correspondantsScreen) && <AccessChecker access={PROFILS.ADMIN}>
			<Field
				component={Select}
				name={CONNECTE}
				label={<FormattedMessage id="users.search.connecteLabel" />}
			>
				<MenuItem key={0} value={0}>
					<FormattedMessage id="users.search.default" />
				</MenuItem>
				{Object.keys(USER_CONNECTE).map((key) =>
					<MenuItem key={key} value={USER_CONNECTE_NB[key]}>
						<FormattedMessage id={`constants.user_connecte.${key}`} />
					</MenuItem>
				)}
			</Field>
		</AccessChecker>}
		<Actions>
			<Button
				key="rechercher"
				color="primary"
				variant="contained"
				className={classes.button}
				type="submit"
			>
				<FormattedMessage id="global.buttons.rechercher" />
			</Button>
		</Actions>
	</form>)
}

UsersForm.propTypes = {
	demandesScreen: PropTypes.bool,
	correspondantsScreen: PropTypes.bool,
	isCorrespondant: PropTypes.bool,
	zoneList: PropTypes.array,
	paysList: PropTypes.array,
	classes: PropTypes.object,
	onSubmit: PropTypes.func
}

export default withStyles(styles)(UsersForm)
