import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import withStyles from '@material-ui/core/styles/withStyles'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import { colors } from '../utils/constants'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => getStyles({
	dialogTitle: {
		borderBottom: '3px solid',
		paddingBottom: 10
	},
	dialogContent: {
		paddingTop: 10
	},
	actions: {
		display: 'flex',
		justifyContent: 'space-around',
		marginBottom: 20
	},
	closeButton: {
		position: 'absolute',
		right: 8,
		top: 2,
		color: colors.text
	},
	fromSearch: {
		display: 'flex',
		justifyContent: 'space-evenly',
		padding: 12,
		boxShadow: '0px -3px 3px 0px lightgrey'
	}
})

const Popin = ({ open, actions, classes, title, content, contentStyleName, paperStyle, dialogClasses, onClose, fullScreen, index = 0, fromSearch, ...otherProps }) => {

	return (
		<Dialog
			open={open}
			fullWidth
			fullScreen={fullScreen}
			onExited={onClose}
			onBackdropClick={onClose}
			PaperProps={{
				style: {
					backgroundColor: colors.container,
					...paperStyle
				}
			}}
			classes={dialogClasses}
			{...otherProps}
		>
			<DialogTitle disableTypography>
				<Typography variant="h4" className={classes.dialogTitle}>
					{title}
				</Typography>
				{onClose && (
					<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
						<CloseIcon />
					</IconButton>
				)}
			</DialogTitle>
			<DialogContent className={[classes.dialogContent, contentStyleName].join(' ')}>
				{content}
			</DialogContent>
			<DialogActions
				className={fromSearch
					? classes.fromSearch
					: classes.actions
				}
			>
				{actions}
			</DialogActions>
		</Dialog>
	)
}

Popin.propTypes = {
	title: PropTypes.node,
	content: PropTypes.node,
	actions: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element)
	]),
	classes: PropTypes.object,
	open: PropTypes.bool,
	contentStyleName: PropTypes.string,
	onExited: PropTypes.func,
	dialogClasses: PropTypes.object,
	fullScreen: PropTypes.bool.isRequired
}

Popin.defaultProps = {
	paperStyle: {}
}

export default compose(
	withMobileDialog(),
	withStyles(styles)
)(Popin)

