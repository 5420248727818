import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import { BLOC_INFOS, BLOC_VALIDATION_ADMIN, BLOC_VALIDATION_CORRESPONDANT, BLOCS_DETAIL } from './accueilConstants'
import SearchBar from '../../../components/layout/SearchBar'
import CenteredLayout from '../../../components/layout/CenteredLayout'
import { PROFILS } from '../../../utils/constants'
import AccessChecker, { hasAccess } from '../../../components/security/AccessChecker'
import * as userSelectors from '../user/services/userSelectors'
import DetailBloc from './components/DetailBloc'
import AideBloc from './components/AideBloc'
import TopBloc from './components/TopBloc'
import ValidationBloc from './components/ValidationBloc'
import MesContributionsBloc from './components/MesContributionsBloc'
import { getCountPublishedDocument, getCountToValidateDocument, getCountToValidateAccess } from './services/accueilApi'

const styles = () => getStyles({
	container: {
		marginBottom: 20
	},
	searchBarContainer: {
		margin: '24px 0px 64px 0px'
	},
	displayFlex: {
		display: 'flex'
	},
	contenuContainer: {
		marginTop: 30
	}
})

const AccueilPage = ({ isCorrespondant, isAdmin, classes }) => {
	const [countDocPublished, setCountDocPublished] = React.useState(0)
	const [countDocToValidate, setCountDocToValidate] = React.useState(0)
	const [countAccessToValidate, setCountAccessToValidate] = React.useState(0)

	React.useEffect(() => {
		getCountPublishedDocument()
			.then(count => setCountDocPublished(count))
		if (isCorrespondant || isAdmin) {
			getCountToValidateDocument()
				.then(count => setCountDocToValidate(count))
			getCountToValidateAccess()
				.then(count => setCountAccessToValidate(count))
		}
	}, [])

	// Si les deux blocs de validation sont vides, on les masque
	const blocValidation = isAdmin ? BLOC_VALIDATION_ADMIN : BLOC_VALIDATION_CORRESPONDANT
	const validationIsNotEmpty = countDocToValidate !== 0 || countAccessToValidate !== 0

	return (
		<CenteredLayout className={classes.container}>
			<Grid container direction="row">

				{/*<MessageFullWidth></MessageFullWidth>*/}

				{/*Barre de recherche*/}
				<Grid item xs={12} sm={12}>
					<Grid container className={classes.searchBarContainer} alignItems="center" justify="center">
						<Grid item xs={8} sm={8}>
							<SearchBar />
						</Grid>
					</Grid>
				</Grid>

				{/*Synthèse Contenus*/}
				<Grid item container xs={12} sm={12} justify="space-evenly">
					{BLOCS_DETAIL.map((detail, index) => <Grid key={`detail-${index}`} item className={classes.displayFlex}>
						<DetailBloc contenu={{
							...detail,
							data: {
								disponible: countDocPublished && countDocPublished[`nb${detail.serverKey}`],
								publication: countDocPublished && countDocPublished[`nb${detail.serverKey}LastMonth`]
							}
						}} />
					</Grid>)}
				</Grid>

				{validationIsNotEmpty && <AccessChecker access={[PROFILS.CORRESPONDANT, PROFILS.ADMIN]}>
					<Grid item container xs={12} sm={12} className={classes.contenuContainer} justify="space-evenly">
						{blocValidation.map((validation, index) => <Grid key={`validation-${index}`} item className={classes.displayFlex}>
							<ValidationBloc contenu={{
								...validation,
								number: index === 0 ? countDocToValidate : countAccessToValidate
							}} />
						</Grid>)}
					</Grid>
				</AccessChecker>}


				{/*Contenus*/}
				<Grid item xs={12} sm={12} container className={classes.contenuContainer} justify="space-evenly">
					<Grid item className={classes.displayFlex}>
						<AccessChecker access={PROFILS.VISITEUR}>
							<AideBloc contenu={{ key:'visiteur', link: '/me' }} accessButton />
						</AccessChecker>
						<AccessChecker access={[PROFILS.MEMBRE, PROFILS.CORRESPONDANT, PROFILS.ADMIN]}>
							<MesContributionsBloc />
						</AccessChecker>
					</Grid>
					<Grid item><TopBloc /></Grid>
				</Grid>

				{/*Informations*/}
				<Grid item xs={12} sm={12} container className={classes.contenuContainer} justify="space-evenly">
					{BLOC_INFOS.map((info, index) => <Grid key={`infos-${index}`} className={classes.displayFlex}>
						<AideBloc contenu={info} />
					</Grid>)}
				</Grid>
			</Grid>
		</CenteredLayout>
	)
}

const mapStateToProps = state => ({
	isAdmin: hasAccess(PROFILS.ADMIN, userSelectors.getAuthorities(state)),
	isCorrespondant: hasAccess(PROFILS.CORRESPONDANT, userSelectors.getAuthorities(state)),
})

AccueilPage.propTypes = {
	isAdmin: PropTypes.bool,
	isCorrespondant: PropTypes.bool,
	classes: PropTypes.object
}

export default compose(
	connect(mapStateToProps),
	withStyles(styles)
)(AccueilPage)
