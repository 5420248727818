import { fetchFactory } from 'isotope-client'

/**
 * Ajout d'une recherche en favoris
 * @param values
 * @returns {*}
 */
export const addRechercheToFavorite = (values) => fetchFactory('/favoris/recherche', {
	method: 'POST',
	body: JSON.stringify(values)
})

/**
 * Suppression d'une recherche en favoris à partir de l'id recherche
 * @param id
 * @returns {*}
 */
export const removeRechercheFromFavorite = (id) => fetchFactory(`/favoris/recherche/${id}`, {
	method: 'DELETE'
})

/**
 * Récupère l'ensemble des recherches en favoris
 * @returns {*}
 */
export const getRechercheFavoris = () => fetchFactory('/favoris/recherche')

/**
 * Récupère l'ensemble des documents en favoris
 * @returns {*}
 */
export const getDocumentFavoris = () => fetchFactory('/favoris/document')

/**
 * Ajout d'un document en favoris à partir de l'id document
 * @param id
 * @returns {*}
 */
export const addDocumentToFavorite = (id) => fetchFactory(`/favoris/document/${id}`, {
	method: 'POST'
})

/**
 * Suppression d'un document en favoris à partir de l'id document
 * @param id
 * @returns {*}
 */
export const removeDocumentFromFavorite = (id) => fetchFactory(`/favoris/document/${id}`, {
	method: 'DELETE'
})
