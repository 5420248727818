import { injectFormSnackbar } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import Typography from '@material-ui/core/Typography'
import Popin from '../../../../components/Popin'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'

const EnregistrerPopin = ({ open, closePopup, userName, enregistrerUser, params }) => {

	const handleClose = () => closePopup()

	const actions = [
		<Button
			color="inherit"
			variant="outlined"
			key="fermer"
			onClick={handleClose}
		>
			<FormattedMessage id="global.buttons.annuler" />
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			onClick={() => enregistrerUser(params)}
		>
			<FormattedMessage id="global.buttons.save" />
		</Button>
	]

	return (
		<Popin
			open={open}
			actions={actions}
			title={<FormattedMessage id="modificationUser.popin.title" />}
			content={<Typography><FormattedMessage id="modificationUser.popin.message" /> {userName} ?</Typography>}
			onClose={handleClose}
		/>
	)
}

EnregistrerPopin.propTypes = {
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	userName: PropTypes.string,
	params: PropTypes.object,
	enregistrerUser: PropTypes.func
}

export default compose(
	injectFormSnackbar()
)(EnregistrerPopin)
