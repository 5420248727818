import {Grid, MenuItem, Typography} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {injectActions as injectSnackActions} from 'isotope-client/components/snackbar/services/snackbarInjector'
import {push} from 'connected-react-router'
import React from 'react'
import PropTypes from 'prop-types'
import Input from '../../../../components/form/Input'
import Select from '../../../../components/form/Select'
import Popin from '../../../../components/Popin'
import {Field, reduxForm} from 'redux-form'
import {FormattedMessage} from 'react-intl'
import {compose} from 'redux'
import {connect} from 'react-redux'
import * as favorisActions from '../../favoris/services/favorisActions'
import * as userSelectors from '../../user/services/userSelectors'
import {getRechercheById} from '../services/documentApi'

const validate = (values) => {
	const errors = {}

	if (!values.title) {
		errors.title = { id: 'global.errors.obligatoire' }
	}

	return errors
}

const initDeleteItem = {
	open: false,
	key: '',
	callback: () => {
	}
}


const AddRecherchePopin = ({ open, onClose, title, deleteRecherche, recherche, params, snackSuccess, addToFavorite, goToSearch, handleSubmit, change, snackError }) => {
	const [deleteItem, setDeleteItem] = React.useState(initDeleteItem)
	const [filters, setFilters] = React.useState()

	React.useEffect(() => {
		if (!params && recherche) {
			getRechercheById(recherche.id, true)
				.then((data) => {
					setFilters(data.filters)
				})
		} else if (params) {
			setFilters(params)
		}
	}, [recherche, params])

	React.useEffect(() => {
		if (recherche) {
			change('title', recherche.title)
			change('weeklyNotification', recherche.weeklyNotification)
		}
	}, [recherche])

	const notificationOptions = [
		{
			message: <FormattedMessage id="global.yes" />,
			key: 'YES',
			value: true
		},
		{
			message: <FormattedMessage id="global.no" />,
			key: 'NO',
			value: false
		}
	]

	const onSubmit = (values) => {
		if (filters && values) {
			const body = {
				...filters,
				weeklyNotification: values.weeklyNotification,
				title: values.title,
				idRecherche: recherche ? recherche.id : null
			}
			return addToFavorite(body)
				.then(() => snackSuccess(<FormattedMessage id="favoris.addSearch.success" />))
				.catch(() => snackError(<FormattedMessage id="favoris.addSearch.error" />))
				.finally(onClose)
		} else {
			onClose()
		}
	}

	const deleteActions = [
		<Button
			color="inherit"
			variant="outlined"
			key="no"
			onClick={() => setDeleteItem(initDeleteItem)}
		>
			<FormattedMessage id="global.no" />
		</Button>,
		<Button
			key="yes"
			color="primary"
			variant="contained"
			onClick={deleteItem.callback}
		>
			<FormattedMessage id="global.yes" />
		</Button>
	]

	const actions = [
		<Button color="inherit" variant="outlined" key="fermer" onClick={(e) => {
			if (recherche) {
				setDeleteItem({
					open: true,
					key: 'Search',
					callback: () => {
						deleteRecherche(recherche.id)
						setDeleteItem(initDeleteItem)
					}
				})
				e.stopPropagation()
				onClose()
			} else {
				onClose()
			}
		}}>
			<FormattedMessage id="global.buttons.delete" />
		</Button>,
		<Button variant="outlined" color="primary" key="updateField" onClick={() => {
			if (recherche) {
				goToSearch(recherche.id)
				onClose()
			} else {
				onClose()
			}
		}}>
			<FormattedMessage id="global.buttons.updateFields" />
		</Button>,
		<Button key="submit" color="primary" variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
			<FormattedMessage id="global.buttons.save" />
		</Button>
	]

	return (
		<>
			<Popin
				title={title}
				open={open}
				onClose={onClose}
				actions={actions}
				content={<>
					<Field
						name="title"
						component={Input}
						label={<FormattedMessage id="document.search.addToFavorite.form.title" />}
					/>
					<Grid item container xs={12} alignItems="center">
						<Grid item xs={6}><Typography><FormattedMessage id="document.search.addToFavorite.form.notification" /></Typography></Grid>
						<Grid item xs={6}>
							<Field
								name="weeklyNotification"
								component={Select}
							>
								{notificationOptions.map((option) => (
									<MenuItem
										key={option.key}
										value={option.value}
									>
										{option.message}
									</MenuItem>
								))}
							</Field>
						</Grid>
					</Grid>
				</>}
			/>
			{deleteItem.open && <Popin
				open={deleteItem.open}
				actions={deleteActions}
				title={<FormattedMessage id={`favoris.delete${deleteItem.key}Title`} />}
				content={<Typography><FormattedMessage id={`favoris.delete${deleteItem.key}Description`} /></Typography>}
				onClose={onClose}
			/>}
		</>
	)
}

AddRecherchePopin.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	title: PropTypes.string,
	document: PropTypes.object,
	deleteRecherche: PropTypes.func,
	params: PropTypes.object,
	addToFavorite: PropTypes.func,
	snackSuccess: PropTypes.func,
	snackError: PropTypes.func,
	goToSearch: PropTypes.func,
	notificationEnable: PropTypes.bool
}

const mapStateToProps = (state, props) => ({
	userLanguage: userSelectors.getUserLanguage(state),
	initialValues: {
		weeklyNotification: props.notificationEnable
	}
})

const actions = {
	deleteRecherche: favorisActions.removeRechercheFromFavorite,
	addToFavorite: favorisActions.addRechercheToFavorite,
	goToSearch: (id) => dispatch => dispatch(push(`/documents/favoris/${id}`))
}

const FORM_NAME = 'ADD_FAVORITE_SEARCH_FORM'

export default compose(
	injectSnackActions,
	connect(mapStateToProps, actions),
	reduxForm({
		form: FORM_NAME,
		validate
	})
)(AddRecherchePopin)
