import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Popin from '../../../../../components/Popin'
import { useFormState, useForm } from 'react-final-form'
import { getDatasetParameters, getDuplicatedNumberOfModelSectionFromDocument, getDuplicateSuffix, getFieldName, getFilteredFields, getFilteredSections } from '../../utils/documentUtils'
import { DEFAULT_SU, GlOBAL_LANGUAGE_ID } from '../../../../../utils/constants'
import { ATTRIBUTE_DATA_MO_FIELD, FIELD_TYPE } from '../../utils/documentConstants'
import { rechercheListDataLake } from '../../services/documentApi'
import * as documentSelectors from '../../services/documentSelectors'
import * as modelSelectors from '../../../../common/model/modelSelectors'
import { getUserSu } from '../../../user/services/userSelectors'
import { getItemsByListCode } from 'isotope-client/modules/valueList/services/valueListSelectors'
import { connect } from 'react-redux'
import { getConversionMap } from '../../../../common/conversion/conversionSelectors'
import { getStyles } from 'isotope-client'
import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { spliceIntoChunks } from '../../../../../utils/utils'
import Button from '../../../../../components/Button'


const styles = () => getStyles({
    paddingText: {
        marginBottom: 10
    }
})

const GlobalReportPopin = ({
                               open,
                               closePopin,
                               model,
                               document,
                               su,
                               items,
                               conversionMap,
                               setMapSectionByField,
                               classes
                           }) => {
    const [forceUpdate, setForceUpdate] = React.useState(false)
    const [dataAtotal, setDataAtotal] = React.useState(0)
    const [dataUpdateDone, setDataUpdateDone] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    const { values } = useFormState();

    React.useEffect(() => {
        if (open) {
            setForceUpdate(false)
            setLoading(false)
        }
    }, [open])

    React.useEffect(() => {
        if (forceUpdate) {
            setLoading(true)
            launchRechercheGR()
        }
    }, [forceUpdate])

    const addSectionInMap = (fieldName, sectionInfo) => setMapSectionByField({
        [fieldName]: sectionInfo
    })


    const launchRechercheGR = async () => {
        let listParam = {}

        getFilteredSections(model.sections).forEach(section =>
            getDuplicatedNumberOfModelSectionFromDocument(section, document.sections).forEach(sectionDuplicateNumber => {
                const param = forceUpdateSectionLoop(section, sectionDuplicateNumber, 1)
                if (param != null && param.length > 0) {
                    param.map(elem => listParam[`${elem.field.reference}${getDuplicateSuffix(elem.duplicatedNumber)}`] = elem)
                }
            })
        )

        const filteredListParamReformated = Object.keys(listParam).map(key => (
            {
                "valueToSend": listParam[key].valueToSend,
                "fieldReference": `${listParam[key].field.reference}${getDuplicateSuffix(listParam[key].duplicatedNumber)}`
            })
        )

        const poolSize = 5;
        let poolPromise = {};
        const dataFormatedSplit = spliceIntoChunks(filteredListParamReformated, 40)
        let loadingProgress = []
        for (const indexData in dataFormatedSplit) {
            poolPromise[dataFormatedSplit[indexData][0].fieldReference] = rechercheListDataLake(dataFormatedSplit[indexData])
                .then(resList => {
                    resList.forEach(res => {
                        const fieldData = listParam[res.fieldReference]
                        const duplicatedNumber = fieldData.duplicatedNumber
                        const field = fieldData.field
                        const data = field.attributeData[GlOBAL_LANGUAGE_ID]
                        let uniteList = []
                        let coeff = 1

                        if (data
                            && data[ATTRIBUTE_DATA_MO_FIELD.UNITE_LIST] && data[ATTRIBUTE_DATA_MO_FIELD.UNITE_LIST].value &&
                            data[ATTRIBUTE_DATA_MO_FIELD.DEFAULT_UNITE] && data[ATTRIBUTE_DATA_MO_FIELD.DEFAULT_UNITE].value) {
                            uniteList = items[data[ATTRIBUTE_DATA_MO_FIELD.UNITE_LIST].value] || []
                            const uniteFound = uniteList.find(unite => unite.code === data[ATTRIBUTE_DATA_MO_FIELD.DEFAULT_UNITE].value)
                            if (uniteFound) {
                                if (su !== DEFAULT_SU) {
                                    const conversion = conversionMap && conversionMap[su] && conversionMap[su][uniteFound.code]
                                    if (conversion) {
                                        coeff = conversion && conversion[su] && conversion[su][uniteFound.code] && conversion[su][uniteFound.code].coeff
                                    }
                                }
                            }
                        }
                        const suffixField = field.type === FIELD_TYPE.AMOUNT ? 'siValue' : 'value'
                        const fieldResultat = (res.resultat.length > 0 && res.resultat[0].value !== '')
                            ? `${field.type === FIELD_TYPE.NUMBER_UNIT ? res.resultat[0].value * coeff : res.resultat[0].value}`
                            : ''


                        if (fieldResultat !== '') {
                            form.change(`${getFieldName(field, duplicatedNumber)}.${suffixField}`, fieldResultat)
                        }
                    })
                    loadingProgress.push( Number(dataFormatedSplit[indexData].length))
                    console.log(`UpdateData :${loadingProgress.reduce((a, b) => a + b, 0)}/${Object.keys(listParam).length}`)
                    // Un return pour le resolvedId et donc le retirer du pool des promise en attente
                    return dataFormatedSplit[indexData][0].fieldReference
                }).catch(() => {
                    // on fait rien en soit c'est juste pour ne pas arreter le processus

                })

            if (Object.keys(poolPromise).length > poolSize - 1) {
                const promises = Object.values(poolPromise);
                const resolvedId = await Promise.race(promises); // wait for one Promise to finish
                delete poolPromise[resolvedId]; // remove that Promise from the pool
            }

        }

        // attend que les dernirr dans le pool soit fini.
        await Promise.allSettled(Object.values(poolPromise))
        closePopin()
        setLoading(false)
    }

    const forceUpdateSectionLoop = (section, duplicatedNumber, level) => {
        const loopSection = getFilteredSections(section.sections).reduce((acc, att) => {
            const loop = forceUpdateSectionLoop(att,duplicatedNumber, level+1)
            Array.prototype.push.apply(acc,loop)
            return acc
        }, [])
        const loopFields = getFilteredFields(section.fields)
            .filter(field => [FIELD_TYPE.NUMBER, FIELD_TYPE.NUMBER_UNIT, FIELD_TYPE.AMOUNT].includes(field.type))
            .map((field, index)  => forceUpdateFieldLoop(field, duplicatedNumber, section, level)
            )
        Array.prototype.push.apply(loopSection,loopFields)
        return loopSection.filter(function( element ) {
            return element !== undefined;
        })
    }

    const forceUpdateFieldLoop = (field, duplicatedNumber, section, level ) => {
        const defaultValue = field.attributeData[GlOBAL_LANGUAGE_ID] && field.attributeData[GlOBAL_LANGUAGE_ID][ATTRIBUTE_DATA_MO_FIELD.DEFAULT_VALUE];
        const datasetParams = getDatasetParameters(defaultValue, duplicatedNumber)
        const initialParams = datasetParams
            .reduce((acc, fieldParam) => {
                if(values.fields[fieldParam.key+getDuplicateSuffix(duplicatedNumber)]){
                    const { value } = values.fields[fieldParam.key+getDuplicateSuffix(duplicatedNumber)]
                    return ({
                        ...acc,
                        [fieldParam.fieldName]: {
                            key: fieldParam.key,
                            value
                        }
                    })
                } else {
                    return ({
                        ...acc
                    })
                }
            }, {})

        const valueParams = Object.values(initialParams)
        if (valueParams.length > 0 && valueParams.filter(param => !param.value).length === 0) {
            let valueToSend = defaultValue.value
            valueParams.forEach(valueParam => {
                valueToSend = valueToSend.replaceAll(`\${${valueParam.key}}`, valueParam.value)
            })


            const sectionInfo = {
                id: section.id,
                idMoParentSection: section.idMoParentSection,
                position: level === 1 ? duplicatedNumber || section.position : section.position,
                duplicated: duplicatedNumber > 0,
                duplicatedNumber: duplicatedNumber,
                deleted: duplicatedNumber > 0 && false,
                finished: false
            }
            addSectionInMap(getFieldName(field, duplicatedNumber), sectionInfo)

            return {
                valueToSend : valueToSend,
                field : field,
                duplicatedNumber : duplicatedNumber
            }
        }
    }


    const form = useForm()

    const actions = [
        <Button
            color="inherit"
            variant="outlined"
            key="fermer"
            onClick={closePopin}
        >
            <FormattedMessage id={'global.buttons.annuler'} />
        </Button>,
        <Button
            key="valider"
            color="primary"
            variant="contained"
            onClick={() => {
                setForceUpdate(true)
            }}
            loading={loading}
        >
            <FormattedMessage id="document.globalReport.popin.confirm" />
        </Button>
    ]

    return <>
        <Popin
            open={open}
            actions={actions}
            title={<FormattedMessage id={'document.globalReport.popin.title'} />}
            onClose={closePopin}
            content={
                <Typography className={classes.paddingText}>
                    <FormattedMessage id={'document.globalReport.popin.details'} />
                </Typography>
            }
        />
    </>
}

GlobalReportPopin.propTypes = {
    open: PropTypes.bool,
    closePopin: PropTypes.func,
    model: PropTypes.object,
    document: PropTypes.object,
    su: PropTypes.string,
    items: PropTypes.object,
    conversionMap: PropTypes.object,
    setMapSectionByField: PropTypes.func
}

const mapStateToProps = (state, { match }) => {
    const document = documentSelectors.getDocument(state)
    const documentType = (document && document.type) || (match && match.params && match.params.typeDocument)
    const model = modelSelectors.getModelByType(state, documentType)

    return {
        document,
        model,
        su: getUserSu(state),
        items: getItemsByListCode(state),
        conversionMap: getConversionMap(state)
    }
}

export default compose(
    connect(mapStateToProps, null),
    withStyles(styles)
)(GlobalReportPopin)
