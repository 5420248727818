import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getModelByType } from './modelActions'
import { TYPE_CONTENU } from '../../../utils/constants'

export const loadDocumentModels = (WrappedComponent) => {
	const ModelInjector = ({ getModelByType, ...props }) => {
		React.useEffect(() => {
			// Load des 3 modèles
			getModelByType(TYPE_CONTENU.REFERENCE)
			getModelByType(TYPE_CONTENU.PROPOSITION_COMMERCIALE)
			getModelByType(TYPE_CONTENU.SAVOIR_FAIRE)
		}, [])

		return <WrappedComponent {...props} />
	}

	const actions = {
		getModelByType
	}

	ModelInjector.propTypes = {
		getModelByType: PropTypes.func
	}

	return connect(undefined, actions)(ModelInjector)
}
