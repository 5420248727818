import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import DonneeTableauColonne from './DonneeTableauColonne'
import { useField, useForm } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import EditDonneeTableauPopin from './EditDonneeTableauPopin'
import Actions from '../../../../../../components/form/Actions'
import Button from '../../../../../../components/Button'
import { getUserLanguage } from '../../../../../fo/user/services/userSelectors'

const styles = () => getStyles({
	actions: {
		padding: 15
	},
	draggableDiv: {
		width: '100%',
		paddingLeft: 10,
		paddingRight: 10
	}
})

const DonneeTableau = ({ classes, defaultLanguage, otherLanguages, idLanguage }) => {

	const fields = useField('fields').input.value
	const form = useForm()

	const [popupState, setPopupState] = React.useState({
		open: false,
		initialValues: {}
	})

	const onDragEnd = ({ destination, source }) => {
		if (!destination) {
			return
		}
		if (destination.index === source.index) {
			return
		}

		form.mutators.move('fields', source.index, destination.index)
	}

	const handleOpenEdit = (field, index) => setPopupState({
		open: true,
		initialValues: field,
		position: index
	})

	const handleOpenCreate = () => setPopupState({
		open: true,
		initialValues: {}
	})

	const handleClose = () => setPopupState({
		open: false,
		initialValues: {},
		position: undefined
	})

	const handleEditColonne = (values) => {
		if (popupState.position === undefined) {
			form.mutators.push('fields', values)
		} else {
			form.mutators.update('fields', popupState.position, values)
		}
		handleClose()
	}

	const handleDeleteColonne = (index) => {
		form.mutators.remove('fields', index)
	}

	// Affichage des colonnes avec Drag and Drop
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			{popupState.open && <EditDonneeTableauPopin
				{...popupState}
				defaultLanguage={defaultLanguage}
				otherLanguages={otherLanguages}
				submit={handleEditColonne}
				closePopin={handleClose}
			/>}
			<Droppable droppableId="droppable">
				{(provided) => <div {...provided.droppableProps} ref={provided.innerRef} className={classes.draggableDiv}>
					{(fields || []).map((field, index) => <DonneeTableauColonne
						field={field}
						index={index}
						idLanguage={idLanguage}
						handleSelect={() => handleOpenEdit(field, index)}
						deleteColonne={() => handleDeleteColonne(index)}
					/>)}
					{provided.placeholder}
				</div>}
			</Droppable>
			<Actions className={classes.actions}>
				<Button
					variant="contained"
					onClick={handleOpenCreate}
					disabled={(fields || []).length > 7}
				>
					<FormattedMessage id="global.buttons.add" />
				</Button>
			</Actions>
		</DragDropContext>
	)
}

DonneeTableau.propTypes = {
	classes: PropTypes.object,
	defaultLanguage: PropTypes.object,
	otherLanguages: PropTypes.array,
	idLanguage: PropTypes.string
}

const mapStateToProps = state => ({
	idLanguage: getUserLanguage(state)
})

export default compose(
	withStyles(styles),
	connect(mapStateToProps)
)(DonneeTableau)
