import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import { FIELD_TYPE, SELECT_OPTIONS_AVANCEE } from '../utils/documentConstants'
import CancelIcon from '@material-ui/icons/Cancel'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'redux'

const styles = theme => getStyles({
	card: {
		border: `${theme.palette.border} 1px solid`,
		padding: 5,
		marginBottom: 10,
		borderRadius: 5,
		position: 'relative',
		cursor: 'pointer'
	},
	selectedCard: {
		border: `${theme.palette.primary.main} 1px solid`,
		padding: 5,
		marginBottom: 10,
		borderRadius: 5,
		position: 'relative',
		cursor: 'pointer'
	},
	deleteIcon: {
		position: 'absolute',
		top: 5,
		right: 5,
		cursor: 'pointer',
		fontSize: 20
	},
	filterPath: {
		marginRight: 24,
		wordBreak: 'break-word'
	},
	rechercheChamp: {
		fontWeight: 'bold',
		fontSize: 14,
		wordBreak: 'break-all'
	},
	reference: {
		fontSize: 10,
		fontWeight: 'normal'
	}
})

const renderFieldAvanceValue = (rechercheField, intl) => {
	if (rechercheField.type === FIELD_TYPE.SELECT || rechercheField.type === FIELD_TYPE.MULTI_SELECT) {
		return rechercheField.valueLabel.replaceAll(', ', ' | ')
	}
	if (rechercheField.type === FIELD_TYPE.FILE) {
		return intl.formatMessage({ id: `global.${rechercheField.searchType === SELECT_OPTIONS_AVANCEE.INCLUDE ? 'yes' : 'no'}` })
	}

	if (rechercheField.type === FIELD_TYPE.DATE) {
		if (Array.isArray(rechercheField.value)) {
			return [intl.formatDate(rechercheField.value[0], {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric'
			}), intl.formatDate(rechercheField.value[1], {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric'
			})
			]
		} else {
			return intl.formatDate(rechercheField.value, {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric'
			})
		}
	}

	const valueField = rechercheField.value
	if (rechercheField.type === FIELD_TYPE.AMOUNT) {
		if(Array.isArray(valueField)){
			return [`${valueField[0]} EUR`, `${valueField[1]} EUR`]
		}else{
			return `${valueField} EUR`
		}
	}

	if (rechercheField.type === FIELD_TYPE.NUMBER_UNIT) {
		if(Array.isArray(valueField)){
			return [`${valueField[0]} ${rechercheField.unite.label}`, `${valueField[1]} ${rechercheField.unite.label}`]
		}else{
			return `${valueField} ${rechercheField.unite.label}`
		}
	}
	if(Array.isArray(valueField)){
		return [valueField[0], valueField[1]]
	}
	return valueField
}

const AdditionalFiltersApplied = ({ rechercheAvancee, classes, intl, removeField, change, modifyFilterIndex, setModifyFilterIndex, additionalFiltersPopinState, setAdditionalFiltersPopinState, fromPopin }) => {
	return (
		<Grid item xs={12}>
			{rechercheAvancee.map((rechAv, index) => <Grid
				key={`rechAv-${index}`}
				item
				container
				xs={12}
				className={index === modifyFilterIndex ? classes.selectedCard : classes.card}
				onClick={() => {
					if (fromPopin) {
						change('searchKey', rechAv)
						change('searchType', rechAv.searchType)
						if (Array.isArray(rechAv.value)) {
							change('searchValue', rechAv.value[0])
							change('searchValue2', rechAv.value[1])
						} else {
							if(rechAv.type === FIELD_TYPE.MULTI_SELECT || rechAv.type === FIELD_TYPE.SELECT){
								change('searchValue', JSON.parse(rechAv.value))
							} else {
								change('searchValue', rechAv.value)
							}
						}
					} else {
						if (Array.isArray(rechAv.value)) {
							setAdditionalFiltersPopinState({
								...additionalFiltersPopinState,
								open: true,
								initialValues: {
									searchKey: rechAv,
									searchType: rechAv.searchType,
									searchValue: rechAv.value[0],
									searchValue2: rechAv.value[1]
								}
							})
						} else {
							if(rechAv.type === FIELD_TYPE.MULTI_SELECT || rechAv.type === FIELD_TYPE.SELECT){
								setAdditionalFiltersPopinState({
									...additionalFiltersPopinState,
									open: true,
									initialValues: {
										searchKey: rechAv,
										searchType: rechAv.searchType,
										searchValue: JSON.parse(rechAv.value)
									}
								})
							} else {
								setAdditionalFiltersPopinState({
									...additionalFiltersPopinState,
									open: true,
									initialValues: {
										searchKey: rechAv,
										searchType: rechAv.searchType,
										searchValue: rechAv.value
									}
								})
							}
						}
					}
					setModifyFilterIndex(index)
				}}
			>
				<CancelIcon color="primary" onClick={e => {
					removeField(index)
					e.stopPropagation()
				}} className={classes.deleteIcon} />
				<Grid item container xs={12} className={classes.filterPath}>
					<Typography className={classes.reference}>{intl.formatMessage({ id: `enums.documentType.${rechAv.moType}` }).toUpperCase()} {rechAv.path}</Typography>
				</Grid>
				<Grid item container xs={12}>
					<Typography className={classes.rechercheChamp}>{rechAv.label}<br /><FormattedMessage
						id={`document.search.advance.newFilter.field.${rechAv.searchType}`} /> {Array.isArray(rechAv.value) ? <FormattedMessage id={'document.search.advance.newFilter.field.and'} values={{lessThan: renderFieldAvanceValue(rechAv, intl)[0], greaterThan: renderFieldAvanceValue(rechAv, intl)[1]}}/> : renderFieldAvanceValue(rechAv, intl)}
					</Typography>
				</Grid>
			</Grid>)}
		</Grid>
	)
}

export default compose(
	withStyles(styles),
	injectIntl
)(AdditionalFiltersApplied)

