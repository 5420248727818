import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import DonneeAttributeField from './DonneeAttributeField'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '../../../../../../components/form/Select'
import { ATTRIBUTE_DATA_MO_FIELD } from '../../../../../fo/document/utils/documentConstants'
import { getLists } from '../../../../../common/valueList/valueListSelectors'
import { OnChange } from 'react-final-form-listeners'
import { FormSpy } from 'react-final-form'
import { loadListFoByShortcut } from '../../../../../common/valueList/valueListActions'
import { getUserLanguage } from '../../../../../fo/user/services/userSelectors'

const DonneeUnite = ({ sources, userLanguage, intl, loadListFoByShortcut, uniteCodeList }) => {
	const [listDefault, setListDefault] = React.useState([])

	React.useEffect(() => {
		if (uniteCodeList) {
			loadListFoByShortcut(uniteCodeList, userLanguage)
				.then(({ payload }) => setListDefault(payload))
		}
	}, [])

	return (<Grid item container xs={12}>
		<FormSpy subscription={{}}>
			{({ form }) =>
				<OnChange name={`attributeData.${ATTRIBUTE_DATA_MO_FIELD.UNITE_LIST}`}>
					{value => {
						form.change(`attributeData.${ATTRIBUTE_DATA_MO_FIELD.DEFAULT_UNITE}`)
						if (!!value) {
							loadListFoByShortcut(value, userLanguage)
								.then(({ payload }) => setListDefault(payload))
						}
					}}
				</OnChange>
			}
		</FormSpy>
		<Grid item xs={6}>
			<DonneeAttributeField
				name={ATTRIBUTE_DATA_MO_FIELD.UNITE_LIST}
				label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.unite.source' })} *`}
				component={Select}
			>
				{sources.map(s => <MenuItem key={s.id} value={s.shortcut}>
						{`${s.name} (${s.shortcut})`}
					</MenuItem>
				)}
			</DonneeAttributeField>
		</Grid>
		<Grid item xs={6}>
			<DonneeAttributeField
				name={ATTRIBUTE_DATA_MO_FIELD.DEFAULT_UNITE}
				label={`${intl.formatMessage({ id: 'parametrage.donneeForm.fields.unite.default' })} *`}
				component={Select}
			>
				{listDefault.map(unite => <MenuItem key={unite.code} value={unite.code}>
						{unite.label}
					</MenuItem>
				)}
			</DonneeAttributeField>
		</Grid>
	</Grid>)
}

const mapStateToProps = state => ({
	sources: getLists(state).filter(source => !!source.shortcut && source.shortcut.startsWith('UNIT_VALUE')),
	userLanguage: getUserLanguage(state)
})

const actions = {
	loadListFoByShortcut
}

DonneeUnite.propTypes = {
	sources: PropTypes.array,
	userLanguage: PropTypes.string,
	uniteCodeList: PropTypes.string,
	intl: PropTypes.object,
	loadListFoByShortcut: PropTypes.func
}

export default compose(
	injectIntl,
	connect(mapStateToProps, actions)
)(DonneeUnite)
