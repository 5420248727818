import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import { getStyles } from 'isotope-client'
import moment from 'moment/moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Field, useField, useForm } from 'react-final-form'
import { FieldArray, useFieldArray } from 'react-final-form-arrays'
import Input from '../../../../../components/form/Input'
import OverflowTypography from '../../../../../components/OverflowTypography'
import { DEFAULT_LANGUAGE_ID } from '../../../../../utils/constants'
import { ATTRIBUTE_DATA_MO_FIELD, FIELD_TYPE } from '../../utils/documentConstants'
import { getFieldName, normalizeDate, normalizeNumber, parseNumber } from '../../utils/documentUtils'
import DocumentSelectField from './DocumentSelectField'

const styles = theme => getStyles({
	containerTotal: {
		position: 'relative'
	},
	container: {
		overflowX: 'overlay',
		width: 'calc(100% - 45px)'
	},
	containerConsultation: {
		overflowX: 'overlay',
		width: '100%'
	},
	header: {
		backgroundColor: theme.palette.input,
		display: '-webkit-inline-box',
		minWidth: 'calc(100% - 10px)'
	},
	headerColumn: {
		paddingLeft: 10
	},
	headerColumnConsultation: {
		paddingLeft: 10,
		paddingTop: 5,
		paddingBottom: 30,
		minWidth: 190
	},
	headerTextConsultation: {
		color: '#88888A'
	},
	row: {
		display: '-webkit-inline-box',
		marginBottom: 30,
		marginTop: 10,
		width: '100%'
	},
	buttonContainer: {
		width: 45,
		position: 'absolute',
		right: 0,
		bottom: 0,
		marginTop: 13
	},
	buttonPlus: {
		width: 45,
		height: 45,
		padding: 0,
		marginTop: 2
	},
	buttonMoinsContainer: {
		width: 45,
		height: 56,
		marginTop: 15
	},
	buttonMoins: {
		width: 45,
		height: 45,
		marginTop: 5
	},
	consultationBloc: {
		padding: '10px 10px 10px 10px'
	},
	consultationRowContainer: {
		marginBottom: 30,
		marginTop: 10,
		width: '100%'
	},
	consultationRow: {
		borderBottom: '1px solid #f6f6f6',
		display: '-webkit-inline-box',
		width: '100%'
	},
	numberRow: {
		padding: 0,
		margin: 0,
		height: 0
	}
})

// Renvoie le type d'input correspondant au type référencé
const getInputType = (type) => {
	switch (type) {
		case FIELD_TYPE.DATE:
			return 'date'
		default:
			return 'text'
	}
}

const Row = ({ fields, column, selectedLanguage, nbRow, inputNamePrefix }) => {
	const nbRowTab = new Array(nbRow).fill(0)
	return <>
		{(fields.length !== nbRow ? nbRowTab : fields).map((row, i) => {

			let fieldProps = {
				name: `${fields.name}[${i}]`
			}
			if (column.type === FIELD_TYPE.SELECT) {
				return <DocumentSelectField
					key={fieldProps.name}
					consultation={false}
					field={column}
					selectedLanguage={selectedLanguage}
					fieldProps={fieldProps}
				/>
			}

			fieldProps = {
				...fieldProps,
				key: fieldProps.name,
				component: Input,
				type: getInputType(column.type)
			}

			if (column.type === FIELD_TYPE.NUMBER) {
				return <Field
					{...fieldProps}
					parse={parseNumber}
					format={normalizeNumber}
				/>
			} else if (column.type === FIELD_TYPE.DATE) {
				return <Field
					{...fieldProps}
					format={normalizeDate}
				/>
			}

			return <Field {...fieldProps} />
		})}
	</>
}

const renderRowConsultation = (fields, selectedLanguage, initialValues, duplicatedNumber, nbRow, itemSize, classes) => {
	const nbRowTab = new Array(nbRow).fill(0)
	return <>
		{nbRowTab.map((row, i) => {
			return <div key={`value.${i}`} className={classes.consultationRow}>
				{fields.map((field) => {
					if (initialValues[field.reference]) {
						if (field.type === FIELD_TYPE.SELECT) {
							const data = field.attributeData[selectedLanguage] || field.attributeData[DEFAULT_LANGUAGE_ID]
							const fieldProps = {
								name: `${getFieldName(field, duplicatedNumber)}.value`,
								value: initialValues[field.reference].value[i],
								label: data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] && `${data[ATTRIBUTE_DATA_MO_FIELD.NAME].value} ${field.required ? '*' : ''}`
							}
							return <div key={`value.${field.reference}.value[${i}]`} style={{ width: itemSize, minWidth: 190 }}>
								<DocumentSelectField
									tableAffichage
									initialValues={initialValues[field.reference].value[i]}
									consultation
									field={field}
									fieldProps={fieldProps}
									selectedLanguage={selectedLanguage}
								/>
							</div>
						} else if (field.type === FIELD_TYPE.DATE) {
							if (initialValues[field.reference].value[i]) {
								if (selectedLanguage === DEFAULT_LANGUAGE_ID) {
									return <div key={`value.${field.reference}.value[${i}]`} style={{ width: itemSize, minWidth: 190 }}>
										<OverflowTypography className={classes.consultationBloc} variant="body2">
											{moment(initialValues[field.reference].value[i]).format('MM/DD/YYYY')}
										</OverflowTypography>
									</div>
								} else {
									return <div key={`value.${field.reference}.value[${i}]`} style={{ width: itemSize, minWidth: 190 }}>
										<OverflowTypography className={classes.consultationBloc} variant="body2">
											{moment(initialValues[field.reference].value[i]).format('DD/MM/YYYY')}
										</OverflowTypography>
									</div>
								}
							} else {
								return <Typography
									key={`value.${field.reference}.value[${i}]`}
									style={{ width: itemSize, minWidth: 190 }}
									variant="body1"
								/>
							}
						} else {
							if (initialValues[field.reference] && initialValues[field.reference].value[i]) {
								return <div key={`value.${field.reference}.value[${i}]`} style={{ width: itemSize, minWidth: 190 }}>
									<OverflowTypography className={classes.consultationBloc} variant="body2">
										{initialValues[field.reference].value[i]}
									</OverflowTypography>
								</div>
							} else {
								return <div key={`value.${field.reference}.value[${i}]`} style={{ width: itemSize, minWidth: 190 }} />
							}
						}
					} else {
						return <div key={`value.${field.reference}.value[${i}]`} style={{ width: itemSize, minWidth: 190 }}><OverflowTypography
							className={classes.consultationBloc} variant="body2" /></div>
					}
				})}
			</div>
		})
		}
	</>
}

const InternalDocumentTableField = ({ field, nbRow = 1, duplicatedNumber, classes, consultation, initialValues }) => {

	const form = useForm()
	const selectedLanguageField = useField('selectedLanguage')
	const selectedLanguage = selectedLanguageField.input.value
	const fieldArrays = (field && field.fields ? field.fields : []).map((col) => useFieldArray(`fields.${field.reference}.value.${col.reference}.value`))

	if (nbRow === 0) {
		nbRow = 1
	}

	const nbCol = field.fields.length
	const itemSize = `${100 / nbCol}%`
	const buttonTab = new Array(nbRow).fill(0)

	const addRow = () => {
		fieldArrays.forEach(fieldArray => fieldArray.fields.push(''))
		form.change(`${getFieldName(field, duplicatedNumber)}.numberRow`, nbRow + 1)
	}

	const deleteRow = (index) => {
		fieldArrays.forEach(fieldArray => fieldArray.fields.remove(index))
		form.change(`${getFieldName(field, duplicatedNumber)}.numberRow`, nbRow - 1)
	}

	if (consultation) {
		let noEmptyData = false
		field.fields.map((col) => {
			if (!!initialValues[col.reference] && initialValues[col.reference].value.length >= 1 && initialValues[col.reference].value[0] !== '') {
				noEmptyData = true
			}
			return null
		})
		if (noEmptyData) {
			const data = field.attributeData[selectedLanguage] || field.attributeData[DEFAULT_LANGUAGE_ID]
			return <Grid container alignItems="flex-start" className={classes.consultationContainer}>
				<Grid item xs={12}>
					<Typography variant="caption">
						{data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] && `${data[ATTRIBUTE_DATA_MO_FIELD.NAME].value}`}
					</Typography>
				</Grid>
				<Grid container>
					<div className={classes.containerConsultation}>
						<div className={classes.header}>
							{field.fields.map((col, index) => {
								const data = col.attributeData[selectedLanguage]
								return <Grid
									item key={`${field.reference}-header-${index}`}
									className={classes.headerColumnConsultation}
									style={{ width: itemSize }}
								>
									<Typography className={classes.headerTextConsultation} variant="h6">
										{data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] && data[ATTRIBUTE_DATA_MO_FIELD.NAME].value}
									</Typography>
								</Grid>
							})}
						</div>
						<div className={classes.consultationRowContainer}>
							{renderRowConsultation(field.fields, selectedLanguage, initialValues, duplicatedNumber, parseInt(nbRow), itemSize, classes)}
						</div>
					</div>
				</Grid>
			</Grid>
		} else {
			return <></>
		}
	} else {
		return <Grid container className={classes.containerTotal}>
			<div className={classes.container}>
				<div className={classes.numberRow}>
					<Field
						name="numberRow"
						component={Input}
						type="hidden"
						className={classes.numberRow}
					/>
				</div>
				<div className={classes.header}>
					{field.fields.map((col, index) => {
						const data = col.attributeData[selectedLanguage]
						return <Grid item key={`${field.reference}-header-${index}`} className={classes.headerColumn} style={{ width: itemSize, minWidth: 190 }}>
							<Typography variant="h6">
								{data && data[ATTRIBUTE_DATA_MO_FIELD.NAME] && data[ATTRIBUTE_DATA_MO_FIELD.NAME].value}
							</Typography>
						</Grid>
					})}
					<Grid item className={classes.headerColumn} />
				</div>
				<div className={classes.row}>
					{field.fields.map((col, index) =>
						<Grid item key={`${field.reference}-col-${index}`} style={{ width: itemSize, minWidth: 190 }}>
							<FieldArray
								name={`${getFieldName(field, duplicatedNumber)}.value.${col.reference}.value`}
								consultation={consultation}
								initialValues={initialValues}
							>
								{({ fields }) => <Row
									inputNamePrefix={`${field.reference}-col-${index}`}
									selectedLanguage={selectedLanguage}
									column={col}
									fields={fields}
									nbRow={parseInt(nbRow)}
								/>}
							</FieldArray>
						</Grid>)}
				</div>
			</div>
			<Grid item className={classes.buttonContainer}>
				{nbRow > 1 && buttonTab.map((key, index) => <div
					className={classes.buttonMoinsContainer}
					key={`btn-${field.reference}-${key}-${index}`}
				>
					<IconButton className={classes.buttonMoins} onClick={() => deleteRow(index)}>
						<RemoveCircleOutlineIcon color="primary" />
					</IconButton>
				</div>)}
				<IconButton className={classes.buttonPlus} onClick={addRow}><AddCircleOutlineIcon color="primary" /></IconButton>
			</Grid>
		</Grid>
	}
}

const DocumentTableField = (props) => {
	const { field, duplicatedNumber } = props
	return (
		<Field name={`${getFieldName(field, duplicatedNumber)}.value`}>
			{() => (
				<Field name={`${getFieldName(field, duplicatedNumber)}.numberRow`}>
					{({ input: input2 }) => {
						return (
							<InternalDocumentTableField nbRow={parseInt(input2.value) || 0} {...props} />
						)
					}}
				</Field>
			)}
		</Field>
	);
}

DocumentTableField.propTypes = {
	field: PropTypes.object.isRequired,
	nbRow: PropTypes.number,
	duplicatedNumber: PropTypes.number,
	classes: PropTypes.object,
	consultation: PropTypes.bool,
	initialValues: PropTypes.object
}

export default withStyles(styles)(DocumentTableField)
