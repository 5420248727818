import { createSelector } from 'reselect'

export const getLocalState = state => state.tabDocument

export const getTabDocumentData = createSelector(
	getLocalState,
	tabDocument => tabDocument.tabDocumentData
)

export const isChecked = (state, id, isSection) => createSelector(
	getLocalState,
	tabDocument => {
		const checkedState = isSection ? tabDocument.checkedState.sectionsChecked : tabDocument.checkedState.fieldsChecked
		return checkedState[id]
	}
)(state)

export const isCollapsed = (state, id) => createSelector(
	getLocalState,
	tabDocument => {
		const collapsedSelectColumns = tabDocument.tabDocumentCollapsed.collapsedSelectColumns
		return collapsedSelectColumns[id]
	}
)(state)

export const isAdvancedChecked = (state, id) => createSelector(
	getLocalState,
	tabDocument => tabDocument.checkedState.sectionsAdvancedChecked[id]
)(state)

export const getAllFieldsChecked = createSelector(
	getLocalState,
	tabDocument => tabDocument.checkedState.fieldsChecked
)
