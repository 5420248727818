import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import { getStyles } from 'isotope-client'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import Loader from '../../../components/layout/Loader'
import { saveResearchFields } from './services/additionalFiltersApi'
import { getUserLanguage } from '../../fo/user/services/userSelectors'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Button from '../../../components/Button'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import DonneeBloc from '../parametrage/components/donnee/DonneeBloc'
import { getLocalState as getAdditionalFilters, getFields, getSectionSelected } from './services/additionalFiltersSelectors'
import { dispatchSaveResearchFields, dropField, getResearchFields, getResearchSections, postResearchField, postResearchSection, reorderFields, resetFields, setSectionSelected } from './services/additionalFiltersActions'
import EditionResearchSectionPopin from './components/EditionResearchSectionPopin'
import EditionResearchFieldPopin from './components/EditionResearchFieldPopin'
import { colors, DEFAULT_LANGUAGE_ID, FR_LANGUAGE_ID, PL_LANGUAGE_ID, SP_LANGUAGE_ID } from '../../../utils/constants'
import SectionResearchBloc from './components/ResearchSectionBloc'

const styles = theme => getStyles({
	titleContent: {
		borderBottom: `1px solid ${theme.palette.borderColor}`,
		padding: 30
	},
	title: {
		borderBottom: `1px solid ${theme.palette.borderColor}`,
		padding: 30
	},
	plan: {
		borderRight: `1px solid ${theme.palette.borderColor}`
	},
	paper: {
		backgroundColor: 'white',
		height: '75vh'
	},
	sectionSelected: {
		color: colors.secondary
	},
	sections: {
		padding: '22px 12px 12px 32px',
		alignContent: 'space-between',
		maxHeight: 'calc(75vh - 150px)',
		height: 'calc(75vh - 150px)',
		overflowY: 'auto'
	},
	sectionLabel: {
		marginBottom: 12,
		cursor: 'pointer'
	},
	buttonNewSection: {
		textAlign: 'center',
		height: 34
	},
	actions: {
		height: 60,
		borderTop: `1px solid ${theme.palette.borderColor}`,
		alignContent: 'center'
	},
	actionsLabel: {
		fontSize: 16,
		fontWeight: 'bold',
		cursor: 'pointer'
	},
	dragAndDropZone: {
		alignContent: 'space-between',
		maxHeight: 'calc(75vh - 150px)',
		height: 'calc(75vh - 150px)',
		padding: '16px 35px 10px 20px'
	},
	content: {
		maxHeight: 'calc(75vh - 300px)',
		overflowY: 'auto',
		marginBottom: 12
	}
})

const AdditionalFiltersPage = ({
	classes,
	idLanguage,
	sections,
	getResearchSections,
	fields,
	getResearchFields,
	sectionSelected,
	setSectionSelected,
	reorderFields,
	dropField,
	snackSuccess,
	snackError,
	postResearchSection,
	postResearchField,
	resetFields,
	dispatchSaveResearchFields
}) => {

	const loadSections = React.useCallback(() => getResearchSections(idLanguage), [])

	React.useEffect(() => {
		loadSections()
	}, [loadSections])

	React.useEffect(() => {
		sectionSelected && sectionSelected.researchSection && sectionSelected.researchSection.id && getResearchFields(sectionSelected.researchSection.id)
	}, [sectionSelected])

	const setSection = React.useCallback((section) => setSectionSelected(section), [setSectionSelected])

	const onDragEnd = ({ destination, source }) => {
		if (!destination) {
			return
		}
		if (destination.index === source.index) {
			return
		}
		reorderFields(source, destination)
	}

	const closeResearchSectionPopin = () => {
		setEditionResearchSectionPopinState({
			open: false,
			initValues: { published: true }
		})
	}

	const closeResearchFieldPopin = () => {
		setEditionResearchFieldPopinState({
			open: false,
			initValues: { published: true }
		})
	}

	const saveResearchSection = (values) => {
		postResearchSection(values).then(closeResearchSectionPopin)
	}

	const saveResearchField = (values) => {
		postResearchField(values, sectionSelected.researchSection.id)
			.then(closeResearchFieldPopin)
			.catch(e => e.reference && snackError(<FormattedMessage id={e.reference.id} />))
	}

	const [editionResearchSectionPopinState, setEditionResearchSectionPopinState] = React.useState({
		open: false,
		initValues: { published: true }
	})

	const [editionResearchFieldPopinState, setEditionResearchFieldPopinState] = React.useState({
		open: false,
		initValues: { published: true }
	})

	const handleSelectSection = (section) => setEditionResearchSectionPopinState({
		...editionResearchSectionPopinState,
		open: true,
		initValues: {
			name: section.dataResearchSection.find(attribute => attribute.idLanguage === parseInt(DEFAULT_LANGUAGE_ID)) && section.dataResearchSection.find(attribute => attribute.idLanguage === parseInt(DEFAULT_LANGUAGE_ID)).label,
			nameFr: section.dataResearchSection.find(attribute => attribute.idLanguage === parseInt(FR_LANGUAGE_ID)) && section.dataResearchSection.find(attribute => attribute.idLanguage === parseInt(FR_LANGUAGE_ID)).label,
			nameEs: section.dataResearchSection.find(attribute => attribute.idLanguage === parseInt(SP_LANGUAGE_ID)) && section.dataResearchSection.find(attribute => attribute.idLanguage === parseInt(SP_LANGUAGE_ID)).label,
			namePl: section.dataResearchSection.find(attribute => attribute.idLanguage === parseInt(PL_LANGUAGE_ID)) && section.dataResearchSection.find(attribute => attribute.idLanguage === parseInt(PL_LANGUAGE_ID)).label,
			published: section.researchSection.published,
			idResearchSection: section.researchSection.id,
			subSegments: section.assoResearchSectionSubSegment && section.assoResearchSectionSubSegment.map(asso => asso.codeSubSegment).join('|')
		}
	})

	const openPopinResearchField = (field) => {
		setEditionResearchFieldPopinState({
			...editionResearchFieldPopinState,
			open: true,
			initValues: {
				reference: field.reference,
				published: field.published,
				idResearchField: field.id
			}
		})
	}

	const saveResearchFieldsChanges = () => {
		return saveResearchFields(sectionSelected.researchSection.id, fields.map(field => field.id))
			.then(dispatchSaveResearchFields)
			.then(() => snackSuccess(<FormattedMessage id={'additionalFilters.add.snackSuccess'} />))
	}

	return (
		<>
			<Grid container justify="center" className={classes.titleContent}>
				<Typography variant="h3">
					<FormattedMessage id={`additionalFilters.title`} />
				</Typography>
			</Grid>
			<Grid container className={classes.paper}>
				{sections ?
					<>
						<Grid item container xs={6} direction="column" className={classes.plan}>
							<Grid item container justify="center" className={classes.title}>
								<Typography variant="h4">
									<FormattedMessage id="additionalFilters.researchSections" />
								</Typography>
							</Grid>
							<Grid item container className={classes.sections}>
								<Grid item container>
									{
										sections && sections.length > 0 ?
											sections.map(section =>
												<Grid item xs={12} className={classes.sectionLabel} key={section.researchSection.id}>
													<Typography variant="body2" onClick={() => setSection(section)} className={sectionSelected === section ? classes.sectionSelected : undefined}>
														{(section.dataResearchSection.find(data => data.idLanguage === parseInt(idLanguage)) && section.dataResearchSection.find(data => data.idLanguage === parseInt(idLanguage)).label)
														|| (section.dataResearchSection.find(data => data.idLanguage === parseInt(DEFAULT_LANGUAGE_ID)) && section.dataResearchSection.find(data => data.idLanguage === parseInt(DEFAULT_LANGUAGE_ID)).label)}
													</Typography>
												</Grid>
											)
											: <></>
									}
								</Grid>
							</Grid>
							<Grid item className={classes.buttonNewSection}>
								<Button
									color="primary"
									variant="outlined"
									onClick={() => setEditionResearchSectionPopinState({ ...editionResearchSectionPopinState, open: true })}
								>
									<FormattedMessage id="additionalFilters.newSection" />
								</Button>
							</Grid>
						</Grid>
						<Grid item container xs={6} direction="column" className={classes.fields}>
							<Grid item container direction="column" alignItems="center" className={classes.title}>
								<Typography variant="h4">
									<FormattedMessage id="additionalFilters.researchFields" />
								</Typography>
							</Grid>
							<Grid container item className={classes.dragAndDropZone}>
								<Grid item container justifyContent={'flex-end'}>
									{
										Object.keys(sectionSelected).length !== 0 && <SectionResearchBloc section={sectionSelected} handleSelectSection={handleSelectSection} />
									}
									{
										Object.keys(sectionSelected).length !== 0 && fields && fields.length > 0
											? <DragDropContext onDragEnd={onDragEnd}>
												<Grid item container direction="column" wrap="nowrap" className={classes.content}>
													<Droppable droppableId="droppable">
														{(provided) => <div {...provided.droppableProps} ref={provided.innerRef}>
															{fields.map((field, index) =>
																<DonneeBloc
																	key={`fieldBloc-${sectionSelected.researchSection.id}-${field.id}`}
																	field={field.moField}
																	index={index}
																	publishedResearchField={field.published}
																	openPopinEditionDonnee={() => openPopinResearchField(field)}
																	deleteField={() => dropField(field.id)}
																	fromFilters
																/>
															)}
															{provided.placeholder}
														</div>}
													</Droppable>
												</Grid>
											</DragDropContext>
											: <></>
									}
									{
										Object.keys(sectionSelected).length !== 0
											? <Grid container item xs={12} justifyContent={'flex-end'}>
												<Button
													color="primary"
													variant="outlined"
													onClick={() => setEditionResearchFieldPopinState({ ...editionResearchFieldPopinState, open: true })}
												>
													<FormattedMessage id="additionalFilters.addField" />
												</Button>
											</Grid>
											: <></>
									}
								</Grid>
							</Grid>
							<Grid container justify={'flex-end'} className={classes.actions}>
								<Grid item xs={2}>
									<Typography className={classes.actionsLabel} onClick={resetFields}>
										<FormattedMessage id={'additionalFilters.reset'} />
									</Typography>
								</Grid>
								<Grid item xs={2}>
									<Typography className={classes.actionsLabel} style={{ color: colors.primary }} onClick={() => {
										saveResearchFieldsChanges()
									}}>
										<FormattedMessage id={'additionalFilters.save'} />
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</>
					: <Loader />
				}
			</Grid>
			<EditionResearchSectionPopin
				{...editionResearchSectionPopinState}
				closePopin={closeResearchSectionPopin}
				saveSection={saveResearchSection}
			/>
			{
				sectionSelected && sectionSelected.researchSection && sectionSelected.researchSection.id
					? <EditionResearchFieldPopin
						{...editionResearchFieldPopinState}
						closePopin={closeResearchFieldPopin}
						saveField={saveResearchField}
					/>
					: <></>
			}
		</>
	)
}

AdditionalFiltersPage.propTypes = {
	classes: PropTypes.object,
	idLanguage: PropTypes.string,
	sections: PropTypes.array,
	getResearchSections: PropTypes.func,
	fields: PropTypes.array,
	getResearchFields: PropTypes.func,
	reorderFields: PropTypes.func,
	dropField: PropTypes.func,
	snackSuccess: PropTypes.func,
	postResearchSection: PropTypes.func,
	postResearchField: PropTypes.func,
	resetFields: PropTypes.func,
	dispatchSaveResearchFields: PropTypes.func
}

const mapStateToProps = (state) => {
	const sections = getAdditionalFilters(state).sections
	const fields = getFields(state)
	const sectionSelected = getSectionSelected(state)
	return {
		idLanguage: getUserLanguage(state),
		sections,
		fields,
		sectionSelected
	}
}

const actions = {
	getResearchSections,
	getResearchFields,
	reorderFields,
	dropField,
	postResearchSection,
	postResearchField,
	resetFields,
	setSectionSelected,
	dispatchSaveResearchFields
}

export default compose(
	connect(mapStateToProps, actions),
	injectSnackActions,
	withStyles(styles)
)(AdditionalFiltersPage)
