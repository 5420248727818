import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FormattedMessage } from 'react-intl'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import Popin from '../../../../../../components/Popin'
import * as documentActions from '../../../services/documentSelectors'
import { closeFolderPopin, clearFolderPopin } from '../../../services/documentActions'
import { getFieldFileContent, rechercheDrivePopin } from '../../../services/documentApi'
import DriveBrowseFolder from './DriveBrowseFolder'
import { DRIVE_UPLOAD_TYPE } from '../../../utils/documentConstants'
import CircularProgress from '@material-ui/core/CircularProgress'
import FolderCard from '../../card/FolderCard'
import FileCard from '../../card/FileCard'
import Grid from '@material-ui/core/Grid'
import EmptyResult from '../../../../../../components/layout/EmptyResult'
import { useField, useForm } from 'react-final-form'
import Fab from '@material-ui/core/Fab'
import { injectIntl } from 'react-intl'
import { Field, reduxForm } from 'redux-form'
import SearchIcon from '@material-ui/icons/Search'
import Input from '../../../../../../components/form/Input'
import { colors } from '../../../../../../utils/constants'
import Paper from '@material-ui/core/Paper'

const styles = theme => getStyles({
	loader: {
		position: 'absolute',
		top: '50%',
		left: '50%'
	},
	browser: {
		height: '100%',
		width: 300,
		overflowX: 'hidden',
		overflowY: 'auto',
		borderRight: `${theme.palette.border} solid 1px`,
		paddingRight: 10
	},
	viewer: {
		height: '100%',
		width: 'calc(100% - 300px)',
		overflowX: 'auto',
		paddingLeft: 10
	},
	popinContent: {
		display: 'flex',
		overflow: 'hidden'
	},
	viewFile: {
		paddingRight: 24,
		cursor: 'pointer',
		marginBottom: 10
	},
	emptyView: {
		width: 'auto',
		paddingTop: 0,
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(+25%, -50%)'
	},
	emptyViewImg: {
		height: '10vw'
	},
	form: {
		display: 'flex',
		width: '100%'
	},
	inputHeader: {
		marginTop: 2,
		marginLeft: 8,
		flex: 1,
		fontSize: '14px'
	},
	fabHeader: {
		marginTop: 16,
		height: 40,
		width: 40,
		boxShadow: 'unset',
		color: colors.text,
		backgroundColor: colors.tertiary
	},
	iconButton: {
		padding: 10
	},
	rootHeader: {
		padding: 0,
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		height: 40,
		borderRadius: '30px',
		overflow: 'hidden',
		backgroundColor: colors.background,
		boxShadow: 'none'
	}
})

// Fonction utilitaire pour construire le dictionnaire de folder
const reduceFolder = (folderList) => folderList.reduce((acc, current) => ({
	...acc,
	[current.id]: current,
	...reduceFolder((current.content || []))
}), {})


const DriveFolderPopin = ({ folderPopin, closeFolderPopin, clearFolderPopin, classes, intl, handleSubmit }) => {
	// Contient l'arbo initial
	const [root, setRoot] = React.useState({})
	// Contient l'arbo Filter au besoin par la recherche
	const [arbo, setArbo] = React.useState({})
	// Le dico ( la partie gauche de la popin ) du folder selectionné
	const [dictionnary, setDictionnary] = React.useState({})
	// Contient la liste des id de tout les folder de l'arbo pour la rechercher
	const [listIdFolder, setListIdFolder] = React.useState({})
	// Savoir si c'est un affichage de recherche ou non
	const [isSearch, setIsSearch] = React.useState(false)
	// Pour affichier le loader a affichage de la popin ou de la recherche
	const [loading, setLoading] = React.useState(false)
	// Contient id fu folder actuellement selectionné
	const [selectedFolder, setSelectedFolder] = React.useState(undefined)

	const form = useForm()
	// Field du formulaire
	const formField = useField(folderPopin.fieldName)

	// Deleted content folder
	const deletedContentFolderField = useField('deletedFolderContent')
	const deletedContentFolder = (deletedContentFolderField && deletedContentFolderField.input.value) || []

	React.useEffect(() => {
		return () => {
			clearFolderPopin()
		}
	}, [])

	React.useEffect(() => {
		if (!!folderPopin.fieldId) {
			setLoading(true)
			getFieldFileContent(folderPopin.fieldId)
				.then(result => {
					// Set de la root
					const resultWithRoot = {
						content: result.content,
						id: 'ROOT',
						name: folderPopin.fieldLabel,
						defaultOpen: true
					}
					setListIdFolder(result.idListFolder)
					setArbo(resultWithRoot)
					setRoot(resultWithRoot)
					setDictionnary(reduceFolder([resultWithRoot]))
					setSelectedFolder(folderPopin.selectedFolderOnOpen)
					setLoading(false)
				})
				.catch(() => {
					setRoot({})
					setDictionnary({})
					setLoading(false)
				})
		}
	}, [folderPopin.fieldId])

	React.useEffect(() => {
		setSelectedFolder(folderPopin.selectedFolderOnOpen)
	}, [folderPopin.selectedFolderOnOpen])

	const handleClose = () => {
		closeFolderPopin()
	}

	const deleteContent = (item) => {
		const filesField = formField && formField.input.value
		const files = (filesField && filesField.value) || []

		// On regarde s'il s'agit d'un dossier root
		const indexRoot = files.findIndex(file => file.id === item.id)
		if (indexRoot !== -1) {
			// Dans ce cas, on le supprime du field
			const newFiles = [...files]
			newFiles.splice(indexRoot, 1)
			form.change(`${folderPopin.fieldName}.value`, newFiles)

			// Dans le cas où il n'y a plus de fichier, on ferme la popin
			if (newFiles.length === 0) {
				handleClose()
			}
		}

		// On met à jour ensuite les contenus en local
		form.change(`deletedFolderContent`, [...deletedContentFolder, { id: item.id, isRoot: indexRoot !== -1 }])
	}

	const rechercherDrive = (values) => {
		if(!!values.queryRechercheDrive){
			setLoading(true)
			setArbo({})
			setIsSearch(false)
			rechercheDrivePopin(folderPopin.fieldId,values, listIdFolder)
				.then(handleRechercheDriveResults)
				.catch(() => {
					setDictionnary(reduceFolder([""]))
					setSelectedFolder(folderPopin.selectedFolderOnOpen)
					setLoading(false)
				})
		} else {
			// on reset l'arbo
			setSelectedFolder(folderPopin.selectedFolderOnOpen)
			setArbo(root)
			setDictionnary(reduceFolder([root]))
		}
	}

	const handleRechercheDriveResults = data => {
		const temp = filterTreeSearch(root, data)
		setArbo(temp)
		setDictionnary(reduceFolder([temp]))
		setSelectedFolder(folderPopin.selectedFolderOnOpen)
		setIsSearch(true)
		setLoading(false)
	}


	const filterTreeSearch = (folder, resulatSearch) => {
		let copieFolder = Object.assign({}, folder)
		if(folder.content){
			let contentFiltred = folder.content.sort((a, b) => (a.type === b.type) ? 0 : a.type === DRIVE_UPLOAD_TYPE.FOLDER ? -1 : 1).map(item => {
					if (item.type === DRIVE_UPLOAD_TYPE.FOLDER) {
						const itemFolderCheck = filterTreeSearch(item, resulatSearch)
						if(itemFolderCheck.content && itemFolderCheck.content.length > 0){
							return itemFolderCheck
						}
					} else {
						if(Object.keys(resulatSearch).includes(item.id)){
							return item
						}
					}
					return undefined
				}
			)
			copieFolder.content = contentFiltred.filter(content => content !== undefined)
		}
		return copieFolder
	}


	const QUERY_RECHERCHE_DRIVE = 'queryRechercheDrive'

	// Contenu visible en filtrant le contenu supprimé localement (avant enregistrement serveur)
	const viewContentList = !!dictionnary[selectedFolder] && dictionnary[selectedFolder].content.filter(item => deletedContentFolder.findIndex(content => content.id === item.id) === -1)
	return <Popin
		paperStyle={{
			height: 600
		}}
		contentStyleName={classes.popinContent}
		maxWidth="lg"
		open={folderPopin.open}
		actions={[]}
		title={<FormattedMessage id="document.folderPopin.title" values={{ fieldName: folderPopin.fieldLabel }} />}
		onClose={handleClose}
		content={<>
			{loading ?
				<CircularProgress className={classes.loader} />
				:
				<>
					<div className={classes.browser}>
						<Paper className={classes.rootHeader} elevation={1}>
							<form className={classes.form} onSubmit={handleSubmit(rechercherDrive)}>
								{/*Champ de recherche*/}
								<div className={classes.inputHeader} >
									<Field
										component={Input}
										name={QUERY_RECHERCHE_DRIVE}
										placeholder={intl.formatMessage({ id: 'document.field.drive.formPlaceHolder' })}
										className={classes.inputHeader}
									/>
								</div>

								{/*Bouton de recherche*/}
								<Fab aria-label="Search" className={classes.fabHeader} type="submit">
									<SearchIcon />
								</Fab>
							</form>
						</Paper>
						<DriveBrowseFolder
							key="browse-folder-ROOT"
							folder={arbo}
							level={0}
							deletedContentFolder={deletedContentFolder}
							selectedFolder={selectedFolder}
							setSelectedFolder={setSelectedFolder}
						/>
					</div>
					<div className={classes.viewer}>
						{(!!selectedFolder && !!dictionnary[selectedFolder]) && viewContentList.length > 0 ?
							<Grid container>
								{viewContentList.sort((a, b) => (a.type === b.type) ? 0 : a.type === DRIVE_UPLOAD_TYPE.FOLDER ? -1 : 1).map(item =>
									<Grid item container xs={6} key={`folder-viewer-${item.id}`} className={classes.viewFile}>
										{item.type === DRIVE_UPLOAD_TYPE.FOLDER ?
											<FolderCard
												folder={item}
												canDelete={folderPopin.isEdition}
												onClick={() => setSelectedFolder(item.id)}
												onDelete={() => deleteContent(item)}
											/>
											:
											<FileCard
												file={item}
												canDelete={folderPopin.isEdition}
												onDelete={() => deleteContent(item)}
											/>
										}
									</Grid>
								)}
							</Grid>
							:
								(!!selectedFolder ?
									<>
										{isSearch ?
											<EmptyResult
												className={classes.emptyView}
												imgClassName={classes.emptyViewImg}
												message={<FormattedMessage id="document.folderPopin.emptySearch"/>}
											/>
											:
											<EmptyResult
												className={classes.emptyView}
												imgClassName={classes.emptyViewImg}
												message={<FormattedMessage id="document.folderPopin.emptyFolder"/>}
											/>
										}
									</>
								:
									<></>
							)
							}
					</div>
				</>
			}
		</>}
	/>
}

const mapStateToProps = (state) => ({
	folderPopin: documentActions.getFolderPopin(state)
})

const actions = {
	closeFolderPopin,
	clearFolderPopin
}

DriveFolderPopin.propTypes = {
	folderPopin: PropTypes.object,
	closeFolderPopin: PropTypes.func,
	clearFolderPopin: PropTypes.func,
	classes: PropTypes.object
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'searchDriverPopin'
	}),
	injectIntl,
	withStyles(styles)
)(DriveFolderPopin)
