import { fetchFactory } from 'isotope-client'

export const getTabDocuments = (nbPage, params, isConsultation, targetLanguage, selectedColumns) => fetchFactory(`/document-table/${nbPage}`, {
	method: 'POST',
	body: JSON.stringify({ ...params, isConsultation, targetLanguage, selectedColumns })
})

export const updateField = (idRow, idField, newValue, idLanguage, codeColumn, type, unite, newValueArray) => fetchFactory(`/document-table/update-field`, {
	method: 'POST',
	body: JSON.stringify({ idContent: idRow, idField, newValue: newValue ? newValue : '', idLanguage, codeColumn, type, unite, newValueArray })
})

export const getFieldOptions = (idField, idContent, valueListShortcut, idLanguage) => fetchFactory(`/document-table/getFieldOptions`, {
	method: 'POST',
	body: JSON.stringify({ idField, idContent, valueListShortcut, idLanguage })
})

export const shareRecherche = (values) => fetchFactory('/document-table/partage', {
	method: 'POST',
	body: JSON.stringify(values)
})

export const getRechercheById = (id, onEdition) => fetchFactory(`/document-table/getSearch/${id}?onEdition=${onEdition}`)
