import { fetchFactory } from 'isotope-client'

/**
 * Récupération du nombre de documents publiés
 * @returns {*}
 */
export const getCountPublishedDocument = () => fetchFactory('/document/published')

/**
 * Récupération du nombre de documents à valider/publier
 * @returns {*}
 */
export const getCountToValidateDocument = () => fetchFactory('/document/to_validate')


export const getCountToValidateAccess = () => fetchFactory('/utilisateurs/to_validate')
