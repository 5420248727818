import { GET_USER_SUCCESS, LOGIN_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH } from './loginActions'
import { removeLocalUser as removeLocalUserApi } from './loginApi'
import { LOCAL_STORAGE_ITEM } from '../../../../utils/constants'

export default function (localStorage) {
	const token = localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN)
	const refreshToken = localStorage.getItem(LOCAL_STORAGE_ITEM.REFRESH_TOKEN)
	const accessToken = localStorage.getItem(LOCAL_STORAGE_ITEM.ACCESS_TOKEN)
	const expiresIn = localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_IN)
	const expiresTimeStamp = localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_TIMESTAMP)


	const initialState = {
		token,
		refreshToken,
		accessToken,
		expiresIn,
		expiresTimeStamp,
		authenticated: false
	}

	return (state = initialState, action = {}) => {
		switch (action.type) {
		case LOGIN_SUCCESS:
		case TOKEN_REFRESH:
			return {
				...state,
				token: action.token,
				refreshToken: action.refreshToken,
				accessToken: action.accessToken,
				expiresIn: action.expiresIn,
				expiresTimeStamp: action.expiresTimeStamp,
				authenticated: true
			}
		case GET_USER_SUCCESS: {
			const user = action.user
			return {
				...state,
				...user
			}
		}
		case LOGOUT_SUCCESS:
			removeLocalUserApi()
			return {}
		default:
			return state
		}
	}
}
