import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import { getStyles } from 'isotope-client'
import mapError from './mapError'
import classnames from 'classnames'

const styles = () => getStyles({
	input: {
		width: '100%'
	},
	container: {
		paddingRight: 10,
		paddingLeft: 10,
		marginBottom: 15
	}
})

const FormInput = (Component) => ({ input: { ...inputProps }, meta: { error, ...otherMeta }, containerClassName, classes, ...otherProps }
) => {
	return <div className={classnames(classes.container, containerClassName)}>
		<Component
			{...otherProps}
			{...inputProps}
			className={classes.input}
			variant="filled"
			helperText={((!otherMeta || otherMeta.touched) && error) || otherProps.error}
			meta={otherMeta}
			error={((!otherMeta || otherMeta.touched) && !!error) || !!otherProps.error}
			onPaste={(e) => {
				// Fix encodage lors du copier/coller
				const formattedText = e.clipboardData.getData('text/plain').replace((/\\t/g), '')
				e.preventDefault()
				document.execCommand('insertText', false, formattedText);
			}}
		/>
	</div>
}

FormInput.defaultProps = {
	containerClassName: ''
}

FormInput.propTypes = {
	input: PropTypes.object,
	meta: PropTypes.object,
	classes: PropTypes.object
}

export default compose(
	mapError,
	withStyles(styles)
)(FormInput(TextField))

