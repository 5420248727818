import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { getStyles } from 'isotope-client'
import classnames from 'classnames'

const styles = () => getStyles({
	container: {
		width: 1340,
		backgroundColor: 'transparent',
		margin: 'auto',
		marginTop: 35
	}
})

const CenteredLayout = ({ classes, children, className }) => {
	return (<Paper elevation={0} className={classnames(classes.container, className)}>
		{children}
	</Paper>)
}

CenteredLayout.defaultProps = {
	className: ''
}

CenteredLayout.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object,
	children: PropTypes.node
}

export default withStyles(styles)(CenteredLayout)
