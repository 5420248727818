import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { push } from 'connected-react-router'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { formValueSelector, getFormValues, reduxForm } from 'redux-form'
import EmptyResult from '../../../components/layout/EmptyResult'
import Loader from '../../../components/layout/Loader'
import SearchResultHeader from '../../../components/layout/SearchResultHeader'
import Link from '../../../components/Link'
import AccessChecker, { hasAccess } from '../../../components/security/AccessChecker'
import checkRole from '../../../components/security/RoleChecker'
import {
	CODE_SUJET_CORRESPONDANT_FORMULAIRE,
	PROFILS,
	REQUEST,
	ROLE_WEIGHT,
	USER_PREFERENCES,
	VALUE_LIST_SHORTCUTS
} from '../../../utils/constants'
import { readPreferenceList } from '../../../utils/utils'
import * as listesSelectors from '../../bo/listes/listesSelectors'
import {
	injectBusinessSegmentList,
	injectMarketList,
	injectPaysList,
	injectZoneList
} from '../../common/valueList/ValueListInjector'
import RefuserPopin from './component/RefuserPopin'
import UsersForm from './component/UsersForm'
import ValiderPopin from './component/ValiderPopin'
import { getUsers, getUsersList } from './services/usersApi'
import * as userSelectors from './services/userSelectors'
import UserCard from './UserCard'
import {exportRecherche} from './services/userActions'

const styles = theme => getStyles({
	search: {
		margin: '10px 0px 0px 10px',
		minHeight: 'calc(100vh - 150px)',
		padding: 10,
		position: 'relative'
	},
	h2: {
		fontWeight: 'lighter'
	},
	voirplus: {
		display: 'block',
		margin: 'auto'
	},
	titleSearch: {
		marginBottom: 15,
		paddingLeft: 10,
		fontSize: 18
	},
	blocCorrespondant: {
		position: 'absolute',
		bottom: 20,
		left: '50%',
		transform: 'translateX(-50%)'
	},
	textCorrespondant: {
		textAlign: 'center'
	},
	linkCorrespondant: {
		color: theme.palette.primary.main,
		marginTop: 10
	},
	noResultContainer: {
		position: 'relative',
		height: 'calc(100vh - 300px)',
		margin: '10px 10px 10px 5px',
		backgroundColor: theme.palette.empty,
		minHeight: 590
	},
	noResult: {
		position: 'absolute',
		top: '45%',
		transform: 'translateY(-50%) translateX(-50%)',
		left: '50%'
	},
	container: {
		margin: '18px 10px 6px 10px',
		height: 36,
		fontSize: 24,
		alignItems: 'center'
	},
	searchResultContainer: {
		paddingLeft: 7
	}
})

const ZONE = 'zone'

const UsersPage = ({
	classes,
	zoneList,
	handleSubmit,
	paysList,
	businessSegmentList,
	marketList,
	isCorrespondant,
	demandesScreen,
	correspondantsScreen,
	initialValues,
	goToModification,
	change,
	formValues,
	isAdmin,
	exportRecherche,
	snackSuccess
}) => {
	const [users, setUsers] = React.useState([])
	const [loading, setLoading] = React.useState(false)
	const [anchorEl, setAnchorEl] = React.useState(undefined)
	const [anchorElTris, setAnchorElTris] = React.useState(undefined)
	const [anchorElMore, setAnchorElMore] = React.useState(undefined)
	const [pageNo, setPageNo] = React.useState(0)
	const [totalElements, setTotalElements] = React.useState(null)
	const [aValider, setAValider] = React.useState(undefined)
	const [openValider, setOpenValider] = React.useState(false)
	const [openRefuser, setOpenRefuser] = React.useState(false)
	const [userClick, setUserClick] = React.useState({})
	const [params, setParams] = React.useState([])
	const [retire, setRetire] = React.useState(false)

	React.useEffect(() => {
		getUser(pageNo, initialValues)
	}, [])

	const getUser = (pageNo, params) => {
		setParams(params)
		setLoading(true)
		getUsers(pageNo, params)
			.then(data => {
				setUsers(data.content)
				setLoading(false)
				setTotalElements(data.totalElements)
			})
			.catch(() => setLoading(false))
	}

	const voirPlus = () => {
		setPageNo(pageNo + 1)
		getUsers(pageNo + 1, params)
			.then(data => {
				setUsers(users.concat(data.content))
			})
	}

	const rechercher = (values) => {
		setPageNo(0)
		getUser(0, values)
	}

	const rechercherTris = (valuesTris) => {
		setAnchorElTris(null)
		setAnchorElMore(null)
		setPageNo(0)
		getUser(0, { ...formValues, sortFields: valuesTris })
	}

	const valider = () => {
		setAnchorEl(null)
		setOpenValider(true)
	}

	const refuser = () => {
		setAnchorEl(null)
		setOpenRefuser(true)
	}

	const getUserList = () => {
		getUsersList(users.length, params)
			.then(data => {
				setUsers(data)
				setTotalElements(data.totalElements)
			})
	}

	const exportSearch = () => {
		setAnchorElMore(null)
		return exportRecherche({
			...params,
			viewFields: {
				fieldsAdvance: params.fieldsAdvance
			}
		})
			.then(() => snackSuccess(<FormattedMessage id="document.search.export.sent" />))
	}

	const renderResults = () => {
		if (!users || users.length === 0) {
			return <Grid item sm={12} className={classes.noResultContainer}>
				<EmptyResult className={classes.noResult} message={<FormattedMessage id="users.noResults" />} />
			</Grid>
		}
		return (<>
			{users.map(element => {
					const userRole = element && element.roles && element.roles.length > 0 && element.roles[0]

					// Le menu n'est pas visible dans deux cas:
					// - le screen correspondant
					// - l'utilisateur est correspondant et l'utilisateur est admin ou correspondant
					return <UserCard
						user={element}
						setAnchorEl={setAnchorEl}
						setAValider={setAValider}
						setUserClick={setUserClick}
						key={element.id}
						displayMenu={!(correspondantsScreen || (isCorrespondant && userRole && (userRole.id === `${PROFILS.CORRESPONDANT.id}` || userRole.id === `${PROFILS.ADMIN.id}`)))}
					/>
				}
			)}
			{(totalElements > users.length) &&
			<Button key="voirPlus" color="primary" onClick={voirPlus} className={classes.voirplus}>
				<FormattedMessage id="global.buttons.voirPlus" />
			</Button>}
		</>)
	}

	let titlePage = 'listeUsers'
	if (demandesScreen) {
		titlePage = 'listeDemandes'
	} else if (correspondantsScreen) {
		titlePage = 'listeCorrespondants'
	}

	return (
		<>
			<Grid container>
				<Grid item xs={12} className={classes.container}>
					<Typography variant="h2" className={classes.h2}><FormattedMessage
						id={`users.${titlePage}`} /></Typography>
				</Grid>
				<Grid item sm={3}>
					<Paper className={classes.search}>
						<Typography variant="h2" className={classes.titleSearch}><FormattedMessage id="users.title" /></Typography>
						<UsersForm
							demandesScreen={demandesScreen}
							correspondantsScreen={correspondantsScreen}
							isCorrespondant={isCorrespondant}
							zoneList={zoneList}
							paysList={paysList}
							isAdmin={isAdmin}
							marketList={marketList}
							businessSegmentList={businessSegmentList}
							onSubmit={handleSubmit(rechercher)}
							change={change}
						/>

						{correspondantsScreen && <Grid item sm={12} container justify="center" className={classes.blocCorrespondant}>
							<Grid item sm={12}>
								<Typography variant="body1" className={classes.textCorrespondant}><FormattedMessage
									id="users.demandeCorrespondant" /></Typography>
							</Grid>
							<Grid item className={classes.linkCorrespondant}>
								<Link to="/contact-form" state={{ initialSujet: CODE_SUJET_CORRESPONDANT_FORMULAIRE }}><FormattedMessage
									id="users.click" /></Link>
							</Grid>
						</Grid>}
					</Paper>
				</Grid>
				{loading ? <Grid item sm={9} className={classes.searchResultContainer}><Loader minHeight={false} /></Grid> : <Grid item sm={9} className={classes.searchResultContainer}>
					<SearchResultHeader
						title={<FormattedMessage id="global.resultTitle" values={{ results: totalElements }} />}
						setAnchorElTris={setAnchorElTris}
						setAnchorElMore={setAnchorElMore}
					/>
					{renderResults()}
				</Grid>
				}
			</Grid>
			<Menu
				id="menuTris"
				anchorEl={anchorElTris}
				open={Boolean(anchorElTris)}
				onClose={() => setAnchorElTris(null)}
			>
				<MenuItem onClick={() => rechercherTris([{ reference: 'lastname', order: 'ASC' }])}><FormattedMessage
					id="global.tris.a_z" /></MenuItem>
				<MenuItem onClick={() => rechercherTris([{ reference: 'lastname', order: 'DESC' }])}><FormattedMessage
					id="global.tris.z_a" /></MenuItem>
				<AccessChecker access={PROFILS.ADMIN}>
					<MenuItem onClick={() => rechercherTris([{ reference: 'date_connexion', order: 'DESC' }])}><FormattedMessage
						id="global.tris.date_connexion_desc" /></MenuItem>
					<MenuItem onClick={() => rechercherTris([{ reference: 'date_connexion', order: 'ASC' }])}><FormattedMessage
						id="global.tris.date_connexion_asc" /></MenuItem>
				</AccessChecker>
			</Menu>
			<Menu
				id="menuMore"
				anchorEl={anchorElMore}
				open={Boolean(anchorElMore)}
				onClose={() => setAnchorElMore(null)}
			>
				<AccessChecker access={[PROFILS.ADMIN]}>
					<MenuItem disabled={totalElements === 0} onClick={exportSearch}><FormattedMessage id="global.more.export_result" /></MenuItem>
				</AccessChecker>
			</Menu>
			<Menu
				id="menuUser"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
			>
				<AccessChecker access={PROFILS.ADMIN}>
					<MenuItem onClick={() => goToModification(userClick.id)}><FormattedMessage
						id="users.actions.modify" /></MenuItem>
				</AccessChecker>
				{aValider && <MenuItem onClick={valider}><FormattedMessage id="users.actions.valid" /></MenuItem>}
				{aValider && <MenuItem
					onClick={() => {
						refuser()
						setRetire(false)
					}}
				><FormattedMessage id="users.actions.refuse" /></MenuItem>}
				{(!aValider && userClick.refuse === 'true') &&
				<MenuItem onClick={valider}><FormattedMessage id="users.actions.donDroits" /></MenuItem>}
				{(!aValider && userClick.refuse === 'false' && userClick.role === `${PROFILS.MEMBRE.id}`) && <MenuItem
					onClick={() => {
						refuser()
						setRetire(true)
					}}
				><FormattedMessage id="users.actions.delDroits" /></MenuItem>}
			</Menu>
			<ValiderPopin open={openValider} closePopup={() => setOpenValider(false)} getUsersList={getUserList}
			              userName={userClick.name} idUser={userClick.id} />
			<RefuserPopin open={openRefuser} closePopup={() => setOpenRefuser(false)} getUsersList={getUserList}
			              userName={userClick.name} idUser={userClick.id} retirer={retire} />
		</>
	)
}

const FORM_NAME = 'usersSearch'

const getDefaultMapStateToProps = (state) => {
	const user = userSelectors.getUser(state)
	const zoneSelected = formValueSelector(FORM_NAME)(state, ZONE)
	const formValues = getFormValues(FORM_NAME)(state)
	// Si l'utilisateur est correspondant, on fige la zone
	const preferences = userSelectors.getPreferences(state)
	const authorities = userSelectors.getAuthorities(state)
	const isCorrespondant = hasAccess(PROFILS.CORRESPONDANT, authorities)
	const isAdmin = hasAccess(PROFILS.ADMIN, authorities)

	return {
		paysList: listesSelectors.getFilteredListsPays(state, zoneSelected, VALUE_LIST_SHORTCUTS.PAYS),
		formValues,
		isCorrespondant,
		isAdmin,
		initialValues: {
			name: '',
			zone: isCorrespondant ? preferences[USER_PREFERENCES.ZONE] || 0 : 0,
			pays: [0],
			paysOrigine: hasAccess(PROFILS.CORRESPONDANT, userSelectors.getAuthorities(state)) ? readPreferenceList(user, USER_PREFERENCES.FI_PAYS) : [0],
			profil: 0,
			demande: 0,
			connecte: 0,
			segment: [0],
			marche: [0]
		}
	}
}

const getDefaultCompose = () => compose(
	injectZoneList,
	injectPaysList,
	injectMarketList,
	injectBusinessSegmentList,
	withStyles(styles)
)

const mapStateToProps = (state) => getDefaultMapStateToProps(state)

const actions = {
	goToModification: (idUser) => dispatch => dispatch(push(`/utilisateurs/${idUser}`)),
	exportRecherche
}


UsersPage.propTypes = {
	classes: PropTypes.object,
	zoneList: PropTypes.array,
	paysList: PropTypes.array,
	isCorrespondant: PropTypes.bool,
	correspondantsScreen: PropTypes.bool,
	demandesScreen: PropTypes.bool,
	handleSubmit: PropTypes.func,
	snackSuccess: PropTypes.func,
	goToModification: PropTypes.func
}

// Page demandes à valider
const demandesMapStateToProps = (state) => {
	const commonMapState = getDefaultMapStateToProps(state)
	return {
		...commonMapState,
		demandesScreen: true,
		initialValues: {
			...commonMapState.initialValues,
			profil: PROFILS.VISITEUR.id,
			demande: REQUEST.TO_VALIDATE
		}
	}
}

export const DemandesAccesPage = compose(
	checkRole(ROLE_WEIGHT.ROLE_CORRESPONDANT),
	getDefaultCompose(),
	connect(demandesMapStateToProps, actions),
	reduxForm({
		form: FORM_NAME
	})
)(UsersPage)

// Page liste correspondants
const correspondantsMapStateToProps = (state) => {
	const commonMapState = getDefaultMapStateToProps(state)
	return {
		...commonMapState,
		correspondantsScreen: true,
		initialValues: {
			...commonMapState.initialValues,
			zone: 0,
			pays: [0],
			paysOrigine: [0],
			profil: PROFILS.CORRESPONDANT.id
		}
	}
}

export const CorrespondantsPage = compose(
	getDefaultCompose(),
	connect(correspondantsMapStateToProps, actions),
	reduxForm({
		form: FORM_NAME,
		enableReinitialize: true
	})
)(UsersPage)

// Page par défaut utilisateurs
export default compose(
	checkRole(ROLE_WEIGHT.ROLE_CORRESPONDANT),
	getDefaultCompose(),
	connect(mapStateToProps, actions),
	reduxForm({
		form: FORM_NAME
	})
)(UsersPage)
