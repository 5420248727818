import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import Typography from '@material-ui/core/Typography'
import Popin from '../../../../components/Popin'
import { refuserDemande } from '../services/usersApi'
import { FormattedMessage } from 'react-intl'
import { Field, reduxForm } from 'redux-form'
import Input from '../../../../components/form/Input'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'

const styles = () => getStyles({
	message: {
		marginBottom: 10,
		paddingLeft: 10
	}
})

const validate = (values) => {
	const errors = {}
	if (!values.justification) {
		errors.justification = { id: 'global.errors.obligatoire' }
	}
	return errors
}

const RefusForm = ({ onSubmit }) => (
	<form onSubmit={onSubmit}>
		<Field
			id="justification"
			name="justification"
			label={<FormattedMessage id="users.popin.refus.field" />}
			component={Input}
		/>
	</form>
)

const FORM_NAME = 'refuserPopinForm'

const RefuserPopin = ({ open, closePopup, userName, idUser, snackSuccess, snackError, retirer, getUsersList, invalid, handleSubmit, destroy, classes }) => {
	const refuser = (values) => {
		return refuserDemande(idUser, values.justification)
			.then(() => {
				handleClose()
				snackSuccess(<FormattedMessage id="users.popin.refus.success" />)
				getUsersList()
			})
			.catch(() => snackError(<FormattedMessage id="users.popin.refus.error" />))
	}

	const handleClose = () => {
		destroy(FORM_NAME)
		closePopup()
	}

	const actions = [
		<Button
			color="inherit"
			variant="outlined"
			key="fermer"
			onClick={handleClose}
		>
			<FormattedMessage id="global.buttons.annuler" />
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			type="submit"
			disabled={invalid}
			onClick={handleSubmit(refuser)}
		>
			<FormattedMessage id="global.buttons.confirm" />
		</Button>
	]

	return (
		<Popin
			open={open}
			actions={actions}
			title={<FormattedMessage id={`users.popin.refus.${retirer ? 'titleRetire' : 'titleRefus'}`} />}
			onClose={handleClose}
			content={<>
				<Typography className={classes.message}><FormattedMessage id={`users.popin.refus.${retirer ? 'messageRetire' : 'messageRefus'}`} /> {userName} ?</Typography>
				<RefusForm onSubmit={handleSubmit(refuser)}/>
			</>}
		/>
	)
}

RefuserPopin.propTypes = {
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	userName: PropTypes.string,
	retirer: PropTypes.bool,
	getUsersList: PropTypes.func,
	classes: PropTypes.object
}

export default compose(
	reduxForm({
		form: FORM_NAME,
		initialValues: {
			justification: ''
		},
		validate
	}),
	injectSnackActions,
	withStyles(styles),
)(RefuserPopin)
