import { combineReducers } from 'redux'

// Data du modèle
import * as actions from './modelActions'
import { extractRequired, formatAttributeData } from '../../fo/document/utils/documentUtils'
import {
	DELETE_SECTION_SUCCESS,
	EDIT_SECTION_SUCCESS,
	NEW_SECTION_SUCCESS,
	UPDATE_ORDER_SUCCESS,
	POST_FIELD_SUCCESS,
	PUT_FIELD_SUCCESS,
	DELETE_FIELD_SUCCESS
} from '../../bo/parametrage/services/parametrageActions'

/**
 * Méthode utilitaire qui parcourt la structure d'un modèle pour trouver une section,
 * puis applique une fonction de transformation à cette section, sans modifier le reste du modèle
 * @param section la section en cours de traitement (peut-être le modèle lui-même)
 * @param path le chemin vers la section à modifier
 * @param transform la fonction de transofmation
 */
const findSectionAndApply = (section, path, transform) => {
	if (path.length) {
		const [childId, ...childPath] = path
		return {
			...section,
			sections: section.sections.map(childSection => {
				if (childSection.id === childId) {
					return findSectionAndApply(childSection, childPath, transform)
				}
				return childSection
			})
		}
	}
	return transform(section)
}

const data = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_MODEL_BY_TYPE_SUCCESS:
			return {
				...state,
				[action.modelType]: action.model
			}
		case UPDATE_ORDER_SUCCESS:
			return {
				...state,
				[action.modelType]: findSectionAndApply(state[action.modelType], action.sectionPath, section => ({
					...section,
					fields: action.fieldList.map((fieldId, index) => ({
						...section.fields.find(field => {
							return field.id === fieldId
						}),
						position: index
					}))
				}))
			}
		case NEW_SECTION_SUCCESS:
			return {
				...state,
				[action.modelType]: findSectionAndApply(state[action.modelType], action.sectionPath, section => {
					let currentPosition = 1
					return {
						...section,
						//Assigner une nouvelle valeur de position à chaque section, ajouter la nouvelle section puis trier par position
						level: action.sectionPath.length,
						sections: [...section.sections.filter(child => child.id !== action.section.id).map(child => {
							currentPosition === action.section.position && currentPosition++
							return { ...child, position: currentPosition++ }
						}),
							{
								...action.section,
								sections: [],
								fields: [],
								attributeData: formatAttributeData(action.section.attributeData)
							}].sort((sectionA, sectionB) => sectionA.position - sectionB.position)
					}
				})
			}

		case EDIT_SECTION_SUCCESS:
			//Suppression de la section dans l'ancienne section parente
			const newState = {
				...state,
				[action.modelType]: findSectionAndApply(state[action.modelType], action.oldSectionPath, section => {
					let currentPosition = 1
					return {
						...section,
						//Assigner une nouvelle valeur de position à chaque section
						level: action.oldSectionPath.length,
						sections: [...section.sections.filter(child => child.id !== action.section.id).map(child => {
							return { ...child, position: currentPosition++ }
						})]
					}
				})
			}
			//Ajout de la section dans la nouvelle section parente
			return {
				...newState,
				[action.modelType]: findSectionAndApply(newState[action.modelType], action.sectionPath, section => {
					let currentPosition = 1
					return {
						...section,
						//Assigner une nouvelle valeur de position à chaque section, ajouter la nouvelle section puis trier par position
						level: action.sectionPath.length,
						sections: [...section.sections.map(child => {
							currentPosition === action.section.position && currentPosition++
							return { ...child, position: currentPosition++ }
						}),
							{
								...action.section,
								fields: action.editedSection ? action.editedSection.fields : [],
								sections: action.editedSection ? action.editedSection.sections : [],
								attributeData: formatAttributeData(action.section.attributeData)
							}].sort((sectionA, sectionB) => sectionA.position - sectionB.position)
					}
				})
			}

		case POST_FIELD_SUCCESS:
			return {
				...state,
				[action.modelType]: findSectionAndApply(state[action.modelType], action.sectionPath, section => ({
					...section,
					fields: [
						...section.fields,
						action.field
					]
				}))
			}
		case PUT_FIELD_SUCCESS:
			//Cas où on ne change pas de section parente, l'ordre des fields ne change pas
			if (action.oldSectionPath[action.oldSectionPath.length - 1] === action.sectionPath[action.sectionPath.length - 1]){
				return {
					...state,
					[action.modelType]: findSectionAndApply(state[action.modelType], action.sectionPath, section => ({
						...section,
						fields: section.fields.map(field => field.id === action.field.id ? action.field : field)
					}))
				}
			}
			//Cas où la section parente est différente
			//Suppression du field dans l'ancienne section parente
			const stateDelete = {
				...state,
				[action.modelType]: findSectionAndApply(state[action.modelType], action.oldSectionPath, section => {
					return ({
						...section,
						fields: section.fields.filter(field => field.id !== action.field.id)
					})
				})
			}
			//Ajout du field à la fin de la nouvelle section parente
			return {
				...stateDelete,
				[action.modelType]: findSectionAndApply(stateDelete[action.modelType], action.sectionPath, section => ({
					...section,
					fields: [
						...section.fields,
						action.field
					]
				}))
			}
		case DELETE_FIELD_SUCCESS:
			return {
				...state,
				[action.modelType]: findSectionAndApply(state[action.modelType], action.sectionPath, section => {
					return ({
						...section,
						fields: section.fields.filter(field => field.id !== action.idField)
					})
				})
			}

		case DELETE_SECTION_SUCCESS:
			return {
				...state,
				[action.modelType]: findSectionAndApply(state[action.modelType], action.sectionPath, section => {
					let currentPosition = 1
					return {
						...section,
						//Assigner une nouvelle valeur de position à chaque section
						level: action.sectionPath.length,
						sections: [...section.sections.filter(child => child.id !== action.sectionId).map(child => {
							return { ...child, position: currentPosition++ }
						})]
					}
				})
			}

		case actions.GET_MODEL_BY_TYPE_ERROR:
		default:
			return state
	}
}

const referenceDependencies = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_MODEL_BY_TYPE_SUCCESS:
			return {
				...state,
				...action.dependenciesSection
			}
		case actions.GET_MODEL_BY_TYPE_ERROR:
			return {}
		default:
			return state
	}
}

// Champs requis du modèle
const requiredFields = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_MODEL_BY_TYPE_SUCCESS:
			return {
				...state,
				[action.modelType]: extractRequired((action.model && action.model.sections) || [])
			}
		case actions.GET_MODEL_BY_TYPE_ERROR:
			return {}
		default:
			return state
	}
}

export const model = combineReducers({
	data,
	referenceDependencies,
	requiredFields
})
